<section>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <div class="sub_menu_content">
                <a class="navbar-brand" href="#">
                    <img src="../../../../assets/img/jigyasa/banner/JigyasaUniversityLogo.png" width="160px" height="60px"
                        alt="jigyasa">
                </a>
                <button class="navbar-toggler menu_btn" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#AboutUs">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page"
                                href="admissionlp#Accreditation">Accreditation</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#Programs">Programs</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#Gallery">Gallery</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#Recruiters">Recruiters</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#Placements">Placements</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#Testimonial">Testimonial</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#FAQs">FAQs</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#USPs">USPs</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page"
                                href="admissionlp#CourseSpecialization">Specialisation</a>
                        </li>
                        
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#AboutParagraph">About
                                Paragraph </a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</section>
<div class="main_section">
    <section id="registerForm">
        <div class="main_banner">
            <div class="row">
                <div class="col-md-8">
                    <!-- <img class="mobile_baaner" src="../../../../assets/img/jigyasa/banner/landing_banner.png" width="100%" alt="admission"> -->
                 
                    <img class="mobile_baaner" src="../../../../assets/img/jigyasa/banner/Jigyasa-Main-banner.jpg" width="100%" alt="admission">
               
                </div>
                <div class="col-md-4 d-flex justify-content-center align-items-center">
                    <div class="admission_form">
                        <form action="" [formGroup]="admissionForm">
                        <!-- <div class="btn_list">
                            <button class="btn yellow_btn btn-sm">Register</button>
                            <button class="btn border_btn mx-3 btn-sm">Login</button>
                        </div> -->
                        <div class="form-group mt-5">
                            <input type="text" class="form-control" placeholder="Name" formControlName="fname"
                            [ngClass]="{ 'is-invalid': submitted && f.fname.errors }">
                            <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                                <span class="text-danger msg_txt" *ngIf="(f.fname.touched || submitted) && f.fname.errors?.required">
                                   First Name is required
                                </span>
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <input type="text" class="form-control" placeholder="Email"formControlName="email"
                          [ngClass]="{ 'is-invalid': submitted && f.email.errors }" >
                          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <span class="text-danger msg_txt" *ngIf="(f.email.touched || submitted) && f.email.errors?.required">
                                Email is required
                            </span>
                            <span class="text-danger msg_txt" *ngIf="f.email.touched && f.email.errors?.email">
                                Enter a valid email address
                            </span>
                        </div>
                        </div>
                        <div class="form-group mt-2">
                            <input type="text" class="form-control" placeholder="+91" formControlName="mobile"
                            [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" >
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback msg_txt">
                                <span *ngIf="f.mobile.errors?.required">Mobile is required.</span>
                                <span *ngIf="f.mobile.errors?.pattern">Please, Enter 10 digit Mobile.</span>
                              
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <select name="" id="" class="form-control" formControlName="state"
                            [ngClass]="{ 'is-invalid': submitted && f.state.errors }" (change)="selectState($event)">
                                <option value="">State</option>
                                <option  *ngFor="let state of stateList" value="{{state}}">
                                    {{state}}</option>
                            </select>
                            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                <span class="text-danger msg_txt"
                                    *ngIf="(f.state.touched || submitted) && f.state.errors?.required">
                                    State is required
                                </span>
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <select name="" id="" class="form-control" formControlName="city"
                            [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                                <option value="">City</option>
                                <option value="{{city.city}}" *ngFor="let city of cityList">
                                    {{city.city}}</option>
                            </select>
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <span class="text-danger msg_txt"
                                    *ngIf="(f.city.touched || submitted) && f.city.errors?.required">
                                    City is required
                                </span>
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <select name="" id="" class="form-control" formControlName="course"
                            [ngClass]="{ 'is-invalid': submitted && f.course.errors }" (change)="selectCourse($event)">
                                <option value="">Course</option>
                                <option *ngFor="let course of courseDataList" value="{{course}}">{{course}}</option>
                              
                            </select>
                            <div *ngIf="submitted && f.course.errors" class="invalid-feedback">
                                <span class="text-danger msg_txt"
                                    *ngIf="(f.course.touched || submitted) && f.course.errors?.required">
                                    course is required
                                </span>
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <select name="" id="" class="form-control" formControlName="specialization"
                            [ngClass]="{ 'is-invalid': submitted && f.specialization.errors }">
                                <option value="">Specialization</option>
                                <option *ngFor="let spcil of specializationList" value="{{spcil.specialization}}">{{spcil.specialization}}</option>
                            </select>
                            <div *ngIf="submitted && f.specialization.errors" class="invalid-feedback">
                                <span class="text-danger msg_txt"
                                    *ngIf="(f.specialization.touched || submitted) && f.specialization.errors?.required">
                                    Specialization is required
                                </span>
                            </div>
                        </div>

                        <div class="from-group mt-2">
                            <div class="box-CAPTCHA d-flex justify-content-between align-items-center" id="box-CAPTCHA">
                                <div class="captcha_content_flex">
                                    <input type="text" class="form-control" [(ngModel)]="text_captcha" id="text_captcha" placeholder="Type CAPTCHA Here"
                                        maxlength="6">

                                    <div id="wrong" style="margin-left: 10px;color: #f00; font-size: 12px;"></div>
                                    <div id="done" style="margin-left: 10px;color: #080; font-size: 12px"></div>
                                </div>
                                <div class="CAPTCHA" id="CAPTCHA">{{gen_captcha}}</div>
                            </div>
                        </div>

                        <div class="from-group mt-2">
                            <input type="checkbox" id="" [(ngModel)]="isChecked" (change)="checkValue($event)" class="mx-2" name="" value=""><span class="msg">I agree to
                                receive information by signing up on Jigyasa University</span>
                        </div>
                        <div class="from-group mt-3">
                            <!-- <button class="btn btn_style_color btn-sm w-100" (click)="validationForm()">Register
                                <div class="spinner-border text-dark" role="status" *ngIf="loader">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                            </button> -->
                            <button class="btn btn_style_color btn-sm w-100 d-flex justify-content-center  align-items-center"(click)="validationForm()">Register 
                                <div class="spinner-border text-dark" role="status" *ngIf="loader">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                            </button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="coun_content mt-1">
            <div class="count">
                <div class="row">
                    <div class="col-md-1"></div>

                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="count_box">
                            <div class="d-flex justify-content-center align-items-center">
                                <!-- <span class="color_text">upto</span> -->
                                <span class="color_num">10,000+</span>
                            </div>
                            <div class="color_text d-flex justify-content-center align-items-center">Alumni</div>
                        </div>
                    </div>

                  
                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="count_box">
                            <div class="d-flex justify-content-center align-items-center">
                                <span class="color_num">20+</span>
                                <span class="color_text">Years</span>

                            </div>
                            <div class="color_text d-flex justify-content-center align-items-center">of Academic
                                Excellence</div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="count_box">
                            <div class="d-flex justify-content-center align-items-center">
                                <!-- <span class="color_text">upto</span> -->
                                <span class="color_num">180+</span>
                            </div>
                            <div class="color_text d-flex justify-content-center align-items-center">Recruiters</div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="count_box">
                            <div class="d-flex justify-content-center align-items-center">
                                <!-- <span class="color_text">upto</span> -->
                                <span class="color_num">55</span>
                            </div>
                            <div class="color_text d-flex justify-content-center align-items-center">Acres Wi-Fi Campus
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="">
                            <div class="d-flex justify-content-center align-items-center">
                                <span class="color_text">upto</span>
                                <span class="color_num">100%</span>
                            </div>
                            <div class="color_text d-flex justify-content-center align-items-center">Scholarship*</div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
            </div>
            

            <div class="count2">
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="count_box mt-3">
                            <div class="d-flex justify-content-center align-items-center">
                                <!-- <span class="color_text">upto</span> -->
                                <span class="color_num">10,000+</span>
                            </div>
                            <div class="color_text d-flex justify-content-center align-items-center">Alumni</div>
                        </div>
                    </div>
                    
                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="mt-3">
                            <div class="d-flex justify-content-center align-items-center">
                                <span class="color_num">20+</span>
                                <span class="color_text">Years</span>

                            </div>
                            <div class="color_text d-flex justify-content-center align-items-center">of Academic
                                Excellence</div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="count_box mt-3">
                            <div class="d-flex justify-content-center align-items-center">
                                <!-- <span class="color_text">upto</span> -->
                                <span class="color_num">180+</span>
                            </div>
                            <div class="color_text d-flex justify-content-center align-items-center">Recruiters</div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="mt-3">
                            <div class="d-flex justify-content-center align-items-center">
                                <!-- <span class="color_text">upto</span> -->
                                <span class="color_num">55</span>
                            </div>
                            <div class="color_text d-flex justify-content-center align-items-center">Acres Wi-Fi Campus
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                        <div class="mt-3">
                            <div class="d-flex justify-content-center align-items-center">
                                <span class="color_text">upto</span>
                                <span class="color_num">100%</span>
                            </div>
                            <div class="color_text d-flex justify-content-center align-items-center">Scholarship*</div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
            </div>
        </div>
    </section>
    <section id="AboutUs">
        <div class="aboutUs">
            <div class="sub_content">
                <div class="row">
                    <div class="col-md-6">
                        <div class="info_content">
                            <!-- <h3>About Us</h3>
                            <div class="para">
                                <p>Jigyasa University (Formerly Himgiri Zee University ) has been established to meet
                                    the growing demand for trained human resources in various social, educational,
                                    scientific, technical and professional organizations so as to channel human energy
                                    towards sustainable economic and social development.</p>
                                <p>Based on the motto `Vasudhaiva Kutumbakam’ (World as Family), Jigyasa University has
                                    been established for the vocationalisation of higher education which is national in
                                    character and transnational in jurisdiction. The University is characterized by its
                                    resolve not to discriminate on grounds of caste, creed, residence, community, gender
                                    and religion.</p>
                            </div> -->
                                 <div class="title padding_top">Our Vision</div>
                                <p>We provide the environment to ignite, nurture and unleash your potential and talent.</p>
                                    <div class="title">Our Mission</div>
                                <ol>
                                    <li>Progressive educational proficiencies that stimulate holistic development.</li>
                                    <li>Enhancing experiential learning through endorsing an inclusive mind-set.</li>
                                    <li>Advancing research, nurturing innovations and catalyzing entrepreneurship.</li>
                                    <li>Cultivation of leadership qualities with a strong sense of values and ethics.</li>
                                </ol>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center">
                        <div class="img_content">
                            <img src="../../../../assets/img/jigyasa/admissionLP/aboutus.jpeg" width="100%" alt="">
                        </div>
                    </div>
                </div>
                <div class="sub_content_coreValue">
                    <div class="title mt-3">JIGYASA Core Values</div>
                    <div class="coreValues">
                    <div>
                    <span>Respect</span>
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/core-value-step1.webp" alt="JIGYASA Core Values">
                </div>
                <div>
                    <span>Innovation</span>
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/core-value-step2.webp" alt="JIGYASA Core Values">
                </div>
                <div>
                    <span>Passion</span>
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/core-value-step3.webp" alt="JIGYASA Core Values">
                </div>
                <div>
                    <span>Inclusivity</span>
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/core-value-step4.webp" alt="JIGYASA Core Values">
                </div>
                <div>
                    <span>Trust</span>
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/core-value-step5.webp" alt="JIGYASA Core Values">
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
    <!-- <section id="CourseSpecialization">
        <div class="course_content mt-5">
            <div class="sub_content">
                <h3 class="text-white">Course + Specialization</h3>
                <div class="row pb-5 mt-3">
                    <div class="col-md-6">
                        <div class="cource_box">
                            <div class="card_info_content">
                                <p>Inspire to Achieve</p>
                                <h4>Modern Facilities</h4>
                                <p class="color_text">Advanced resources</p>
                                <span class="learn_more">Learn More</span>
                            </div>
                            <div class="img_box">
                                <img src="../../../../assets/img/jigyasa/admissionLP/cource_img.png" width="100%"
                                    alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="cource_box">
                            <div class="card_info_content">
                                <p>Craft Your Path</p>
                                <h4>Alumni Successes</h4>
                                <p class="color_text">Global opportunities.</p>
                                <span class="learn_more">Connect Now</span>
                            </div>
                            <div class="img_box">
                                <img src="../../../../assets/img/jigyasa/admissionLP/cource_img2.png" width="100%"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

    <section id="Accreditation">
        <div class="accreditation_content pb-5">
            <div class="sub_content">
                <h3 class="text-center">Accreditation</h3>
                <div class="row mt-3">
                    <div class="col-md-1"></div>
                    <div class="col-md-2">
                        <div class="accorditation_box">
                            <div class="logo d-flex justify-content-center align-items-center">
                                <img src="../../../../assets/img/jigyasa/admissionLP/ugc.jpg" class="ugc_img" style="border-radius: 10px;" alt="">
                            </div>
                            <h4 class="text-white text-center mt-4">UGC</h4>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="accorditation_box">
                            <div class="logo d-flex justify-content-center align-items-center">
                                <img src="../../../../assets/img/jigyasa/admissionLP/nc.png" alt="">
                            </div>
                            <h4 class="text-white text-center mt-4">NC</h4>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="accorditation_box">
                            <div class="logo d-flex justify-content-center align-items-center">
                                <img src="../../../../assets/img/jigyasa/admissionLP/coa.png" alt="">
                            </div>
                            <h4 class="text-white text-center mt-4">COA</h4>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="accorditation_box">
                            <div class="logo d-flex justify-content-center align-items-center">
                                <img src="../../../../assets/img/jigyasa/admissionLP/bci.png" alt="">
                            </div>
                            <h4 class="text-white text-center mt-4">BCI</h4>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="accorditation_box">
                            <div class="logo d-flex justify-content-center align-items-center">
                                <img src="../../../../assets/img/jigyasa/admissionLP/pci.png" alt="">
                            </div>
                            <h4 class="text-white text-center mt-4">PCI</h4>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
            </div>
        </div>
    </section>

    <section id="Programs">
        <div class="programs pb-5">
            <div class="sub_content">
                <h3 class="text-white text-center">Programs Offered</h3>
                <div class="banner_content_slider">
                    <owl-carousel-o [options]="customOptions">
                        <ng-template carouselSlide>
                            <div class="d-flex justify-content-center">
                                <div class="common-card">
                                    <figure>
                                        <img class="lazyloaded"
                                            src="../../../../assets/img/jigyasa/admissionLP/program1.png" width="100%"
                                            height="130px">
                                    </figure>
                                    <div class="common-card-body">
                                        <div class="info_content">
                                            <div class="common-card-body-heads">
                                                <h5 class="mt-2">School of science and technology</h5>
                                                <div class="para">
                                                    <ul class="card_ul">
                                                        <li>B.Tech – CSE (Cyber Security, AI & ML, Data Science, IoT)
                                                        </li>
                                                        <li>BCA (Data Science, IoT) </li>
                                                        <li>Ph.D.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn_content d-flex justify-content-center align-items-center">
                                           <a href="admissionlp#registerForm"><button class="btn apply_btn">Apply Now</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="d-flex justify-content-center">
                                <div class="common-card">
                                    <figure>
                                        <img class="lazyloaded"
                                            src="../../../../assets/img/jigyasa/admissionLP/program2.png" width="100%"
                                            height="130px">
                                    </figure>
                                    <div class="common-card-body">
                                        <div class="info_content">
                                            <div class="common-card-body-heads">
                                                <h5 class="mt-2">School of Agriculture Forestry and Fisheries</h5>
                                                <div class="para">
                                                    <ul class="card_ul">
                                                        <li>B.Sc. (Hons) Agriculture</li>
                                                        <li>M.Sc. (Agriculture) Agronomy </li>
                                                        <li>M.Sc. (Agriculture) Genetics and Plant Breeding</li>
                                                        <li>Ph.D.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn_content d-flex justify-content-center align-items-center">
                                            <a href="admissionlp#registerForm"><button class="btn apply_btn">Apply Now</button></a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="d-flex justify-content-center">
                                <div class="common-card">
                                    <figure>
                                        <img class="lazyloaded"
                                            src="../../../../assets/img/jigyasa/admissionLP/program3.png" width="100%"
                                            height="130px">
                                    </figure>
                                    <div class="common-card-body">
                                        <div class="info_content">
                                            <div class="common-card-body-heads">
                                                <h5 class="mt-2">school of pharmaceutical science</h5>
                                                <div class="para">
                                                    <ul class="card_ul">
                                                        <li>B.Pharm</li>
                                                        <li>D.Pharm</li>
                                                        <li>B.Sc. Clinical Research</li>
                                                        <li>M.Sc. Clinical Research</li>
                                                        <li>Master in Public Health</li>
                                                        <li>MBA - Health Care Management</li>
                                                        <li>Ph.D.</li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="btn_content d-flex justify-content-center align-items-center">
                                            <a href="admissionlp#registerForm"><button class="btn apply_btn">Apply Now</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="d-flex justify-content-center">
                                <div class="common-card">
                                    <figure>
                                        <img class="lazyloaded"
                                            src="../../../../assets/img/jigyasa/admissionLP/program4.png" width="100%"
                                            height="130px">
                                    </figure>
                                    <div class="common-card-body">
                                        <div class="info_content">
                                            <div class="common-card-body-heads">
                                                <h5 class="mt-2">School Of Hotel and Hospitality Management</h5>
                                                <div class="para">
                                                    <ul class="card_ul">
                                                        <li>Bachelor of Hotel Management</li>
                                                        <li>BA (Restaurant Management)</li>
                                                        <li>Diploma in Hotel Management</li>
                                                        <li>Certificate in Hotel Management (Barbeque)*</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn_content d-flex justify-content-center align-items-center">
                                            <a href="admissionlp#registerForm"> <button class="btn apply_btn">Apply Now</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="d-flex justify-content-center">
                                <div class="common-card">
                                    <figure>
                                        <img class="lazyloaded"
                                            src="../../../../assets/img/jigyasa/admissionLP/program5.png" width="100%"
                                            height="130px">
                                    </figure>
                                    <div class="common-card-body">
                                        <div class="info_content">
                                            <div class="common-card-body-heads">
                                                <h5 class="mt-2">School Of Legal Studies</h5>
                                                <div class="para">
                                                    <ul class="card_ul">
                                                        <li>BA LLB </li>
                                                        <li>BBA LLB</li>
                                                        <li>LLB</li>
                                                        <li>LLM - 1 YR</li>
                                                        <li>LLM -  2 YR</li>
                                                        <li>Ph.D.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn_content d-flex justify-content-center align-items-center">
                                            <a href="admissionlp#registerForm"><button class="btn apply_btn">Apply Now</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="d-flex justify-content-center">
                                <div class="common-card">
                                    <figure>
                                        <img class="lazyloaded"
                                            src="../../../../assets/img/jigyasa/admissionLP/program6.png" width="100%"
                                            height="130px">
                                    </figure>
                                    <div class="common-card-body">
                                        <div class="info_content">
                                            <div class="common-card-body-heads">
                                                <h5 class="mt-2">School Of Business Studies</h5>
                                                <div class="para">
                                                    <ul class="card_ul">
                                                        <li>BBA </li>
                                                        <li>B.Com (Hons)</li>
                                                        <li>MBA</li>
                                                        <li>HR, Finance, Marketing, Production and Operation, Tourism,
                                                            Aviation</li>
                                                        <li>Ph.D</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn_content d-flex justify-content-center align-items-center">
                                            <a href="admissionlp#registerForm"><button class="btn apply_btn">Apply Now</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="d-flex justify-content-center">
                                <div class="common-card">
                                    <figure>
                                        <img class="lazyloaded"
                                            src="../../../../assets/img/jigyasa/admissionLP/program7.png" width="100%"
                                            height="130px">
                                    </figure>
                                    <div class="common-card-body">
                                        <div class="info_content">
                                            <div class="common-card-body-heads">
                                                <h5 class="mt-2">School Of Journalism and Mass Communication</h5>
                                                <div class="para">
                                                    <ul class="card_ul">
                                                        <li>BA (Journalism & Mass Communication)</li>
                                                        <li>BA (Digital Journalism) * </li>
                                                        <li>B.Sc. Animation & Graphic Design *</li>
                                                        <li>MA Journalism & Mass Communication</li>
                                                        <li>ZICA / ZIMA*</li>
                                                        <li>Ph.D.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn_content d-flex justify-content-center align-items-center">
                                            <a href="admissionlp#registerForm"><button class="btn apply_btn">Apply Now</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="d-flex justify-content-center">
                                <div class="common-card">
                                    <figure>
                                        <img class="lazyloaded"
                                            src="../../../../assets/img/jigyasa/admissionLP/program8.png" width="100%"
                                            height="130px">
                                    </figure>
                                    <div class="common-card-body">
                                        <div class="info_content">
                                            <div class="common-card-body-heads">
                                                <h5 class="mt-2">School Of Education and Humanities</h5>
                                                <div class="para">
                                                    <ul class="card_ul">
                                                        <li>BA</li>
                                                        <li>BA (Psychology,International Relations) *</li>
                                                        <li>B.Ed.</li>
                                                        <li>MA Education,Clinical Psychology</li>
                                                        <li>Ph.D.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn_content d-flex justify-content-center align-items-center">
                                            <a href="admissionlp#registerForm"><button class="btn apply_btn">Apply Now</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </section>

    <section id="Gallery">
        <div class="main_content_para my-4">
            <div class="sub_content">
                <h3 class="text-center text-center">Experience campus vibrance.</h3>
                <div class="program-tab-outer">
                    <div class="listSearchArea d-flex justify-content-center">
                        <ul class="nav nav-tabs tabs-buttons" role="tablist">
                            <li class="nav-item" role="presentation" id="boardOfGovernorsTab0">
                                <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0"
                                    type="button" role="tab" aria-labelledby="board Of Governors Tab 0"
                                    aria-selected="false">All</button>
                            </li>
                            <li class="nav-item" role="presentation" id="boardOfGovernorsTab1">
                                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors1"
                                    type="button" role="tab" aria-labelledby="board Of Governors Tab 1"
                                    aria-selected="true">Infra</button>
                            </li>
                            <li class="nav-item" role="presentation" id="boardOfGovernorsTab2">
                                <button class="nav-link " data-bs-toggle="tab" data-bs-target="#boardOfGovernors2"
                                    type="button" role="tab" aria-labelledby="board Of Governors Tab 2"
                                    aria-selected="false">Events</button>
                            </li>
                            <li class="nav-item" role="presentation" id="boardOfGovernorsTab3">
                                <button class="nav-link " data-bs-toggle="tab" data-bs-target="#boardOfGovernors3"
                                    type="button" role="tab" aria-labelledby="board Of Governors Tab 3"
                                    aria-selected="false">Classroom</button>
                            </li>

                        </ul>

                    </div>
                </div>
                <div class="tab-content" id="findYourProgramTabs-content">

                    <div class="tab-pane fade active show" id="boardOfGovernors0" role="tabpanel"
                        aria-labelledby="board Of Governors Tab 0">
                        <div class="row pb-5">
                            
                            <div class="col-md-4 mt-3">
                            <div class="gallery_img">
                                <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/i1.jpeg">
                                    <img src="../../../../assets/img/jigyasa/admissionLP/gallery/i1.jpeg" width="100%"
                                        height="200px" alt="">
                                </a>
                            </div>
                        </div>
                       
                      
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/g4.png">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/g4.png" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/g6.png">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/g6.png" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                           
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/i2.jpeg">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/gallery/i2.jpeg" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/g2.png">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/g2.png" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/e1.jpeg">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/gallery/e1.jpeg" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/e2.jpeg">
                                    <img src="../../../../assets/img/jigyasa/admissionLP/gallery/e2.jpeg" width="100%"
                                        height="200px" alt="">
                                </a>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/g8.png">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/g8.png" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/g9.png">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/g9.png" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="boardOfGovernors1" role="tabpanel"
                        aria-labelledby="board Of Governors Tab 1">
                        <div class="row pb-5">
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/i1.jpeg">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/gallery/i1.jpeg" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/i2.jpeg">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/gallery/i2.jpeg" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/g2.png">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/g2.png" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div class="tab-pane fade " id="boardOfGovernors2" role="tabpanel"
                        aria-labelledby="board Of Governors Tab 2">
                        <div class="row pb-5">
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/e1.jpeg">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/gallery/e1.jpeg" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/e2.jpeg">
                                    <img src="../../../../assets/img/jigyasa/admissionLP/gallery/e2.jpeg" width="100%"
                                        height="200px" alt="">
                                </a>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/e3.jpeg">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/gallery/e3.jpeg" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/e4.jpeg">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/gallery/e4.jpeg" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/e5.jpeg">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/gallery/e5.jpeg" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/gallery/e6.jpeg">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/gallery/e6.jpeg" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/g7.png">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/g7.png" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/g8.png">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/g8.png" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/g9.png">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/g9.png" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade " id="boardOfGovernors3" role="tabpanel"
                        aria-labelledby="board Of Governors Tab 3">
                        <div class="row pb-5">
                           
                          
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../../assets/img/jigyasa/admissionLP/g3.png">
                                        <img src="../../../../assets/img/jigyasa/admissionLP/g3.png" width="100%"
                                            height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="Recruiters">
        <div class="recruiters pb-5">

            <div class="sub_content">
                <h3 class="text-center">Recruiters</h3>
                <div class="row mt-4">
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo11.png" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo12.png" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo13.jfif" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo1.png" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo2.png" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo3.png" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo4.png" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo5.png" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo6.png" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo7.png" width="100%" alt="">
                        </div>
                    </div>
                    <!-- <div class="col-md-2 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo8.png" width="100%" alt="">
                        </div>
                    </div> -->
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">

                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo9.png" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6 d-flex justify-content-center align-items-center">
                        <div class="logo_box">
                            <img src="../../../../assets/img/jigyasa/admissionLP/Presslogo10.png" width="100%" alt="">
                        </div>
                    </div>
                </div>
                <div class="manyMore text-center">
                    and many more....
                </div>
            </div>
        </div>
    </section>

    <section id="Placements">
        <div class="placement_content p-5">
            <div class="sub_content">
                <h3 class="text-white text-center">Placement Highlights</h3>
                <div class="row mt-3">
                    <div class="col-lg-3 col-md-3 col-6 d-flex justify-content-center align-items-center">
                        <div class="placement_img">
                            <div class="profil_img">
                                <img src="../../../../assets/img/jigyasa/admissionLP/Shubham.png" class="mahapara_img" alt="Shubham Jha">
                            </div>
                            <div class="ingo_content">
                                <h6 class="text-white text-center mt-2">
                                    Shubham Jha
                                </h6>
                                <div class="sub_text text-center">BJMC</div>
                                <div class="color_text text-center">NFCD</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-6 d-flex justify-content-center align-items-center">
                        <div class="placement_img">
                            <div class="profil_img">
                                <img src="../../../../assets/img/jigyasa/admissionLP/Rishabh.png" class="mahapara_img" alt="Rishabh Bhatt">
                            </div>
                            <div class="ingo_content">
                                <h6 class="text-white text-center mt-2">
                                    Rishabh Bhatt
                                </h6>
                                <div class="sub_text text-center">Computer Science</div>
                                <div class="color_text text-center">Rubico IT</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-6 d-flex justify-content-center align-items-center">
                        <div class="placement_img">
                            <div class="profil_img">
                                <img src="../../../../assets/img/jigyasa/admissionLP/Ayush.png" class="mahapara_img" alt="Ayush Raj">
                            </div>
                            <div class="ingo_content">
                                <h6 class="text-white text-center mt-2">
                                    Ayush Raj
                                </h6>
                                <div class="sub_text text-center">B.Com</div>
                                <div class="color_text text-center">KPMG</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-6 d-flex justify-content-center align-items-center">
                        <div class="placement_img">
                            <div class="profil_img">
                                <img src="../../../../assets/img/jigyasa/admissionLP/Mahapara.png" class="mahapara_img" alt="Mahapara Khan">
                            </div>
                            <div class="ingo_content">
                                <h6 class="text-white text-center mt-2">
                                    Mahapara Khan
                                </h6>
                                <div class="sub_text text-center">MBA</div>
                                <div class="color_text text-center">ICICI & Airtel</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="Testimonial">
        <div class="studentsSpeak">
            <div class="sub_content">
                <h3 class="text-center">Our Students Speak</h3>
                <div class="row mt-4">
                    <div class="col-md-4 d-flex justify-content-center">
                        <div class="student_content">
                            <div class="student_img">
                                <img src="../../../../assets/img/jigyasa/admissionLP/NainaPandey.png" width="100%"
                                    height="225px" alt="">
                            </div>
                            <h5>BA (Journalism & Mass Communication)</h5>
                            <div class="para mt-2">
                                <p>My experience at the university is full of learning and grooming. Being a wonderful university, It gives me an opportunity to meet different kinds of people from around the country and learn many things from them. I am thankful to all the faculty, mentors at the Mass Communication department for providing us with quality education. I am also grateful to TNP Cell for organising Personality Development Classes and arranging regular Placements visits. Overall, it has been a great experience and a lifetime memory at Jigyasa University (Formerly Himgiri Zee University).</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 d-flex justify-content-center">
                        <div class="student_content">
                            <div class="student_img">
                                <img src="../../../../assets/img/jigyasa/admissionLP/AnushkaGarg.png" width="100%"
                                    height="225px" alt="">
                            </div>
                            <h5>BA (Journalism & Mass Communication)</h5>
                            <div class="para mt-2">
                                <p>Jigyasa University has become a significant companion in my career Journey. Here,
                                    along with the academics, I have enhanced my behavioral communication. I feel
                                    grateful that faculty members here have been very caring and professional. With the
                                    expertise learnt, I really feel confident today. I am ready to make the mark in the
                                    field of Media and Communication. Thanks Jigyasa University.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 d-flex justify-content-center">
                        <div class="student_content">
                            <div class="student_img">
                                <img src="../../../../assets/img/jigyasa/admissionLP/LalitPrasad.png" width="100%"
                                    height="225px" alt="">
                            </div>
                            <h5>Bachelor of Hotel Management</h5>
                            <div class="para mt-2">
                                <p>I found the Jigyasa University the most disciplined University in Dehradun. The
                                    Department of Hotel & Tourism Management was always there whenever I needed their
                                    guidance, the academic quality and all infrastructure is very good. I can say that
                                    we all students got the right blend of theoretical knowledge and practical skills
                                    which are needed and essential for this hospitality industry. Being a chef and
                                    evolve new recipe was always my dream, and my teachers were always there to help me
                                    out. I always thanks to my University for providing and upgrading such theoretical
                                    and technical skills for making path for such a great career for me.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <section>
        <div class="container desktopList">
            <img src="../../../../assets/img/jigyasa/LOAN PARTNER_horizontal.png" class="img-fluid" alt="Responsive image">
        </div>
        <div class="container mt-2 text-center mobile_baaner">
            <img src="../../../../assets/img/jigyasa/LOAN PARTNER_vertical.png" class="img-fluid" alt="Responsive image">
        </div>
    </section>

    <section id="FAQs">
        <div class="faqs_content">
            <div class="sub_content">
                <div class="row">
                    <div class="col-md-4">
                        <div class="faqs_text_content">
                            <h3>Frequently Asked Questions</h3>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p> -->
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="col-faqs_list">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Is the university recognized by the UGC?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Yes, Candidates can visit the University campus on any working day from 8:30 AM to
                                                6:00 PM and can complete the admission process. It is suggested to contact the
                                                University officials on 1800-180-4181 before visiting the campus.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                            aria-expanded="false" aria-controls="collapseTwo">
                                            How do I know if I am eligible to take admission in the program?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Eligibility criteria for all the programs are specified on the respective program page of
                                                the website (www.jigyasauniversity.edu.in). Kindly visit the program page in which you
                                                are interested and check the eligibility to take admission.</p>
                                                <p>You can also contact us at 1800-180-4181 we will assist you with the information.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                            aria-expanded="false" aria-controls="collapseThree">
                                            What are the documents that I need to upload to the admission portal?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>The scanned copies of the following documents are required at the time of filling up the
                                                application online:</p>
                                            
                                                    <ul>
                                                        <li>Passport size photograph</li>
                                                        <li>10th Marksheet</li>
                                                        <li>12th Marksheet/Diploma Marksheets (of all Semesters)</li>
                                                        <li>Graduation Marksheets (of all semesters)/Degree Certificate (PG Programs)</li>
                                                        <li>Aadhaar card of the candidate (Both Sides)/Voter ID/PAN Card</li>
                                                        <li>Domicile Certificate</li>
                                                        <li>Caste Certificate (in case of OBC, SC, ST)</li>
                                                        <li>Original Transfer certificate, Migration Certificate character certificate</li>
                                                        <li>International Candidates can upload their Passport for ID proof.</li>
                                                    </ul>
                                                  
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree1">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree1"
                                            aria-expanded="false" aria-controls="collapseThree1">
                                            Where is Jigyasa University (Formerly Himgiri Zee University)?
                                        </button>
                                    </h2>
                                    <div id="collapseThree1" class="accordion-collapse collapse"
                                        aria-labelledby="headingThree1" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>P.O. Sherpur, Chakrata Road, Dehradun (Uttarakhand) Pin-248197</p>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree2">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree2"
                                            aria-expanded="false" aria-controls="collapseThree2">
                                            Any hidden charges or extra charges apart from fees?
                                        </button>
                                    </h2>
                                    <div id="collapseThree2" class="accordion-collapse collapse"
                                        aria-labelledby="headingThree2" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>This is the third item's accordion body.</p> It is hidden by
                                            default, until the collapse plugin adds the appropriate classes that we use
                                            to style each element. These classes control the overall appearance, as well
                                            as the showing and hiding via CSS transitions. You can modify any of this
                                            with custom CSS or overriding our default variables. It's also worth noting
                                            that just about any HTML can go within the <code>.accordion-body</code>,
                                            though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree3">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree3"
                                            aria-expanded="false" aria-controls="collapseThree3">
                                            Can we get the Demo class session?
                                        </button>
                                    </h2>
                                    <div id="collapseThree3" class="accordion-collapse collapse"
                                        aria-labelledby="headingThree3" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>This is the third item's accordion body.</p> It is hidden by
                                            default, until the collapse plugin adds the appropriate classes that we use
                                            to style each element. These classes control the overall appearance, as well
                                            as the showing and hiding via CSS transitions. You can modify any of this
                                            with custom CSS or overriding our default variables. It's also worth noting
                                            that just about any HTML can go within the <code>.accordion-body</code>,
                                            though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_content mt-5">
            <div class="">
                <div class="row">
                    <div class="col-md-3">
                        <img src="../../../../assets/img/jigyasa/banner/JigyasaUniversityLogo.png" width="70%" alt="">
                        <div class="midea_icon_list mt-4">
                            <a href="https://www.facebook.com/Jigyasauniversity" target="_blank" class="mx-1"><img
                                    src="../../../../assets/img/jigyasa/admissionLP/Icon.png" width="22px" height="22px"
                                    alt=""></a>
                            <a href="https://www.linkedin.com/school/jigyasa-university/" target="_blank" class="mx-1"><img
                                    src="../../../../assets/img/jigyasa/admissionLP/Icon2.png" width="22px"
                                    height="22px" alt=""></a>
                            <a href="https://www.youtube.com/@Jigyasauniversity" target="_blank" class="mx-1"><img
                                    src="../../../../assets/img/jigyasa/admissionLP/Icon3.png" width="22px"
                                    height="22px" alt=""></a>
                            <a href="https://www.instagram.com/ju_ddn/" target="_blank" class="mx-1"><img
                                    src="../../../../assets/img/jigyasa/admissionLP/Icon4.png" width="22px"
                                    height="22px" alt=""></a>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-6">
                                <ul>
                                    <li><a href="admissionlp#AboutUs"> About Us </a></li>
                                     <li><a href="admissionlp#Accreditation">Accreditation</a></li>
                                     <li><a href="admissionlp#Programs">Programs</a></li>
                                    
                                </ul>
                            </div>
                            <div class="col-lg-4 col-md-4 col-6">
                                <ul>
                                    <li><a href="admissionlp#Gallery">Gallery</a></li>
                                    <li><a href="admissionlp#Recruiters">Recruiters</a></li>
                                    <li><a href="admissionlp#Placements">Placements</a></li>
                                </ul>
                            </div>
                            <div class="col-lg-4 col-md-4 col-6">
                                <ul>
                                    <li><a href="admissionlp#Testimonial">Testimonial</a></li>
                                    <li><a href="admissionlp#FAQs">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>




    