import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonFileService {

  constructor() { }
 
  stateCityList:any = {
    "Master_State_City": [
      {
        "state": "Andaman and Nicobar",
        "city": "Car Nicobar"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Nancowry"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Diglipur"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Mayabunder"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Rangat"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Ferrargunj"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Hut Bay"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Agali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Amadagur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anantapur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Beluguppa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bommireddy Cheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Brahmasamudram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bukkapatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bukkaraya Samudram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chennekothapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chilamathur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "D.Hirehal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dharmavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Donnikota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gandlapenta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Garladinne"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gooty"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gorantla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gudibanda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gummagatta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Guntakal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Herial"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Hindupur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kadiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kambadur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kammavari Palli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kanaganapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kanekal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kothacheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kudair"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kuderu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kundurpi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lepakshi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Madakasira"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Malameedi Kambala Dinne"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mudigubba"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mutyala Cheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nallacheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nallamada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nambula Pulakunta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narapala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Obuladevaracheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pamidi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Parigi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Peddamogalai Palli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Peddapappuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penukonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Putluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Puttaparthi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramagiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rapthadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rayadurgam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Roddam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rolla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Setturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Singanamala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Somandepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sreekanthapuram Rural"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadimarri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadipatri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Talupula"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tanakallu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Uravakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vajrakarur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vidapanakal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yadiki"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Akkurthi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "B.Kothakota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "B.N. Kandriga"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bangarupalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhakarapet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bokkasam Palem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chandragiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chinna Gotti Gallu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chittecherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chittoor"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chowdepalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Damalcheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gandupalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gangadhara Nellore"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gudipala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gurram Konda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Irala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalakada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalikiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kambhamvaripalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kanipakam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Karveti Nagar"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kondareddy Palle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kuppam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Madanapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mulakalacheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Murukambattu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narayanavanam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nindra"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pakala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palamaner"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palasamudram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pannur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedda Panjani"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penumur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pichatur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pileru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pulicherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Punganur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Putalapattu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Puttur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramakuppam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramasamudram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Renigunta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sathyavedu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Satyavedu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Shanthipuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sreerangarajapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Srikalahasti"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Srirangarajapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Thondamanadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vadamalapet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Varadaiahpalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vayalpadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vedurukuppam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkata Krishna Puram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkatagirikota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vijayapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yadamari"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yerpadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yerravaripalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Aalamuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Addateegala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ainavilli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Alamuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Allavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ambajipeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anaparthy"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atreyapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bikkavolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "East Godavari"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Folkspeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gadi Lanka"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gandepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gangavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gokavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gollaprollu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jaggampeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kadiam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kajuluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kakinada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kapileswarapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Karapa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Katrenikona"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kirlampudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Korukonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kotananduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kothapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kothapeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Malikipuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mamidikududru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mandapeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Manepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Maredumilli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mummidivaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Patha Gannavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Peddapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pithapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pondicherry"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pydipaka"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajahmundry"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajanagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajavommangi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rampachodavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rangampeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ravulapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rayavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Razole"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sakhinetipalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Samarlakota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sankhavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sithanagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tallarevu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tatipaka"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tondangi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Torredu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tuni"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Uppalaguptam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vaadaparru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Y .ramavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yeleswaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Achampet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Amaravathi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ananthavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Angalakuduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Annavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Appikatla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bapatla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bellamkonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhattiprolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bollapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chandole"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Cherukupalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Cheruvu Madhavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chilakaluripet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chodayapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dachepalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dhulipudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Duggirala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Durgi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Edlapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Emani"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Guntur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gurazala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Inturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ipur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Janapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kakumanu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kanagala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Karempudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Karlapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kolakaluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kollipara"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kolluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Krosur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kuchinapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kuchipudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lemalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Machavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Macherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Medikonduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Muppalla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nadendla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Namburu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandivelugu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narasaraopet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nekarikallu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nizampatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nutakki"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nuzendla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Patchalatadiparru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedakakani"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedakurapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedanandipadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedavadlapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Phirangipuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Piduguralla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ponnekallu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ponnur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rentachintala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Repalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sattenapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Savalyapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadepalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadikonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tenali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Thullur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tsunduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Turumella"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veldhurthy"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vellaturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vemuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vinukonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atlur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "B.P.Rachapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Badvel"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Balapanur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "C.Rajupalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chakrayapet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chennur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chinnamandem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chinthakommadinne"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chitvel"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Galiveedu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gopavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jammalamadugu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kadapa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalasapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kamalapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Khajipet Sunkesula"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lakkireddipalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lavanuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lingala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Maduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Malkapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mydukur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nallaguttapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandalur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandimandalam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Obulavaripalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palugurallapalle Part IV"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penagalur Agraharam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penagaluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pendlimarri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Porumamilla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Proddatur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pulivendula"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pullampet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajampet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rayachoty"
      },
      {
        "state": "Andhra Pradesh",
        "city": "S.Mydukur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sambepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sidhout"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Simhadri Puram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "T. Sundupalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Thondur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vallur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veeraballi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vempalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vontimitta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yearraguntla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yerraguntla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "A.Konduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Agiripalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Akunuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Avanigadda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Billanapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Challapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chandralapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chatrai"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Krishna"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gampalagudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ghantasala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gudivada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gudlavalleru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Guduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Hanuman Junction"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ibrahimpatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jaggayyapet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kaikaluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kakulapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalidindi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kankipadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Katuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kondapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kruthivennu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lankapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Machilipatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mandavalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mopidevi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Movva"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mudinepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Musunuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nagayalanka"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandivada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nuzividu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedana"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedaparupudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penamaluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penuganchiprolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penugolanu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramannamodi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Reddigudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Telaprolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Thotlavalluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tiruvuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Turakapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vatsavai"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veeravalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veerullapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Visakhapatnam Port"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vissannapetaa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vuyyuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Adoni"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Banaganapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bandi Atmakuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Betamcherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "C. Belagal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Devanakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dhone"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dornipadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gadivemula"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gonegandla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gospadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kodumur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Koilkuntla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kolimigundla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kurnool"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Maddikera"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Maddikera East"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mantralayam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandikotkur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandyal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Owk"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pagidyala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pamulapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Panyam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pattikonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sanjamala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sirvella"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Srisailam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tuggali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veldurthi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Velugodu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yemmiganur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anamasamudrampeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ananthasagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anemadugu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Balayapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bitragunta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Brahmadevam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Buchireddypalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chakalakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chejerla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chillakur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chittamuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dagadarthi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dakkili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Damavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Doravarichatram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Duttalur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gandavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gandipalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gudur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gumparlapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gundalammapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jaladanki"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kaligiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kaluvaya"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Karatampadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kavali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kodavalur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kovur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Manubolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Marripadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Muthukur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Muttukuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mypadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nagamambapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Naidupeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandipadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narampeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nelapattu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nellore"
      },
      {
        "state": "Andhra Pradesh",
        "city": "North Mopuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "North Rajupalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pellakuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Podalakur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rapur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Reddypalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sarvepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Seetharamapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Siddanakonduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sullurupeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sydapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tada Khandrika"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Thotapalli Gudur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Udayagiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vakadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Varikuntapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkatachalam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkatagiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vidavalur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vinjamur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Viroor"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Addanki"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ambavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ardhaveedu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bestavaripeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chinna Dornala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Cumbum"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Donakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Giddalur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Prakasam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kanigiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Komarolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kurichedu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Markapur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Martur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ongole"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Parchoor"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedda Aravidu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Podili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pullala Cheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Racherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Singarayakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tanguturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tarlupadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tripuranthakam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vetapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Voletivaripalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yerragondapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Amadalavalasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atisurikaviti"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhamini"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Burja"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chilakapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chinnajonnavalasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Etcherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ganguvarisigadam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Hiramandalam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ichchapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jalumuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Joduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kaviti"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kotabommali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Laveru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Loddalapeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Madanapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Majjigudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mandasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Meliaputti"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narasannapeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pathapatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pathrapada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Polaki"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Salihundam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Santhabommali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Saravakota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sarubujjili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Seethampeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sirusuvada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sompeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Srikakulam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sumitrapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tekkali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vajrapukotturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vandrangi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veeraghattam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkata Rangaraya Puram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anakapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anandapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ananthagiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Araku"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atchutapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bheemunipatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Cheedikada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chintapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chodavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "D.Tallavalasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Golugonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gopalapatnam Rural"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narsipatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nathavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Paderu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Visakhapatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Alamanda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Badangi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Balijipeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhogapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bobbili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bonangi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Challapeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Cheepurupalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gajapatinagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gantyada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Garividi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Garugubilli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gummalaxmipuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gurla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jiyyammavalasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Komarada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Konada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kotagandredu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kurupam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lakkavarapu Kota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Makkuva"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mentada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Merakamudidam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nellimarla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pachipenta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palligandredu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Parvathipuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedamajjipalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedamanapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pusapatirega"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramabhadrapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramatheertham"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Salur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vizianagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkatakrishnaraja Puram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Achanta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Akividu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Attili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhimadole"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhimavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Butteyagudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chagallu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chataparru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Darbhagudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Denduluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Deverapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dharmaji Gudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dwaraka Tirumala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ganapavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gopannapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gundugolanu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "West Godavari"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Iragavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jaggisetti Gudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jangareddigudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jeelugumilli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kaikaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kamavarapu Kota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kannapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kovvali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kovvur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Koyyalagudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lakkavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Manchili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Maruteru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mogalturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Moyyeru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nallajerla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narsapur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nidadavolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Padapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palakoderu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palakollu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pentapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penugonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penumantra"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Peravali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pragadavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pulla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Somarajuillindalaparru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Surapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "T.narasapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadepalligudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadikalapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tallapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tanuku"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Undarajavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Undi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vedullakunta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veeravasaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Velivennu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vijayarai"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Anjaw"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bordumsa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bubang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Changlang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Jairampur"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Khemiyong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kherem Bisa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lallung"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Miao"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Namdang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nampong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Namtok"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Rajanagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Ranglum"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Vijoynagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Vijoypur"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yangkang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Alinye"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Anelih"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Anini"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Seijosa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Mebo"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kra Daadi"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Chambang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Damin"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Koloriang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nyapin"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Palin"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tali"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Chowkham"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kamlang Nagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lohit"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Mahadevpur"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Medo"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Momong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Sunpura"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tezu"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Wakro"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Longding"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lower Siang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Namsai"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Balijan"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Balijan Nishi"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bandardewa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Diomukh"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kimin"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kokila"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Papum Pare"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Naharlagun"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nirjuli"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Sagalee"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Sonajuli"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yazali"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yupia"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Siang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Jang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lumla"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Shannan"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tawang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Deomali"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tirap"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kanubari"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kanubari Town"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Khonsa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Namsang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Namsang Mukh"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Narottam Nagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Soha"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tuting"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yingkiong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bogia Siyum"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Daporijo"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Dumporijo"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Giba"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lemiking"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lepajaring"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Maro"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Muri"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nacho"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Sippi"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Siyum"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tabarijo"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Taksing"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Taliha"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bhalukpong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bomdila"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Dirang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kalaktang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Khellong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lish"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Munna Camp"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nafra"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Rupa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Salari"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Sangti"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Senge"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tenga Market"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tippi"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bagra"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bagra Higi"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bame"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Basar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bene"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bordumsa Circle"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Dali"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Darak"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Daring"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Darka"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Gumgong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kambang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kaying"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kombo Monku"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Likabali"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Liromoba"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Mechuka"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Monigong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nikte"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Payum"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tato"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tirbin"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Vivek Nagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yoji Yora"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yomcha"
      },
      {
        "state": "Assam",
        "city": "Baksa"
      },
      {
        "state": "Assam",
        "city": "Baharihat"
      },
      {
        "state": "Assam",
        "city": "Bajali"
      },
      {
        "state": "Assam",
        "city": "Barpeta"
      },
      {
        "state": "Assam",
        "city": "Barbang"
      },
      {
        "state": "Assam",
        "city": "Bhawanipur"
      },
      {
        "state": "Assam",
        "city": "Bhella"
      },
      {
        "state": "Assam",
        "city": "Bhera"
      },
      {
        "state": "Assam",
        "city": "Bhowanipur"
      },
      {
        "state": "Assam",
        "city": "Howly"
      },
      {
        "state": "Assam",
        "city": "Kalgachia"
      },
      {
        "state": "Assam",
        "city": "Kokilabari"
      },
      {
        "state": "Assam",
        "city": "Mandia"
      },
      {
        "state": "Assam",
        "city": "Mayanbari"
      },
      {
        "state": "Assam",
        "city": "Patacharkuchi"
      },
      {
        "state": "Assam",
        "city": "Pathsala"
      },
      {
        "state": "Assam",
        "city": "Pub Kathal Muri"
      },
      {
        "state": "Assam",
        "city": "Pub Khagra Bari"
      },
      {
        "state": "Assam",
        "city": "Sarthebari"
      },
      {
        "state": "Assam",
        "city": "Sarupeta"
      },
      {
        "state": "Assam",
        "city": "Simlaguri"
      },
      {
        "state": "Assam",
        "city": "Sorbhog"
      },
      {
        "state": "Assam",
        "city": "Biswanath"
      },
      {
        "state": "Assam",
        "city": "Abhayapuri"
      },
      {
        "state": "Assam",
        "city": "Bidyapara II Part Pt"
      },
      {
        "state": "Assam",
        "city": "Boitamari"
      },
      {
        "state": "Assam",
        "city": "Bongaigaon"
      },
      {
        "state": "Assam",
        "city": "Raghunandanpur Pt II"
      },
      {
        "state": "Assam",
        "city": "Sidli"
      },
      {
        "state": "Assam",
        "city": "Cachar"
      },
      {
        "state": "Assam",
        "city": "Dalu Grant"
      },
      {
        "state": "Assam",
        "city": "Narsingpur Pt II"
      },
      {
        "state": "Assam",
        "city": "Rakhaltila"
      },
      {
        "state": "Assam",
        "city": "Charaideo"
      },
      {
        "state": "Assam",
        "city": "Chirang"
      },
      {
        "state": "Assam",
        "city": "Atherikhat Jangle"
      },
      {
        "state": "Assam",
        "city": "Bhairabkunda"
      },
      {
        "state": "Assam",
        "city": "Bhakatpara"
      },
      {
        "state": "Assam",
        "city": "Dalgaon"
      },
      {
        "state": "Assam",
        "city": "Deomornoi"
      },
      {
        "state": "Assam",
        "city": "Dhula"
      },
      {
        "state": "Assam",
        "city": "Dimakuchi"
      },
      {
        "state": "Assam",
        "city": "Duni"
      },
      {
        "state": "Assam",
        "city": "Harisinga"
      },
      {
        "state": "Assam",
        "city": "Hattigarh"
      },
      {
        "state": "Assam",
        "city": "Kalaigaon"
      },
      {
        "state": "Assam",
        "city": "Kharupetiaghat"
      },
      {
        "state": "Assam",
        "city": "Khoirabari"
      },
      {
        "state": "Assam",
        "city": "Kopati"
      },
      {
        "state": "Assam",
        "city": "Lalpool"
      },
      {
        "state": "Assam",
        "city": "Mangaldoi"
      },
      {
        "state": "Assam",
        "city": "Mazbat"
      },
      {
        "state": "Assam",
        "city": "No.4 Barangajuli"
      },
      {
        "state": "Assam",
        "city": "Patharughat"
      },
      {
        "state": "Assam",
        "city": "Pub Paneri"
      },
      {
        "state": "Assam",
        "city": "Rowta"
      },
      {
        "state": "Assam",
        "city": "Sipajhar"
      },
      {
        "state": "Assam",
        "city": "Tangla"
      },
      {
        "state": "Assam",
        "city": "Bordoloni"
      },
      {
        "state": "Assam",
        "city": "Dhemaji"
      },
      {
        "state": "Assam",
        "city": "Jonai"
      },
      {
        "state": "Assam",
        "city": "Machkhowa Chariali"
      },
      {
        "state": "Assam",
        "city": "Silapathar"
      },
      {
        "state": "Assam",
        "city": "Simen Chapori"
      },
      {
        "state": "Assam",
        "city": "Sissiborgaon"
      },
      {
        "state": "Assam",
        "city": "Agomani"
      },
      {
        "state": "Assam",
        "city": "Arearjhar Pt.I"
      },
      {
        "state": "Assam",
        "city": "Bagribari"
      },
      {
        "state": "Assam",
        "city": "Debitola"
      },
      {
        "state": "Assam",
        "city": "Dhubri"
      },
      {
        "state": "Assam",
        "city": "Fekamari"
      },
      {
        "state": "Assam",
        "city": "Golakganj"
      },
      {
        "state": "Assam",
        "city": "Mankachar"
      },
      {
        "state": "Assam",
        "city": "Rupshi Part IV"
      },
      {
        "state": "Assam",
        "city": "Salkocha"
      },
      {
        "state": "Assam",
        "city": "Salmara"
      },
      {
        "state": "Assam",
        "city": "Shamugaon"
      },
      {
        "state": "Assam",
        "city": "Tumni Lawkhowa"
      },
      {
        "state": "Assam",
        "city": "Bamunbari"
      },
      {
        "state": "Assam",
        "city": "Bor Baruah"
      },
      {
        "state": "Assam",
        "city": "Dibrugarh"
      },
      {
        "state": "Assam",
        "city": "Duliajan"
      },
      {
        "state": "Assam",
        "city": "Godabahar Gaon"
      },
      {
        "state": "Assam",
        "city": "Jaloni Naohalia"
      },
      {
        "state": "Assam",
        "city": "Jutlibari Borhula"
      },
      {
        "state": "Assam",
        "city": "Khowang"
      },
      {
        "state": "Assam",
        "city": "Lahowal"
      },
      {
        "state": "Assam",
        "city": "Naharkatia"
      },
      {
        "state": "Assam",
        "city": "Teloijan Darkhsta No.25"
      },
      {
        "state": "Assam",
        "city": "Tingkhong"
      },
      {
        "state": "Assam",
        "city": "Dima Hasao (North Cachar Hills)"
      },
      {
        "state": "Assam",
        "city": "Bakrapara Pt-II"
      },
      {
        "state": "Assam",
        "city": "Goalpara"
      },
      {
        "state": "Assam",
        "city": "Deulguri Pt-II"
      },
      {
        "state": "Assam",
        "city": "Lakhipur"
      },
      {
        "state": "Assam",
        "city": "Rangjuli"
      },
      {
        "state": "Assam",
        "city": "Bokakhat"
      },
      {
        "state": "Assam",
        "city": "Golaghat"
      },
      {
        "state": "Assam",
        "city": "Sarupathar"
      },
      {
        "state": "Assam",
        "city": "Hailakandi"
      },
      {
        "state": "Assam",
        "city": "Hojai"
      },
      {
        "state": "Assam",
        "city": "Jorhat"
      },
      {
        "state": "Assam",
        "city": "Titabor"
      },
      {
        "state": "Assam",
        "city": "Amtala"
      },
      {
        "state": "Assam",
        "city": "Kamrup"
      },
      {
        "state": "Assam",
        "city": "Barpathar"
      },
      {
        "state": "Assam",
        "city": "Bezera"
      },
      {
        "state": "Assam",
        "city": "Boko"
      },
      {
        "state": "Assam",
        "city": "Bongaon"
      },
      {
        "state": "Assam",
        "city": "Chamaria Pam"
      },
      {
        "state": "Assam",
        "city": "Chamaria Satra"
      },
      {
        "state": "Assam",
        "city": "Chandrapur Bagicha"
      },
      {
        "state": "Assam",
        "city": "Changsari"
      },
      {
        "state": "Assam",
        "city": "Chhaygaon"
      },
      {
        "state": "Assam",
        "city": "Goreswar Block"
      },
      {
        "state": "Assam",
        "city": "Goroimari"
      },
      {
        "state": "Assam",
        "city": "Hajo"
      },
      {
        "state": "Assam",
        "city": "Luki ( Boko Block)"
      },
      {
        "state": "Assam",
        "city": "Muhimari Bilar Pathar"
      },
      {
        "state": "Assam",
        "city": "Nagarbera"
      },
      {
        "state": "Assam",
        "city": "Niz-Madartola"
      },
      {
        "state": "Assam",
        "city": "No.1 Chandana T.E."
      },
      {
        "state": "Assam",
        "city": "No.3 Goreswar"
      },
      {
        "state": "Assam",
        "city": "Palashbari"
      },
      {
        "state": "Assam",
        "city": "Parakuchi"
      },
      {
        "state": "Assam",
        "city": "Rampur No.3"
      },
      {
        "state": "Assam",
        "city": "Rangia"
      },
      {
        "state": "Assam",
        "city": "Rewa N.C."
      },
      {
        "state": "Assam",
        "city": "Saualkuchi"
      },
      {
        "state": "Assam",
        "city": "Sonapur Block"
      },
      {
        "state": "Assam",
        "city": "Kamrup Metropolitan"
      },
      {
        "state": "Assam",
        "city": "Bokajan"
      },
      {
        "state": "Assam",
        "city": "Dongkamukam"
      },
      {
        "state": "Assam",
        "city": "Donkamokam"
      },
      {
        "state": "Assam",
        "city": "Hamren"
      },
      {
        "state": "Assam",
        "city": "Karbi Anglong"
      },
      {
        "state": "Assam",
        "city": "Karimganj"
      },
      {
        "state": "Assam",
        "city": "Bengtol Kumguri"
      },
      {
        "state": "Assam",
        "city": "Bhowraguri"
      },
      {
        "state": "Assam",
        "city": "Bhowraguri Mandarpara"
      },
      {
        "state": "Assam",
        "city": "Bhowraguri-Satbil-II"
      },
      {
        "state": "Assam",
        "city": "Charagaon"
      },
      {
        "state": "Assam",
        "city": "Kokrajhar"
      },
      {
        "state": "Assam",
        "city": "Gossaigaon"
      },
      {
        "state": "Assam",
        "city": "Hatidhura"
      },
      {
        "state": "Assam",
        "city": "Kachugaon"
      },
      {
        "state": "Assam",
        "city": "Kaslatari Pt.I"
      },
      {
        "state": "Assam",
        "city": "Aujuri No.3"
      },
      {
        "state": "Assam",
        "city": "Bihpuria"
      },
      {
        "state": "Assam",
        "city": "Boginadi"
      },
      {
        "state": "Assam",
        "city": "Dhakuakhana"
      },
      {
        "state": "Assam",
        "city": "Dhalpur"
      },
      {
        "state": "Assam",
        "city": "Lakhimpur"
      },
      {
        "state": "Assam",
        "city": "Dikrong"
      },
      {
        "state": "Assam",
        "city": "Dirpaisantipur Gaon"
      },
      {
        "state": "Assam",
        "city": "Laluk"
      },
      {
        "state": "Assam",
        "city": "Mandia Gaon"
      },
      {
        "state": "Assam",
        "city": "Moinapara"
      },
      {
        "state": "Assam",
        "city": "Na-Ali Bhatia"
      },
      {
        "state": "Assam",
        "city": "No.1 Barchala"
      },
      {
        "state": "Assam",
        "city": "Pathali Pahar"
      },
      {
        "state": "Assam",
        "city": "Pathali Pam Grant 314/51"
      },
      {
        "state": "Assam",
        "city": "Majuli"
      },
      {
        "state": "Assam",
        "city": "Morigaon"
      },
      {
        "state": "Assam",
        "city": "Doboka Town"
      },
      {
        "state": "Assam",
        "city": "Nagaon"
      },
      {
        "state": "Assam",
        "city": "Kaliabor"
      },
      {
        "state": "Assam",
        "city": "Kampur Town"
      },
      {
        "state": "Assam",
        "city": "Pachim Sualani"
      },
      {
        "state": "Assam",
        "city": "Adalbari"
      },
      {
        "state": "Assam",
        "city": "Ahopa"
      },
      {
        "state": "Assam",
        "city": "Arikuchi"
      },
      {
        "state": "Assam",
        "city": "Baganpara"
      },
      {
        "state": "Assam",
        "city": "Bagulamari"
      },
      {
        "state": "Assam",
        "city": "Bakuajari"
      },
      {
        "state": "Assam",
        "city": "Balikaria"
      },
      {
        "state": "Assam",
        "city": "Balikuchi"
      },
      {
        "state": "Assam",
        "city": "Balizar"
      },
      {
        "state": "Assam",
        "city": "Baramchari"
      },
      {
        "state": "Assam",
        "city": "Barbari"
      },
      {
        "state": "Assam",
        "city": "Bargandubi"
      },
      {
        "state": "Assam",
        "city": "Barimakha"
      },
      {
        "state": "Assam",
        "city": "Barkhetri"
      },
      {
        "state": "Assam",
        "city": "Barnadi Par"
      },
      {
        "state": "Assam",
        "city": "Belsor"
      },
      {
        "state": "Assam",
        "city": "Bennabari"
      },
      {
        "state": "Assam",
        "city": "Bhojkuchi"
      },
      {
        "state": "Assam",
        "city": "Buri Nagar"
      },
      {
        "state": "Assam",
        "city": "Chaibari"
      },
      {
        "state": "Assam",
        "city": "Chamata"
      },
      {
        "state": "Assam",
        "city": "Chanbarikhuti"
      },
      {
        "state": "Assam",
        "city": "Chaudhuritop"
      },
      {
        "state": "Assam",
        "city": "Chechapani"
      },
      {
        "state": "Assam",
        "city": "Dalbari"
      },
      {
        "state": "Assam",
        "city": "Dangarmakha"
      },
      {
        "state": "Assam",
        "city": "Darangamela"
      },
      {
        "state": "Assam",
        "city": "Dhanbil"
      },
      {
        "state": "Assam",
        "city": "Dhaniagog"
      },
      {
        "state": "Assam",
        "city": "Dicial Dhulia Gaon"
      },
      {
        "state": "Assam",
        "city": "Dihira"
      },
      {
        "state": "Assam",
        "city": "Dirua"
      },
      {
        "state": "Assam",
        "city": "Doomni"
      },
      {
        "state": "Assam",
        "city": "Gerua"
      },
      {
        "state": "Assam",
        "city": "Geruapara"
      },
      {
        "state": "Assam",
        "city": "Guakuchi"
      },
      {
        "state": "Assam",
        "city": "Hanapara"
      },
      {
        "state": "Assam",
        "city": "Haribhanga"
      },
      {
        "state": "Assam",
        "city": "Jalkhana"
      },
      {
        "state": "Assam",
        "city": "Joy Mangla"
      },
      {
        "state": "Assam",
        "city": "Kaithalkuchi"
      },
      {
        "state": "Assam",
        "city": "Kakaya"
      },
      {
        "state": "Assam",
        "city": "Kaljar"
      },
      {
        "state": "Assam",
        "city": "Kamarkuchi"
      },
      {
        "state": "Assam",
        "city": "Karemura"
      },
      {
        "state": "Assam",
        "city": "Kendukuchi"
      },
      {
        "state": "Assam",
        "city": "Khagrabari"
      },
      {
        "state": "Assam",
        "city": "Kharsitha"
      },
      {
        "state": "Assam",
        "city": "Khatikuchi"
      },
      {
        "state": "Assam",
        "city": "Kumarikata"
      },
      {
        "state": "Assam",
        "city": "Marowa"
      },
      {
        "state": "Assam",
        "city": "Milanpur"
      },
      {
        "state": "Assam",
        "city": "Mugkuchi"
      },
      {
        "state": "Assam",
        "city": "Mukalmua"
      },
      {
        "state": "Assam",
        "city": "Mukta Pur"
      },
      {
        "state": "Assam",
        "city": "Mushalpur"
      },
      {
        "state": "Assam",
        "city": "N.C.Angarkata"
      },
      {
        "state": "Assam",
        "city": "Nalbari"
      },
      {
        "state": "Assam",
        "city": "Nankar Bhaira"
      },
      {
        "state": "Assam",
        "city": "Nikashi"
      },
      {
        "state": "Assam",
        "city": "Niz Namati"
      },
      {
        "state": "Assam",
        "city": "Niz-Bahjani"
      },
      {
        "state": "Assam",
        "city": "Niz-Barigog"
      },
      {
        "state": "Assam",
        "city": "No.1.Kaplabari"
      },
      {
        "state": "Assam",
        "city": "No.1.Kekan Kuchi"
      },
      {
        "state": "Assam",
        "city": "No.1.Paharpur"
      },
      {
        "state": "Assam",
        "city": "Pakribari"
      },
      {
        "state": "Assam",
        "city": "Patkijuli"
      },
      {
        "state": "Assam",
        "city": "Rampur Agagara"
      },
      {
        "state": "Assam",
        "city": "Sandheli"
      },
      {
        "state": "Assam",
        "city": "Sanekuchi"
      },
      {
        "state": "Assam",
        "city": "Saukuchi"
      },
      {
        "state": "Assam",
        "city": "Suradi"
      },
      {
        "state": "Assam",
        "city": "Tamulpur"
      },
      {
        "state": "Assam",
        "city": "Terechia"
      },
      {
        "state": "Assam",
        "city": "Thamna"
      },
      {
        "state": "Assam",
        "city": "Thanguri"
      },
      {
        "state": "Assam",
        "city": "Tihu"
      },
      {
        "state": "Assam",
        "city": "Ulabari"
      },
      {
        "state": "Assam",
        "city": "Amguri"
      },
      {
        "state": "Assam",
        "city": "Charingia Gaon"
      },
      {
        "state": "Assam",
        "city": "Sivasagar"
      },
      {
        "state": "Assam",
        "city": "Lunpuria"
      },
      {
        "state": "Assam",
        "city": "Mohon Deodhai"
      },
      {
        "state": "Assam",
        "city": "Moranhat Town"
      },
      {
        "state": "Assam",
        "city": "Panchgram"
      },
      {
        "state": "Assam",
        "city": "Phakum Kumar Phodia"
      },
      {
        "state": "Assam",
        "city": "Ranga Pothar"
      },
      {
        "state": "Assam",
        "city": "Sepon Chah Bagicha 107/110 Nlr"
      },
      {
        "state": "Assam",
        "city": "Alisinga"
      },
      {
        "state": "Assam",
        "city": "Bakola"
      },
      {
        "state": "Assam",
        "city": "Balipara"
      },
      {
        "state": "Assam",
        "city": "Bandarmari"
      },
      {
        "state": "Assam",
        "city": "Barangabari"
      },
      {
        "state": "Assam",
        "city": "Bedeti T.E. 318/494"
      },
      {
        "state": "Assam",
        "city": "Behali"
      },
      {
        "state": "Assam",
        "city": "Bihaguri"
      },
      {
        "state": "Assam",
        "city": "Bindukuri T.E."
      },
      {
        "state": "Assam",
        "city": "Biswanath Charali"
      },
      {
        "state": "Assam",
        "city": "Biswanathghat"
      },
      {
        "state": "Assam",
        "city": "Borsola"
      },
      {
        "state": "Assam",
        "city": "Borthekerabari"
      },
      {
        "state": "Assam",
        "city": "Chariali"
      },
      {
        "state": "Assam",
        "city": "Chariduar"
      },
      {
        "state": "Assam",
        "city": "Dekargaon"
      },
      {
        "state": "Assam",
        "city": "Dhekiajuli"
      },
      {
        "state": "Assam",
        "city": "Sonitpur"
      },
      {
        "state": "Assam",
        "city": "Dighali Gaon"
      },
      {
        "state": "Assam",
        "city": "Dihingia Gaon"
      },
      {
        "state": "Assam",
        "city": "Ghoramari"
      },
      {
        "state": "Assam",
        "city": "Gohpur"
      },
      {
        "state": "Assam",
        "city": "Gorbil"
      },
      {
        "state": "Assam",
        "city": "Halem T.E. 270/442 Grant"
      },
      {
        "state": "Assam",
        "city": "Hatibandha"
      },
      {
        "state": "Assam",
        "city": "Jamugurihat"
      },
      {
        "state": "Assam",
        "city": "Kacharigaon"
      },
      {
        "state": "Assam",
        "city": "Ketekibari"
      },
      {
        "state": "Assam",
        "city": "Khalihamari"
      },
      {
        "state": "Assam",
        "city": "Khelmati"
      },
      {
        "state": "Assam",
        "city": "Mijikajan T.E. 281/321"
      },
      {
        "state": "Assam",
        "city": "Mikir Para Chakardoi"
      },
      {
        "state": "Assam",
        "city": "Missamari"
      },
      {
        "state": "Assam",
        "city": "Moa Mari Kachari"
      },
      {
        "state": "Assam",
        "city": "Nag-Sankar Gaon"
      },
      {
        "state": "Assam",
        "city": "Na-Pam Gaon"
      },
      {
        "state": "Assam",
        "city": "No.1 Gorali T.E."
      },
      {
        "state": "Assam",
        "city": "Pabhoi"
      },
      {
        "state": "Assam",
        "city": "Pachim Jinjia"
      },
      {
        "state": "Assam",
        "city": "Ranga Chakua"
      },
      {
        "state": "Assam",
        "city": "Rangamati Gaon"
      },
      {
        "state": "Assam",
        "city": "Rangapara"
      },
      {
        "state": "Assam",
        "city": "Sirajuli"
      },
      {
        "state": "Assam",
        "city": "Sootea"
      },
      {
        "state": "Assam",
        "city": "Tetenbari"
      },
      {
        "state": "Assam",
        "city": "Tezpur"
      },
      {
        "state": "Assam",
        "city": "Thelamara"
      },
      {
        "state": "Assam",
        "city": "South Salamara-Mankachar"
      },
      {
        "state": "Assam",
        "city": "Chapakhowa"
      },
      {
        "state": "Assam",
        "city": "Doom Dooma"
      },
      {
        "state": "Assam",
        "city": "Lakhimi Pathar"
      },
      {
        "state": "Assam",
        "city": "Margherita"
      },
      {
        "state": "Assam",
        "city": "Margherita Kumar Potty"
      },
      {
        "state": "Assam",
        "city": "Sadiya"
      },
      {
        "state": "Assam",
        "city": "Teghakhat"
      },
      {
        "state": "Assam",
        "city": "Tinsukia"
      },
      {
        "state": "Assam",
        "city": "Tipling"
      },
      {
        "state": "Assam",
        "city": "Udalguri"
      },
      {
        "state": "Assam",
        "city": "West Karbi Anglong"
      },
      {
        "state": "Bihar",
        "city": "Araria"
      },
      {
        "state": "Bihar",
        "city": "Arwal"
      },
      {
        "state": "Bihar",
        "city": "Aurangabad"
      },
      {
        "state": "Bihar",
        "city": "Barun"
      },
      {
        "state": "Bihar",
        "city": "Gobardhanpur Kanap"
      },
      {
        "state": "Bihar",
        "city": "Haspura"
      },
      {
        "state": "Bihar",
        "city": "Jamhore"
      },
      {
        "state": "Bihar",
        "city": "Kutumba"
      },
      {
        "state": "Bihar",
        "city": "Nabinagar"
      },
      {
        "state": "Bihar",
        "city": "Obra"
      },
      {
        "state": "Bihar",
        "city": "Banka"
      },
      {
        "state": "Bihar",
        "city": "Bounsi"
      },
      {
        "state": "Bihar",
        "city": "Katoria"
      },
      {
        "state": "Bihar",
        "city": "Kuangarhi"
      },
      {
        "state": "Bihar",
        "city": "Rajaun"
      },
      {
        "state": "Bihar",
        "city": "Rupauli Chausima"
      },
      {
        "state": "Bihar",
        "city": "Simariya"
      },
      {
        "state": "Bihar",
        "city": "Akbarpur Barari"
      },
      {
        "state": "Bihar",
        "city": "Barauni"
      },
      {
        "state": "Bihar",
        "city": "Begusarai"
      },
      {
        "state": "Bihar",
        "city": "Saidpur Salha Barari"
      },
      {
        "state": "Bihar",
        "city": "Samho"
      },
      {
        "state": "Bihar",
        "city": "Teghra"
      },
      {
        "state": "Bihar",
        "city": "Bhagalpur"
      },
      {
        "state": "Bihar",
        "city": "Bihpur"
      },
      {
        "state": "Bihar",
        "city": "Birnackpur"
      },
      {
        "state": "Bihar",
        "city": "Goradeeh"
      },
      {
        "state": "Bihar",
        "city": "Kahalgaon"
      },
      {
        "state": "Bihar",
        "city": "Kharik"
      },
      {
        "state": "Bihar",
        "city": "Maikhand Milik"
      },
      {
        "state": "Bihar",
        "city": "Naugachia"
      },
      {
        "state": "Bihar",
        "city": "Pirpainti"
      },
      {
        "state": "Bihar",
        "city": "Rangra"
      },
      {
        "state": "Bihar",
        "city": "Sihpur"
      },
      {
        "state": "Bihar",
        "city": "Sonahula"
      },
      {
        "state": "Bihar",
        "city": "Agiaon"
      },
      {
        "state": "Bihar",
        "city": "Bhojpur"
      },
      {
        "state": "Bihar",
        "city": "Behea"
      },
      {
        "state": "Bihar",
        "city": "Garhani"
      },
      {
        "state": "Bihar",
        "city": "Koilwar"
      },
      {
        "state": "Bihar",
        "city": "Piro"
      },
      {
        "state": "Bihar",
        "city": "Sandesh"
      },
      {
        "state": "Bihar",
        "city": "Tarari"
      },
      {
        "state": "Bihar",
        "city": "Buxar"
      },
      {
        "state": "Bihar",
        "city": "Dumraon"
      },
      {
        "state": "Bihar",
        "city": "Itarhi"
      },
      {
        "state": "Bihar",
        "city": "Kesath"
      },
      {
        "state": "Bihar",
        "city": "Misraulia"
      },
      {
        "state": "Bihar",
        "city": "Navanagar"
      },
      {
        "state": "Bihar",
        "city": "Simri"
      },
      {
        "state": "Bihar",
        "city": "Biraul"
      },
      {
        "state": "Bihar",
        "city": "Darbhanga"
      },
      {
        "state": "Bihar",
        "city": "Kharka Basant"
      },
      {
        "state": "Bihar",
        "city": "Singhwara"
      },
      {
        "state": "Bihar",
        "city": "Areraj"
      },
      {
        "state": "Bihar",
        "city": "Bankatwa"
      },
      {
        "state": "Bihar",
        "city": "Chakia"
      },
      {
        "state": "Bihar",
        "city": "Chauradano"
      },
      {
        "state": "Bihar",
        "city": "Chiraia"
      },
      {
        "state": "Bihar",
        "city": "Dhaka"
      },
      {
        "state": "Bihar",
        "city": "East Champaran"
      },
      {
        "state": "Bihar",
        "city": "Ghorasahan"
      },
      {
        "state": "Bihar",
        "city": "Harshidhi"
      },
      {
        "state": "Bihar",
        "city": "Kesariya"
      },
      {
        "state": "Bihar",
        "city": "Mehsi"
      },
      {
        "state": "Bihar",
        "city": "Nawada Govindganj"
      },
      {
        "state": "Bihar",
        "city": "Pakridayal"
      },
      {
        "state": "Bihar",
        "city": "Patahi"
      },
      {
        "state": "Bihar",
        "city": "Phenhara"
      },
      {
        "state": "Bihar",
        "city": "Pipra Kothi"
      },
      {
        "state": "Bihar",
        "city": "Ramgarhwa"
      },
      {
        "state": "Bihar",
        "city": "Raxaul"
      },
      {
        "state": "Bihar",
        "city": "Shekhwa Tola"
      },
      {
        "state": "Bihar",
        "city": "Sugauli"
      },
      {
        "state": "Bihar",
        "city": "Tetaria"
      },
      {
        "state": "Bihar",
        "city": "Turkaulia"
      },
      {
        "state": "Bihar",
        "city": "Amas"
      },
      {
        "state": "Bihar",
        "city": "Gaya"
      },
      {
        "state": "Bihar",
        "city": "Bankey Bazar"
      },
      {
        "state": "Bihar",
        "city": "Barachatti"
      },
      {
        "state": "Bihar",
        "city": "Bhagahar"
      },
      {
        "state": "Bihar",
        "city": "Bodhgaya"
      },
      {
        "state": "Bihar",
        "city": "Dobhi"
      },
      {
        "state": "Bihar",
        "city": "Guraru"
      },
      {
        "state": "Bihar",
        "city": "Gurua"
      },
      {
        "state": "Bihar",
        "city": "Imamganj"
      },
      {
        "state": "Bihar",
        "city": "Kalaua Kalan"
      },
      {
        "state": "Bihar",
        "city": "Khizersarai"
      },
      {
        "state": "Bihar",
        "city": "Paraiya"
      },
      {
        "state": "Bihar",
        "city": "Tikari"
      },
      {
        "state": "Bihar",
        "city": "Gopalganj"
      },
      {
        "state": "Bihar",
        "city": "Hathwa"
      },
      {
        "state": "Bihar",
        "city": "Aliganj"
      },
      {
        "state": "Bihar",
        "city": "Barhat"
      },
      {
        "state": "Bihar",
        "city": "Chakai"
      },
      {
        "state": "Bihar",
        "city": "Gidhaur"
      },
      {
        "state": "Bihar",
        "city": "Islam Nagar"
      },
      {
        "state": "Bihar",
        "city": "Jamui"
      },
      {
        "state": "Bihar",
        "city": "Jhajha"
      },
      {
        "state": "Bihar",
        "city": "Khiara"
      },
      {
        "state": "Bihar",
        "city": "Najari"
      },
      {
        "state": "Bihar",
        "city": "Noma"
      },
      {
        "state": "Bihar",
        "city": "Raunakabad"
      },
      {
        "state": "Bihar",
        "city": "Sondiha"
      },
      {
        "state": "Bihar",
        "city": "Jehanabad"
      },
      {
        "state": "Bihar",
        "city": "Faridpur"
      },
      {
        "state": "Bihar",
        "city": "Kako"
      },
      {
        "state": "Bihar",
        "city": "Mahendia"
      },
      {
        "state": "Bihar",
        "city": "Ratnifaridpur"
      },
      {
        "state": "Bihar",
        "city": "Adhaura"
      },
      {
        "state": "Bihar",
        "city": "Bhabua"
      },
      {
        "state": "Bihar",
        "city": "Chainpur"
      },
      {
        "state": "Bihar",
        "city": "Kudra"
      },
      {
        "state": "Bihar",
        "city": "Mohania"
      },
      {
        "state": "Bihar",
        "city": "Kaimur (Bhabua)"
      },
      {
        "state": "Bihar",
        "city": "Nuaon"
      },
      {
        "state": "Bihar",
        "city": "Katihar"
      },
      {
        "state": "Bihar",
        "city": "Beldour"
      },
      {
        "state": "Bihar",
        "city": "Khagaria"
      },
      {
        "state": "Bihar",
        "city": "Kishanganj"
      },
      {
        "state": "Bihar",
        "city": "Brahiya"
      },
      {
        "state": "Bihar",
        "city": "Chamghara"
      },
      {
        "state": "Bihar",
        "city": "Lakhisarai"
      },
      {
        "state": "Bihar",
        "city": "Bihariganj"
      },
      {
        "state": "Bihar",
        "city": "Madhepura"
      },
      {
        "state": "Bihar",
        "city": "Kumarkhand"
      },
      {
        "state": "Bihar",
        "city": "Puraini"
      },
      {
        "state": "Bihar",
        "city": "Sahsaul"
      },
      {
        "state": "Bihar",
        "city": "Singheshwar"
      },
      {
        "state": "Bihar",
        "city": "Udakishanganj"
      },
      {
        "state": "Bihar",
        "city": "Andhra Tharhi"
      },
      {
        "state": "Bihar",
        "city": "Babubarhi"
      },
      {
        "state": "Bihar",
        "city": "Basopatti"
      },
      {
        "state": "Bihar",
        "city": "Benipatti"
      },
      {
        "state": "Bihar",
        "city": "Bharwara"
      },
      {
        "state": "Bihar",
        "city": "Madhubani"
      },
      {
        "state": "Bihar",
        "city": "Ekhatha"
      },
      {
        "state": "Bihar",
        "city": "Ghoghardiha"
      },
      {
        "state": "Bihar",
        "city": "Harlakhi"
      },
      {
        "state": "Bihar",
        "city": "Jaynagar"
      },
      {
        "state": "Bihar",
        "city": "Jhanjharpur"
      },
      {
        "state": "Bihar",
        "city": "Kaluahi"
      },
      {
        "state": "Bihar",
        "city": "Khajauli"
      },
      {
        "state": "Bihar",
        "city": "Khutauna"
      },
      {
        "state": "Bihar",
        "city": "Ladania"
      },
      {
        "state": "Bihar",
        "city": "Laukaha"
      },
      {
        "state": "Bihar",
        "city": "Madhepur"
      },
      {
        "state": "Bihar",
        "city": "Pandaul"
      },
      {
        "state": "Bihar",
        "city": "Phulparas"
      },
      {
        "state": "Bihar",
        "city": "Rajnagar"
      },
      {
        "state": "Bihar",
        "city": "Sadar Madhubani"
      },
      {
        "state": "Bihar",
        "city": "Teoth"
      },
      {
        "state": "Bihar",
        "city": "Asharganj"
      },
      {
        "state": "Bihar",
        "city": "Bariarpur"
      },
      {
        "state": "Bihar",
        "city": "Munger"
      },
      {
        "state": "Bihar",
        "city": "Dharhara"
      },
      {
        "state": "Bihar",
        "city": "Kharagpur"
      },
      {
        "state": "Bihar",
        "city": "Muzaffarpur"
      },
      {
        "state": "Bihar",
        "city": "Bochaha"
      },
      {
        "state": "Bihar",
        "city": "Kan Maniari"
      },
      {
        "state": "Bihar",
        "city": "Maniari"
      },
      {
        "state": "Bihar",
        "city": "Marwan"
      },
      {
        "state": "Bihar",
        "city": "Minapur"
      },
      {
        "state": "Bihar",
        "city": "Motipur"
      },
      {
        "state": "Bihar",
        "city": "Muradpur Bharath"
      },
      {
        "state": "Bihar",
        "city": "Paroo"
      },
      {
        "state": "Bihar",
        "city": "Sadikpur Moraul"
      },
      {
        "state": "Bihar",
        "city": "Sahebganj"
      },
      {
        "state": "Bihar",
        "city": "Saraiya"
      },
      {
        "state": "Bihar",
        "city": "Bathani"
      },
      {
        "state": "Bihar",
        "city": "Ekangar Sarai"
      },
      {
        "state": "Bihar",
        "city": "Harnaut"
      },
      {
        "state": "Bihar",
        "city": "Hilsa"
      },
      {
        "state": "Bihar",
        "city": "Hulashganj"
      },
      {
        "state": "Bihar",
        "city": "Nagar Nausa"
      },
      {
        "state": "Bihar",
        "city": "Nalanda"
      },
      {
        "state": "Bihar",
        "city": "Noorsarai"
      },
      {
        "state": "Bihar",
        "city": "Parwalpur"
      },
      {
        "state": "Bihar",
        "city": "Rahui"
      },
      {
        "state": "Bihar",
        "city": "Rajgir"
      },
      {
        "state": "Bihar",
        "city": "Sarmera"
      },
      {
        "state": "Bihar",
        "city": "Silao"
      },
      {
        "state": "Bihar",
        "city": "Sugia"
      },
      {
        "state": "Bihar",
        "city": "Telhara"
      },
      {
        "state": "Bihar",
        "city": "Tharthari"
      },
      {
        "state": "Bihar",
        "city": "Gobindpur Urf Qanungo Bigha"
      },
      {
        "state": "Bihar",
        "city": "Hisua"
      },
      {
        "state": "Bihar",
        "city": "Kashi Chak"
      },
      {
        "state": "Bihar",
        "city": "Kawakol"
      },
      {
        "state": "Bihar",
        "city": "Meskaur"
      },
      {
        "state": "Bihar",
        "city": "Nardiganj"
      },
      {
        "state": "Bihar",
        "city": "Narhat"
      },
      {
        "state": "Bihar",
        "city": "Nawada"
      },
      {
        "state": "Bihar",
        "city": "Pakribarwan"
      },
      {
        "state": "Bihar",
        "city": "Rajauli"
      },
      {
        "state": "Bihar",
        "city": "Sirdala"
      },
      {
        "state": "Bihar",
        "city": "Warisaliganj"
      },
      {
        "state": "Bihar",
        "city": "Athmal Gola"
      },
      {
        "state": "Bihar",
        "city": "Bikram"
      },
      {
        "state": "Bihar",
        "city": "Danapur"
      },
      {
        "state": "Bihar",
        "city": "Daniawan"
      },
      {
        "state": "Bihar",
        "city": "Dhanarua"
      },
      {
        "state": "Bihar",
        "city": "Dulhin Bazar"
      },
      {
        "state": "Bihar",
        "city": "Fatuha"
      },
      {
        "state": "Bihar",
        "city": "Ghoswari"
      },
      {
        "state": "Bihar",
        "city": "Khusrupur"
      },
      {
        "state": "Bihar",
        "city": "Patna"
      },
      {
        "state": "Bihar",
        "city": "Maner"
      },
      {
        "state": "Bihar",
        "city": "Masaurhi"
      },
      {
        "state": "Bihar",
        "city": "Naubatpur"
      },
      {
        "state": "Bihar",
        "city": "Phulwari"
      },
      {
        "state": "Bihar",
        "city": "Pundarakh"
      },
      {
        "state": "Bihar",
        "city": "Purnea"
      },
      {
        "state": "Bihar",
        "city": "Baisi"
      },
      {
        "state": "Bihar",
        "city": "Banmankhi"
      },
      {
        "state": "Bihar",
        "city": "Parora"
      },
      {
        "state": "Bihar",
        "city": "Akorhi"
      },
      {
        "state": "Bihar",
        "city": "Akorhi Gola"
      },
      {
        "state": "Bihar",
        "city": "Bikramganj"
      },
      {
        "state": "Bihar",
        "city": "Dawath"
      },
      {
        "state": "Bihar",
        "city": "Dehri"
      },
      {
        "state": "Bihar",
        "city": "Dinara"
      },
      {
        "state": "Bihar",
        "city": "Garh Nokha"
      },
      {
        "state": "Bihar",
        "city": "Karakat"
      },
      {
        "state": "Bihar",
        "city": "Kargahar"
      },
      {
        "state": "Bihar",
        "city": "Kochas"
      },
      {
        "state": "Bihar",
        "city": "Lahuara"
      },
      {
        "state": "Bihar",
        "city": "Maudiha"
      },
      {
        "state": "Bihar",
        "city": "Rohtas"
      },
      {
        "state": "Bihar",
        "city": "Nasriganj"
      },
      {
        "state": "Bihar",
        "city": "Sanjhauli"
      },
      {
        "state": "Bihar",
        "city": "Sasaram"
      },
      {
        "state": "Bihar",
        "city": "Tilauthu"
      },
      {
        "state": "Bihar",
        "city": "Bela Dih"
      },
      {
        "state": "Bihar",
        "city": "Saharsa"
      },
      {
        "state": "Bihar",
        "city": "Khanarda"
      },
      {
        "state": "Bihar",
        "city": "Mahishi"
      },
      {
        "state": "Bihar",
        "city": "Patarghat"
      },
      {
        "state": "Bihar",
        "city": "Salkhua"
      },
      {
        "state": "Bihar",
        "city": "Satar Kataiya"
      },
      {
        "state": "Bihar",
        "city": "Simri Bakhtiarpur"
      },
      {
        "state": "Bihar",
        "city": "Bibhutipur"
      },
      {
        "state": "Bihar",
        "city": "Dalsingh Sarai"
      },
      {
        "state": "Bihar",
        "city": "Samastipur"
      },
      {
        "state": "Bihar",
        "city": "Mohiuddin Nagar"
      },
      {
        "state": "Bihar",
        "city": "Patori"
      },
      {
        "state": "Bihar",
        "city": "Rosera"
      },
      {
        "state": "Bihar",
        "city": "Alahdadpur"
      },
      {
        "state": "Bihar",
        "city": "Amnour"
      },
      {
        "state": "Bihar",
        "city": "Bansi Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Bhagwani Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Bodha Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Dighwara"
      },
      {
        "state": "Bihar",
        "city": "Ekma"
      },
      {
        "state": "Bihar",
        "city": "Garkha"
      },
      {
        "state": "Bihar",
        "city": "Ishupur"
      },
      {
        "state": "Bihar",
        "city": "Jalalpur"
      },
      {
        "state": "Bihar",
        "city": "Kanth Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Khararahia"
      },
      {
        "state": "Bihar",
        "city": "Lahladpur"
      },
      {
        "state": "Bihar",
        "city": "Madan Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Mahesia"
      },
      {
        "state": "Bihar",
        "city": "Maker"
      },
      {
        "state": "Bihar",
        "city": "Marhowrah"
      },
      {
        "state": "Bihar",
        "city": "Mashrakh"
      },
      {
        "state": "Bihar",
        "city": "Saran"
      },
      {
        "state": "Bihar",
        "city": "Pakri"
      },
      {
        "state": "Bihar",
        "city": "Panapur"
      },
      {
        "state": "Bihar",
        "city": "Parsa"
      },
      {
        "state": "Bihar",
        "city": "Revelganj"
      },
      {
        "state": "Bihar",
        "city": "shilowri"
      },
      {
        "state": "Bihar",
        "city": "Sobhan Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Taraiya"
      },
      {
        "state": "Bihar",
        "city": "Tekniwas"
      },
      {
        "state": "Bihar",
        "city": "Ariari"
      },
      {
        "state": "Bihar",
        "city": "Ariyari"
      },
      {
        "state": "Bihar",
        "city": "Hathiyawan"
      },
      {
        "state": "Bihar",
        "city": "Sheikhpura"
      },
      {
        "state": "Bihar",
        "city": "Mehus"
      },
      {
        "state": "Bihar",
        "city": "Shekhopur"
      },
      {
        "state": "Bihar",
        "city": "Pota urf Tajpur Phaksia Bharthi"
      },
      {
        "state": "Bihar",
        "city": "Sheohar"
      },
      {
        "state": "Bihar",
        "city": "Bairgania"
      },
      {
        "state": "Bihar",
        "city": "Bathnaha"
      },
      {
        "state": "Bihar",
        "city": "Bazpatti"
      },
      {
        "state": "Bihar",
        "city": "Bishunpur Dhodhni"
      },
      {
        "state": "Bihar",
        "city": "Bokhra"
      },
      {
        "state": "Bihar",
        "city": "Choraut"
      },
      {
        "state": "Bihar",
        "city": "Sitamarhi"
      },
      {
        "state": "Bihar",
        "city": "Katraul"
      },
      {
        "state": "Bihar",
        "city": "Kuari Madan"
      },
      {
        "state": "Bihar",
        "city": "Nanpur"
      },
      {
        "state": "Bihar",
        "city": "Parihar"
      },
      {
        "state": "Bihar",
        "city": "Riga"
      },
      {
        "state": "Bihar",
        "city": "Runi Saidpur"
      },
      {
        "state": "Bihar",
        "city": "Sursand"
      },
      {
        "state": "Bihar",
        "city": "Andar"
      },
      {
        "state": "Bihar",
        "city": "Duraundha"
      },
      {
        "state": "Bihar",
        "city": "Siwan"
      },
      {
        "state": "Bihar",
        "city": "Guthani"
      },
      {
        "state": "Bihar",
        "city": "Hasanpura"
      },
      {
        "state": "Bihar",
        "city": "Lakat Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Mairwa"
      },
      {
        "state": "Bihar",
        "city": "Nabiganj"
      },
      {
        "state": "Bihar",
        "city": "Sembhu Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Ziradei"
      },
      {
        "state": "Bihar",
        "city": "Supaul"
      },
      {
        "state": "Bihar",
        "city": "Birpur"
      },
      {
        "state": "Bihar",
        "city": "Chhatapur"
      },
      {
        "state": "Bihar",
        "city": "Derbandhe"
      },
      {
        "state": "Bihar",
        "city": "Koriapatti"
      },
      {
        "state": "Bihar",
        "city": "Marouna"
      },
      {
        "state": "Bihar",
        "city": "Pratapganj"
      },
      {
        "state": "Bihar",
        "city": "Saraigarh"
      },
      {
        "state": "Bihar",
        "city": "Triveniganj"
      },
      {
        "state": "Bihar",
        "city": "Belsar"
      },
      {
        "state": "Bihar",
        "city": "Bharhan"
      },
      {
        "state": "Bihar",
        "city": "Bidupur"
      },
      {
        "state": "Bihar",
        "city": "Chehra Kalan Urf Mohammadpur"
      },
      {
        "state": "Bihar",
        "city": "Desari"
      },
      {
        "state": "Bihar",
        "city": "Goraul"
      },
      {
        "state": "Bihar",
        "city": "Goraul Bhagwanpur"
      },
      {
        "state": "Bihar",
        "city": "Hajipur"
      },
      {
        "state": "Bihar",
        "city": "Hasanpur Buzurg"
      },
      {
        "state": "Bihar",
        "city": "Hazrat Jandaha"
      },
      {
        "state": "Bihar",
        "city": "Kiratpur Raja Ram"
      },
      {
        "state": "Bihar",
        "city": "Lalganj"
      },
      {
        "state": "Bihar",
        "city": "Mahnar"
      },
      {
        "state": "Bihar",
        "city": "Vaishali"
      },
      {
        "state": "Bihar",
        "city": "Patepur"
      },
      {
        "state": "Bihar",
        "city": "Rajapakar"
      },
      {
        "state": "Bihar",
        "city": "Sahdai Buzurg"
      },
      {
        "state": "Bihar",
        "city": "Surahatha Dharampur"
      },
      {
        "state": "Bihar",
        "city": "Bagaha"
      },
      {
        "state": "Bihar",
        "city": "Bagaha-1"
      },
      {
        "state": "Bihar",
        "city": "Bettiah"
      },
      {
        "state": "Chandigarh",
        "city": "Chandigarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Balod"
      },
      {
        "state": "Chhattisgarh",
        "city": "Baloda Bazar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Balrampur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bastar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jagdalpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Keshkal"
      },
      {
        "state": "Chhattisgarh",
        "city": "Keskal"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bemetera"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhairamgarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bijapur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pandewar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Belgahna"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bilha"
      },
      {
        "state": "Chhattisgarh",
        "city": "Ganiyari"
      },
      {
        "state": "Chhattisgarh",
        "city": "Hirri Mines"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jairamnagar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bilaspur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kargi Road"
      },
      {
        "state": "Chhattisgarh",
        "city": "Lormi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Masturi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pendra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Ratanpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sipat"
      },
      {
        "state": "Chhattisgarh",
        "city": "Takhatpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Waidhan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dantewada"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dhamtari"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kurud"
      },
      {
        "state": "Chhattisgarh",
        "city": "Nagri"
      },
      {
        "state": "Chhattisgarh",
        "city": "Rudri"
      },
      {
        "state": "Chhattisgarh",
        "city": "Awari"
      },
      {
        "state": "Chhattisgarh",
        "city": "Durg"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhilai"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dhamdha"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dondi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dondi Awari"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dondi Lohara"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gunderdehi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Nandghat"
      },
      {
        "state": "Chhattisgarh",
        "city": "Nawagarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Patah"
      },
      {
        "state": "Chhattisgarh",
        "city": "Saja"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sanjaribalod"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gariaband"
      },
      {
        "state": "Chhattisgarh",
        "city": "Adbhar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Akaltara"
      },
      {
        "state": "Chhattisgarh",
        "city": "Janjgir-Champa"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dabhara"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gopalnagar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Janjgir"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kharod"
      },
      {
        "state": "Chhattisgarh",
        "city": "Nariyara"
      },
      {
        "state": "Chhattisgarh",
        "city": "Naya Baradwar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pamgarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Parsada"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sakti"
      },
      {
        "state": "Chhattisgarh",
        "city": "Shivrinarayan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bagicha"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jashpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jashpurnagar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pathalgaon"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pandaria"
      },
      {
        "state": "Chhattisgarh",
        "city": "Antagarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhanupratappur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Charama"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kanker"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pakhanjore"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kondagaon"
      },
      {
        "state": "Chhattisgarh",
        "city": "Balconagar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bamhni Bazar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bango"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bankimongra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhaisma"
      },
      {
        "state": "Chhattisgarh",
        "city": "Korba"
      },
      {
        "state": "Chhattisgarh",
        "city": "Hardi Bazar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jaijaipur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jamnipali"
      },
      {
        "state": "Chhattisgarh",
        "city": "Katghora"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kusmunda"
      },
      {
        "state": "Chhattisgarh",
        "city": "Manikpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Baikunthpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Chirimiri"
      },
      {
        "state": "Chhattisgarh",
        "city": "Janakpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Koriya"
      },
      {
        "state": "Chhattisgarh",
        "city": "Manendragarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bagbahara"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bsna"
      },
      {
        "state": "Chhattisgarh",
        "city": "Mahasamund"
      },
      {
        "state": "Chhattisgarh",
        "city": "Deori"
      },
      {
        "state": "Chhattisgarh",
        "city": "Hathibahra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Komakhan of Mahasa"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kaskera"
      },
      {
        "state": "Chhattisgarh",
        "city": "Narra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Salhebhata"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pithora"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sankra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Saraipali"
      },
      {
        "state": "Chhattisgarh",
        "city": "Mungeli"
      },
      {
        "state": "Chhattisgarh",
        "city": "Narayanpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dharmajaigarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gharghoda"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kharsia"
      },
      {
        "state": "Chhattisgarh",
        "city": "Raigarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Saria"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sarnagarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Tamnar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Abhanpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Baikunth"
      },
      {
        "state": "Chhattisgarh",
        "city": "Raipur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Barauda"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bilaigarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bindranawagarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Birgaon"
      },
      {
        "state": "Chhattisgarh",
        "city": "Chhura"
      },
      {
        "state": "Chhattisgarh",
        "city": "Deobhog"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dharsiwa"
      },
      {
        "state": "Chhattisgarh",
        "city": "Fingeshwar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Hathbandh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Hirmi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kasdol"
      },
      {
        "state": "Chhattisgarh",
        "city": "Katgi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kharora"
      },
      {
        "state": "Chhattisgarh",
        "city": "Lawan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Mana Camp"
      },
      {
        "state": "Chhattisgarh",
        "city": "Manddar Cement Factory"
      },
      {
        "state": "Chhattisgarh",
        "city": "Mandir Hasod"
      },
      {
        "state": "Chhattisgarh",
        "city": "Neora"
      },
      {
        "state": "Chhattisgarh",
        "city": "Palari"
      },
      {
        "state": "Chhattisgarh",
        "city": "Panduka"
      },
      {
        "state": "Chhattisgarh",
        "city": "Phingeshwar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Rajim"
      },
      {
        "state": "Chhattisgarh",
        "city": "Rawan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sarsiwa"
      },
      {
        "state": "Chhattisgarh",
        "city": "Simga"
      },
      {
        "state": "Chhattisgarh",
        "city": "Tilda Newra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Watgan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Ambagarh Chowki"
      },
      {
        "state": "Chhattisgarh",
        "city": "Chhuikhadan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Chhuriya"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dongargaon"
      },
      {
        "state": "Chhattisgarh",
        "city": "Rajnandgaon"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gandai Pandaria"
      },
      {
        "state": "Chhattisgarh",
        "city": "Khairagarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kumarra Chhuriya"
      },
      {
        "state": "Chhattisgarh",
        "city": "Mohala"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sukma"
      },
      {
        "state": "Chhattisgarh",
        "city": "Surajpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Amatoli"
      },
      {
        "state": "Chhattisgarh",
        "city": "Ambikapur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Surguja"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhaiyathan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kosanga"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kusmi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Lakhanpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pratppur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Ramanuj Ganj"
      },
      {
        "state": "Dadra And Nagar Haveli",
        "city": "Dadra and Nagar Haveli"
      },
      {
        "state": "Dadra And Nagar Haveli",
        "city": "Dharampur"
      },
      {
        "state": "Dadra And Nagar Haveli",
        "city": "Killa-pardi"
      },
      {
        "state": "Dadra And Nagar Haveli",
        "city": "Silvassa"
      },
      {
        "state": "Daman And Diu",
        "city": "Daman"
      },
      {
        "state": "Daman And Diu",
        "city": "Diu"
      },
      {
        "state": "Delhi",
        "city": "Central Delhi"
      },
      {
        "state": "Delhi",
        "city": "East Delhi"
      },
      {
        "state": "Delhi",
        "city": "New Delhi"
      },
      {
        "state": "Delhi",
        "city": "North Delhi"
      },
      {
        "state": "Delhi",
        "city": "North East Delhi"
      },
      {
        "state": "Delhi",
        "city": "North West Delhi"
      },
      {
        "state": "Delhi",
        "city": "Shahdara"
      },
      {
        "state": "Delhi",
        "city": "South Delhi"
      },
      {
        "state": "Delhi",
        "city": "South East Delhi"
      },
      {
        "state": "Delhi",
        "city": "South West Delhi"
      },
      {
        "state": "Delhi",
        "city": "West Delhi"
      },
      {
        "state": "Goa",
        "city": "Aradi Socorro"
      },
      {
        "state": "Goa",
        "city": "Bardez"
      },
      {
        "state": "Goa",
        "city": "Bicholim"
      },
      {
        "state": "Goa",
        "city": "Mapusa"
      },
      {
        "state": "Goa",
        "city": "Panjim"
      },
      {
        "state": "Goa",
        "city": "Pernem"
      },
      {
        "state": "Goa",
        "city": "Salvador do Mundo"
      },
      {
        "state": "Goa",
        "city": "Satari"
      },
      {
        "state": "Goa",
        "city": "Canacona"
      },
      {
        "state": "Goa",
        "city": "Curchorem"
      },
      {
        "state": "Goa",
        "city": "Mormugao"
      },
      {
        "state": "Goa",
        "city": "Quepem"
      },
      {
        "state": "Goa",
        "city": "Salcete"
      },
      {
        "state": "Goa",
        "city": "Sanguem"
      },
      {
        "state": "Goa",
        "city": "Sanquelim"
      },
      {
        "state": "Goa",
        "city": "Vodlemol Cacora"
      },
      {
        "state": "Gujarat",
        "city": "Ahmedabad"
      },
      {
        "state": "Gujarat",
        "city": "Barwala"
      },
      {
        "state": "Gujarat",
        "city": "Bavla"
      },
      {
        "state": "Gujarat",
        "city": "Daskroi"
      },
      {
        "state": "Gujarat",
        "city": "Detroj"
      },
      {
        "state": "Gujarat",
        "city": "Dholka"
      },
      {
        "state": "Gujarat",
        "city": "Mandal"
      },
      {
        "state": "Gujarat",
        "city": "Sanand"
      },
      {
        "state": "Gujarat",
        "city": "Viramgam"
      },
      {
        "state": "Gujarat",
        "city": "Amreli"
      },
      {
        "state": "Gujarat",
        "city": "Babra"
      },
      {
        "state": "Gujarat",
        "city": "Bagasara"
      },
      {
        "state": "Gujarat",
        "city": "Dhari"
      },
      {
        "state": "Gujarat",
        "city": "Jafrabad"
      },
      {
        "state": "Gujarat",
        "city": "Khambha"
      },
      {
        "state": "Gujarat",
        "city": "Lathi"
      },
      {
        "state": "Gujarat",
        "city": "Liliya"
      },
      {
        "state": "Gujarat",
        "city": "Rajula"
      },
      {
        "state": "Gujarat",
        "city": "Anand"
      },
      {
        "state": "Gujarat",
        "city": "Anklav"
      },
      {
        "state": "Gujarat",
        "city": "Dakor"
      },
      {
        "state": "Gujarat",
        "city": "Khambhat"
      },
      {
        "state": "Gujarat",
        "city": "Nar"
      },
      {
        "state": "Gujarat",
        "city": "Unreth"
      },
      {
        "state": "Gujarat",
        "city": "Aravalli"
      },
      {
        "state": "Gujarat",
        "city": "Ambaji"
      },
      {
        "state": "Gujarat",
        "city": "Amirgadh"
      },
      {
        "state": "Gujarat",
        "city": "Bhabhar"
      },
      {
        "state": "Gujarat",
        "city": "Danta"
      },
      {
        "state": "Gujarat",
        "city": "Dantiwada"
      },
      {
        "state": "Gujarat",
        "city": "Deesa"
      },
      {
        "state": "Gujarat",
        "city": "Deodar"
      },
      {
        "state": "Gujarat",
        "city": "Dhanera"
      },
      {
        "state": "Gujarat",
        "city": "Disa"
      },
      {
        "state": "Gujarat",
        "city": "Diyodar"
      },
      {
        "state": "Gujarat",
        "city": "Mewada"
      },
      {
        "state": "Gujarat",
        "city": "Palanpur"
      },
      {
        "state": "Gujarat",
        "city": "Satsan"
      },
      {
        "state": "Gujarat",
        "city": "Shihori"
      },
      {
        "state": "Gujarat",
        "city": "Shri Amirgadh"
      },
      {
        "state": "Gujarat",
        "city": "Tharad"
      },
      {
        "state": "Gujarat",
        "city": "Vadagam"
      },
      {
        "state": "Gujarat",
        "city": "Vadgam"
      },
      {
        "state": "Gujarat",
        "city": "Vav"
      },
      {
        "state": "Gujarat",
        "city": "Bharuch"
      },
      {
        "state": "Gujarat",
        "city": "Dahej"
      },
      {
        "state": "Gujarat",
        "city": "Vagara"
      },
      {
        "state": "Gujarat",
        "city": "Bhavnagar"
      },
      {
        "state": "Gujarat",
        "city": "Dhasa"
      },
      {
        "state": "Gujarat",
        "city": "Gadhada"
      },
      {
        "state": "Gujarat",
        "city": "Gariyadhar"
      },
      {
        "state": "Gujarat",
        "city": "Ghogha"
      },
      {
        "state": "Gujarat",
        "city": "Jesar"
      },
      {
        "state": "Gujarat",
        "city": "Palitana"
      },
      {
        "state": "Gujarat",
        "city": "Sihor"
      },
      {
        "state": "Gujarat",
        "city": "Talaja"
      },
      {
        "state": "Gujarat",
        "city": "Umrala"
      },
      {
        "state": "Gujarat",
        "city": "Vallabhipur"
      },
      {
        "state": "Gujarat",
        "city": "Botad"
      },
      {
        "state": "Gujarat",
        "city": "Chhota Udepur"
      },
      {
        "state": "Gujarat",
        "city": "Dahod"
      },
      {
        "state": "Gujarat",
        "city": "Dohad"
      },
      {
        "state": "Gujarat",
        "city": "Fatepura"
      },
      {
        "state": "Gujarat",
        "city": "Garbada"
      },
      {
        "state": "Gujarat",
        "city": "Jahlod"
      },
      {
        "state": "Gujarat",
        "city": "Jhalod"
      },
      {
        "state": "Gujarat",
        "city": "Limkheda"
      },
      {
        "state": "Gujarat",
        "city": "Dangs (Ahwa)"
      },
      {
        "state": "Gujarat",
        "city": "Devbhoomi Dwarka"
      },
      {
        "state": "Gujarat",
        "city": "Gandhinagar"
      },
      {
        "state": "Gujarat",
        "city": "Gir Somnath"
      },
      {
        "state": "Gujarat",
        "city": "Bhatiya"
      },
      {
        "state": "Gujarat",
        "city": "Jamkalyanpur"
      },
      {
        "state": "Gujarat",
        "city": "Jamnagar"
      },
      {
        "state": "Gujarat",
        "city": "Jodiya"
      },
      {
        "state": "Gujarat",
        "city": "Kahbhalia"
      },
      {
        "state": "Gujarat",
        "city": "Kalawad Simani"
      },
      {
        "state": "Gujarat",
        "city": "Kalyanpur"
      },
      {
        "state": "Gujarat",
        "city": "Khambhalia"
      },
      {
        "state": "Gujarat",
        "city": "Lalpur"
      },
      {
        "state": "Gujarat",
        "city": "Morana"
      },
      {
        "state": "Gujarat",
        "city": "Okha"
      },
      {
        "state": "Gujarat",
        "city": "Okhamandal"
      },
      {
        "state": "Gujarat",
        "city": "Bhesan"
      },
      {
        "state": "Gujarat",
        "city": "Jetpur Navagadh"
      },
      {
        "state": "Gujarat",
        "city": "Junagadh"
      },
      {
        "state": "Gujarat",
        "city": "Keshod"
      },
      {
        "state": "Gujarat",
        "city": "Kodinar"
      },
      {
        "state": "Gujarat",
        "city": "Mendarda"
      },
      {
        "state": "Gujarat",
        "city": "Sutrapada"
      },
      {
        "state": "Gujarat",
        "city": "Vadal"
      },
      {
        "state": "Gujarat",
        "city": "Vanthli"
      },
      {
        "state": "Gujarat",
        "city": "Veraval"
      },
      {
        "state": "Gujarat",
        "city": "Anjar"
      },
      {
        "state": "Gujarat",
        "city": "Bhachau"
      },
      {
        "state": "Gujarat",
        "city": "Bhuj"
      },
      {
        "state": "Gujarat",
        "city": "Gandhidham"
      },
      {
        "state": "Gujarat",
        "city": "Kachchh"
      },
      {
        "state": "Gujarat",
        "city": "Kothara"
      },
      {
        "state": "Gujarat",
        "city": "Lakhpat"
      },
      {
        "state": "Gujarat",
        "city": "Mandvi Rural"
      },
      {
        "state": "Gujarat",
        "city": "Mundra"
      },
      {
        "state": "Gujarat",
        "city": "Nakhatrana"
      },
      {
        "state": "Gujarat",
        "city": "Naliya"
      },
      {
        "state": "Gujarat",
        "city": "Naranpar"
      },
      {
        "state": "Gujarat",
        "city": "Rapar"
      },
      {
        "state": "Gujarat",
        "city": "Kheda"
      },
      {
        "state": "Gujarat",
        "city": "Balasinor"
      },
      {
        "state": "Gujarat",
        "city": "Birpur"
      },
      {
        "state": "Gujarat",
        "city": "Jetholi"
      },
      {
        "state": "Gujarat",
        "city": "Kapadvanj"
      },
      {
        "state": "Gujarat",
        "city": "Kathlal"
      },
      {
        "state": "Gujarat",
        "city": "Mahudha"
      },
      {
        "state": "Gujarat",
        "city": "Mehmedabad"
      },
      {
        "state": "Gujarat",
        "city": "Nadiad"
      },
      {
        "state": "Gujarat",
        "city": "Mahisagar"
      },
      {
        "state": "Gujarat",
        "city": "Becharaji"
      },
      {
        "state": "Gujarat",
        "city": "Dharoi"
      },
      {
        "state": "Gujarat",
        "city": "Kheralu"
      },
      {
        "state": "Gujarat",
        "city": "Mehsana"
      },
      {
        "state": "Gujarat",
        "city": "Satlasana"
      },
      {
        "state": "Gujarat",
        "city": "Vadnagar"
      },
      {
        "state": "Gujarat",
        "city": "Vijapur"
      },
      {
        "state": "Gujarat",
        "city": "Visnagar"
      },
      {
        "state": "Gujarat",
        "city": "Morbi"
      },
      {
        "state": "Gujarat",
        "city": "Narmada (Rajpipla)"
      },
      {
        "state": "Gujarat",
        "city": "Pratappara"
      },
      {
        "state": "Gujarat",
        "city": "Rusela"
      },
      {
        "state": "Gujarat",
        "city": "Tilakvada"
      },
      {
        "state": "Gujarat",
        "city": "Bansda"
      },
      {
        "state": "Gujarat",
        "city": "Bilimora"
      },
      {
        "state": "Gujarat",
        "city": "Chikhli"
      },
      {
        "state": "Gujarat",
        "city": "Gandevi"
      },
      {
        "state": "Gujarat",
        "city": "Jalalpore"
      },
      {
        "state": "Gujarat",
        "city": "Navsari"
      },
      {
        "state": "Gujarat",
        "city": "Panchmahal (Godhra)"
      },
      {
        "state": "Gujarat",
        "city": "Babri"
      },
      {
        "state": "Gujarat",
        "city": "Ghoghamba"
      },
      {
        "state": "Gujarat",
        "city": "Godhra"
      },
      {
        "state": "Gujarat",
        "city": "Halol"
      },
      {
        "state": "Gujarat",
        "city": "Jambughoda"
      },
      {
        "state": "Gujarat",
        "city": "Kadana"
      },
      {
        "state": "Gujarat",
        "city": "Khanpur"
      },
      {
        "state": "Gujarat",
        "city": "Morva Hadaf"
      },
      {
        "state": "Gujarat",
        "city": "Panch Mahudi"
      },
      {
        "state": "Gujarat",
        "city": "Shehera"
      },
      {
        "state": "Gujarat",
        "city": "Chanasma"
      },
      {
        "state": "Gujarat",
        "city": "Patan"
      },
      {
        "state": "Gujarat",
        "city": "Radhanpur"
      },
      {
        "state": "Gujarat",
        "city": "Sami"
      },
      {
        "state": "Gujarat",
        "city": "Santalpur"
      },
      {
        "state": "Gujarat",
        "city": "Siddhpur"
      },
      {
        "state": "Gujarat",
        "city": "Porbandar"
      },
      {
        "state": "Gujarat",
        "city": "Ranavav"
      },
      {
        "state": "Gujarat",
        "city": "Dahisara"
      },
      {
        "state": "Gujarat",
        "city": "Gondal"
      },
      {
        "state": "Gujarat",
        "city": "Jamkandorana"
      },
      {
        "state": "Gujarat",
        "city": "Rajkot"
      },
      {
        "state": "Gujarat",
        "city": "Jasdan"
      },
      {
        "state": "Gujarat",
        "city": "Jetpur"
      },
      {
        "state": "Gujarat",
        "city": "Kamar Kotda"
      },
      {
        "state": "Gujarat",
        "city": "Khajurada"
      },
      {
        "state": "Gujarat",
        "city": "Khirasara"
      },
      {
        "state": "Gujarat",
        "city": "Kotda Sangani"
      },
      {
        "state": "Gujarat",
        "city": "Lodhika"
      },
      {
        "state": "Gujarat",
        "city": "Morvi"
      },
      {
        "state": "Gujarat",
        "city": "Mota Dahisara"
      },
      {
        "state": "Gujarat",
        "city": "Padadhari"
      },
      {
        "state": "Gujarat",
        "city": "Sardhar"
      },
      {
        "state": "Gujarat",
        "city": "Tankara"
      },
      {
        "state": "Gujarat",
        "city": "Wankaner"
      },
      {
        "state": "Gujarat",
        "city": "Abdasan"
      },
      {
        "state": "Gujarat",
        "city": "Sabarkantha (Himmatnagar)"
      },
      {
        "state": "Gujarat",
        "city": "Bahecharpura"
      },
      {
        "state": "Gujarat",
        "city": "Bayad"
      },
      {
        "state": "Gujarat",
        "city": "Bhiloda"
      },
      {
        "state": "Gujarat",
        "city": "Demai"
      },
      {
        "state": "Gujarat",
        "city": "Dhansura"
      },
      {
        "state": "Gujarat",
        "city": "Himatnagar"
      },
      {
        "state": "Gujarat",
        "city": "Idar"
      },
      {
        "state": "Gujarat",
        "city": "Khedbrahma"
      },
      {
        "state": "Gujarat",
        "city": "Meghraj"
      },
      {
        "state": "Gujarat",
        "city": "Prantij"
      },
      {
        "state": "Gujarat",
        "city": "Sathamba"
      },
      {
        "state": "Gujarat",
        "city": "Talod"
      },
      {
        "state": "Gujarat",
        "city": "Vadali"
      },
      {
        "state": "Gujarat",
        "city": "Vijaynagar"
      },
      {
        "state": "Gujarat",
        "city": "Bardoli"
      },
      {
        "state": "Gujarat",
        "city": "Choryasi"
      },
      {
        "state": "Gujarat",
        "city": "Surat"
      },
      {
        "state": "Gujarat",
        "city": "Lajpur"
      },
      {
        "state": "Gujarat",
        "city": "Makrej"
      },
      {
        "state": "Gujarat",
        "city": "Mota Miya Mangrol"
      },
      {
        "state": "Gujarat",
        "city": "Nizar"
      },
      {
        "state": "Gujarat",
        "city": "Palsana"
      },
      {
        "state": "Gujarat",
        "city": "Sayan"
      },
      {
        "state": "Gujarat",
        "city": "Udhna"
      },
      {
        "state": "Gujarat",
        "city": "Umarpada"
      },
      {
        "state": "Gujarat",
        "city": "Vankal"
      },
      {
        "state": "Gujarat",
        "city": "Variav"
      },
      {
        "state": "Gujarat",
        "city": "Velachha"
      },
      {
        "state": "Gujarat",
        "city": "Zankhvav"
      },
      {
        "state": "Gujarat",
        "city": "Surendranagar"
      },
      {
        "state": "Gujarat",
        "city": "Chotila"
      },
      {
        "state": "Gujarat",
        "city": "Dasada"
      },
      {
        "state": "Gujarat",
        "city": "Dhrangadhra"
      },
      {
        "state": "Gujarat",
        "city": "Halvad"
      },
      {
        "state": "Gujarat",
        "city": "Lakhatar"
      },
      {
        "state": "Gujarat",
        "city": "Limbdi"
      },
      {
        "state": "Gujarat",
        "city": "Muli"
      },
      {
        "state": "Gujarat",
        "city": "Ajabpura"
      },
      {
        "state": "Gujarat",
        "city": "Vadodara"
      },
      {
        "state": "Gujarat",
        "city": "Bodeli"
      },
      {
        "state": "Gujarat",
        "city": "Karjan"
      },
      {
        "state": "Gujarat",
        "city": "Kavant"
      },
      {
        "state": "Gujarat",
        "city": "Nasvadi"
      },
      {
        "state": "Gujarat",
        "city": "Navapara"
      },
      {
        "state": "Gujarat",
        "city": "Padra"
      },
      {
        "state": "Gujarat",
        "city": "Pavijetpur"
      },
      {
        "state": "Gujarat",
        "city": "Sankheda"
      },
      {
        "state": "Gujarat",
        "city": "Savli"
      },
      {
        "state": "Gujarat",
        "city": "Sinor"
      },
      {
        "state": "Gujarat",
        "city": "Vaghodia"
      },
      {
        "state": "Gujarat",
        "city": "Vaghodia INA"
      },
      {
        "state": "Gujarat",
        "city": "Dharampur"
      },
      {
        "state": "Gujarat",
        "city": "Dungri"
      },
      {
        "state": "Gujarat",
        "city": "Kaprada"
      },
      {
        "state": "Gujarat",
        "city": "Killa-pardi"
      },
      {
        "state": "Gujarat",
        "city": "Valsad"
      },
      {
        "state": "Gujarat",
        "city": "Pardi"
      },
      {
        "state": "Gujarat",
        "city": "Umargam"
      },
      {
        "state": "Gujarat",
        "city": "Umbergaon"
      },
      {
        "state": "Gujarat",
        "city": "Vapi"
      },
      {
        "state": "Haryana",
        "city": "Ambala"
      },
      {
        "state": "Haryana",
        "city": "Barara"
      },
      {
        "state": "Haryana",
        "city": "Naraingarh"
      },
      {
        "state": "Haryana",
        "city": "Achina"
      },
      {
        "state": "Haryana",
        "city": "Badhra"
      },
      {
        "state": "Haryana",
        "city": "Baund Kalan"
      },
      {
        "state": "Haryana",
        "city": "Bawani Khera"
      },
      {
        "state": "Haryana",
        "city": "Bhiwani"
      },
      {
        "state": "Haryana",
        "city": "Dadri"
      },
      {
        "state": "Haryana",
        "city": "Loharu"
      },
      {
        "state": "Haryana",
        "city": "Mundhal"
      },
      {
        "state": "Haryana",
        "city": "Siwani"
      },
      {
        "state": "Haryana",
        "city": "Tosham"
      },
      {
        "state": "Haryana",
        "city": "Tosham Rural"
      },
      {
        "state": "Haryana",
        "city": "Charkhi Dadri"
      },
      {
        "state": "Haryana",
        "city": "Ballabgarh"
      },
      {
        "state": "Haryana",
        "city": "Faridabad"
      },
      {
        "state": "Haryana",
        "city": "Hathin"
      },
      {
        "state": "Haryana",
        "city": "Hodal"
      },
      {
        "state": "Haryana",
        "city": "Hodal Rural"
      },
      {
        "state": "Haryana",
        "city": "Fatehabad"
      },
      {
        "state": "Haryana",
        "city": "Ratia"
      },
      {
        "state": "Haryana",
        "city": "Arjun Nagar"
      },
      {
        "state": "Haryana",
        "city": "Badshahpur"
      },
      {
        "state": "Haryana",
        "city": "Bhondsi"
      },
      {
        "state": "Haryana",
        "city": "Bhorakalan"
      },
      {
        "state": "Haryana",
        "city": "Farrukh Nagar"
      },
      {
        "state": "Haryana",
        "city": "Gurugram"
      },
      {
        "state": "Haryana",
        "city": "Firozpur Jhirka"
      },
      {
        "state": "Haryana",
        "city": "Garhi Harsaru"
      },
      {
        "state": "Haryana",
        "city": "Haillymandi"
      },
      {
        "state": "Haryana",
        "city": "Khalilpur"
      },
      {
        "state": "Haryana",
        "city": "Manesar"
      },
      {
        "state": "Haryana",
        "city": "Nagina"
      },
      {
        "state": "Haryana",
        "city": "Nanukalan"
      },
      {
        "state": "Haryana",
        "city": "Nuh"
      },
      {
        "state": "Haryana",
        "city": "Palam Road"
      },
      {
        "state": "Haryana",
        "city": "Palam Vihar"
      },
      {
        "state": "Haryana",
        "city": "Pataudi"
      },
      {
        "state": "Haryana",
        "city": "Punhana"
      },
      {
        "state": "Haryana",
        "city": "Shahpur"
      },
      {
        "state": "Haryana",
        "city": "Shivaji Nagar"
      },
      {
        "state": "Haryana",
        "city": "Sohna"
      },
      {
        "state": "Haryana",
        "city": "Tauru"
      },
      {
        "state": "Haryana",
        "city": "Hansi"
      },
      {
        "state": "Haryana",
        "city": "Hisar"
      },
      {
        "state": "Haryana",
        "city": "Jhajjar"
      },
      {
        "state": "Haryana",
        "city": "Jhamri"
      },
      {
        "state": "Haryana",
        "city": "Jhanswa"
      },
      {
        "state": "Haryana",
        "city": "Jharli"
      },
      {
        "state": "Haryana",
        "city": "Jondhi"
      },
      {
        "state": "Haryana",
        "city": "Sankhol"
      },
      {
        "state": "Haryana",
        "city": "Jind"
      },
      {
        "state": "Haryana",
        "city": "Narwana"
      },
      {
        "state": "Haryana",
        "city": "Safidon"
      },
      {
        "state": "Haryana",
        "city": "Guhla"
      },
      {
        "state": "Haryana",
        "city": "Kaithal"
      },
      {
        "state": "Haryana",
        "city": "Kalayat"
      },
      {
        "state": "Haryana",
        "city": "Pundri"
      },
      {
        "state": "Haryana",
        "city": "Rajaund"
      },
      {
        "state": "Haryana",
        "city": "Rajound"
      },
      {
        "state": "Haryana",
        "city": "Assandh"
      },
      {
        "state": "Haryana",
        "city": "Gharaunda"
      },
      {
        "state": "Haryana",
        "city": "Indri"
      },
      {
        "state": "Haryana",
        "city": "Jundla"
      },
      {
        "state": "Haryana",
        "city": "Karnal"
      },
      {
        "state": "Haryana",
        "city": "Kunjpura"
      },
      {
        "state": "Haryana",
        "city": "Maduban"
      },
      {
        "state": "Haryana",
        "city": "Nigdhu"
      },
      {
        "state": "Haryana",
        "city": "Nilokheri"
      },
      {
        "state": "Haryana",
        "city": "Nissing"
      },
      {
        "state": "Haryana",
        "city": "Babain"
      },
      {
        "state": "Haryana",
        "city": "Kurukshetra"
      },
      {
        "state": "Haryana",
        "city": "Ladwa"
      },
      {
        "state": "Haryana",
        "city": "Pehowa"
      },
      {
        "state": "Haryana",
        "city": "Shahabad"
      },
      {
        "state": "Haryana",
        "city": "Shahbaad Markanda"
      },
      {
        "state": "Haryana",
        "city": "Thanesar"
      },
      {
        "state": "Haryana",
        "city": "Akbarpur"
      },
      {
        "state": "Haryana",
        "city": "Akoda"
      },
      {
        "state": "Haryana",
        "city": "Mahendragarh"
      },
      {
        "state": "Haryana",
        "city": "Ateli"
      },
      {
        "state": "Haryana",
        "city": "Baghot"
      },
      {
        "state": "Haryana",
        "city": "Bairawas"
      },
      {
        "state": "Haryana",
        "city": "Barda"
      },
      {
        "state": "Haryana",
        "city": "Barkoda"
      },
      {
        "state": "Haryana",
        "city": "Basai"
      },
      {
        "state": "Haryana",
        "city": "Bashirpur"
      },
      {
        "state": "Haryana",
        "city": "Bassai"
      },
      {
        "state": "Haryana",
        "city": "Bawal"
      },
      {
        "state": "Haryana",
        "city": "Bawana"
      },
      {
        "state": "Haryana",
        "city": "Bawania"
      },
      {
        "state": "Haryana",
        "city": "Bawwa"
      },
      {
        "state": "Haryana",
        "city": "Bayal"
      },
      {
        "state": "Haryana",
        "city": "Behali"
      },
      {
        "state": "Haryana",
        "city": "Beri"
      },
      {
        "state": "Haryana",
        "city": "Beri Dopana"
      },
      {
        "state": "Haryana",
        "city": "Bharaf"
      },
      {
        "state": "Haryana",
        "city": "Bhaungerka"
      },
      {
        "state": "Haryana",
        "city": "Bhungarka"
      },
      {
        "state": "Haryana",
        "city": "Bhushan Kalan"
      },
      {
        "state": "Haryana",
        "city": "Bisowa"
      },
      {
        "state": "Haryana",
        "city": "Buddin"
      },
      {
        "state": "Haryana",
        "city": "Budhwa"
      },
      {
        "state": "Haryana",
        "city": "Chillro"
      },
      {
        "state": "Haryana",
        "city": "Chitrauli"
      },
      {
        "state": "Haryana",
        "city": "Dalanwas"
      },
      {
        "state": "Haryana",
        "city": "Deroli Ahir"
      },
      {
        "state": "Haryana",
        "city": "Dhadhot"
      },
      {
        "state": "Haryana",
        "city": "Dhador"
      },
      {
        "state": "Haryana",
        "city": "Dhanaunda"
      },
      {
        "state": "Haryana",
        "city": "Dhanonda"
      },
      {
        "state": "Haryana",
        "city": "Dhanota"
      },
      {
        "state": "Haryana",
        "city": "Dharson"
      },
      {
        "state": "Haryana",
        "city": "Dholera"
      },
      {
        "state": "Haryana",
        "city": "Dhor Kalan"
      },
      {
        "state": "Haryana",
        "city": "Digrota"
      },
      {
        "state": "Haryana",
        "city": "Dochana"
      },
      {
        "state": "Haryana",
        "city": "Dulana"
      },
      {
        "state": "Haryana",
        "city": "Duloth Ahir"
      },
      {
        "state": "Haryana",
        "city": "Golwa"
      },
      {
        "state": "Haryana",
        "city": "Gudha"
      },
      {
        "state": "Haryana",
        "city": "Gurha"
      },
      {
        "state": "Haryana",
        "city": "Hamidpur"
      },
      {
        "state": "Haryana",
        "city": "Hudina"
      },
      {
        "state": "Haryana",
        "city": "Ismaila"
      },
      {
        "state": "Haryana",
        "city": "Jano"
      },
      {
        "state": "Haryana",
        "city": "Jarwa"
      },
      {
        "state": "Haryana",
        "city": "Jharoda"
      },
      {
        "state": "Haryana",
        "city": "Jholri"
      },
      {
        "state": "Haryana",
        "city": "Jhook"
      },
      {
        "state": "Haryana",
        "city": "Kakrola"
      },
      {
        "state": "Haryana",
        "city": "Kamania"
      },
      {
        "state": "Haryana",
        "city": "Kanina"
      },
      {
        "state": "Haryana",
        "city": "Kanwi"
      },
      {
        "state": "Haryana",
        "city": "Karoli"
      },
      {
        "state": "Haryana",
        "city": "Karota"
      },
      {
        "state": "Haryana",
        "city": "Khatod"
      },
      {
        "state": "Haryana",
        "city": "Kheri"
      },
      {
        "state": "Haryana",
        "city": "Khudana"
      },
      {
        "state": "Haryana",
        "city": "Kohrar"
      },
      {
        "state": "Haryana",
        "city": "Koriawas"
      },
      {
        "state": "Haryana",
        "city": "Kothal Khurd"
      },
      {
        "state": "Haryana",
        "city": "Kotia"
      },
      {
        "state": "Haryana",
        "city": "Krishan Nagar"
      },
      {
        "state": "Haryana",
        "city": "Kultajpur"
      },
      {
        "state": "Haryana",
        "city": "Laad"
      },
      {
        "state": "Haryana",
        "city": "Lawan"
      },
      {
        "state": "Haryana",
        "city": "Lilodh"
      },
      {
        "state": "Haryana",
        "city": "Lookhi"
      },
      {
        "state": "Haryana",
        "city": "Madana Kalan"
      },
      {
        "state": "Haryana",
        "city": "Madhogarh"
      },
      {
        "state": "Haryana",
        "city": "Nagal Katha"
      },
      {
        "state": "Haryana",
        "city": "Naigawan"
      },
      {
        "state": "Haryana",
        "city": "Nangal Dargu"
      },
      {
        "state": "Haryana",
        "city": "Nangal Katha"
      },
      {
        "state": "Haryana",
        "city": "Nangal Sirohi"
      },
      {
        "state": "Haryana",
        "city": "Narnaul"
      },
      {
        "state": "Haryana",
        "city": "Nautana"
      },
      {
        "state": "Haryana",
        "city": "Nawan"
      },
      {
        "state": "Haryana",
        "city": "Neerpur"
      },
      {
        "state": "Haryana",
        "city": "Nehrugarh"
      },
      {
        "state": "Haryana",
        "city": "Niamatpur"
      },
      {
        "state": "Haryana",
        "city": "Nihalawas"
      },
      {
        "state": "Haryana",
        "city": "Nimbi"
      },
      {
        "state": "Haryana",
        "city": "Nizampur"
      },
      {
        "state": "Haryana",
        "city": "Nuni Awal"
      },
      {
        "state": "Haryana",
        "city": "Pabnera"
      },
      {
        "state": "Haryana",
        "city": "Palripanihara"
      },
      {
        "state": "Haryana",
        "city": "Pota"
      },
      {
        "state": "Haryana",
        "city": "Rambas"
      },
      {
        "state": "Haryana",
        "city": "Rasoolpur"
      },
      {
        "state": "Haryana",
        "city": "Sahibzada Ajit Singh Nagar"
      },
      {
        "state": "Haryana",
        "city": "Sareli"
      },
      {
        "state": "Haryana",
        "city": "Satnali"
      },
      {
        "state": "Haryana",
        "city": "Sehlang"
      },
      {
        "state": "Haryana",
        "city": "Sehma"
      },
      {
        "state": "Haryana",
        "city": "Shobhapur"
      },
      {
        "state": "Haryana",
        "city": "Shyampura"
      },
      {
        "state": "Haryana",
        "city": "Siana"
      },
      {
        "state": "Haryana",
        "city": "Sihore"
      },
      {
        "state": "Haryana",
        "city": "Sirohi Bahali"
      },
      {
        "state": "Haryana",
        "city": "Thanwas"
      },
      {
        "state": "Haryana",
        "city": "Mewat"
      },
      {
        "state": "Haryana",
        "city": "Palwal"
      },
      {
        "state": "Haryana",
        "city": "Panchkula"
      },
      {
        "state": "Haryana",
        "city": "Kalka"
      },
      {
        "state": "Haryana",
        "city": "Pinjore"
      },
      {
        "state": "Haryana",
        "city": "Panipat"
      },
      {
        "state": "Haryana",
        "city": "Ahrod"
      },
      {
        "state": "Haryana",
        "city": "Rewari"
      },
      {
        "state": "Haryana",
        "city": "Ateli"
      },
      {
        "state": "Haryana",
        "city": "Baghthala"
      },
      {
        "state": "Haryana",
        "city": "Bas Dooda"
      },
      {
        "state": "Haryana",
        "city": "Basbatori"
      },
      {
        "state": "Haryana",
        "city": "Basduda"
      },
      {
        "state": "Haryana",
        "city": "Bhakli"
      },
      {
        "state": "Haryana",
        "city": "Bhatsana"
      },
      {
        "state": "Haryana",
        "city": "Bhotwas Ahir"
      },
      {
        "state": "Haryana",
        "city": "Biran"
      },
      {
        "state": "Haryana",
        "city": "Bithwana"
      },
      {
        "state": "Haryana",
        "city": "Boria Kamalpur"
      },
      {
        "state": "Haryana",
        "city": "Budhpur"
      },
      {
        "state": "Haryana",
        "city": "Buroli"
      },
      {
        "state": "Haryana",
        "city": "Cheeta Dungra"
      },
      {
        "state": "Haryana",
        "city": "Dakhora"
      },
      {
        "state": "Haryana",
        "city": "Daroli"
      },
      {
        "state": "Haryana",
        "city": "Dehlawas"
      },
      {
        "state": "Haryana",
        "city": "Dhani Kolana"
      },
      {
        "state": "Haryana",
        "city": "Dharuhera"
      },
      {
        "state": "Haryana",
        "city": "Dhawana"
      },
      {
        "state": "Haryana",
        "city": "Didoli"
      },
      {
        "state": "Haryana",
        "city": "Dunderwas"
      },
      {
        "state": "Haryana",
        "city": "Garhi Alawalpur"
      },
      {
        "state": "Haryana",
        "city": "Garhi Bolni"
      },
      {
        "state": "Haryana",
        "city": "Gokalgarh"
      },
      {
        "state": "Haryana",
        "city": "Gothra"
      },
      {
        "state": "Haryana",
        "city": "Gudiyani"
      },
      {
        "state": "Haryana",
        "city": "Gugodh"
      },
      {
        "state": "Haryana",
        "city": "Gujar Majra"
      },
      {
        "state": "Haryana",
        "city": "Guraora"
      },
      {
        "state": "Haryana",
        "city": "Gurgaon Rural"
      },
      {
        "state": "Haryana",
        "city": "Guriani"
      },
      {
        "state": "Haryana",
        "city": "Jakhala"
      },
      {
        "state": "Haryana",
        "city": "Jatusana"
      },
      {
        "state": "Haryana",
        "city": "Jhal"
      },
      {
        "state": "Haryana",
        "city": "Jitpur Istamrar"
      },
      {
        "state": "Haryana",
        "city": "Juddi"
      },
      {
        "state": "Haryana",
        "city": "Kannaura"
      },
      {
        "state": "Haryana",
        "city": "Kanwali"
      },
      {
        "state": "Haryana",
        "city": "Karawara Manakpur"
      },
      {
        "state": "Haryana",
        "city": "Karnawas"
      },
      {
        "state": "Haryana",
        "city": "Khaleta"
      },
      {
        "state": "Haryana",
        "city": "Khaliawas"
      },
      {
        "state": "Haryana",
        "city": "Khandora"
      },
      {
        "state": "Haryana",
        "city": "Khol"
      },
      {
        "state": "Haryana",
        "city": "Khor"
      },
      {
        "state": "Haryana",
        "city": "Khori"
      },
      {
        "state": "Haryana",
        "city": "Kolana"
      },
      {
        "state": "Haryana",
        "city": "Kosli"
      },
      {
        "state": "Haryana",
        "city": "Kund"
      },
      {
        "state": "Haryana",
        "city": "Lisan"
      },
      {
        "state": "Haryana",
        "city": "Maheshwari"
      },
      {
        "state": "Haryana",
        "city": "Majra Gurdas"
      },
      {
        "state": "Haryana",
        "city": "Majra Sheoraj"
      },
      {
        "state": "Haryana",
        "city": "Mamaria Ahir"
      },
      {
        "state": "Haryana",
        "city": "Masani"
      },
      {
        "state": "Haryana",
        "city": "Mastapur"
      },
      {
        "state": "Haryana",
        "city": "Mayan"
      },
      {
        "state": "Haryana",
        "city": "Mohanpur"
      },
      {
        "state": "Haryana",
        "city": "Mundi"
      },
      {
        "state": "Haryana",
        "city": "Musepur"
      },
      {
        "state": "Haryana",
        "city": "Nagal Jamalpur"
      },
      {
        "state": "Haryana",
        "city": "Nandrampur Bass"
      },
      {
        "state": "Haryana",
        "city": "Nangal Jamalpur"
      },
      {
        "state": "Haryana",
        "city": "Nangal Pathani"
      },
      {
        "state": "Haryana",
        "city": "Nangli Godha"
      },
      {
        "state": "Haryana",
        "city": "Nangli Godha Village"
      },
      {
        "state": "Haryana",
        "city": "Nehrugarh-N"
      },
      {
        "state": "Haryana",
        "city": "Nimoth"
      },
      {
        "state": "Haryana",
        "city": "Palhawas"
      },
      {
        "state": "Haryana",
        "city": "Punsika"
      },
      {
        "state": "Haryana",
        "city": "Raliawas"
      },
      {
        "state": "Haryana",
        "city": "Rampuri"
      },
      {
        "state": "Haryana",
        "city": "Rathanthal"
      },
      {
        "state": "Haryana",
        "city": "Rattanthal"
      },
      {
        "state": "Haryana",
        "city": "Rohrai"
      },
      {
        "state": "Haryana",
        "city": "Sadat Nagar"
      },
      {
        "state": "Haryana",
        "city": "Saharanwas"
      },
      {
        "state": "Haryana",
        "city": "Sangwari"
      },
      {
        "state": "Haryana",
        "city": "Shadat Nagar -N"
      },
      {
        "state": "Haryana",
        "city": "Shayam Nagar"
      },
      {
        "state": "Haryana",
        "city": "Siha"
      },
      {
        "state": "Haryana",
        "city": "Sulkha"
      },
      {
        "state": "Haryana",
        "city": "Surethi"
      },
      {
        "state": "Haryana",
        "city": "Tankri"
      },
      {
        "state": "Haryana",
        "city": "Tint"
      },
      {
        "state": "Haryana",
        "city": "Tumna"
      },
      {
        "state": "Haryana",
        "city": "Zainabad"
      },
      {
        "state": "Haryana",
        "city": "Rohtak"
      },
      {
        "state": "Haryana",
        "city": "Meham"
      },
      {
        "state": "Haryana",
        "city": "Bara Gudha"
      },
      {
        "state": "Haryana",
        "city": "Dabwali"
      },
      {
        "state": "Haryana",
        "city": "Sirsa"
      },
      {
        "state": "Haryana",
        "city": "Mandi Dabwali"
      },
      {
        "state": "Haryana",
        "city": "Mangali"
      },
      {
        "state": "Haryana",
        "city": "Odhan"
      },
      {
        "state": "Haryana",
        "city": "Rania"
      },
      {
        "state": "Haryana",
        "city": "Bhainswal Kalan"
      },
      {
        "state": "Haryana",
        "city": "Gannaur"
      },
      {
        "state": "Haryana",
        "city": "Gohana"
      },
      {
        "state": "Haryana",
        "city": "Jakhauli"
      },
      {
        "state": "Haryana",
        "city": "Kharkhoda"
      },
      {
        "state": "Haryana",
        "city": "Mundlana"
      },
      {
        "state": "Haryana",
        "city": "Nahri"
      },
      {
        "state": "Haryana",
        "city": "Purkhas"
      },
      {
        "state": "Haryana",
        "city": "Sonipat"
      },
      {
        "state": "Haryana",
        "city": "Yamuna Nagar"
      },
      {
        "state": "Haryana",
        "city": "Chhachhrauli"
      },
      {
        "state": "Haryana",
        "city": "Dammunpur Bara"
      },
      {
        "state": "Haryana",
        "city": "Jagadhri"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bilaspur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dolra"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Jhanduta"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Naina Devi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bahrmour"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bhalai"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bharmour"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bhatiyat"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Chamba"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Churah"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dalhousie"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Holi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Pangi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Salooni"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sihunta"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Slooni"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Barsar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bhaoranj"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bhoranj"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bijhri"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Budhan Upper"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Hamirpur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Mehre"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nadaun"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sadoh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sujanpur Tira"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Baijnath"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Balah"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Ban Khandi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Baroh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bhatehr"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kangra"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Chhatar Khas"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Daulatpur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dhaliara"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dharamshala"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Diyara"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Harchakian"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Indora"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Jachh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Jaisinghpur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Jawali"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Khundian"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nurpur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Palampur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Reserve Jangal Rakkar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Shahpur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dhar Chhochhodun"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Hangrang"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kinnaur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Moorang"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Pooh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Reckong Peo"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sangla"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Anni"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kullu"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Manali"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Naggar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sainj"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Lahaul and Spiti"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Lahul"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Spiti"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Baldwara"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Baloh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Barwi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Beri"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Mandi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Chachyot"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dharampur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Joginder Nagar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kamand"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Karsog"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Lad Bharol"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nihri"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Pandoh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sadar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sandhol"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sarkaghat"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Thunag"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Chirgaon"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Chopal"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dodra Kawar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Jubbal"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Shimla"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kotkhai"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kumarsain"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nankhari"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Rohru"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Suni"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Theog"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Charna"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dhamla"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nahan"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nohra"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Pachhad"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Pajhota"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Paonta Sahib"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sirmaur (Sirmour)"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sangrah"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sarahan Khurd"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Shillai"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Arki"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kasauli"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nalagarh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Ramshehar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Solan"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Amb"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bharwain"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dangoh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Una"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Haroli"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Tahliwal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Achabal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Achan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Akura"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Anantnag"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Awenura"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Barsoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Behi Bagh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bijbehara"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bindoo Zalan Gam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Chimar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Demidulla"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Dialgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Dooru"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Frisal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gopal Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Halsidar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Hutmarah"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kaprin"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Katrasoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Khanabal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Khigogipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Khull"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kokernag"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kragsoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kukarnag"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Laram Jangi Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Larnoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Manjmoh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Manzgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Martand"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Mattan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nandi Marg"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nanil"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nasnoor"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nehama"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nissu"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Noonmai"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nunmai"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pahalgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Panzeth"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Qamar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Qanmar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Qazigund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ranbir Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Reban Gund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Reban Gund Bahram"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sadinare"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tarigam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tarigam Devsar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Verinag"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Wangund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Y.K. Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Yaripora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bandipore"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Aripanthan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Badran"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bandipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Baramulla"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gulmarg"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gurez"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nambal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pattan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sonawari"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sorore"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sumbal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Uri"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Abhama"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Arath"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Hardudalwan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Hyderpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ichgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Budgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Jawalapora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kachwari"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kanidagan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kanihama"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Karipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Krem Shora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Lalgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Later Chadoora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nagam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Natipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ompora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pakherpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "R H Hall"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Raithan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Rangar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sagam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Saibugh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sanat Nagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sangam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sholi Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sholipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Suraryan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Wanpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Watakala"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Watrihaal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Banihal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bathri"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bhaderwah"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Doda"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gandoh Bhalessa"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Katyara"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Thathri"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ganderbal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Akhnoor"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Birpur"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bishnah"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Jammu"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ranbir Singh Pura"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bani"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Basholi"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Billawar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kathua"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kishtwar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kulgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kupwara"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kalarooch"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sogam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tangdhar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Watergam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Drairi"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Poonch"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pulwama"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Aripal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Baigund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Batagund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bathnoor"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Batpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bouli"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Buchoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Chattargam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Haripora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kachachakote"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kahleel"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kund Bo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Linglish"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Lorgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Lurgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Mandoora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Midroo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Midru"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pastana"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Saimoh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Samuh Kheti"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Satura"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Shopin"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tral"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Rajouri"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kalakot"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ramban"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Reasi"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Samba"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Shopian"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "A K Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Badyar Balla"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bagh Mehtab"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Batrara"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Srinagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Jawahar Nagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kangan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Karan Nagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kathi Darwaza"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Khrew"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kullan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nandpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Noushera"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pantha Chowk"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Parimpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Rainawari"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "S Bala"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "S R Gunj"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sathu"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Wangat"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Wussan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Wuyan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Zainakote"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Chenani"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gran Bayotran"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Udhampur"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Mahore"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ramnagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Shajroo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tajoor"
      },
      {
        "state": "Jharkhand",
        "city": "Bermo"
      },
      {
        "state": "Jharkhand",
        "city": "Bokaro"
      },
      {
        "state": "Jharkhand",
        "city": "Damkarabarwa"
      },
      {
        "state": "Jharkhand",
        "city": "Gomia"
      },
      {
        "state": "Jharkhand",
        "city": "Kasmar"
      },
      {
        "state": "Jharkhand",
        "city": "Konardam"
      },
      {
        "state": "Jharkhand",
        "city": "Nawadih"
      },
      {
        "state": "Jharkhand",
        "city": "Peterbar"
      },
      {
        "state": "Jharkhand",
        "city": "Tenughat"
      },
      {
        "state": "Jharkhand",
        "city": "Tilaiya"
      },
      {
        "state": "Jharkhand",
        "city": "Chatra"
      },
      {
        "state": "Jharkhand",
        "city": "Gidhaur"
      },
      {
        "state": "Jharkhand",
        "city": "Hunterganj"
      },
      {
        "state": "Jharkhand",
        "city": "Itkori"
      },
      {
        "state": "Jharkhand",
        "city": "Jori"
      },
      {
        "state": "Jharkhand",
        "city": "Partabpura"
      },
      {
        "state": "Jharkhand",
        "city": "Pratappur"
      },
      {
        "state": "Jharkhand",
        "city": "Simaria"
      },
      {
        "state": "Jharkhand",
        "city": "Simaria Khurd"
      },
      {
        "state": "Jharkhand",
        "city": "Tandwa"
      },
      {
        "state": "Jharkhand",
        "city": "Chopa"
      },
      {
        "state": "Jharkhand",
        "city": "Debipur"
      },
      {
        "state": "Jharkhand",
        "city": "Deoghar"
      },
      {
        "state": "Jharkhand",
        "city": "Devipur"
      },
      {
        "state": "Jharkhand",
        "city": "Dibipur"
      },
      {
        "state": "Jharkhand",
        "city": "Doghar"
      },
      {
        "state": "Jharkhand",
        "city": "Karon"
      },
      {
        "state": "Jharkhand",
        "city": "Madhupur"
      },
      {
        "state": "Jharkhand",
        "city": "Mohanpur"
      },
      {
        "state": "Jharkhand",
        "city": "Rohini"
      },
      {
        "state": "Jharkhand",
        "city": "Sarawan"
      },
      {
        "state": "Jharkhand",
        "city": "Dhanbad"
      },
      {
        "state": "Jharkhand",
        "city": "Pathardih"
      },
      {
        "state": "Jharkhand",
        "city": "Bagnocha"
      },
      {
        "state": "Jharkhand",
        "city": "Dumka"
      },
      {
        "state": "Jharkhand",
        "city": "Jama"
      },
      {
        "state": "Jharkhand",
        "city": "Kathikund"
      },
      {
        "state": "Jharkhand",
        "city": "Masalia"
      },
      {
        "state": "Jharkhand",
        "city": "Ranishwar"
      },
      {
        "state": "Jharkhand",
        "city": "Saraiyahat"
      },
      {
        "state": "Jharkhand",
        "city": "Shikaripara"
      },
      {
        "state": "Jharkhand",
        "city": "Azadnagar"
      },
      {
        "state": "Jharkhand",
        "city": "Baharagora"
      },
      {
        "state": "Jharkhand",
        "city": "Chakulia"
      },
      {
        "state": "Jharkhand",
        "city": "Chhota Gobindpur"
      },
      {
        "state": "Jharkhand",
        "city": "Dumaria"
      },
      {
        "state": "Jharkhand",
        "city": "Forest Block"
      },
      {
        "state": "Jharkhand",
        "city": "Ghatshila"
      },
      {
        "state": "Jharkhand",
        "city": "Gobindpur"
      },
      {
        "state": "Jharkhand",
        "city": "Golmuri"
      },
      {
        "state": "Jharkhand",
        "city": "Indranagar"
      },
      {
        "state": "Jharkhand",
        "city": "Jadugora"
      },
      {
        "state": "Jharkhand",
        "city": "Jugsalai"
      },
      {
        "state": "Jharkhand",
        "city": "Kadma"
      },
      {
        "state": "Jharkhand",
        "city": "Mahulia"
      },
      {
        "state": "Jharkhand",
        "city": "Mango"
      },
      {
        "state": "Jharkhand",
        "city": "Moubhandar"
      },
      {
        "state": "Jharkhand",
        "city": "Musabani"
      },
      {
        "state": "Jharkhand",
        "city": "Patamda"
      },
      {
        "state": "Jharkhand",
        "city": "Rahargora"
      },
      {
        "state": "Jharkhand",
        "city": "East Singhbhum"
      },
      {
        "state": "Jharkhand",
        "city": "Sakchi"
      },
      {
        "state": "Jharkhand",
        "city": "Sundarnagar"
      },
      {
        "state": "Jharkhand",
        "city": "Tatanagar"
      },
      {
        "state": "Jharkhand",
        "city": "Bhawanathpur"
      },
      {
        "state": "Jharkhand",
        "city": "Garhwa"
      },
      {
        "state": "Jharkhand",
        "city": "Bagodar"
      },
      {
        "state": "Jharkhand",
        "city": "Bengabad"
      },
      {
        "state": "Jharkhand",
        "city": "Beniadih"
      },
      {
        "state": "Jharkhand",
        "city": "Birni"
      },
      {
        "state": "Jharkhand",
        "city": "Deori"
      },
      {
        "state": "Jharkhand",
        "city": "Djanwar"
      },
      {
        "state": "Jharkhand",
        "city": "Dumri"
      },
      {
        "state": "Jharkhand",
        "city": "Ganwan"
      },
      {
        "state": "Jharkhand",
        "city": "Giridih"
      },
      {
        "state": "Jharkhand",
        "city": "Jamua"
      },
      {
        "state": "Jharkhand",
        "city": "Jamuagiridih"
      },
      {
        "state": "Jharkhand",
        "city": "Pirtand"
      },
      {
        "state": "Jharkhand",
        "city": "Rajdhanwar"
      },
      {
        "state": "Jharkhand",
        "city": "Tisri"
      },
      {
        "state": "Jharkhand",
        "city": "Godda"
      },
      {
        "state": "Jharkhand",
        "city": "Gouraijore"
      },
      {
        "state": "Jharkhand",
        "city": "Mahagama"
      },
      {
        "state": "Jharkhand",
        "city": "Mehrma"
      },
      {
        "state": "Jharkhand",
        "city": "Pahargama"
      },
      {
        "state": "Jharkhand",
        "city": "Pathargama"
      },
      {
        "state": "Jharkhand",
        "city": "Sunadar Pahari"
      },
      {
        "state": "Jharkhand",
        "city": "Basia"
      },
      {
        "state": "Jharkhand",
        "city": "Bharno"
      },
      {
        "state": "Jharkhand",
        "city": "Bishunpur"
      },
      {
        "state": "Jharkhand",
        "city": "Gumla"
      },
      {
        "state": "Jharkhand",
        "city": "Nawagarh"
      },
      {
        "state": "Jharkhand",
        "city": "Noadih"
      },
      {
        "state": "Jharkhand",
        "city": "Palkot"
      },
      {
        "state": "Jharkhand",
        "city": "Sisai"
      },
      {
        "state": "Jharkhand",
        "city": "Toto"
      },
      {
        "state": "Jharkhand",
        "city": "Barhi"
      },
      {
        "state": "Jharkhand",
        "city": "Barkagaon"
      },
      {
        "state": "Jharkhand",
        "city": "Bishungarh"
      },
      {
        "state": "Jharkhand",
        "city": "Ichak"
      },
      {
        "state": "Jharkhand",
        "city": "Katkamsandi"
      },
      {
        "state": "Jharkhand",
        "city": "Padma"
      },
      {
        "state": "Jharkhand",
        "city": "Hazaribag"
      },
      {
        "state": "Jharkhand",
        "city": "Bnarayanpur"
      },
      {
        "state": "Jharkhand",
        "city": "Jamtara"
      },
      {
        "state": "Jharkhand",
        "city": "Nala"
      },
      {
        "state": "Jharkhand",
        "city": "Narainpur"
      },
      {
        "state": "Jharkhand",
        "city": "Sarth"
      },
      {
        "state": "Jharkhand",
        "city": "Khunti"
      },
      {
        "state": "Jharkhand",
        "city": "Chandwara"
      },
      {
        "state": "Jharkhand",
        "city": "Jainagar"
      },
      {
        "state": "Jharkhand",
        "city": "Koderma"
      },
      {
        "state": "Jharkhand",
        "city": "Markacho"
      },
      {
        "state": "Jharkhand",
        "city": "Satgawan"
      },
      {
        "state": "Jharkhand",
        "city": "Barwadih"
      },
      {
        "state": "Jharkhand",
        "city": "Chandwa"
      },
      {
        "state": "Jharkhand",
        "city": "Latehar"
      },
      {
        "state": "Jharkhand",
        "city": "Malumath"
      },
      {
        "state": "Jharkhand",
        "city": "Phulwartanr"
      },
      {
        "state": "Jharkhand",
        "city": "Lohardaga"
      },
      {
        "state": "Jharkhand",
        "city": "Kuru"
      },
      {
        "state": "Jharkhand",
        "city": "Amrapara"
      },
      {
        "state": "Jharkhand",
        "city": "Dumarghati"
      },
      {
        "state": "Jharkhand",
        "city": "Pakur"
      },
      {
        "state": "Jharkhand",
        "city": "Hiranpur"
      },
      {
        "state": "Jharkhand",
        "city": "Maheshpur"
      },
      {
        "state": "Jharkhand",
        "city": "Palamu"
      },
      {
        "state": "Jharkhand",
        "city": "Chinpur"
      },
      {
        "state": "Jharkhand",
        "city": "Hariharganj"
      },
      {
        "state": "Jharkhand",
        "city": "Japla"
      },
      {
        "state": "Jharkhand",
        "city": "Lapla"
      },
      {
        "state": "Jharkhand",
        "city": "Nagar Untari"
      },
      {
        "state": "Jharkhand",
        "city": "Panki"
      },
      {
        "state": "Jharkhand",
        "city": "Satbarwa"
      },
      {
        "state": "Jharkhand",
        "city": "Chitarpur"
      },
      {
        "state": "Jharkhand",
        "city": "Gola"
      },
      {
        "state": "Jharkhand",
        "city": "Mandu"
      },
      {
        "state": "Jharkhand",
        "city": "Ramgarh"
      },
      {
        "state": "Jharkhand",
        "city": "Akashi"
      },
      {
        "state": "Jharkhand",
        "city": "Angara"
      },
      {
        "state": "Jharkhand",
        "city": "Bero"
      },
      {
        "state": "Jharkhand",
        "city": "Bundu"
      },
      {
        "state": "Jharkhand",
        "city": "Burmu"
      },
      {
        "state": "Jharkhand",
        "city": "Chanho"
      },
      {
        "state": "Jharkhand",
        "city": "Dakra"
      },
      {
        "state": "Jharkhand",
        "city": "Ginjo Thakurgaon"
      },
      {
        "state": "Jharkhand",
        "city": "Ranchi"
      },
      {
        "state": "Jharkhand",
        "city": "Itki"
      },
      {
        "state": "Jharkhand",
        "city": "Kanke"
      },
      {
        "state": "Jharkhand",
        "city": "Karra"
      },
      {
        "state": "Jharkhand",
        "city": "Khalari"
      },
      {
        "state": "Jharkhand",
        "city": "Lapung"
      },
      {
        "state": "Jharkhand",
        "city": "Murhu"
      },
      {
        "state": "Jharkhand",
        "city": "Namkum"
      },
      {
        "state": "Jharkhand",
        "city": "Ormanjhi"
      },
      {
        "state": "Jharkhand",
        "city": "Rania"
      },
      {
        "state": "Jharkhand",
        "city": "Silli"
      },
      {
        "state": "Jharkhand",
        "city": "Tamar"
      },
      {
        "state": "Jharkhand",
        "city": "Torpa"
      },
      {
        "state": "Jharkhand",
        "city": "Turguru"
      },
      {
        "state": "Jharkhand",
        "city": "Barhait"
      },
      {
        "state": "Jharkhand",
        "city": "Barharwa"
      },
      {
        "state": "Jharkhand",
        "city": "Berhait"
      },
      {
        "state": "Jharkhand",
        "city": "Sahibganj"
      },
      {
        "state": "Jharkhand",
        "city": "Lalbathan"
      },
      {
        "state": "Jharkhand",
        "city": "Pathna Land"
      },
      {
        "state": "Jharkhand",
        "city": "Rajmahal"
      },
      {
        "state": "Jharkhand",
        "city": "Saliburu"
      },
      {
        "state": "Jharkhand",
        "city": "Taljhari"
      },
      {
        "state": "Jharkhand",
        "city": "Udhua"
      },
      {
        "state": "Jharkhand",
        "city": "Udhwa"
      },
      {
        "state": "Jharkhand",
        "city": "Amda"
      },
      {
        "state": "Jharkhand",
        "city": "Bara Amda"
      },
      {
        "state": "Jharkhand",
        "city": "Chandil"
      },
      {
        "state": "Jharkhand",
        "city": "Chawlibasa"
      },
      {
        "state": "Jharkhand",
        "city": "Chowka"
      },
      {
        "state": "Jharkhand",
        "city": "Gamharia"
      },
      {
        "state": "Jharkhand",
        "city": "Kandra"
      },
      {
        "state": "Jharkhand",
        "city": "Kharsawangarh"
      },
      {
        "state": "Jharkhand",
        "city": "Rajnagar"
      },
      {
        "state": "Jharkhand",
        "city": "Sini"
      },
      {
        "state": "Jharkhand",
        "city": "Sirkadih"
      },
      {
        "state": "Jharkhand",
        "city": "Tiruldih"
      },
      {
        "state": "Jharkhand",
        "city": "Bano"
      },
      {
        "state": "Jharkhand",
        "city": "Bolba"
      },
      {
        "state": "Jharkhand",
        "city": "Simdega"
      },
      {
        "state": "Jharkhand",
        "city": "Thethaitanagar"
      },
      {
        "state": "Jharkhand",
        "city": "Barajamda"
      },
      {
        "state": "Jharkhand",
        "city": "Chiria"
      },
      {
        "state": "Jharkhand",
        "city": "Dangoaposi"
      },
      {
        "state": "Jharkhand",
        "city": "Goilkera"
      },
      {
        "state": "Jharkhand",
        "city": "Gua"
      },
      {
        "state": "Jharkhand",
        "city": "Guira"
      },
      {
        "state": "Jharkhand",
        "city": "Hatgamaria"
      },
      {
        "state": "Jharkhand",
        "city": "Jagannathpur"
      },
      {
        "state": "Jharkhand",
        "city": "Jhinkpani"
      },
      {
        "state": "Jharkhand",
        "city": "Jorapokhar"
      },
      {
        "state": "Jharkhand",
        "city": "West Singhbhum"
      },
      {
        "state": "Jharkhand",
        "city": "Kiriburu"
      },
      {
        "state": "Jharkhand",
        "city": "Manoharpur"
      },
      {
        "state": "Jharkhand",
        "city": "Meghahatuburu"
      },
      {
        "state": "Jharkhand",
        "city": "Noamundi"
      },
      {
        "state": "Jharkhand",
        "city": "Saraikela"
      },
      {
        "state": "Jharkhand",
        "city": "Sonua"
      },
      {
        "state": "Karnataka",
        "city": "Badami"
      },
      {
        "state": "Karnataka",
        "city": "Bagalkot"
      },
      {
        "state": "Karnataka",
        "city": "Bilgi"
      },
      {
        "state": "Karnataka",
        "city": "Bilgi Rural"
      },
      {
        "state": "Karnataka",
        "city": "Hungund"
      },
      {
        "state": "Karnataka",
        "city": "Kolur"
      },
      {
        "state": "Karnataka",
        "city": "Madanamatti"
      },
      {
        "state": "Karnataka",
        "city": "Mudhol"
      },
      {
        "state": "Karnataka",
        "city": "Ballari"
      },
      {
        "state": "Karnataka",
        "city": "Hadagalli"
      },
      {
        "state": "Karnataka",
        "city": "Hagaribommanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Hanumana Halli"
      },
      {
        "state": "Karnataka",
        "city": "Hoovina Hadagali"
      },
      {
        "state": "Karnataka",
        "city": "Hosapete"
      },
      {
        "state": "Karnataka",
        "city": "Kudligi"
      },
      {
        "state": "Karnataka",
        "city": "Sandur"
      },
      {
        "state": "Karnataka",
        "city": "Siruguppa"
      },
      {
        "state": "Karnataka",
        "city": "Athani"
      },
      {
        "state": "Karnataka",
        "city": "Bachanakeri"
      },
      {
        "state": "Karnataka",
        "city": "Bailhongal"
      },
      {
        "state": "Karnataka",
        "city": "Bedkihal"
      },
      {
        "state": "Karnataka",
        "city": "Belagavi"
      },
      {
        "state": "Karnataka",
        "city": "Belgaum"
      },
      {
        "state": "Karnataka",
        "city": "Chikodi"
      },
      {
        "state": "Karnataka",
        "city": "Gokak"
      },
      {
        "state": "Karnataka",
        "city": "Hallur"
      },
      {
        "state": "Karnataka",
        "city": "Hukkeri"
      },
      {
        "state": "Karnataka",
        "city": "Khanapur"
      },
      {
        "state": "Karnataka",
        "city": "Londa"
      },
      {
        "state": "Karnataka",
        "city": "Parasgad"
      },
      {
        "state": "Karnataka",
        "city": "Raibag"
      },
      {
        "state": "Karnataka",
        "city": "Ramdurg"
      },
      {
        "state": "Karnataka",
        "city": "Sampgaon"
      },
      {
        "state": "Karnataka",
        "city": "Saundatti"
      },
      {
        "state": "Karnataka",
        "city": "Saundatti-Yellamma"
      },
      {
        "state": "Karnataka",
        "city": "Bengaluru Rural"
      },
      {
        "state": "Karnataka",
        "city": "Bengaluru Urban"
      },
      {
        "state": "Karnataka",
        "city": "Doddaballapura"
      },
      {
        "state": "Karnataka",
        "city": "Hosakote"
      },
      {
        "state": "Karnataka",
        "city": "Hulikal"
      },
      {
        "state": "Karnataka",
        "city": "Kemminje Rural"
      },
      {
        "state": "Karnataka",
        "city": "Nelamangala"
      },
      {
        "state": "Karnataka",
        "city": "Anekal"
      },
      {
        "state": "Karnataka",
        "city": "Badamanavarthekaval"
      },
      {
        "state": "Karnataka",
        "city": "Kadabeesanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Aurad"
      },
      {
        "state": "Karnataka",
        "city": "Basavakalyan"
      },
      {
        "state": "Karnataka",
        "city": "Bhalki"
      },
      {
        "state": "Karnataka",
        "city": "Bidar"
      },
      {
        "state": "Karnataka",
        "city": "Humnabad"
      },
      {
        "state": "Karnataka",
        "city": "Ladwanti"
      },
      {
        "state": "Karnataka",
        "city": "Chamarajanagar"
      },
      {
        "state": "Karnataka",
        "city": "Gundlupete"
      },
      {
        "state": "Karnataka",
        "city": "Modahalli"
      },
      {
        "state": "Karnataka",
        "city": "Yelandur"
      },
      {
        "state": "Karnataka",
        "city": "Bachareddyhalli"
      },
      {
        "state": "Karnataka",
        "city": "Jeerigepete"
      },
      {
        "state": "Karnataka",
        "city": "Balige"
      },
      {
        "state": "Karnataka",
        "city": "Chickmagaluru"
      },
      {
        "state": "Karnataka",
        "city": "Guddethota"
      },
      {
        "state": "Karnataka",
        "city": "Halsur"
      },
      {
        "state": "Karnataka",
        "city": "Hillikere"
      },
      {
        "state": "Karnataka",
        "city": "Kadur"
      },
      {
        "state": "Karnataka",
        "city": "Koppa"
      },
      {
        "state": "Karnataka",
        "city": "Koppa Rural"
      },
      {
        "state": "Karnataka",
        "city": "Mudigere"
      },
      {
        "state": "Karnataka",
        "city": "Narasimharajapura"
      },
      {
        "state": "Karnataka",
        "city": "Sringeri"
      },
      {
        "state": "Karnataka",
        "city": "Tarikere"
      },
      {
        "state": "Karnataka",
        "city": "Challakere"
      },
      {
        "state": "Karnataka",
        "city": "Chitradurga"
      },
      {
        "state": "Karnataka",
        "city": "Hiriyur"
      },
      {
        "state": "Karnataka",
        "city": "Holalkere"
      },
      {
        "state": "Karnataka",
        "city": "Holalkere Rural"
      },
      {
        "state": "Karnataka",
        "city": "Hosadurga"
      },
      {
        "state": "Karnataka",
        "city": "Molakalmuru"
      },
      {
        "state": "Karnataka",
        "city": "Bantwal"
      },
      {
        "state": "Karnataka",
        "city": "Mangaluru"
      },
      {
        "state": "Karnataka",
        "city": "Puttur"
      },
      {
        "state": "Karnataka",
        "city": "Dakshina Kannada"
      },
      {
        "state": "Karnataka",
        "city": "Davangere"
      },
      {
        "state": "Karnataka",
        "city": "Harapanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Harihara"
      },
      {
        "state": "Karnataka",
        "city": "Harpanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Dharwad"
      },
      {
        "state": "Karnataka",
        "city": "Hubali-Dharwad"
      },
      {
        "state": "Karnataka",
        "city": "Hubballi"
      },
      {
        "state": "Karnataka",
        "city": "Kalghatgi"
      },
      {
        "state": "Karnataka",
        "city": "Kundgol"
      },
      {
        "state": "Karnataka",
        "city": "Kundgol Rural"
      },
      {
        "state": "Karnataka",
        "city": "Navalgund"
      },
      {
        "state": "Karnataka",
        "city": "Gadag"
      },
      {
        "state": "Karnataka",
        "city": "Koujageri"
      },
      {
        "state": "Karnataka",
        "city": "Mudargi"
      },
      {
        "state": "Karnataka",
        "city": "Mundargi"
      },
      {
        "state": "Karnataka",
        "city": "Nargund"
      },
      {
        "state": "Karnataka",
        "city": "Rona"
      },
      {
        "state": "Karnataka",
        "city": "Shirahatti"
      },
      {
        "state": "Karnataka",
        "city": "Alur"
      },
      {
        "state": "Karnataka",
        "city": "Alur Rural"
      },
      {
        "state": "Karnataka",
        "city": "Arakalagud"
      },
      {
        "state": "Karnataka",
        "city": "Arsikere"
      },
      {
        "state": "Karnataka",
        "city": "Belur"
      },
      {
        "state": "Karnataka",
        "city": "Channarayapattana"
      },
      {
        "state": "Karnataka",
        "city": "Hassan"
      },
      {
        "state": "Karnataka",
        "city": "Holenarasipur"
      },
      {
        "state": "Karnataka",
        "city": "Sakleshpur"
      },
      {
        "state": "Karnataka",
        "city": "Byadagi"
      },
      {
        "state": "Karnataka",
        "city": "Haveri"
      },
      {
        "state": "Karnataka",
        "city": "Hangal"
      },
      {
        "state": "Karnataka",
        "city": "Hirekerur"
      },
      {
        "state": "Karnataka",
        "city": "Hosa Neeralgi-M-Karadgi New"
      },
      {
        "state": "Karnataka",
        "city": "Ranebennur"
      },
      {
        "state": "Karnataka",
        "city": "Savanur"
      },
      {
        "state": "Karnataka",
        "city": "Shiggaon"
      },
      {
        "state": "Karnataka",
        "city": "Afzalpur"
      },
      {
        "state": "Karnataka",
        "city": "Aland"
      },
      {
        "state": "Karnataka",
        "city": "Chincholi"
      },
      {
        "state": "Karnataka",
        "city": "Chincholi H"
      },
      {
        "state": "Karnataka",
        "city": "Chittapur"
      },
      {
        "state": "Karnataka",
        "city": "Gulbarga"
      },
      {
        "state": "Karnataka",
        "city": "Jevargi"
      },
      {
        "state": "Karnataka",
        "city": "Mavinsur"
      },
      {
        "state": "Karnataka",
        "city": "Sedam"
      },
      {
        "state": "Karnataka",
        "city": "Kalaburagi (Gulbarga)"
      },
      {
        "state": "Karnataka",
        "city": "B.Shettigeri"
      },
      {
        "state": "Karnataka",
        "city": "Somwarpet"
      },
      {
        "state": "Karnataka",
        "city": "Virajpet"
      },
      {
        "state": "Karnataka",
        "city": "Bangarapet"
      },
      {
        "state": "Karnataka",
        "city": "Kolar"
      },
      {
        "state": "Karnataka",
        "city": "Cheelammanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Chikkakrimanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Doddagummanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Gotlakunte"
      },
      {
        "state": "Karnataka",
        "city": "Guribidanur"
      },
      {
        "state": "Karnataka",
        "city": "Ivarahalli"
      },
      {
        "state": "Karnataka",
        "city": "Kolar Gold Fields"
      },
      {
        "state": "Karnataka",
        "city": "Malur"
      },
      {
        "state": "Karnataka",
        "city": "Muddalahalli"
      },
      {
        "state": "Karnataka",
        "city": "Mulbagal"
      },
      {
        "state": "Karnataka",
        "city": "Srinivasapura"
      },
      {
        "state": "Karnataka",
        "city": "Srinivaspur"
      },
      {
        "state": "Karnataka",
        "city": "Yenigadale"
      },
      {
        "state": "Karnataka",
        "city": "Koppal"
      },
      {
        "state": "Karnataka",
        "city": "Kukshtagi"
      },
      {
        "state": "Karnataka",
        "city": "Kushtagi"
      },
      {
        "state": "Karnataka",
        "city": "Anegola"
      },
      {
        "state": "Karnataka",
        "city": "Basthihalli"
      },
      {
        "state": "Karnataka",
        "city": "Chinnenahalli"
      },
      {
        "state": "Karnataka",
        "city": "Mandya"
      },
      {
        "state": "Karnataka",
        "city": "Krishnarajpete"
      },
      {
        "state": "Karnataka",
        "city": "Madduru"
      },
      {
        "state": "Karnataka",
        "city": "Malavalli"
      },
      {
        "state": "Karnataka",
        "city": "Nagamangala"
      },
      {
        "state": "Karnataka",
        "city": "Pandavapura"
      },
      {
        "state": "Karnataka",
        "city": "Shrirangapattana"
      },
      {
        "state": "Karnataka",
        "city": "Srirangapatna"
      },
      {
        "state": "Karnataka",
        "city": "Heggadadevankote"
      },
      {
        "state": "Karnataka",
        "city": "Hunsur"
      },
      {
        "state": "Karnataka",
        "city": "Krishnarajanagara"
      },
      {
        "state": "Karnataka",
        "city": "Mookanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Nanjanagudu"
      },
      {
        "state": "Karnataka",
        "city": "Periyapatna"
      },
      {
        "state": "Karnataka",
        "city": "Tirumakudalu Narasipura"
      },
      {
        "state": "Karnataka",
        "city": "Aidanhal"
      },
      {
        "state": "Karnataka",
        "city": "Belladamaradi"
      },
      {
        "state": "Karnataka",
        "city": "Chilkaragi"
      },
      {
        "state": "Karnataka",
        "city": "Devadurga"
      },
      {
        "state": "Karnataka",
        "city": "Lingsugur"
      },
      {
        "state": "Karnataka",
        "city": "Manvi"
      },
      {
        "state": "Karnataka",
        "city": "Raichur"
      },
      {
        "state": "Karnataka",
        "city": "Sindhanur"
      },
      {
        "state": "Karnataka",
        "city": "Alahalli"
      },
      {
        "state": "Karnataka",
        "city": "Channapatna"
      },
      {
        "state": "Karnataka",
        "city": "Ramanagara"
      },
      {
        "state": "Karnataka",
        "city": "Bhadravati"
      },
      {
        "state": "Karnataka",
        "city": "Shimoga"
      },
      {
        "state": "Karnataka",
        "city": "Hosanagar"
      },
      {
        "state": "Karnataka",
        "city": "Shikaripur"
      },
      {
        "state": "Karnataka",
        "city": "Soraba"
      },
      {
        "state": "Karnataka",
        "city": "Thirthahalli"
      },
      {
        "state": "Karnataka",
        "city": "Bilinandi"
      },
      {
        "state": "Karnataka",
        "city": "Chikkanayakanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Gubbi"
      },
      {
        "state": "Karnataka",
        "city": "Koratagere"
      },
      {
        "state": "Karnataka",
        "city": "Kunigal"
      },
      {
        "state": "Karnataka",
        "city": "Madhugiri"
      },
      {
        "state": "Karnataka",
        "city": "Pavagada"
      },
      {
        "state": "Karnataka",
        "city": "Sira"
      },
      {
        "state": "Karnataka",
        "city": "Sira Rural"
      },
      {
        "state": "Karnataka",
        "city": "Tiptur"
      },
      {
        "state": "Karnataka",
        "city": "Tumakuru"
      },
      {
        "state": "Karnataka",
        "city": "Turuvekere"
      },
      {
        "state": "Karnataka",
        "city": "Kundapura"
      },
      {
        "state": "Karnataka",
        "city": "Kundpaura"
      },
      {
        "state": "Karnataka",
        "city": "Moodubelle"
      },
      {
        "state": "Karnataka",
        "city": "Navunda"
      },
      {
        "state": "Karnataka",
        "city": "Udupi"
      },
      {
        "state": "Karnataka",
        "city": "Ankola"
      },
      {
        "state": "Karnataka",
        "city": "Bhatkal"
      },
      {
        "state": "Karnataka",
        "city": "Haliyal"
      },
      {
        "state": "Karnataka",
        "city": "Honnavar"
      },
      {
        "state": "Karnataka",
        "city": "Hosali"
      },
      {
        "state": "Karnataka",
        "city": "Joida"
      },
      {
        "state": "Karnataka",
        "city": "Kanchikai"
      },
      {
        "state": "Karnataka",
        "city": "Karwar"
      },
      {
        "state": "Karnataka",
        "city": "Kumta"
      },
      {
        "state": "Karnataka",
        "city": "Mundgod"
      },
      {
        "state": "Karnataka",
        "city": "Mutmurd"
      },
      {
        "state": "Karnataka",
        "city": "Siddapur"
      },
      {
        "state": "Karnataka",
        "city": "Sirsi"
      },
      {
        "state": "Karnataka",
        "city": "Supa"
      },
      {
        "state": "Karnataka",
        "city": "Yellapur"
      },
      {
        "state": "Karnataka",
        "city": "Vijayapura"
      },
      {
        "state": "Karnataka",
        "city": "Basavana Bagewadi"
      },
      {
        "state": "Karnataka",
        "city": "Indi"
      },
      {
        "state": "Karnataka",
        "city": "Muddebihal"
      },
      {
        "state": "Karnataka",
        "city": "Sindagi"
      },
      {
        "state": "Karnataka",
        "city": "Jangangaddi"
      },
      {
        "state": "Karnataka",
        "city": "Kurekanhal"
      },
      {
        "state": "Karnataka",
        "city": "Yadgir"
      },
      {
        "state": "Karnataka",
        "city": "Shorapur"
      },
      {
        "state": "Kerala",
        "city": "Alappuzha"
      },
      {
        "state": "Kerala",
        "city": "Ambalappuzha"
      },
      {
        "state": "Kerala",
        "city": "Chengannur"
      },
      {
        "state": "Kerala",
        "city": "Chennithala"
      },
      {
        "state": "Kerala",
        "city": "Cherthala"
      },
      {
        "state": "Kerala",
        "city": "Kandalloor"
      },
      {
        "state": "Kerala",
        "city": "Karthikappally"
      },
      {
        "state": "Kerala",
        "city": "Kumarapuram"
      },
      {
        "state": "Kerala",
        "city": "Kuttanadu"
      },
      {
        "state": "Kerala",
        "city": "Mararikulam"
      },
      {
        "state": "Kerala",
        "city": "Mavelikkara"
      },
      {
        "state": "Kerala",
        "city": "Muthukulam"
      },
      {
        "state": "Kerala",
        "city": "Nooranad"
      },
      {
        "state": "Kerala",
        "city": "Paravoor"
      },
      {
        "state": "Kerala",
        "city": "Thekkekara"
      },
      {
        "state": "Kerala",
        "city": "Aluva"
      },
      {
        "state": "Kerala",
        "city": "Angamaly"
      },
      {
        "state": "Kerala",
        "city": "Ernakulam"
      },
      {
        "state": "Kerala",
        "city": "Kalady"
      },
      {
        "state": "Kerala",
        "city": "Kanayannur"
      },
      {
        "state": "Kerala",
        "city": "Kochi"
      },
      {
        "state": "Kerala",
        "city": "Koonammavu"
      },
      {
        "state": "Kerala",
        "city": "Kothamangalam"
      },
      {
        "state": "Kerala",
        "city": "Kunnathunadu"
      },
      {
        "state": "Kerala",
        "city": "Muvattupuzha"
      },
      {
        "state": "Kerala",
        "city": "Paravur"
      },
      {
        "state": "Kerala",
        "city": "Devikulam"
      },
      {
        "state": "Kerala",
        "city": "Idukki"
      },
      {
        "state": "Kerala",
        "city": "Peermade"
      },
      {
        "state": "Kerala",
        "city": "Thodupuzha"
      },
      {
        "state": "Kerala",
        "city": "Udumbanchola"
      },
      {
        "state": "Kerala",
        "city": "Vannappuram"
      },
      {
        "state": "Kerala",
        "city": "Kambil"
      },
      {
        "state": "Kerala",
        "city": "Kannur"
      },
      {
        "state": "Kerala",
        "city": "Mayyil"
      },
      {
        "state": "Kerala",
        "city": "Payyanur"
      },
      {
        "state": "Kerala",
        "city": "Pazhayangadi"
      },
      {
        "state": "Kerala",
        "city": "Pilathara"
      },
      {
        "state": "Kerala",
        "city": "Taliparamba"
      },
      {
        "state": "Kerala",
        "city": "Thalassery"
      },
      {
        "state": "Kerala",
        "city": "Hosdurg"
      },
      {
        "state": "Kerala",
        "city": "Kasaragod"
      },
      {
        "state": "Kerala",
        "city": "Adichanallur"
      },
      {
        "state": "Kerala",
        "city": "Kollam"
      },
      {
        "state": "Kerala",
        "city": "Chithara"
      },
      {
        "state": "Kerala",
        "city": "Karunagappally"
      },
      {
        "state": "Kerala",
        "city": "Kottarakkara"
      },
      {
        "state": "Kerala",
        "city": "Thalavoor"
      },
      {
        "state": "Kerala",
        "city": "Thiruvananthapuram"
      },
      {
        "state": "Kerala",
        "city": "Changanassery"
      },
      {
        "state": "Kerala",
        "city": "Kottayam"
      },
      {
        "state": "Kerala",
        "city": "Kaduthuruthy"
      },
      {
        "state": "Kerala",
        "city": "Kanjirapally"
      },
      {
        "state": "Kerala",
        "city": "Koovappally"
      },
      {
        "state": "Kerala",
        "city": "Kuravilangad"
      },
      {
        "state": "Kerala",
        "city": "Meenachil"
      },
      {
        "state": "Kerala",
        "city": "Meenachil part"
      },
      {
        "state": "Kerala",
        "city": "Melukavumattam"
      },
      {
        "state": "Kerala",
        "city": "Mevada"
      },
      {
        "state": "Kerala",
        "city": "Panackapalam"
      },
      {
        "state": "Kerala",
        "city": "Perumbaikad"
      },
      {
        "state": "Kerala",
        "city": "Pinnakkanad"
      },
      {
        "state": "Kerala",
        "city": "Pravithanam"
      },
      {
        "state": "Kerala",
        "city": "Thalayazham"
      },
      {
        "state": "Kerala",
        "city": "Thalayolaparambu"
      },
      {
        "state": "Kerala",
        "city": "Vaikom"
      },
      {
        "state": "Kerala",
        "city": "Vazhoor"
      },
      {
        "state": "Kerala",
        "city": "Arikkulam"
      },
      {
        "state": "Kerala",
        "city": "Koyilandy"
      },
      {
        "state": "Kerala",
        "city": "Kozhikode"
      },
      {
        "state": "Kerala",
        "city": "Moodadi"
      },
      {
        "state": "Kerala",
        "city": "Quilandy"
      },
      {
        "state": "Kerala",
        "city": "Vadakara"
      },
      {
        "state": "Kerala",
        "city": "Chemmad"
      },
      {
        "state": "Kerala",
        "city": "Eranad"
      },
      {
        "state": "Kerala",
        "city": "Malappuram"
      },
      {
        "state": "Kerala",
        "city": "Manjeri"
      },
      {
        "state": "Kerala",
        "city": "Nilambur"
      },
      {
        "state": "Kerala",
        "city": "Perintalmanna"
      },
      {
        "state": "Kerala",
        "city": "Ponnani"
      },
      {
        "state": "Kerala",
        "city": "Pulpatta"
      },
      {
        "state": "Kerala",
        "city": "Ramanattukara"
      },
      {
        "state": "Kerala",
        "city": "Thenhipalam"
      },
      {
        "state": "Kerala",
        "city": "Tirur"
      },
      {
        "state": "Kerala",
        "city": "Tirurangadi"
      },
      {
        "state": "Kerala",
        "city": "Alathur"
      },
      {
        "state": "Kerala",
        "city": "Cherpulassery"
      },
      {
        "state": "Kerala",
        "city": "Chittur-Thathamangalam"
      },
      {
        "state": "Kerala",
        "city": "Kozhinjampara"
      },
      {
        "state": "Kerala",
        "city": "Malampuzha-II"
      },
      {
        "state": "Kerala",
        "city": "Mannarkad-I"
      },
      {
        "state": "Kerala",
        "city": "Mannarkad-II"
      },
      {
        "state": "Kerala",
        "city": "Ottpalam"
      },
      {
        "state": "Kerala",
        "city": "Palakkad"
      },
      {
        "state": "Kerala",
        "city": "Vaniyamkulam-I"
      },
      {
        "state": "Kerala",
        "city": "Adur"
      },
      {
        "state": "Kerala",
        "city": "Pathanamthitta"
      },
      {
        "state": "Kerala",
        "city": "Kozhencherry"
      },
      {
        "state": "Kerala",
        "city": "Kulanada"
      },
      {
        "state": "Kerala",
        "city": "Mallappally"
      },
      {
        "state": "Kerala",
        "city": "Ranni"
      },
      {
        "state": "Kerala",
        "city": "Thiruvalla"
      },
      {
        "state": "Kerala",
        "city": "Chirayinkeezhu"
      },
      {
        "state": "Kerala",
        "city": "Chirayinkil"
      },
      {
        "state": "Kerala",
        "city": "Nedumangad"
      },
      {
        "state": "Kerala",
        "city": "Neyyattinkara"
      },
      {
        "state": "Kerala",
        "city": "Trivandrum"
      },
      {
        "state": "Kerala",
        "city": "Chavakkad"
      },
      {
        "state": "Kerala",
        "city": "Thrissur"
      },
      {
        "state": "Kerala",
        "city": "Irinjalakuda"
      },
      {
        "state": "Kerala",
        "city": "Kadappuram"
      },
      {
        "state": "Kerala",
        "city": "Kaiparambu"
      },
      {
        "state": "Kerala",
        "city": "Kodungallur"
      },
      {
        "state": "Kerala",
        "city": "Kottuvally"
      },
      {
        "state": "Kerala",
        "city": "Mala"
      },
      {
        "state": "Kerala",
        "city": "Mukundapuram"
      },
      {
        "state": "Kerala",
        "city": "Thalapilly"
      },
      {
        "state": "Kerala",
        "city": "Wadakkanchery"
      },
      {
        "state": "Kerala",
        "city": "Mananthavady"
      },
      {
        "state": "Kerala",
        "city": "Sultan Bathery"
      },
      {
        "state": "Kerala",
        "city": "Vythiri"
      },
      {
        "state": "Kerala",
        "city": "Vythiti"
      },
      {
        "state": "Kerala",
        "city": "Wayanad"
      },
      {
        "state": "Lakshadweep",
        "city": "Amini"
      },
      {
        "state": "Lakshadweep",
        "city": "Kiltan Island"
      },
      {
        "state": "Lakshadweep",
        "city": "Lakshadweep"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Agar Malwa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Alirajpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Anuppur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Paraswar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pushparajgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ashoknagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chanderi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Esagarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Isagarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Baihar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Balaghat"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Katangi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khairlanji"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kirnapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lalbarra"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lanji"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Tirodi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Waraseoni"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Anjad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Barwani"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Newali"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pansemal"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sendhwa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bahinsdehi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Betul"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhainsdehi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jamaldi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ater"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhind"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Daboh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gohad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lahar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mehgaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mihona"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raun"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Vijaypur Alias Kilhar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhopal"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Burhanpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bada Malhera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chhatarpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bijawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gorihar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nowgong"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Amarwara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chhindwara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bichhua"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chandameta-Butaria"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chandametta"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chaurai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chourai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Harrai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Junnardeo"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pandhurna"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Parasia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sausar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Tamia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Batiyagarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Damoh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Harduwa Khurd"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Hatta"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jabera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Patera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Patharia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pathariya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhander"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Datia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Indergarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Seondha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bagli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Dewas"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kannod"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khategaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sonkatch"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Badnawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Dhar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Dharampuri"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kukshi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Manawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Dindori"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sarmapur Ryt."
      },
      {
        "state": "Madhya Pradesh",
        "city": "Guna"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bamori"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chachawada"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kumbhraj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Maksudangarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raghogarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gwalior"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhitarwar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Harda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bankhedi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Hoshangabad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Itarsi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pipariya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Seoni Malwa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Depalpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Indore"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mhow"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sanwer"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jabalpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kundam"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Patan"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sleemanabad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhavra"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jhabua"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Megh Nagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Petlawad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ranapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Thandla"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bahoriband"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Katni"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rithi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khandwa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Old Harsud"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pandhana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jhirniya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kasrawad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Barwaha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khargone"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhikangaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Maheshwar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Marugarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Zirnia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Badora"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mandla"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhuabichhia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bichhiya Ryt."
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nainpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhanpura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Garoth"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mandsaur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sitamau"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Suwasra"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ambah"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Joura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kailaras"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kelaras"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Morena"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Porsa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sabalgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chhota Chhindwara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Narsinghpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gotegaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kareli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Saikheda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Tendukheda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jawad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Manasa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Neemuch"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Panna"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raipura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bareli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Begumganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gairatganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gouharganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raisen"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Silwani"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Udaipura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Biaora"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Boda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chhapera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chhapiheda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rajgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jirapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khilchipur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khujner"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kurawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Machalpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pachore"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rajgarh(Bia)"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sarangpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Suthaliya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Talen"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Alote"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bajna"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Padliya Umath"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Piploda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ratlam"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Hanumana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kolgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rewa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sirmaur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Teonthar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bina"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Deori"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Deori Chhirari"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Deori Naharmau"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Deori Pathariya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Garhakota"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kesali"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khurai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rahatgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rehli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Singrawan"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Amarpatan"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Maihar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nagod"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raghurajnagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Satna"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sarlanagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Unchehara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Baktara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bilkisganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Budhni"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sehore"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ichhawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kothri"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nasrullaganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rehti"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Siddiqueganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sikandarganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Barghat"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ghansor"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Keolari"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Korai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lakhnadon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Seoni"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Burhar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jaisinghnagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Shahdol"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Agar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Shajapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Akodia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Awantipur Badodia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Berchha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chauki Rahimabad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jamner"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kalapipal Gaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kalisindh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kanad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mohan Babodiya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Moman Badodia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nalkheda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Salsalai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Shujalpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Soyat Kalan"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sundersi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Susner"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bijaypur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bijeypur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Karahal"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sheopur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Karera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khaniadhana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kolaras"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Narwar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pohari"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Shivpuri"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chitrangi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Deosar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Majauli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Majhauli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rampur Naikin"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sidhi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sihawal"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Umariya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Singrauli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Tikamgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jatara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khargapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Orachha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Palera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Barnagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhatpachlana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ujjain"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gata"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ghatiya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jharda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khachrod"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mahidpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nagda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Piploda Sagotimata"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Polay Kalan"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Runija"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Tarana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bandhogarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kila Bandhogarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pali Birsinghpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Umaria"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ganj Basoda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gyaraspur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lateri"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nateran"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sironj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Vidisha"
      },
      {
        "state": "Maharashtra",
        "city": "Ahmednagar"
      },
      {
        "state": "Maharashtra",
        "city": "Akole"
      },
      {
        "state": "Maharashtra",
        "city": "Jamkhed"
      },
      {
        "state": "Maharashtra",
        "city": "Kolgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Kopargaon"
      },
      {
        "state": "Maharashtra",
        "city": "Mhase"
      },
      {
        "state": "Maharashtra",
        "city": "Newasa"
      },
      {
        "state": "Maharashtra",
        "city": "Pathardi"
      },
      {
        "state": "Maharashtra",
        "city": "Rahata"
      },
      {
        "state": "Maharashtra",
        "city": "Rahta Pimplas"
      },
      {
        "state": "Maharashtra",
        "city": "Rahuri"
      },
      {
        "state": "Maharashtra",
        "city": "Shevgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Shrigonda"
      },
      {
        "state": "Maharashtra",
        "city": "Shrirampur"
      },
      {
        "state": "Maharashtra",
        "city": "Akola"
      },
      {
        "state": "Maharashtra",
        "city": "Akot"
      },
      {
        "state": "Maharashtra",
        "city": "Barsi Takli"
      },
      {
        "state": "Maharashtra",
        "city": "Murtizapur"
      },
      {
        "state": "Maharashtra",
        "city": "Risod"
      },
      {
        "state": "Maharashtra",
        "city": "Telhara"
      },
      {
        "state": "Maharashtra",
        "city": "Achalpur"
      },
      {
        "state": "Maharashtra",
        "city": "Amravati"
      },
      {
        "state": "Maharashtra",
        "city": "Anjangaon"
      },
      {
        "state": "Maharashtra",
        "city": "Bhatkuli"
      },
      {
        "state": "Maharashtra",
        "city": "Chandur Bazar"
      },
      {
        "state": "Maharashtra",
        "city": "Chandur Railway"
      },
      {
        "state": "Maharashtra",
        "city": "Chikhaldara"
      },
      {
        "state": "Maharashtra",
        "city": "Daryapur Banosa"
      },
      {
        "state": "Maharashtra",
        "city": "Dharni"
      },
      {
        "state": "Maharashtra",
        "city": "Morshi"
      },
      {
        "state": "Maharashtra",
        "city": "Nandgaon Khandeshwar"
      },
      {
        "state": "Maharashtra",
        "city": "Paratwada"
      },
      {
        "state": "Maharashtra",
        "city": "Teosa"
      },
      {
        "state": "Maharashtra",
        "city": "Warud"
      },
      {
        "state": "Maharashtra",
        "city": "Aurangabad"
      },
      {
        "state": "Maharashtra",
        "city": "Kannad"
      },
      {
        "state": "Maharashtra",
        "city": "Khuldabad"
      },
      {
        "state": "Maharashtra",
        "city": "Malegaon Kannad"
      },
      {
        "state": "Maharashtra",
        "city": "Paithan"
      },
      {
        "state": "Maharashtra",
        "city": "Phulambri"
      },
      {
        "state": "Maharashtra",
        "city": "Soygaon"
      },
      {
        "state": "Maharashtra",
        "city": "Takali Ambad"
      },
      {
        "state": "Maharashtra",
        "city": "Vaijapur"
      },
      {
        "state": "Maharashtra",
        "city": "Ambajogai"
      },
      {
        "state": "Maharashtra",
        "city": "Ambewadgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Beed"
      },
      {
        "state": "Maharashtra",
        "city": "Dharur"
      },
      {
        "state": "Maharashtra",
        "city": "Georai"
      },
      {
        "state": "Maharashtra",
        "city": "Jayakochiwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Kaij"
      },
      {
        "state": "Maharashtra",
        "city": "Parli"
      },
      {
        "state": "Maharashtra",
        "city": "Patoda"
      },
      {
        "state": "Maharashtra",
        "city": "Pimpri Ashti"
      },
      {
        "state": "Maharashtra",
        "city": "Wadwani"
      },
      {
        "state": "Maharashtra",
        "city": "Bhandara"
      },
      {
        "state": "Maharashtra",
        "city": "Bhikarkheda"
      },
      {
        "state": "Maharashtra",
        "city": "Lakhandur"
      },
      {
        "state": "Maharashtra",
        "city": "Lakhni"
      },
      {
        "state": "Maharashtra",
        "city": "Mohadi"
      },
      {
        "state": "Maharashtra",
        "city": "Pauni"
      },
      {
        "state": "Maharashtra",
        "city": "Sakoli"
      },
      {
        "state": "Maharashtra",
        "city": "Tumsar"
      },
      {
        "state": "Maharashtra",
        "city": "Buldhana"
      },
      {
        "state": "Maharashtra",
        "city": "Chikhli"
      },
      {
        "state": "Maharashtra",
        "city": "Deaulgaon Raja"
      },
      {
        "state": "Maharashtra",
        "city": "Deulgaon Mahi"
      },
      {
        "state": "Maharashtra",
        "city": "Jalgaon Jamod"
      },
      {
        "state": "Maharashtra",
        "city": "Khamgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Lonar"
      },
      {
        "state": "Maharashtra",
        "city": "Malkapur"
      },
      {
        "state": "Maharashtra",
        "city": "Mehkar"
      },
      {
        "state": "Maharashtra",
        "city": "Motala"
      },
      {
        "state": "Maharashtra",
        "city": "Nandura"
      },
      {
        "state": "Maharashtra",
        "city": "Sangrampur"
      },
      {
        "state": "Maharashtra",
        "city": "Shegaon"
      },
      {
        "state": "Maharashtra",
        "city": "Sindkhed Raja"
      },
      {
        "state": "Maharashtra",
        "city": "Chandrapur"
      },
      {
        "state": "Maharashtra",
        "city": "Balharshah"
      },
      {
        "state": "Maharashtra",
        "city": "Bhadravati"
      },
      {
        "state": "Maharashtra",
        "city": "Chandur"
      },
      {
        "state": "Maharashtra",
        "city": "Chimur"
      },
      {
        "state": "Maharashtra",
        "city": "Gondpipri"
      },
      {
        "state": "Maharashtra",
        "city": "Jiwati"
      },
      {
        "state": "Maharashtra",
        "city": "Korpana"
      },
      {
        "state": "Maharashtra",
        "city": "Mohdal Bhanguda"
      },
      {
        "state": "Maharashtra",
        "city": "Nagbhid"
      },
      {
        "state": "Maharashtra",
        "city": "Nagbhir"
      },
      {
        "state": "Maharashtra",
        "city": "Pombhurna"
      },
      {
        "state": "Maharashtra",
        "city": "Rajura"
      },
      {
        "state": "Maharashtra",
        "city": "Saoli"
      },
      {
        "state": "Maharashtra",
        "city": "Sawali"
      },
      {
        "state": "Maharashtra",
        "city": "Sindewahi"
      },
      {
        "state": "Maharashtra",
        "city": "Warora"
      },
      {
        "state": "Maharashtra",
        "city": "Dhule"
      },
      {
        "state": "Maharashtra",
        "city": "Parsamal"
      },
      {
        "state": "Maharashtra",
        "city": "Sakri"
      },
      {
        "state": "Maharashtra",
        "city": "Sindkheda"
      },
      {
        "state": "Maharashtra",
        "city": "Aheri"
      },
      {
        "state": "Maharashtra",
        "city": "Gadchiroli"
      },
      {
        "state": "Maharashtra",
        "city": "Armori"
      },
      {
        "state": "Maharashtra",
        "city": "Chamorshi"
      },
      {
        "state": "Maharashtra",
        "city": "Etapalli"
      },
      {
        "state": "Maharashtra",
        "city": "Korchi"
      },
      {
        "state": "Maharashtra",
        "city": "Kurkheda"
      },
      {
        "state": "Maharashtra",
        "city": "Mulchera"
      },
      {
        "state": "Maharashtra",
        "city": "Sironcha Ry."
      },
      {
        "state": "Maharashtra",
        "city": "Amgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Arjuni"
      },
      {
        "state": "Maharashtra",
        "city": "Gondia"
      },
      {
        "state": "Maharashtra",
        "city": "Sadak Arjuni"
      },
      {
        "state": "Maharashtra",
        "city": "Salekasa"
      },
      {
        "state": "Maharashtra",
        "city": "Tirora"
      },
      {
        "state": "Maharashtra",
        "city": "Hingoli"
      },
      {
        "state": "Maharashtra",
        "city": "Kalamnuri"
      },
      {
        "state": "Maharashtra",
        "city": "Sengaon"
      },
      {
        "state": "Maharashtra",
        "city": "Amalner"
      },
      {
        "state": "Maharashtra",
        "city": "Bhadgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Bhusawal"
      },
      {
        "state": "Maharashtra",
        "city": "Bodwad"
      },
      {
        "state": "Maharashtra",
        "city": "Chalisgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Chopda"
      },
      {
        "state": "Maharashtra",
        "city": "Dharangaon"
      },
      {
        "state": "Maharashtra",
        "city": "Erandol"
      },
      {
        "state": "Maharashtra",
        "city": "Jalgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Muktainagar"
      },
      {
        "state": "Maharashtra",
        "city": "Pachora"
      },
      {
        "state": "Maharashtra",
        "city": "Parola"
      },
      {
        "state": "Maharashtra",
        "city": "Pimpri Bk.Pr.Pachora"
      },
      {
        "state": "Maharashtra",
        "city": "Raver"
      },
      {
        "state": "Maharashtra",
        "city": "Yawal"
      },
      {
        "state": "Maharashtra",
        "city": "Jalna"
      },
      {
        "state": "Maharashtra",
        "city": "Ghansawangi"
      },
      {
        "state": "Maharashtra",
        "city": "Harat Kheda"
      },
      {
        "state": "Maharashtra",
        "city": "Kodgaon Ambad"
      },
      {
        "state": "Maharashtra",
        "city": "Ramkheda"
      },
      {
        "state": "Maharashtra",
        "city": "Kolhapur"
      },
      {
        "state": "Maharashtra",
        "city": "Ajara"
      },
      {
        "state": "Maharashtra",
        "city": "Bhuye"
      },
      {
        "state": "Maharashtra",
        "city": "Chandgad"
      },
      {
        "state": "Maharashtra",
        "city": "Dholagarwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Gaganbavada"
      },
      {
        "state": "Maharashtra",
        "city": "Gaganbawda"
      },
      {
        "state": "Maharashtra",
        "city": "Hatkanangale"
      },
      {
        "state": "Maharashtra",
        "city": "Kagal"
      },
      {
        "state": "Maharashtra",
        "city": "Kudnur"
      },
      {
        "state": "Maharashtra",
        "city": "Panhala"
      },
      {
        "state": "Maharashtra",
        "city": "Radhanagari"
      },
      {
        "state": "Maharashtra",
        "city": "Ranewadi"
      },
      {
        "state": "Maharashtra",
        "city": "Shahuwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Shirol"
      },
      {
        "state": "Maharashtra",
        "city": "Tambale"
      },
      {
        "state": "Maharashtra",
        "city": "Jalkot"
      },
      {
        "state": "Maharashtra",
        "city": "Latur"
      },
      {
        "state": "Maharashtra",
        "city": "Nilanga"
      },
      {
        "state": "Maharashtra",
        "city": "Udgir"
      },
      {
        "state": "Maharashtra",
        "city": "Mumbai Suburban"
      },
      {
        "state": "Maharashtra",
        "city": "Bhiwapur"
      },
      {
        "state": "Maharashtra",
        "city": "Godhani"
      },
      {
        "state": "Maharashtra",
        "city": "Hingna"
      },
      {
        "state": "Maharashtra",
        "city": "Kalameshwar"
      },
      {
        "state": "Maharashtra",
        "city": "Kamptee"
      },
      {
        "state": "Maharashtra",
        "city": "Katol"
      },
      {
        "state": "Maharashtra",
        "city": "Kuhi"
      },
      {
        "state": "Maharashtra",
        "city": "Mauda"
      },
      {
        "state": "Maharashtra",
        "city": "Nagpur"
      },
      {
        "state": "Maharashtra",
        "city": "Narkhed"
      },
      {
        "state": "Maharashtra",
        "city": "Parshivni"
      },
      {
        "state": "Maharashtra",
        "city": "Ramtek"
      },
      {
        "state": "Maharashtra",
        "city": "Ranala"
      },
      {
        "state": "Maharashtra",
        "city": "Savner"
      },
      {
        "state": "Maharashtra",
        "city": "Tembhari"
      },
      {
        "state": "Maharashtra",
        "city": "Umred"
      },
      {
        "state": "Maharashtra",
        "city": "Nanded"
      },
      {
        "state": "Maharashtra",
        "city": "Ardhapur"
      },
      {
        "state": "Maharashtra",
        "city": "Bhokar"
      },
      {
        "state": "Maharashtra",
        "city": "Biloli"
      },
      {
        "state": "Maharashtra",
        "city": "Degloor"
      },
      {
        "state": "Maharashtra",
        "city": "Dharmabad"
      },
      {
        "state": "Maharashtra",
        "city": "Hadgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Himayatnagar"
      },
      {
        "state": "Maharashtra",
        "city": "Kandhar"
      },
      {
        "state": "Maharashtra",
        "city": "Kinwat"
      },
      {
        "state": "Maharashtra",
        "city": "Kiwat"
      },
      {
        "state": "Maharashtra",
        "city": "Mudkhed"
      },
      {
        "state": "Maharashtra",
        "city": "Mukhed"
      },
      {
        "state": "Maharashtra",
        "city": "Naigaon"
      },
      {
        "state": "Maharashtra",
        "city": "Peth Umri"
      },
      {
        "state": "Maharashtra",
        "city": "Akkalkuva"
      },
      {
        "state": "Maharashtra",
        "city": "Chandsaili"
      },
      {
        "state": "Maharashtra",
        "city": "Dhadgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Nandurbar"
      },
      {
        "state": "Maharashtra",
        "city": "Navapur"
      },
      {
        "state": "Maharashtra",
        "city": "Shahade"
      },
      {
        "state": "Maharashtra",
        "city": "Taloda"
      },
      {
        "state": "Maharashtra",
        "city": "Anjaneri"
      },
      {
        "state": "Maharashtra",
        "city": "Chandwad"
      },
      {
        "state": "Maharashtra",
        "city": "Nashik"
      },
      {
        "state": "Maharashtra",
        "city": "Karanjali"
      },
      {
        "state": "Maharashtra",
        "city": "Niphad"
      },
      {
        "state": "Maharashtra",
        "city": "Peint"
      },
      {
        "state": "Maharashtra",
        "city": "Peth"
      },
      {
        "state": "Maharashtra",
        "city": "Satana"
      },
      {
        "state": "Maharashtra",
        "city": "Sinnar"
      },
      {
        "state": "Maharashtra",
        "city": "Surgana"
      },
      {
        "state": "Maharashtra",
        "city": "Trimbak"
      },
      {
        "state": "Maharashtra",
        "city": "Yeola"
      },
      {
        "state": "Maharashtra",
        "city": "Osmanabad"
      },
      {
        "state": "Maharashtra",
        "city": "Bhoom"
      },
      {
        "state": "Maharashtra",
        "city": "Kallam"
      },
      {
        "state": "Maharashtra",
        "city": "Lohara"
      },
      {
        "state": "Maharashtra",
        "city": "Omerga"
      },
      {
        "state": "Maharashtra",
        "city": "Tuljapur"
      },
      {
        "state": "Maharashtra",
        "city": "Palghar"
      },
      {
        "state": "Maharashtra",
        "city": "Gangakhed"
      },
      {
        "state": "Maharashtra",
        "city": "Parbhani"
      },
      {
        "state": "Maharashtra",
        "city": "Jintur"
      },
      {
        "state": "Maharashtra",
        "city": "Manwath"
      },
      {
        "state": "Maharashtra",
        "city": "Purna"
      },
      {
        "state": "Maharashtra",
        "city": "Selu"
      },
      {
        "state": "Maharashtra",
        "city": "Sonpeth"
      },
      {
        "state": "Maharashtra",
        "city": "Pune"
      },
      {
        "state": "Maharashtra",
        "city": "Akluj"
      },
      {
        "state": "Maharashtra",
        "city": "Ambegaon"
      },
      {
        "state": "Maharashtra",
        "city": "Baramati"
      },
      {
        "state": "Maharashtra",
        "city": "Bhavaninagar"
      },
      {
        "state": "Maharashtra",
        "city": "Daund"
      },
      {
        "state": "Maharashtra",
        "city": "Gherapurandhar"
      },
      {
        "state": "Maharashtra",
        "city": "Gorewasti"
      },
      {
        "state": "Maharashtra",
        "city": "Indapur"
      },
      {
        "state": "Maharashtra",
        "city": "Junnar"
      },
      {
        "state": "Maharashtra",
        "city": "Lonavala"
      },
      {
        "state": "Maharashtra",
        "city": "Malegaon Bk"
      },
      {
        "state": "Maharashtra",
        "city": "Manchar"
      },
      {
        "state": "Maharashtra",
        "city": "Mawalgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Mohopada"
      },
      {
        "state": "Maharashtra",
        "city": "Naygaon"
      },
      {
        "state": "Maharashtra",
        "city": "Pimpalgaon Tarf Khed"
      },
      {
        "state": "Maharashtra",
        "city": "Pimpri-Chinchwad"
      },
      {
        "state": "Maharashtra",
        "city": "Rajgurunagar"
      },
      {
        "state": "Maharashtra",
        "city": "Saswad"
      },
      {
        "state": "Maharashtra",
        "city": "Vadgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Varvand"
      },
      {
        "state": "Maharashtra",
        "city": "Velhe"
      },
      {
        "state": "Maharashtra",
        "city": "Alibag"
      },
      {
        "state": "Maharashtra",
        "city": "Ghera Sudhagad"
      },
      {
        "state": "Maharashtra",
        "city": "Khopoli"
      },
      {
        "state": "Maharashtra",
        "city": "Mahad"
      },
      {
        "state": "Maharashtra",
        "city": "Mangaon"
      },
      {
        "state": "Maharashtra",
        "city": "Matheran"
      },
      {
        "state": "Maharashtra",
        "city": "Mhasala"
      },
      {
        "state": "Maharashtra",
        "city": "Murud"
      },
      {
        "state": "Maharashtra",
        "city": "Murud rural"
      },
      {
        "state": "Maharashtra",
        "city": "Panvel"
      },
      {
        "state": "Maharashtra",
        "city": "Pen"
      },
      {
        "state": "Maharashtra",
        "city": "Poladpur"
      },
      {
        "state": "Maharashtra",
        "city": "Raigad"
      },
      {
        "state": "Maharashtra",
        "city": "Roha"
      },
      {
        "state": "Maharashtra",
        "city": "Shrivardhan"
      },
      {
        "state": "Maharashtra",
        "city": "Ratnagiri"
      },
      {
        "state": "Maharashtra",
        "city": "Chiplun"
      },
      {
        "state": "Maharashtra",
        "city": "Dapoli"
      },
      {
        "state": "Maharashtra",
        "city": "Guhagar"
      },
      {
        "state": "Maharashtra",
        "city": "Lanja"
      },
      {
        "state": "Maharashtra",
        "city": "Mandangad"
      },
      {
        "state": "Maharashtra",
        "city": "Mundhe Tarf Chiplun"
      },
      {
        "state": "Maharashtra",
        "city": "Rajapur"
      },
      {
        "state": "Maharashtra",
        "city": "Sangameshwar"
      },
      {
        "state": "Maharashtra",
        "city": "Talvat Khed"
      },
      {
        "state": "Maharashtra",
        "city": "Talvat Pal"
      },
      {
        "state": "Maharashtra",
        "city": "Atpadi"
      },
      {
        "state": "Maharashtra",
        "city": "Jath"
      },
      {
        "state": "Maharashtra",
        "city": "Kadegaon"
      },
      {
        "state": "Maharashtra",
        "city": "Kahanapur"
      },
      {
        "state": "Maharashtra",
        "city": "Kavathe Mahankal"
      },
      {
        "state": "Maharashtra",
        "city": "Khanapur"
      },
      {
        "state": "Maharashtra",
        "city": "Mandur"
      },
      {
        "state": "Maharashtra",
        "city": "Miraj"
      },
      {
        "state": "Maharashtra",
        "city": "Sangli"
      },
      {
        "state": "Maharashtra",
        "city": "Palus"
      },
      {
        "state": "Maharashtra",
        "city": "Panumbre Tarf Shirala"
      },
      {
        "state": "Maharashtra",
        "city": "Shirala"
      },
      {
        "state": "Maharashtra",
        "city": "Tasgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Walwa"
      },
      {
        "state": "Maharashtra",
        "city": "Satara"
      },
      {
        "state": "Maharashtra",
        "city": "Bavada"
      },
      {
        "state": "Maharashtra",
        "city": "Dahiwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Dhondewadi"
      },
      {
        "state": "Maharashtra",
        "city": "Javali"
      },
      {
        "state": "Maharashtra",
        "city": "Kahatav"
      },
      {
        "state": "Maharashtra",
        "city": "Karad"
      },
      {
        "state": "Maharashtra",
        "city": "Khandala"
      },
      {
        "state": "Maharashtra",
        "city": "Khatav"
      },
      {
        "state": "Maharashtra",
        "city": "Kikali"
      },
      {
        "state": "Maharashtra",
        "city": "Koregaon"
      },
      {
        "state": "Maharashtra",
        "city": "Mahabaleshwar"
      },
      {
        "state": "Maharashtra",
        "city": "Phaltan"
      },
      {
        "state": "Maharashtra",
        "city": "Shekhmirewadi"
      },
      {
        "state": "Maharashtra",
        "city": "Shirwal"
      },
      {
        "state": "Maharashtra",
        "city": "Wai"
      },
      {
        "state": "Maharashtra",
        "city": "Sindhudurg"
      },
      {
        "state": "Maharashtra",
        "city": "Devgad"
      },
      {
        "state": "Maharashtra",
        "city": "Dodamarg"
      },
      {
        "state": "Maharashtra",
        "city": "Kankavali"
      },
      {
        "state": "Maharashtra",
        "city": "Malvan"
      },
      {
        "state": "Maharashtra",
        "city": "Oros"
      },
      {
        "state": "Maharashtra",
        "city": "Sawantwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Vaibhavwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Vengurla"
      },
      {
        "state": "Maharashtra",
        "city": "Akkalkot"
      },
      {
        "state": "Maharashtra",
        "city": "Barshi"
      },
      {
        "state": "Maharashtra",
        "city": "Karmala"
      },
      {
        "state": "Maharashtra",
        "city": "Karmala Rural"
      },
      {
        "state": "Maharashtra",
        "city": "Kurduwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Madha"
      },
      {
        "state": "Maharashtra",
        "city": "Malshiras"
      },
      {
        "state": "Maharashtra",
        "city": "Mangalwedha"
      },
      {
        "state": "Maharashtra",
        "city": "Mohol"
      },
      {
        "state": "Maharashtra",
        "city": "Solapur"
      },
      {
        "state": "Maharashtra",
        "city": "Sangola"
      },
      {
        "state": "Maharashtra",
        "city": "Thane"
      },
      {
        "state": "Maharashtra",
        "city": "Ambernath"
      },
      {
        "state": "Maharashtra",
        "city": "Bhiwandi"
      },
      {
        "state": "Maharashtra",
        "city": "Dahanu"
      },
      {
        "state": "Maharashtra",
        "city": "Jawhar"
      },
      {
        "state": "Maharashtra",
        "city": "Kalyan"
      },
      {
        "state": "Maharashtra",
        "city": "Mira Bhayandar"
      },
      {
        "state": "Maharashtra",
        "city": "Mokhada"
      },
      {
        "state": "Maharashtra",
        "city": "Murbad"
      },
      {
        "state": "Maharashtra",
        "city": "Shahapur"
      },
      {
        "state": "Maharashtra",
        "city": "Talasari"
      },
      {
        "state": "Maharashtra",
        "city": "Vasai"
      },
      {
        "state": "Maharashtra",
        "city": "Vikramgad"
      },
      {
        "state": "Maharashtra",
        "city": "Wada"
      },
      {
        "state": "Maharashtra",
        "city": "Wadhe"
      },
      {
        "state": "Maharashtra",
        "city": "Hinganghat"
      },
      {
        "state": "Maharashtra",
        "city": "Karanja (Ghadge)"
      },
      {
        "state": "Maharashtra",
        "city": "Pulgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Samudrapur"
      },
      {
        "state": "Maharashtra",
        "city": "Seloo"
      },
      {
        "state": "Maharashtra",
        "city": "Wardha"
      },
      {
        "state": "Maharashtra",
        "city": "Washim"
      },
      {
        "state": "Maharashtra",
        "city": "Mangrulpir"
      },
      {
        "state": "Maharashtra",
        "city": "Yavatmal"
      },
      {
        "state": "Maharashtra",
        "city": "Babhulgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Darwha"
      },
      {
        "state": "Maharashtra",
        "city": "Digras"
      },
      {
        "state": "Maharashtra",
        "city": "Ghatanji"
      },
      {
        "state": "Maharashtra",
        "city": "Kelapur"
      },
      {
        "state": "Maharashtra",
        "city": "Mahagaon"
      },
      {
        "state": "Maharashtra",
        "city": "Maregaon"
      },
      {
        "state": "Maharashtra",
        "city": "Pusad"
      },
      {
        "state": "Maharashtra",
        "city": "Ralegaon"
      },
      {
        "state": "Maharashtra",
        "city": "Umarkhed"
      },
      {
        "state": "Maharashtra",
        "city": "Wani"
      },
      {
        "state": "Manipur",
        "city": "Imphal East"
      },
      {
        "state": "Manipur",
        "city": "Thoubal"
      },
      {
        "state": "Manipur",
        "city": "Chandel"
      },
      {
        "state": "Manipur",
        "city": "Churachandpur"
      },
      {
        "state": "Manipur",
        "city": "Imphal West"
      },
      {
        "state": "Manipur",
        "city": "Mandop Yumpham"
      },
      {
        "state": "Manipur",
        "city": "Jiribam"
      },
      {
        "state": "Manipur",
        "city": "Kakching"
      },
      {
        "state": "Manipur",
        "city": "Kamjong"
      },
      {
        "state": "Manipur",
        "city": "Kangpokpi"
      },
      {
        "state": "Manipur",
        "city": "Noney"
      },
      {
        "state": "Manipur",
        "city": "Pherzawl"
      },
      {
        "state": "Manipur",
        "city": "Senapati"
      },
      {
        "state": "Manipur",
        "city": "Tamenglong"
      },
      {
        "state": "Manipur",
        "city": "Tengnoupal"
      },
      {
        "state": "Manipur",
        "city": "Ukhrul"
      },
      {
        "state": "Meghalaya",
        "city": "Dambo Rongjeng"
      },
      {
        "state": "Meghalaya",
        "city": "Dudnai"
      },
      {
        "state": "Meghalaya",
        "city": "Rongjeng"
      },
      {
        "state": "Meghalaya",
        "city": "Williamnagar"
      },
      {
        "state": "Meghalaya",
        "city": "Khliehriat"
      },
      {
        "state": "Meghalaya",
        "city": "Lad Rymbai"
      },
      {
        "state": "Meghalaya",
        "city": "Bholaganj Bazar"
      },
      {
        "state": "Meghalaya",
        "city": "Cherrabazar"
      },
      {
        "state": "Meghalaya",
        "city": "Cherrapunjee"
      },
      {
        "state": "Meghalaya",
        "city": "East Khasi Hills"
      },
      {
        "state": "Meghalaya",
        "city": "Laban"
      },
      {
        "state": "Meghalaya",
        "city": "Laitkor Peak"
      },
      {
        "state": "Meghalaya",
        "city": "Madanriting"
      },
      {
        "state": "Meghalaya",
        "city": "Mawkynrew"
      },
      {
        "state": "Meghalaya",
        "city": "Mawlai"
      },
      {
        "state": "Meghalaya",
        "city": "Mawpat"
      },
      {
        "state": "Meghalaya",
        "city": "Mawsynram"
      },
      {
        "state": "Meghalaya",
        "city": "Nonglyer"
      },
      {
        "state": "Meghalaya",
        "city": "Pynursla"
      },
      {
        "state": "Meghalaya",
        "city": "Rynjah"
      },
      {
        "state": "Meghalaya",
        "city": "Shella"
      },
      {
        "state": "Meghalaya",
        "city": "Shillong"
      },
      {
        "state": "Meghalaya",
        "city": "Smit"
      },
      {
        "state": "Meghalaya",
        "city": "Synrang Kaban"
      },
      {
        "state": "Meghalaya",
        "city": "North Garo Hills"
      },
      {
        "state": "Meghalaya",
        "city": "Byrnihat"
      },
      {
        "state": "Meghalaya",
        "city": "Nongpoh"
      },
      {
        "state": "Meghalaya",
        "city": "UCC"
      },
      {
        "state": "Meghalaya",
        "city": "Umiam"
      },
      {
        "state": "Meghalaya",
        "city": "Umsaw"
      },
      {
        "state": "Meghalaya",
        "city": "Umsning"
      },
      {
        "state": "Meghalaya",
        "city": "Baghmara"
      },
      {
        "state": "Meghalaya",
        "city": "Rongra"
      },
      {
        "state": "Meghalaya",
        "city": "South West Garo Hills"
      },
      {
        "state": "Meghalaya",
        "city": "South West Khasi Hills"
      },
      {
        "state": "Meghalaya",
        "city": "Ampati"
      },
      {
        "state": "Meghalaya",
        "city": "Araimile"
      },
      {
        "state": "Meghalaya",
        "city": "Barengapara"
      },
      {
        "state": "Meghalaya",
        "city": "Dobasipara"
      },
      {
        "state": "Meghalaya",
        "city": "Fulbari"
      },
      {
        "state": "Meghalaya",
        "city": "Garobadha"
      },
      {
        "state": "Meghalaya",
        "city": "Mahendraganj"
      },
      {
        "state": "Meghalaya",
        "city": "Mendipathar"
      },
      {
        "state": "Meghalaya",
        "city": "Phulbari"
      },
      {
        "state": "Meghalaya",
        "city": "Tikrikilla"
      },
      {
        "state": "Meghalaya",
        "city": "Tura"
      },
      {
        "state": "Meghalaya",
        "city": "Amlarem"
      },
      {
        "state": "Meghalaya",
        "city": "Jowai"
      },
      {
        "state": "Meghalaya",
        "city": "West Khasi Hills"
      },
      {
        "state": "Meghalaya",
        "city": "Mairang"
      },
      {
        "state": "Meghalaya",
        "city": "Mawkyrwat"
      },
      {
        "state": "Meghalaya",
        "city": "Nongstoin"
      },
      {
        "state": "Mizoram",
        "city": "Aibawk"
      },
      {
        "state": "Mizoram",
        "city": "Aizawl"
      },
      {
        "state": "Mizoram",
        "city": "Bawngkawn"
      },
      {
        "state": "Mizoram",
        "city": "Darlawn"
      },
      {
        "state": "Mizoram",
        "city": "Kulikawn"
      },
      {
        "state": "Mizoram",
        "city": "Phullen"
      },
      {
        "state": "Mizoram",
        "city": "Saitual"
      },
      {
        "state": "Mizoram",
        "city": "Sialsuk"
      },
      {
        "state": "Mizoram",
        "city": "Sihphir"
      },
      {
        "state": "Mizoram",
        "city": "Thingsulthliah"
      },
      {
        "state": "Mizoram",
        "city": "Zemabawk"
      },
      {
        "state": "Mizoram",
        "city": "Champhai"
      },
      {
        "state": "Mizoram",
        "city": "Khawbung"
      },
      {
        "state": "Mizoram",
        "city": "Khawzawl"
      },
      {
        "state": "Mizoram",
        "city": "Ngopa"
      },
      {
        "state": "Mizoram",
        "city": "S.khawbung"
      },
      {
        "state": "Mizoram",
        "city": "Kolasib"
      },
      {
        "state": "Mizoram",
        "city": "Bilkhawthlir"
      },
      {
        "state": "Mizoram",
        "city": "Kawnpui"
      },
      {
        "state": "Mizoram",
        "city": "N.Kawnpui"
      },
      {
        "state": "Mizoram",
        "city": "North Thingdawl"
      },
      {
        "state": "Mizoram",
        "city": "Vairengte"
      },
      {
        "state": "Mizoram",
        "city": "Lawngtlai"
      },
      {
        "state": "Mizoram",
        "city": "Bunghmun"
      },
      {
        "state": "Mizoram",
        "city": "Demagiri"
      },
      {
        "state": "Mizoram",
        "city": "Hanhthial"
      },
      {
        "state": "Mizoram",
        "city": "Lunglei"
      },
      {
        "state": "Mizoram",
        "city": "Lungsen"
      },
      {
        "state": "Mizoram",
        "city": "Thingsai"
      },
      {
        "state": "Mizoram",
        "city": "Tlabung"
      },
      {
        "state": "Mizoram",
        "city": "W.Bunghmun"
      },
      {
        "state": "Mizoram",
        "city": "Zotlang"
      },
      {
        "state": "Mizoram",
        "city": "Mamit"
      },
      {
        "state": "Mizoram",
        "city": "Phaileng"
      },
      {
        "state": "Mizoram",
        "city": "Reiek"
      },
      {
        "state": "Mizoram",
        "city": "Sairang"
      },
      {
        "state": "Mizoram",
        "city": "Siaha"
      },
      {
        "state": "Mizoram",
        "city": "Tuipang"
      },
      {
        "state": "Mizoram",
        "city": "Serchhip"
      },
      {
        "state": "Nagaland",
        "city": "Artc"
      },
      {
        "state": "Nagaland",
        "city": "Chumukedima"
      },
      {
        "state": "Nagaland",
        "city": "Dimapur"
      },
      {
        "state": "Nagaland",
        "city": "Medziphema"
      },
      {
        "state": "Nagaland",
        "city": "Kiphire"
      },
      {
        "state": "Nagaland",
        "city": "Chiechama"
      },
      {
        "state": "Nagaland",
        "city": "Kohima"
      },
      {
        "state": "Nagaland",
        "city": "Imphal"
      },
      {
        "state": "Nagaland",
        "city": "Tseminyu"
      },
      {
        "state": "Nagaland",
        "city": "Longleng"
      },
      {
        "state": "Nagaland",
        "city": "Changtongya"
      },
      {
        "state": "Nagaland",
        "city": "Chuchulang"
      },
      {
        "state": "Nagaland",
        "city": "Chuchuyimlang"
      },
      {
        "state": "Nagaland",
        "city": "Impur"
      },
      {
        "state": "Nagaland",
        "city": "Mokokchung"
      },
      {
        "state": "Nagaland",
        "city": "Mangkolemba"
      },
      {
        "state": "Nagaland",
        "city": "Ongpang Kong"
      },
      {
        "state": "Nagaland",
        "city": "Tuli"
      },
      {
        "state": "Nagaland",
        "city": "Champang"
      },
      {
        "state": "Nagaland",
        "city": "Mon"
      },
      {
        "state": "Nagaland",
        "city": "Naginimora"
      },
      {
        "state": "Nagaland",
        "city": "Tizit"
      },
      {
        "state": "Nagaland",
        "city": "Jalukie"
      },
      {
        "state": "Nagaland",
        "city": "Peren"
      },
      {
        "state": "Nagaland",
        "city": "Zalukie"
      },
      {
        "state": "Nagaland",
        "city": "Chizami"
      },
      {
        "state": "Nagaland",
        "city": "Chozuba"
      },
      {
        "state": "Nagaland",
        "city": "Phek"
      },
      {
        "state": "Nagaland",
        "city": "Meluri"
      },
      {
        "state": "Nagaland",
        "city": "Pfutsero"
      },
      {
        "state": "Nagaland",
        "city": "Longkhim"
      },
      {
        "state": "Nagaland",
        "city": "Noklak"
      },
      {
        "state": "Nagaland",
        "city": "Tuensang"
      },
      {
        "state": "Nagaland",
        "city": "Bhandari"
      },
      {
        "state": "Nagaland",
        "city": "Doyang Hydro Project"
      },
      {
        "state": "Nagaland",
        "city": "Wokha"
      },
      {
        "state": "Nagaland",
        "city": "Zakhama"
      },
      {
        "state": "Nagaland",
        "city": "Aghunato"
      },
      {
        "state": "Nagaland",
        "city": "Akuluto"
      },
      {
        "state": "Nagaland",
        "city": "Atoizu"
      },
      {
        "state": "Nagaland",
        "city": "Zunheboto"
      },
      {
        "state": "Odisha",
        "city": "Angul"
      },
      {
        "state": "Odisha",
        "city": "Athmallik"
      },
      {
        "state": "Odisha",
        "city": "Banarpal"
      },
      {
        "state": "Odisha",
        "city": "Chhendipada"
      },
      {
        "state": "Odisha",
        "city": "Colliery"
      },
      {
        "state": "Odisha",
        "city": "Kaniha"
      },
      {
        "state": "Odisha",
        "city": "Palasahi"
      },
      {
        "state": "Odisha",
        "city": "Pallahara"
      },
      {
        "state": "Odisha",
        "city": "Rengali Dam Projectship"
      },
      {
        "state": "Odisha",
        "city": "Rengali Project Town"
      },
      {
        "state": "Odisha",
        "city": "Samal Barrage"
      },
      {
        "state": "Odisha",
        "city": "Thakurgarh"
      },
      {
        "state": "Odisha",
        "city": "Balangir"
      },
      {
        "state": "Odisha",
        "city": "Kantabanji"
      },
      {
        "state": "Odisha",
        "city": "Khaprakhol"
      },
      {
        "state": "Odisha",
        "city": "Loisingha"
      },
      {
        "state": "Odisha",
        "city": "Patnagarh"
      },
      {
        "state": "Odisha",
        "city": "S.rampur"
      },
      {
        "state": "Odisha",
        "city": "Sindhekela"
      },
      {
        "state": "Odisha",
        "city": "Titilagarh"
      },
      {
        "state": "Odisha",
        "city": "Tusura"
      },
      {
        "state": "Odisha",
        "city": "Balasore"
      },
      {
        "state": "Odisha",
        "city": "Baliapal"
      },
      {
        "state": "Odisha",
        "city": "Bhograi"
      },
      {
        "state": "Odisha",
        "city": "Jaleswar"
      },
      {
        "state": "Odisha",
        "city": "Kupari"
      },
      {
        "state": "Odisha",
        "city": "Markona"
      },
      {
        "state": "Odisha",
        "city": "Nilagiri"
      },
      {
        "state": "Odisha",
        "city": "Raibania"
      },
      {
        "state": "Odisha",
        "city": "Remuna"
      },
      {
        "state": "Odisha",
        "city": "Rupsa"
      },
      {
        "state": "Odisha",
        "city": "samkona"
      },
      {
        "state": "Odisha",
        "city": "Simulia"
      },
      {
        "state": "Odisha",
        "city": "Soro"
      },
      {
        "state": "Odisha",
        "city": "Ambabhona"
      },
      {
        "state": "Odisha",
        "city": "Attabira"
      },
      {
        "state": "Odisha",
        "city": "Badmal"
      },
      {
        "state": "Odisha",
        "city": "Barapali Town"
      },
      {
        "state": "Odisha",
        "city": "Bargarh"
      },
      {
        "state": "Odisha",
        "city": "Barpali"
      },
      {
        "state": "Odisha",
        "city": "Bhatli"
      },
      {
        "state": "Odisha",
        "city": "Dumerpali"
      },
      {
        "state": "Odisha",
        "city": "Gaisilat"
      },
      {
        "state": "Odisha",
        "city": "Jharbandh"
      },
      {
        "state": "Odisha",
        "city": "Krafadgang"
      },
      {
        "state": "Odisha",
        "city": "Melchhamunda"
      },
      {
        "state": "Odisha",
        "city": "Padmapur Town"
      },
      {
        "state": "Odisha",
        "city": "Paikamal"
      },
      {
        "state": "Odisha",
        "city": "Paikmal"
      },
      {
        "state": "Odisha",
        "city": "Patrapali"
      },
      {
        "state": "Odisha",
        "city": "Sohela"
      },
      {
        "state": "Odisha",
        "city": "Basudebpur"
      },
      {
        "state": "Odisha",
        "city": "Bhadrak"
      },
      {
        "state": "Odisha",
        "city": "Bhadrak Rural"
      },
      {
        "state": "Odisha",
        "city": "Bhandari Pokhari"
      },
      {
        "state": "Odisha",
        "city": "Chandbali"
      },
      {
        "state": "Odisha",
        "city": "Dhamanagar"
      },
      {
        "state": "Odisha",
        "city": "Nandipada"
      },
      {
        "state": "Odisha",
        "city": "Purusandha"
      },
      {
        "state": "Odisha",
        "city": "Randia"
      },
      {
        "state": "Odisha",
        "city": "Surajapur"
      },
      {
        "state": "Odisha",
        "city": "Tihidi"
      },
      {
        "state": "Odisha",
        "city": "Baghiabahal"
      },
      {
        "state": "Odisha",
        "city": "Boudh"
      },
      {
        "state": "Odisha",
        "city": "Baunsuni"
      },
      {
        "state": "Odisha",
        "city": "Biranarasinghpur"
      },
      {
        "state": "Odisha",
        "city": "Bounsuni"
      },
      {
        "state": "Odisha",
        "city": "Jhadrajing"
      },
      {
        "state": "Odisha",
        "city": "Kantamal"
      },
      {
        "state": "Odisha",
        "city": "Manamunda"
      },
      {
        "state": "Odisha",
        "city": "Puranakatak"
      },
      {
        "state": "Odisha",
        "city": "Sarsara"
      },
      {
        "state": "Odisha",
        "city": "Cuttack"
      },
      {
        "state": "Odisha",
        "city": "Athagarh"
      },
      {
        "state": "Odisha",
        "city": "Badamba"
      },
      {
        "state": "Odisha",
        "city": "Barang"
      },
      {
        "state": "Odisha",
        "city": "Chaudwar"
      },
      {
        "state": "Odisha",
        "city": "Kesannagar"
      },
      {
        "state": "Odisha",
        "city": "Mankha"
      },
      {
        "state": "Odisha",
        "city": "Mundali"
      },
      {
        "state": "Odisha",
        "city": "Narasinghpur"
      },
      {
        "state": "Odisha",
        "city": "Nizigarh"
      },
      {
        "state": "Odisha",
        "city": "Phulnakhara"
      },
      {
        "state": "Odisha",
        "city": "Salipur"
      },
      {
        "state": "Odisha",
        "city": "Tigiria"
      },
      {
        "state": "Odisha",
        "city": "Urali"
      },
      {
        "state": "Odisha",
        "city": "Barkote"
      },
      {
        "state": "Odisha",
        "city": "Debagarh"
      },
      {
        "state": "Odisha",
        "city": "Kundheigola"
      },
      {
        "state": "Odisha",
        "city": "Tileibani"
      },
      {
        "state": "Odisha",
        "city": "Bhuban"
      },
      {
        "state": "Odisha",
        "city": "Dhenkanal"
      },
      {
        "state": "Odisha",
        "city": "Dhenkanal Sadar"
      },
      {
        "state": "Odisha",
        "city": "Gandia"
      },
      {
        "state": "Odisha",
        "city": "Kamakhyanagar"
      },
      {
        "state": "Odisha",
        "city": "Parjang"
      },
      {
        "state": "Odisha",
        "city": "Ranjagola"
      },
      {
        "state": "Odisha",
        "city": "Rasol"
      },
      {
        "state": "Odisha",
        "city": "Satmile"
      },
      {
        "state": "Odisha",
        "city": "Tumusingha"
      },
      {
        "state": "Odisha",
        "city": "Adava"
      },
      {
        "state": "Odisha",
        "city": "Saintala"
      },
      {
        "state": "Odisha",
        "city": "Belpada"
      },
      {
        "state": "Odisha",
        "city": "Tushra"
      },
      {
        "state": "Odisha",
        "city": "Agalpur"
      },
      {
        "state": "Odisha",
        "city": "Deogaon"
      },
      {
        "state": "Odisha",
        "city": "Chudapali"
      },
      {
        "state": "Odisha",
        "city": "Biripali"
      },
      {
        "state": "Odisha",
        "city": "Bhalumunda"
      },
      {
        "state": "Odisha",
        "city": "Bangomunda"
      },
      {
        "state": "Odisha",
        "city": "Turekela"
      },
      {
        "state": "Odisha",
        "city": "Gajapati"
      },
      {
        "state": "Odisha",
        "city": "Garabandha"
      },
      {
        "state": "Odisha",
        "city": "Kashinagar"
      },
      {
        "state": "Odisha",
        "city": "R.udayagiri"
      },
      {
        "state": "Odisha",
        "city": "Ganjam"
      },
      {
        "state": "Odisha",
        "city": "Ankush Pur"
      },
      {
        "state": "Odisha",
        "city": "Asika"
      },
      {
        "state": "Odisha",
        "city": "Bhanjanagar"
      },
      {
        "state": "Odisha",
        "city": "Bhishmagiri"
      },
      {
        "state": "Odisha",
        "city": "Brahmapur"
      },
      {
        "state": "Odisha",
        "city": "Buguda"
      },
      {
        "state": "Odisha",
        "city": "Chatrapur"
      },
      {
        "state": "Odisha",
        "city": "Chikiti"
      },
      {
        "state": "Odisha",
        "city": "Digapahandi"
      },
      {
        "state": "Odisha",
        "city": "Girisola"
      },
      {
        "state": "Odisha",
        "city": "Gobindanagar"
      },
      {
        "state": "Odisha",
        "city": "Hinjillikatu"
      },
      {
        "state": "Odisha",
        "city": "Jarada"
      },
      {
        "state": "Odisha",
        "city": "Jhadabai"
      },
      {
        "state": "Odisha",
        "city": "Kavisuryanagar"
      },
      {
        "state": "Odisha",
        "city": "Khallikot"
      },
      {
        "state": "Odisha",
        "city": "Kodala"
      },
      {
        "state": "Odisha",
        "city": "Mohana"
      },
      {
        "state": "Odisha",
        "city": "Purushottampur"
      },
      {
        "state": "Odisha",
        "city": "Purusottampur"
      },
      {
        "state": "Odisha",
        "city": "Rambha"
      },
      {
        "state": "Odisha",
        "city": "Surada"
      },
      {
        "state": "Odisha",
        "city": "Balikuda"
      },
      {
        "state": "Odisha",
        "city": "Bidyadharpur"
      },
      {
        "state": "Odisha",
        "city": "Biridi"
      },
      {
        "state": "Odisha",
        "city": "Ersama"
      },
      {
        "state": "Odisha",
        "city": "Kujang"
      },
      {
        "state": "Odisha",
        "city": "Paradeep"
      },
      {
        "state": "Odisha",
        "city": "Tirtol"
      },
      {
        "state": "Odisha",
        "city": "Bharchhan"
      },
      {
        "state": "Odisha",
        "city": "Binjharpur"
      },
      {
        "state": "Odisha",
        "city": "Jajpur"
      },
      {
        "state": "Odisha",
        "city": "Dharmasala"
      },
      {
        "state": "Odisha",
        "city": "Hasanabad chaka badachana"
      },
      {
        "state": "Odisha",
        "city": "Jajapur"
      },
      {
        "state": "Odisha",
        "city": "Sunguda"
      },
      {
        "state": "Odisha",
        "city": "Banaharapali"
      },
      {
        "state": "Odisha",
        "city": "Belpahar"
      },
      {
        "state": "Odisha",
        "city": "Jharsuguda"
      },
      {
        "state": "Odisha",
        "city": "Kirmira"
      },
      {
        "state": "Odisha",
        "city": "Kolabira"
      },
      {
        "state": "Odisha",
        "city": "Mauliberena"
      },
      {
        "state": "Odisha",
        "city": "Bhawanipatna"
      },
      {
        "state": "Odisha",
        "city": "Budhi Padar"
      },
      {
        "state": "Odisha",
        "city": "Dharmagarh"
      },
      {
        "state": "Odisha",
        "city": "Golamunda"
      },
      {
        "state": "Odisha",
        "city": "Jaipatna"
      },
      {
        "state": "Odisha",
        "city": "Junagarh"
      },
      {
        "state": "Odisha",
        "city": "Kalampur"
      },
      {
        "state": "Odisha",
        "city": "Karlamunda"
      },
      {
        "state": "Odisha",
        "city": "Kesinga"
      },
      {
        "state": "Odisha",
        "city": "Lanjigarh"
      },
      {
        "state": "Odisha",
        "city": "Lanjigarh Road"
      },
      {
        "state": "Odisha",
        "city": "Madanpur Rampur"
      },
      {
        "state": "Odisha",
        "city": "Narla"
      },
      {
        "state": "Odisha",
        "city": "Parmanandapur"
      },
      {
        "state": "Odisha",
        "city": "Kalahandi"
      },
      {
        "state": "Odisha",
        "city": "Thuamul Rampur"
      },
      {
        "state": "Odisha",
        "city": "Kandhamal"
      },
      {
        "state": "Odisha",
        "city": "Balliguda"
      },
      {
        "state": "Odisha",
        "city": "Bamunigaon"
      },
      {
        "state": "Odisha",
        "city": "Barakhama"
      },
      {
        "state": "Odisha",
        "city": "Chakapada"
      },
      {
        "state": "Odisha",
        "city": "Daringbadi"
      },
      {
        "state": "Odisha",
        "city": "G Udayagiri"
      },
      {
        "state": "Odisha",
        "city": "Kalinga"
      },
      {
        "state": "Odisha",
        "city": "Kotagad"
      },
      {
        "state": "Odisha",
        "city": "Kothagarh"
      },
      {
        "state": "Odisha",
        "city": "Kurtamgarh"
      },
      {
        "state": "Odisha",
        "city": "Line Pada"
      },
      {
        "state": "Odisha",
        "city": "Nuagam"
      },
      {
        "state": "Odisha",
        "city": "Paburia"
      },
      {
        "state": "Odisha",
        "city": "Phiringia"
      },
      {
        "state": "Odisha",
        "city": "Phulbani"
      },
      {
        "state": "Odisha",
        "city": "Raikia"
      },
      {
        "state": "Odisha",
        "city": "Sankarakhole"
      },
      {
        "state": "Odisha",
        "city": "Sarangada"
      },
      {
        "state": "Odisha",
        "city": "Tikabali"
      },
      {
        "state": "Odisha",
        "city": "Tikkaballi"
      },
      {
        "state": "Odisha",
        "city": "Tumudibandh"
      },
      {
        "state": "Odisha",
        "city": "Aul"
      },
      {
        "state": "Odisha",
        "city": "Kendrapara"
      },
      {
        "state": "Odisha",
        "city": "Pattamundai"
      },
      {
        "state": "Odisha",
        "city": "Rajkanika"
      },
      {
        "state": "Odisha",
        "city": "Rajnagar"
      },
      {
        "state": "Odisha",
        "city": "Anandapur"
      },
      {
        "state": "Odisha",
        "city": "Banspal"
      },
      {
        "state": "Odisha",
        "city": "Barbil"
      },
      {
        "state": "Odisha",
        "city": "Champua"
      },
      {
        "state": "Odisha",
        "city": "Ghatgaon"
      },
      {
        "state": "Odisha",
        "city": "Gobindapur"
      },
      {
        "state": "Odisha",
        "city": "Hatadihi"
      },
      {
        "state": "Odisha",
        "city": "Keonjhar"
      },
      {
        "state": "Odisha",
        "city": "Kendujhar"
      },
      {
        "state": "Odisha",
        "city": "Salapada"
      },
      {
        "state": "Odisha",
        "city": "Telkoi"
      },
      {
        "state": "Odisha",
        "city": "Uparadiha"
      },
      {
        "state": "Odisha",
        "city": "Balianta"
      },
      {
        "state": "Odisha",
        "city": "Banapur"
      },
      {
        "state": "Odisha",
        "city": "Begunia"
      },
      {
        "state": "Odisha",
        "city": "Bhubanpur"
      },
      {
        "state": "Odisha",
        "city": "Bolagarh"
      },
      {
        "state": "Odisha",
        "city": "Chandaka"
      },
      {
        "state": "Odisha",
        "city": "Chilika"
      },
      {
        "state": "Odisha",
        "city": "Jatni"
      },
      {
        "state": "Odisha",
        "city": "Khordha"
      },
      {
        "state": "Odisha",
        "city": "Khurdha"
      },
      {
        "state": "Odisha",
        "city": "Nachhipur"
      },
      {
        "state": "Odisha",
        "city": "Bhairabsingipur"
      },
      {
        "state": "Odisha",
        "city": "Borigumma"
      },
      {
        "state": "Odisha",
        "city": "Damanjodi"
      },
      {
        "state": "Odisha",
        "city": "Dasmanthpur"
      },
      {
        "state": "Odisha",
        "city": "Jeypore"
      },
      {
        "state": "Odisha",
        "city": "Koraput"
      },
      {
        "state": "Odisha",
        "city": "Kundura"
      },
      {
        "state": "Odisha",
        "city": "Machh kund"
      },
      {
        "state": "Odisha",
        "city": "Narayanpatna"
      },
      {
        "state": "Odisha",
        "city": "Nowrangpur"
      },
      {
        "state": "Odisha",
        "city": "Paduwa"
      },
      {
        "state": "Odisha",
        "city": "Pottangi"
      },
      {
        "state": "Odisha",
        "city": "Similiguda"
      },
      {
        "state": "Odisha",
        "city": "Sunabeda"
      },
      {
        "state": "Odisha",
        "city": "Chitrakonda"
      },
      {
        "state": "Odisha",
        "city": "Kalimela"
      },
      {
        "state": "Odisha",
        "city": "Khinimung"
      },
      {
        "state": "Odisha",
        "city": "Malkangiri"
      },
      {
        "state": "Odisha",
        "city": "Mathili"
      },
      {
        "state": "Odisha",
        "city": "Orkel"
      },
      {
        "state": "Odisha",
        "city": "Badasahi"
      },
      {
        "state": "Odisha",
        "city": "Bahalda"
      },
      {
        "state": "Odisha",
        "city": "Barasahi"
      },
      {
        "state": "Odisha",
        "city": "Baripada"
      },
      {
        "state": "Odisha",
        "city": "Barsahi"
      },
      {
        "state": "Odisha",
        "city": "Betnoti"
      },
      {
        "state": "Odisha",
        "city": "Karanjia"
      },
      {
        "state": "Odisha",
        "city": "Khunta"
      },
      {
        "state": "Odisha",
        "city": "Rairangpur"
      },
      {
        "state": "Odisha",
        "city": "Rasgovindpur"
      },
      {
        "state": "Odisha",
        "city": "Takadida"
      },
      {
        "state": "Odisha",
        "city": "Udala"
      },
      {
        "state": "Odisha",
        "city": "Dabugaon"
      },
      {
        "state": "Odisha",
        "city": "Jharigaon"
      },
      {
        "state": "Odisha",
        "city": "Khatiguda"
      },
      {
        "state": "Odisha",
        "city": "Nabarangapur"
      },
      {
        "state": "Odisha",
        "city": "Pappadahandi"
      },
      {
        "state": "Odisha",
        "city": "Raighar"
      },
      {
        "state": "Odisha",
        "city": "Tentulikhunti"
      },
      {
        "state": "Odisha",
        "city": "Umerkote"
      },
      {
        "state": "Odisha",
        "city": "Bhapur"
      },
      {
        "state": "Odisha",
        "city": "Daspalla"
      },
      {
        "state": "Odisha",
        "city": "Khandapada"
      },
      {
        "state": "Odisha",
        "city": "Khandapra"
      },
      {
        "state": "Odisha",
        "city": "Nayagarh"
      },
      {
        "state": "Odisha",
        "city": "Odagaon"
      },
      {
        "state": "Odisha",
        "city": "Raj-Ranpur"
      },
      {
        "state": "Odisha",
        "city": "Boden"
      },
      {
        "state": "Odisha",
        "city": "Duajhar"
      },
      {
        "state": "Odisha",
        "city": "Jhagrahi"
      },
      {
        "state": "Odisha",
        "city": "Khariar Road"
      },
      {
        "state": "Odisha",
        "city": "Nuapara"
      },
      {
        "state": "Odisha",
        "city": "Sinapali"
      },
      {
        "state": "Odisha",
        "city": "Brahmagiri"
      },
      {
        "state": "Odisha",
        "city": "Puri"
      },
      {
        "state": "Odisha",
        "city": "Fulaalasa"
      },
      {
        "state": "Odisha",
        "city": "Kakatpur"
      },
      {
        "state": "Odisha",
        "city": "Konark"
      },
      {
        "state": "Odisha",
        "city": "Krishnaprasad"
      },
      {
        "state": "Odisha",
        "city": "Nimapada"
      },
      {
        "state": "Odisha",
        "city": "Rayagada"
      },
      {
        "state": "Odisha",
        "city": "Bishama Katek"
      },
      {
        "state": "Odisha",
        "city": "Bissamcuttack"
      },
      {
        "state": "Odisha",
        "city": "Gudari"
      },
      {
        "state": "Odisha",
        "city": "Gunupur"
      },
      {
        "state": "Odisha",
        "city": "Muniguda"
      },
      {
        "state": "Odisha",
        "city": "Pindapadar"
      },
      {
        "state": "Odisha",
        "city": "Rondapali"
      },
      {
        "state": "Odisha",
        "city": "Sambalpur"
      },
      {
        "state": "Odisha",
        "city": "Burla"
      },
      {
        "state": "Odisha",
        "city": "Gunderpur"
      },
      {
        "state": "Odisha",
        "city": "Hirakud"
      },
      {
        "state": "Odisha",
        "city": "Jamankira"
      },
      {
        "state": "Odisha",
        "city": "Jujumura"
      },
      {
        "state": "Odisha",
        "city": "Katarbaga"
      },
      {
        "state": "Odisha",
        "city": "Kisinda"
      },
      {
        "state": "Odisha",
        "city": "Korua"
      },
      {
        "state": "Odisha",
        "city": "Kuchinda"
      },
      {
        "state": "Odisha",
        "city": "Mahulpali"
      },
      {
        "state": "Odisha",
        "city": "Maneswar"
      },
      {
        "state": "Odisha",
        "city": "Naktideul"
      },
      {
        "state": "Odisha",
        "city": "Palsada"
      },
      {
        "state": "Odisha",
        "city": "Redhakhol"
      },
      {
        "state": "Odisha",
        "city": "Rengali"
      },
      {
        "state": "Odisha",
        "city": "Sonepur"
      },
      {
        "state": "Odisha",
        "city": "Binka"
      },
      {
        "state": "Odisha",
        "city": "Subarnapur"
      },
      {
        "state": "Odisha",
        "city": "Ulunda"
      },
      {
        "state": "Odisha",
        "city": "Angargan"
      },
      {
        "state": "Odisha",
        "city": "Balisankara"
      },
      {
        "state": "Odisha",
        "city": "Banei"
      },
      {
        "state": "Odisha",
        "city": "Bhasma"
      },
      {
        "state": "Odisha",
        "city": "Birmitrapur"
      },
      {
        "state": "Odisha",
        "city": "Bonaigarh"
      },
      {
        "state": "Odisha",
        "city": "Gurundia"
      },
      {
        "state": "Odisha",
        "city": "Hatibari"
      },
      {
        "state": "Odisha",
        "city": "Hemgir"
      },
      {
        "state": "Odisha",
        "city": "Joketa"
      },
      {
        "state": "Odisha",
        "city": "Kalosihiria"
      },
      {
        "state": "Odisha",
        "city": "Khuntagaon"
      },
      {
        "state": "Odisha",
        "city": "Kinjirkela"
      },
      {
        "state": "Odisha",
        "city": "Koira"
      },
      {
        "state": "Odisha",
        "city": "Kuanrmunda"
      },
      {
        "state": "Odisha",
        "city": "Kutra"
      },
      {
        "state": "Odisha",
        "city": "Lahunipara"
      },
      {
        "state": "Odisha",
        "city": "Lathikata"
      },
      {
        "state": "Odisha",
        "city": "Lephripara"
      },
      {
        "state": "Odisha",
        "city": "Mahulpada"
      },
      {
        "state": "Odisha",
        "city": "Raghunathapali"
      },
      {
        "state": "Odisha",
        "city": "Rajgangpur"
      },
      {
        "state": "Odisha",
        "city": "Rourkela"
      },
      {
        "state": "Odisha",
        "city": "Subdega"
      },
      {
        "state": "Odisha",
        "city": "Sukhabandh"
      },
      {
        "state": "Odisha",
        "city": "Talasara"
      },
      {
        "state": "Odisha",
        "city": "Tangarpali"
      },
      {
        "state": "Puducherry",
        "city": "Karaikal"
      },
      {
        "state": "Puducherry",
        "city": "Kottucherry"
      },
      {
        "state": "Puducherry",
        "city": "Nannilam"
      },
      {
        "state": "Puducherry",
        "city": "Nedungadu"
      },
      {
        "state": "Puducherry",
        "city": "Neravy"
      },
      {
        "state": "Puducherry",
        "city": "Thiruvarur"
      },
      {
        "state": "Puducherry",
        "city": "Tirumalairayan Pattinam"
      },
      {
        "state": "Puducherry",
        "city": "Mahe"
      },
      {
        "state": "Puducherry",
        "city": "Bahour"
      },
      {
        "state": "Puducherry",
        "city": "Periyababu Samuthiram"
      },
      {
        "state": "Puducherry",
        "city": "Puducherry"
      },
      {
        "state": "Puducherry",
        "city": "Villianur"
      },
      {
        "state": "Puducherry",
        "city": "Yanam"
      },
      {
        "state": "Punjab",
        "city": "Ajnala"
      },
      {
        "state": "Punjab",
        "city": "Amritsar"
      },
      {
        "state": "Punjab",
        "city": "Amritsar Cantt."
      },
      {
        "state": "Punjab",
        "city": "Attari"
      },
      {
        "state": "Punjab",
        "city": "Bandala"
      },
      {
        "state": "Punjab",
        "city": "Beas"
      },
      {
        "state": "Punjab",
        "city": "Bhikhiwind"
      },
      {
        "state": "Punjab",
        "city": "Bundala"
      },
      {
        "state": "Punjab",
        "city": "Butala"
      },
      {
        "state": "Punjab",
        "city": "Chabhal"
      },
      {
        "state": "Punjab",
        "city": "Chamiari"
      },
      {
        "state": "Punjab",
        "city": "Chamyari"
      },
      {
        "state": "Punjab",
        "city": "Chetanpura"
      },
      {
        "state": "Punjab",
        "city": "Chogawan"
      },
      {
        "state": "Punjab",
        "city": "Dhotian"
      },
      {
        "state": "Punjab",
        "city": "Jandiala Guru"
      },
      {
        "state": "Punjab",
        "city": "Kathu Nangal"
      },
      {
        "state": "Punjab",
        "city": "Khadur Sahib"
      },
      {
        "state": "Punjab",
        "city": "Khasa"
      },
      {
        "state": "Punjab",
        "city": "Majitha"
      },
      {
        "state": "Punjab",
        "city": "Mehta Chowk"
      },
      {
        "state": "Punjab",
        "city": "Naushehra Pannuan"
      },
      {
        "state": "Punjab",
        "city": "Preet Nagar"
      },
      {
        "state": "Punjab",
        "city": "Raja Sansi"
      },
      {
        "state": "Punjab",
        "city": "Rayya"
      },
      {
        "state": "Punjab",
        "city": "Sarai Amanat Khan"
      },
      {
        "state": "Punjab",
        "city": "Sarhali"
      },
      {
        "state": "Punjab",
        "city": "Sarhali Kalan"
      },
      {
        "state": "Punjab",
        "city": "Sathiala"
      },
      {
        "state": "Punjab",
        "city": "Sur Singh"
      },
      {
        "state": "Punjab",
        "city": "Tarsikka"
      },
      {
        "state": "Punjab",
        "city": "Verka"
      },
      {
        "state": "Punjab",
        "city": "Barnala"
      },
      {
        "state": "Punjab",
        "city": "Banawala"
      },
      {
        "state": "Punjab",
        "city": "Bareta"
      },
      {
        "state": "Punjab",
        "city": "Bathinda"
      },
      {
        "state": "Punjab",
        "city": "Bhagta Bhai Ka"
      },
      {
        "state": "Punjab",
        "city": "Bhai Rupa"
      },
      {
        "state": "Punjab",
        "city": "Bhucho Mandi"
      },
      {
        "state": "Punjab",
        "city": "Goniana"
      },
      {
        "state": "Punjab",
        "city": "Phul"
      },
      {
        "state": "Punjab",
        "city": "Sangat"
      },
      {
        "state": "Punjab",
        "city": "Talwandi Sabo"
      },
      {
        "state": "Punjab",
        "city": "Faridkot"
      },
      {
        "state": "Punjab",
        "city": "Jaito"
      },
      {
        "state": "Punjab",
        "city": "Kot Kapura"
      },
      {
        "state": "Punjab",
        "city": "Kothe Kehar Singh"
      },
      {
        "state": "Punjab",
        "city": "Fatehgarh Sahib"
      },
      {
        "state": "Punjab",
        "city": "Fazilka"
      },
      {
        "state": "Punjab",
        "city": "Firozpur"
      },
      {
        "state": "Punjab",
        "city": "Batala"
      },
      {
        "state": "Punjab",
        "city": "Dera Baba Nanak"
      },
      {
        "state": "Punjab",
        "city": "Dhariwal"
      },
      {
        "state": "Punjab",
        "city": "Gurdaspur"
      },
      {
        "state": "Punjab",
        "city": "Jhela Amda Gurdaspur"
      },
      {
        "state": "Punjab",
        "city": "Bihala"
      },
      {
        "state": "Punjab",
        "city": "Dasuya"
      },
      {
        "state": "Punjab",
        "city": "Garhshankar"
      },
      {
        "state": "Punjab",
        "city": "Hoshiarpur"
      },
      {
        "state": "Punjab",
        "city": "Mukerian"
      },
      {
        "state": "Punjab",
        "city": "Jalandhar"
      },
      {
        "state": "Punjab",
        "city": "Nakodar"
      },
      {
        "state": "Punjab",
        "city": "Bhulath"
      },
      {
        "state": "Punjab",
        "city": "Dhilwan"
      },
      {
        "state": "Punjab",
        "city": "Gazipur"
      },
      {
        "state": "Punjab",
        "city": "Kapurthala"
      },
      {
        "state": "Punjab",
        "city": "Jagraon"
      },
      {
        "state": "Punjab",
        "city": "Khanna"
      },
      {
        "state": "Punjab",
        "city": "Ludhiana"
      },
      {
        "state": "Punjab",
        "city": "Raikot"
      },
      {
        "state": "Punjab",
        "city": "Samrala"
      },
      {
        "state": "Punjab",
        "city": "Mansa"
      },
      {
        "state": "Punjab",
        "city": "Boha"
      },
      {
        "state": "Punjab",
        "city": "Budhlada"
      },
      {
        "state": "Punjab",
        "city": "Sardulgarh"
      },
      {
        "state": "Punjab",
        "city": "Dharamkot"
      },
      {
        "state": "Punjab",
        "city": "Moga"
      },
      {
        "state": "Punjab",
        "city": "Nihal Singh Wala"
      },
      {
        "state": "Punjab",
        "city": "Khizrabad"
      },
      {
        "state": "Punjab",
        "city": "Mullanpur"
      },
      {
        "state": "Punjab",
        "city": "Mullanpur Garibdass"
      },
      {
        "state": "Punjab",
        "city": "Mohali"
      },
      {
        "state": "Punjab",
        "city": "Sahauran"
      },
      {
        "state": "Punjab",
        "city": "Sialba Majri"
      },
      {
        "state": "Punjab",
        "city": "Husnar"
      },
      {
        "state": "Punjab",
        "city": "Lambi"
      },
      {
        "state": "Punjab",
        "city": "Malout"
      },
      {
        "state": "Punjab",
        "city": "Muktsar"
      },
      {
        "state": "Punjab",
        "city": "Bazidpur"
      },
      {
        "state": "Punjab",
        "city": "Nawanshahr (Shahid Bhagat Singh Nagar)"
      },
      {
        "state": "Punjab",
        "city": "Langroya"
      },
      {
        "state": "Punjab",
        "city": "Lasara"
      },
      {
        "state": "Punjab",
        "city": "Nawanshahr"
      },
      {
        "state": "Punjab",
        "city": "Pathankot"
      },
      {
        "state": "Punjab",
        "city": "Patiala"
      },
      {
        "state": "Punjab",
        "city": "Nabha"
      },
      {
        "state": "Punjab",
        "city": "Samana"
      },
      {
        "state": "Punjab",
        "city": "Zirakpur"
      },
      {
        "state": "Punjab",
        "city": "Anandpur Sahib"
      },
      {
        "state": "Punjab",
        "city": "Bela"
      },
      {
        "state": "Punjab",
        "city": "Bhanopli"
      },
      {
        "state": "Punjab",
        "city": "Bharatgarh"
      },
      {
        "state": "Punjab",
        "city": "Chak"
      },
      {
        "state": "Punjab",
        "city": "Kiratpur Sahib"
      },
      {
        "state": "Punjab",
        "city": "Kurali"
      },
      {
        "state": "Punjab",
        "city": "Lutheri"
      },
      {
        "state": "Punjab",
        "city": "Rupnagar"
      },
      {
        "state": "Punjab",
        "city": "Chhajli"
      },
      {
        "state": "Punjab",
        "city": "Dhuri"
      },
      {
        "state": "Punjab",
        "city": "Ladda"
      },
      {
        "state": "Punjab",
        "city": "Lehragaga"
      },
      {
        "state": "Punjab",
        "city": "Malerkotla"
      },
      {
        "state": "Punjab",
        "city": "Moonak"
      },
      {
        "state": "Punjab",
        "city": "Sangrur"
      },
      {
        "state": "Punjab",
        "city": "Tarn Taran"
      },
      {
        "state": "Punjab",
        "city": "Chabal Kalan"
      },
      {
        "state": "Rajasthan",
        "city": "Ajmer"
      },
      {
        "state": "Rajasthan",
        "city": "Bewar"
      },
      {
        "state": "Rajasthan",
        "city": "Bhinai"
      },
      {
        "state": "Rajasthan",
        "city": "Bijainagar"
      },
      {
        "state": "Rajasthan",
        "city": "Kekri"
      },
      {
        "state": "Rajasthan",
        "city": "Masuda"
      },
      {
        "state": "Rajasthan",
        "city": "Nasirabad"
      },
      {
        "state": "Rajasthan",
        "city": "Patti Beena"
      },
      {
        "state": "Rajasthan",
        "city": "Pisangan"
      },
      {
        "state": "Rajasthan",
        "city": "Sarwar"
      },
      {
        "state": "Rajasthan",
        "city": "Alwar"
      },
      {
        "state": "Rajasthan",
        "city": "Bansur"
      },
      {
        "state": "Rajasthan",
        "city": "Behror"
      },
      {
        "state": "Rajasthan",
        "city": "Kathoomar"
      },
      {
        "state": "Rajasthan",
        "city": "Mandawar"
      },
      {
        "state": "Rajasthan",
        "city": "Mundawar"
      },
      {
        "state": "Rajasthan",
        "city": "Neemrana"
      },
      {
        "state": "Rajasthan",
        "city": "Thanagazi"
      },
      {
        "state": "Rajasthan",
        "city": "Tijara"
      },
      {
        "state": "Rajasthan",
        "city": "Banswara"
      },
      {
        "state": "Rajasthan",
        "city": "Baran"
      },
      {
        "state": "Rajasthan",
        "city": "Balotra"
      },
      {
        "state": "Rajasthan",
        "city": "Barmer"
      },
      {
        "state": "Rajasthan",
        "city": "Baytu"
      },
      {
        "state": "Rajasthan",
        "city": "Bishala"
      },
      {
        "state": "Rajasthan",
        "city": "Chohtan"
      },
      {
        "state": "Rajasthan",
        "city": "Dhorimana"
      },
      {
        "state": "Rajasthan",
        "city": "Gadra Road"
      },
      {
        "state": "Rajasthan",
        "city": "Gudamalani"
      },
      {
        "state": "Rajasthan",
        "city": "Kawas"
      },
      {
        "state": "Rajasthan",
        "city": "Mewa Nagar"
      },
      {
        "state": "Rajasthan",
        "city": "Mokalsar"
      },
      {
        "state": "Rajasthan",
        "city": "Pachpadra"
      },
      {
        "state": "Rajasthan",
        "city": "Padru"
      },
      {
        "state": "Rajasthan",
        "city": "Parlu"
      },
      {
        "state": "Rajasthan",
        "city": "Ramsar"
      },
      {
        "state": "Rajasthan",
        "city": "Samdari"
      },
      {
        "state": "Rajasthan",
        "city": "Sanawara"
      },
      {
        "state": "Rajasthan",
        "city": "Sawau Padamsingh"
      },
      {
        "state": "Rajasthan",
        "city": "Serwa"
      },
      {
        "state": "Rajasthan",
        "city": "Sindhari"
      },
      {
        "state": "Rajasthan",
        "city": "Siwana"
      },
      {
        "state": "Rajasthan",
        "city": "Bachhren"
      },
      {
        "state": "Rajasthan",
        "city": "Bayana"
      },
      {
        "state": "Rajasthan",
        "city": "Bharatpur"
      },
      {
        "state": "Rajasthan",
        "city": "Deeg"
      },
      {
        "state": "Rajasthan",
        "city": "Janoothar"
      },
      {
        "state": "Rajasthan",
        "city": "Kaman"
      },
      {
        "state": "Rajasthan",
        "city": "Kumher"
      },
      {
        "state": "Rajasthan",
        "city": "Nadbai"
      },
      {
        "state": "Rajasthan",
        "city": "Nagar"
      },
      {
        "state": "Rajasthan",
        "city": "Pahari"
      },
      {
        "state": "Rajasthan",
        "city": "Roopbas"
      },
      {
        "state": "Rajasthan",
        "city": "Rupbas"
      },
      {
        "state": "Rajasthan",
        "city": "Weir"
      },
      {
        "state": "Rajasthan",
        "city": "Bhilwara"
      },
      {
        "state": "Rajasthan",
        "city": "Mandalgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Bikaner"
      },
      {
        "state": "Rajasthan",
        "city": "Chhattargarh"
      },
      {
        "state": "Rajasthan",
        "city": "Khajuwala"
      },
      {
        "state": "Rajasthan",
        "city": "Kolayat"
      },
      {
        "state": "Rajasthan",
        "city": "Lunkaransar"
      },
      {
        "state": "Rajasthan",
        "city": "Nokha"
      },
      {
        "state": "Rajasthan",
        "city": "Bundi"
      },
      {
        "state": "Rajasthan",
        "city": "Arnaud"
      },
      {
        "state": "Rajasthan",
        "city": "Arnod"
      },
      {
        "state": "Rajasthan",
        "city": "Bari Sadri"
      },
      {
        "state": "Rajasthan",
        "city": "Begun"
      },
      {
        "state": "Rajasthan",
        "city": "Bhadesar"
      },
      {
        "state": "Rajasthan",
        "city": "Bhainsrorgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Bhupalsagar"
      },
      {
        "state": "Rajasthan",
        "city": "Chhoti Sadri"
      },
      {
        "state": "Rajasthan",
        "city": "Chittorgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Dhamotar"
      },
      {
        "state": "Rajasthan",
        "city": "Doonglai"
      },
      {
        "state": "Rajasthan",
        "city": "Dungla"
      },
      {
        "state": "Rajasthan",
        "city": "Gangarar"
      },
      {
        "state": "Rajasthan",
        "city": "Kapasan"
      },
      {
        "state": "Rajasthan",
        "city": "Nimbahera"
      },
      {
        "state": "Rajasthan",
        "city": "Rashmi"
      },
      {
        "state": "Rajasthan",
        "city": "Rasmi"
      },
      {
        "state": "Rajasthan",
        "city": "Rawatbhata"
      },
      {
        "state": "Rajasthan",
        "city": "Shambhupura"
      },
      {
        "state": "Rajasthan",
        "city": "Churu"
      },
      {
        "state": "Rajasthan",
        "city": "Ratangarh"
      },
      {
        "state": "Rajasthan",
        "city": "Sadulpur"
      },
      {
        "state": "Rajasthan",
        "city": "Sardarshahar"
      },
      {
        "state": "Rajasthan",
        "city": "Taranagar"
      },
      {
        "state": "Rajasthan",
        "city": "Dausa"
      },
      {
        "state": "Rajasthan",
        "city": "Bari"
      },
      {
        "state": "Rajasthan",
        "city": "Baseri"
      },
      {
        "state": "Rajasthan",
        "city": "Dholpur"
      },
      {
        "state": "Rajasthan",
        "city": "Rajakhera"
      },
      {
        "state": "Rajasthan",
        "city": "Saipau"
      },
      {
        "state": "Rajasthan",
        "city": "Aspur"
      },
      {
        "state": "Rajasthan",
        "city": "Dungarpur"
      },
      {
        "state": "Rajasthan",
        "city": "6 Amp"
      },
      {
        "state": "Rajasthan",
        "city": "Barwali"
      },
      {
        "state": "Rajasthan",
        "city": "Bhadra"
      },
      {
        "state": "Rajasthan",
        "city": "Hanumangarh"
      },
      {
        "state": "Rajasthan",
        "city": "Hanumangarh Town"
      },
      {
        "state": "Rajasthan",
        "city": "Nohar"
      },
      {
        "state": "Rajasthan",
        "city": "Pilibanga"
      },
      {
        "state": "Rajasthan",
        "city": "Sangaria"
      },
      {
        "state": "Rajasthan",
        "city": "Tibbi"
      },
      {
        "state": "Rajasthan",
        "city": "Jaipur"
      },
      {
        "state": "Rajasthan",
        "city": "Jaisalmer"
      },
      {
        "state": "Rajasthan",
        "city": "Fateh Garh"
      },
      {
        "state": "Rajasthan",
        "city": "Pokhran"
      },
      {
        "state": "Rajasthan",
        "city": "Ahore"
      },
      {
        "state": "Rajasthan",
        "city": "Bagoda"
      },
      {
        "state": "Rajasthan",
        "city": "Bagora"
      },
      {
        "state": "Rajasthan",
        "city": "Bagra"
      },
      {
        "state": "Rajasthan",
        "city": "Bhinmal"
      },
      {
        "state": "Rajasthan",
        "city": "Jalore"
      },
      {
        "state": "Rajasthan",
        "city": "Raniwara"
      },
      {
        "state": "Rajasthan",
        "city": "Sanchore"
      },
      {
        "state": "Rajasthan",
        "city": "Sayla"
      },
      {
        "state": "Rajasthan",
        "city": "Jhalawar"
      },
      {
        "state": "Rajasthan",
        "city": "Alsisar"
      },
      {
        "state": "Rajasthan",
        "city": "Buhana"
      },
      {
        "state": "Rajasthan",
        "city": "Chirawa"
      },
      {
        "state": "Rajasthan",
        "city": "Jhunjhunu"
      },
      {
        "state": "Rajasthan",
        "city": "Khetri"
      },
      {
        "state": "Rajasthan",
        "city": "Khetri Nagar"
      },
      {
        "state": "Rajasthan",
        "city": "Malsisar"
      },
      {
        "state": "Rajasthan",
        "city": "Nawalgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Surajgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Udaipurwati"
      },
      {
        "state": "Rajasthan",
        "city": "Aau"
      },
      {
        "state": "Rajasthan",
        "city": "Asop"
      },
      {
        "state": "Rajasthan",
        "city": "Balesar"
      },
      {
        "state": "Rajasthan",
        "city": "Jodhpur"
      },
      {
        "state": "Rajasthan",
        "city": "Bhopalgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Borunda"
      },
      {
        "state": "Rajasthan",
        "city": "Chadi"
      },
      {
        "state": "Rajasthan",
        "city": "Dechu"
      },
      {
        "state": "Rajasthan",
        "city": "Hanawant Nagar"
      },
      {
        "state": "Rajasthan",
        "city": "Khichan"
      },
      {
        "state": "Rajasthan",
        "city": "Lohawat"
      },
      {
        "state": "Rajasthan",
        "city": "Luni"
      },
      {
        "state": "Rajasthan",
        "city": "Mathania"
      },
      {
        "state": "Rajasthan",
        "city": "Osian"
      },
      {
        "state": "Rajasthan",
        "city": "Peelwa"
      },
      {
        "state": "Rajasthan",
        "city": "Piparcity"
      },
      {
        "state": "Rajasthan",
        "city": "Setrawa"
      },
      {
        "state": "Rajasthan",
        "city": "Tena"
      },
      {
        "state": "Rajasthan",
        "city": "Teori"
      },
      {
        "state": "Rajasthan",
        "city": "Alawara"
      },
      {
        "state": "Rajasthan",
        "city": "Baloti"
      },
      {
        "state": "Rajasthan",
        "city": "Karauli"
      },
      {
        "state": "Rajasthan",
        "city": "Hindaun City"
      },
      {
        "state": "Rajasthan",
        "city": "Mandrayal"
      },
      {
        "state": "Rajasthan",
        "city": "Todabhim"
      },
      {
        "state": "Rajasthan",
        "city": "Kota"
      },
      {
        "state": "Rajasthan",
        "city": "Digod"
      },
      {
        "state": "Rajasthan",
        "city": "Degana"
      },
      {
        "state": "Rajasthan",
        "city": "Didwana"
      },
      {
        "state": "Rajasthan",
        "city": "Nagaur"
      },
      {
        "state": "Rajasthan",
        "city": "Khimsar"
      },
      {
        "state": "Rajasthan",
        "city": "Kuchaman City"
      },
      {
        "state": "Rajasthan",
        "city": "Ladnun"
      },
      {
        "state": "Rajasthan",
        "city": "Makrana"
      },
      {
        "state": "Rajasthan",
        "city": "Merta"
      },
      {
        "state": "Rajasthan",
        "city": "Mundwa"
      },
      {
        "state": "Rajasthan",
        "city": "Parbatsar"
      },
      {
        "state": "Rajasthan",
        "city": "Sherani Abad"
      },
      {
        "state": "Rajasthan",
        "city": "Bali"
      },
      {
        "state": "Rajasthan",
        "city": "Chak Jaitaran"
      },
      {
        "state": "Rajasthan",
        "city": "Desuri"
      },
      {
        "state": "Rajasthan",
        "city": "Gandav"
      },
      {
        "state": "Rajasthan",
        "city": "Jaitaran"
      },
      {
        "state": "Rajasthan",
        "city": "Jetaran"
      },
      {
        "state": "Rajasthan",
        "city": "Marwar Junction"
      },
      {
        "state": "Rajasthan",
        "city": "Pali"
      },
      {
        "state": "Rajasthan",
        "city": "Rohat"
      },
      {
        "state": "Rajasthan",
        "city": "Sojat"
      },
      {
        "state": "Rajasthan",
        "city": "Sumerpur"
      },
      {
        "state": "Rajasthan",
        "city": "Pratapgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Amet"
      },
      {
        "state": "Rajasthan",
        "city": "Rajsamand"
      },
      {
        "state": "Rajasthan",
        "city": "Deogarh"
      },
      {
        "state": "Rajasthan",
        "city": "Gilund"
      },
      {
        "state": "Rajasthan",
        "city": "Gosundi"
      },
      {
        "state": "Rajasthan",
        "city": "Kankroli"
      },
      {
        "state": "Rajasthan",
        "city": "Khamnore"
      },
      {
        "state": "Rajasthan",
        "city": "Kumbhalgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Kuraj"
      },
      {
        "state": "Rajasthan",
        "city": "Nathdwara"
      },
      {
        "state": "Rajasthan",
        "city": "Relmangra"
      },
      {
        "state": "Rajasthan",
        "city": "Sardargarh"
      },
      {
        "state": "Rajasthan",
        "city": "Bonli"
      },
      {
        "state": "Rajasthan",
        "city": "Chauth Ka Barwara"
      },
      {
        "state": "Rajasthan",
        "city": "Sawai Madhopur"
      },
      {
        "state": "Rajasthan",
        "city": "Khandar"
      },
      {
        "state": "Rajasthan",
        "city": "Danta Ramgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Kerwali"
      },
      {
        "state": "Rajasthan",
        "city": "Makri Fatak"
      },
      {
        "state": "Rajasthan",
        "city": "Sikar"
      },
      {
        "state": "Rajasthan",
        "city": "Shrimadhopur"
      },
      {
        "state": "Rajasthan",
        "city": "Abu Road"
      },
      {
        "state": "Rajasthan",
        "city": "Pindwara"
      },
      {
        "state": "Rajasthan",
        "city": "Revdar"
      },
      {
        "state": "Rajasthan",
        "city": "Sheoganj"
      },
      {
        "state": "Rajasthan",
        "city": "Sirohi"
      },
      {
        "state": "Rajasthan",
        "city": "Anupgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Gajsinghpura"
      },
      {
        "state": "Rajasthan",
        "city": "Gharsana"
      },
      {
        "state": "Rajasthan",
        "city": "Sri Ganganagar"
      },
      {
        "state": "Rajasthan",
        "city": "Kesrisinghpur"
      },
      {
        "state": "Rajasthan",
        "city": "Padampura"
      },
      {
        "state": "Rajasthan",
        "city": "Raisingh Nagar"
      },
      {
        "state": "Rajasthan",
        "city": "Sri Vijaynagar"
      },
      {
        "state": "Rajasthan",
        "city": "Srikaranpur"
      },
      {
        "state": "Rajasthan",
        "city": "Suratgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Niwai"
      },
      {
        "state": "Rajasthan",
        "city": "Tonk"
      },
      {
        "state": "Rajasthan",
        "city": "Bhinder"
      },
      {
        "state": "Rajasthan",
        "city": "Girwas"
      },
      {
        "state": "Rajasthan",
        "city": "Gordhan Vilas Rural"
      },
      {
        "state": "Rajasthan",
        "city": "Jharol"
      },
      {
        "state": "Rajasthan",
        "city": "Kanore"
      },
      {
        "state": "Rajasthan",
        "city": "Kherwara"
      },
      {
        "state": "Rajasthan",
        "city": "Kherwara Chhaoni"
      },
      {
        "state": "Rajasthan",
        "city": "Kotra"
      },
      {
        "state": "Rajasthan",
        "city": "Lasadiya"
      },
      {
        "state": "Rajasthan",
        "city": "Navania"
      },
      {
        "state": "Rajasthan",
        "city": "Salumbar"
      },
      {
        "state": "Rajasthan",
        "city": "Udaipur"
      },
      {
        "state": "Rajasthan",
        "city": "Vallabhnagar"
      },
      {
        "state": "Sikkim",
        "city": "Dikchu"
      },
      {
        "state": "Sikkim",
        "city": "Gangtok"
      },
      {
        "state": "Sikkim",
        "city": "Pakyong"
      },
      {
        "state": "Sikkim",
        "city": "Rangpo"
      },
      {
        "state": "Sikkim",
        "city": "Ranipool"
      },
      {
        "state": "Sikkim",
        "city": "Rhenock"
      },
      {
        "state": "Sikkim",
        "city": "Rongli"
      },
      {
        "state": "Sikkim",
        "city": "Singtam"
      },
      {
        "state": "Sikkim",
        "city": "Chungthang"
      },
      {
        "state": "Sikkim",
        "city": "Mangan"
      },
      {
        "state": "Sikkim",
        "city": "Melli"
      },
      {
        "state": "Sikkim",
        "city": "Namchi"
      },
      {
        "state": "Sikkim",
        "city": "Ravangla"
      },
      {
        "state": "Sikkim",
        "city": "Geyzing"
      },
      {
        "state": "Sikkim",
        "city": "Gyalshing"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ariyalur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Jayamkondacholapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sendurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Udayarpalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chennai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ramapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Anaimalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Avinashi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Coimbatore"
      },
      {
        "state": "Tamil Nadu",
        "city": "Coimbatore North"
      },
      {
        "state": "Tamil Nadu",
        "city": "Coimbatore South"
      },
      {
        "state": "Tamil Nadu",
        "city": "Madhavapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mettupalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pollachi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sholayar Dam City"
      },
      {
        "state": "Tamil Nadu",
        "city": "Udumalpet"
      },
      {
        "state": "Tamil Nadu",
        "city": "Valparai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vellalore"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chidambaram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chidambaram North"
      },
      {
        "state": "Tamil Nadu",
        "city": "Cuddalore"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tittagudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Virudhachalam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Dharmapuri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nallampalli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pappireddipatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pauparapatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pennagaram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Dindigul"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kodaikanal"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nilakottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Oddanchatram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Palani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Bhavani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Dharapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Erode"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gobichettipalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kangayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Karaipudur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Moongilpatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Perundurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Puliampatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sathyamangalam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chengalpattu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kanchipuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Cheyur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Madurantakam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Padapai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Palayanur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tirukazhukundram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Uttiramerur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vandalur R.F."
      },
      {
        "state": "Tamil Nadu",
        "city": "Vennangupattu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Agasteeswaram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kalkulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kanyakumari"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kuzhithurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nagercoil"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thovalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vilavancode"
      },
      {
        "state": "Tamil Nadu",
        "city": "Karur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Krishnarayapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kulithalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Naduppati"
      },
      {
        "state": "Tamil Nadu",
        "city": "Soodamani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Bargur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Denkanikottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Krishnagiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Hosur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kaveripattinam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pochampalli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Uthangarai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Uttangarai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Madurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Madurai North"
      },
      {
        "state": "Tamil Nadu",
        "city": "Madurai South"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mallapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Melur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sholavandan"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tirumangalam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Usilampatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vadipatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kilvelur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mayiladuthurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nagapattinam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sirkazhi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tharangambadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vedaranyam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Namakkal"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mallasamudram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pallipalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Paramathi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pullagoundampatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Cherangode"
      },
      {
        "state": "Tamil Nadu",
        "city": "Coonoor"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gudalur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kotagiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kundah"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nilgiris"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ooty"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pandalur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Perambalur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thandalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Veppanthattai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Annavasal"
      },
      {
        "state": "Tamil Nadu",
        "city": "Aranthangi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Avudaiyarkoil"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pudukkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gandarvakkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Illuppur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kadambarayapatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kothamangalam North"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kulathur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Manamelkudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "PillaPalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vanadirayanpatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kadaladi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kadambur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kamudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kamuthi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Muthukulathur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Paramakudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ramanathapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Rameswaram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruvadanai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Attur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Salem"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mettur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Omalur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Valappadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Yercaud"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sivaganga"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ilayangudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Karaikudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Manamadurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sakkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Singampunari"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruppuvanam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vairavanpatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kumarapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kumbakonam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Papanasam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Peravurani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sengaluneer Thottam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thanjavur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruvaiyaru"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruvidaimaruthur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Andipatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Andippatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Bodinayakanur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Theni"
      },
      {
        "state": "Tamil Nadu",
        "city": "Periyakulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Uthamapalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thoothukudi (Tuticorin)"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ettaiyapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kovilpatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ottapidaram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sathankulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Srivaikuntam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thoothukudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruchendur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vilathikulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Z.Devarkulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruchirappalli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kattuputhur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Musiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pavithiram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thottiyam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Alangulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ambasamudram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tirunelveli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nanguneri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Palayamkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Radhapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Rayagiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Shenkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sivagiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tenkasi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ullar"
      },
      {
        "state": "Tamil Nadu",
        "city": "Veerakeralampudur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruppur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Avadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruvallur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gummudipoondi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pallipattu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ponneri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruthani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chengam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Dusi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gunambadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruvannamalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thandrambattu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thandrampattu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Koradacheri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruvarur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nidamangalam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thirumakkottai -II"
      },
      {
        "state": "Tamil Nadu",
        "city": "Arakkonam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Arcot"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vellore"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gudiyattam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Katpadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thuraikkadu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vaniyambadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Walajapet"
      },
      {
        "state": "Tamil Nadu",
        "city": "Wallajah"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chinnasalem"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gingee"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kallakurichi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Marakkanam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pennathur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sankarapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thirukoilure"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tindivanam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vanur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Viriyur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kariapatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Virudhunagar"
      },
      {
        "state": "Tamil Nadu",
        "city": "Rajapalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "S Ramachandrapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sivakasi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Srivilliputhur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruchuli"
      },
      {
        "state": "Telangana",
        "city": "Adilabad"
      },
      {
        "state": "Telangana",
        "city": "Asifabad"
      },
      {
        "state": "Telangana",
        "city": "Bazarhathnoor"
      },
      {
        "state": "Telangana",
        "city": "Bejjur"
      },
      {
        "state": "Telangana",
        "city": "Boath"
      },
      {
        "state": "Telangana",
        "city": "Chinnur"
      },
      {
        "state": "Telangana",
        "city": "Jainoor"
      },
      {
        "state": "Telangana",
        "city": "Kerimeri"
      },
      {
        "state": "Telangana",
        "city": "Khanapur"
      },
      {
        "state": "Telangana",
        "city": "Luxettipet"
      },
      {
        "state": "Telangana",
        "city": "Mandamarri"
      },
      {
        "state": "Telangana",
        "city": "Mudhole"
      },
      {
        "state": "Telangana",
        "city": "Narnoor"
      },
      {
        "state": "Telangana",
        "city": "Sirpur-Kaghaznagar"
      },
      {
        "state": "Telangana",
        "city": "Tamsi"
      },
      {
        "state": "Telangana",
        "city": "Tiryani"
      },
      {
        "state": "Telangana",
        "city": "Utnoor"
      },
      {
        "state": "Telangana",
        "city": "Utukur"
      },
      {
        "state": "Telangana",
        "city": "Bhadradri Kothagudem"
      },
      {
        "state": "Telangana",
        "city": "Hyderabad"
      },
      {
        "state": "Telangana",
        "city": "Saroornagar"
      },
      {
        "state": "Telangana",
        "city": "Jagtial"
      },
      {
        "state": "Telangana",
        "city": "Jangaon"
      },
      {
        "state": "Telangana",
        "city": "Jayashankar Bhoopalpally"
      },
      {
        "state": "Telangana",
        "city": "Jogulamba Gadwal"
      },
      {
        "state": "Telangana",
        "city": "Kamareddy"
      },
      {
        "state": "Telangana",
        "city": "Alugunur"
      },
      {
        "state": "Telangana",
        "city": "Bejjanki"
      },
      {
        "state": "Telangana",
        "city": "Bheemadevarpalle"
      },
      {
        "state": "Telangana",
        "city": "Chigurumamidi"
      },
      {
        "state": "Telangana",
        "city": "Choppadandi"
      },
      {
        "state": "Telangana",
        "city": "Karimnagar"
      },
      {
        "state": "Telangana",
        "city": "Dharmaram"
      },
      {
        "state": "Telangana",
        "city": "Ellantakunta"
      },
      {
        "state": "Telangana",
        "city": "Gambhiraopet"
      },
      {
        "state": "Telangana",
        "city": "Gangadhara"
      },
      {
        "state": "Telangana",
        "city": "Gollapalli"
      },
      {
        "state": "Telangana",
        "city": "Husnabad"
      },
      {
        "state": "Telangana",
        "city": "Huzurabad"
      },
      {
        "state": "Telangana",
        "city": "Jammikunta"
      },
      {
        "state": "Telangana",
        "city": "Julapalle"
      },
      {
        "state": "Telangana",
        "city": "Julapalli"
      },
      {
        "state": "Telangana",
        "city": "Kalva Srirampur"
      },
      {
        "state": "Telangana",
        "city": "Kamanpur"
      },
      {
        "state": "Telangana",
        "city": "Kataram"
      },
      {
        "state": "Telangana",
        "city": "Kathlapoor"
      },
      {
        "state": "Telangana",
        "city": "Kesavapatnam"
      },
      {
        "state": "Telangana",
        "city": "Kodimial"
      },
      {
        "state": "Telangana",
        "city": "Koheda"
      },
      {
        "state": "Telangana",
        "city": "Konaraopet"
      },
      {
        "state": "Telangana",
        "city": "Korutla"
      },
      {
        "state": "Telangana",
        "city": "Mallayal"
      },
      {
        "state": "Telangana",
        "city": "Mallial"
      },
      {
        "state": "Telangana",
        "city": "Manakondur"
      },
      {
        "state": "Telangana",
        "city": "Manthani"
      },
      {
        "state": "Telangana",
        "city": "Medapalli"
      },
      {
        "state": "Telangana",
        "city": "Metpalle"
      },
      {
        "state": "Telangana",
        "city": "Metpalli"
      },
      {
        "state": "Telangana",
        "city": "Metpally"
      },
      {
        "state": "Telangana",
        "city": "Mustabad"
      },
      {
        "state": "Telangana",
        "city": "Nizamabad Village"
      },
      {
        "state": "Telangana",
        "city": "Pegadapalli"
      },
      {
        "state": "Telangana",
        "city": "Pegadapally"
      },
      {
        "state": "Telangana",
        "city": "Raikal"
      },
      {
        "state": "Telangana",
        "city": "Ramadugu"
      },
      {
        "state": "Telangana",
        "city": "Ramagundam"
      },
      {
        "state": "Telangana",
        "city": "Saidapur"
      },
      {
        "state": "Telangana",
        "city": "Sarangapur"
      },
      {
        "state": "Telangana",
        "city": "Seethampet"
      },
      {
        "state": "Telangana",
        "city": "Shankarapatnam"
      },
      {
        "state": "Telangana",
        "city": "Sircilla"
      },
      {
        "state": "Telangana",
        "city": "Sircilla Rural"
      },
      {
        "state": "Telangana",
        "city": "Sultanabad"
      },
      {
        "state": "Telangana",
        "city": "Thimmapur"
      },
      {
        "state": "Telangana",
        "city": "Veenavanka"
      },
      {
        "state": "Telangana",
        "city": "Velgatur"
      },
      {
        "state": "Telangana",
        "city": "Vemulawada"
      },
      {
        "state": "Telangana",
        "city": "Yellareddipet"
      },
      {
        "state": "Telangana",
        "city": "Yellareddypet"
      },
      {
        "state": "Telangana",
        "city": "Aswapuram"
      },
      {
        "state": "Telangana",
        "city": "Aswaraopeta"
      },
      {
        "state": "Telangana",
        "city": "Balaraju Gudem"
      },
      {
        "state": "Telangana",
        "city": "Bayyaram"
      },
      {
        "state": "Telangana",
        "city": "Bhadrachalam"
      },
      {
        "state": "Telangana",
        "city": "Bhoorgampahad"
      },
      {
        "state": "Telangana",
        "city": "Bonakal"
      },
      {
        "state": "Telangana",
        "city": "Chandrugonda"
      },
      {
        "state": "Telangana",
        "city": "Cheruvumadharam"
      },
      {
        "state": "Telangana",
        "city": "Chintakani"
      },
      {
        "state": "Telangana",
        "city": "Dammapeta"
      },
      {
        "state": "Telangana",
        "city": "Dummugudem"
      },
      {
        "state": "Telangana",
        "city": "Khammam"
      },
      {
        "state": "Telangana",
        "city": "Enkoor"
      },
      {
        "state": "Telangana",
        "city": "Garla"
      },
      {
        "state": "Telangana",
        "city": "Gdpeta"
      },
      {
        "state": "Telangana",
        "city": "Gokinepalli"
      },
      {
        "state": "Telangana",
        "city": "Julurpad"
      },
      {
        "state": "Telangana",
        "city": "Kallur"
      },
      {
        "state": "Telangana",
        "city": "Karepalli"
      },
      {
        "state": "Telangana",
        "city": "Kothagudem"
      },
      {
        "state": "Telangana",
        "city": "Kusumanchi"
      },
      {
        "state": "Telangana",
        "city": "Madhira"
      },
      {
        "state": "Telangana",
        "city": "Manuguru"
      },
      {
        "state": "Telangana",
        "city": "Mothugudem"
      },
      {
        "state": "Telangana",
        "city": "Nellipaka"
      },
      {
        "state": "Telangana",
        "city": "Padamati Narsapuram"
      },
      {
        "state": "Telangana",
        "city": "Palair"
      },
      {
        "state": "Telangana",
        "city": "Palwancha"
      },
      {
        "state": "Telangana",
        "city": "Penuballi"
      },
      {
        "state": "Telangana",
        "city": "Pindiprolu"
      },
      {
        "state": "Telangana",
        "city": "Ramanujapuram"
      },
      {
        "state": "Telangana",
        "city": "Samathsingaram"
      },
      {
        "state": "Telangana",
        "city": "Sarapaka"
      },
      {
        "state": "Telangana",
        "city": "Sathupally"
      },
      {
        "state": "Telangana",
        "city": "Satyanarayanapuram"
      },
      {
        "state": "Telangana",
        "city": "Siripuram"
      },
      {
        "state": "Telangana",
        "city": "Subled"
      },
      {
        "state": "Telangana",
        "city": "Sujathanagar"
      },
      {
        "state": "Telangana",
        "city": "Tallada"
      },
      {
        "state": "Telangana",
        "city": "Thanikella"
      },
      {
        "state": "Telangana",
        "city": "V.Venkatayapalem"
      },
      {
        "state": "Telangana",
        "city": "Vemsoor"
      },
      {
        "state": "Telangana",
        "city": "Wyra"
      },
      {
        "state": "Telangana",
        "city": "Yellandu"
      },
      {
        "state": "Telangana",
        "city": "Yerrupalem"
      },
      {
        "state": "Telangana",
        "city": "Komaram Bheem Asifabad"
      },
      {
        "state": "Telangana",
        "city": "Mahabubabad"
      },
      {
        "state": "Telangana",
        "city": "Amangal"
      },
      {
        "state": "Telangana",
        "city": "Amrabad"
      },
      {
        "state": "Telangana",
        "city": "Balmur"
      },
      {
        "state": "Telangana",
        "city": "Bhootpur"
      },
      {
        "state": "Telangana",
        "city": "Bhuthpur"
      },
      {
        "state": "Telangana",
        "city": "Bijnapalli"
      },
      {
        "state": "Telangana",
        "city": "Boothpur"
      },
      {
        "state": "Telangana",
        "city": "Chinna Chintha Kunta"
      },
      {
        "state": "Telangana",
        "city": "Devarkadra"
      },
      {
        "state": "Telangana",
        "city": "Dharur"
      },
      {
        "state": "Telangana",
        "city": "Gadwal"
      },
      {
        "state": "Telangana",
        "city": "Gopalpet"
      },
      {
        "state": "Telangana",
        "city": "Mahbubnagar"
      },
      {
        "state": "Telangana",
        "city": "Jadcherla"
      },
      {
        "state": "Telangana",
        "city": "Kalwakurthy"
      },
      {
        "state": "Telangana",
        "city": "Keshampet"
      },
      {
        "state": "Telangana",
        "city": "Kodair"
      },
      {
        "state": "Telangana",
        "city": "Kodangal"
      },
      {
        "state": "Telangana",
        "city": "Kollapur"
      },
      {
        "state": "Telangana",
        "city": "Kondur"
      },
      {
        "state": "Telangana",
        "city": "Kondurg"
      },
      {
        "state": "Telangana",
        "city": "Kosigi"
      },
      {
        "state": "Telangana",
        "city": "Kothakota"
      },
      {
        "state": "Telangana",
        "city": "Lingal"
      },
      {
        "state": "Telangana",
        "city": "Lingala"
      },
      {
        "state": "Telangana",
        "city": "Madgul"
      },
      {
        "state": "Telangana",
        "city": "Madhira D"
      },
      {
        "state": "Telangana",
        "city": "Midjil"
      },
      {
        "state": "Telangana",
        "city": "Narayanpet"
      },
      {
        "state": "Telangana",
        "city": "Palem"
      },
      {
        "state": "Telangana",
        "city": "Pangal"
      },
      {
        "state": "Telangana",
        "city": "Pebbair"
      },
      {
        "state": "Telangana",
        "city": "Peddakothapally"
      },
      {
        "state": "Telangana",
        "city": "Peddamandadi"
      },
      {
        "state": "Telangana",
        "city": "Shadnagar"
      },
      {
        "state": "Telangana",
        "city": "Singam Palle"
      },
      {
        "state": "Telangana",
        "city": "Tadoor"
      },
      {
        "state": "Telangana",
        "city": "Talakondapally"
      },
      {
        "state": "Telangana",
        "city": "Telkapalle"
      },
      {
        "state": "Telangana",
        "city": "Telkapally"
      },
      {
        "state": "Telangana",
        "city": "Thadoor"
      },
      {
        "state": "Telangana",
        "city": "Thimajipet"
      },
      {
        "state": "Telangana",
        "city": "Uppununthala"
      },
      {
        "state": "Telangana",
        "city": "Vangoor"
      },
      {
        "state": "Telangana",
        "city": "Veepanagandla"
      },
      {
        "state": "Telangana",
        "city": "Veldanda"
      },
      {
        "state": "Telangana",
        "city": "Yerravalli"
      },
      {
        "state": "Telangana",
        "city": "Mancherial"
      },
      {
        "state": "Telangana",
        "city": "Alladurg"
      },
      {
        "state": "Telangana",
        "city": "Andole"
      },
      {
        "state": "Telangana",
        "city": "Dubbak"
      },
      {
        "state": "Telangana",
        "city": "Medak"
      },
      {
        "state": "Telangana",
        "city": "Jogipet"
      },
      {
        "state": "Telangana",
        "city": "Narayankhed"
      },
      {
        "state": "Telangana",
        "city": "Narsapur"
      },
      {
        "state": "Telangana",
        "city": "Papannapet"
      },
      {
        "state": "Telangana",
        "city": "Sadashivpet"
      },
      {
        "state": "Telangana",
        "city": "Shankarampet"
      },
      {
        "state": "Telangana",
        "city": "Shankarampet (A)"
      },
      {
        "state": "Telangana",
        "city": "Zaheerabad"
      },
      {
        "state": "Telangana",
        "city": "Medchal"
      },
      {
        "state": "Telangana",
        "city": "Nagarkurnool"
      },
      {
        "state": "Telangana",
        "city": "Aler"
      },
      {
        "state": "Telangana",
        "city": "Anumula"
      },
      {
        "state": "Telangana",
        "city": "Bhoodan Pochampally"
      },
      {
        "state": "Telangana",
        "city": "Bhuvanagiri"
      },
      {
        "state": "Telangana",
        "city": "Bibinagar"
      },
      {
        "state": "Telangana",
        "city": "Choutuppal"
      },
      {
        "state": "Telangana",
        "city": "Devarakonda"
      },
      {
        "state": "Telangana",
        "city": "Huzur Nagar"
      },
      {
        "state": "Telangana",
        "city": "Nalgonda"
      },
      {
        "state": "Telangana",
        "city": "Jangedu"
      },
      {
        "state": "Telangana",
        "city": "Kodad"
      },
      {
        "state": "Telangana",
        "city": "Marriguda"
      },
      {
        "state": "Telangana",
        "city": "Miryalaguda"
      },
      {
        "state": "Telangana",
        "city": "Mothkur"
      },
      {
        "state": "Telangana",
        "city": "Munugodu"
      },
      {
        "state": "Telangana",
        "city": "Nakerakal"
      },
      {
        "state": "Telangana",
        "city": "Narayanapur"
      },
      {
        "state": "Telangana",
        "city": "Pedda Adiserla Palle"
      },
      {
        "state": "Telangana",
        "city": "Peddavura"
      },
      {
        "state": "Telangana",
        "city": "Pochampalle"
      },
      {
        "state": "Telangana",
        "city": "Ramannapeta"
      },
      {
        "state": "Telangana",
        "city": "Thungathurthy"
      },
      {
        "state": "Telangana",
        "city": "Tungaturthi"
      },
      {
        "state": "Telangana",
        "city": "Valigonda"
      },
      {
        "state": "Telangana",
        "city": "Yadagirigutta"
      },
      {
        "state": "Telangana",
        "city": "Yaragandla Pally"
      },
      {
        "state": "Telangana",
        "city": "Nirmal"
      },
      {
        "state": "Telangana",
        "city": "Armoor"
      },
      {
        "state": "Telangana",
        "city": "Banswada"
      },
      {
        "state": "Telangana",
        "city": "Bheemgal"
      },
      {
        "state": "Telangana",
        "city": "Bhiknoor"
      },
      {
        "state": "Telangana",
        "city": "Bichkunda"
      },
      {
        "state": "Telangana",
        "city": "Bodhan"
      },
      {
        "state": "Telangana",
        "city": "Bodhan Rural"
      },
      {
        "state": "Telangana",
        "city": "Dichpally"
      },
      {
        "state": "Telangana",
        "city": "Nizamabad"
      },
      {
        "state": "Telangana",
        "city": "kottakkal"
      },
      {
        "state": "Telangana",
        "city": "Lingampet"
      },
      {
        "state": "Telangana",
        "city": "Machareddi"
      },
      {
        "state": "Telangana",
        "city": "Madnoor"
      },
      {
        "state": "Telangana",
        "city": "Yedapally"
      },
      {
        "state": "Telangana",
        "city": "Yellareddy"
      },
      {
        "state": "Telangana",
        "city": "Peddapalli"
      },
      {
        "state": "Telangana",
        "city": "Rajanna Sircilla"
      },
      {
        "state": "Telangana",
        "city": "Hayathnagar"
      },
      {
        "state": "Telangana",
        "city": "Rangareddy"
      },
      {
        "state": "Telangana",
        "city": "Maheshwaram"
      },
      {
        "state": "Telangana",
        "city": "Maheswaram"
      },
      {
        "state": "Telangana",
        "city": "Moinabad"
      },
      {
        "state": "Telangana",
        "city": "Nawabpet"
      },
      {
        "state": "Telangana",
        "city": "Shankarpally"
      },
      {
        "state": "Telangana",
        "city": "Tandur"
      },
      {
        "state": "Telangana",
        "city": "Sangareddy"
      },
      {
        "state": "Telangana",
        "city": "Siddipet"
      },
      {
        "state": "Telangana",
        "city": "Suryapet"
      },
      {
        "state": "Telangana",
        "city": "Vikarabad"
      },
      {
        "state": "Telangana",
        "city": "Wanaparthy"
      },
      {
        "state": "Telangana",
        "city": "Atmakur"
      },
      {
        "state": "Telangana",
        "city": "Warangal (Urban)"
      },
      {
        "state": "Telangana",
        "city": "Yadadri Bhuvanagiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bachannapet"
      },
      {
        "state": "Telangana",
        "city": "Bachannapet"
      },
      {
        "state": "Telangana",
        "city": "Bhupalpally"
      },
      {
        "state": "Telangana",
        "city": "Cheeturu"
      },
      {
        "state": "Telangana",
        "city": "Chelpur"
      },
      {
        "state": "Telangana",
        "city": "Chennaraopet"
      },
      {
        "state": "Telangana",
        "city": "Cherial"
      },
      {
        "state": "Telangana",
        "city": "Chityal"
      },
      {
        "state": "Telangana",
        "city": "Devaruppal"
      },
      {
        "state": "Telangana",
        "city": "Dharmasagar"
      },
      {
        "state": "Telangana",
        "city": "Dornakal"
      },
      {
        "state": "Telangana",
        "city": "Duggondi"
      },
      {
        "state": "Telangana",
        "city": "Eturnagaram"
      },
      {
        "state": "Telangana",
        "city": "Gavicherla"
      },
      {
        "state": "Telangana",
        "city": "Geesugonda"
      },
      {
        "state": "Telangana",
        "city": "Ghanpur"
      },
      {
        "state": "Telangana",
        "city": "Ghanpur(m)"
      },
      {
        "state": "Telangana",
        "city": "Govindaraopet"
      },
      {
        "state": "Telangana",
        "city": "Gudur"
      },
      {
        "state": "Telangana",
        "city": "Hanamkonda"
      },
      {
        "state": "Telangana",
        "city": "Hasanparthy"
      },
      {
        "state": "Telangana",
        "city": "Kamareddy Gudem"
      },
      {
        "state": "Telangana",
        "city": "Kanchanapally"
      },
      {
        "state": "Telangana",
        "city": "Kesamudram"
      },
      {
        "state": "Telangana",
        "city": "Kodakandla"
      },
      {
        "state": "Telangana",
        "city": "Kondapur"
      },
      {
        "state": "Telangana",
        "city": "Kuravi"
      },
      {
        "state": "Telangana",
        "city": "Lingal Ghanpur"
      },
      {
        "state": "Telangana",
        "city": "Maddur"
      },
      {
        "state": "Telangana",
        "city": "Mangapet"
      },
      {
        "state": "Telangana",
        "city": "Maripeda"
      },
      {
        "state": "Telangana",
        "city": "Mogula Pally"
      },
      {
        "state": "Telangana",
        "city": "Mulug"
      },
      {
        "state": "Telangana",
        "city": "Mulugu"
      },
      {
        "state": "Telangana",
        "city": "Nallabelli"
      },
      {
        "state": "Telangana",
        "city": "Narmetta"
      },
      {
        "state": "Telangana",
        "city": "Narsampet"
      },
      {
        "state": "Telangana",
        "city": "Narsimhulapet"
      },
      {
        "state": "Telangana",
        "city": "Nekkonda"
      },
      {
        "state": "Telangana",
        "city": "Nellikudur"
      },
      {
        "state": "Telangana",
        "city": "Palakurthy"
      },
      {
        "state": "Telangana",
        "city": "Parkal"
      },
      {
        "state": "Telangana",
        "city": "Parvathagiri"
      },
      {
        "state": "Telangana",
        "city": "Raiparthy"
      },
      {
        "state": "Telangana",
        "city": "Rayaparthi"
      },
      {
        "state": "Telangana",
        "city": "Redlawada"
      },
      {
        "state": "Telangana",
        "city": "Regonda"
      },
      {
        "state": "Telangana",
        "city": "Sangem"
      },
      {
        "state": "Telangana",
        "city": "Shayampet"
      },
      {
        "state": "Telangana",
        "city": "Station Ghanpur"
      },
      {
        "state": "Telangana",
        "city": "Tadvai"
      },
      {
        "state": "Telangana",
        "city": "Thorrur"
      },
      {
        "state": "Telangana",
        "city": "Venkatapur"
      },
      {
        "state": "Telangana",
        "city": "Wardannapet"
      },
      {
        "state": "Telangana",
        "city": "Zaffergadh"
      },
      {
        "state": "Tripura",
        "city": "Chulubari"
      },
      {
        "state": "Tripura",
        "city": "Dalapatipara"
      },
      {
        "state": "Tripura",
        "city": "Halahali"
      },
      {
        "state": "Tripura",
        "city": "Halhuli"
      },
      {
        "state": "Tripura",
        "city": "Jagabandhupara"
      },
      {
        "state": "Tripura",
        "city": "Kachucherra"
      },
      {
        "state": "Tripura",
        "city": "Katalutma"
      },
      {
        "state": "Tripura",
        "city": "Kuchainala"
      },
      {
        "state": "Tripura",
        "city": "Kulai Ghantachara"
      },
      {
        "state": "Tripura",
        "city": "Longtarai R.F."
      },
      {
        "state": "Tripura",
        "city": "Mainama"
      },
      {
        "state": "Tripura",
        "city": "Manik Bhandar"
      },
      {
        "state": "Tripura",
        "city": "Purba Masli"
      },
      {
        "state": "Tripura",
        "city": "Purba Nalichhara"
      },
      {
        "state": "Tripura",
        "city": "Ranipukur"
      },
      {
        "state": "Tripura",
        "city": "Gomati"
      },
      {
        "state": "Tripura",
        "city": "Khowai"
      },
      {
        "state": "Tripura",
        "city": "Ambassa"
      },
      {
        "state": "Tripura",
        "city": "Bagbasa"
      },
      {
        "state": "Tripura",
        "city": "Balidhum"
      },
      {
        "state": "Tripura",
        "city": "Baruakandi"
      },
      {
        "state": "Tripura",
        "city": "Betchhara"
      },
      {
        "state": "Tripura",
        "city": "Birchandranagar"
      },
      {
        "state": "Tripura",
        "city": "Central Catchment R.F."
      },
      {
        "state": "Tripura",
        "city": "Chailengta"
      },
      {
        "state": "Tripura",
        "city": "Chawmanu"
      },
      {
        "state": "Tripura",
        "city": "Chhantail"
      },
      {
        "state": "Tripura",
        "city": "Churaibari"
      },
      {
        "state": "Tripura",
        "city": "Dasda"
      },
      {
        "state": "Tripura",
        "city": "Debendrachandranagar"
      },
      {
        "state": "Tripura",
        "city": "Dewanpasa"
      },
      {
        "state": "Tripura",
        "city": "Dhanbilash"
      },
      {
        "state": "Tripura",
        "city": "Dharmanagar"
      },
      {
        "state": "Tripura",
        "city": "Fatikroy"
      },
      {
        "state": "Tripura",
        "city": "Fultali"
      },
      {
        "state": "Tripura",
        "city": "Indurail"
      },
      {
        "state": "Tripura",
        "city": "Jampuii Hills"
      },
      {
        "state": "Tripura",
        "city": "Jubarajnagar"
      },
      {
        "state": "Tripura",
        "city": "Kailashahar"
      },
      {
        "state": "Tripura",
        "city": "Kameswar"
      },
      {
        "state": "Tripura",
        "city": "Kanchanbari"
      },
      {
        "state": "Tripura",
        "city": "Khedacherra"
      },
      {
        "state": "Tripura",
        "city": "Kumarghat"
      },
      {
        "state": "Tripura",
        "city": "Kurti"
      },
      {
        "state": "Tripura",
        "city": "Laljuri"
      },
      {
        "state": "Tripura",
        "city": "Nabincherra"
      },
      {
        "state": "Tripura",
        "city": "Nalkata"
      },
      {
        "state": "Tripura",
        "city": "Paschim Kanchanbari"
      },
      {
        "state": "Tripura",
        "city": "Paschim Manpai"
      },
      {
        "state": "Tripura",
        "city": "Pecharthal"
      },
      {
        "state": "Tripura",
        "city": "Pencharthal"
      },
      {
        "state": "Tripura",
        "city": "Purba Halflong"
      },
      {
        "state": "Tripura",
        "city": "Purba Tilthai"
      },
      {
        "state": "Tripura",
        "city": "Ragna"
      },
      {
        "state": "Tripura",
        "city": "Rajkandi"
      },
      {
        "state": "Tripura",
        "city": "Rangauti"
      },
      {
        "state": "Tripura",
        "city": "Rangrung"
      },
      {
        "state": "Tripura",
        "city": "Sabual"
      },
      {
        "state": "Tripura",
        "city": "Samrurpar"
      },
      {
        "state": "Tripura",
        "city": "Satnala"
      },
      {
        "state": "Tripura",
        "city": "Sonaimuri"
      },
      {
        "state": "Tripura",
        "city": "Tuichama"
      },
      {
        "state": "Tripura",
        "city": "Tulabagan"
      },
      {
        "state": "Tripura",
        "city": "Uptakhali"
      },
      {
        "state": "Tripura",
        "city": "Uttar Machmara"
      },
      {
        "state": "Tripura",
        "city": "Vanghmun"
      },
      {
        "state": "Tripura",
        "city": "Sepahijala"
      },
      {
        "state": "Tripura",
        "city": "Ampinagar"
      },
      {
        "state": "Tripura",
        "city": "Bagafa"
      },
      {
        "state": "Tripura",
        "city": "Belonia"
      },
      {
        "state": "Tripura",
        "city": "Garji"
      },
      {
        "state": "Tripura",
        "city": "Hrishyamukh"
      },
      {
        "state": "Tripura",
        "city": "Jatan Bari"
      },
      {
        "state": "Tripura",
        "city": "Matabari"
      },
      {
        "state": "Tripura",
        "city": "Muhuripur"
      },
      {
        "state": "Tripura",
        "city": "Purba Anandapur"
      },
      {
        "state": "Tripura",
        "city": "Radhakishorepur R.F."
      },
      {
        "state": "Tripura",
        "city": "Rajnagar"
      },
      {
        "state": "Tripura",
        "city": "Sabroom"
      },
      {
        "state": "Tripura",
        "city": "Santirbazar"
      },
      {
        "state": "Tripura",
        "city": "Satchand"
      },
      {
        "state": "Tripura",
        "city": "South Tripura"
      },
      {
        "state": "Tripura",
        "city": "Unakoti"
      },
      {
        "state": "Tripura",
        "city": "Asharambari"
      },
      {
        "state": "Tripura",
        "city": "Banbazar"
      },
      {
        "state": "Tripura",
        "city": "Behalabari"
      },
      {
        "state": "Tripura",
        "city": "Bishalgarh"
      },
      {
        "state": "Tripura",
        "city": "Boxanagar"
      },
      {
        "state": "Tripura",
        "city": "Chebri"
      },
      {
        "state": "Tripura",
        "city": "Dakshin Promodenagar"
      },
      {
        "state": "Tripura",
        "city": "Dakshin Ramchandraghat"
      },
      {
        "state": "Tripura",
        "city": "Ganki"
      },
      {
        "state": "Tripura",
        "city": "Ghilatali"
      },
      {
        "state": "Tripura",
        "city": "Jirania"
      },
      {
        "state": "Tripura",
        "city": "Kathalia"
      },
      {
        "state": "Tripura",
        "city": "West Tripura"
      },
      {
        "state": "Tripura",
        "city": "Mohanpur"
      },
      {
        "state": "Tripura",
        "city": "Nehalchandranagar"
      },
      {
        "state": "Tripura",
        "city": "Paglabari"
      },
      {
        "state": "Tripura",
        "city": "Paschim Bachaibari"
      },
      {
        "state": "Tripura",
        "city": "Paschim Champachhara"
      },
      {
        "state": "Tripura",
        "city": "Paschim Kalyanpur"
      },
      {
        "state": "Tripura",
        "city": "Paschim Simna part"
      },
      {
        "state": "Tripura",
        "city": "Promodenagar"
      },
      {
        "state": "Tripura",
        "city": "Purba Bachaibari"
      },
      {
        "state": "Tripura",
        "city": "Ramdayalbari"
      },
      {
        "state": "Tripura",
        "city": "Ranirbazar"
      },
      {
        "state": "Tripura",
        "city": "Sadar"
      },
      {
        "state": "Tripura",
        "city": "Sardu Karkari"
      },
      {
        "state": "Tripura",
        "city": "Sekerkote"
      },
      {
        "state": "Tripura",
        "city": "Sonamura"
      },
      {
        "state": "Tripura",
        "city": "Sonatala"
      },
      {
        "state": "Tripura",
        "city": "Teliamura R.F. part"
      },
      {
        "state": "Tripura",
        "city": "Uttar Gakulnagar"
      },
      {
        "state": "Tripura",
        "city": "Uttar Padmabil part"
      },
      {
        "state": "Tripura",
        "city": "Uttar Ramchandraghat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Agra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bah"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Etmadpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fatahabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kheragarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kiraoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saiyan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Aligarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Atrauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Akorha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Allahabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amiliya Kala"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Anapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Atrampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Balkaranpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Barna"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gambhir Patti Bisana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ghiyanagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hanuman Ganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hasanpur Soraon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Holagarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur Chandhan Urfpirthipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Janghai Bazar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khain"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Koraon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lalgopalganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mau Aima"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Meja"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Patwa Mutfarka"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Phaphamau"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sahson"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saidabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saraismail Urf Khagalpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shankargarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Soraon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tikri Ta.Kalmai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ugrasenpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Aalapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Alapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ambedkar Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Alipur Berjee"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Allapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur Pikar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur Sahara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur Tekam Para"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khatmepur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ladlapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rajesultanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tanda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amethi (Chatrapati Sahuji Mahraj Nagar)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amroha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amroha (J.P. Nagar)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhanaura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gajraula"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hasanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kanth"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Auraiya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bidhuna"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khan Jahanpur Chirkuan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Azamgarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Atraith"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bagh Shahoman"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Budhan Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dariya Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Godhan Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Itaura Or Itraura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jahana Ganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jajauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kasba Sagri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Katghar Sadar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maniha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nijamabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sagri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Baghpat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khekada"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bahraich"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kaisarganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahsi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nanpara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sankalpa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bairia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ballia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bansdih"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bariya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Basllia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Belthara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Belthara Road"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rasra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sikanderpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Balrampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Banda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Barabanki"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Haidergarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kandarwal Kalan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ram Sanehi Ghat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ramnagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saifpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sirauligauspur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bareilly"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Baheri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhamora"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Faridpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fridpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Meerganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mirganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Basti"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhusuriya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khoria"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lakhraiya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bijnor"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chandpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Faridpur Meghu Urf Tuwakpuri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kotkadar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nagina"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Najibabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Badaun"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bilsi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bisauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Budaun"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Busauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dataganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gunnaur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sahaswan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Anupshahar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bulandshahr"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dibai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sikarpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Siyana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chandauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhanapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chitrakoot"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bandha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Barhaj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhatpar Rani"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Deoria"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rudrapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Telia Kurmi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Aliganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Etah"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalesar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kasganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Patiyali"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Patiyali Dehat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bharthana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chakar Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Etawah"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jaswant Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saifai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Alapur Uparhar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Faizabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur Parsurampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Motinagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sohawal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Farrukhabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fatehpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Firozabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shikohabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Achheja"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bisara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bishrakh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dadri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dharauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhoom Manikpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maicha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Piyaoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Salarpur Kalan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ator Nagla"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ghaziabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chhapraula"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chipyana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhaulana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Garhmukteshwar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khanjarpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khiluai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Loni"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Modinagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Morta"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Muradnagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nistoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pasonda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Roza Yakubpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Simbhaoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ghazipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jakhanian"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalal Kasim"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kakhania"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kakori"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saidpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Zamania"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gonda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Manakpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Manapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mankapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tarabganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bansgaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhowapar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Brijmanganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gorakhpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gola Bazar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jangal Agahi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kauriram"
      },
      {
        "state": "Uttar Pradesh",
        "city": "KHAJANI"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Madria"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sahjanwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shajnwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hamirpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hapur (Panchsheel Nagar)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bilgram"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hardoi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mallawan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sandila"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sawayajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hathras"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalaun"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kalpi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Madhogarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Orai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jaunpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Badlapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bidauri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kerakat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Machhali Shahar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mainuddinpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mariahu"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mungra Badshahpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shahganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Garautha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jhansi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mauranipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Moth Rural"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kannauj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amraudha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bilhaur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chaubepur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jhinjhak"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kanpur Dehat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pathakpur Shivrajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pukhrayan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rasulabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sheoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shivli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shivrajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Uttaripura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kanpur Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amaur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amour"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Anand Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Anwarganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Aranj Hami"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Armapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Arya Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ashok Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Baikunthpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Barai Garh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bausar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Behta Gambhirpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhadrsa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhairam Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bharu"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhautipratappur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bheoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhimsen"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bidhnu"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Binour"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bisayakpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bithoor"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chakarpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chakeri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chaubepur Pankhan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chauraha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chaurai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Daulat Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fahimabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Farrashkhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fatehpur Roshanai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fazalganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ganga Ganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Garholi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gazipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gopalpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gopalpur Narwal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Govind Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Harchand Khera"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hardauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Harjinder Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hathgaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hatia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hithgaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Itra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jajmau"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jamoo"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jarkala"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jawahar Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jugrajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Juhi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kadri Champatpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kaindha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kakadeo"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kalyanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kamalpur Narwal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kamlapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Karbigwan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kasigaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kathara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Katherua"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kathongar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kaushalpuri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khadesar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khapra Mohal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kheora"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kudani"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kudni"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kulgaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lajpat Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maharajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maholi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahua Gaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Majhawan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mandhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mardanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Meerpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Motijheel"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Munshipurwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Naramau"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Narwal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nasra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Naubasta"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nayaganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "New Kanpur City"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nirala"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pachor"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Palhepur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Palibhogipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Panki"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Parade"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Parsadepur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Parsauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pem"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Phuphuwarrajthok"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pipergaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Premnagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Purana Kanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Purwameer"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Railganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Raipur Kukhat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ramaipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rania"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rasulpur Umra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rooma"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sachendi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sanigawan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sapai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sarsaul"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sarvodaya Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sawaijpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Semarjhal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shanti Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shivaji Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shivarajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shyam Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sirhi Itara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Subhauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Swaijpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Swarup Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tatiyaganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Taudhakpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tikra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tikra Kanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tilsahari"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Vijay Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Vikas Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Yashoda Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kanshiram Nagar (Kasganj)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kaushambi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Babura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bamharavli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Charwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Karari"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Manjhanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bodarwar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hata"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kasia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kushinagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Padrauna"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sewarhi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhaurahara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhaurehra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gola Gokaran Nath"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lakhimpur - Kheri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maharani Paschim"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mohammadi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nighasan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Palia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Palia Kalan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pallia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lalitpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahrauni"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Talbehat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lucknow"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bargadi Magath"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Malihabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mohanlalganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Farenda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maharajganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahuawa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nachulwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nautanwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pharenda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahoba"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mainpuri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhongaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kahal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Karahal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Karhal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mathura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chhata"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chhata Rural"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mani Garhi Bangar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mani Garhi Khadar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mant"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mau"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kotwalipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maunath Bhanjan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Meerut"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kithore"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mawana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sardhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Adalhat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mirzapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chunar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhusah"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mirazapur Sadar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Balari"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bilari"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Moradabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pakharauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Thakurdwara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Budhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jansath"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kairana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Muzaffarnagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amaria"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pilibhit"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Barkhera"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bisalpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pooranpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Puranpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pratapgarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Babu Patti"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kudna Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kunda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lalganj Ajhara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Misir Patti"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Narhar Patti"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pratapgarh city"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pure Goliya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Raniganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sarayindrawat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "RaeBareli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dalmau"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lohanipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Salon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Salon Bazar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sareni"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tiloi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Unchahar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jafrabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kilak"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Milak"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shahabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Suar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Behat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Deoband"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gangoh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saharanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Muzaffarabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nagal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nakur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nanauta"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nangal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Punwarka"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rampur Maniharan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Roorkee"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sarsawa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sambhal (Bhim Nagar)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sant Kabir Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhan Ghata"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhanghata"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shahjahanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Powayan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tilhar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shamali (Prabuddh Nagar)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Siddharth Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhanpur Rani"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Itwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Malimanha Mustkaham"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nakthar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Naugarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Biswan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Laharpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahmoodabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahmudabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mishrikh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Misrikh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Misrikh-cum-Neemsar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sidhauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sitapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Anpara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dudhinagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sonbhadra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mrizapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Robertsganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sobhadra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gauriganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Haliyapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kadipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lambhua"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sultanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Musafirkhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Akbapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Safipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Unnao"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Varanasi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Thana"
      },
      {
        "state": "Uttarakhand",
        "city": "Almora"
      },
      {
        "state": "Uttarakhand",
        "city": "Barkinda"
      },
      {
        "state": "Uttarakhand",
        "city": "Bhanoli"
      },
      {
        "state": "Uttarakhand",
        "city": "Bhikia Sain"
      },
      {
        "state": "Uttarakhand",
        "city": "Bhikiyasan"
      },
      {
        "state": "Uttarakhand",
        "city": "Chaukhutiya"
      },
      {
        "state": "Uttarakhand",
        "city": "Dwarahat"
      },
      {
        "state": "Uttarakhand",
        "city": "Jainti"
      },
      {
        "state": "Uttarakhand",
        "city": "Ranikhet"
      },
      {
        "state": "Uttarakhand",
        "city": "Sigro"
      },
      {
        "state": "Uttarakhand",
        "city": "Bageshwar"
      },
      {
        "state": "Uttarakhand",
        "city": "Kanda"
      },
      {
        "state": "Uttarakhand",
        "city": "Kapkot"
      },
      {
        "state": "Uttarakhand",
        "city": "Chamoli"
      },
      {
        "state": "Uttarakhand",
        "city": "Ghat"
      },
      {
        "state": "Uttarakhand",
        "city": "Joshimath"
      },
      {
        "state": "Uttarakhand",
        "city": "Khadun Laga Gawali"
      },
      {
        "state": "Uttarakhand",
        "city": "Maidhana Saimi"
      },
      {
        "state": "Uttarakhand",
        "city": "Pokhari"
      },
      {
        "state": "Uttarakhand",
        "city": "Tharali"
      },
      {
        "state": "Uttarakhand",
        "city": "Champawat"
      },
      {
        "state": "Uttarakhand",
        "city": "Lohaghat"
      },
      {
        "state": "Uttarakhand",
        "city": "Chakrata"
      },
      {
        "state": "Uttarakhand",
        "city": "Dehradun"
      },
      {
        "state": "Uttarakhand",
        "city": "Radu"
      },
      {
        "state": "Uttarakhand",
        "city": "Tiuni"
      },
      {
        "state": "Uttarakhand",
        "city": "Veerbhadra"
      },
      {
        "state": "Uttarakhand",
        "city": "Vikasnagar"
      },
      {
        "state": "Uttarakhand",
        "city": "Haridwar"
      },
      {
        "state": "Uttarakhand",
        "city": "Laksar"
      },
      {
        "state": "Uttarakhand",
        "city": "Muzaffarabad"
      },
      {
        "state": "Uttarakhand",
        "city": "Roorkee"
      },
      {
        "state": "Uttarakhand",
        "city": "Basikhet"
      },
      {
        "state": "Uttarakhand",
        "city": "Bhimtal"
      },
      {
        "state": "Uttarakhand",
        "city": "Haldwani"
      },
      {
        "state": "Uttarakhand",
        "city": "Kaladhungi"
      },
      {
        "state": "Uttarakhand",
        "city": "Lalkuan"
      },
      {
        "state": "Uttarakhand",
        "city": "Nainital"
      },
      {
        "state": "Uttarakhand",
        "city": "South Gola Range"
      },
      {
        "state": "Uttarakhand",
        "city": "Dhumakot"
      },
      {
        "state": "Uttarakhand",
        "city": "Kotdwara"
      },
      {
        "state": "Uttarakhand",
        "city": "Lansdowne"
      },
      {
        "state": "Uttarakhand",
        "city": "Pauri"
      },
      {
        "state": "Uttarakhand",
        "city": "Pauri Garhwal"
      },
      {
        "state": "Uttarakhand",
        "city": "Takoli Challannsyun"
      },
      {
        "state": "Uttarakhand",
        "city": "Thali Sain"
      },
      {
        "state": "Uttarakhand",
        "city": "Berinag"
      },
      {
        "state": "Uttarakhand",
        "city": "Pithoragarh"
      },
      {
        "state": "Uttarakhand",
        "city": "Dharchula"
      },
      {
        "state": "Uttarakhand",
        "city": "Didihat"
      },
      {
        "state": "Uttarakhand",
        "city": "Gangolihat"
      },
      {
        "state": "Uttarakhand",
        "city": "Munsyari"
      },
      {
        "state": "Uttarakhand",
        "city": "Jakholi"
      },
      {
        "state": "Uttarakhand",
        "city": "Jakholi Badma"
      },
      {
        "state": "Uttarakhand",
        "city": "Rudraprayag"
      },
      {
        "state": "Uttarakhand",
        "city": "Ukhimath"
      },
      {
        "state": "Uttarakhand",
        "city": "Tehri Garhwal"
      },
      {
        "state": "Uttarakhand",
        "city": "Devaprayag"
      },
      {
        "state": "Uttarakhand",
        "city": "Dhanolti"
      },
      {
        "state": "Uttarakhand",
        "city": "Dhanolti Lagga Goth"
      },
      {
        "state": "Uttarakhand",
        "city": "Gaja"
      },
      {
        "state": "Uttarakhand",
        "city": "Ghansali"
      },
      {
        "state": "Uttarakhand",
        "city": "Jakhnidhar"
      },
      {
        "state": "Uttarakhand",
        "city": "Narendra Nagar"
      },
      {
        "state": "Uttarakhand",
        "city": "New Tehri"
      },
      {
        "state": "Uttarakhand",
        "city": "Tehri"
      },
      {
        "state": "Uttarakhand",
        "city": "Bazpur"
      },
      {
        "state": "Uttarakhand",
        "city": "Udham Singh Nagar"
      },
      {
        "state": "Uttarakhand",
        "city": "Gadarpur"
      },
      {
        "state": "Uttarakhand",
        "city": "Jaspur"
      },
      {
        "state": "Uttarakhand",
        "city": "Kashipur"
      },
      {
        "state": "Uttarakhand",
        "city": "Khatima"
      },
      {
        "state": "Uttarakhand",
        "city": "Kichha"
      },
      {
        "state": "Uttarakhand",
        "city": "Rudrapur"
      },
      {
        "state": "Uttarakhand",
        "city": "Sitarganj"
      },
      {
        "state": "Uttarakhand",
        "city": "Tanda Range"
      },
      {
        "state": "Uttarakhand",
        "city": "Barkot"
      },
      {
        "state": "Uttarakhand",
        "city": "Bhatwari"
      },
      {
        "state": "Uttarakhand",
        "city": "Purola"
      },
      {
        "state": "Uttarakhand",
        "city": "Rajgarhi"
      },
      {
        "state": "West Bengal",
        "city": "Alipurduar"
      },
      {
        "state": "West Bengal",
        "city": "Bamunara"
      },
      {
        "state": "West Bengal",
        "city": "Bankura - I"
      },
      {
        "state": "West Bengal",
        "city": "Bankura - II"
      },
      {
        "state": "West Bengal",
        "city": "Bankura"
      },
      {
        "state": "West Bengal",
        "city": "Barjora"
      },
      {
        "state": "West Bengal",
        "city": "Bishnpur"
      },
      {
        "state": "West Bengal",
        "city": "Chhatna"
      },
      {
        "state": "West Bengal",
        "city": "Gangajalghati"
      },
      {
        "state": "West Bengal",
        "city": "Hir Bandh"
      },
      {
        "state": "West Bengal",
        "city": "Hirbandh"
      },
      {
        "state": "West Bengal",
        "city": "Indas"
      },
      {
        "state": "West Bengal",
        "city": "Indpur"
      },
      {
        "state": "West Bengal",
        "city": "Kotulpur"
      },
      {
        "state": "West Bengal",
        "city": "Mejia"
      },
      {
        "state": "West Bengal",
        "city": "Onda"
      },
      {
        "state": "West Bengal",
        "city": "Patrasayer"
      },
      {
        "state": "West Bengal",
        "city": "Raipur Bazar"
      },
      {
        "state": "West Bengal",
        "city": "Ranibandh"
      },
      {
        "state": "West Bengal",
        "city": "Sarenga"
      },
      {
        "state": "West Bengal",
        "city": "Simlapal"
      },
      {
        "state": "West Bengal",
        "city": "Sonamukhi"
      },
      {
        "state": "West Bengal",
        "city": "Taldangra"
      },
      {
        "state": "West Bengal",
        "city": "Andal"
      },
      {
        "state": "West Bengal",
        "city": "Asansol"
      },
      {
        "state": "West Bengal",
        "city": "Ausgram"
      },
      {
        "state": "West Bengal",
        "city": "Barabani"
      },
      {
        "state": "West Bengal",
        "city": "Bardhaman"
      },
      {
        "state": "West Bengal",
        "city": "Barsul"
      },
      {
        "state": "West Bengal",
        "city": "Bhatar"
      },
      {
        "state": "West Bengal",
        "city": "Billagram"
      },
      {
        "state": "West Bengal",
        "city": "Debsala"
      },
      {
        "state": "West Bengal",
        "city": "Dhramatala"
      },
      {
        "state": "West Bengal",
        "city": "Durgapur"
      },
      {
        "state": "West Bengal",
        "city": "Faridpur Durgapur"
      },
      {
        "state": "West Bengal",
        "city": "Galsi"
      },
      {
        "state": "West Bengal",
        "city": "Gangatikuri"
      },
      {
        "state": "West Bengal",
        "city": "Gushkara"
      },
      {
        "state": "West Bengal",
        "city": "Guskhara"
      },
      {
        "state": "West Bengal",
        "city": "Jamuria"
      },
      {
        "state": "West Bengal",
        "city": "Kanksa"
      },
      {
        "state": "West Bengal",
        "city": "Katwa - I"
      },
      {
        "state": "West Bengal",
        "city": "Khandaghosh"
      },
      {
        "state": "West Bengal",
        "city": "Kusumgram"
      },
      {
        "state": "West Bengal",
        "city": "Laudoha"
      },
      {
        "state": "West Bengal",
        "city": "Mangalkot"
      },
      {
        "state": "West Bengal",
        "city": "Manteswar"
      },
      {
        "state": "West Bengal",
        "city": "Pandaveshwar"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Memari"
      },
      {
        "state": "West Bengal",
        "city": "Raniganj"
      },
      {
        "state": "West Bengal",
        "city": "Rukushpur"
      },
      {
        "state": "West Bengal",
        "city": "Salanpur"
      },
      {
        "state": "West Bengal",
        "city": "Birbhum"
      },
      {
        "state": "West Bengal",
        "city": "Bolpur"
      },
      {
        "state": "West Bengal",
        "city": "Dubrajpur"
      },
      {
        "state": "West Bengal",
        "city": "Haraipur"
      },
      {
        "state": "West Bengal",
        "city": "Labpur"
      },
      {
        "state": "West Bengal",
        "city": "Mayureswar"
      },
      {
        "state": "West Bengal",
        "city": "Murarai"
      },
      {
        "state": "West Bengal",
        "city": "Nalhati"
      },
      {
        "state": "West Bengal",
        "city": "Nanoor"
      },
      {
        "state": "West Bengal",
        "city": "Rampurhat"
      },
      {
        "state": "West Bengal",
        "city": "Sainthia"
      },
      {
        "state": "West Bengal",
        "city": "Sriniketan"
      },
      {
        "state": "West Bengal",
        "city": "Suri"
      },
      {
        "state": "West Bengal",
        "city": "Cooch Behar"
      },
      {
        "state": "West Bengal",
        "city": "Bamunia Purba"
      },
      {
        "state": "West Bengal",
        "city": "Bara Simulguri"
      },
      {
        "state": "West Bengal",
        "city": "Chhota Laukuthi"
      },
      {
        "state": "West Bengal",
        "city": "Dinhata"
      },
      {
        "state": "West Bengal",
        "city": "Kharija Durganagar Dwitia Khanda"
      },
      {
        "state": "West Bengal",
        "city": "Matiar Kuthi"
      },
      {
        "state": "West Bengal",
        "city": "Pundibari"
      },
      {
        "state": "West Bengal",
        "city": "Sitai"
      },
      {
        "state": "West Bengal",
        "city": "Sitalkuchi"
      },
      {
        "state": "West Bengal",
        "city": "Tengnamari"
      },
      {
        "state": "West Bengal",
        "city": "Tufanganj"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Chhat Jaygir Chilakhana"
      },
      {
        "state": "West Bengal",
        "city": "Balurghat"
      },
      {
        "state": "West Bengal",
        "city": "Bansihari"
      },
      {
        "state": "West Bengal",
        "city": "Gangarampur"
      },
      {
        "state": "West Bengal",
        "city": "Hili"
      },
      {
        "state": "West Bengal",
        "city": "Kushmandi"
      },
      {
        "state": "West Bengal",
        "city": "Rangabhita"
      },
      {
        "state": "West Bengal",
        "city": "Tapan"
      },
      {
        "state": "West Bengal",
        "city": "Darjeeling"
      },
      {
        "state": "West Bengal",
        "city": "Dayal Thong Tea Garden"
      },
      {
        "state": "West Bengal",
        "city": "Ghoom"
      },
      {
        "state": "West Bengal",
        "city": "Khoribari"
      },
      {
        "state": "West Bengal",
        "city": "Kurseong"
      },
      {
        "state": "West Bengal",
        "city": "Mal Forest"
      },
      {
        "state": "West Bengal",
        "city": "Matigara"
      },
      {
        "state": "West Bengal",
        "city": "Mirik"
      },
      {
        "state": "West Bengal",
        "city": "Naxalbari"
      },
      {
        "state": "West Bengal",
        "city": "Pulbazar"
      },
      {
        "state": "West Bengal",
        "city": "Rangli Rangliot"
      },
      {
        "state": "West Bengal",
        "city": "Soriang Khasmahal"
      },
      {
        "state": "West Bengal",
        "city": "Tukdah Forest"
      },
      {
        "state": "West Bengal",
        "city": "Amodghata"
      },
      {
        "state": "West Bengal",
        "city": "Balagarh"
      },
      {
        "state": "West Bengal",
        "city": "Bandel"
      },
      {
        "state": "West Bengal",
        "city": "Bansberia"
      },
      {
        "state": "West Bengal",
        "city": "Bhastara"
      },
      {
        "state": "West Bengal",
        "city": "Boinchi"
      },
      {
        "state": "West Bengal",
        "city": "Chandangar"
      },
      {
        "state": "West Bengal",
        "city": "Chanditala"
      },
      {
        "state": "West Bengal",
        "city": "Chinsurah - Magra"
      },
      {
        "state": "West Bengal",
        "city": "Dankuni"
      },
      {
        "state": "West Bengal",
        "city": "Dhaniakhali"
      },
      {
        "state": "West Bengal",
        "city": "Goghat"
      },
      {
        "state": "West Bengal",
        "city": "Guptipara"
      },
      {
        "state": "West Bengal",
        "city": "Gurap"
      },
      {
        "state": "West Bengal",
        "city": "Haripal"
      },
      {
        "state": "West Bengal",
        "city": "Hooghly"
      },
      {
        "state": "West Bengal",
        "city": "Itachuna"
      },
      {
        "state": "West Bengal",
        "city": "Jelepara"
      },
      {
        "state": "West Bengal",
        "city": "Khamargachi"
      },
      {
        "state": "West Bengal",
        "city": "Khamarpara"
      },
      {
        "state": "West Bengal",
        "city": "Khanakul"
      },
      {
        "state": "West Bengal",
        "city": "Khanati"
      },
      {
        "state": "West Bengal",
        "city": "Madhubati"
      },
      {
        "state": "West Bengal",
        "city": "Mosat"
      },
      {
        "state": "West Bengal",
        "city": "Pandua"
      },
      {
        "state": "West Bengal",
        "city": "Puinan"
      },
      {
        "state": "West Bengal",
        "city": "Purusattampur"
      },
      {
        "state": "West Bengal",
        "city": "Raynagar"
      },
      {
        "state": "West Bengal",
        "city": "Senhat"
      },
      {
        "state": "West Bengal",
        "city": "Serampore"
      },
      {
        "state": "West Bengal",
        "city": "Serampur Uttarpara"
      },
      {
        "state": "West Bengal",
        "city": "Singur"
      },
      {
        "state": "West Bengal",
        "city": "Uttarpara Kotrung"
      },
      {
        "state": "West Bengal",
        "city": "Domjur"
      },
      {
        "state": "West Bengal",
        "city": "Howrah"
      },
      {
        "state": "West Bengal",
        "city": "Jagatballavpur"
      },
      {
        "state": "West Bengal",
        "city": "Panchla"
      },
      {
        "state": "West Bengal",
        "city": "Bhagatpur Tea Garden"
      },
      {
        "state": "West Bengal",
        "city": "Binnaguri"
      },
      {
        "state": "West Bengal",
        "city": "Birpara Tea Garden"
      },
      {
        "state": "West Bengal",
        "city": "Chhat Guzrimari"
      },
      {
        "state": "West Bengal",
        "city": "Dhupguri"
      },
      {
        "state": "West Bengal",
        "city": "Gachaimari"
      },
      {
        "state": "West Bengal",
        "city": "Hamiltonganj"
      },
      {
        "state": "West Bengal",
        "city": "Jalpaiguri"
      },
      {
        "state": "West Bengal",
        "city": "Kalchini"
      },
      {
        "state": "West Bengal",
        "city": "Kalchini Tea Garden"
      },
      {
        "state": "West Bengal",
        "city": "Kumargram"
      },
      {
        "state": "West Bengal",
        "city": "Matelli"
      },
      {
        "state": "West Bengal",
        "city": "Nagrakata"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Kamakhyaguri"
      },
      {
        "state": "West Bengal",
        "city": "Kalimpong"
      },
      {
        "state": "West Bengal",
        "city": "Kolkata"
      },
      {
        "state": "West Bengal",
        "city": "Aktail"
      },
      {
        "state": "West Bengal",
        "city": "Alal"
      },
      {
        "state": "West Bengal",
        "city": "Malda"
      },
      {
        "state": "West Bengal",
        "city": "Andhirampara"
      },
      {
        "state": "West Bengal",
        "city": "Baishnabnagar"
      },
      {
        "state": "West Bengal",
        "city": "Bamongola"
      },
      {
        "state": "West Bengal",
        "city": "Bishnuprosad"
      },
      {
        "state": "West Bengal",
        "city": "Bulbulchandi"
      },
      {
        "state": "West Bengal",
        "city": "Chanchal"
      },
      {
        "state": "West Bengal",
        "city": "Chauki Mirdadpur"
      },
      {
        "state": "West Bengal",
        "city": "Choralmani"
      },
      {
        "state": "West Bengal",
        "city": "Damaipur"
      },
      {
        "state": "West Bengal",
        "city": "Deotala"
      },
      {
        "state": "West Bengal",
        "city": "Englishbazar"
      },
      {
        "state": "West Bengal",
        "city": "Gazole"
      },
      {
        "state": "West Bengal",
        "city": "Hardam Nagar"
      },
      {
        "state": "West Bengal",
        "city": "Harishchandrapur"
      },
      {
        "state": "West Bengal",
        "city": "Hatinda"
      },
      {
        "state": "West Bengal",
        "city": "Jadupur"
      },
      {
        "state": "West Bengal",
        "city": "Kadamtala"
      },
      {
        "state": "West Bengal",
        "city": "Kaliachak"
      },
      {
        "state": "West Bengal",
        "city": "Kanturka"
      },
      {
        "state": "West Bengal",
        "city": "Khanpur Hubaspur 165"
      },
      {
        "state": "West Bengal",
        "city": "Kharba"
      },
      {
        "state": "West Bengal",
        "city": "Kusidha"
      },
      {
        "state": "West Bengal",
        "city": "Lakshipur"
      },
      {
        "state": "West Bengal",
        "city": "Mahatpur"
      },
      {
        "state": "West Bengal",
        "city": "Malior"
      },
      {
        "state": "West Bengal",
        "city": "Manikchak"
      },
      {
        "state": "West Bengal",
        "city": "Mathabari"
      },
      {
        "state": "West Bengal",
        "city": "Mayna"
      },
      {
        "state": "West Bengal",
        "city": "Nimna Tandu Forest"
      },
      {
        "state": "West Bengal",
        "city": "Old Malda"
      },
      {
        "state": "West Bengal",
        "city": "Panchanandapur"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Narayanpur"
      },
      {
        "state": "West Bengal",
        "city": "Pashchim Narayanpur"
      },
      {
        "state": "West Bengal",
        "city": "Pipla Kasimpur"
      },
      {
        "state": "West Bengal",
        "city": "Pirolgari More"
      },
      {
        "state": "West Bengal",
        "city": "Pukhuria"
      },
      {
        "state": "West Bengal",
        "city": "Ratua"
      },
      {
        "state": "West Bengal",
        "city": "Sahajalalpur"
      },
      {
        "state": "West Bengal",
        "city": "Talashur"
      },
      {
        "state": "West Bengal",
        "city": "Talbangrua"
      },
      {
        "state": "West Bengal",
        "city": "Talgachhi"
      },
      {
        "state": "West Bengal",
        "city": "Tulsihata"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Lakshmipur"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Laxmipur"
      },
      {
        "state": "West Bengal",
        "city": "Ahiran"
      },
      {
        "state": "West Bengal",
        "city": "Beharkhanda Nabagram"
      },
      {
        "state": "West Bengal",
        "city": "Behrampore"
      },
      {
        "state": "West Bengal",
        "city": "Beldanga"
      },
      {
        "state": "West Bengal",
        "city": "Berhampore"
      },
      {
        "state": "West Bengal",
        "city": "Bhagwangola-ii"
      },
      {
        "state": "West Bengal",
        "city": "Domkal"
      },
      {
        "state": "West Bengal",
        "city": "Farakka"
      },
      {
        "state": "West Bengal",
        "city": "Farrakka Barrage Township"
      },
      {
        "state": "West Bengal",
        "city": "Giria"
      },
      {
        "state": "West Bengal",
        "city": "Hariharpara"
      },
      {
        "state": "West Bengal",
        "city": "Iswarbati"
      },
      {
        "state": "West Bengal",
        "city": "Jalangi"
      },
      {
        "state": "West Bengal",
        "city": "Jangipur"
      },
      {
        "state": "West Bengal",
        "city": "Jiaganj"
      },
      {
        "state": "West Bengal",
        "city": "Kasbagoas"
      },
      {
        "state": "West Bengal",
        "city": "Khargram"
      },
      {
        "state": "West Bengal",
        "city": "Lalgola"
      },
      {
        "state": "West Bengal",
        "city": "Murshidabad"
      },
      {
        "state": "West Bengal",
        "city": "Nabagram"
      },
      {
        "state": "West Bengal",
        "city": "Palsanda"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Punropara"
      },
      {
        "state": "West Bengal",
        "city": "Raghunathganj"
      },
      {
        "state": "West Bengal",
        "city": "Raninagar"
      },
      {
        "state": "West Bengal",
        "city": "Suti"
      },
      {
        "state": "West Bengal",
        "city": "Teghari"
      },
      {
        "state": "West Bengal",
        "city": "Anandapally"
      },
      {
        "state": "West Bengal",
        "city": "Bethuadahari"
      },
      {
        "state": "West Bengal",
        "city": "Chakdaha"
      },
      {
        "state": "West Bengal",
        "city": "Chapra"
      },
      {
        "state": "West Bengal",
        "city": "Debagram"
      },
      {
        "state": "West Bengal",
        "city": "Dhubulia"
      },
      {
        "state": "West Bengal",
        "city": "Dogachhi"
      },
      {
        "state": "West Bengal",
        "city": "Dogachhi P"
      },
      {
        "state": "West Bengal",
        "city": "Dwipchandrapur"
      },
      {
        "state": "West Bengal",
        "city": "Hanskhali"
      },
      {
        "state": "West Bengal",
        "city": "Haringhata"
      },
      {
        "state": "West Bengal",
        "city": "Haringhata Farm"
      },
      {
        "state": "West Bengal",
        "city": "Karimpur 2"
      },
      {
        "state": "West Bengal",
        "city": "Nadia"
      },
      {
        "state": "West Bengal",
        "city": "Krishnaganj"
      },
      {
        "state": "West Bengal",
        "city": "Krishnanagar"
      },
      {
        "state": "West Bengal",
        "city": "Nabadwip"
      },
      {
        "state": "West Bengal",
        "city": "Nakashipara"
      },
      {
        "state": "West Bengal",
        "city": "Nowda"
      },
      {
        "state": "West Bengal",
        "city": "Palashipara"
      },
      {
        "state": "West Bengal",
        "city": "Payradanga"
      },
      {
        "state": "West Bengal",
        "city": "Plashipara"
      },
      {
        "state": "West Bengal",
        "city": "Plassey"
      },
      {
        "state": "West Bengal",
        "city": "Ranaghat"
      },
      {
        "state": "West Bengal",
        "city": "Santipur"
      },
      {
        "state": "West Bengal",
        "city": "Syamnagar"
      },
      {
        "state": "West Bengal",
        "city": "Tehatta"
      },
      {
        "state": "West Bengal",
        "city": "Adhata"
      },
      {
        "state": "West Bengal",
        "city": "Ajijnagar"
      },
      {
        "state": "West Bengal",
        "city": "Akaipur"
      },
      {
        "state": "West Bengal",
        "city": "Akandakeshari"
      },
      {
        "state": "West Bengal",
        "city": "North 24 Parganas"
      },
      {
        "state": "West Bengal",
        "city": "Ambaria"
      },
      {
        "state": "West Bengal",
        "city": "Amdobe"
      },
      {
        "state": "West Bengal",
        "city": "Amodpur"
      },
      {
        "state": "West Bengal",
        "city": "Amudia"
      },
      {
        "state": "West Bengal",
        "city": "Angrail"
      },
      {
        "state": "West Bengal",
        "city": "Asharu"
      },
      {
        "state": "West Bengal",
        "city": "Atghara"
      },
      {
        "state": "West Bengal",
        "city": "Atpukur"
      },
      {
        "state": "West Bengal",
        "city": "Aturia"
      },
      {
        "state": "West Bengal",
        "city": "Aziznagar"
      },
      {
        "state": "West Bengal",
        "city": "Badkulla"
      },
      {
        "state": "West Bengal",
        "city": "Baduria"
      },
      {
        "state": "West Bengal",
        "city": "Bagband-Saiberia"
      },
      {
        "state": "West Bengal",
        "city": "Bagdah"
      },
      {
        "state": "West Bengal",
        "city": "Baidyapur"
      },
      {
        "state": "West Bengal",
        "city": "Baikara"
      },
      {
        "state": "West Bengal",
        "city": "Baksha"
      },
      {
        "state": "West Bengal",
        "city": "Ballabhpur"
      },
      {
        "state": "West Bengal",
        "city": "Ballavpur"
      },
      {
        "state": "West Bengal",
        "city": "Bamangachi"
      },
      {
        "state": "West Bengal",
        "city": "Bamanpukur"
      },
      {
        "state": "West Bengal",
        "city": "Baneswarpur"
      },
      {
        "state": "West Bengal",
        "city": "Bangangram"
      },
      {
        "state": "West Bengal",
        "city": "Bangaon"
      },
      {
        "state": "West Bengal",
        "city": "Banglani"
      },
      {
        "state": "West Bengal",
        "city": "Bankra Dobar"
      },
      {
        "state": "West Bengal",
        "city": "Bara Bankra"
      },
      {
        "state": "West Bengal",
        "city": "Barasat"
      },
      {
        "state": "West Bengal",
        "city": "Barasehana"
      },
      {
        "state": "West Bengal",
        "city": "Barrackpur Cantonment"
      },
      {
        "state": "West Bengal",
        "city": "Barunhat"
      },
      {
        "state": "West Bengal",
        "city": "Basirhat"
      },
      {
        "state": "West Bengal",
        "city": "Beara"
      },
      {
        "state": "West Bengal",
        "city": "Berabari"
      },
      {
        "state": "West Bengal",
        "city": "Beraberi"
      },
      {
        "state": "West Bengal",
        "city": "Bermajur"
      },
      {
        "state": "West Bengal",
        "city": "Bhabanipur"
      },
      {
        "state": "West Bengal",
        "city": "Bhaduria"
      },
      {
        "state": "West Bengal",
        "city": "Bhagyamantapur"
      },
      {
        "state": "West Bengal",
        "city": "Bhandar khola"
      },
      {
        "state": "West Bengal",
        "city": "Bhandarkhali"
      },
      {
        "state": "West Bengal",
        "city": "BHANDER KOLA BAZAR"
      },
      {
        "state": "West Bengal",
        "city": "Bhangatushkhali"
      },
      {
        "state": "West Bengal",
        "city": "Bhasila"
      },
      {
        "state": "West Bengal",
        "city": "Bhatpara"
      },
      {
        "state": "West Bengal",
        "city": "Biramnagar"
      },
      {
        "state": "West Bengal",
        "city": "Bishpur"
      },
      {
        "state": "West Bengal",
        "city": "Bithari"
      },
      {
        "state": "West Bengal",
        "city": "Bodai"
      },
      {
        "state": "West Bengal",
        "city": "Bouthakurani"
      },
      {
        "state": "West Bengal",
        "city": "Boyra"
      },
      {
        "state": "West Bengal",
        "city": "Chaigharia"
      },
      {
        "state": "West Bengal",
        "city": "Chaital"
      },
      {
        "state": "West Bengal",
        "city": "Chak Patli"
      },
      {
        "state": "West Bengal",
        "city": "Chandpara"
      },
      {
        "state": "West Bengal",
        "city": "Charalkhali"
      },
      {
        "state": "West Bengal",
        "city": "Charghat"
      },
      {
        "state": "West Bengal",
        "city": "Chauhata"
      },
      {
        "state": "West Bengal",
        "city": "Chhaigharia"
      },
      {
        "state": "West Bengal",
        "city": "Chhota Jagulia"
      },
      {
        "state": "West Bengal",
        "city": "Chhota Sehara"
      },
      {
        "state": "West Bengal",
        "city": "Chikanpara"
      },
      {
        "state": "West Bengal",
        "city": "Chowberia"
      },
      {
        "state": "West Bengal",
        "city": "Chowrashi"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Bagundi"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Chatra"
      },
      {
        "state": "West Bengal",
        "city": "Dandirhat P"
      },
      {
        "state": "West Bengal",
        "city": "Dashin Akhratala"
      },
      {
        "state": "West Bengal",
        "city": "Dattapara"
      },
      {
        "state": "West Bengal",
        "city": "Deara"
      },
      {
        "state": "West Bengal",
        "city": "Debaipukur"
      },
      {
        "state": "West Bengal",
        "city": "Deganga"
      },
      {
        "state": "West Bengal",
        "city": "Deulia"
      },
      {
        "state": "West Bengal",
        "city": "Dhaltitha P"
      },
      {
        "state": "West Bengal",
        "city": "Dhania"
      },
      {
        "state": "West Bengal",
        "city": "Dhanyakuria"
      },
      {
        "state": "West Bengal",
        "city": "Dheknamari"
      },
      {
        "state": "West Bengal",
        "city": "Dhuturdaha"
      },
      {
        "state": "West Bengal",
        "city": "Dogachia"
      },
      {
        "state": "West Bengal",
        "city": "Durgamandap"
      },
      {
        "state": "West Bengal",
        "city": "Duttapara"
      },
      {
        "state": "West Bengal",
        "city": "Eojnagar"
      },
      {
        "state": "West Bengal",
        "city": "Gadpukuria"
      },
      {
        "state": "West Bengal",
        "city": "Gaighata"
      },
      {
        "state": "West Bengal",
        "city": "Galdaha"
      },
      {
        "state": "West Bengal",
        "city": "Gandharbbapur"
      },
      {
        "state": "West Bengal",
        "city": "Ganrapota"
      },
      {
        "state": "West Bengal",
        "city": "Ganti"
      },
      {
        "state": "West Bengal",
        "city": "Garakupi"
      },
      {
        "state": "West Bengal",
        "city": "Garulia"
      },
      {
        "state": "West Bengal",
        "city": "Ghat Patila"
      },
      {
        "state": "West Bengal",
        "city": "Ghatihara"
      },
      {
        "state": "West Bengal",
        "city": "Ghona"
      },
      {
        "state": "West Bengal",
        "city": "Goal Bathan"
      },
      {
        "state": "West Bengal",
        "city": "Goaldaha"
      },
      {
        "state": "West Bengal",
        "city": "Gobardanga"
      },
      {
        "state": "West Bengal",
        "city": "Gobardhanpur"
      },
      {
        "state": "West Bengal",
        "city": "Gobila"
      },
      {
        "state": "West Bengal",
        "city": "Gobindapur"
      },
      {
        "state": "West Bengal",
        "city": "Gobrapur"
      },
      {
        "state": "West Bengal",
        "city": "Gokna"
      },
      {
        "state": "West Bengal",
        "city": "Gopalnagar"
      },
      {
        "state": "West Bengal",
        "city": "Habra"
      },
      {
        "state": "West Bengal",
        "city": "Haroa"
      },
      {
        "state": "West Bengal",
        "city": "Hasnabad"
      },
      {
        "state": "West Bengal",
        "city": "Hatgachha"
      },
      {
        "state": "West Bengal",
        "city": "Hatisala"
      },
      {
        "state": "West Bengal",
        "city": "Helencha"
      },
      {
        "state": "West Bengal",
        "city": "Hemnagar"
      },
      {
        "state": "West Bengal",
        "city": "Hingalganj"
      },
      {
        "state": "West Bengal",
        "city": "Hingli"
      },
      {
        "state": "West Bengal",
        "city": "Hisabi"
      },
      {
        "state": "West Bengal",
        "city": "Humbirpara"
      },
      {
        "state": "West Bengal",
        "city": "Ichhabacha"
      },
      {
        "state": "West Bengal",
        "city": "Ichhlampur"
      },
      {
        "state": "West Bengal",
        "city": "Ishwarigachha"
      },
      {
        "state": "West Bengal",
        "city": "Itinda"
      },
      {
        "state": "West Bengal",
        "city": "Jadurhati"
      },
      {
        "state": "West Bengal",
        "city": "Jagadishpur"
      },
      {
        "state": "West Bengal",
        "city": "Jagatnagar"
      },
      {
        "state": "West Bengal",
        "city": "Jaleshwar"
      },
      {
        "state": "West Bengal",
        "city": "Janaphul"
      },
      {
        "state": "West Bengal",
        "city": "Jangalpur"
      },
      {
        "state": "West Bengal",
        "city": "Jashaikati"
      },
      {
        "state": "West Bengal",
        "city": "Jhawdanga"
      },
      {
        "state": "West Bengal",
        "city": "Jhikra"
      },
      {
        "state": "West Bengal",
        "city": "Jhowdanga"
      },
      {
        "state": "West Bengal",
        "city": "Jogesganj"
      },
      {
        "state": "West Bengal",
        "city": "Joygopalpur"
      },
      {
        "state": "West Bengal",
        "city": "Joypul"
      },
      {
        "state": "West Bengal",
        "city": "Kachua Swarupnagar"
      },
      {
        "state": "West Bengal",
        "city": "Kadambagachi"
      },
      {
        "state": "West Bengal",
        "city": "Kadampukur"
      },
      {
        "state": "West Bengal",
        "city": "Kahankia"
      },
      {
        "state": "West Bengal",
        "city": "Kaijuri"
      },
      {
        "state": "West Bengal",
        "city": "Kaipukharia"
      },
      {
        "state": "West Bengal",
        "city": "Kaipukuria"
      },
      {
        "state": "West Bengal",
        "city": "Kaliani"
      },
      {
        "state": "West Bengal",
        "city": "Kalinagar"
      },
      {
        "state": "West Bengal",
        "city": "Kamdebpur"
      },
      {
        "state": "West Bengal",
        "city": "Kamduni"
      },
      {
        "state": "West Bengal",
        "city": "Kanchrapara"
      },
      {
        "state": "West Bengal",
        "city": "Kanmari"
      },
      {
        "state": "West Bengal",
        "city": "Kapileshwarpur"
      },
      {
        "state": "West Bengal",
        "city": "Kashinathpur"
      },
      {
        "state": "West Bengal",
        "city": "Kasinathpur"
      },
      {
        "state": "West Bengal",
        "city": "Katiahat"
      },
      {
        "state": "West Bengal",
        "city": "Khalisadi"
      },
      {
        "state": "West Bengal",
        "city": "Khalitpur"
      },
      {
        "state": "West Bengal",
        "city": "Kharampur"
      },
      {
        "state": "West Bengal",
        "city": "Kharua Rajapur"
      },
      {
        "state": "West Bengal",
        "city": "Kharupala"
      },
      {
        "state": "West Bengal",
        "city": "Khas Balandar"
      },
      {
        "state": "West Bengal",
        "city": "Khilkapur"
      },
      {
        "state": "West Bengal",
        "city": "Kholapota"
      },
      {
        "state": "West Bengal",
        "city": "Khulna"
      },
      {
        "state": "West Bengal",
        "city": "Korakati"
      },
      {
        "state": "West Bengal",
        "city": "Kothabbari"
      },
      {
        "state": "West Bengal",
        "city": "Krishnachandrapur"
      },
      {
        "state": "West Bengal",
        "city": "Krishnapur Madanpur"
      },
      {
        "state": "West Bengal",
        "city": "Kumarkhola"
      },
      {
        "state": "West Bengal",
        "city": "Kundipur"
      },
      {
        "state": "West Bengal",
        "city": "Labukhali"
      },
      {
        "state": "West Bengal",
        "city": "Lebukhali"
      },
      {
        "state": "West Bengal",
        "city": "Lot No 126"
      },
      {
        "state": "West Bengal",
        "city": "Machibhanga"
      },
      {
        "state": "West Bengal",
        "city": "Magurali"
      },
      {
        "state": "West Bengal",
        "city": "Magurati Shrirampur"
      },
      {
        "state": "West Bengal",
        "city": "Mahisha Machhlandapur"
      },
      {
        "state": "West Bengal",
        "city": "Makhalgacha"
      },
      {
        "state": "West Bengal",
        "city": "Malancha"
      },
      {
        "state": "West Bengal",
        "city": "Malekanghumti"
      },
      {
        "state": "West Bengal",
        "city": "Malidah"
      },
      {
        "state": "West Bengal",
        "city": "Malikapur"
      },
      {
        "state": "West Bengal",
        "city": "Matbari Abad"
      },
      {
        "state": "West Bengal",
        "city": "Merudandi"
      },
      {
        "state": "West Bengal",
        "city": "Minakhan"
      },
      {
        "state": "West Bengal",
        "city": "Mirhati"
      },
      {
        "state": "West Bengal",
        "city": "Murarisah"
      },
      {
        "state": "West Bengal",
        "city": "Naihati"
      },
      {
        "state": "West Bengal",
        "city": "Nakpul"
      },
      {
        "state": "West Bengal",
        "city": "Naksa"
      },
      {
        "state": "West Bengal",
        "city": "Nalabara"
      },
      {
        "state": "West Bengal",
        "city": "Nalkora"
      },
      {
        "state": "West Bengal",
        "city": "Natabaria"
      },
      {
        "state": "West Bengal",
        "city": "Natagram"
      },
      {
        "state": "West Bengal",
        "city": "Nazat"
      },
      {
        "state": "West Bengal",
        "city": "Nebadhai Duttapukur"
      },
      {
        "state": "West Bengal",
        "city": "Nehalpur"
      },
      {
        "state": "West Bengal",
        "city": "New Town"
      },
      {
        "state": "West Bengal",
        "city": "Nimchi"
      },
      {
        "state": "West Bengal",
        "city": "Nimdaria"
      },
      {
        "state": "West Bengal",
        "city": "Nityananda Kati"
      },
      {
        "state": "West Bengal",
        "city": "Panchberia"
      },
      {
        "state": "West Bengal",
        "city": "Panchita"
      },
      {
        "state": "West Bengal",
        "city": "Panchpota"
      },
      {
        "state": "West Bengal",
        "city": "Panitar"
      },
      {
        "state": "West Bengal",
        "city": "Parghumti"
      },
      {
        "state": "West Bengal",
        "city": "Parpatna"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Madhyampur"
      },
      {
        "state": "West Bengal",
        "city": "Patharghata"
      },
      {
        "state": "West Bengal",
        "city": "Patshimulia"
      },
      {
        "state": "West Bengal",
        "city": "Payragachhi"
      },
      {
        "state": "West Bengal",
        "city": "Petrapole"
      },
      {
        "state": "West Bengal",
        "city": "Phultala"
      },
      {
        "state": "West Bengal",
        "city": "Pipha"
      },
      {
        "state": "West Bengal",
        "city": "Pitha Pukuria"
      },
      {
        "state": "West Bengal",
        "city": "Prithiba"
      },
      {
        "state": "West Bengal",
        "city": "Purandarpur"
      },
      {
        "state": "West Bengal",
        "city": "Purbba Chak"
      },
      {
        "state": "West Bengal",
        "city": "Purbba Khejurbaria"
      },
      {
        "state": "West Bengal",
        "city": "Putia"
      },
      {
        "state": "West Bengal",
        "city": "Putiamathbari"
      },
      {
        "state": "West Bengal",
        "city": "Raghabpur"
      },
      {
        "state": "West Bengal",
        "city": "Raikhan"
      },
      {
        "state": "West Bengal",
        "city": "Rajarhat"
      },
      {
        "state": "West Bengal",
        "city": "Rajendrapur"
      },
      {
        "state": "West Bengal",
        "city": "Rajibpur"
      },
      {
        "state": "West Bengal",
        "city": "Rajibpur Bira"
      },
      {
        "state": "West Bengal",
        "city": "Rajpur Sonarpur"
      },
      {
        "state": "West Bengal",
        "city": "Ramnathpur"
      },
      {
        "state": "West Bengal",
        "city": "Ramshankarpur"
      },
      {
        "state": "West Bengal",
        "city": "Ranihati"
      },
      {
        "state": "West Bengal",
        "city": "Rupmari"
      },
      {
        "state": "West Bengal",
        "city": "Sahebkhali"
      },
      {
        "state": "West Bengal",
        "city": "Salua"
      },
      {
        "state": "West Bengal",
        "city": "Samudrapur"
      },
      {
        "state": "West Bengal",
        "city": "Sandelerbil"
      },
      {
        "state": "West Bengal",
        "city": "Sankardaha Abad"
      },
      {
        "state": "West Bengal",
        "city": "Sat Bhaiya"
      },
      {
        "state": "West Bengal",
        "city": "Satashi"
      },
      {
        "state": "West Bengal",
        "city": "Sayestanagar"
      },
      {
        "state": "West Bengal",
        "city": "Sendanga"
      },
      {
        "state": "West Bengal",
        "city": "Shasan"
      },
      {
        "state": "West Bengal",
        "city": "Shibhati"
      },
      {
        "state": "West Bengal",
        "city": "Shimulia"
      },
      {
        "state": "West Bengal",
        "city": "Sholka Durgapur"
      },
      {
        "state": "West Bengal",
        "city": "Shridhar Kati"
      },
      {
        "state": "West Bengal",
        "city": "Shutia"
      },
      {
        "state": "West Bengal",
        "city": "Simulpur"
      },
      {
        "state": "West Bengal",
        "city": "Sindrani"
      },
      {
        "state": "West Bengal",
        "city": "Sitalia"
      },
      {
        "state": "West Bengal",
        "city": "Soladanga"
      },
      {
        "state": "West Bengal",
        "city": "Suria"
      },
      {
        "state": "West Bengal",
        "city": "Sutia"
      },
      {
        "state": "West Bengal",
        "city": "Swarupnagar"
      },
      {
        "state": "West Bengal",
        "city": "Taki"
      },
      {
        "state": "West Bengal",
        "city": "Taranipur"
      },
      {
        "state": "West Bengal",
        "city": "Titagarh"
      },
      {
        "state": "West Bengal",
        "city": "Tushkhali"
      },
      {
        "state": "West Bengal",
        "city": "Uchildaha"
      },
      {
        "state": "West Bengal",
        "city": "Uludanga"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Baliadanga"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Kalsur"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Kasipur"
      },
      {
        "state": "West Bengal",
        "city": "Adasimla"
      },
      {
        "state": "West Bengal",
        "city": "Akpura"
      },
      {
        "state": "West Bengal",
        "city": "Ambigere"
      },
      {
        "state": "West Bengal",
        "city": "Amla Kora"
      },
      {
        "state": "West Bengal",
        "city": "Angua"
      },
      {
        "state": "West Bengal",
        "city": "Anikola"
      },
      {
        "state": "West Bengal",
        "city": "Asda"
      },
      {
        "state": "West Bengal",
        "city": "Babla"
      },
      {
        "state": "West Bengal",
        "city": "Bachhurkhoyar"
      },
      {
        "state": "West Bengal",
        "city": "Bahurupa"
      },
      {
        "state": "West Bengal",
        "city": "Bakurpada"
      },
      {
        "state": "West Bengal",
        "city": "Balichak"
      },
      {
        "state": "West Bengal",
        "city": "Balitora"
      },
      {
        "state": "West Bengal",
        "city": "Ballichak"
      },
      {
        "state": "West Bengal",
        "city": "Bandhu Chak"
      },
      {
        "state": "West Bengal",
        "city": "Bansh Kuti"
      },
      {
        "state": "West Bengal",
        "city": "Bara Kalanki"
      },
      {
        "state": "West Bengal",
        "city": "Barangi"
      },
      {
        "state": "West Bengal",
        "city": "Begunia"
      },
      {
        "state": "West Bengal",
        "city": "Belda"
      },
      {
        "state": "West Bengal",
        "city": "Belmula"
      },
      {
        "state": "West Bengal",
        "city": "Benadiha"
      },
      {
        "state": "West Bengal",
        "city": "Bhaudi"
      },
      {
        "state": "West Bengal",
        "city": "Binpur"
      },
      {
        "state": "West Bengal",
        "city": "Borah"
      },
      {
        "state": "West Bengal",
        "city": "Borai"
      },
      {
        "state": "West Bengal",
        "city": "Chandabila"
      },
      {
        "state": "West Bengal",
        "city": "Chandrakona"
      },
      {
        "state": "West Bengal",
        "city": "Chandrakona Road"
      },
      {
        "state": "West Bengal",
        "city": "Chaulia"
      },
      {
        "state": "West Bengal",
        "city": "Chhorda"
      },
      {
        "state": "West Bengal",
        "city": "Chhota Bagasthi"
      },
      {
        "state": "West Bengal",
        "city": "Chunpara"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Bayenda"
      },
      {
        "state": "West Bengal",
        "city": "Dangarpara"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Medinipur (West Medinipur)"
      },
      {
        "state": "West Bengal",
        "city": "Dasagram"
      },
      {
        "state": "West Bengal",
        "city": "Debra"
      },
      {
        "state": "West Bengal",
        "city": "Dhalbelun"
      },
      {
        "state": "West Bengal",
        "city": "Dol Gram"
      },
      {
        "state": "West Bengal",
        "city": "Duria"
      },
      {
        "state": "West Bengal",
        "city": "Ekarukhi"
      },
      {
        "state": "West Bengal",
        "city": "Gaganeswar"
      },
      {
        "state": "West Bengal",
        "city": "Gaita"
      },
      {
        "state": "West Bengal",
        "city": "Gazipur Kismat"
      },
      {
        "state": "West Bengal",
        "city": "Goaltore"
      },
      {
        "state": "West Bengal",
        "city": "Gomunda"
      },
      {
        "state": "West Bengal",
        "city": "Gopiballabpur"
      },
      {
        "state": "West Bengal",
        "city": "Handla"
      },
      {
        "state": "West Bengal",
        "city": "Hoomgarh"
      },
      {
        "state": "West Bengal",
        "city": "Jahalda"
      },
      {
        "state": "West Bengal",
        "city": "Jamboni"
      },
      {
        "state": "West Bengal",
        "city": "Jhanjia Nankar"
      },
      {
        "state": "West Bengal",
        "city": "Jiakhali"
      },
      {
        "state": "West Bengal",
        "city": "Kakrajit"
      },
      {
        "state": "West Bengal",
        "city": "Kalma Pukhuria"
      },
      {
        "state": "West Bengal",
        "city": "Kanthalia"
      },
      {
        "state": "West Bengal",
        "city": "Kasba Narayangarh"
      },
      {
        "state": "West Bengal",
        "city": "Keshiary"
      },
      {
        "state": "West Bengal",
        "city": "Keshpur"
      },
      {
        "state": "West Bengal",
        "city": "Keshrambha"
      },
      {
        "state": "West Bengal",
        "city": "Khakurda"
      },
      {
        "state": "West Bengal",
        "city": "Khalina"
      },
      {
        "state": "West Bengal",
        "city": "Kharikamathani"
      },
      {
        "state": "West Bengal",
        "city": "Khirpai"
      },
      {
        "state": "West Bengal",
        "city": "Konarpur"
      },
      {
        "state": "West Bengal",
        "city": "Kontai Jinagerya"
      },
      {
        "state": "West Bengal",
        "city": "Kotpada"
      },
      {
        "state": "West Bengal",
        "city": "Kuili Suta"
      },
      {
        "state": "West Bengal",
        "city": "Kuldiha"
      },
      {
        "state": "West Bengal",
        "city": "Kumarda"
      },
      {
        "state": "West Bengal",
        "city": "Kush Bashan"
      },
      {
        "state": "West Bengal",
        "city": "Lalat"
      },
      {
        "state": "West Bengal",
        "city": "Lenga Mara"
      },
      {
        "state": "West Bengal",
        "city": "Loha Baria"
      },
      {
        "state": "West Bengal",
        "city": "Lohabaran Chak"
      },
      {
        "state": "West Bengal",
        "city": "Makdumnagar"
      },
      {
        "state": "West Bengal",
        "city": "Maljamuna"
      },
      {
        "state": "West Bengal",
        "city": "Malpara"
      },
      {
        "state": "West Bengal",
        "city": "Murabani"
      },
      {
        "state": "West Bengal",
        "city": "Nachhipur"
      },
      {
        "state": "West Bengal",
        "city": "Nagri Pada"
      },
      {
        "state": "West Bengal",
        "city": "Nayagram"
      },
      {
        "state": "West Bengal",
        "city": "Nekrasole"
      },
      {
        "state": "West Bengal",
        "city": "Nikursini"
      },
      {
        "state": "West Bengal",
        "city": "Nilda"
      },
      {
        "state": "West Bengal",
        "city": "Palsandapur"
      },
      {
        "state": "West Bengal",
        "city": "Phandar"
      },
      {
        "state": "West Bengal",
        "city": "Pingla"
      },
      {
        "state": "West Bengal",
        "city": "Purbba Radhapur"
      },
      {
        "state": "West Bengal",
        "city": "Purunda"
      },
      {
        "state": "West Bengal",
        "city": "Ramkrishnapur"
      },
      {
        "state": "West Bengal",
        "city": "Rautarapur"
      },
      {
        "state": "West Bengal",
        "city": "Renjura"
      },
      {
        "state": "West Bengal",
        "city": "Sabang"
      },
      {
        "state": "West Bengal",
        "city": "Salboni"
      },
      {
        "state": "West Bengal",
        "city": "Saluka"
      },
      {
        "state": "West Bengal",
        "city": "Santra Pur"
      },
      {
        "state": "West Bengal",
        "city": "Sartla"
      },
      {
        "state": "West Bengal",
        "city": "Sautia"
      },
      {
        "state": "West Bengal",
        "city": "Shaljora"
      },
      {
        "state": "West Bengal",
        "city": "Siyalsai"
      },
      {
        "state": "West Bengal",
        "city": "Sonakhali"
      },
      {
        "state": "West Bengal",
        "city": "Sri Ram Pur"
      },
      {
        "state": "West Bengal",
        "city": "Takla"
      },
      {
        "state": "West Bengal",
        "city": "Tanuya"
      },
      {
        "state": "West Bengal",
        "city": "Tutranga"
      },
      {
        "state": "West Bengal",
        "city": "Uchudiha"
      },
      {
        "state": "West Bengal",
        "city": "West Medinipur"
      },
      {
        "state": "West Bengal",
        "city": "Ajodhyapur"
      },
      {
        "state": "West Bengal",
        "city": "Alanggiri"
      },
      {
        "state": "West Bengal",
        "city": "Alankarpur"
      },
      {
        "state": "West Bengal",
        "city": "Amarshi"
      },
      {
        "state": "West Bengal",
        "city": "Amtalia"
      },
      {
        "state": "West Bengal",
        "city": "Analberia"
      },
      {
        "state": "West Bengal",
        "city": "Argara"
      },
      {
        "state": "West Bengal",
        "city": "Atbati"
      },
      {
        "state": "West Bengal",
        "city": "Ayma Barbaria"
      },
      {
        "state": "West Bengal",
        "city": "Badalpur 2Nd Part"
      },
      {
        "state": "West Bengal",
        "city": "Baguran Jalpai"
      },
      {
        "state": "West Bengal",
        "city": "Baikunthapur"
      },
      {
        "state": "West Bengal",
        "city": "Balighai"
      },
      {
        "state": "West Bengal",
        "city": "Balisai"
      },
      {
        "state": "West Bengal",
        "city": "Balyagobindapur"
      },
      {
        "state": "West Bengal",
        "city": "Bamunia"
      },
      {
        "state": "West Bengal",
        "city": "Bara Bari"
      },
      {
        "state": "West Bengal",
        "city": "Bara Garania"
      },
      {
        "state": "West Bengal",
        "city": "Barabantalia"
      },
      {
        "state": "West Bengal",
        "city": "Barabatia"
      },
      {
        "state": "West Bengal",
        "city": "Baratala"
      },
      {
        "state": "West Bengal",
        "city": "Baraudaypur"
      },
      {
        "state": "West Bengal",
        "city": "Bartana"
      },
      {
        "state": "West Bengal",
        "city": "Basantia"
      },
      {
        "state": "West Bengal",
        "city": "Basudebpur"
      },
      {
        "state": "West Bengal",
        "city": "Bathuari"
      },
      {
        "state": "West Bengal",
        "city": "Benachakri"
      },
      {
        "state": "West Bengal",
        "city": "Benichak"
      },
      {
        "state": "West Bengal",
        "city": "Bhaja Chauli"
      },
      {
        "state": "West Bengal",
        "city": "Bhatda"
      },
      {
        "state": "West Bengal",
        "city": "Bhupatinagar"
      },
      {
        "state": "West Bengal",
        "city": "Bibhishananpur"
      },
      {
        "state": "West Bengal",
        "city": "Biramput"
      },
      {
        "state": "West Bengal",
        "city": "Purba Medinipur (East Medinipur)"
      },
      {
        "state": "West Bengal",
        "city": "Biswanathpur"
      },
      {
        "state": "West Bengal",
        "city": "Bodhra"
      },
      {
        "state": "West Bengal",
        "city": "Bolkushda"
      },
      {
        "state": "West Bengal",
        "city": "Brajaballavpur"
      },
      {
        "state": "West Bengal",
        "city": "Chak Bhabani"
      },
      {
        "state": "West Bengal",
        "city": "Chata Padmapur"
      },
      {
        "state": "West Bengal",
        "city": "Chaulkhola"
      },
      {
        "state": "West Bengal",
        "city": "Chhota Chaulkhola"
      },
      {
        "state": "West Bengal",
        "city": "Chingur Dania"
      },
      {
        "state": "West Bengal",
        "city": "Chirulia"
      },
      {
        "state": "West Bengal",
        "city": "Chorpalia"
      },
      {
        "state": "West Bengal",
        "city": "Contai"
      },
      {
        "state": "West Bengal",
        "city": "Daisai"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Darua"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Kalamdan"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Moyna"
      },
      {
        "state": "West Bengal",
        "city": "Darua"
      },
      {
        "state": "West Bengal",
        "city": "Dekhali"
      },
      {
        "state": "West Bengal",
        "city": "Deulpota"
      },
      {
        "state": "West Bengal",
        "city": "Dholmari"
      },
      {
        "state": "West Bengal",
        "city": "Digha Danri"
      },
      {
        "state": "West Bengal",
        "city": "Dihierench"
      },
      {
        "state": "West Bengal",
        "city": "Dobandi"
      },
      {
        "state": "West Bengal",
        "city": "Dubda"
      },
      {
        "state": "West Bengal",
        "city": "Durmut"
      },
      {
        "state": "West Bengal",
        "city": "Egra"
      },
      {
        "state": "West Bengal",
        "city": "Erenda"
      },
      {
        "state": "West Bengal",
        "city": "Gangadhar Chak"
      },
      {
        "state": "West Bengal",
        "city": "Ghoraghata"
      },
      {
        "state": "West Bengal",
        "city": "Gograskottaluk"
      },
      {
        "state": "West Bengal",
        "city": "Gopal Chak"
      },
      {
        "state": "West Bengal",
        "city": "Gopi Chak"
      },
      {
        "state": "West Bengal",
        "city": "Haldia"
      },
      {
        "state": "West Bengal",
        "city": "Haldia Municipality"
      },
      {
        "state": "West Bengal",
        "city": "Haludbari"
      },
      {
        "state": "West Bengal",
        "city": "Harranamaldina"
      },
      {
        "state": "West Bengal",
        "city": "Hatbaincha"
      },
      {
        "state": "West Bengal",
        "city": "Hatiary"
      },
      {
        "state": "West Bengal",
        "city": "Itaberia"
      },
      {
        "state": "West Bengal",
        "city": "Jerthan"
      },
      {
        "state": "West Bengal",
        "city": "Juki"
      },
      {
        "state": "West Bengal",
        "city": "Kamarda"
      },
      {
        "state": "West Bengal",
        "city": "Kanaidighi"
      },
      {
        "state": "West Bengal",
        "city": "Kanda Gram"
      },
      {
        "state": "West Bengal",
        "city": "Kartik Khali"
      },
      {
        "state": "West Bengal",
        "city": "Kasaria"
      },
      {
        "state": "West Bengal",
        "city": "Kasbagola"
      },
      {
        "state": "West Bengal",
        "city": "Katkadebi Chak"
      },
      {
        "state": "West Bengal",
        "city": "Katranka"
      },
      {
        "state": "West Bengal",
        "city": "Kayemgeria"
      },
      {
        "state": "West Bengal",
        "city": "Kekai"
      },
      {
        "state": "West Bengal",
        "city": "Khagda"
      },
      {
        "state": "West Bengal",
        "city": "Khalisabhanga"
      },
      {
        "state": "West Bengal",
        "city": "Khejurda"
      },
      {
        "state": "West Bengal",
        "city": "Khodambari"
      },
      {
        "state": "West Bengal",
        "city": "Khurutia"
      },
      {
        "state": "West Bengal",
        "city": "Kismat Bajkul"
      },
      {
        "state": "West Bengal",
        "city": "Kshirai"
      },
      {
        "state": "West Bengal",
        "city": "Kulanjara"
      },
      {
        "state": "West Bengal",
        "city": "Kumirda"
      },
      {
        "state": "West Bengal",
        "city": "Kunjapur"
      },
      {
        "state": "West Bengal",
        "city": "Lakshan Chak"
      },
      {
        "state": "West Bengal",
        "city": "Lalua"
      },
      {
        "state": "West Bengal",
        "city": "Madanmohanpur"
      },
      {
        "state": "West Bengal",
        "city": "Madhabpur"
      },
      {
        "state": "West Bengal",
        "city": "Mahespur"
      },
      {
        "state": "West Bengal",
        "city": "Mahisadal"
      },
      {
        "state": "West Bengal",
        "city": "Mahishadal"
      },
      {
        "state": "West Bengal",
        "city": "Maisali"
      },
      {
        "state": "West Bengal",
        "city": "Malbari"
      },
      {
        "state": "West Bengal",
        "city": "Mallikpur"
      },
      {
        "state": "West Bengal",
        "city": "Manglamaro"
      },
      {
        "state": "West Bengal",
        "city": "Manikabasan"
      },
      {
        "state": "West Bengal",
        "city": "Manikjore"
      },
      {
        "state": "West Bengal",
        "city": "Marishda"
      },
      {
        "state": "West Bengal",
        "city": "Mirgoda"
      },
      {
        "state": "West Bengal",
        "city": "Mohati"
      },
      {
        "state": "West Bengal",
        "city": "Mugberia"
      },
      {
        "state": "West Bengal",
        "city": "Nachinda"
      },
      {
        "state": "West Bengal",
        "city": "Nandakumar"
      },
      {
        "state": "West Bengal",
        "city": "Nandigram"
      },
      {
        "state": "West Bengal",
        "city": "Narandia"
      },
      {
        "state": "West Bengal",
        "city": "Narayanchak"
      },
      {
        "state": "West Bengal",
        "city": "Naskarpur"
      },
      {
        "state": "West Bengal",
        "city": "Nata Talya"
      },
      {
        "state": "West Bengal",
        "city": "Nayaput"
      },
      {
        "state": "West Bengal",
        "city": "Nodakhali"
      },
      {
        "state": "West Bengal",
        "city": "Pailachhanpur"
      },
      {
        "state": "West Bengal",
        "city": "Paldhui"
      },
      {
        "state": "West Bengal",
        "city": "Palpara"
      },
      {
        "state": "West Bengal",
        "city": "Palta Beria"
      },
      {
        "state": "West Bengal",
        "city": "Panchet"
      },
      {
        "state": "West Bengal",
        "city": "Panchrol"
      },
      {
        "state": "West Bengal",
        "city": "Panchuria"
      },
      {
        "state": "West Bengal",
        "city": "Pankhai"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Manikpur"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Sarpai"
      },
      {
        "state": "West Bengal",
        "city": "Patashpur"
      },
      {
        "state": "West Bengal",
        "city": "Pratap Dighi"
      },
      {
        "state": "West Bengal",
        "city": "Ram Chak"
      },
      {
        "state": "West Bengal",
        "city": "Ranisai"
      },
      {
        "state": "West Bengal",
        "city": "Saridaspur"
      },
      {
        "state": "West Bengal",
        "city": "Sarishaberya"
      },
      {
        "state": "West Bengal",
        "city": "Sarpai"
      },
      {
        "state": "West Bengal",
        "city": "Sat Khanda Saheb Nagar"
      },
      {
        "state": "West Bengal",
        "city": "Satbahini"
      },
      {
        "state": "West Bengal",
        "city": "Satikeshwar"
      },
      {
        "state": "West Bengal",
        "city": "Satilapur"
      },
      {
        "state": "West Bengal",
        "city": "Satmile"
      },
      {
        "state": "West Bengal",
        "city": "Selmabad"
      },
      {
        "state": "West Bengal",
        "city": "Serkhan Chak"
      },
      {
        "state": "West Bengal",
        "city": "Shalikota"
      },
      {
        "state": "West Bengal",
        "city": "Shimulia Bar"
      },
      {
        "state": "West Bengal",
        "city": "Shipur"
      },
      {
        "state": "West Bengal",
        "city": "Shushunia"
      },
      {
        "state": "West Bengal",
        "city": "Suarasatya"
      },
      {
        "state": "West Bengal",
        "city": "Subdi"
      },
      {
        "state": "West Bengal",
        "city": "Sukha Khola"
      },
      {
        "state": "West Bengal",
        "city": "Sutahata"
      },
      {
        "state": "West Bengal",
        "city": "Tagaria Gopalpur"
      },
      {
        "state": "West Bengal",
        "city": "Taldaratan Chak"
      },
      {
        "state": "West Bengal",
        "city": "Tamluk"
      },
      {
        "state": "West Bengal",
        "city": "Telami"
      },
      {
        "state": "West Bengal",
        "city": "Tengunia"
      },
      {
        "state": "West Bengal",
        "city": "Thakurnagar"
      },
      {
        "state": "West Bengal",
        "city": "Tikashi"
      },
      {
        "state": "West Bengal",
        "city": "Tilkhoja"
      },
      {
        "state": "West Bengal",
        "city": "Tota Nala"
      },
      {
        "state": "West Bengal",
        "city": "Urdhabpur"
      },
      {
        "state": "West Bengal",
        "city": "Ururi"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Amtalia"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Chara Sankarara"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Gobindapur"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Kalamdan"
      },
      {
        "state": "West Bengal",
        "city": "Uttar-tajpur"
      },
      {
        "state": "West Bengal",
        "city": "Adra"
      },
      {
        "state": "West Bengal",
        "city": "Arsha"
      },
      {
        "state": "West Bengal",
        "city": "Baghmundi"
      },
      {
        "state": "West Bengal",
        "city": "Balakdi"
      },
      {
        "state": "West Bengal",
        "city": "Balarampur"
      },
      {
        "state": "West Bengal",
        "city": "Barabazar"
      },
      {
        "state": "West Bengal",
        "city": "Barabhum"
      },
      {
        "state": "West Bengal",
        "city": "Bargram"
      },
      {
        "state": "West Bengal",
        "city": "Bongabari"
      },
      {
        "state": "West Bengal",
        "city": "Chapari"
      },
      {
        "state": "West Bengal",
        "city": "Chelyama"
      },
      {
        "state": "West Bengal",
        "city": "Chharrah"
      },
      {
        "state": "West Bengal",
        "city": "Gobag"
      },
      {
        "state": "West Bengal",
        "city": "Purulia"
      },
      {
        "state": "West Bengal",
        "city": "Hura"
      },
      {
        "state": "West Bengal",
        "city": "Hutmura"
      },
      {
        "state": "West Bengal",
        "city": "Jhalida"
      },
      {
        "state": "West Bengal",
        "city": "Jiudaru"
      },
      {
        "state": "West Bengal",
        "city": "Kalapathar"
      },
      {
        "state": "West Bengal",
        "city": "Kenda"
      },
      {
        "state": "West Bengal",
        "city": "Kotshila"
      },
      {
        "state": "West Bengal",
        "city": "Manbazar"
      },
      {
        "state": "West Bengal",
        "city": "Manguria"
      },
      {
        "state": "West Bengal",
        "city": "Manihara"
      },
      {
        "state": "West Bengal",
        "city": "Murardih"
      },
      {
        "state": "West Bengal",
        "city": "Murulahar"
      },
      {
        "state": "West Bengal",
        "city": "Neturia"
      },
      {
        "state": "West Bengal",
        "city": "Puncha"
      },
      {
        "state": "West Bengal",
        "city": "Pundag"
      },
      {
        "state": "West Bengal",
        "city": "Ramkanali"
      },
      {
        "state": "West Bengal",
        "city": "Santaldih"
      },
      {
        "state": "West Bengal",
        "city": "Santuri"
      },
      {
        "state": "West Bengal",
        "city": "Saoradi"
      },
      {
        "state": "West Bengal",
        "city": "Sirkabad"
      },
      {
        "state": "West Bengal",
        "city": "Tulin"
      },
      {
        "state": "West Bengal",
        "city": "Baribhanga Abad"
      },
      {
        "state": "West Bengal",
        "city": "Basanti"
      },
      {
        "state": "West Bengal",
        "city": "South 24 Parganas"
      },
      {
        "state": "West Bengal",
        "city": "Canning"
      },
      {
        "state": "West Bengal",
        "city": "Champahati"
      },
      {
        "state": "West Bengal",
        "city": "Chanddandaha"
      },
      {
        "state": "West Bengal",
        "city": "Dongaria"
      },
      {
        "state": "West Bengal",
        "city": "Falta"
      },
      {
        "state": "West Bengal",
        "city": "Golgram"
      },
      {
        "state": "West Bengal",
        "city": "Gosaba"
      },
      {
        "state": "West Bengal",
        "city": "Jalabaria"
      },
      {
        "state": "West Bengal",
        "city": "Jaynagar"
      },
      {
        "state": "West Bengal",
        "city": "Kakdwip"
      },
      {
        "state": "West Bengal",
        "city": "Kirtankhola"
      },
      {
        "state": "West Bengal",
        "city": "Kulpi"
      },
      {
        "state": "West Bengal",
        "city": "Magrahat - II"
      },
      {
        "state": "West Bengal",
        "city": "Mathurapur"
      },
      {
        "state": "West Bengal",
        "city": "Namkhana"
      },
      {
        "state": "West Bengal",
        "city": "Nimpith"
      },
      {
        "state": "West Bengal",
        "city": "Nischintapur"
      },
      {
        "state": "West Bengal",
        "city": "Pathar Pratima"
      },
      {
        "state": "West Bengal",
        "city": "Raidighi"
      },
      {
        "state": "West Bengal",
        "city": "Ramnathpur K"
      },
      {
        "state": "West Bengal",
        "city": "Sahebpur"
      },
      {
        "state": "West Bengal",
        "city": "Sarisha"
      },
      {
        "state": "West Bengal",
        "city": "Sonarpur"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Mokamberia"
      },
      {
        "state": "West Bengal",
        "city": "Bhagabati"
      },
      {
        "state": "West Bengal",
        "city": "Goalpokhar"
      },
      {
        "state": "West Bengal",
        "city": "Hemtabad"
      },
      {
        "state": "West Bengal",
        "city": "Kaliyaganj"
      },
      {
        "state": "West Bengal",
        "city": "Karandighi"
      },
      {
        "state": "West Bengal",
        "city": "Lalkunri"
      },
      {
        "state": "West Bengal",
        "city": "Raiganj"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Port Blair"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Digipur"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Roing"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Ziro"
      },
      {
        "state": "Gujarat",
        "city": "Nijhar"
      },
      {
        "state": "Maharashtra",
        "city": "Mumbai City"
      },
      {
        "state": "Manipur",
        "city": "Bishnupur"
      },
      {
        "state": "Punjab",
        "city": "Abohar"
      },
      {
        "state": "Gujarat",
        "city": "Ahwa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Allagadda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Alluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Amarapuram"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Amla"
      },
      {
        "state": "Gujarat",
        "city": "Amod"
      },
      {
        "state": "Gujarat",
        "city": "Ankleshwar"
      },
      {
        "state": "Bihar",
        "city": "Arrah"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ashta"
      },
      {
        "state": "Bihar",
        "city": "Asthawan"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atmakur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atmakur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atmakur"
      },
      {
        "state": "Odisha",
        "city": "Badnuagaon"
      },
      {
        "state": "Bihar",
        "city": "Bakhtiyarpur"
      },
      {
        "state": "Gujarat",
        "city": "Bakod"
      },
      {
        "state": "Assam",
        "city": "Bala Bhitha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Baldevgarh"
      },
      {
        "state": "Assam",
        "city": "Balijana"
      },
      {
        "state": "Odisha",
        "city": "Balimi"
      },
      {
        "state": "Odisha",
        "city": "Balipatna"
      },
      {
        "state": "Gujarat",
        "city": "Banaskantha"
      },
      {
        "state": "Bihar",
        "city": "Baniapur"
      },
      {
        "state": "Odisha",
        "city": "Banki"
      },
      {
        "state": "Chhattisgarh",
        "city": "Baradwar"
      },
      {
        "state": "Bihar",
        "city": "Barahat"
      },
      {
        "state": "Bihar",
        "city": "Barahra"
      },
      {
        "state": "Bihar",
        "city": "Barbigha"
      },
      {
        "state": "Assam",
        "city": "Barpeta Road"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bathalapalle"
      },
      {
        "state": "Bihar",
        "city": "Bausi"
      },
      {
        "state": "Bihar",
        "city": "Belchi"
      },
      {
        "state": "Bihar",
        "city": "Belhar"
      },
      {
        "state": "Bihar",
        "city": "Belsand"
      },
      {
        "state": "Bihar",
        "city": "Benipur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Beohari"
      },
      {
        "state": "Bihar",
        "city": "Bhagwanpur Hat"
      },
      {
        "state": "Gujarat",
        "city": "Bhanvad"
      },
      {
        "state": "Odisha",
        "city": "Bheden"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhopalpatnam"
      },
      {
        "state": "Odisha",
        "city": "Bhubaneswar"
      },
      {
        "state": "Assam",
        "city": "Bhuragaon"
      },
      {
        "state": "Bihar",
        "city": "Bihar Sharif"
      },
      {
        "state": "Odisha",
        "city": "Bijepur"
      },
      {
        "state": "Assam",
        "city": "Bijni"
      },
      {
        "state": "Assam",
        "city": "Bilasipara"
      },
      {
        "state": "Odisha",
        "city": "Birmaharajpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Birra"
      },
      {
        "state": "Assam",
        "city": "Bogribari"
      },
      {
        "state": "Odisha",
        "city": "Boipariguda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bondapalli"
      },
      {
        "state": "Gujarat",
        "city": "Borsad"
      },
      {
        "state": "Assam",
        "city": "Bosa Gaon"
      },
      {
        "state": "Uttarakhand",
        "city": "Burkot"
      },
      {
        "state": "Assam",
        "city": "Chabua"
      },
      {
        "state": "Chhattisgarh",
        "city": "Champa"
      },
      {
        "state": "Bihar",
        "city": "Chandadih"
      },
      {
        "state": "Odisha",
        "city": "Chandikhol"
      },
      {
        "state": "Bihar",
        "city": "Chausa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chebrolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chebrolu"
      },
      {
        "state": "Bihar",
        "city": "Chenari"
      },
      {
        "state": "Bihar",
        "city": "Chewara"
      },
      {
        "state": "Bihar",
        "city": "Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Chichmhi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chintalapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chirala"
      },
      {
        "state": "Bihar",
        "city": "Chousa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Churhat"
      },
      {
        "state": "Gujarat",
        "city": "Dabhoi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Dabra"
      },
      {
        "state": "Gujarat",
        "city": "Dahegam"
      },
      {
        "state": "Assam",
        "city": "Darrang"
      },
      {
        "state": "Bihar",
        "city": "Daudnagar"
      },
      {
        "state": "Gujarat",
        "city": "Dediapada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Denkada"
      },
      {
        "state": "Odisha",
        "city": "Deogarh"
      },
      {
        "state": "Gujarat",
        "city": "Devgadh Baria"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Devipatnam"
      },
      {
        "state": "Gujarat",
        "city": "Dhandhuka"
      },
      {
        "state": "Gujarat",
        "city": "Dhanpur"
      },
      {
        "state": "Odisha",
        "city": "Dhaurapali"
      },
      {
        "state": "Gujarat",
        "city": "Dhoraji"
      },
      {
        "state": "Gujarat",
        "city": "Dhrol"
      },
      {
        "state": "Assam",
        "city": "Diphu"
      },
      {
        "state": "Assam",
        "city": "Dudhnoi"
      },
      {
        "state": "Uttarakhand",
        "city": "Dunda Range"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Duvvuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Eluru"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gadarwara"
      },
      {
        "state": "Bihar",
        "city": "Gamharia"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gannavaram"
      },
      {
        "state": "Uttarakhand",
        "city": "Garur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gaurella"
      },
      {
        "state": "Odisha",
        "city": "Ghantapada"
      },
      {
        "state": "Assam",
        "city": "Ghilamora"
      },
      {
        "state": "Bihar",
        "city": "Ghoshi"
      },
      {
        "state": "Bihar",
        "city": "Giriak"
      },
      {
        "state": "Assam",
        "city": "Gogamukh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gopadbanas"
      },
      {
        "state": "Assam",
        "city": "Guwahati"
      },
      {
        "state": "Bihar",
        "city": "Halsi"
      },
      {
        "state": "Gujarat",
        "city": "Hansot"
      },
      {
        "state": "Odisha",
        "city": "Harabhanga"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Hayuliang"
      },
      {
        "state": "Gujarat",
        "city": "Hazira"
      },
      {
        "state": "Bihar",
        "city": "Hulasganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Huzur"
      },
      {
        "state": "Odisha",
        "city": "Iswarpur"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Itanagar"
      },
      {
        "state": "Odisha",
        "city": "Jagatsinghpur"
      },
      {
        "state": "Bihar",
        "city": "Jagdishpur"
      },
      {
        "state": "Assam",
        "city": "Jagiroad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jaithari"
      },
      {
        "state": "Odisha",
        "city": "Jajpur Road"
      },
      {
        "state": "Gujarat",
        "city": "Jambusar"
      },
      {
        "state": "Gujarat",
        "city": "Jamjodhpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jaora"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jayant"
      },
      {
        "state": "Gujarat",
        "city": "Jhagadia"
      },
      {
        "state": "Gujarat",
        "city": "Kadi"
      },
      {
        "state": "Assam",
        "city": "Kalabari"
      },
      {
        "state": "Gujarat",
        "city": "Kalavad"
      },
      {
        "state": "Bihar",
        "city": "Kaler"
      },
      {
        "state": "Gujarat",
        "city": "Kalol"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalyandurg"
      },
      {
        "state": "Odisha",
        "city": "Kalyansingpur"
      },
      {
        "state": "Gujarat",
        "city": "Kamrej"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kamta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kandukur"
      },
      {
        "state": "Uttarakhand",
        "city": "Karnaprayag"
      },
      {
        "state": "Bihar",
        "city": "Karpi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kasibugga"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kawardha"
      },
      {
        "state": "Odisha",
        "city": "Khajuripada"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khaknar Khurd"
      },
      {
        "state": "Odisha",
        "city": "Khariar"
      },
      {
        "state": "Assam",
        "city": "Kharupetia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khirkiya"
      },
      {
        "state": "Odisha",
        "city": "Kodinga"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Koduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kondapuram"
      },
      {
        "state": "Chhattisgarh",
        "city": "Konta"
      },
      {
        "state": "Odisha",
        "city": "Kotapad"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kothavalasa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kotma"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kotturu"
      },
      {
        "state": "Odisha",
        "city": "Krushnaprasad"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kunkuri"
      },
      {
        "state": "Bihar",
        "city": "Kurhani"
      },
      {
        "state": "Bihar",
        "city": "Kursela"
      },
      {
        "state": "Bihar",
        "city": "Kurtha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kurwai"
      },
      {
        "state": "Gujarat",
        "city": "Kutch"
      },
      {
        "state": "Gujarat",
        "city": "Kutiyana"
      },
      {
        "state": "Odisha",
        "city": "Laikera"
      },
      {
        "state": "Bihar",
        "city": "Laukahi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lavkush Nagar"
      },
      {
        "state": "Gujarat",
        "city": "Lunawada"
      },
      {
        "state": "Gujarat",
        "city": "Mahuva"
      },
      {
        "state": "Bihar",
        "city": "Makhdumpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Malhargarh"
      },
      {
        "state": "Gujarat",
        "city": "Malia"
      },
      {
        "state": "Chhattisgarh",
        "city": "Malkharoda"
      },
      {
        "state": "Gujarat",
        "city": "Malpur"
      },
      {
        "state": "Gujarat",
        "city": "Manavadar"
      },
      {
        "state": "Gujarat",
        "city": "Mandvi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mangalagiri"
      },
      {
        "state": "Gujarat",
        "city": "Mangrol"
      },
      {
        "state": "Bihar",
        "city": "Manjhi"
      },
      {
        "state": "Odisha",
        "city": "Marshaghai"
      },
      {
        "state": "Chhattisgarh",
        "city": "Marwahi"
      },
      {
        "state": "Gujarat",
        "city": "Matar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Maunganj"
      },
      {
        "state": "Gujarat",
        "city": "Modasa"
      },
      {
        "state": "Bihar",
        "city": "Mokameh Khas"
      },
      {
        "state": "Bihar",
        "city": "Motihari"
      },
      {
        "state": "Odisha",
        "city": "Motunga"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Muddanur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Multai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mungaoli"
      },
      {
        "state": "Bihar",
        "city": "Murliganj"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mylavaram"
      },
      {
        "state": "Assam",
        "city": "Namrup"
      },
      {
        "state": "Odisha",
        "city": "Nandapur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandigama"
      },
      {
        "state": "Gujarat",
        "city": "Nandod"
      },
      {
        "state": "Gujarat",
        "city": "Narmada"
      },
      {
        "state": "Bihar",
        "city": "Nauhatta"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nepanagar"
      },
      {
        "state": "Odisha",
        "city": "Niali"
      },
      {
        "state": "Bihar",
        "city": "Nirmali"
      },
      {
        "state": "Odisha",
        "city": "Nisankapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Niwas"
      },
      {
        "state": "Assam",
        "city": "North Lakhimpur"
      },
      {
        "state": "Odisha",
        "city": "Nuagaon"
      },
      {
        "state": "Gujarat",
        "city": "Olpad"
      },
      {
        "state": "Bihar",
        "city": "Pachrukhi"
      },
      {
        "state": "Odisha",
        "city": "Padmapur"
      },
      {
        "state": "Bihar",
        "city": "Paliganj"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pamarru"
      },
      {
        "state": "Odisha",
        "city": "Paralakhemundi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Parasia Baitul"
      },
      {
        "state": "Bihar",
        "city": "Parbatta"
      },
      {
        "state": "Bihar",
        "city": "Parsauni"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Pasighat"
      },
      {
        "state": "Gujarat",
        "city": "Patdi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Patharia"
      },
      {
        "state": "Odisha",
        "city": "Patkura"
      },
      {
        "state": "Gujarat",
        "city": "Petlad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pichhore"
      },
      {
        "state": "Odisha",
        "city": "Pipili"
      },
      {
        "state": "Bihar",
        "city": "Piprahi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Polavaram"
      },
      {
        "state": "Goa",
        "city": "Ponda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ponduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Prathipadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Prathipadu"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Prithvipur"
      },
      {
        "state": "Bihar",
        "city": "Pupri"
      },
      {
        "state": "Bihar",
        "city": "Rafiganj"
      },
      {
        "state": "Bihar",
        "city": "Raghopur"
      },
      {
        "state": "Bihar",
        "city": "Rajoun"
      },
      {
        "state": "Gujarat",
        "city": "Rajpipla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajupalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajupalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramachandrapuram"
      },
      {
        "state": "Uttarakhand",
        "city": "Ramnagar"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ranastalam"
      },
      {
        "state": "Gujarat",
        "city": "Ranpur"
      },
      {
        "state": "Bihar",
        "city": "Ratni"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Razam"
      },
      {
        "state": "Odisha",
        "city": "Reamal"
      },
      {
        "state": "Uttarakhand",
        "city": "Rishikesh"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Roing"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rompicherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rompicherla"
      },
      {
        "state": "Bihar",
        "city": "Rupauli"
      },
      {
        "state": "Gujarat",
        "city": "Sagbara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sailana"
      },
      {
        "state": "Odisha",
        "city": "Sakhigopal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sangam Jagarlamudi"
      },
      {
        "state": "Bihar",
        "city": "Sangrampur"
      },
      {
        "state": "Gujarat",
        "city": "Santrampur"
      },
      {
        "state": "Bihar",
        "city": "Saur Bazar"
      },
      {
        "state": "Gujarat",
        "city": "Savar Kundla"
      },
      {
        "state": "Gujarat",
        "city": "Sayla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Seethanagaram"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Segaon"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Seppa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Shahpura"
      },
      {
        "state": "Bihar",
        "city": "Shambhuganj"
      },
      {
        "state": "Bihar",
        "city": "Shekupur Sarai"
      },
      {
        "state": "Bihar",
        "city": "Sherghati"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sigadam"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sihora"
      },
      {
        "state": "Assam",
        "city": "Silchar"
      },
      {
        "state": "Odisha",
        "city": "Similia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sohagpur"
      },
      {
        "state": "Gujarat",
        "city": "Sojitra"
      },
      {
        "state": "Uttarakhand",
        "city": "Someshwar"
      },
      {
        "state": "Bihar",
        "city": "Sonbarsa"
      },
      {
        "state": "Gujarat",
        "city": "Songadh"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Srungavarapu kota"
      },
      {
        "state": "Bihar",
        "city": "Sultanganj"
      },
      {
        "state": "Odisha",
        "city": "Sundargarh"
      },
      {
        "state": "Bihar",
        "city": "Surajgarha"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadepalli"
      },
      {
        "state": "Gujarat",
        "city": "Talala"
      },
      {
        "state": "Odisha",
        "city": "Talcher"
      },
      {
        "state": "Uttarakhand",
        "city": "Tanakpur"
      },
      {
        "state": "Odisha",
        "city": "Tangi"
      },
      {
        "state": "Gujarat",
        "city": "Tapi"
      },
      {
        "state": "Gujarat",
        "city": "Tarapur"
      },
      {
        "state": "Odisha",
        "city": "Tarbha"
      },
      {
        "state": "Bihar",
        "city": "Tekari"
      },
      {
        "state": "Assam",
        "city": "Tengakhat"
      },
      {
        "state": "Gujarat",
        "city": "Thasra"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Therlam"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Thikri"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Timarni"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tirupati"
      },
      {
        "state": "Goa",
        "city": "Tiswadi"
      },
      {
        "state": "Gujarat",
        "city": "Uchchhal"
      },
      {
        "state": "Gujarat",
        "city": "Umreth"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Unguturu"
      },
      {
        "state": "Gujarat",
        "city": "Unjha"
      },
      {
        "state": "Gujarat",
        "city": "Upleta"
      },
      {
        "state": "Gujarat",
        "city": "Valia"
      },
      {
        "state": "Gujarat",
        "city": "Valod"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vangara"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vangara"
      },
      {
        "state": "Gujarat",
        "city": "Vansda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vepada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vijayawada"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Vijayraghavgarh"
      },
      {
        "state": "Gujarat",
        "city": "Virpur"
      },
      {
        "state": "Gujarat",
        "city": "Visavadar"
      },
      {
        "state": "Gujarat",
        "city": "Vyara"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yellamanchili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yellanur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Akbarpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amethi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Aonla"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bansi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Baraut"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bikapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Campirganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chail"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chakia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chandausi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chauri Chaura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Colonelganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Domariyaganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Filkhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ghatampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ghosi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Greater Noida"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gyanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Handia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Harraiya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ikauna"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jasrana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jewar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Karchana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kasia Bazaar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khalilabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khurja"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Konch"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lalganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Madanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Madhuban"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Marihan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mehnagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Menhdawal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Milkipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mohammadabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Moor Ghat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Moth"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nawabganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nichlaul"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nigohi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Noida"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Patti"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Payagpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Phulpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pindra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Purwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rudauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sadabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sakaldiha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Salempur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sambhal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shahabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shikarpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shohratgarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sirathu"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tulsipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Utraula"
      },
      {
        "state": "Telangana",
        "city": "Achampet"
      },
      {
        "state": "Kerala",
        "city": "Adoor"
      },
      {
        "state": "Tamil Nadu",
        "city": "Alangudi"
      },
      {
        "state": "Jharkhand",
        "city": "Amwar"
      },
      {
        "state": "Tamil Nadu",
        "city": "Arani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Aravakurichi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Aruppukkottai"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Aut"
      },
      {
        "state": "Jharkhand",
        "city": "Bachra"
      },
      {
        "state": "Karnataka",
        "city": "Bagepalli"
      },
      {
        "state": "Haryana",
        "city": "Bahadurgarh"
      },
      {
        "state": "Jharkhand",
        "city": "Bakaspura"
      },
      {
        "state": "Telangana",
        "city": "Balanagar"
      },
      {
        "state": "Meghalaya",
        "city": "Balat"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bali Chowki"
      },
      {
        "state": "Jharkhand",
        "city": "Balumath"
      },
      {
        "state": "Rajasthan",
        "city": "Bamanwas"
      },
      {
        "state": "Jharkhand",
        "city": "Bandgaon"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bangana"
      },
      {
        "state": "Haryana",
        "city": "Banipur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Banjar"
      },
      {
        "state": "Rajasthan",
        "city": "Banwala"
      },
      {
        "state": "Jharkhand",
        "city": "Barkhatha"
      },
      {
        "state": "Rajasthan",
        "city": "Bassi"
      },
      {
        "state": "Rajasthan",
        "city": "Beawar"
      },
      {
        "state": "Karnataka",
        "city": "Belthangady"
      },
      {
        "state": "Haryana",
        "city": "Bewal"
      },
      {
        "state": "Jharkhand",
        "city": "Bhandra"
      },
      {
        "state": "Haryana",
        "city": "Bhojawas"
      },
      {
        "state": "Rajasthan",
        "city": "Bilara"
      },
      {
        "state": "Jharkhand",
        "city": "Bisrampur"
      },
      {
        "state": "Jharkhand",
        "city": "Bokaro Steel City"
      },
      {
        "state": "Telangana",
        "city": "Bommakal"
      },
      {
        "state": "Jharkhand",
        "city": "Borrio"
      },
      {
        "state": "Jharkhand",
        "city": "Chaibasa"
      },
      {
        "state": "Jharkhand",
        "city": "Chainpur"
      },
      {
        "state": "Jharkhand",
        "city": "Chakradharpur"
      },
      {
        "state": "Karnataka",
        "city": "Chandrapura -Chatrakane"
      },
      {
        "state": "Telangana",
        "city": "Chandurthi"
      },
      {
        "state": "Karnataka",
        "city": "Channagiri"
      },
      {
        "state": "Jharkhand",
        "city": "Chas"
      },
      {
        "state": "Tamil Nadu",
        "city": "Cheyyar"
      },
      {
        "state": "Karnataka",
        "city": "Chikkaballapura"
      },
      {
        "state": "Karnataka",
        "city": "Chintamani"
      },
      {
        "state": "Jharkhand",
        "city": "Chouparan"
      },
      {
        "state": "Jharkhand",
        "city": "Churchu"
      },
      {
        "state": "Haryana",
        "city": "Dahina"
      },
      {
        "state": "Jharkhand",
        "city": "Daltonganj"
      },
      {
        "state": "Meghalaya",
        "city": "Dawki"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dehra Gopipur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Devakottai"
      },
      {
        "state": "Karnataka",
        "city": "Devanahalli"
      },
      {
        "state": "Jharkhand",
        "city": "Dhalbhumgarh"
      },
      {
        "state": "Haryana",
        "city": "Dhana"
      },
      {
        "state": "Haryana",
        "city": "Dharan"
      },
      {
        "state": "Haryana",
        "city": "Dongra Ahir"
      },
      {
        "state": "Telangana",
        "city": "Farooqnagar"
      },
      {
        "state": "Karnataka",
        "city": "Gadag-Betigeri"
      },
      {
        "state": "Rajasthan",
        "city": "Gajsinghpur"
      },
      {
        "state": "Rajasthan",
        "city": "Gangapur City"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gangavalli"
      },
      {
        "state": "Karnataka",
        "city": "Gangavathi"
      },
      {
        "state": "Haryana",
        "city": "Ganiar"
      },
      {
        "state": "Karnataka",
        "city": "Gauribidanur"
      },
      {
        "state": "Nagaland",
        "city": "Ghathashi"
      },
      {
        "state": "Telangana",
        "city": "Ghatkesar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Ghumarwin"
      },
      {
        "state": "Rajasthan",
        "city": "Gogunda"
      },
      {
        "state": "Haryana",
        "city": "Gokal Pur"
      },
      {
        "state": "Jharkhand",
        "city": "Gopikandar"
      },
      {
        "state": "Karnataka",
        "city": "Gudibanda"
      },
      {
        "state": "Haryana",
        "city": "Gujarwas"
      },
      {
        "state": "Tamil Nadu",
        "city": "Harur"
      },
      {
        "state": "Jharkhand",
        "city": "Hazaribagh"
      },
      {
        "state": "Karnataka",
        "city": "Honnali"
      },
      {
        "state": "Karnataka",
        "city": "Hoskote"
      },
      {
        "state": "Telangana",
        "city": "Ibrahimpatnam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Idappadi"
      },
      {
        "state": "Manipur",
        "city": "Imphal"
      },
      {
        "state": "Haryana",
        "city": "Jai Singhpur Khera"
      },
      {
        "state": "Karnataka",
        "city": "Jamkhandi"
      },
      {
        "state": "Jharkhand",
        "city": "Jamshedpur"
      },
      {
        "state": "Jharkhand",
        "city": "Jarmundi"
      },
      {
        "state": "Haryana",
        "city": "Jhabuwa"
      },
      {
        "state": "Haryana",
        "city": "Jhagroli"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Junga"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kala Amb"
      },
      {
        "state": "Karnataka",
        "city": "Kalaburagi"
      },
      {
        "state": "Haryana",
        "city": "Kalanwali"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kalpa"
      },
      {
        "state": "Jharkhand",
        "city": "Kamdara"
      },
      {
        "state": "Karnataka",
        "city": "Kanakapura"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kandaghat"
      },
      {
        "state": "Telangana",
        "city": "Kandukur"
      },
      {
        "state": "Kerala",
        "city": "Kanhangad"
      },
      {
        "state": "Kerala",
        "city": "Kanjirappally"
      },
      {
        "state": "Haryana",
        "city": "Kanti"
      },
      {
        "state": "Karnataka",
        "city": "Karkala"
      },
      {
        "state": "Jharkhand",
        "city": "Karma"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kattumannarkoil"
      },
      {
        "state": "Kerala",
        "city": "Kayamkulam"
      },
      {
        "state": "Rajasthan",
        "city": "Kishangarh Bas"
      },
      {
        "state": "Jharkhand",
        "city": "Kolebira"
      },
      {
        "state": "Karnataka",
        "city": "Kollegal"
      },
      {
        "state": "Telangana",
        "city": "Kothur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kotli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kudavasal"
      },
      {
        "state": "Jharkhand",
        "city": "Kundahit"
      },
      {
        "state": "Kerala",
        "city": "Kunnathur"
      },
      {
        "state": "Jharkhand",
        "city": "Kurdeg"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kuttalam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Lalgudi"
      },
      {
        "state": "Rajasthan",
        "city": "LALSOT"
      },
      {
        "state": "Rajasthan",
        "city": "Laxmangarh"
      },
      {
        "state": "Jharkhand",
        "city": "Lesliganj"
      },
      {
        "state": "Jharkhand",
        "city": "Litipara"
      },
      {
        "state": "Karnataka",
        "city": "Madikeri"
      },
      {
        "state": "Karnataka",
        "city": "Magadi"
      },
      {
        "state": "Rajasthan",
        "city": "Malarna Dungar"
      },
      {
        "state": "Tamil Nadu",
        "city": "Manapparai"
      },
      {
        "state": "Jharkhand",
        "city": "Mandar"
      },
      {
        "state": "Haryana",
        "city": "Mandola"
      },
      {
        "state": "Jharkhand",
        "city": "Mandro"
      },
      {
        "state": "Rajasthan",
        "city": "Mangrol"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mannachanallur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mannargudi"
      },
      {
        "state": "Rajasthan",
        "city": "Mavli"
      },
      {
        "state": "Meghalaya",
        "city": "Mawphlang"
      },
      {
        "state": "Jharkhand",
        "city": "Meral"
      },
      {
        "state": "Haryana",
        "city": "Mohindergarh"
      },
      {
        "state": "Jharkhand",
        "city": "Muhammadganj"
      },
      {
        "state": "Karnataka",
        "city": "Mysuru"
      },
      {
        "state": "Rajasthan",
        "city": "Nadoti"
      },
      {
        "state": "Haryana",
        "city": "Nahar"
      },
      {
        "state": "Haryana",
        "city": "Naichana"
      },
      {
        "state": "Haryana",
        "city": "Nangal Mala"
      },
      {
        "state": "Haryana",
        "city": "Nangal Teju"
      },
      {
        "state": "Meghalaya",
        "city": "Nangwalbibra"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nannilam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Natham"
      },
      {
        "state": "Rajasthan",
        "city": "Neem Ka Thana"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nichar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nirmand"
      },
      {
        "state": "Kerala",
        "city": "North Paravur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Orathanadu"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Padhar"
      },
      {
        "state": "Tamil Nadu",
        "city": "Palacode"
      },
      {
        "state": "Jharkhand",
        "city": "Palojori"
      },
      {
        "state": "Tamil Nadu",
        "city": "Panruti"
      },
      {
        "state": "Jharkhand",
        "city": "Pareya Hat"
      },
      {
        "state": "Kerala",
        "city": "Pathanapuram"
      },
      {
        "state": "Haryana",
        "city": "Pathera"
      },
      {
        "state": "Jharkhand",
        "city": "Patratu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pattukkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Peraiyur"
      },
      {
        "state": "Rajasthan",
        "city": "Phalodi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Polur"
      },
      {
        "state": "Haryana",
        "city": "Pranpura"
      },
      {
        "state": "Telangana",
        "city": "Qutubullapur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Rakkar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Rampur Bushahr"
      },
      {
        "state": "Rajasthan",
        "city": "Rani Gaon"
      },
      {
        "state": "Tamil Nadu",
        "city": "Rasipuram"
      },
      {
        "state": "Haryana",
        "city": "Rattia"
      },
      {
        "state": "Rajasthan",
        "city": "Rawatsar"
      },
      {
        "state": "Jharkhand",
        "city": "Rehla"
      },
      {
        "state": "Rajasthan",
        "city": "Sadul Shahar"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sankarankovil"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sankari"
      },
      {
        "state": "Rajasthan",
        "city": "Sapotara"
      },
      {
        "state": "Jharkhand",
        "city": "Sarath"
      },
      {
        "state": "Jharkhand",
        "city": "Sarwan"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sattur"
      },
      {
        "state": "Rajasthan",
        "city": "Sayra"
      },
      {
        "state": "Telangana",
        "city": "Secunderabad"
      },
      {
        "state": "Jharkhand",
        "city": "Seraikela"
      },
      {
        "state": "Telangana",
        "city": "Shabad"
      },
      {
        "state": "Karnataka",
        "city": "Shahapur"
      },
      {
        "state": "Karnataka",
        "city": "Shivamogga"
      },
      {
        "state": "Rajasthan",
        "city": "Shri Dungargarh"
      },
      {
        "state": "Karnataka",
        "city": "Sidlaghatta"
      },
      {
        "state": "Karnataka",
        "city": "Southegowdanahalli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sriperumbudur"
      },
      {
        "state": "Rajasthan",
        "city": "Sujangarh"
      },
      {
        "state": "Karnataka",
        "city": "Sullia"
      },
      {
        "state": "Meghalaya",
        "city": "Resubelpara"
      },
      {
        "state": "Meghalaya",
        "city": "Sumer"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sundernagar"
      },
      {
        "state": "Haryana",
        "city": "Sundrah"
      },
      {
        "state": "Haryana",
        "city": "Suthana"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thirukkuvalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thirumayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thirupananthal"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruthuraipoondi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruvarur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thuraiyur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruchengode"
      },
      {
        "state": "Telangana",
        "city": "Tirumalagiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tirupattur"
      },
      {
        "state": "Haryana",
        "city": "Tajpur"
      },
      {
        "state": "Haryana",
        "city": "Tohana"
      },
      {
        "state": "Tamil Nadu",
        "city": "Turaiyur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ulundurpet"
      },
      {
        "state": "Telangana",
        "city": "Uppal"
      },
      {
        "state": "Tamil Nadu",
        "city": "Uthukkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Valangaiman"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vandavasi"
      },
      {
        "state": "Telangana",
        "city": "Varni"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vedasandur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Velur"
      },
      {
        "state": "Telangana",
        "city": "Warangal"
      },
      {
        "state": "Karnataka",
        "city": "Yelburga"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Aharbal"
      },
      {
        "state": "Maharashtra",
        "city": "Ahmedpur"
      },
      {
        "state": "Maharashtra",
        "city": "Ambad"
      },
      {
        "state": "Maharashtra",
        "city": "Arvi"
      },
      {
        "state": "Maharashtra",
        "city": "Ashti"
      },
      {
        "state": "Maharashtra",
        "city": "Aundha Nagnath"
      },
      {
        "state": "Maharashtra",
        "city": "Ausa"
      },
      {
        "state": "Maharashtra",
        "city": "Badnapur"
      },
      {
        "state": "Maharashtra",
        "city": "Balapur"
      },
      {
        "state": "Maharashtra",
        "city": "Basmat"
      },
      {
        "state": "Maharashtra",
        "city": "Bhamragarh"
      },
      {
        "state": "Maharashtra",
        "city": "Bhokardan"
      },
      {
        "state": "Maharashtra",
        "city": "Chakur"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Awantipora"
      },
      {
        "state": "Maharashtra",
        "city": "Deoni"
      },
      {
        "state": "Maharashtra",
        "city": "Desaiganj"
      },
      {
        "state": "Maharashtra",
        "city": "Dhamangaon"
      },
      {
        "state": "Maharashtra",
        "city": "Dombivli"
      },
      {
        "state": "Maharashtra",
        "city": "Gadhinglaj"
      },
      {
        "state": "Maharashtra",
        "city": "Goregaon"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Beerwah"
      },
      {
        "state": "Maharashtra",
        "city": "Kalamb"
      },
      {
        "state": "Maharashtra",
        "city": "Karanja"
      },
      {
        "state": "Maharashtra",
        "city": "Karjat"
      },
      {
        "state": "Maharashtra",
        "city": "Khalapur"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Chadoora"
      },
      {
        "state": "Maharashtra",
        "city": "Kudal"
      },
      {
        "state": "Maharashtra",
        "city": "Malegaon"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Damhal Hanji Pora"
      },
      {
        "state": "Maharashtra",
        "city": "Mantha"
      },
      {
        "state": "Maharashtra",
        "city": "Mulshi"
      },
      {
        "state": "Maharashtra",
        "city": "Mumbai"
      },
      {
        "state": "Maharashtra",
        "city": "Nala Sopara"
      },
      {
        "state": "Maharashtra",
        "city": "Navi Mumbai"
      },
      {
        "state": "Maharashtra",
        "city": "Pandharpur"
      },
      {
        "state": "Maharashtra",
        "city": "Paranda"
      },
      {
        "state": "Maharashtra",
        "city": "Parner"
      },
      {
        "state": "Maharashtra",
        "city": "Partur"
      },
      {
        "state": "Maharashtra",
        "city": "Sangamner"
      },
      {
        "state": "Maharashtra",
        "city": "Shahada"
      },
      {
        "state": "Maharashtra",
        "city": "Shirpur"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gool Gulab Garh"
      },
      {
        "state": "Maharashtra",
        "city": "Shirur"
      },
      {
        "state": "Maharashtra",
        "city": "Shirur Anantpal"
      },
      {
        "state": "Assam",
        "city": "Haflong"
      },
      {
        "state": "Assam",
        "city": "Haflong"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Handwara"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Hiranagar"
      },
      {
        "state": "Maharashtra",
        "city": "Sillod"
      },
      {
        "state": "Maharashtra",
        "city": "Sironcha"
      },
      {
        "state": "Maharashtra",
        "city": "Virar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kharonti"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Magam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nowgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pampore"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sopore"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sunderbani"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tangmarg"
      },
      {
        "state": "Punjab",
        "city": "Amloh"
      },
      {
        "state": "Punjab",
        "city": "Baba Bakala"
      },
      {
        "state": "Punjab",
        "city": "Bagha Purana"
      },
      {
        "state": "Punjab",
        "city": "Balachaur"
      },
      {
        "state": "Punjab",
        "city": "Banga Rural"
      },
      {
        "state": "Punjab",
        "city": "Bassi Pathana"
      },
      {
        "state": "Punjab",
        "city": "Bhaini Bagha"
      },
      {
        "state": "Punjab",
        "city": "Bholath"
      },
      {
        "state": "Punjab",
        "city": "Chamkaur Sahib"
      },
      {
        "state": "Mizoram",
        "city": "Chawngte"
      },
      {
        "state": "Punjab",
        "city": "Chohla Sahib"
      },
      {
        "state": "Punjab",
        "city": "Dera Bassi"
      },
      {
        "state": "Punjab",
        "city": "Dhar Kalan"
      },
      {
        "state": "Mizoram",
        "city": "East Lungdar"
      },
      {
        "state": "Punjab",
        "city": "Fatehpur Rajputan"
      },
      {
        "state": "Punjab",
        "city": "Ferozepur"
      },
      {
        "state": "Punjab",
        "city": "Gidderbaha"
      },
      {
        "state": "Punjab",
        "city": "Goindwal"
      },
      {
        "state": "Punjab",
        "city": "Harike"
      },
      {
        "state": "Sikkim",
        "city": "Jorethang"
      },
      {
        "state": "Punjab",
        "city": "Kacha Pakka"
      },
      {
        "state": "Punjab",
        "city": "Kairon"
      },
      {
        "state": "Punjab",
        "city": "Kang"
      },
      {
        "state": "Punjab",
        "city": "Khalra"
      },
      {
        "state": "Punjab",
        "city": "Khamanon"
      },
      {
        "state": "Punjab",
        "city": "Kharar"
      },
      {
        "state": "Punjab",
        "city": "Khem Karan"
      },
      {
        "state": "Punjab",
        "city": "Khilchian"
      },
      {
        "state": "Mizoram",
        "city": "Lengpui"
      },
      {
        "state": "Punjab",
        "city": "Maur"
      },
      {
        "state": "Punjab",
        "city": "Patti"
      },
      {
        "state": "Punjab",
        "city": "Payal"
      },
      {
        "state": "Sikkim",
        "city": "Pelling"
      },
      {
        "state": "Punjab",
        "city": "Phagwara"
      },
      {
        "state": "Punjab",
        "city": "Phillaur"
      },
      {
        "state": "West Bengal",
        "city": "Purbasthali"
      },
      {
        "state": "West Bengal",
        "city": "Rajnagar"
      },
      {
        "state": "Punjab",
        "city": "Rajpura"
      },
      {
        "state": "West Bengal",
        "city": "Rameswarpur"
      },
      {
        "state": "Punjab",
        "city": "Rampura Phul"
      },
      {
        "state": "West Bengal",
        "city": "Rautara"
      },
      {
        "state": "West Bengal",
        "city": "Rayna"
      },
      {
        "state": "West Bengal",
        "city": "Sagardighi"
      },
      {
        "state": "Punjab",
        "city": "Sahibzada Ajit Singh Nagar"
      },
      {
        "state": "West Bengal",
        "city": "Saltora"
      },
      {
        "state": "West Bengal",
        "city": "Sandeshkhali"
      },
      {
        "state": "Mizoram",
        "city": "Sangau"
      },
      {
        "state": "Punjab",
        "city": "Shaheed Bhagat Singh Nagar"
      },
      {
        "state": "Punjab",
        "city": "Shahkot"
      },
      {
        "state": "Punjab",
        "city": "Shehzada Nangal"
      },
      {
        "state": "West Bengal",
        "city": "Sikharpur"
      },
      {
        "state": "West Bengal",
        "city": "Siliguri"
      },
      {
        "state": "West Bengal",
        "city": "Simulia"
      },
      {
        "state": "Punjab",
        "city": "Sirhind"
      },
      {
        "state": "West Bengal",
        "city": "Sonakania"
      },
      {
        "state": "Punjab",
        "city": "Sri Muktsar Sahib"
      },
      {
        "state": "West Bengal",
        "city": "Srikrishnapur"
      },
      {
        "state": "West Bengal",
        "city": "Sukani"
      },
      {
        "state": "Punjab",
        "city": "Sultanpur Lodhi"
      },
      {
        "state": "Punjab",
        "city": "Sunam"
      },
      {
        "state": "West Bengal",
        "city": "Susunia"
      },
      {
        "state": "West Bengal",
        "city": "Tarakeswar"
      },
      {
        "state": "West Bengal",
        "city": "Udbadal"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Asda"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Madarihat"
      },
      {
        "state": "Mizoram",
        "city": "Zawlnuam"
      },
      {
        "state": "Punjab",
        "city": "Zira"
      },
      {
        "state": "Tripura",
        "city": "Agartala"
      },
      {
        "state": "West Bengal",
        "city": "Akanda Beria"
      },
      {
        "state": "West Bengal",
        "city": "Alikhoja"
      },
      {
        "state": "West Bengal",
        "city": "Amdanga"
      },
      {
        "state": "West Bengal",
        "city": "Amta"
      },
      {
        "state": "West Bengal",
        "city": "Arambagh"
      },
      {
        "state": "West Bengal",
        "city": "Bakhrabad"
      },
      {
        "state": "West Bengal",
        "city": "Baliadanga"
      },
      {
        "state": "West Bengal",
        "city": "Bamangola"
      },
      {
        "state": "West Bengal",
        "city": "Barida"
      },
      {
        "state": "West Bengal",
        "city": "Barrackpore"
      },
      {
        "state": "West Bengal",
        "city": "Baruipur"
      },
      {
        "state": "West Bengal",
        "city": "Basudeb Berya"
      },
      {
        "state": "West Bengal",
        "city": "Bhandarhati"
      },
      {
        "state": "West Bengal",
        "city": "Bhupati Nagar"
      },
      {
        "state": "Tripura",
        "city": "Birendra Nagar"
      },
      {
        "state": "Tripura",
        "city": "Bishramganj"
      },
      {
        "state": "West Bengal",
        "city": "Bongaon"
      },
      {
        "state": "West Bengal",
        "city": "Burdwan"
      },
      {
        "state": "West Bengal",
        "city": "Chandannagar"
      },
      {
        "state": "West Bengal",
        "city": "Chandipur"
      },
      {
        "state": "West Bengal",
        "city": "Chatla"
      },
      {
        "state": "Tripura",
        "city": "Dakshin Padmabil"
      },
      {
        "state": "West Bengal",
        "city": "Dalkhola"
      },
      {
        "state": "Tripura",
        "city": "Damcherra"
      },
      {
        "state": "West Bengal",
        "city": "Daspur"
      },
      {
        "state": "West Bengal",
        "city": "Deuli"
      },
      {
        "state": "West Bengal",
        "city": "Dhanghara"
      },
      {
        "state": "West Bengal",
        "city": "Diamond Harbour"
      },
      {
        "state": "West Bengal",
        "city": "Digha"
      },
      {
        "state": "West Bengal",
        "city": "Falakata"
      },
      {
        "state": "Tripura",
        "city": "Fatikchhara"
      },
      {
        "state": "Tripura",
        "city": "Gakulnagar"
      },
      {
        "state": "West Bengal",
        "city": "Garhbeta"
      },
      {
        "state": "West Bengal",
        "city": "Ghatal"
      },
      {
        "state": "West Bengal",
        "city": "Gorubathan"
      },
      {
        "state": "West Bengal",
        "city": "Haldibari"
      },
      {
        "state": "West Bengal",
        "city": "Hansghara"
      },
      {
        "state": "West Bengal",
        "city": "Ichapur"
      },
      {
        "state": "West Bengal",
        "city": "Itahar"
      },
      {
        "state": "West Bengal",
        "city": "Jagannathpur"
      },
      {
        "state": "West Bengal",
        "city": "Jangipara"
      },
      {
        "state": "West Bengal",
        "city": "Jhargram"
      },
      {
        "state": "Tripura",
        "city": "Jolaibari"
      },
      {
        "state": "West Bengal",
        "city": "Joypur"
      },
      {
        "state": "Tripura",
        "city": "Kakraban"
      },
      {
        "state": "West Bengal",
        "city": "Kaliaganj"
      },
      {
        "state": "West Bengal",
        "city": "Kalna"
      },
      {
        "state": "West Bengal",
        "city": "Kantai"
      },
      {
        "state": "West Bengal",
        "city": "Kashimpur"
      },
      {
        "state": "West Bengal",
        "city": "Katwa"
      },
      {
        "state": "West Bengal",
        "city": "Ketugram"
      },
      {
        "state": "West Bengal",
        "city": "Kharagpur"
      },
      {
        "state": "West Bengal",
        "city": "Kolanda"
      },
      {
        "state": "West Bengal",
        "city": "Kultikri"
      },
      {
        "state": "West Bengal",
        "city": "Kumarganj"
      },
      {
        "state": "West Bengal",
        "city": "Laudaha"
      },
      {
        "state": "West Bengal",
        "city": "Madhya Madarihat"
      },
      {
        "state": "Tripura",
        "city": "Maharanipur"
      },
      {
        "state": "West Bengal",
        "city": "Maheshtala"
      },
      {
        "state": "West Bengal",
        "city": "Makrampur"
      },
      {
        "state": "West Bengal",
        "city": "Mal Bazar"
      },
      {
        "state": "Tripura",
        "city": "Manu Bazar"
      },
      {
        "state": "West Bengal",
        "city": "Mathabhanga"
      },
      {
        "state": "West Bengal",
        "city": "Maynaguri"
      },
      {
        "state": "West Bengal",
        "city": "Medinipur"
      },
      {
        "state": "West Bengal",
        "city": "Mekhliganj"
      },
      {
        "state": "Tripura",
        "city": "Melaghar"
      },
      {
        "state": "West Bengal",
        "city": "Memari"
      },
      {
        "state": "West Bengal",
        "city": "Panapukur"
      },
      {
        "state": "Tripura",
        "city": "Panisagar"
      },
      {
        "state": "West Bengal",
        "city": "Panskura"
      },
      {
        "state": "Tripura",
        "city": "Purba Chhamanu"
      },
      {
        "state": "Tripura",
        "city": "Radhanagar"
      },
      {
        "state": "Tripura",
        "city": "Teliamura"
      },
      {
        "state": "Tamil Nadu",
        "city": "Palladam"
      },
      {
        "state": "Karnataka",
        "city": "Bengaluru"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhadohi"
      },
      {
        "state": "West Bengal",
        "city": "Dhuliyan"
      },
      {
        "state": "Karnataka",
        "city": "Rabindranath Tagore Nagar"
      },
      {
        "state": "Bihar",
        "city": "Barh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhinga"
      },
      {
        "state": "Tamil Nadu",
        "city": "Villupuram"
      },
      {
        "state": "Rajasthan",
        "city": "Pilani"
      },
      {
        "state": "Assam",
        "city": "Dispur"
      },
      {
        "state": "Goa",
        "city": "Vasco Da Gama"
      },
      {
        "state": "Goa",
        "city": "Margao"
      },
      {
        "state": "Goa",
        "city": "Old Goa"
      },
      {
        "state": "Gujarat",
        "city": "Kandla"
      },
      {
        "state": "Karnataka",
        "city": "Manipal"
      },
      {
        "state": "Karnataka",
        "city": "Hampi"
      },
      {
        "state": "Karnataka",
        "city": "Coorg"
      },
      {
        "state": "Kerala",
        "city": "Kovalam"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khajuraho"
      },
      {
        "state": "Odisha",
        "city": "Raghurajpur"
      },
      {
        "state": "Punjab",
        "city": "Gobindgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Shekhawati"
      },
      {
        "state": "Rajasthan",
        "city": "Bhiwadi"
      },
      {
        "state": "Rajasthan",
        "city": "Mount Abu"
      },
      {
        "state": "Uttarakhand",
        "city": "Mussoorie"
      },
      {
        "state": "West Bengal",
        "city": "Kalyani"
      },
      {
        "state": "West Bengal",
        "city": "Baharampur"
      },
      {
        "state": "Punjab",
        "city": "Jalalabad"
      },
      {
        "state": "Kargil",
        "city": "Dras"
      },
      {
        "state": "Kargil",
        "city": "Kargil"
      },
      {
        "state": "Kargil",
        "city": "Sankoo"
      },
      {
        "state": "Kargil",
        "city": "Zanskar"
      },
      {
        "state": "Leh",
        "city": "Hunder"
      },
      {
        "state": "Leh",
        "city": "Khalsi"
      },
      {
        "state": "Leh",
        "city": "Ladakh"
      },
      {
        "state": "Leh",
        "city": "Leh"
      },
      {
        "state": "Leh",
        "city": "Len"
      },
      {
        "state": "Leh",
        "city": "Nobra"
      },
      {
        "state": "state",
        "city": "city"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Car Nicobar"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Digipur"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Diglipur"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Ferrargunj"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Hut Bay"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Mayabunder"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Nancowry"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Port Blair"
      },
      {
        "state": "Andaman and Nicobar",
        "city": "Rangat"
      },
      {
        "state": "Andhra Pradesh",
        "city": "A.Konduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Aalamuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Achampet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Achanta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Addanki"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Addateegala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Adoni"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Agali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Agiripalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ainavilli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Akividu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Akkurthi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Akunuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Alamanda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Alamuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Allagadda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Allavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Alluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Amadagur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Amadalavalasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Amarapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Amaravathi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ambajipeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ambavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anakapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anamasamudrampeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anandapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anantapur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ananthagiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ananthasagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ananthavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anaparthy"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Anemadugu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Angalakuduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Annavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Appikatla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Araku"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ardhaveedu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atchutapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atisurikaviti"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atlur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atmakur 3"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Atreyapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Attili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Avanigadda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "B.Kothakota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "B.N. Kandriga"
      },
      {
        "state": "Andhra Pradesh",
        "city": "B.P.Rachapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bachannapet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Badangi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Badvel"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Balapanur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Balayapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Balijipeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Banaganapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bandi Atmakuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bangarupalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bapatla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bathalapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bellamkonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Beluguppa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bestavaripeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Betamcherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhakarapet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhamini"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhattiprolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bheemunipatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhimadole"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhimavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bhogapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bikkavolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Billanapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bitragunta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bobbili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bokkasam Palem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bollapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bommireddy Cheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bonangi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bondapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Brahmadevam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Brahmasamudram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Buchireddypalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bukkapatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Bukkaraya Samudram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Burja"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Butteyagudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "C. Belagal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "C.Rajupalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chagallu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chakalakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chakrayapet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Challapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Challapeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chandole"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chandragiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chandralapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chataparru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chatrai"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chebrolu 2"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Cheedikada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Cheepurupalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chejerla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chennekothapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chennur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Cherukupalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Cheruvu Madhavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chilakaluripet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chilakapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chilamathur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chillakur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chinna Dornala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chinna Gotti Gallu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chinnajonnavalasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chinnamandem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chintalapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chintapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chinthakommadinne"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chirala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chittamuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chittecherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chittoor"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chitvel"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chodavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chodayapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Chowdepalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Cumbum"
      },
      {
        "state": "Andhra Pradesh",
        "city": "D.Hirehal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "D.Tallavalasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dachepalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dagadarthi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dakkili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Damalcheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Damavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Darbhagudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Denduluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Denkada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Devanakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Deverapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Devipatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dharmaji Gudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dharmavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dhone"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dhulipudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Donakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Donnikota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Doravarichatram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dornipadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Duggirala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Durgi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Duttalur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Duvvuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Dwaraka Tirumala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "East Godavari"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Edlapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Eluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Emani"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Etcherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Folkspeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gadi Lanka"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gadivemula"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gajapatinagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Galiveedu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gampalagudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ganapavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gandavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gandepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gandipalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gandlapenta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gandupalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gangadhara Nellore"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gangavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ganguvarisigadam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gannavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gantyada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Garividi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Garladinne"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Garugubilli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ghantasala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Giddalur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gokavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gollaprollu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Golugonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gonegandla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gooty"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gopalapatnam Rural"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gopannapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gopavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gorantla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gospadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gudibanda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gudipala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gudivada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gudlavalleru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gudur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Guduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gummagatta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gummalaxmipuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gumparlapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gundalammapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gundugolanu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Guntakal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Guntur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gurazala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gurla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Gurram Konda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Hanuman Junction"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Herial"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Hindupur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Hiramandalam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ibrahimpatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ichchapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Inturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ipur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Iragavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Irala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jaggampeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jaggayyapet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jaggisetti Gudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jaladanki"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jalumuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jammalamadugu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Janapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jangareddigudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jeelugumilli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Jiyyammavalasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Joduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kadapa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kadiam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kadiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kaikaluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kaikaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kajuluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kakinada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kakulapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kakumanu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalakada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalasapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalidindi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kaligiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalikiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kaluvaya"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kalyandurg"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kamalapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kamavarapu Kota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kambadur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kambhamvaripalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kammavari Palli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kanagala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kanaganapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kandukur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kanekal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kanigiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kanipakam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kankipadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kannapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kapileswarapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Karapa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Karatampadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Karempudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Karlapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Karveti Nagar"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kasibugga"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Katrenikona"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Katuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kavali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kaviti"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Khajipet Sunkesula"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kirlampudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kodavalur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kodumur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Koduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Koilkuntla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kolakaluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kolimigundla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kollipara"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kolluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Komarada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Komarolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Konada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kondapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kondapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kondareddy Palle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Korukonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kotabommali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kotagandredu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kotananduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kothacheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kothapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kothapeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kothavalasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kotturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kovur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kovvali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kovvur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Koyyalagudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Krishna"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Krosur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kruthivennu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kuchinapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kuchipudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kudair"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kuderu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kundurpi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kuppam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kurichedu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kurnool"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Kurupam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lakkavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lakkavarapu Kota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lakkireddipalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lankapalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lavanuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Laveru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lemalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lepakshi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Lingala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Loddalapeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Machavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Macherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Machilipatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Madakasira"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Madanapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Madanapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Maddikera"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Maddikera East"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Maduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Majjigudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Makkuva"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Malameedi Kambala Dinne"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Malikipuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Malkapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mamidikududru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Manchili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mandapeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mandasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mandavalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Manepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mangalagiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mantralayam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Manubolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Maredumilli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Markapur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Marripadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Martur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Maruteru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Medikonduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Meliaputti"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mentada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Merakamudidam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mogalturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mopidevi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Movva"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Moyyeru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Muddanur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mudigubba"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mudinepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mulakalacheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mummidivaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Muppalla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Murukambattu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Musunuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Muthukur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Muttukuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mutyala Cheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mydukur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mylavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Mypadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nadendla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nagamambapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nagayalanka"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Naidupeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nallacheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nallaguttapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nallajerla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nallamada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nambula Pulakunta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Namburu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandalur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandigama"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandikotkur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandimandalam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandipadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandivada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandivelugu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nandyal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narampeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narapala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narasannapeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narasaraopet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narayanavanam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narsapur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Narsipatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nathavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nekarikallu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nelapattu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nellimarla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nellore"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nidadavolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nindra"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nizampatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "North Mopuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "North Rajupalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nutakki"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nuzendla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Nuzividu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Obuladevaracheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Obulavaripalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ongole"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Owk"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pachipenta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Padapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Paderu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pagidyala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pakala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palakoderu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palakollu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palamaner"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palasa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palasamudram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palligandredu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Palugurallapalle Part IV"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pamarru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pamidi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pamulapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pannur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Panyam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Parchoor"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Parigi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Parvathipuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Patchalatadiparru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Patha Gannavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pathapatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pathrapada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pattikonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedakakani"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedakurapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedamajjipalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedamanapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedana"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedanandipadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedaparupudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedavadlapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedda Aravidu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pedda Panjani"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Peddamogalai Palli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Peddapappuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Peddapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pellakuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penagalur Agraharam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penagaluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penamaluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pendlimarri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pentapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penuganchiprolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penugolanu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penugonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penukonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penumantra"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Penumur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Peravali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Phirangipuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pichatur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Piduguralla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pileru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pithapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Podalakur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Podili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Polaki"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Polavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pondicherry"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ponduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ponnekallu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ponnur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Porumamilla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pragadavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Prakasam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Prathipadu 2"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Proddatur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pulicherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pulivendula"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pulla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pullala Cheruvu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pullampet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Punganur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pusapatirega"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Putalapattu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Putluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Puttaparthi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Puttur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Pydipaka"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Racherla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajahmundry"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajampet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajanagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajavommangi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rajupalem 2"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramabhadrapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramachandrapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramagiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramakuppam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramannamodi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramasamudram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ramatheertham"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rampachodavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ranastalam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rangampeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rapthadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rapur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Ravulapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rayachoty"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rayadurgam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rayavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Razam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Razole"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Reddigudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Reddypalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Renigunta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rentachintala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Repalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Roddam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rolla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Rompicherla 2"
      },
      {
        "state": "Andhra Pradesh",
        "city": "S.Mydukur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sakhinetipalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Salihundam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Salur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Samarlakota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sambepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sangam Jagarlamudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sanjamala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sankhavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Santhabommali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Saravakota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sarubujjili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sarvepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sathyavedu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sattenapalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Satyavedu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Savalyapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Seethampeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Seethanagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Seetharamapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Setturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Shanthipuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Siddanakonduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sidhout"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sigadam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Simhadri Puram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Singanamala"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Singarayakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sirusuvada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sirvella"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sithanagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Somandepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Somarajuillindalaparru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sompeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sreekanthapuram Rural"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sreerangarajapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Srikakulam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Srikalahasti"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Srirangarajapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Srisailam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Srungavarapu kota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sullurupeta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sumitrapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Surapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Sydapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "T. Sundupalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "T.narasapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tada Khandrika"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadepalle"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadepalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadepalligudem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadikalapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadikonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadimarri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tadipatri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tallapudi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tallarevu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Talupula"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tanakallu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tanguturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tanuku"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tarlupadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tatipaka"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tekkali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Telaprolu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tenali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Therlam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Thondamanadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Thondur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Thotapalli Gudur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Thotlavalluru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Thullur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tirupati"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tiruvuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tondangi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Torredu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tripuranthakam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tsunduru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tuggali"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Tuni"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Turakapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Turumella"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Udayagiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Undarajavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Undi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Unguturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Uppalaguptam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Uravakonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vaadaparru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vadamalapet"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vajrakarur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vajrapukotturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vakadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vallur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vandrangi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vangara 2"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Varadaiahpalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Varikuntapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vatsavai"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vayalpadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vedullakunta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vedurukuppam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veeraballi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veeraghattam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veeravalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veeravasaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veerullapadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veldhurthy"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Veldurthi"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Velivennu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vellaturu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Velugodu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vempalli"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vemuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkata Krishna Puram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkata Rangaraya Puram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkatachalam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkatagiri"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkatagirikota"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Venkatakrishnaraja Puram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vepada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vetapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vidapanakal"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vidavalur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vijayapuram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vijayarai"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vijayawada"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vinjamur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vinukonda"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Viroor"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Visakhapatnam"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Visakhapatnam Port"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vissannapetaa"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vizianagaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Voletivaripalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vontimitta"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Vuyyuru"
      },
      {
        "state": "Andhra Pradesh",
        "city": "West Godavari"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Y .ramavaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yadamari"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yadiki"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yearraguntla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yeleswaram"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yellamanchili"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yellanur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yemmiganur"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yerpadu"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yerragondapalem"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yerraguntla"
      },
      {
        "state": "Andhra Pradesh",
        "city": "Yerravaripalem"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Alinye"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Anelih"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Anini"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Anjaw"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bagra"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bagra Higi"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Balijan"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Balijan Nishi"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bame"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bandardewa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Basar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bene"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bhalukpong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bogia Siyum"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bomdila"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bordumsa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bordumsa Circle"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Bubang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Chambang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Changlang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Chowkham"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Dali"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Damin"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Daporijo"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Darak"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Daring"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Darka"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Deomali"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Diomukh"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Dirang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Dumporijo"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Giba"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Gumgong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Hayuliang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Itanagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Jairampur"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Jang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kalaktang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kambang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kamlang Nagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kanubari"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kanubari Town"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kaying"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Khellong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Khemiyong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kherem Bisa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Khonsa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kimin"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kokila"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Koloriang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kombo Monku"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Kra Daadi"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lallung"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lemiking"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lepajaring"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Likabali"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Liromoba"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lish"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lohit"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Longding"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lower Siang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Lumla"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Mahadevpur"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Maro"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Mebo"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Mechuka"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Medo"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Miao"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Momong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Monigong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Munna Camp"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Muri"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nacho"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nafra"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Naharlagun"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Namdang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nampong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Namsai"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Namsang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Namsang Mukh"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Namtok"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Narottam Nagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nikte"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nirjuli"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Nyapin"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Palin"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Papum Pare"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Pasighat"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Payum"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Rajanagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Ranglum"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Roing 2"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Rupa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Sagalee"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Salari"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Sangti"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Seijosa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Senge"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Seppa"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Shannan"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Siang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Sippi"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Siyum"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Soha"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Sonajuli"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Sunpura"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tabarijo"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Taksing"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tali"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Taliha"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tato"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tawang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tenga Market"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tezu"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tippi"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tirap"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tirbin"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Tuting"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Vijoynagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Vijoypur"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Vivek Nagar"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Wakro"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yangkang"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yazali"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yingkiong"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yoji Yora"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yomcha"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Yupia"
      },
      {
        "state": "Arunachal Pradesh",
        "city": "Ziro"
      },
      {
        "state": "Assam",
        "city": "Abhayapuri"
      },
      {
        "state": "Assam",
        "city": "Adalbari"
      },
      {
        "state": "Assam",
        "city": "Agomani"
      },
      {
        "state": "Assam",
        "city": "Ahopa"
      },
      {
        "state": "Assam",
        "city": "Alisinga"
      },
      {
        "state": "Assam",
        "city": "Amguri"
      },
      {
        "state": "Assam",
        "city": "Amtala"
      },
      {
        "state": "Assam",
        "city": "Arearjhar Pt.I"
      },
      {
        "state": "Assam",
        "city": "Arikuchi"
      },
      {
        "state": "Assam",
        "city": "Atherikhat Jangle"
      },
      {
        "state": "Assam",
        "city": "Aujuri No.3"
      },
      {
        "state": "Assam",
        "city": "Baganpara"
      },
      {
        "state": "Assam",
        "city": "Bagribari"
      },
      {
        "state": "Assam",
        "city": "Bagulamari"
      },
      {
        "state": "Assam",
        "city": "Baharihat"
      },
      {
        "state": "Assam",
        "city": "Bajali"
      },
      {
        "state": "Assam",
        "city": "Bakola"
      },
      {
        "state": "Assam",
        "city": "Bakrapara Pt-II"
      },
      {
        "state": "Assam",
        "city": "Baksa"
      },
      {
        "state": "Assam",
        "city": "Bakuajari"
      },
      {
        "state": "Assam",
        "city": "Bala Bhitha"
      },
      {
        "state": "Assam",
        "city": "Balijana"
      },
      {
        "state": "Assam",
        "city": "Balikaria"
      },
      {
        "state": "Assam",
        "city": "Balikuchi"
      },
      {
        "state": "Assam",
        "city": "Balipara"
      },
      {
        "state": "Assam",
        "city": "Balizar"
      },
      {
        "state": "Assam",
        "city": "Bamunbari"
      },
      {
        "state": "Assam",
        "city": "Bandarmari"
      },
      {
        "state": "Assam",
        "city": "Baramchari"
      },
      {
        "state": "Assam",
        "city": "Barangabari"
      },
      {
        "state": "Assam",
        "city": "Barbang"
      },
      {
        "state": "Assam",
        "city": "Barbari"
      },
      {
        "state": "Assam",
        "city": "Bargandubi"
      },
      {
        "state": "Assam",
        "city": "Barimakha"
      },
      {
        "state": "Assam",
        "city": "Barkhetri"
      },
      {
        "state": "Assam",
        "city": "Barnadi Par"
      },
      {
        "state": "Assam",
        "city": "Barpathar"
      },
      {
        "state": "Assam",
        "city": "Barpeta"
      },
      {
        "state": "Assam",
        "city": "Barpeta Road"
      },
      {
        "state": "Assam",
        "city": "Bedeti T.E. 318/494"
      },
      {
        "state": "Assam",
        "city": "Behali"
      },
      {
        "state": "Assam",
        "city": "Belsor"
      },
      {
        "state": "Assam",
        "city": "Bengtol Kumguri"
      },
      {
        "state": "Assam",
        "city": "Bennabari"
      },
      {
        "state": "Assam",
        "city": "Bezera"
      },
      {
        "state": "Assam",
        "city": "Bhairabkunda"
      },
      {
        "state": "Assam",
        "city": "Bhakatpara"
      },
      {
        "state": "Assam",
        "city": "Bhawanipur"
      },
      {
        "state": "Assam",
        "city": "Bhella"
      },
      {
        "state": "Assam",
        "city": "Bhera"
      },
      {
        "state": "Assam",
        "city": "Bhojkuchi"
      },
      {
        "state": "Assam",
        "city": "Bhowanipur"
      },
      {
        "state": "Assam",
        "city": "Bhowraguri"
      },
      {
        "state": "Assam",
        "city": "Bhowraguri Mandarpara"
      },
      {
        "state": "Assam",
        "city": "Bhowraguri-Satbil-II"
      },
      {
        "state": "Assam",
        "city": "Bhuragaon"
      },
      {
        "state": "Assam",
        "city": "Bidyapara II Part Pt"
      },
      {
        "state": "Assam",
        "city": "Bihaguri"
      },
      {
        "state": "Assam",
        "city": "Bihpuria"
      },
      {
        "state": "Assam",
        "city": "Bijni"
      },
      {
        "state": "Assam",
        "city": "Bilasipara"
      },
      {
        "state": "Assam",
        "city": "Bindukuri T.E."
      },
      {
        "state": "Assam",
        "city": "Biswanath"
      },
      {
        "state": "Assam",
        "city": "Biswanath Charali"
      },
      {
        "state": "Assam",
        "city": "Biswanathghat"
      },
      {
        "state": "Assam",
        "city": "Boginadi"
      },
      {
        "state": "Assam",
        "city": "Bogribari"
      },
      {
        "state": "Assam",
        "city": "Boitamari"
      },
      {
        "state": "Assam",
        "city": "Bokajan"
      },
      {
        "state": "Assam",
        "city": "Bokakhat"
      },
      {
        "state": "Assam",
        "city": "Boko"
      },
      {
        "state": "Assam",
        "city": "Bongaigaon"
      },
      {
        "state": "Assam",
        "city": "Bongaon"
      },
      {
        "state": "Assam",
        "city": "Bor Baruah"
      },
      {
        "state": "Assam",
        "city": "Bordoloni"
      },
      {
        "state": "Assam",
        "city": "Borsola"
      },
      {
        "state": "Assam",
        "city": "Borthekerabari"
      },
      {
        "state": "Assam",
        "city": "Bosa Gaon"
      },
      {
        "state": "Assam",
        "city": "Buri Nagar"
      },
      {
        "state": "Assam",
        "city": "Cachar"
      },
      {
        "state": "Assam",
        "city": "Chabua"
      },
      {
        "state": "Assam",
        "city": "Chaibari"
      },
      {
        "state": "Assam",
        "city": "Chamaria Pam"
      },
      {
        "state": "Assam",
        "city": "Chamaria Satra"
      },
      {
        "state": "Assam",
        "city": "Chamata"
      },
      {
        "state": "Assam",
        "city": "Chanbarikhuti"
      },
      {
        "state": "Assam",
        "city": "Chandrapur Bagicha"
      },
      {
        "state": "Assam",
        "city": "Changsari"
      },
      {
        "state": "Assam",
        "city": "Chapakhowa"
      },
      {
        "state": "Assam",
        "city": "Charagaon"
      },
      {
        "state": "Assam",
        "city": "Charaideo"
      },
      {
        "state": "Assam",
        "city": "Chariali"
      },
      {
        "state": "Assam",
        "city": "Chariduar"
      },
      {
        "state": "Assam",
        "city": "Charingia Gaon"
      },
      {
        "state": "Assam",
        "city": "Chaudhuritop"
      },
      {
        "state": "Assam",
        "city": "Chechapani"
      },
      {
        "state": "Assam",
        "city": "Chhaygaon"
      },
      {
        "state": "Assam",
        "city": "Chirang"
      },
      {
        "state": "Assam",
        "city": "Dalbari"
      },
      {
        "state": "Assam",
        "city": "Dalgaon"
      },
      {
        "state": "Assam",
        "city": "Dalu Grant"
      },
      {
        "state": "Assam",
        "city": "Dangarmakha"
      },
      {
        "state": "Assam",
        "city": "Darangamela"
      },
      {
        "state": "Assam",
        "city": "Darrang"
      },
      {
        "state": "Assam",
        "city": "Debitola"
      },
      {
        "state": "Assam",
        "city": "Dekargaon"
      },
      {
        "state": "Assam",
        "city": "Deomornoi"
      },
      {
        "state": "Assam",
        "city": "Deulguri Pt-II"
      },
      {
        "state": "Assam",
        "city": "Dhakuakhana"
      },
      {
        "state": "Assam",
        "city": "Dhalpur"
      },
      {
        "state": "Assam",
        "city": "Dhanbil"
      },
      {
        "state": "Assam",
        "city": "Dhaniagog"
      },
      {
        "state": "Assam",
        "city": "Dhekiajuli"
      },
      {
        "state": "Assam",
        "city": "Dhemaji"
      },
      {
        "state": "Assam",
        "city": "Dhubri"
      },
      {
        "state": "Assam",
        "city": "Dhula"
      },
      {
        "state": "Assam",
        "city": "Dibrugarh"
      },
      {
        "state": "Assam",
        "city": "Dicial Dhulia Gaon"
      },
      {
        "state": "Assam",
        "city": "Dighali Gaon"
      },
      {
        "state": "Assam",
        "city": "Dihingia Gaon"
      },
      {
        "state": "Assam",
        "city": "Dihira"
      },
      {
        "state": "Assam",
        "city": "Dikrong"
      },
      {
        "state": "Assam",
        "city": "Dima Hasao (North Cachar Hills)"
      },
      {
        "state": "Assam",
        "city": "Dimakuchi"
      },
      {
        "state": "Assam",
        "city": "Diphu"
      },
      {
        "state": "Assam",
        "city": "Dirpaisantipur Gaon"
      },
      {
        "state": "Assam",
        "city": "Dirua"
      },
      {
        "state": "Assam",
        "city": "Dispur"
      },
      {
        "state": "Assam",
        "city": "Doboka Town"
      },
      {
        "state": "Assam",
        "city": "Dongkamukam"
      },
      {
        "state": "Assam",
        "city": "Donkamokam"
      },
      {
        "state": "Assam",
        "city": "Doom Dooma"
      },
      {
        "state": "Assam",
        "city": "Doomni"
      },
      {
        "state": "Assam",
        "city": "Dudhnoi"
      },
      {
        "state": "Assam",
        "city": "Duliajan"
      },
      {
        "state": "Assam",
        "city": "Duni"
      },
      {
        "state": "Assam",
        "city": "Fekamari"
      },
      {
        "state": "Assam",
        "city": "Gerua"
      },
      {
        "state": "Assam",
        "city": "Geruapara"
      },
      {
        "state": "Assam",
        "city": "Ghilamora"
      },
      {
        "state": "Assam",
        "city": "Ghoramari"
      },
      {
        "state": "Assam",
        "city": "Goalpara"
      },
      {
        "state": "Assam",
        "city": "Godabahar Gaon"
      },
      {
        "state": "Assam",
        "city": "Gogamukh"
      },
      {
        "state": "Assam",
        "city": "Gohpur"
      },
      {
        "state": "Assam",
        "city": "Golaghat"
      },
      {
        "state": "Assam",
        "city": "Golakganj"
      },
      {
        "state": "Assam",
        "city": "Gorbil"
      },
      {
        "state": "Assam",
        "city": "Goreswar Block"
      },
      {
        "state": "Assam",
        "city": "Goroimari"
      },
      {
        "state": "Assam",
        "city": "Gossaigaon"
      },
      {
        "state": "Assam",
        "city": "Guakuchi"
      },
      {
        "state": "Assam",
        "city": "Guwahati"
      },
      {
        "state": "Assam",
        "city": "Haflong 2"
      },
      {
        "state": "Assam",
        "city": "Hailakandi"
      },
      {
        "state": "Assam",
        "city": "Hajo"
      },
      {
        "state": "Assam",
        "city": "Halem T.E. 270/442 Grant"
      },
      {
        "state": "Assam",
        "city": "Hamren"
      },
      {
        "state": "Assam",
        "city": "Hanapara"
      },
      {
        "state": "Assam",
        "city": "Haribhanga"
      },
      {
        "state": "Assam",
        "city": "Harisinga"
      },
      {
        "state": "Assam",
        "city": "Hatibandha"
      },
      {
        "state": "Assam",
        "city": "Hatidhura"
      },
      {
        "state": "Assam",
        "city": "Hattigarh"
      },
      {
        "state": "Assam",
        "city": "Hojai"
      },
      {
        "state": "Assam",
        "city": "Howly"
      },
      {
        "state": "Assam",
        "city": "Jagiroad"
      },
      {
        "state": "Assam",
        "city": "Jalkhana"
      },
      {
        "state": "Assam",
        "city": "Jaloni Naohalia"
      },
      {
        "state": "Assam",
        "city": "Jamugurihat"
      },
      {
        "state": "Assam",
        "city": "Jonai"
      },
      {
        "state": "Assam",
        "city": "Jorhat"
      },
      {
        "state": "Assam",
        "city": "Joy Mangla"
      },
      {
        "state": "Assam",
        "city": "Jutlibari Borhula"
      },
      {
        "state": "Assam",
        "city": "Kacharigaon"
      },
      {
        "state": "Assam",
        "city": "Kachugaon"
      },
      {
        "state": "Assam",
        "city": "Kaithalkuchi"
      },
      {
        "state": "Assam",
        "city": "Kakaya"
      },
      {
        "state": "Assam",
        "city": "Kalabari"
      },
      {
        "state": "Assam",
        "city": "Kalaigaon"
      },
      {
        "state": "Assam",
        "city": "Kalgachia"
      },
      {
        "state": "Assam",
        "city": "Kaliabor"
      },
      {
        "state": "Assam",
        "city": "Kaljar"
      },
      {
        "state": "Assam",
        "city": "Kamarkuchi"
      },
      {
        "state": "Assam",
        "city": "Kampur Town"
      },
      {
        "state": "Assam",
        "city": "Kamrup"
      },
      {
        "state": "Assam",
        "city": "Kamrup Metropolitan"
      },
      {
        "state": "Assam",
        "city": "Karbi Anglong"
      },
      {
        "state": "Assam",
        "city": "Karemura"
      },
      {
        "state": "Assam",
        "city": "Karimganj"
      },
      {
        "state": "Assam",
        "city": "Kaslatari Pt.I"
      },
      {
        "state": "Assam",
        "city": "Kendukuchi"
      },
      {
        "state": "Assam",
        "city": "Ketekibari"
      },
      {
        "state": "Assam",
        "city": "Khagrabari"
      },
      {
        "state": "Assam",
        "city": "Khalihamari"
      },
      {
        "state": "Assam",
        "city": "Kharsitha"
      },
      {
        "state": "Assam",
        "city": "Kharupetia"
      },
      {
        "state": "Assam",
        "city": "Kharupetiaghat"
      },
      {
        "state": "Assam",
        "city": "Khatikuchi"
      },
      {
        "state": "Assam",
        "city": "Khelmati"
      },
      {
        "state": "Assam",
        "city": "Khoirabari"
      },
      {
        "state": "Assam",
        "city": "Khowang"
      },
      {
        "state": "Assam",
        "city": "Kokilabari"
      },
      {
        "state": "Assam",
        "city": "Kokrajhar"
      },
      {
        "state": "Assam",
        "city": "Kopati"
      },
      {
        "state": "Assam",
        "city": "Kumarikata"
      },
      {
        "state": "Assam",
        "city": "Lahowal"
      },
      {
        "state": "Assam",
        "city": "Lakhimi Pathar"
      },
      {
        "state": "Assam",
        "city": "Lakhimpur"
      },
      {
        "state": "Assam",
        "city": "Lakhipur"
      },
      {
        "state": "Assam",
        "city": "Lalpool"
      },
      {
        "state": "Assam",
        "city": "Laluk"
      },
      {
        "state": "Assam",
        "city": "Luki ( Boko Block)"
      },
      {
        "state": "Assam",
        "city": "Lunpuria"
      },
      {
        "state": "Assam",
        "city": "Machkhowa Chariali"
      },
      {
        "state": "Assam",
        "city": "Majuli"
      },
      {
        "state": "Assam",
        "city": "Mandia"
      },
      {
        "state": "Assam",
        "city": "Mandia Gaon"
      },
      {
        "state": "Assam",
        "city": "Mangaldoi"
      },
      {
        "state": "Assam",
        "city": "Mankachar"
      },
      {
        "state": "Assam",
        "city": "Margherita"
      },
      {
        "state": "Assam",
        "city": "Margherita Kumar Potty"
      },
      {
        "state": "Assam",
        "city": "Marowa"
      },
      {
        "state": "Assam",
        "city": "Mayanbari"
      },
      {
        "state": "Assam",
        "city": "Mazbat"
      },
      {
        "state": "Assam",
        "city": "Mijikajan T.E. 281/321"
      },
      {
        "state": "Assam",
        "city": "Mikir Para Chakardoi"
      },
      {
        "state": "Assam",
        "city": "Milanpur"
      },
      {
        "state": "Assam",
        "city": "Missamari"
      },
      {
        "state": "Assam",
        "city": "Moa Mari Kachari"
      },
      {
        "state": "Assam",
        "city": "Mohon Deodhai"
      },
      {
        "state": "Assam",
        "city": "Moinapara"
      },
      {
        "state": "Assam",
        "city": "Moranhat Town"
      },
      {
        "state": "Assam",
        "city": "Morigaon"
      },
      {
        "state": "Assam",
        "city": "Mugkuchi"
      },
      {
        "state": "Assam",
        "city": "Muhimari Bilar Pathar"
      },
      {
        "state": "Assam",
        "city": "Mukalmua"
      },
      {
        "state": "Assam",
        "city": "Mukta Pur"
      },
      {
        "state": "Assam",
        "city": "Mushalpur"
      },
      {
        "state": "Assam",
        "city": "N.C.Angarkata"
      },
      {
        "state": "Assam",
        "city": "Na-Ali Bhatia"
      },
      {
        "state": "Assam",
        "city": "Na-Pam Gaon"
      },
      {
        "state": "Assam",
        "city": "Nag-Sankar Gaon"
      },
      {
        "state": "Assam",
        "city": "Nagaon"
      },
      {
        "state": "Assam",
        "city": "Nagarbera"
      },
      {
        "state": "Assam",
        "city": "Naharkatia"
      },
      {
        "state": "Assam",
        "city": "Nalbari"
      },
      {
        "state": "Assam",
        "city": "Namrup"
      },
      {
        "state": "Assam",
        "city": "Nankar Bhaira"
      },
      {
        "state": "Assam",
        "city": "Narsingpur Pt II"
      },
      {
        "state": "Assam",
        "city": "Nikashi"
      },
      {
        "state": "Assam",
        "city": "Niz Namati"
      },
      {
        "state": "Assam",
        "city": "Niz-Bahjani"
      },
      {
        "state": "Assam",
        "city": "Niz-Barigog"
      },
      {
        "state": "Assam",
        "city": "Niz-Madartola"
      },
      {
        "state": "Assam",
        "city": "No.1 Barchala"
      },
      {
        "state": "Assam",
        "city": "No.1 Chandana T.E."
      },
      {
        "state": "Assam",
        "city": "No.1 Gorali T.E."
      },
      {
        "state": "Assam",
        "city": "No.1.Kaplabari"
      },
      {
        "state": "Assam",
        "city": "No.1.Kekan Kuchi"
      },
      {
        "state": "Assam",
        "city": "No.1.Paharpur"
      },
      {
        "state": "Assam",
        "city": "No.3 Goreswar"
      },
      {
        "state": "Assam",
        "city": "No.4 Barangajuli"
      },
      {
        "state": "Assam",
        "city": "North Lakhimpur"
      },
      {
        "state": "Assam",
        "city": "Pabhoi"
      },
      {
        "state": "Assam",
        "city": "Pachim Jinjia"
      },
      {
        "state": "Assam",
        "city": "Pachim Sualani"
      },
      {
        "state": "Assam",
        "city": "Pakribari"
      },
      {
        "state": "Assam",
        "city": "Palashbari"
      },
      {
        "state": "Assam",
        "city": "Panchgram"
      },
      {
        "state": "Assam",
        "city": "Parakuchi"
      },
      {
        "state": "Assam",
        "city": "Patacharkuchi"
      },
      {
        "state": "Assam",
        "city": "Pathali Pahar"
      },
      {
        "state": "Assam",
        "city": "Pathali Pam Grant 314/51"
      },
      {
        "state": "Assam",
        "city": "Patharughat"
      },
      {
        "state": "Assam",
        "city": "Pathsala"
      },
      {
        "state": "Assam",
        "city": "Patkijuli"
      },
      {
        "state": "Assam",
        "city": "Phakum Kumar Phodia"
      },
      {
        "state": "Assam",
        "city": "Pub Kathal Muri"
      },
      {
        "state": "Assam",
        "city": "Pub Khagra Bari"
      },
      {
        "state": "Assam",
        "city": "Pub Paneri"
      },
      {
        "state": "Assam",
        "city": "Raghunandanpur Pt II"
      },
      {
        "state": "Assam",
        "city": "Rakhaltila"
      },
      {
        "state": "Assam",
        "city": "Rampur Agagara"
      },
      {
        "state": "Assam",
        "city": "Rampur No.3"
      },
      {
        "state": "Assam",
        "city": "Ranga Chakua"
      },
      {
        "state": "Assam",
        "city": "Ranga Pothar"
      },
      {
        "state": "Assam",
        "city": "Rangamati Gaon"
      },
      {
        "state": "Assam",
        "city": "Rangapara"
      },
      {
        "state": "Assam",
        "city": "Rangia"
      },
      {
        "state": "Assam",
        "city": "Rangjuli"
      },
      {
        "state": "Assam",
        "city": "Rewa N.C."
      },
      {
        "state": "Assam",
        "city": "Rowta"
      },
      {
        "state": "Assam",
        "city": "Rupshi Part IV"
      },
      {
        "state": "Assam",
        "city": "Sadiya"
      },
      {
        "state": "Assam",
        "city": "Salkocha"
      },
      {
        "state": "Assam",
        "city": "Salmara"
      },
      {
        "state": "Assam",
        "city": "Sandheli"
      },
      {
        "state": "Assam",
        "city": "Sanekuchi"
      },
      {
        "state": "Assam",
        "city": "Sarthebari"
      },
      {
        "state": "Assam",
        "city": "Sarupathar"
      },
      {
        "state": "Assam",
        "city": "Sarupeta"
      },
      {
        "state": "Assam",
        "city": "Saualkuchi"
      },
      {
        "state": "Assam",
        "city": "Saukuchi"
      },
      {
        "state": "Assam",
        "city": "Sepon Chah Bagicha 107/110 Nlr"
      },
      {
        "state": "Assam",
        "city": "Shamugaon"
      },
      {
        "state": "Assam",
        "city": "Sidli"
      },
      {
        "state": "Assam",
        "city": "Silapathar"
      },
      {
        "state": "Assam",
        "city": "Silchar"
      },
      {
        "state": "Assam",
        "city": "Simen Chapori"
      },
      {
        "state": "Assam",
        "city": "Simlaguri"
      },
      {
        "state": "Assam",
        "city": "Sipajhar"
      },
      {
        "state": "Assam",
        "city": "Sirajuli"
      },
      {
        "state": "Assam",
        "city": "Sissiborgaon"
      },
      {
        "state": "Assam",
        "city": "Sivasagar"
      },
      {
        "state": "Assam",
        "city": "Sonapur Block"
      },
      {
        "state": "Assam",
        "city": "Sonitpur"
      },
      {
        "state": "Assam",
        "city": "Sootea"
      },
      {
        "state": "Assam",
        "city": "Sorbhog"
      },
      {
        "state": "Assam",
        "city": "South Salamara-Mankachar"
      },
      {
        "state": "Assam",
        "city": "Suradi"
      },
      {
        "state": "Assam",
        "city": "Tamulpur"
      },
      {
        "state": "Assam",
        "city": "Tangla"
      },
      {
        "state": "Assam",
        "city": "Teghakhat"
      },
      {
        "state": "Assam",
        "city": "Teloijan Darkhsta No.25"
      },
      {
        "state": "Assam",
        "city": "Tengakhat"
      },
      {
        "state": "Assam",
        "city": "Terechia"
      },
      {
        "state": "Assam",
        "city": "Tetenbari"
      },
      {
        "state": "Assam",
        "city": "Tezpur"
      },
      {
        "state": "Assam",
        "city": "Thamna"
      },
      {
        "state": "Assam",
        "city": "Thanguri"
      },
      {
        "state": "Assam",
        "city": "Thelamara"
      },
      {
        "state": "Assam",
        "city": "Tihu"
      },
      {
        "state": "Assam",
        "city": "Tingkhong"
      },
      {
        "state": "Assam",
        "city": "Tinsukia"
      },
      {
        "state": "Assam",
        "city": "Tipling"
      },
      {
        "state": "Assam",
        "city": "Titabor"
      },
      {
        "state": "Assam",
        "city": "Tumni Lawkhowa"
      },
      {
        "state": "Assam",
        "city": "Udalguri"
      },
      {
        "state": "Assam",
        "city": "Ulabari"
      },
      {
        "state": "Assam",
        "city": "West Karbi Anglong"
      },
      {
        "state": "Bihar",
        "city": "Adhaura"
      },
      {
        "state": "Bihar",
        "city": "Agiaon"
      },
      {
        "state": "Bihar",
        "city": "Akbarpur Barari"
      },
      {
        "state": "Bihar",
        "city": "Akorhi"
      },
      {
        "state": "Bihar",
        "city": "Akorhi Gola"
      },
      {
        "state": "Bihar",
        "city": "Alahdadpur"
      },
      {
        "state": "Bihar",
        "city": "Aliganj"
      },
      {
        "state": "Bihar",
        "city": "Amas"
      },
      {
        "state": "Bihar",
        "city": "Amnour"
      },
      {
        "state": "Bihar",
        "city": "Andar"
      },
      {
        "state": "Bihar",
        "city": "Andhra Tharhi"
      },
      {
        "state": "Bihar",
        "city": "Araria"
      },
      {
        "state": "Bihar",
        "city": "Areraj"
      },
      {
        "state": "Bihar",
        "city": "Ariari"
      },
      {
        "state": "Bihar",
        "city": "Ariyari"
      },
      {
        "state": "Bihar",
        "city": "Arrah"
      },
      {
        "state": "Bihar",
        "city": "Arwal"
      },
      {
        "state": "Bihar",
        "city": "Asharganj"
      },
      {
        "state": "Bihar",
        "city": "Asthawan"
      },
      {
        "state": "Bihar",
        "city": "Athmal Gola"
      },
      {
        "state": "Bihar",
        "city": "Aurangabad"
      },
      {
        "state": "Bihar",
        "city": "Babubarhi"
      },
      {
        "state": "Bihar",
        "city": "Bagaha"
      },
      {
        "state": "Bihar",
        "city": "Bagaha-1"
      },
      {
        "state": "Bihar",
        "city": "Bairgania"
      },
      {
        "state": "Bihar",
        "city": "Baisi"
      },
      {
        "state": "Bihar",
        "city": "Bakhtiyarpur"
      },
      {
        "state": "Bihar",
        "city": "Baniapur"
      },
      {
        "state": "Bihar",
        "city": "Banka"
      },
      {
        "state": "Bihar",
        "city": "Bankatwa"
      },
      {
        "state": "Bihar",
        "city": "Bankey Bazar"
      },
      {
        "state": "Bihar",
        "city": "Banmankhi"
      },
      {
        "state": "Bihar",
        "city": "Bansi Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Barachatti"
      },
      {
        "state": "Bihar",
        "city": "Barahat"
      },
      {
        "state": "Bihar",
        "city": "Barahra"
      },
      {
        "state": "Bihar",
        "city": "Barauni"
      },
      {
        "state": "Bihar",
        "city": "Barbigha"
      },
      {
        "state": "Bihar",
        "city": "Barh"
      },
      {
        "state": "Bihar",
        "city": "Barhat"
      },
      {
        "state": "Bihar",
        "city": "Bariarpur"
      },
      {
        "state": "Bihar",
        "city": "Barun"
      },
      {
        "state": "Bihar",
        "city": "Basopatti"
      },
      {
        "state": "Bihar",
        "city": "Bathani"
      },
      {
        "state": "Bihar",
        "city": "Bathnaha"
      },
      {
        "state": "Bihar",
        "city": "Bausi"
      },
      {
        "state": "Bihar",
        "city": "Bazpatti"
      },
      {
        "state": "Bihar",
        "city": "Begusarai"
      },
      {
        "state": "Bihar",
        "city": "Behea"
      },
      {
        "state": "Bihar",
        "city": "Bela Dih"
      },
      {
        "state": "Bihar",
        "city": "Belchi"
      },
      {
        "state": "Bihar",
        "city": "Beldour"
      },
      {
        "state": "Bihar",
        "city": "Belhar"
      },
      {
        "state": "Bihar",
        "city": "Belsand"
      },
      {
        "state": "Bihar",
        "city": "Belsar"
      },
      {
        "state": "Bihar",
        "city": "Benipatti"
      },
      {
        "state": "Bihar",
        "city": "Benipur"
      },
      {
        "state": "Bihar",
        "city": "Bettiah"
      },
      {
        "state": "Bihar",
        "city": "Bhabua"
      },
      {
        "state": "Bihar",
        "city": "Bhagahar"
      },
      {
        "state": "Bihar",
        "city": "Bhagalpur"
      },
      {
        "state": "Bihar",
        "city": "Bhagwani Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Bhagwanpur Hat"
      },
      {
        "state": "Bihar",
        "city": "Bharhan"
      },
      {
        "state": "Bihar",
        "city": "Bharwara"
      },
      {
        "state": "Bihar",
        "city": "Bhojpur"
      },
      {
        "state": "Bihar",
        "city": "Bibhutipur"
      },
      {
        "state": "Bihar",
        "city": "Bidupur"
      },
      {
        "state": "Bihar",
        "city": "Bihar Sharif"
      },
      {
        "state": "Bihar",
        "city": "Bihariganj"
      },
      {
        "state": "Bihar",
        "city": "Bihpur"
      },
      {
        "state": "Bihar",
        "city": "Bikram"
      },
      {
        "state": "Bihar",
        "city": "Bikramganj"
      },
      {
        "state": "Bihar",
        "city": "Biraul"
      },
      {
        "state": "Bihar",
        "city": "Birnackpur"
      },
      {
        "state": "Bihar",
        "city": "Birpur"
      },
      {
        "state": "Bihar",
        "city": "Bishunpur Dhodhni"
      },
      {
        "state": "Bihar",
        "city": "Bochaha"
      },
      {
        "state": "Bihar",
        "city": "Bodha Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Bodhgaya"
      },
      {
        "state": "Bihar",
        "city": "Bokhra"
      },
      {
        "state": "Bihar",
        "city": "Bounsi"
      },
      {
        "state": "Bihar",
        "city": "Brahiya"
      },
      {
        "state": "Bihar",
        "city": "Buxar"
      },
      {
        "state": "Bihar",
        "city": "Chainpur"
      },
      {
        "state": "Bihar",
        "city": "Chakai"
      },
      {
        "state": "Bihar",
        "city": "Chakia"
      },
      {
        "state": "Bihar",
        "city": "Chamghara"
      },
      {
        "state": "Bihar",
        "city": "Chandadih"
      },
      {
        "state": "Bihar",
        "city": "Chauradano"
      },
      {
        "state": "Bihar",
        "city": "Chausa"
      },
      {
        "state": "Bihar",
        "city": "Chehra Kalan Urf Mohammadpur"
      },
      {
        "state": "Bihar",
        "city": "Chenari"
      },
      {
        "state": "Bihar",
        "city": "Chewara"
      },
      {
        "state": "Bihar",
        "city": "Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Chhatapur"
      },
      {
        "state": "Bihar",
        "city": "Chichmhi"
      },
      {
        "state": "Bihar",
        "city": "Chiraia"
      },
      {
        "state": "Bihar",
        "city": "Choraut"
      },
      {
        "state": "Bihar",
        "city": "Chousa"
      },
      {
        "state": "Bihar",
        "city": "Dalsingh Sarai"
      },
      {
        "state": "Bihar",
        "city": "Danapur"
      },
      {
        "state": "Bihar",
        "city": "Daniawan"
      },
      {
        "state": "Bihar",
        "city": "Darbhanga"
      },
      {
        "state": "Bihar",
        "city": "Daudnagar"
      },
      {
        "state": "Bihar",
        "city": "Dawath"
      },
      {
        "state": "Bihar",
        "city": "Dehri"
      },
      {
        "state": "Bihar",
        "city": "Derbandhe"
      },
      {
        "state": "Bihar",
        "city": "Desari"
      },
      {
        "state": "Bihar",
        "city": "Dhaka"
      },
      {
        "state": "Bihar",
        "city": "Dhanarua"
      },
      {
        "state": "Bihar",
        "city": "Dharhara"
      },
      {
        "state": "Bihar",
        "city": "Dighwara"
      },
      {
        "state": "Bihar",
        "city": "Dinara"
      },
      {
        "state": "Bihar",
        "city": "Dobhi"
      },
      {
        "state": "Bihar",
        "city": "Dulhin Bazar"
      },
      {
        "state": "Bihar",
        "city": "Dumraon"
      },
      {
        "state": "Bihar",
        "city": "Duraundha"
      },
      {
        "state": "Bihar",
        "city": "East Champaran"
      },
      {
        "state": "Bihar",
        "city": "Ekangar Sarai"
      },
      {
        "state": "Bihar",
        "city": "Ekhatha"
      },
      {
        "state": "Bihar",
        "city": "Ekma"
      },
      {
        "state": "Bihar",
        "city": "Faridpur"
      },
      {
        "state": "Bihar",
        "city": "Fatuha"
      },
      {
        "state": "Bihar",
        "city": "Gamharia"
      },
      {
        "state": "Bihar",
        "city": "Garh Nokha"
      },
      {
        "state": "Bihar",
        "city": "Garhani"
      },
      {
        "state": "Bihar",
        "city": "Garkha"
      },
      {
        "state": "Bihar",
        "city": "Gaya"
      },
      {
        "state": "Bihar",
        "city": "Ghoghardiha"
      },
      {
        "state": "Bihar",
        "city": "Ghorasahan"
      },
      {
        "state": "Bihar",
        "city": "Ghoshi"
      },
      {
        "state": "Bihar",
        "city": "Ghoswari"
      },
      {
        "state": "Bihar",
        "city": "Gidhaur"
      },
      {
        "state": "Bihar",
        "city": "Giriak"
      },
      {
        "state": "Bihar",
        "city": "Gobardhanpur Kanap"
      },
      {
        "state": "Bihar",
        "city": "Gobindpur Urf Qanungo Bigha"
      },
      {
        "state": "Bihar",
        "city": "Gopalganj"
      },
      {
        "state": "Bihar",
        "city": "Goradeeh"
      },
      {
        "state": "Bihar",
        "city": "Goraul"
      },
      {
        "state": "Bihar",
        "city": "Goraul Bhagwanpur"
      },
      {
        "state": "Bihar",
        "city": "Guraru"
      },
      {
        "state": "Bihar",
        "city": "Gurua"
      },
      {
        "state": "Bihar",
        "city": "Guthani"
      },
      {
        "state": "Bihar",
        "city": "Hajipur"
      },
      {
        "state": "Bihar",
        "city": "Halsi"
      },
      {
        "state": "Bihar",
        "city": "Harlakhi"
      },
      {
        "state": "Bihar",
        "city": "Harnaut"
      },
      {
        "state": "Bihar",
        "city": "Harshidhi"
      },
      {
        "state": "Bihar",
        "city": "Hasanpur Buzurg"
      },
      {
        "state": "Bihar",
        "city": "Hasanpura"
      },
      {
        "state": "Bihar",
        "city": "Haspura"
      },
      {
        "state": "Bihar",
        "city": "Hathiyawan"
      },
      {
        "state": "Bihar",
        "city": "Hathwa"
      },
      {
        "state": "Bihar",
        "city": "Hazrat Jandaha"
      },
      {
        "state": "Bihar",
        "city": "Hilsa"
      },
      {
        "state": "Bihar",
        "city": "Hisua"
      },
      {
        "state": "Bihar",
        "city": "Hulasganj"
      },
      {
        "state": "Bihar",
        "city": "Hulashganj"
      },
      {
        "state": "Bihar",
        "city": "Imamganj"
      },
      {
        "state": "Bihar",
        "city": "Ishupur"
      },
      {
        "state": "Bihar",
        "city": "Islam Nagar"
      },
      {
        "state": "Bihar",
        "city": "Itarhi"
      },
      {
        "state": "Bihar",
        "city": "Jagdishpur"
      },
      {
        "state": "Bihar",
        "city": "Jalalpur"
      },
      {
        "state": "Bihar",
        "city": "Jamhore"
      },
      {
        "state": "Bihar",
        "city": "Jamui"
      },
      {
        "state": "Bihar",
        "city": "Jaynagar"
      },
      {
        "state": "Bihar",
        "city": "Jehanabad"
      },
      {
        "state": "Bihar",
        "city": "Jhajha"
      },
      {
        "state": "Bihar",
        "city": "Jhanjharpur"
      },
      {
        "state": "Bihar",
        "city": "Kahalgaon"
      },
      {
        "state": "Bihar",
        "city": "Kaimur (Bhabua)"
      },
      {
        "state": "Bihar",
        "city": "Kako"
      },
      {
        "state": "Bihar",
        "city": "Kalaua Kalan"
      },
      {
        "state": "Bihar",
        "city": "Kaler"
      },
      {
        "state": "Bihar",
        "city": "Kaluahi"
      },
      {
        "state": "Bihar",
        "city": "Kan Maniari"
      },
      {
        "state": "Bihar",
        "city": "Kanth Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Karakat"
      },
      {
        "state": "Bihar",
        "city": "Kargahar"
      },
      {
        "state": "Bihar",
        "city": "Karpi"
      },
      {
        "state": "Bihar",
        "city": "Kashi Chak"
      },
      {
        "state": "Bihar",
        "city": "Katihar"
      },
      {
        "state": "Bihar",
        "city": "Katoria"
      },
      {
        "state": "Bihar",
        "city": "Katraul"
      },
      {
        "state": "Bihar",
        "city": "Kawakol"
      },
      {
        "state": "Bihar",
        "city": "Kesariya"
      },
      {
        "state": "Bihar",
        "city": "Kesath"
      },
      {
        "state": "Bihar",
        "city": "Khagaria"
      },
      {
        "state": "Bihar",
        "city": "Khajauli"
      },
      {
        "state": "Bihar",
        "city": "Khanarda"
      },
      {
        "state": "Bihar",
        "city": "Kharagpur"
      },
      {
        "state": "Bihar",
        "city": "Khararahia"
      },
      {
        "state": "Bihar",
        "city": "Kharik"
      },
      {
        "state": "Bihar",
        "city": "Kharka Basant"
      },
      {
        "state": "Bihar",
        "city": "Khiara"
      },
      {
        "state": "Bihar",
        "city": "Khizersarai"
      },
      {
        "state": "Bihar",
        "city": "Khusrupur"
      },
      {
        "state": "Bihar",
        "city": "Khutauna"
      },
      {
        "state": "Bihar",
        "city": "Kiratpur Raja Ram"
      },
      {
        "state": "Bihar",
        "city": "Kishanganj"
      },
      {
        "state": "Bihar",
        "city": "Kochas"
      },
      {
        "state": "Bihar",
        "city": "Koilwar"
      },
      {
        "state": "Bihar",
        "city": "Koriapatti"
      },
      {
        "state": "Bihar",
        "city": "Kuangarhi"
      },
      {
        "state": "Bihar",
        "city": "Kuari Madan"
      },
      {
        "state": "Bihar",
        "city": "Kudra"
      },
      {
        "state": "Bihar",
        "city": "Kumarkhand"
      },
      {
        "state": "Bihar",
        "city": "Kurhani"
      },
      {
        "state": "Bihar",
        "city": "Kursela"
      },
      {
        "state": "Bihar",
        "city": "Kurtha"
      },
      {
        "state": "Bihar",
        "city": "Kutumba"
      },
      {
        "state": "Bihar",
        "city": "Ladania"
      },
      {
        "state": "Bihar",
        "city": "Lahladpur"
      },
      {
        "state": "Bihar",
        "city": "Lahuara"
      },
      {
        "state": "Bihar",
        "city": "Lakat Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Lakhisarai"
      },
      {
        "state": "Bihar",
        "city": "Lalganj"
      },
      {
        "state": "Bihar",
        "city": "Laukaha"
      },
      {
        "state": "Bihar",
        "city": "Laukahi"
      },
      {
        "state": "Bihar",
        "city": "Madan Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Madhepur"
      },
      {
        "state": "Bihar",
        "city": "Madhepura"
      },
      {
        "state": "Bihar",
        "city": "Madhubani"
      },
      {
        "state": "Bihar",
        "city": "Mahendia"
      },
      {
        "state": "Bihar",
        "city": "Mahesia"
      },
      {
        "state": "Bihar",
        "city": "Mahishi"
      },
      {
        "state": "Bihar",
        "city": "Mahnar"
      },
      {
        "state": "Bihar",
        "city": "Maikhand Milik"
      },
      {
        "state": "Bihar",
        "city": "Mairwa"
      },
      {
        "state": "Bihar",
        "city": "Maker"
      },
      {
        "state": "Bihar",
        "city": "Makhdumpur"
      },
      {
        "state": "Bihar",
        "city": "Maner"
      },
      {
        "state": "Bihar",
        "city": "Maniari"
      },
      {
        "state": "Bihar",
        "city": "Manjhi"
      },
      {
        "state": "Bihar",
        "city": "Marhowrah"
      },
      {
        "state": "Bihar",
        "city": "Marouna"
      },
      {
        "state": "Bihar",
        "city": "Marwan"
      },
      {
        "state": "Bihar",
        "city": "Masaurhi"
      },
      {
        "state": "Bihar",
        "city": "Mashrakh"
      },
      {
        "state": "Bihar",
        "city": "Maudiha"
      },
      {
        "state": "Bihar",
        "city": "Mehsi"
      },
      {
        "state": "Bihar",
        "city": "Mehus"
      },
      {
        "state": "Bihar",
        "city": "Meskaur"
      },
      {
        "state": "Bihar",
        "city": "Minapur"
      },
      {
        "state": "Bihar",
        "city": "Misraulia"
      },
      {
        "state": "Bihar",
        "city": "Mohania"
      },
      {
        "state": "Bihar",
        "city": "Mohiuddin Nagar"
      },
      {
        "state": "Bihar",
        "city": "Mokameh Khas"
      },
      {
        "state": "Bihar",
        "city": "Motihari"
      },
      {
        "state": "Bihar",
        "city": "Motipur"
      },
      {
        "state": "Bihar",
        "city": "Munger"
      },
      {
        "state": "Bihar",
        "city": "Muradpur Bharath"
      },
      {
        "state": "Bihar",
        "city": "Murliganj"
      },
      {
        "state": "Bihar",
        "city": "Muzaffarpur"
      },
      {
        "state": "Bihar",
        "city": "Nabiganj"
      },
      {
        "state": "Bihar",
        "city": "Nabinagar"
      },
      {
        "state": "Bihar",
        "city": "Nagar Nausa"
      },
      {
        "state": "Bihar",
        "city": "Najari"
      },
      {
        "state": "Bihar",
        "city": "Nalanda"
      },
      {
        "state": "Bihar",
        "city": "Nanpur"
      },
      {
        "state": "Bihar",
        "city": "Nardiganj"
      },
      {
        "state": "Bihar",
        "city": "Narhat"
      },
      {
        "state": "Bihar",
        "city": "Nasriganj"
      },
      {
        "state": "Bihar",
        "city": "Naubatpur"
      },
      {
        "state": "Bihar",
        "city": "Naugachia"
      },
      {
        "state": "Bihar",
        "city": "Nauhatta"
      },
      {
        "state": "Bihar",
        "city": "Navanagar"
      },
      {
        "state": "Bihar",
        "city": "Nawada"
      },
      {
        "state": "Bihar",
        "city": "Nawada Govindganj"
      },
      {
        "state": "Bihar",
        "city": "Nirmali"
      },
      {
        "state": "Bihar",
        "city": "Noma"
      },
      {
        "state": "Bihar",
        "city": "Noorsarai"
      },
      {
        "state": "Bihar",
        "city": "Nuaon"
      },
      {
        "state": "Bihar",
        "city": "Obra"
      },
      {
        "state": "Bihar",
        "city": "Pachrukhi"
      },
      {
        "state": "Bihar",
        "city": "Pakri"
      },
      {
        "state": "Bihar",
        "city": "Pakribarwan"
      },
      {
        "state": "Bihar",
        "city": "Pakridayal"
      },
      {
        "state": "Bihar",
        "city": "Paliganj"
      },
      {
        "state": "Bihar",
        "city": "Panapur"
      },
      {
        "state": "Bihar",
        "city": "Pandaul"
      },
      {
        "state": "Bihar",
        "city": "Paraiya"
      },
      {
        "state": "Bihar",
        "city": "Parbatta"
      },
      {
        "state": "Bihar",
        "city": "Parihar"
      },
      {
        "state": "Bihar",
        "city": "Paroo"
      },
      {
        "state": "Bihar",
        "city": "Parora"
      },
      {
        "state": "Bihar",
        "city": "Parsa"
      },
      {
        "state": "Bihar",
        "city": "Parsauni"
      },
      {
        "state": "Bihar",
        "city": "Parwalpur"
      },
      {
        "state": "Bihar",
        "city": "Patahi"
      },
      {
        "state": "Bihar",
        "city": "Patarghat"
      },
      {
        "state": "Bihar",
        "city": "Patepur"
      },
      {
        "state": "Bihar",
        "city": "Patna"
      },
      {
        "state": "Bihar",
        "city": "Patori"
      },
      {
        "state": "Bihar",
        "city": "Phenhara"
      },
      {
        "state": "Bihar",
        "city": "Phulparas"
      },
      {
        "state": "Bihar",
        "city": "Phulwari"
      },
      {
        "state": "Bihar",
        "city": "Pipra Kothi"
      },
      {
        "state": "Bihar",
        "city": "Piprahi"
      },
      {
        "state": "Bihar",
        "city": "Piro"
      },
      {
        "state": "Bihar",
        "city": "Pirpainti"
      },
      {
        "state": "Bihar",
        "city": "Pota urf Tajpur Phaksia Bharthi"
      },
      {
        "state": "Bihar",
        "city": "Pratapganj"
      },
      {
        "state": "Bihar",
        "city": "Pundarakh"
      },
      {
        "state": "Bihar",
        "city": "Pupri"
      },
      {
        "state": "Bihar",
        "city": "Puraini"
      },
      {
        "state": "Bihar",
        "city": "Purnea"
      },
      {
        "state": "Bihar",
        "city": "Rafiganj"
      },
      {
        "state": "Bihar",
        "city": "Raghopur"
      },
      {
        "state": "Bihar",
        "city": "Rahui"
      },
      {
        "state": "Bihar",
        "city": "Rajapakar"
      },
      {
        "state": "Bihar",
        "city": "Rajauli"
      },
      {
        "state": "Bihar",
        "city": "Rajaun"
      },
      {
        "state": "Bihar",
        "city": "Rajgir"
      },
      {
        "state": "Bihar",
        "city": "Rajnagar"
      },
      {
        "state": "Bihar",
        "city": "Rajoun"
      },
      {
        "state": "Bihar",
        "city": "Ramgarhwa"
      },
      {
        "state": "Bihar",
        "city": "Rangra"
      },
      {
        "state": "Bihar",
        "city": "Ratni"
      },
      {
        "state": "Bihar",
        "city": "Ratnifaridpur"
      },
      {
        "state": "Bihar",
        "city": "Raunakabad"
      },
      {
        "state": "Bihar",
        "city": "Raxaul"
      },
      {
        "state": "Bihar",
        "city": "Revelganj"
      },
      {
        "state": "Bihar",
        "city": "Riga"
      },
      {
        "state": "Bihar",
        "city": "Rohtas"
      },
      {
        "state": "Bihar",
        "city": "Rosera"
      },
      {
        "state": "Bihar",
        "city": "Runi Saidpur"
      },
      {
        "state": "Bihar",
        "city": "Rupauli"
      },
      {
        "state": "Bihar",
        "city": "Rupauli Chausima"
      },
      {
        "state": "Bihar",
        "city": "Sadar Madhubani"
      },
      {
        "state": "Bihar",
        "city": "Sadikpur Moraul"
      },
      {
        "state": "Bihar",
        "city": "Saharsa"
      },
      {
        "state": "Bihar",
        "city": "Sahdai Buzurg"
      },
      {
        "state": "Bihar",
        "city": "Sahebganj"
      },
      {
        "state": "Bihar",
        "city": "Sahsaul"
      },
      {
        "state": "Bihar",
        "city": "Saidpur Salha Barari"
      },
      {
        "state": "Bihar",
        "city": "Salkhua"
      },
      {
        "state": "Bihar",
        "city": "Samastipur"
      },
      {
        "state": "Bihar",
        "city": "Samho"
      },
      {
        "state": "Bihar",
        "city": "Sandesh"
      },
      {
        "state": "Bihar",
        "city": "Sangrampur"
      },
      {
        "state": "Bihar",
        "city": "Sanjhauli"
      },
      {
        "state": "Bihar",
        "city": "Saraigarh"
      },
      {
        "state": "Bihar",
        "city": "Saraiya"
      },
      {
        "state": "Bihar",
        "city": "Saran"
      },
      {
        "state": "Bihar",
        "city": "Sarmera"
      },
      {
        "state": "Bihar",
        "city": "Sasaram"
      },
      {
        "state": "Bihar",
        "city": "Satar Kataiya"
      },
      {
        "state": "Bihar",
        "city": "Saur Bazar"
      },
      {
        "state": "Bihar",
        "city": "Sembhu Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Shambhuganj"
      },
      {
        "state": "Bihar",
        "city": "Sheikhpura"
      },
      {
        "state": "Bihar",
        "city": "Shekhopur"
      },
      {
        "state": "Bihar",
        "city": "Shekhwa Tola"
      },
      {
        "state": "Bihar",
        "city": "Shekupur Sarai"
      },
      {
        "state": "Bihar",
        "city": "Sheohar"
      },
      {
        "state": "Bihar",
        "city": "Sherghati"
      },
      {
        "state": "Bihar",
        "city": "Sihpur"
      },
      {
        "state": "Bihar",
        "city": "Silao"
      },
      {
        "state": "Bihar",
        "city": "Simariya"
      },
      {
        "state": "Bihar",
        "city": "Simri"
      },
      {
        "state": "Bihar",
        "city": "Simri Bakhtiarpur"
      },
      {
        "state": "Bihar",
        "city": "Singheshwar"
      },
      {
        "state": "Bihar",
        "city": "Singhwara"
      },
      {
        "state": "Bihar",
        "city": "Sirdala"
      },
      {
        "state": "Bihar",
        "city": "Sitamarhi"
      },
      {
        "state": "Bihar",
        "city": "Siwan"
      },
      {
        "state": "Bihar",
        "city": "Sobhan Chhapra"
      },
      {
        "state": "Bihar",
        "city": "Sonahula"
      },
      {
        "state": "Bihar",
        "city": "Sonbarsa"
      },
      {
        "state": "Bihar",
        "city": "Sondiha"
      },
      {
        "state": "Bihar",
        "city": "Sugauli"
      },
      {
        "state": "Bihar",
        "city": "Sugia"
      },
      {
        "state": "Bihar",
        "city": "Sultanganj"
      },
      {
        "state": "Bihar",
        "city": "Supaul"
      },
      {
        "state": "Bihar",
        "city": "Surahatha Dharampur"
      },
      {
        "state": "Bihar",
        "city": "Surajgarha"
      },
      {
        "state": "Bihar",
        "city": "Sursand"
      },
      {
        "state": "Bihar",
        "city": "Taraiya"
      },
      {
        "state": "Bihar",
        "city": "Tarari"
      },
      {
        "state": "Bihar",
        "city": "Teghra"
      },
      {
        "state": "Bihar",
        "city": "Tekari"
      },
      {
        "state": "Bihar",
        "city": "Tekniwas"
      },
      {
        "state": "Bihar",
        "city": "Telhara"
      },
      {
        "state": "Bihar",
        "city": "Teoth"
      },
      {
        "state": "Bihar",
        "city": "Tetaria"
      },
      {
        "state": "Bihar",
        "city": "Tharthari"
      },
      {
        "state": "Bihar",
        "city": "Tikari"
      },
      {
        "state": "Bihar",
        "city": "Tilauthu"
      },
      {
        "state": "Bihar",
        "city": "Triveniganj"
      },
      {
        "state": "Bihar",
        "city": "Turkaulia"
      },
      {
        "state": "Bihar",
        "city": "Udakishanganj"
      },
      {
        "state": "Bihar",
        "city": "Vaishali"
      },
      {
        "state": "Bihar",
        "city": "Warisaliganj"
      },
      {
        "state": "Bihar",
        "city": "Ziradei"
      },
      {
        "state": "Bihar",
        "city": "shilowri"
      },
      {
        "state": "Chandigarh",
        "city": "Chandigarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Abhanpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Adbhar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Akaltara"
      },
      {
        "state": "Chhattisgarh",
        "city": "Amatoli"
      },
      {
        "state": "Chhattisgarh",
        "city": "Ambagarh Chowki"
      },
      {
        "state": "Chhattisgarh",
        "city": "Ambikapur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Antagarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Awari"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bagbahara"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bagicha"
      },
      {
        "state": "Chhattisgarh",
        "city": "Baikunth"
      },
      {
        "state": "Chhattisgarh",
        "city": "Baikunthpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Balconagar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Balod"
      },
      {
        "state": "Chhattisgarh",
        "city": "Baloda Bazar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Balrampur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bamhni Bazar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bango"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bankimongra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Baradwar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Barauda"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bastar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Belgahna"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bemetera"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhairamgarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhaisma"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhaiyathan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhanupratappur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhilai"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bhopalpatnam"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bijapur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bilaigarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bilaspur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bilha"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bindranawagarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Birgaon"
      },
      {
        "state": "Chhattisgarh",
        "city": "Birra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Bsna"
      },
      {
        "state": "Chhattisgarh",
        "city": "Champa"
      },
      {
        "state": "Chhattisgarh",
        "city": "Charama"
      },
      {
        "state": "Chhattisgarh",
        "city": "Chhuikhadan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Chhura"
      },
      {
        "state": "Chhattisgarh",
        "city": "Chhuriya"
      },
      {
        "state": "Chhattisgarh",
        "city": "Chirimiri"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dabhara"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dantewada"
      },
      {
        "state": "Chhattisgarh",
        "city": "Deobhog"
      },
      {
        "state": "Chhattisgarh",
        "city": "Deori"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dhamdha"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dhamtari"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dharmajaigarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dharsiwa"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dondi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dondi Awari"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dondi Lohara"
      },
      {
        "state": "Chhattisgarh",
        "city": "Dongargaon"
      },
      {
        "state": "Chhattisgarh",
        "city": "Durg"
      },
      {
        "state": "Chhattisgarh",
        "city": "Fingeshwar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gandai Pandaria"
      },
      {
        "state": "Chhattisgarh",
        "city": "Ganiyari"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gariaband"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gaurella"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gharghoda"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gopalnagar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Gunderdehi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Hardi Bazar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Hathbandh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Hathibahra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Hirmi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Hirri Mines"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jagdalpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jaijaipur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jairamnagar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jamnipali"
      },
      {
        "state": "Chhattisgarh",
        "city": "Janakpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Janjgir"
      },
      {
        "state": "Chhattisgarh",
        "city": "Janjgir-Champa"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jashpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Jashpurnagar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kamta"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kanker"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kargi Road"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kasdol"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kaskera"
      },
      {
        "state": "Chhattisgarh",
        "city": "Katghora"
      },
      {
        "state": "Chhattisgarh",
        "city": "Katgi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kawardha"
      },
      {
        "state": "Chhattisgarh",
        "city": "Keshkal"
      },
      {
        "state": "Chhattisgarh",
        "city": "Keskal"
      },
      {
        "state": "Chhattisgarh",
        "city": "Khairagarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kharod"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kharora"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kharsia"
      },
      {
        "state": "Chhattisgarh",
        "city": "Komakhan of Mahasa"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kondagaon"
      },
      {
        "state": "Chhattisgarh",
        "city": "Konta"
      },
      {
        "state": "Chhattisgarh",
        "city": "Korba"
      },
      {
        "state": "Chhattisgarh",
        "city": "Koriya"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kosanga"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kumarra Chhuriya"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kunkuri"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kurud"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kusmi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Kusmunda"
      },
      {
        "state": "Chhattisgarh",
        "city": "Lakhanpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Lawan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Lormi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Mahasamund"
      },
      {
        "state": "Chhattisgarh",
        "city": "Malkharoda"
      },
      {
        "state": "Chhattisgarh",
        "city": "Mana Camp"
      },
      {
        "state": "Chhattisgarh",
        "city": "Manddar Cement Factory"
      },
      {
        "state": "Chhattisgarh",
        "city": "Mandir Hasod"
      },
      {
        "state": "Chhattisgarh",
        "city": "Manendragarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Manikpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Marwahi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Masturi"
      },
      {
        "state": "Chhattisgarh",
        "city": "Mohala"
      },
      {
        "state": "Chhattisgarh",
        "city": "Mungeli"
      },
      {
        "state": "Chhattisgarh",
        "city": "Nagri"
      },
      {
        "state": "Chhattisgarh",
        "city": "Nandghat"
      },
      {
        "state": "Chhattisgarh",
        "city": "Narayanpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Nariyara"
      },
      {
        "state": "Chhattisgarh",
        "city": "Narra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Nawagarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Naya Baradwar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Neora"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pakhanjore"
      },
      {
        "state": "Chhattisgarh",
        "city": "Palari"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pamgarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pandaria"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pandewar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Panduka"
      },
      {
        "state": "Chhattisgarh",
        "city": "Parsada"
      },
      {
        "state": "Chhattisgarh",
        "city": "Patah"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pathalgaon"
      },
      {
        "state": "Chhattisgarh",
        "city": "Patharia"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pendra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Phingeshwar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pithora"
      },
      {
        "state": "Chhattisgarh",
        "city": "Pratppur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Raigarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Raipur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Rajim"
      },
      {
        "state": "Chhattisgarh",
        "city": "Rajnandgaon"
      },
      {
        "state": "Chhattisgarh",
        "city": "Ramanuj Ganj"
      },
      {
        "state": "Chhattisgarh",
        "city": "Ratanpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Rawan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Rudri"
      },
      {
        "state": "Chhattisgarh",
        "city": "Saja"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sakti"
      },
      {
        "state": "Chhattisgarh",
        "city": "Salhebhata"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sanjaribalod"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sankra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Saraipali"
      },
      {
        "state": "Chhattisgarh",
        "city": "Saria"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sarnagarh"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sarsiwa"
      },
      {
        "state": "Chhattisgarh",
        "city": "Shivrinarayan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Simga"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sipat"
      },
      {
        "state": "Chhattisgarh",
        "city": "Sukma"
      },
      {
        "state": "Chhattisgarh",
        "city": "Surajpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Surguja"
      },
      {
        "state": "Chhattisgarh",
        "city": "Takhatpur"
      },
      {
        "state": "Chhattisgarh",
        "city": "Tamnar"
      },
      {
        "state": "Chhattisgarh",
        "city": "Tilda Newra"
      },
      {
        "state": "Chhattisgarh",
        "city": "Waidhan"
      },
      {
        "state": "Chhattisgarh",
        "city": "Watgan"
      },
      {
        "state": "Dadra And Nagar Haveli",
        "city": "Dadra and Nagar Haveli"
      },
      {
        "state": "Dadra And Nagar Haveli",
        "city": "Dharampur"
      },
      {
        "state": "Dadra And Nagar Haveli",
        "city": "Killa-pardi"
      },
      {
        "state": "Dadra And Nagar Haveli",
        "city": "Silvassa"
      },
      {
        "state": "Daman And Diu",
        "city": "Daman"
      },
      {
        "state": "Daman And Diu",
        "city": "Diu"
      },
      {
        "state": "Delhi",
        "city": "Central Delhi"
      },
      {
        "state": "Delhi",
        "city": "East Delhi"
      },
      {
        "state": "Delhi",
        "city": "New Delhi"
      },
      {
        "state": "Delhi",
        "city": "North Delhi"
      },
      {
        "state": "Delhi",
        "city": "North East Delhi"
      },
      {
        "state": "Delhi",
        "city": "North West Delhi"
      },
      {
        "state": "Delhi",
        "city": "Shahdara"
      },
      {
        "state": "Delhi",
        "city": "South Delhi"
      },
      {
        "state": "Delhi",
        "city": "South East Delhi"
      },
      {
        "state": "Delhi",
        "city": "South West Delhi"
      },
      {
        "state": "Delhi",
        "city": "West Delhi"
      },
      {
        "state": "Goa",
        "city": "Aradi Socorro"
      },
      {
        "state": "Goa",
        "city": "Bardez"
      },
      {
        "state": "Goa",
        "city": "Bicholim"
      },
      {
        "state": "Goa",
        "city": "Canacona"
      },
      {
        "state": "Goa",
        "city": "Curchorem"
      },
      {
        "state": "Goa",
        "city": "Mapusa"
      },
      {
        "state": "Goa",
        "city": "Margao"
      },
      {
        "state": "Goa",
        "city": "Mormugao"
      },
      {
        "state": "Goa",
        "city": "Old Goa"
      },
      {
        "state": "Goa",
        "city": "Panjim"
      },
      {
        "state": "Goa",
        "city": "Pernem"
      },
      {
        "state": "Goa",
        "city": "Ponda"
      },
      {
        "state": "Goa",
        "city": "Quepem"
      },
      {
        "state": "Goa",
        "city": "Salcete"
      },
      {
        "state": "Goa",
        "city": "Salvador do Mundo"
      },
      {
        "state": "Goa",
        "city": "Sanguem"
      },
      {
        "state": "Goa",
        "city": "Sanquelim"
      },
      {
        "state": "Goa",
        "city": "Satari"
      },
      {
        "state": "Goa",
        "city": "Tiswadi"
      },
      {
        "state": "Goa",
        "city": "Vasco Da Gama"
      },
      {
        "state": "Goa",
        "city": "Vodlemol Cacora"
      },
      {
        "state": "Gujarat",
        "city": "Abdasan"
      },
      {
        "state": "Gujarat",
        "city": "Ahmedabad"
      },
      {
        "state": "Gujarat",
        "city": "Ahwa"
      },
      {
        "state": "Gujarat",
        "city": "Ajabpura"
      },
      {
        "state": "Gujarat",
        "city": "Ambaji"
      },
      {
        "state": "Gujarat",
        "city": "Amirgadh"
      },
      {
        "state": "Gujarat",
        "city": "Amod"
      },
      {
        "state": "Gujarat",
        "city": "Amreli"
      },
      {
        "state": "Gujarat",
        "city": "Anand"
      },
      {
        "state": "Gujarat",
        "city": "Anjar"
      },
      {
        "state": "Gujarat",
        "city": "Anklav"
      },
      {
        "state": "Gujarat",
        "city": "Ankleshwar"
      },
      {
        "state": "Gujarat",
        "city": "Aravalli"
      },
      {
        "state": "Gujarat",
        "city": "Babra"
      },
      {
        "state": "Gujarat",
        "city": "Babri"
      },
      {
        "state": "Gujarat",
        "city": "Bagasara"
      },
      {
        "state": "Gujarat",
        "city": "Bahecharpura"
      },
      {
        "state": "Gujarat",
        "city": "Bakod"
      },
      {
        "state": "Gujarat",
        "city": "Balasinor"
      },
      {
        "state": "Gujarat",
        "city": "Banaskantha"
      },
      {
        "state": "Gujarat",
        "city": "Bansda"
      },
      {
        "state": "Gujarat",
        "city": "Bardoli"
      },
      {
        "state": "Gujarat",
        "city": "Barwala"
      },
      {
        "state": "Gujarat",
        "city": "Bavla"
      },
      {
        "state": "Gujarat",
        "city": "Bayad"
      },
      {
        "state": "Gujarat",
        "city": "Becharaji"
      },
      {
        "state": "Gujarat",
        "city": "Bhabhar"
      },
      {
        "state": "Gujarat",
        "city": "Bhachau"
      },
      {
        "state": "Gujarat",
        "city": "Bhanvad"
      },
      {
        "state": "Gujarat",
        "city": "Bharuch"
      },
      {
        "state": "Gujarat",
        "city": "Bhatiya"
      },
      {
        "state": "Gujarat",
        "city": "Bhavnagar"
      },
      {
        "state": "Gujarat",
        "city": "Bhesan"
      },
      {
        "state": "Gujarat",
        "city": "Bhiloda"
      },
      {
        "state": "Gujarat",
        "city": "Bhuj"
      },
      {
        "state": "Gujarat",
        "city": "Bilimora"
      },
      {
        "state": "Gujarat",
        "city": "Birpur"
      },
      {
        "state": "Gujarat",
        "city": "Bodeli"
      },
      {
        "state": "Gujarat",
        "city": "Borsad"
      },
      {
        "state": "Gujarat",
        "city": "Botad"
      },
      {
        "state": "Gujarat",
        "city": "Chanasma"
      },
      {
        "state": "Gujarat",
        "city": "Chhota Udepur"
      },
      {
        "state": "Gujarat",
        "city": "Chikhli"
      },
      {
        "state": "Gujarat",
        "city": "Choryasi"
      },
      {
        "state": "Gujarat",
        "city": "Chotila"
      },
      {
        "state": "Gujarat",
        "city": "Dabhoi"
      },
      {
        "state": "Gujarat",
        "city": "Dahegam"
      },
      {
        "state": "Gujarat",
        "city": "Dahej"
      },
      {
        "state": "Gujarat",
        "city": "Dahisara"
      },
      {
        "state": "Gujarat",
        "city": "Dahod"
      },
      {
        "state": "Gujarat",
        "city": "Dakor"
      },
      {
        "state": "Gujarat",
        "city": "Dangs (Ahwa)"
      },
      {
        "state": "Gujarat",
        "city": "Danta"
      },
      {
        "state": "Gujarat",
        "city": "Dantiwada"
      },
      {
        "state": "Gujarat",
        "city": "Dasada"
      },
      {
        "state": "Gujarat",
        "city": "Daskroi"
      },
      {
        "state": "Gujarat",
        "city": "Dediapada"
      },
      {
        "state": "Gujarat",
        "city": "Deesa"
      },
      {
        "state": "Gujarat",
        "city": "Demai"
      },
      {
        "state": "Gujarat",
        "city": "Deodar"
      },
      {
        "state": "Gujarat",
        "city": "Detroj"
      },
      {
        "state": "Gujarat",
        "city": "Devbhoomi Dwarka"
      },
      {
        "state": "Gujarat",
        "city": "Devgadh Baria"
      },
      {
        "state": "Gujarat",
        "city": "Dhandhuka"
      },
      {
        "state": "Gujarat",
        "city": "Dhanera"
      },
      {
        "state": "Gujarat",
        "city": "Dhanpur"
      },
      {
        "state": "Gujarat",
        "city": "Dhansura"
      },
      {
        "state": "Gujarat",
        "city": "Dharampur"
      },
      {
        "state": "Gujarat",
        "city": "Dhari"
      },
      {
        "state": "Gujarat",
        "city": "Dharoi"
      },
      {
        "state": "Gujarat",
        "city": "Dhasa"
      },
      {
        "state": "Gujarat",
        "city": "Dholka"
      },
      {
        "state": "Gujarat",
        "city": "Dhoraji"
      },
      {
        "state": "Gujarat",
        "city": "Dhrangadhra"
      },
      {
        "state": "Gujarat",
        "city": "Dhrol"
      },
      {
        "state": "Gujarat",
        "city": "Disa"
      },
      {
        "state": "Gujarat",
        "city": "Diyodar"
      },
      {
        "state": "Gujarat",
        "city": "Dohad"
      },
      {
        "state": "Gujarat",
        "city": "Dungri"
      },
      {
        "state": "Gujarat",
        "city": "Fatepura"
      },
      {
        "state": "Gujarat",
        "city": "Gadhada"
      },
      {
        "state": "Gujarat",
        "city": "Gandevi"
      },
      {
        "state": "Gujarat",
        "city": "Gandhidham"
      },
      {
        "state": "Gujarat",
        "city": "Gandhinagar"
      },
      {
        "state": "Gujarat",
        "city": "Garbada"
      },
      {
        "state": "Gujarat",
        "city": "Gariyadhar"
      },
      {
        "state": "Gujarat",
        "city": "Ghogha"
      },
      {
        "state": "Gujarat",
        "city": "Ghoghamba"
      },
      {
        "state": "Gujarat",
        "city": "Gir Somnath"
      },
      {
        "state": "Gujarat",
        "city": "Godhra"
      },
      {
        "state": "Gujarat",
        "city": "Gondal"
      },
      {
        "state": "Gujarat",
        "city": "Halol"
      },
      {
        "state": "Gujarat",
        "city": "Halvad"
      },
      {
        "state": "Gujarat",
        "city": "Hansot"
      },
      {
        "state": "Gujarat",
        "city": "Hazira"
      },
      {
        "state": "Gujarat",
        "city": "Himatnagar"
      },
      {
        "state": "Gujarat",
        "city": "Idar"
      },
      {
        "state": "Gujarat",
        "city": "Jafrabad"
      },
      {
        "state": "Gujarat",
        "city": "Jahlod"
      },
      {
        "state": "Gujarat",
        "city": "Jalalpore"
      },
      {
        "state": "Gujarat",
        "city": "Jambughoda"
      },
      {
        "state": "Gujarat",
        "city": "Jambusar"
      },
      {
        "state": "Gujarat",
        "city": "Jamjodhpur"
      },
      {
        "state": "Gujarat",
        "city": "Jamkalyanpur"
      },
      {
        "state": "Gujarat",
        "city": "Jamkandorana"
      },
      {
        "state": "Gujarat",
        "city": "Jamnagar"
      },
      {
        "state": "Gujarat",
        "city": "Jasdan"
      },
      {
        "state": "Gujarat",
        "city": "Jesar"
      },
      {
        "state": "Gujarat",
        "city": "Jetholi"
      },
      {
        "state": "Gujarat",
        "city": "Jetpur"
      },
      {
        "state": "Gujarat",
        "city": "Jetpur Navagadh"
      },
      {
        "state": "Gujarat",
        "city": "Jhagadia"
      },
      {
        "state": "Gujarat",
        "city": "Jhalod"
      },
      {
        "state": "Gujarat",
        "city": "Jodiya"
      },
      {
        "state": "Gujarat",
        "city": "Junagadh"
      },
      {
        "state": "Gujarat",
        "city": "Kachchh"
      },
      {
        "state": "Gujarat",
        "city": "Kadana"
      },
      {
        "state": "Gujarat",
        "city": "Kadi"
      },
      {
        "state": "Gujarat",
        "city": "Kahbhalia"
      },
      {
        "state": "Gujarat",
        "city": "Kalavad"
      },
      {
        "state": "Gujarat",
        "city": "Kalawad Simani"
      },
      {
        "state": "Gujarat",
        "city": "Kalol"
      },
      {
        "state": "Gujarat",
        "city": "Kalyanpur"
      },
      {
        "state": "Gujarat",
        "city": "Kamar Kotda"
      },
      {
        "state": "Gujarat",
        "city": "Kamrej"
      },
      {
        "state": "Gujarat",
        "city": "Kandla"
      },
      {
        "state": "Gujarat",
        "city": "Kapadvanj"
      },
      {
        "state": "Gujarat",
        "city": "Kaprada"
      },
      {
        "state": "Gujarat",
        "city": "Karjan"
      },
      {
        "state": "Gujarat",
        "city": "Kathlal"
      },
      {
        "state": "Gujarat",
        "city": "Kavant"
      },
      {
        "state": "Gujarat",
        "city": "Keshod"
      },
      {
        "state": "Gujarat",
        "city": "Khajurada"
      },
      {
        "state": "Gujarat",
        "city": "Khambha"
      },
      {
        "state": "Gujarat",
        "city": "Khambhalia"
      },
      {
        "state": "Gujarat",
        "city": "Khambhat"
      },
      {
        "state": "Gujarat",
        "city": "Khanpur"
      },
      {
        "state": "Gujarat",
        "city": "Kheda"
      },
      {
        "state": "Gujarat",
        "city": "Khedbrahma"
      },
      {
        "state": "Gujarat",
        "city": "Kheralu"
      },
      {
        "state": "Gujarat",
        "city": "Khirasara"
      },
      {
        "state": "Gujarat",
        "city": "Killa-pardi"
      },
      {
        "state": "Gujarat",
        "city": "Kodinar"
      },
      {
        "state": "Gujarat",
        "city": "Kotda Sangani"
      },
      {
        "state": "Gujarat",
        "city": "Kothara"
      },
      {
        "state": "Gujarat",
        "city": "Kutch"
      },
      {
        "state": "Gujarat",
        "city": "Kutiyana"
      },
      {
        "state": "Gujarat",
        "city": "Lajpur"
      },
      {
        "state": "Gujarat",
        "city": "Lakhatar"
      },
      {
        "state": "Gujarat",
        "city": "Lakhpat"
      },
      {
        "state": "Gujarat",
        "city": "Lalpur"
      },
      {
        "state": "Gujarat",
        "city": "Lathi"
      },
      {
        "state": "Gujarat",
        "city": "Liliya"
      },
      {
        "state": "Gujarat",
        "city": "Limbdi"
      },
      {
        "state": "Gujarat",
        "city": "Limkheda"
      },
      {
        "state": "Gujarat",
        "city": "Lodhika"
      },
      {
        "state": "Gujarat",
        "city": "Lunawada"
      },
      {
        "state": "Gujarat",
        "city": "Mahisagar"
      },
      {
        "state": "Gujarat",
        "city": "Mahudha"
      },
      {
        "state": "Gujarat",
        "city": "Mahuva"
      },
      {
        "state": "Gujarat",
        "city": "Makrej"
      },
      {
        "state": "Gujarat",
        "city": "Malia"
      },
      {
        "state": "Gujarat",
        "city": "Malpur"
      },
      {
        "state": "Gujarat",
        "city": "Manavadar"
      },
      {
        "state": "Gujarat",
        "city": "Mandal"
      },
      {
        "state": "Gujarat",
        "city": "Mandvi"
      },
      {
        "state": "Gujarat",
        "city": "Mandvi Rural"
      },
      {
        "state": "Gujarat",
        "city": "Mangrol"
      },
      {
        "state": "Gujarat",
        "city": "Matar"
      },
      {
        "state": "Gujarat",
        "city": "Meghraj"
      },
      {
        "state": "Gujarat",
        "city": "Mehmedabad"
      },
      {
        "state": "Gujarat",
        "city": "Mehsana"
      },
      {
        "state": "Gujarat",
        "city": "Mendarda"
      },
      {
        "state": "Gujarat",
        "city": "Mewada"
      },
      {
        "state": "Gujarat",
        "city": "Modasa"
      },
      {
        "state": "Gujarat",
        "city": "Morana"
      },
      {
        "state": "Gujarat",
        "city": "Morbi"
      },
      {
        "state": "Gujarat",
        "city": "Morva Hadaf"
      },
      {
        "state": "Gujarat",
        "city": "Morvi"
      },
      {
        "state": "Gujarat",
        "city": "Mota Dahisara"
      },
      {
        "state": "Gujarat",
        "city": "Mota Miya Mangrol"
      },
      {
        "state": "Gujarat",
        "city": "Muli"
      },
      {
        "state": "Gujarat",
        "city": "Mundra"
      },
      {
        "state": "Gujarat",
        "city": "Nadiad"
      },
      {
        "state": "Gujarat",
        "city": "Nakhatrana"
      },
      {
        "state": "Gujarat",
        "city": "Naliya"
      },
      {
        "state": "Gujarat",
        "city": "Nandod"
      },
      {
        "state": "Gujarat",
        "city": "Nar"
      },
      {
        "state": "Gujarat",
        "city": "Naranpar"
      },
      {
        "state": "Gujarat",
        "city": "Narmada"
      },
      {
        "state": "Gujarat",
        "city": "Narmada (Rajpipla)"
      },
      {
        "state": "Gujarat",
        "city": "Nasvadi"
      },
      {
        "state": "Gujarat",
        "city": "Navapara"
      },
      {
        "state": "Gujarat",
        "city": "Navsari"
      },
      {
        "state": "Gujarat",
        "city": "Nijhar"
      },
      {
        "state": "Gujarat",
        "city": "Nizar"
      },
      {
        "state": "Gujarat",
        "city": "Okha"
      },
      {
        "state": "Gujarat",
        "city": "Okhamandal"
      },
      {
        "state": "Gujarat",
        "city": "Olpad"
      },
      {
        "state": "Gujarat",
        "city": "Padadhari"
      },
      {
        "state": "Gujarat",
        "city": "Padra"
      },
      {
        "state": "Gujarat",
        "city": "Palanpur"
      },
      {
        "state": "Gujarat",
        "city": "Palitana"
      },
      {
        "state": "Gujarat",
        "city": "Palsana"
      },
      {
        "state": "Gujarat",
        "city": "Panch Mahudi"
      },
      {
        "state": "Gujarat",
        "city": "Panchmahal (Godhra)"
      },
      {
        "state": "Gujarat",
        "city": "Pardi"
      },
      {
        "state": "Gujarat",
        "city": "Patan"
      },
      {
        "state": "Gujarat",
        "city": "Patdi"
      },
      {
        "state": "Gujarat",
        "city": "Pavijetpur"
      },
      {
        "state": "Gujarat",
        "city": "Petlad"
      },
      {
        "state": "Gujarat",
        "city": "Porbandar"
      },
      {
        "state": "Gujarat",
        "city": "Prantij"
      },
      {
        "state": "Gujarat",
        "city": "Pratappara"
      },
      {
        "state": "Gujarat",
        "city": "Radhanpur"
      },
      {
        "state": "Gujarat",
        "city": "Rajkot"
      },
      {
        "state": "Gujarat",
        "city": "Rajpipla"
      },
      {
        "state": "Gujarat",
        "city": "Rajula"
      },
      {
        "state": "Gujarat",
        "city": "Ranavav"
      },
      {
        "state": "Gujarat",
        "city": "Ranpur"
      },
      {
        "state": "Gujarat",
        "city": "Rapar"
      },
      {
        "state": "Gujarat",
        "city": "Rusela"
      },
      {
        "state": "Gujarat",
        "city": "Sabarkantha (Himmatnagar)"
      },
      {
        "state": "Gujarat",
        "city": "Sagbara"
      },
      {
        "state": "Gujarat",
        "city": "Sami"
      },
      {
        "state": "Gujarat",
        "city": "Sanand"
      },
      {
        "state": "Gujarat",
        "city": "Sankheda"
      },
      {
        "state": "Gujarat",
        "city": "Santalpur"
      },
      {
        "state": "Gujarat",
        "city": "Santrampur"
      },
      {
        "state": "Gujarat",
        "city": "Sardhar"
      },
      {
        "state": "Gujarat",
        "city": "Sathamba"
      },
      {
        "state": "Gujarat",
        "city": "Satlasana"
      },
      {
        "state": "Gujarat",
        "city": "Satsan"
      },
      {
        "state": "Gujarat",
        "city": "Savar Kundla"
      },
      {
        "state": "Gujarat",
        "city": "Savli"
      },
      {
        "state": "Gujarat",
        "city": "Sayan"
      },
      {
        "state": "Gujarat",
        "city": "Sayla"
      },
      {
        "state": "Gujarat",
        "city": "Shehera"
      },
      {
        "state": "Gujarat",
        "city": "Shihori"
      },
      {
        "state": "Gujarat",
        "city": "Shri Amirgadh"
      },
      {
        "state": "Gujarat",
        "city": "Siddhpur"
      },
      {
        "state": "Gujarat",
        "city": "Sihor"
      },
      {
        "state": "Gujarat",
        "city": "Sinor"
      },
      {
        "state": "Gujarat",
        "city": "Sojitra"
      },
      {
        "state": "Gujarat",
        "city": "Songadh"
      },
      {
        "state": "Gujarat",
        "city": "Surat"
      },
      {
        "state": "Gujarat",
        "city": "Surendranagar"
      },
      {
        "state": "Gujarat",
        "city": "Sutrapada"
      },
      {
        "state": "Gujarat",
        "city": "Talaja"
      },
      {
        "state": "Gujarat",
        "city": "Talala"
      },
      {
        "state": "Gujarat",
        "city": "Talod"
      },
      {
        "state": "Gujarat",
        "city": "Tankara"
      },
      {
        "state": "Gujarat",
        "city": "Tapi"
      },
      {
        "state": "Gujarat",
        "city": "Tarapur"
      },
      {
        "state": "Gujarat",
        "city": "Tharad"
      },
      {
        "state": "Gujarat",
        "city": "Thasra"
      },
      {
        "state": "Gujarat",
        "city": "Tilakvada"
      },
      {
        "state": "Gujarat",
        "city": "Uchchhal"
      },
      {
        "state": "Gujarat",
        "city": "Udhna"
      },
      {
        "state": "Gujarat",
        "city": "Umargam"
      },
      {
        "state": "Gujarat",
        "city": "Umarpada"
      },
      {
        "state": "Gujarat",
        "city": "Umbergaon"
      },
      {
        "state": "Gujarat",
        "city": "Umrala"
      },
      {
        "state": "Gujarat",
        "city": "Umreth"
      },
      {
        "state": "Gujarat",
        "city": "Unjha"
      },
      {
        "state": "Gujarat",
        "city": "Unreth"
      },
      {
        "state": "Gujarat",
        "city": "Upleta"
      },
      {
        "state": "Gujarat",
        "city": "Vadagam"
      },
      {
        "state": "Gujarat",
        "city": "Vadal"
      },
      {
        "state": "Gujarat",
        "city": "Vadali"
      },
      {
        "state": "Gujarat",
        "city": "Vadgam"
      },
      {
        "state": "Gujarat",
        "city": "Vadnagar"
      },
      {
        "state": "Gujarat",
        "city": "Vadodara"
      },
      {
        "state": "Gujarat",
        "city": "Vagara"
      },
      {
        "state": "Gujarat",
        "city": "Vaghodia"
      },
      {
        "state": "Gujarat",
        "city": "Vaghodia INA"
      },
      {
        "state": "Gujarat",
        "city": "Valia"
      },
      {
        "state": "Gujarat",
        "city": "Vallabhipur"
      },
      {
        "state": "Gujarat",
        "city": "Valod"
      },
      {
        "state": "Gujarat",
        "city": "Valsad"
      },
      {
        "state": "Gujarat",
        "city": "Vankal"
      },
      {
        "state": "Gujarat",
        "city": "Vansda"
      },
      {
        "state": "Gujarat",
        "city": "Vanthli"
      },
      {
        "state": "Gujarat",
        "city": "Vapi"
      },
      {
        "state": "Gujarat",
        "city": "Variav"
      },
      {
        "state": "Gujarat",
        "city": "Vav"
      },
      {
        "state": "Gujarat",
        "city": "Velachha"
      },
      {
        "state": "Gujarat",
        "city": "Veraval"
      },
      {
        "state": "Gujarat",
        "city": "Vijapur"
      },
      {
        "state": "Gujarat",
        "city": "Vijaynagar"
      },
      {
        "state": "Gujarat",
        "city": "Viramgam"
      },
      {
        "state": "Gujarat",
        "city": "Virpur"
      },
      {
        "state": "Gujarat",
        "city": "Visavadar"
      },
      {
        "state": "Gujarat",
        "city": "Visnagar"
      },
      {
        "state": "Gujarat",
        "city": "Vyara"
      },
      {
        "state": "Gujarat",
        "city": "Wankaner"
      },
      {
        "state": "Gujarat",
        "city": "Zankhvav"
      },
      {
        "state": "Haryana",
        "city": "Achina"
      },
      {
        "state": "Haryana",
        "city": "Ahrod"
      },
      {
        "state": "Haryana",
        "city": "Akbarpur"
      },
      {
        "state": "Haryana",
        "city": "Akoda"
      },
      {
        "state": "Haryana",
        "city": "Ambala"
      },
      {
        "state": "Haryana",
        "city": "Arjun Nagar"
      },
      {
        "state": "Haryana",
        "city": "Assandh"
      },
      {
        "state": "Haryana",
        "city": "Ateli 2"
      },
      {
        "state": "Haryana",
        "city": "Babain"
      },
      {
        "state": "Haryana",
        "city": "Badhra"
      },
      {
        "state": "Haryana",
        "city": "Badshahpur"
      },
      {
        "state": "Haryana",
        "city": "Baghot"
      },
      {
        "state": "Haryana",
        "city": "Baghthala"
      },
      {
        "state": "Haryana",
        "city": "Bahadurgarh"
      },
      {
        "state": "Haryana",
        "city": "Bairawas"
      },
      {
        "state": "Haryana",
        "city": "Ballabgarh"
      },
      {
        "state": "Haryana",
        "city": "Banipur"
      },
      {
        "state": "Haryana",
        "city": "Bara Gudha"
      },
      {
        "state": "Haryana",
        "city": "Barara"
      },
      {
        "state": "Haryana",
        "city": "Barda"
      },
      {
        "state": "Haryana",
        "city": "Barkoda"
      },
      {
        "state": "Haryana",
        "city": "Bas Dooda"
      },
      {
        "state": "Haryana",
        "city": "Basai"
      },
      {
        "state": "Haryana",
        "city": "Basbatori"
      },
      {
        "state": "Haryana",
        "city": "Basduda"
      },
      {
        "state": "Haryana",
        "city": "Bashirpur"
      },
      {
        "state": "Haryana",
        "city": "Bassai"
      },
      {
        "state": "Haryana",
        "city": "Baund Kalan"
      },
      {
        "state": "Haryana",
        "city": "Bawal"
      },
      {
        "state": "Haryana",
        "city": "Bawana"
      },
      {
        "state": "Haryana",
        "city": "Bawani Khera"
      },
      {
        "state": "Haryana",
        "city": "Bawania"
      },
      {
        "state": "Haryana",
        "city": "Bawwa"
      },
      {
        "state": "Haryana",
        "city": "Bayal"
      },
      {
        "state": "Haryana",
        "city": "Behali"
      },
      {
        "state": "Haryana",
        "city": "Beri"
      },
      {
        "state": "Haryana",
        "city": "Beri Dopana"
      },
      {
        "state": "Haryana",
        "city": "Bewal"
      },
      {
        "state": "Haryana",
        "city": "Bhainswal Kalan"
      },
      {
        "state": "Haryana",
        "city": "Bhakli"
      },
      {
        "state": "Haryana",
        "city": "Bharaf"
      },
      {
        "state": "Haryana",
        "city": "Bhatsana"
      },
      {
        "state": "Haryana",
        "city": "Bhaungerka"
      },
      {
        "state": "Haryana",
        "city": "Bhiwani"
      },
      {
        "state": "Haryana",
        "city": "Bhojawas"
      },
      {
        "state": "Haryana",
        "city": "Bhondsi"
      },
      {
        "state": "Haryana",
        "city": "Bhorakalan"
      },
      {
        "state": "Haryana",
        "city": "Bhotwas Ahir"
      },
      {
        "state": "Haryana",
        "city": "Bhungarka"
      },
      {
        "state": "Haryana",
        "city": "Bhushan Kalan"
      },
      {
        "state": "Haryana",
        "city": "Biran"
      },
      {
        "state": "Haryana",
        "city": "Bisowa"
      },
      {
        "state": "Haryana",
        "city": "Bithwana"
      },
      {
        "state": "Haryana",
        "city": "Boria Kamalpur"
      },
      {
        "state": "Haryana",
        "city": "Buddin"
      },
      {
        "state": "Haryana",
        "city": "Budhpur"
      },
      {
        "state": "Haryana",
        "city": "Budhwa"
      },
      {
        "state": "Haryana",
        "city": "Buroli"
      },
      {
        "state": "Haryana",
        "city": "Charkhi Dadri"
      },
      {
        "state": "Haryana",
        "city": "Cheeta Dungra"
      },
      {
        "state": "Haryana",
        "city": "Chhachhrauli"
      },
      {
        "state": "Haryana",
        "city": "Chillro"
      },
      {
        "state": "Haryana",
        "city": "Chitrauli"
      },
      {
        "state": "Haryana",
        "city": "Dabwali"
      },
      {
        "state": "Haryana",
        "city": "Dadri"
      },
      {
        "state": "Haryana",
        "city": "Dahina"
      },
      {
        "state": "Haryana",
        "city": "Dakhora"
      },
      {
        "state": "Haryana",
        "city": "Dalanwas"
      },
      {
        "state": "Haryana",
        "city": "Dammunpur Bara"
      },
      {
        "state": "Haryana",
        "city": "Daroli"
      },
      {
        "state": "Haryana",
        "city": "Dehlawas"
      },
      {
        "state": "Haryana",
        "city": "Deroli Ahir"
      },
      {
        "state": "Haryana",
        "city": "Dhadhot"
      },
      {
        "state": "Haryana",
        "city": "Dhador"
      },
      {
        "state": "Haryana",
        "city": "Dhana"
      },
      {
        "state": "Haryana",
        "city": "Dhanaunda"
      },
      {
        "state": "Haryana",
        "city": "Dhani Kolana"
      },
      {
        "state": "Haryana",
        "city": "Dhanonda"
      },
      {
        "state": "Haryana",
        "city": "Dhanota"
      },
      {
        "state": "Haryana",
        "city": "Dharan"
      },
      {
        "state": "Haryana",
        "city": "Dharson"
      },
      {
        "state": "Haryana",
        "city": "Dharuhera"
      },
      {
        "state": "Haryana",
        "city": "Dhawana"
      },
      {
        "state": "Haryana",
        "city": "Dholera"
      },
      {
        "state": "Haryana",
        "city": "Dhor Kalan"
      },
      {
        "state": "Haryana",
        "city": "Didoli"
      },
      {
        "state": "Haryana",
        "city": "Digrota"
      },
      {
        "state": "Haryana",
        "city": "Dochana"
      },
      {
        "state": "Haryana",
        "city": "Dongra Ahir"
      },
      {
        "state": "Haryana",
        "city": "Dulana"
      },
      {
        "state": "Haryana",
        "city": "Duloth Ahir"
      },
      {
        "state": "Haryana",
        "city": "Dunderwas"
      },
      {
        "state": "Haryana",
        "city": "Faridabad"
      },
      {
        "state": "Haryana",
        "city": "Farrukh Nagar"
      },
      {
        "state": "Haryana",
        "city": "Fatehabad"
      },
      {
        "state": "Haryana",
        "city": "Firozpur Jhirka"
      },
      {
        "state": "Haryana",
        "city": "Ganiar"
      },
      {
        "state": "Haryana",
        "city": "Gannaur"
      },
      {
        "state": "Haryana",
        "city": "Garhi Alawalpur"
      },
      {
        "state": "Haryana",
        "city": "Garhi Bolni"
      },
      {
        "state": "Haryana",
        "city": "Garhi Harsaru"
      },
      {
        "state": "Haryana",
        "city": "Gharaunda"
      },
      {
        "state": "Haryana",
        "city": "Gohana"
      },
      {
        "state": "Haryana",
        "city": "Gokal Pur"
      },
      {
        "state": "Haryana",
        "city": "Gokalgarh"
      },
      {
        "state": "Haryana",
        "city": "Golwa"
      },
      {
        "state": "Haryana",
        "city": "Gothra"
      },
      {
        "state": "Haryana",
        "city": "Gudha"
      },
      {
        "state": "Haryana",
        "city": "Gudiyani"
      },
      {
        "state": "Haryana",
        "city": "Gugodh"
      },
      {
        "state": "Haryana",
        "city": "Guhla"
      },
      {
        "state": "Haryana",
        "city": "Gujar Majra"
      },
      {
        "state": "Haryana",
        "city": "Gujarwas"
      },
      {
        "state": "Haryana",
        "city": "Guraora"
      },
      {
        "state": "Haryana",
        "city": "Gurgaon Rural"
      },
      {
        "state": "Haryana",
        "city": "Gurha"
      },
      {
        "state": "Haryana",
        "city": "Guriani"
      },
      {
        "state": "Haryana",
        "city": "Gurugram"
      },
      {
        "state": "Haryana",
        "city": "Haillymandi"
      },
      {
        "state": "Haryana",
        "city": "Hamidpur"
      },
      {
        "state": "Haryana",
        "city": "Hansi"
      },
      {
        "state": "Haryana",
        "city": "Hathin"
      },
      {
        "state": "Haryana",
        "city": "Hisar"
      },
      {
        "state": "Haryana",
        "city": "Hodal"
      },
      {
        "state": "Haryana",
        "city": "Hodal Rural"
      },
      {
        "state": "Haryana",
        "city": "Hudina"
      },
      {
        "state": "Haryana",
        "city": "Indri"
      },
      {
        "state": "Haryana",
        "city": "Ismaila"
      },
      {
        "state": "Haryana",
        "city": "Jagadhri"
      },
      {
        "state": "Haryana",
        "city": "Jai Singhpur Khera"
      },
      {
        "state": "Haryana",
        "city": "Jakhala"
      },
      {
        "state": "Haryana",
        "city": "Jakhauli"
      },
      {
        "state": "Haryana",
        "city": "Jano"
      },
      {
        "state": "Haryana",
        "city": "Jarwa"
      },
      {
        "state": "Haryana",
        "city": "Jatusana"
      },
      {
        "state": "Haryana",
        "city": "Jhabuwa"
      },
      {
        "state": "Haryana",
        "city": "Jhagroli"
      },
      {
        "state": "Haryana",
        "city": "Jhajjar"
      },
      {
        "state": "Haryana",
        "city": "Jhal"
      },
      {
        "state": "Haryana",
        "city": "Jhamri"
      },
      {
        "state": "Haryana",
        "city": "Jhanswa"
      },
      {
        "state": "Haryana",
        "city": "Jharli"
      },
      {
        "state": "Haryana",
        "city": "Jharoda"
      },
      {
        "state": "Haryana",
        "city": "Jholri"
      },
      {
        "state": "Haryana",
        "city": "Jhook"
      },
      {
        "state": "Haryana",
        "city": "Jind"
      },
      {
        "state": "Haryana",
        "city": "Jitpur Istamrar"
      },
      {
        "state": "Haryana",
        "city": "Jondhi"
      },
      {
        "state": "Haryana",
        "city": "Juddi"
      },
      {
        "state": "Haryana",
        "city": "Jundla"
      },
      {
        "state": "Haryana",
        "city": "Kaithal"
      },
      {
        "state": "Haryana",
        "city": "Kakrola"
      },
      {
        "state": "Haryana",
        "city": "Kalanwali"
      },
      {
        "state": "Haryana",
        "city": "Kalayat"
      },
      {
        "state": "Haryana",
        "city": "Kalka"
      },
      {
        "state": "Haryana",
        "city": "Kamania"
      },
      {
        "state": "Haryana",
        "city": "Kanina"
      },
      {
        "state": "Haryana",
        "city": "Kannaura"
      },
      {
        "state": "Haryana",
        "city": "Kanti"
      },
      {
        "state": "Haryana",
        "city": "Kanwali"
      },
      {
        "state": "Haryana",
        "city": "Kanwi"
      },
      {
        "state": "Haryana",
        "city": "Karawara Manakpur"
      },
      {
        "state": "Haryana",
        "city": "Karnal"
      },
      {
        "state": "Haryana",
        "city": "Karnawas"
      },
      {
        "state": "Haryana",
        "city": "Karoli"
      },
      {
        "state": "Haryana",
        "city": "Karota"
      },
      {
        "state": "Haryana",
        "city": "Khaleta"
      },
      {
        "state": "Haryana",
        "city": "Khaliawas"
      },
      {
        "state": "Haryana",
        "city": "Khalilpur"
      },
      {
        "state": "Haryana",
        "city": "Khandora"
      },
      {
        "state": "Haryana",
        "city": "Kharkhoda"
      },
      {
        "state": "Haryana",
        "city": "Khatod"
      },
      {
        "state": "Haryana",
        "city": "Kheri"
      },
      {
        "state": "Haryana",
        "city": "Khol"
      },
      {
        "state": "Haryana",
        "city": "Khor"
      },
      {
        "state": "Haryana",
        "city": "Khori"
      },
      {
        "state": "Haryana",
        "city": "Khudana"
      },
      {
        "state": "Haryana",
        "city": "Kohrar"
      },
      {
        "state": "Haryana",
        "city": "Kolana"
      },
      {
        "state": "Haryana",
        "city": "Koriawas"
      },
      {
        "state": "Haryana",
        "city": "Kosli"
      },
      {
        "state": "Haryana",
        "city": "Kothal Khurd"
      },
      {
        "state": "Haryana",
        "city": "Kotia"
      },
      {
        "state": "Haryana",
        "city": "Krishan Nagar"
      },
      {
        "state": "Haryana",
        "city": "Kultajpur"
      },
      {
        "state": "Haryana",
        "city": "Kund"
      },
      {
        "state": "Haryana",
        "city": "Kunjpura"
      },
      {
        "state": "Haryana",
        "city": "Kurukshetra"
      },
      {
        "state": "Haryana",
        "city": "Laad"
      },
      {
        "state": "Haryana",
        "city": "Ladwa"
      },
      {
        "state": "Haryana",
        "city": "Lawan"
      },
      {
        "state": "Haryana",
        "city": "Lilodh"
      },
      {
        "state": "Haryana",
        "city": "Lisan"
      },
      {
        "state": "Haryana",
        "city": "Loharu"
      },
      {
        "state": "Haryana",
        "city": "Lookhi"
      },
      {
        "state": "Haryana",
        "city": "Madana Kalan"
      },
      {
        "state": "Haryana",
        "city": "Madhogarh"
      },
      {
        "state": "Haryana",
        "city": "Maduban"
      },
      {
        "state": "Haryana",
        "city": "Mahendragarh"
      },
      {
        "state": "Haryana",
        "city": "Maheshwari"
      },
      {
        "state": "Haryana",
        "city": "Majra Gurdas"
      },
      {
        "state": "Haryana",
        "city": "Majra Sheoraj"
      },
      {
        "state": "Haryana",
        "city": "Mamaria Ahir"
      },
      {
        "state": "Haryana",
        "city": "Mandi Dabwali"
      },
      {
        "state": "Haryana",
        "city": "Mandola"
      },
      {
        "state": "Haryana",
        "city": "Manesar"
      },
      {
        "state": "Haryana",
        "city": "Mangali"
      },
      {
        "state": "Haryana",
        "city": "Masani"
      },
      {
        "state": "Haryana",
        "city": "Mastapur"
      },
      {
        "state": "Haryana",
        "city": "Mayan"
      },
      {
        "state": "Haryana",
        "city": "Meham"
      },
      {
        "state": "Haryana",
        "city": "Mewat"
      },
      {
        "state": "Haryana",
        "city": "Mohanpur"
      },
      {
        "state": "Haryana",
        "city": "Mohindergarh"
      },
      {
        "state": "Haryana",
        "city": "Mundhal"
      },
      {
        "state": "Haryana",
        "city": "Mundi"
      },
      {
        "state": "Haryana",
        "city": "Mundlana"
      },
      {
        "state": "Haryana",
        "city": "Musepur"
      },
      {
        "state": "Haryana",
        "city": "Nagal Jamalpur"
      },
      {
        "state": "Haryana",
        "city": "Nagal Katha"
      },
      {
        "state": "Haryana",
        "city": "Nagina"
      },
      {
        "state": "Haryana",
        "city": "Nahar"
      },
      {
        "state": "Haryana",
        "city": "Nahri"
      },
      {
        "state": "Haryana",
        "city": "Naichana"
      },
      {
        "state": "Haryana",
        "city": "Naigawan"
      },
      {
        "state": "Haryana",
        "city": "Nandrampur Bass"
      },
      {
        "state": "Haryana",
        "city": "Nangal Dargu"
      },
      {
        "state": "Haryana",
        "city": "Nangal Jamalpur"
      },
      {
        "state": "Haryana",
        "city": "Nangal Katha"
      },
      {
        "state": "Haryana",
        "city": "Nangal Mala"
      },
      {
        "state": "Haryana",
        "city": "Nangal Pathani"
      },
      {
        "state": "Haryana",
        "city": "Nangal Sirohi"
      },
      {
        "state": "Haryana",
        "city": "Nangal Teju"
      },
      {
        "state": "Haryana",
        "city": "Nangli Godha"
      },
      {
        "state": "Haryana",
        "city": "Nangli Godha Village"
      },
      {
        "state": "Haryana",
        "city": "Nanukalan"
      },
      {
        "state": "Haryana",
        "city": "Naraingarh"
      },
      {
        "state": "Haryana",
        "city": "Narnaul"
      },
      {
        "state": "Haryana",
        "city": "Narwana"
      },
      {
        "state": "Haryana",
        "city": "Nautana"
      },
      {
        "state": "Haryana",
        "city": "Nawan"
      },
      {
        "state": "Haryana",
        "city": "Neerpur"
      },
      {
        "state": "Haryana",
        "city": "Nehrugarh"
      },
      {
        "state": "Haryana",
        "city": "Nehrugarh-N"
      },
      {
        "state": "Haryana",
        "city": "Niamatpur"
      },
      {
        "state": "Haryana",
        "city": "Nigdhu"
      },
      {
        "state": "Haryana",
        "city": "Nihalawas"
      },
      {
        "state": "Haryana",
        "city": "Nilokheri"
      },
      {
        "state": "Haryana",
        "city": "Nimbi"
      },
      {
        "state": "Haryana",
        "city": "Nimoth"
      },
      {
        "state": "Haryana",
        "city": "Nissing"
      },
      {
        "state": "Haryana",
        "city": "Nizampur"
      },
      {
        "state": "Haryana",
        "city": "Nuh"
      },
      {
        "state": "Haryana",
        "city": "Nuni Awal"
      },
      {
        "state": "Haryana",
        "city": "Odhan"
      },
      {
        "state": "Haryana",
        "city": "Pabnera"
      },
      {
        "state": "Haryana",
        "city": "Palam Road"
      },
      {
        "state": "Haryana",
        "city": "Palam Vihar"
      },
      {
        "state": "Haryana",
        "city": "Palhawas"
      },
      {
        "state": "Haryana",
        "city": "Palripanihara"
      },
      {
        "state": "Haryana",
        "city": "Palwal"
      },
      {
        "state": "Haryana",
        "city": "Panchkula"
      },
      {
        "state": "Haryana",
        "city": "Panipat"
      },
      {
        "state": "Haryana",
        "city": "Pataudi"
      },
      {
        "state": "Haryana",
        "city": "Pathera"
      },
      {
        "state": "Haryana",
        "city": "Pehowa"
      },
      {
        "state": "Haryana",
        "city": "Pinjore"
      },
      {
        "state": "Haryana",
        "city": "Pota"
      },
      {
        "state": "Haryana",
        "city": "Pranpura"
      },
      {
        "state": "Haryana",
        "city": "Pundri"
      },
      {
        "state": "Haryana",
        "city": "Punhana"
      },
      {
        "state": "Haryana",
        "city": "Punsika"
      },
      {
        "state": "Haryana",
        "city": "Purkhas"
      },
      {
        "state": "Haryana",
        "city": "Rajaund"
      },
      {
        "state": "Haryana",
        "city": "Rajound"
      },
      {
        "state": "Haryana",
        "city": "Raliawas"
      },
      {
        "state": "Haryana",
        "city": "Rambas"
      },
      {
        "state": "Haryana",
        "city": "Rampuri"
      },
      {
        "state": "Haryana",
        "city": "Rania"
      },
      {
        "state": "Haryana",
        "city": "Rasoolpur"
      },
      {
        "state": "Haryana",
        "city": "Rathanthal"
      },
      {
        "state": "Haryana",
        "city": "Ratia"
      },
      {
        "state": "Haryana",
        "city": "Rattanthal"
      },
      {
        "state": "Haryana",
        "city": "Rattia"
      },
      {
        "state": "Haryana",
        "city": "Rewari"
      },
      {
        "state": "Haryana",
        "city": "Rohrai"
      },
      {
        "state": "Haryana",
        "city": "Rohtak"
      },
      {
        "state": "Haryana",
        "city": "Sadat Nagar"
      },
      {
        "state": "Haryana",
        "city": "Safidon"
      },
      {
        "state": "Haryana",
        "city": "Saharanwas"
      },
      {
        "state": "Haryana",
        "city": "Sahibzada Ajit Singh Nagar"
      },
      {
        "state": "Haryana",
        "city": "Sangwari"
      },
      {
        "state": "Haryana",
        "city": "Sankhol"
      },
      {
        "state": "Haryana",
        "city": "Sareli"
      },
      {
        "state": "Haryana",
        "city": "Satnali"
      },
      {
        "state": "Haryana",
        "city": "Sehlang"
      },
      {
        "state": "Haryana",
        "city": "Sehma"
      },
      {
        "state": "Haryana",
        "city": "Shadat Nagar -N"
      },
      {
        "state": "Haryana",
        "city": "Shahabad"
      },
      {
        "state": "Haryana",
        "city": "Shahbaad Markanda"
      },
      {
        "state": "Haryana",
        "city": "Shahpur"
      },
      {
        "state": "Haryana",
        "city": "Shayam Nagar"
      },
      {
        "state": "Haryana",
        "city": "Shivaji Nagar"
      },
      {
        "state": "Haryana",
        "city": "Shobhapur"
      },
      {
        "state": "Haryana",
        "city": "Shyampura"
      },
      {
        "state": "Haryana",
        "city": "Siana"
      },
      {
        "state": "Haryana",
        "city": "Siha"
      },
      {
        "state": "Haryana",
        "city": "Sihore"
      },
      {
        "state": "Haryana",
        "city": "Sirohi Bahali"
      },
      {
        "state": "Haryana",
        "city": "Sirsa"
      },
      {
        "state": "Haryana",
        "city": "Siwani"
      },
      {
        "state": "Haryana",
        "city": "Sohna"
      },
      {
        "state": "Haryana",
        "city": "Sonipat"
      },
      {
        "state": "Haryana",
        "city": "Sulkha"
      },
      {
        "state": "Haryana",
        "city": "Sundrah"
      },
      {
        "state": "Haryana",
        "city": "Surethi"
      },
      {
        "state": "Haryana",
        "city": "Suthana"
      },
      {
        "state": "Haryana",
        "city": "Tajpur"
      },
      {
        "state": "Haryana",
        "city": "Tankri"
      },
      {
        "state": "Haryana",
        "city": "Tauru"
      },
      {
        "state": "Haryana",
        "city": "Thanesar"
      },
      {
        "state": "Haryana",
        "city": "Thanwas"
      },
      {
        "state": "Haryana",
        "city": "Tint"
      },
      {
        "state": "Haryana",
        "city": "Tohana"
      },
      {
        "state": "Haryana",
        "city": "Tosham"
      },
      {
        "state": "Haryana",
        "city": "Tosham Rural"
      },
      {
        "state": "Haryana",
        "city": "Tumna"
      },
      {
        "state": "Haryana",
        "city": "Yamuna Nagar"
      },
      {
        "state": "Haryana",
        "city": "Zainabad"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Amb"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Anni"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Arki"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Aut"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bahrmour"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Baijnath"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Balah"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Baldwara"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bali Chowki"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Baloh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Ban Khandi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bangana"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Banjar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Baroh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Barsar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Barwi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Beri"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bhalai"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bhaoranj"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bharmour"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bharwain"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bhatehr"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bhatiyat"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bhoranj"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bijhri"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Bilaspur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Budhan Upper"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Chachyot"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Chamba"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Charna"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Chhatar Khas"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Chirgaon"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Chopal"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Churah"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dalhousie"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dangoh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Daulatpur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dehra Gopipur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dhaliara"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dhamla"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dhar Chhochhodun"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dharampur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dharamshala"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Diyara"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dodra Kawar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Dolra"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Ghumarwin"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Hamirpur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Hangrang"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Harchakian"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Haroli"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Holi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Indora"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Jachh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Jaisinghpur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Jawali"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Jhanduta"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Joginder Nagar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Jubbal"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Junga"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kala Amb"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kalpa"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kamand"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kandaghat"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kangra"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Karsog"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kasauli"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Khundian"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kinnaur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kotkhai"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kotli"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kullu"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Kumarsain"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Lad Bharol"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Lahaul and Spiti"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Lahul"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Manali"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Mandi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Mehre"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Moorang"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nadaun"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Naggar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nahan"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Naina Devi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nalagarh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nankhari"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nichar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nihri"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nirmand"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nohra"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Nurpur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Pachhad"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Padhar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Pajhota"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Palampur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Pandoh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Pangi"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Paonta Sahib"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Pooh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Rakkar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Rampur Bushahr"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Ramshehar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Reckong Peo"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Reserve Jangal Rakkar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Rohru"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sadar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sadoh"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sainj"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Salooni"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sandhol"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sangla"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sangrah"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sarahan Khurd"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sarkaghat"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Shahpur"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Shillai"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Shimla"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sihunta"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sirmaur (Sirmour)"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Slooni"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Solan"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Spiti"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sujanpur Tira"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Sundernagar"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Suni"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Tahliwal"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Theog"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Thunag"
      },
      {
        "state": "Himachal Pradesh",
        "city": "Una"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "A K Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Abhama"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Achabal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Achan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Aharbal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Akhnoor"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Akura"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Anantnag"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Arath"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Aripal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Aripanthan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Awantipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Awenura"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Badran"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Badyar Balla"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bagh Mehtab"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Baigund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bandipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bandipore"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bani"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Banihal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Baramulla"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Barsoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Basholi"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Batagund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bathnoor"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bathri"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Batpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Batrara"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Beerwah"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Behi Bagh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bhaderwah"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bijbehara"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Billawar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bindoo Zalan Gam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Birpur"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bishnah"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Bouli"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Buchoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Budgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Chadoora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Chattargam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Chenani"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Chimar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Damhal Hanji Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Demidulla"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Dialgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Doda"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Dooru"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Drairi"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Dras"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Frisal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ganderbal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gandoh Bhalessa"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gool Gulab Garh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gopal Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gran Bayotran"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gulmarg"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Gurez"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Halsidar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Handwara"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Hardudalwan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Haripora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Hiranagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Hunder"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Hutmarah"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Hyderpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ichgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Jammu"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Jawahar Nagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Jawalapora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kachachakote"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kachwari"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kahleel"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kalakot"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kalarooch"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kangan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kanidagan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kanihama"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kaprin"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Karan Nagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kargil"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Karipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kathi Darwaza"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kathua"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Katrasoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Katyara"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Khalsi"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Khanabal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kharonti"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Khigogipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Khrew"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Khull"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kishtwar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kokernag"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kragsoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Krem Shora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kukarnag"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kulgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kullan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kund Bo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Kupwara"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ladakh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Lalgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Laram Jangi Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Larnoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Later Chadoora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Leh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Len"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Linglish"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Lorgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Lurgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Magam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Mahore"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Mandoora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Manjmoh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Manzgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Martand"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Mattan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Midroo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Midru"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nagam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nambal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nandi Marg"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nandpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nanil"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nasnoor"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Natipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nehama"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nissu"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nobra"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Noonmai"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Noushera"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nowgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Nunmai"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ompora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pahalgam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pakherpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pampore"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pantha Chowk"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Panzeth"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Parimpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pastana"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pattan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Poonch"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Pulwama"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Qamar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Qanmar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Qazigund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "R H Hall"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Rainawari"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Raithan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Rajouri"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ramban"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ramnagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ranbir Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Ranbir Singh Pura"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Rangar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Reasi"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Reban Gund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Reban Gund Bahram"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "S Bala"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "S R Gunj"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sadinare"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sagam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Saibugh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Saimoh"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Samba"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Samuh Kheti"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sanat Nagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sangam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sankoo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sathu"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Satura"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Shajroo"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sholi Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sholipora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Shopian"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Shopin"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sogam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sonawari"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sopore"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sorore"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Srinagar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sumbal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Sunderbani"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Suraryan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tajoor"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tangdhar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tangmarg"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tarigam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tarigam Devsar"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Thathri"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Tral"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Udhampur"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Uri"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Verinag"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Wangat"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Wangund"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Wanpora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Watakala"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Watergam"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Watrihaal"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Wussan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Wuyan"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Y.K. Pora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Yaripora"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Zainakote"
      },
      {
        "state": "Jammu and Kashmir",
        "city": "Zanskar"
      },
      {
        "state": "Jharkhand",
        "city": "Akashi"
      },
      {
        "state": "Jharkhand",
        "city": "Amda"
      },
      {
        "state": "Jharkhand",
        "city": "Amrapara"
      },
      {
        "state": "Jharkhand",
        "city": "Amwar"
      },
      {
        "state": "Jharkhand",
        "city": "Angara"
      },
      {
        "state": "Jharkhand",
        "city": "Azadnagar"
      },
      {
        "state": "Jharkhand",
        "city": "Bachra"
      },
      {
        "state": "Jharkhand",
        "city": "Bagnocha"
      },
      {
        "state": "Jharkhand",
        "city": "Bagodar"
      },
      {
        "state": "Jharkhand",
        "city": "Baharagora"
      },
      {
        "state": "Jharkhand",
        "city": "Bakaspura"
      },
      {
        "state": "Jharkhand",
        "city": "Balumath"
      },
      {
        "state": "Jharkhand",
        "city": "Bandgaon"
      },
      {
        "state": "Jharkhand",
        "city": "Bano"
      },
      {
        "state": "Jharkhand",
        "city": "Bara Amda"
      },
      {
        "state": "Jharkhand",
        "city": "Barajamda"
      },
      {
        "state": "Jharkhand",
        "city": "Barhait"
      },
      {
        "state": "Jharkhand",
        "city": "Barharwa"
      },
      {
        "state": "Jharkhand",
        "city": "Barhi"
      },
      {
        "state": "Jharkhand",
        "city": "Barkagaon"
      },
      {
        "state": "Jharkhand",
        "city": "Barkhatha"
      },
      {
        "state": "Jharkhand",
        "city": "Barwadih"
      },
      {
        "state": "Jharkhand",
        "city": "Basia"
      },
      {
        "state": "Jharkhand",
        "city": "Bengabad"
      },
      {
        "state": "Jharkhand",
        "city": "Beniadih"
      },
      {
        "state": "Jharkhand",
        "city": "Berhait"
      },
      {
        "state": "Jharkhand",
        "city": "Bermo"
      },
      {
        "state": "Jharkhand",
        "city": "Bero"
      },
      {
        "state": "Jharkhand",
        "city": "Bhandra"
      },
      {
        "state": "Jharkhand",
        "city": "Bharno"
      },
      {
        "state": "Jharkhand",
        "city": "Bhawanathpur"
      },
      {
        "state": "Jharkhand",
        "city": "Birni"
      },
      {
        "state": "Jharkhand",
        "city": "Bishungarh"
      },
      {
        "state": "Jharkhand",
        "city": "Bishunpur"
      },
      {
        "state": "Jharkhand",
        "city": "Bisrampur"
      },
      {
        "state": "Jharkhand",
        "city": "Bnarayanpur"
      },
      {
        "state": "Jharkhand",
        "city": "Bokaro"
      },
      {
        "state": "Jharkhand",
        "city": "Bokaro Steel City"
      },
      {
        "state": "Jharkhand",
        "city": "Bolba"
      },
      {
        "state": "Jharkhand",
        "city": "Borrio"
      },
      {
        "state": "Jharkhand",
        "city": "Bundu"
      },
      {
        "state": "Jharkhand",
        "city": "Burmu"
      },
      {
        "state": "Jharkhand",
        "city": "Chaibasa"
      },
      {
        "state": "Jharkhand",
        "city": "Chainpur"
      },
      {
        "state": "Jharkhand",
        "city": "Chakradharpur"
      },
      {
        "state": "Jharkhand",
        "city": "Chakulia"
      },
      {
        "state": "Jharkhand",
        "city": "Chandil"
      },
      {
        "state": "Jharkhand",
        "city": "Chandwa"
      },
      {
        "state": "Jharkhand",
        "city": "Chandwara"
      },
      {
        "state": "Jharkhand",
        "city": "Chanho"
      },
      {
        "state": "Jharkhand",
        "city": "Chas"
      },
      {
        "state": "Jharkhand",
        "city": "Chatra"
      },
      {
        "state": "Jharkhand",
        "city": "Chawlibasa"
      },
      {
        "state": "Jharkhand",
        "city": "Chhota Gobindpur"
      },
      {
        "state": "Jharkhand",
        "city": "Chinpur"
      },
      {
        "state": "Jharkhand",
        "city": "Chiria"
      },
      {
        "state": "Jharkhand",
        "city": "Chitarpur"
      },
      {
        "state": "Jharkhand",
        "city": "Chopa"
      },
      {
        "state": "Jharkhand",
        "city": "Chouparan"
      },
      {
        "state": "Jharkhand",
        "city": "Chowka"
      },
      {
        "state": "Jharkhand",
        "city": "Churchu"
      },
      {
        "state": "Jharkhand",
        "city": "Dakra"
      },
      {
        "state": "Jharkhand",
        "city": "Daltonganj"
      },
      {
        "state": "Jharkhand",
        "city": "Damkarabarwa"
      },
      {
        "state": "Jharkhand",
        "city": "Dangoaposi"
      },
      {
        "state": "Jharkhand",
        "city": "Debipur"
      },
      {
        "state": "Jharkhand",
        "city": "Deoghar"
      },
      {
        "state": "Jharkhand",
        "city": "Deori"
      },
      {
        "state": "Jharkhand",
        "city": "Devipur"
      },
      {
        "state": "Jharkhand",
        "city": "Dhalbhumgarh"
      },
      {
        "state": "Jharkhand",
        "city": "Dhanbad"
      },
      {
        "state": "Jharkhand",
        "city": "Dibipur"
      },
      {
        "state": "Jharkhand",
        "city": "Djanwar"
      },
      {
        "state": "Jharkhand",
        "city": "Doghar"
      },
      {
        "state": "Jharkhand",
        "city": "Dumarghati"
      },
      {
        "state": "Jharkhand",
        "city": "Dumaria"
      },
      {
        "state": "Jharkhand",
        "city": "Dumka"
      },
      {
        "state": "Jharkhand",
        "city": "Dumri"
      },
      {
        "state": "Jharkhand",
        "city": "East Singhbhum"
      },
      {
        "state": "Jharkhand",
        "city": "Forest Block"
      },
      {
        "state": "Jharkhand",
        "city": "Gamharia"
      },
      {
        "state": "Jharkhand",
        "city": "Ganwan"
      },
      {
        "state": "Jharkhand",
        "city": "Garhwa"
      },
      {
        "state": "Jharkhand",
        "city": "Ghatshila"
      },
      {
        "state": "Jharkhand",
        "city": "Gidhaur"
      },
      {
        "state": "Jharkhand",
        "city": "Ginjo Thakurgaon"
      },
      {
        "state": "Jharkhand",
        "city": "Giridih"
      },
      {
        "state": "Jharkhand",
        "city": "Gobindpur"
      },
      {
        "state": "Jharkhand",
        "city": "Godda"
      },
      {
        "state": "Jharkhand",
        "city": "Goilkera"
      },
      {
        "state": "Jharkhand",
        "city": "Gola"
      },
      {
        "state": "Jharkhand",
        "city": "Golmuri"
      },
      {
        "state": "Jharkhand",
        "city": "Gomia"
      },
      {
        "state": "Jharkhand",
        "city": "Gopikandar"
      },
      {
        "state": "Jharkhand",
        "city": "Gouraijore"
      },
      {
        "state": "Jharkhand",
        "city": "Gua"
      },
      {
        "state": "Jharkhand",
        "city": "Guira"
      },
      {
        "state": "Jharkhand",
        "city": "Gumla"
      },
      {
        "state": "Jharkhand",
        "city": "Hariharganj"
      },
      {
        "state": "Jharkhand",
        "city": "Hatgamaria"
      },
      {
        "state": "Jharkhand",
        "city": "Hazaribag"
      },
      {
        "state": "Jharkhand",
        "city": "Hazaribagh"
      },
      {
        "state": "Jharkhand",
        "city": "Hiranpur"
      },
      {
        "state": "Jharkhand",
        "city": "Hunterganj"
      },
      {
        "state": "Jharkhand",
        "city": "Ichak"
      },
      {
        "state": "Jharkhand",
        "city": "Indranagar"
      },
      {
        "state": "Jharkhand",
        "city": "Itki"
      },
      {
        "state": "Jharkhand",
        "city": "Itkori"
      },
      {
        "state": "Jharkhand",
        "city": "Jadugora"
      },
      {
        "state": "Jharkhand",
        "city": "Jagannathpur"
      },
      {
        "state": "Jharkhand",
        "city": "Jainagar"
      },
      {
        "state": "Jharkhand",
        "city": "Jama"
      },
      {
        "state": "Jharkhand",
        "city": "Jamshedpur"
      },
      {
        "state": "Jharkhand",
        "city": "Jamtara"
      },
      {
        "state": "Jharkhand",
        "city": "Jamua"
      },
      {
        "state": "Jharkhand",
        "city": "Jamuagiridih"
      },
      {
        "state": "Jharkhand",
        "city": "Japla"
      },
      {
        "state": "Jharkhand",
        "city": "Jarmundi"
      },
      {
        "state": "Jharkhand",
        "city": "Jhinkpani"
      },
      {
        "state": "Jharkhand",
        "city": "Jorapokhar"
      },
      {
        "state": "Jharkhand",
        "city": "Jori"
      },
      {
        "state": "Jharkhand",
        "city": "Jugsalai"
      },
      {
        "state": "Jharkhand",
        "city": "Kadma"
      },
      {
        "state": "Jharkhand",
        "city": "Kamdara"
      },
      {
        "state": "Jharkhand",
        "city": "Kandra"
      },
      {
        "state": "Jharkhand",
        "city": "Kanke"
      },
      {
        "state": "Jharkhand",
        "city": "Karma"
      },
      {
        "state": "Jharkhand",
        "city": "Karon"
      },
      {
        "state": "Jharkhand",
        "city": "Karra"
      },
      {
        "state": "Jharkhand",
        "city": "Kasmar"
      },
      {
        "state": "Jharkhand",
        "city": "Kathikund"
      },
      {
        "state": "Jharkhand",
        "city": "Katkamsandi"
      },
      {
        "state": "Jharkhand",
        "city": "Khalari"
      },
      {
        "state": "Jharkhand",
        "city": "Kharsawangarh"
      },
      {
        "state": "Jharkhand",
        "city": "Khunti"
      },
      {
        "state": "Jharkhand",
        "city": "Kiriburu"
      },
      {
        "state": "Jharkhand",
        "city": "Koderma"
      },
      {
        "state": "Jharkhand",
        "city": "Kolebira"
      },
      {
        "state": "Jharkhand",
        "city": "Konardam"
      },
      {
        "state": "Jharkhand",
        "city": "Kundahit"
      },
      {
        "state": "Jharkhand",
        "city": "Kurdeg"
      },
      {
        "state": "Jharkhand",
        "city": "Kuru"
      },
      {
        "state": "Jharkhand",
        "city": "Lalbathan"
      },
      {
        "state": "Jharkhand",
        "city": "Lapla"
      },
      {
        "state": "Jharkhand",
        "city": "Lapung"
      },
      {
        "state": "Jharkhand",
        "city": "Latehar"
      },
      {
        "state": "Jharkhand",
        "city": "Lesliganj"
      },
      {
        "state": "Jharkhand",
        "city": "Litipara"
      },
      {
        "state": "Jharkhand",
        "city": "Lohardaga"
      },
      {
        "state": "Jharkhand",
        "city": "Madhupur"
      },
      {
        "state": "Jharkhand",
        "city": "Mahagama"
      },
      {
        "state": "Jharkhand",
        "city": "Maheshpur"
      },
      {
        "state": "Jharkhand",
        "city": "Mahulia"
      },
      {
        "state": "Jharkhand",
        "city": "Malumath"
      },
      {
        "state": "Jharkhand",
        "city": "Mandar"
      },
      {
        "state": "Jharkhand",
        "city": "Mandro"
      },
      {
        "state": "Jharkhand",
        "city": "Mandu"
      },
      {
        "state": "Jharkhand",
        "city": "Mango"
      },
      {
        "state": "Jharkhand",
        "city": "Manoharpur"
      },
      {
        "state": "Jharkhand",
        "city": "Markacho"
      },
      {
        "state": "Jharkhand",
        "city": "Masalia"
      },
      {
        "state": "Jharkhand",
        "city": "Meghahatuburu"
      },
      {
        "state": "Jharkhand",
        "city": "Mehrma"
      },
      {
        "state": "Jharkhand",
        "city": "Meral"
      },
      {
        "state": "Jharkhand",
        "city": "Mohanpur"
      },
      {
        "state": "Jharkhand",
        "city": "Moubhandar"
      },
      {
        "state": "Jharkhand",
        "city": "Muhammadganj"
      },
      {
        "state": "Jharkhand",
        "city": "Murhu"
      },
      {
        "state": "Jharkhand",
        "city": "Musabani"
      },
      {
        "state": "Jharkhand",
        "city": "Nagar Untari"
      },
      {
        "state": "Jharkhand",
        "city": "Nala"
      },
      {
        "state": "Jharkhand",
        "city": "Namkum"
      },
      {
        "state": "Jharkhand",
        "city": "Narainpur"
      },
      {
        "state": "Jharkhand",
        "city": "Nawadih"
      },
      {
        "state": "Jharkhand",
        "city": "Nawagarh"
      },
      {
        "state": "Jharkhand",
        "city": "Noadih"
      },
      {
        "state": "Jharkhand",
        "city": "Noamundi"
      },
      {
        "state": "Jharkhand",
        "city": "Ormanjhi"
      },
      {
        "state": "Jharkhand",
        "city": "Padma"
      },
      {
        "state": "Jharkhand",
        "city": "Pahargama"
      },
      {
        "state": "Jharkhand",
        "city": "Pakur"
      },
      {
        "state": "Jharkhand",
        "city": "Palamu"
      },
      {
        "state": "Jharkhand",
        "city": "Palkot"
      },
      {
        "state": "Jharkhand",
        "city": "Palojori"
      },
      {
        "state": "Jharkhand",
        "city": "Panki"
      },
      {
        "state": "Jharkhand",
        "city": "Pareya Hat"
      },
      {
        "state": "Jharkhand",
        "city": "Partabpura"
      },
      {
        "state": "Jharkhand",
        "city": "Patamda"
      },
      {
        "state": "Jharkhand",
        "city": "Pathardih"
      },
      {
        "state": "Jharkhand",
        "city": "Pathargama"
      },
      {
        "state": "Jharkhand",
        "city": "Pathna Land"
      },
      {
        "state": "Jharkhand",
        "city": "Patratu"
      },
      {
        "state": "Jharkhand",
        "city": "Peterbar"
      },
      {
        "state": "Jharkhand",
        "city": "Phulwartanr"
      },
      {
        "state": "Jharkhand",
        "city": "Pirtand"
      },
      {
        "state": "Jharkhand",
        "city": "Pratappur"
      },
      {
        "state": "Jharkhand",
        "city": "Rahargora"
      },
      {
        "state": "Jharkhand",
        "city": "Rajdhanwar"
      },
      {
        "state": "Jharkhand",
        "city": "Rajmahal"
      },
      {
        "state": "Jharkhand",
        "city": "Rajnagar"
      },
      {
        "state": "Jharkhand",
        "city": "Ramgarh"
      },
      {
        "state": "Jharkhand",
        "city": "Ranchi"
      },
      {
        "state": "Jharkhand",
        "city": "Rania"
      },
      {
        "state": "Jharkhand",
        "city": "Ranishwar"
      },
      {
        "state": "Jharkhand",
        "city": "Rehla"
      },
      {
        "state": "Jharkhand",
        "city": "Rohini"
      },
      {
        "state": "Jharkhand",
        "city": "Sahibganj"
      },
      {
        "state": "Jharkhand",
        "city": "Sakchi"
      },
      {
        "state": "Jharkhand",
        "city": "Saliburu"
      },
      {
        "state": "Jharkhand",
        "city": "Saraikela"
      },
      {
        "state": "Jharkhand",
        "city": "Saraiyahat"
      },
      {
        "state": "Jharkhand",
        "city": "Sarath"
      },
      {
        "state": "Jharkhand",
        "city": "Sarawan"
      },
      {
        "state": "Jharkhand",
        "city": "Sarth"
      },
      {
        "state": "Jharkhand",
        "city": "Sarwan"
      },
      {
        "state": "Jharkhand",
        "city": "Satbarwa"
      },
      {
        "state": "Jharkhand",
        "city": "Satgawan"
      },
      {
        "state": "Jharkhand",
        "city": "Seraikela"
      },
      {
        "state": "Jharkhand",
        "city": "Shikaripara"
      },
      {
        "state": "Jharkhand",
        "city": "Silli"
      },
      {
        "state": "Jharkhand",
        "city": "Simaria"
      },
      {
        "state": "Jharkhand",
        "city": "Simaria Khurd"
      },
      {
        "state": "Jharkhand",
        "city": "Simdega"
      },
      {
        "state": "Jharkhand",
        "city": "Sini"
      },
      {
        "state": "Jharkhand",
        "city": "Sirkadih"
      },
      {
        "state": "Jharkhand",
        "city": "Sisai"
      },
      {
        "state": "Jharkhand",
        "city": "Sonua"
      },
      {
        "state": "Jharkhand",
        "city": "Sunadar Pahari"
      },
      {
        "state": "Jharkhand",
        "city": "Sundarnagar"
      },
      {
        "state": "Jharkhand",
        "city": "Taljhari"
      },
      {
        "state": "Jharkhand",
        "city": "Tamar"
      },
      {
        "state": "Jharkhand",
        "city": "Tandwa"
      },
      {
        "state": "Jharkhand",
        "city": "Tatanagar"
      },
      {
        "state": "Jharkhand",
        "city": "Tenughat"
      },
      {
        "state": "Jharkhand",
        "city": "Thethaitanagar"
      },
      {
        "state": "Jharkhand",
        "city": "Tilaiya"
      },
      {
        "state": "Jharkhand",
        "city": "Tiruldih"
      },
      {
        "state": "Jharkhand",
        "city": "Tisri"
      },
      {
        "state": "Jharkhand",
        "city": "Torpa"
      },
      {
        "state": "Jharkhand",
        "city": "Toto"
      },
      {
        "state": "Jharkhand",
        "city": "Turguru"
      },
      {
        "state": "Jharkhand",
        "city": "Udhua"
      },
      {
        "state": "Jharkhand",
        "city": "Udhwa"
      },
      {
        "state": "Jharkhand",
        "city": "West Singhbhum"
      },
      {
        "state": "Karnataka",
        "city": "Afzalpur"
      },
      {
        "state": "Karnataka",
        "city": "Aidanhal"
      },
      {
        "state": "Karnataka",
        "city": "Alahalli"
      },
      {
        "state": "Karnataka",
        "city": "Aland"
      },
      {
        "state": "Karnataka",
        "city": "Alur"
      },
      {
        "state": "Karnataka",
        "city": "Alur Rural"
      },
      {
        "state": "Karnataka",
        "city": "Anegola"
      },
      {
        "state": "Karnataka",
        "city": "Anekal"
      },
      {
        "state": "Karnataka",
        "city": "Ankola"
      },
      {
        "state": "Karnataka",
        "city": "Arakalagud"
      },
      {
        "state": "Karnataka",
        "city": "Arsikere"
      },
      {
        "state": "Karnataka",
        "city": "Athani"
      },
      {
        "state": "Karnataka",
        "city": "Aurad"
      },
      {
        "state": "Karnataka",
        "city": "B.Shettigeri"
      },
      {
        "state": "Karnataka",
        "city": "Bachanakeri"
      },
      {
        "state": "Karnataka",
        "city": "Bachareddyhalli"
      },
      {
        "state": "Karnataka",
        "city": "Badamanavarthekaval"
      },
      {
        "state": "Karnataka",
        "city": "Badami"
      },
      {
        "state": "Karnataka",
        "city": "Bagalkot"
      },
      {
        "state": "Karnataka",
        "city": "Bagepalli"
      },
      {
        "state": "Karnataka",
        "city": "Bailhongal"
      },
      {
        "state": "Karnataka",
        "city": "Balige"
      },
      {
        "state": "Karnataka",
        "city": "Ballari"
      },
      {
        "state": "Karnataka",
        "city": "Bangarapet"
      },
      {
        "state": "Karnataka",
        "city": "Bantwal"
      },
      {
        "state": "Karnataka",
        "city": "Basavakalyan"
      },
      {
        "state": "Karnataka",
        "city": "Basavana Bagewadi"
      },
      {
        "state": "Karnataka",
        "city": "Basthihalli"
      },
      {
        "state": "Karnataka",
        "city": "Bedkihal"
      },
      {
        "state": "Karnataka",
        "city": "Belagavi"
      },
      {
        "state": "Karnataka",
        "city": "Belgaum"
      },
      {
        "state": "Karnataka",
        "city": "Belladamaradi"
      },
      {
        "state": "Karnataka",
        "city": "Belthangady"
      },
      {
        "state": "Karnataka",
        "city": "Belur"
      },
      {
        "state": "Karnataka",
        "city": "Bengaluru"
      },
      {
        "state": "Karnataka",
        "city": "Bengaluru Rural"
      },
      {
        "state": "Karnataka",
        "city": "Bengaluru Urban"
      },
      {
        "state": "Karnataka",
        "city": "Bhadravati"
      },
      {
        "state": "Karnataka",
        "city": "Bhalki"
      },
      {
        "state": "Karnataka",
        "city": "Bhatkal"
      },
      {
        "state": "Karnataka",
        "city": "Bidar"
      },
      {
        "state": "Karnataka",
        "city": "Bilgi"
      },
      {
        "state": "Karnataka",
        "city": "Bilgi Rural"
      },
      {
        "state": "Karnataka",
        "city": "Bilinandi"
      },
      {
        "state": "Karnataka",
        "city": "Byadagi"
      },
      {
        "state": "Karnataka",
        "city": "Challakere"
      },
      {
        "state": "Karnataka",
        "city": "Chamarajanagar"
      },
      {
        "state": "Karnataka",
        "city": "Chandrapura -Chatrakane"
      },
      {
        "state": "Karnataka",
        "city": "Channagiri"
      },
      {
        "state": "Karnataka",
        "city": "Channapatna"
      },
      {
        "state": "Karnataka",
        "city": "Channarayapattana"
      },
      {
        "state": "Karnataka",
        "city": "Cheelammanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Chickmagaluru"
      },
      {
        "state": "Karnataka",
        "city": "Chikkaballapura"
      },
      {
        "state": "Karnataka",
        "city": "Chikkakrimanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Chikkanayakanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Chikodi"
      },
      {
        "state": "Karnataka",
        "city": "Chilkaragi"
      },
      {
        "state": "Karnataka",
        "city": "Chincholi"
      },
      {
        "state": "Karnataka",
        "city": "Chincholi H"
      },
      {
        "state": "Karnataka",
        "city": "Chinnenahalli"
      },
      {
        "state": "Karnataka",
        "city": "Chintamani"
      },
      {
        "state": "Karnataka",
        "city": "Chitradurga"
      },
      {
        "state": "Karnataka",
        "city": "Chittapur"
      },
      {
        "state": "Karnataka",
        "city": "Coorg"
      },
      {
        "state": "Karnataka",
        "city": "Dakshina Kannada"
      },
      {
        "state": "Karnataka",
        "city": "Davangere"
      },
      {
        "state": "Karnataka",
        "city": "Devadurga"
      },
      {
        "state": "Karnataka",
        "city": "Devanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Dharwad"
      },
      {
        "state": "Karnataka",
        "city": "Doddaballapura"
      },
      {
        "state": "Karnataka",
        "city": "Doddagummanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Gadag"
      },
      {
        "state": "Karnataka",
        "city": "Gadag-Betigeri"
      },
      {
        "state": "Karnataka",
        "city": "Gangavathi"
      },
      {
        "state": "Karnataka",
        "city": "Gauribidanur"
      },
      {
        "state": "Karnataka",
        "city": "Gokak"
      },
      {
        "state": "Karnataka",
        "city": "Gotlakunte"
      },
      {
        "state": "Karnataka",
        "city": "Gubbi"
      },
      {
        "state": "Karnataka",
        "city": "Guddethota"
      },
      {
        "state": "Karnataka",
        "city": "Gudibanda"
      },
      {
        "state": "Karnataka",
        "city": "Gulbarga"
      },
      {
        "state": "Karnataka",
        "city": "Gundlupete"
      },
      {
        "state": "Karnataka",
        "city": "Guribidanur"
      },
      {
        "state": "Karnataka",
        "city": "Hadagalli"
      },
      {
        "state": "Karnataka",
        "city": "Hagaribommanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Haliyal"
      },
      {
        "state": "Karnataka",
        "city": "Hallur"
      },
      {
        "state": "Karnataka",
        "city": "Halsur"
      },
      {
        "state": "Karnataka",
        "city": "Hampi"
      },
      {
        "state": "Karnataka",
        "city": "Hangal"
      },
      {
        "state": "Karnataka",
        "city": "Hanumana Halli"
      },
      {
        "state": "Karnataka",
        "city": "Harapanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Harihara"
      },
      {
        "state": "Karnataka",
        "city": "Harpanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Hassan"
      },
      {
        "state": "Karnataka",
        "city": "Haveri"
      },
      {
        "state": "Karnataka",
        "city": "Heggadadevankote"
      },
      {
        "state": "Karnataka",
        "city": "Hillikere"
      },
      {
        "state": "Karnataka",
        "city": "Hirekerur"
      },
      {
        "state": "Karnataka",
        "city": "Hiriyur"
      },
      {
        "state": "Karnataka",
        "city": "Holalkere"
      },
      {
        "state": "Karnataka",
        "city": "Holalkere Rural"
      },
      {
        "state": "Karnataka",
        "city": "Holenarasipur"
      },
      {
        "state": "Karnataka",
        "city": "Honnali"
      },
      {
        "state": "Karnataka",
        "city": "Honnavar"
      },
      {
        "state": "Karnataka",
        "city": "Hoovina Hadagali"
      },
      {
        "state": "Karnataka",
        "city": "Hosa Neeralgi-M-Karadgi New"
      },
      {
        "state": "Karnataka",
        "city": "Hosadurga"
      },
      {
        "state": "Karnataka",
        "city": "Hosakote"
      },
      {
        "state": "Karnataka",
        "city": "Hosali"
      },
      {
        "state": "Karnataka",
        "city": "Hosanagar"
      },
      {
        "state": "Karnataka",
        "city": "Hosapete"
      },
      {
        "state": "Karnataka",
        "city": "Hoskote"
      },
      {
        "state": "Karnataka",
        "city": "Hubali-Dharwad"
      },
      {
        "state": "Karnataka",
        "city": "Hubballi"
      },
      {
        "state": "Karnataka",
        "city": "Hukkeri"
      },
      {
        "state": "Karnataka",
        "city": "Hulikal"
      },
      {
        "state": "Karnataka",
        "city": "Humnabad"
      },
      {
        "state": "Karnataka",
        "city": "Hungund"
      },
      {
        "state": "Karnataka",
        "city": "Hunsur"
      },
      {
        "state": "Karnataka",
        "city": "Indi"
      },
      {
        "state": "Karnataka",
        "city": "Ivarahalli"
      },
      {
        "state": "Karnataka",
        "city": "Jamkhandi"
      },
      {
        "state": "Karnataka",
        "city": "Jangangaddi"
      },
      {
        "state": "Karnataka",
        "city": "Jeerigepete"
      },
      {
        "state": "Karnataka",
        "city": "Jevargi"
      },
      {
        "state": "Karnataka",
        "city": "Joida"
      },
      {
        "state": "Karnataka",
        "city": "Kadabeesanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Kadur"
      },
      {
        "state": "Karnataka",
        "city": "Kalaburagi"
      },
      {
        "state": "Karnataka",
        "city": "Kalaburagi (Gulbarga)"
      },
      {
        "state": "Karnataka",
        "city": "Kalghatgi"
      },
      {
        "state": "Karnataka",
        "city": "Kanakapura"
      },
      {
        "state": "Karnataka",
        "city": "Kanchikai"
      },
      {
        "state": "Karnataka",
        "city": "Karkala"
      },
      {
        "state": "Karnataka",
        "city": "Karwar"
      },
      {
        "state": "Karnataka",
        "city": "Kemminje Rural"
      },
      {
        "state": "Karnataka",
        "city": "Khanapur"
      },
      {
        "state": "Karnataka",
        "city": "Kolar"
      },
      {
        "state": "Karnataka",
        "city": "Kolar Gold Fields"
      },
      {
        "state": "Karnataka",
        "city": "Kollegal"
      },
      {
        "state": "Karnataka",
        "city": "Kolur"
      },
      {
        "state": "Karnataka",
        "city": "Koppa"
      },
      {
        "state": "Karnataka",
        "city": "Koppa Rural"
      },
      {
        "state": "Karnataka",
        "city": "Koppal"
      },
      {
        "state": "Karnataka",
        "city": "Koratagere"
      },
      {
        "state": "Karnataka",
        "city": "Koujageri"
      },
      {
        "state": "Karnataka",
        "city": "Krishnarajanagara"
      },
      {
        "state": "Karnataka",
        "city": "Krishnarajpete"
      },
      {
        "state": "Karnataka",
        "city": "Kudligi"
      },
      {
        "state": "Karnataka",
        "city": "Kukshtagi"
      },
      {
        "state": "Karnataka",
        "city": "Kumta"
      },
      {
        "state": "Karnataka",
        "city": "Kundapura"
      },
      {
        "state": "Karnataka",
        "city": "Kundgol"
      },
      {
        "state": "Karnataka",
        "city": "Kundgol Rural"
      },
      {
        "state": "Karnataka",
        "city": "Kundpaura"
      },
      {
        "state": "Karnataka",
        "city": "Kunigal"
      },
      {
        "state": "Karnataka",
        "city": "Kurekanhal"
      },
      {
        "state": "Karnataka",
        "city": "Kushtagi"
      },
      {
        "state": "Karnataka",
        "city": "Ladwanti"
      },
      {
        "state": "Karnataka",
        "city": "Lingsugur"
      },
      {
        "state": "Karnataka",
        "city": "Londa"
      },
      {
        "state": "Karnataka",
        "city": "Madanamatti"
      },
      {
        "state": "Karnataka",
        "city": "Madduru"
      },
      {
        "state": "Karnataka",
        "city": "Madhugiri"
      },
      {
        "state": "Karnataka",
        "city": "Madikeri"
      },
      {
        "state": "Karnataka",
        "city": "Magadi"
      },
      {
        "state": "Karnataka",
        "city": "Malavalli"
      },
      {
        "state": "Karnataka",
        "city": "Malur"
      },
      {
        "state": "Karnataka",
        "city": "Mandya"
      },
      {
        "state": "Karnataka",
        "city": "Mangaluru"
      },
      {
        "state": "Karnataka",
        "city": "Manipal"
      },
      {
        "state": "Karnataka",
        "city": "Manvi"
      },
      {
        "state": "Karnataka",
        "city": "Mavinsur"
      },
      {
        "state": "Karnataka",
        "city": "Modahalli"
      },
      {
        "state": "Karnataka",
        "city": "Molakalmuru"
      },
      {
        "state": "Karnataka",
        "city": "Moodubelle"
      },
      {
        "state": "Karnataka",
        "city": "Mookanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Mudargi"
      },
      {
        "state": "Karnataka",
        "city": "Muddalahalli"
      },
      {
        "state": "Karnataka",
        "city": "Muddebihal"
      },
      {
        "state": "Karnataka",
        "city": "Mudhol"
      },
      {
        "state": "Karnataka",
        "city": "Mudigere"
      },
      {
        "state": "Karnataka",
        "city": "Mulbagal"
      },
      {
        "state": "Karnataka",
        "city": "Mundargi"
      },
      {
        "state": "Karnataka",
        "city": "Mundgod"
      },
      {
        "state": "Karnataka",
        "city": "Mutmurd"
      },
      {
        "state": "Karnataka",
        "city": "Mysuru"
      },
      {
        "state": "Karnataka",
        "city": "Nagamangala"
      },
      {
        "state": "Karnataka",
        "city": "Nanjanagudu"
      },
      {
        "state": "Karnataka",
        "city": "Narasimharajapura"
      },
      {
        "state": "Karnataka",
        "city": "Nargund"
      },
      {
        "state": "Karnataka",
        "city": "Navalgund"
      },
      {
        "state": "Karnataka",
        "city": "Navunda"
      },
      {
        "state": "Karnataka",
        "city": "Nelamangala"
      },
      {
        "state": "Karnataka",
        "city": "Pandavapura"
      },
      {
        "state": "Karnataka",
        "city": "Parasgad"
      },
      {
        "state": "Karnataka",
        "city": "Pavagada"
      },
      {
        "state": "Karnataka",
        "city": "Periyapatna"
      },
      {
        "state": "Karnataka",
        "city": "Puttur"
      },
      {
        "state": "Karnataka",
        "city": "Rabindranath Tagore Nagar"
      },
      {
        "state": "Karnataka",
        "city": "Raibag"
      },
      {
        "state": "Karnataka",
        "city": "Raichur"
      },
      {
        "state": "Karnataka",
        "city": "Ramanagara"
      },
      {
        "state": "Karnataka",
        "city": "Ramdurg"
      },
      {
        "state": "Karnataka",
        "city": "Ranebennur"
      },
      {
        "state": "Karnataka",
        "city": "Rona"
      },
      {
        "state": "Karnataka",
        "city": "Sakleshpur"
      },
      {
        "state": "Karnataka",
        "city": "Sampgaon"
      },
      {
        "state": "Karnataka",
        "city": "Sandur"
      },
      {
        "state": "Karnataka",
        "city": "Saundatti"
      },
      {
        "state": "Karnataka",
        "city": "Saundatti-Yellamma"
      },
      {
        "state": "Karnataka",
        "city": "Savanur"
      },
      {
        "state": "Karnataka",
        "city": "Sedam"
      },
      {
        "state": "Karnataka",
        "city": "Shahapur"
      },
      {
        "state": "Karnataka",
        "city": "Shiggaon"
      },
      {
        "state": "Karnataka",
        "city": "Shikaripur"
      },
      {
        "state": "Karnataka",
        "city": "Shimoga"
      },
      {
        "state": "Karnataka",
        "city": "Shirahatti"
      },
      {
        "state": "Karnataka",
        "city": "Shivamogga"
      },
      {
        "state": "Karnataka",
        "city": "Shorapur"
      },
      {
        "state": "Karnataka",
        "city": "Shrirangapattana"
      },
      {
        "state": "Karnataka",
        "city": "Siddapur"
      },
      {
        "state": "Karnataka",
        "city": "Sidlaghatta"
      },
      {
        "state": "Karnataka",
        "city": "Sindagi"
      },
      {
        "state": "Karnataka",
        "city": "Sindhanur"
      },
      {
        "state": "Karnataka",
        "city": "Sira"
      },
      {
        "state": "Karnataka",
        "city": "Sira Rural"
      },
      {
        "state": "Karnataka",
        "city": "Sirsi"
      },
      {
        "state": "Karnataka",
        "city": "Siruguppa"
      },
      {
        "state": "Karnataka",
        "city": "Somwarpet"
      },
      {
        "state": "Karnataka",
        "city": "Soraba"
      },
      {
        "state": "Karnataka",
        "city": "Southegowdanahalli"
      },
      {
        "state": "Karnataka",
        "city": "Sringeri"
      },
      {
        "state": "Karnataka",
        "city": "Srinivasapura"
      },
      {
        "state": "Karnataka",
        "city": "Srinivaspur"
      },
      {
        "state": "Karnataka",
        "city": "Srirangapatna"
      },
      {
        "state": "Karnataka",
        "city": "Sullia"
      },
      {
        "state": "Karnataka",
        "city": "Supa"
      },
      {
        "state": "Karnataka",
        "city": "Tarikere"
      },
      {
        "state": "Karnataka",
        "city": "Thirthahalli"
      },
      {
        "state": "Karnataka",
        "city": "Tiptur"
      },
      {
        "state": "Karnataka",
        "city": "Tirumakudalu Narasipura"
      },
      {
        "state": "Karnataka",
        "city": "Tumakuru"
      },
      {
        "state": "Karnataka",
        "city": "Turuvekere"
      },
      {
        "state": "Karnataka",
        "city": "Udupi"
      },
      {
        "state": "Karnataka",
        "city": "Vijayapura"
      },
      {
        "state": "Karnataka",
        "city": "Virajpet"
      },
      {
        "state": "Karnataka",
        "city": "Yadgir"
      },
      {
        "state": "Karnataka",
        "city": "Yelandur"
      },
      {
        "state": "Karnataka",
        "city": "Yelburga"
      },
      {
        "state": "Karnataka",
        "city": "Yellapur"
      },
      {
        "state": "Karnataka",
        "city": "Yenigadale"
      },
      {
        "state": "Kerala",
        "city": "Adichanallur"
      },
      {
        "state": "Kerala",
        "city": "Adoor"
      },
      {
        "state": "Kerala",
        "city": "Adur"
      },
      {
        "state": "Kerala",
        "city": "Alappuzha"
      },
      {
        "state": "Kerala",
        "city": "Alathur"
      },
      {
        "state": "Kerala",
        "city": "Aluva"
      },
      {
        "state": "Kerala",
        "city": "Ambalappuzha"
      },
      {
        "state": "Kerala",
        "city": "Angamaly"
      },
      {
        "state": "Kerala",
        "city": "Arikkulam"
      },
      {
        "state": "Kerala",
        "city": "Changanassery"
      },
      {
        "state": "Kerala",
        "city": "Chavakkad"
      },
      {
        "state": "Kerala",
        "city": "Chemmad"
      },
      {
        "state": "Kerala",
        "city": "Chengannur"
      },
      {
        "state": "Kerala",
        "city": "Chennithala"
      },
      {
        "state": "Kerala",
        "city": "Cherpulassery"
      },
      {
        "state": "Kerala",
        "city": "Cherthala"
      },
      {
        "state": "Kerala",
        "city": "Chirayinkeezhu"
      },
      {
        "state": "Kerala",
        "city": "Chirayinkil"
      },
      {
        "state": "Kerala",
        "city": "Chithara"
      },
      {
        "state": "Kerala",
        "city": "Chittur-Thathamangalam"
      },
      {
        "state": "Kerala",
        "city": "Devikulam"
      },
      {
        "state": "Kerala",
        "city": "Eranad"
      },
      {
        "state": "Kerala",
        "city": "Ernakulam"
      },
      {
        "state": "Kerala",
        "city": "Hosdurg"
      },
      {
        "state": "Kerala",
        "city": "Idukki"
      },
      {
        "state": "Kerala",
        "city": "Irinjalakuda"
      },
      {
        "state": "Kerala",
        "city": "Kadappuram"
      },
      {
        "state": "Kerala",
        "city": "Kaduthuruthy"
      },
      {
        "state": "Kerala",
        "city": "Kaiparambu"
      },
      {
        "state": "Kerala",
        "city": "Kalady"
      },
      {
        "state": "Kerala",
        "city": "Kambil"
      },
      {
        "state": "Kerala",
        "city": "Kanayannur"
      },
      {
        "state": "Kerala",
        "city": "Kandalloor"
      },
      {
        "state": "Kerala",
        "city": "Kanhangad"
      },
      {
        "state": "Kerala",
        "city": "Kanjirapally"
      },
      {
        "state": "Kerala",
        "city": "Kanjirappally"
      },
      {
        "state": "Kerala",
        "city": "Kannur"
      },
      {
        "state": "Kerala",
        "city": "Karthikappally"
      },
      {
        "state": "Kerala",
        "city": "Karunagappally"
      },
      {
        "state": "Kerala",
        "city": "Kasaragod"
      },
      {
        "state": "Kerala",
        "city": "Kayamkulam"
      },
      {
        "state": "Kerala",
        "city": "Kochi"
      },
      {
        "state": "Kerala",
        "city": "Kodungallur"
      },
      {
        "state": "Kerala",
        "city": "Kollam"
      },
      {
        "state": "Kerala",
        "city": "Koonammavu"
      },
      {
        "state": "Kerala",
        "city": "Koovappally"
      },
      {
        "state": "Kerala",
        "city": "Kothamangalam"
      },
      {
        "state": "Kerala",
        "city": "Kottarakkara"
      },
      {
        "state": "Kerala",
        "city": "Kottayam"
      },
      {
        "state": "Kerala",
        "city": "Kottuvally"
      },
      {
        "state": "Kerala",
        "city": "Kovalam"
      },
      {
        "state": "Kerala",
        "city": "Koyilandy"
      },
      {
        "state": "Kerala",
        "city": "Kozhencherry"
      },
      {
        "state": "Kerala",
        "city": "Kozhikode"
      },
      {
        "state": "Kerala",
        "city": "Kozhinjampara"
      },
      {
        "state": "Kerala",
        "city": "Kulanada"
      },
      {
        "state": "Kerala",
        "city": "Kumarapuram"
      },
      {
        "state": "Kerala",
        "city": "Kunnathunadu"
      },
      {
        "state": "Kerala",
        "city": "Kunnathur"
      },
      {
        "state": "Kerala",
        "city": "Kuravilangad"
      },
      {
        "state": "Kerala",
        "city": "Kuttanadu"
      },
      {
        "state": "Kerala",
        "city": "Mala"
      },
      {
        "state": "Kerala",
        "city": "Malampuzha-II"
      },
      {
        "state": "Kerala",
        "city": "Malappuram"
      },
      {
        "state": "Kerala",
        "city": "Mallappally"
      },
      {
        "state": "Kerala",
        "city": "Mananthavady"
      },
      {
        "state": "Kerala",
        "city": "Manjeri"
      },
      {
        "state": "Kerala",
        "city": "Mannarkad-I"
      },
      {
        "state": "Kerala",
        "city": "Mannarkad-II"
      },
      {
        "state": "Kerala",
        "city": "Mararikulam"
      },
      {
        "state": "Kerala",
        "city": "Mavelikkara"
      },
      {
        "state": "Kerala",
        "city": "Mayyil"
      },
      {
        "state": "Kerala",
        "city": "Meenachil"
      },
      {
        "state": "Kerala",
        "city": "Meenachil part"
      },
      {
        "state": "Kerala",
        "city": "Melukavumattam"
      },
      {
        "state": "Kerala",
        "city": "Mevada"
      },
      {
        "state": "Kerala",
        "city": "Moodadi"
      },
      {
        "state": "Kerala",
        "city": "Mukundapuram"
      },
      {
        "state": "Kerala",
        "city": "Muthukulam"
      },
      {
        "state": "Kerala",
        "city": "Muvattupuzha"
      },
      {
        "state": "Kerala",
        "city": "Nedumangad"
      },
      {
        "state": "Kerala",
        "city": "Neyyattinkara"
      },
      {
        "state": "Kerala",
        "city": "Nilambur"
      },
      {
        "state": "Kerala",
        "city": "Nooranad"
      },
      {
        "state": "Kerala",
        "city": "North Paravur"
      },
      {
        "state": "Kerala",
        "city": "Ottpalam"
      },
      {
        "state": "Kerala",
        "city": "Palakkad"
      },
      {
        "state": "Kerala",
        "city": "Panackapalam"
      },
      {
        "state": "Kerala",
        "city": "Paravoor"
      },
      {
        "state": "Kerala",
        "city": "Paravur"
      },
      {
        "state": "Kerala",
        "city": "Pathanamthitta"
      },
      {
        "state": "Kerala",
        "city": "Pathanapuram"
      },
      {
        "state": "Kerala",
        "city": "Payyanur"
      },
      {
        "state": "Kerala",
        "city": "Pazhayangadi"
      },
      {
        "state": "Kerala",
        "city": "Peermade"
      },
      {
        "state": "Kerala",
        "city": "Perintalmanna"
      },
      {
        "state": "Kerala",
        "city": "Perumbaikad"
      },
      {
        "state": "Kerala",
        "city": "Pilathara"
      },
      {
        "state": "Kerala",
        "city": "Pinnakkanad"
      },
      {
        "state": "Kerala",
        "city": "Ponnani"
      },
      {
        "state": "Kerala",
        "city": "Pravithanam"
      },
      {
        "state": "Kerala",
        "city": "Pulpatta"
      },
      {
        "state": "Kerala",
        "city": "Quilandy"
      },
      {
        "state": "Kerala",
        "city": "Ramanattukara"
      },
      {
        "state": "Kerala",
        "city": "Ranni"
      },
      {
        "state": "Kerala",
        "city": "Sultan Bathery"
      },
      {
        "state": "Kerala",
        "city": "Taliparamba"
      },
      {
        "state": "Kerala",
        "city": "Thalapilly"
      },
      {
        "state": "Kerala",
        "city": "Thalassery"
      },
      {
        "state": "Kerala",
        "city": "Thalavoor"
      },
      {
        "state": "Kerala",
        "city": "Thalayazham"
      },
      {
        "state": "Kerala",
        "city": "Thalayolaparambu"
      },
      {
        "state": "Kerala",
        "city": "Thekkekara"
      },
      {
        "state": "Kerala",
        "city": "Thenhipalam"
      },
      {
        "state": "Kerala",
        "city": "Thiruvalla"
      },
      {
        "state": "Kerala",
        "city": "Thiruvananthapuram"
      },
      {
        "state": "Kerala",
        "city": "Thodupuzha"
      },
      {
        "state": "Kerala",
        "city": "Thrissur"
      },
      {
        "state": "Kerala",
        "city": "Tirur"
      },
      {
        "state": "Kerala",
        "city": "Tirurangadi"
      },
      {
        "state": "Kerala",
        "city": "Trivandrum"
      },
      {
        "state": "Kerala",
        "city": "Udumbanchola"
      },
      {
        "state": "Kerala",
        "city": "Vadakara"
      },
      {
        "state": "Kerala",
        "city": "Vaikom"
      },
      {
        "state": "Kerala",
        "city": "Vaniyamkulam-I"
      },
      {
        "state": "Kerala",
        "city": "Vannappuram"
      },
      {
        "state": "Kerala",
        "city": "Vazhoor"
      },
      {
        "state": "Kerala",
        "city": "Vythiri"
      },
      {
        "state": "Kerala",
        "city": "Vythiti"
      },
      {
        "state": "Kerala",
        "city": "Wadakkanchery"
      },
      {
        "state": "Kerala",
        "city": "Wayanad"
      },
      {
        "state": "Lakshadweep",
        "city": "Amini"
      },
      {
        "state": "Lakshadweep",
        "city": "Kiltan Island"
      },
      {
        "state": "Lakshadweep",
        "city": "Lakshadweep"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Agar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Agar Malwa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Akodia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Alirajpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Alote"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Amarpatan"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Amarwara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ambah"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Amla"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Anjad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Anuppur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ashoknagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ashta"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ater"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Awantipur Badodia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bada Malhera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Badnawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Badora"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bagli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bahinsdehi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bahoriband"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Baihar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bajna"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Baktara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Balaghat"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Baldevgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bamori"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bandhogarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bankhedi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bareli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Barghat"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Barnagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Barwaha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Barwani"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Batiyagarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Begumganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Beohari"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Berchha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Betul"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhainsdehi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhander"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhanpura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhatpachlana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhavra"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhikangaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhind"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhitarwar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhopal"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bhuabichhia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Biaora"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bichhiya Ryt."
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bichhua"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bijawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bijaypur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bijeypur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bilkisganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Bina"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Boda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Budhni"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Burhanpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Burhar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chachawada"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chandameta-Butaria"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chandametta"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chanderi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chauki Rahimabad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chaurai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chhapera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chhapiheda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chhatarpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chhindwara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chhota Chhindwara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chitrangi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Chourai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Churhat"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Daboh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Dabra"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Damoh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Datia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Deori"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Deori Chhirari"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Deori Naharmau"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Deori Pathariya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Deosar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Depalpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Dewas"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Dhar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Dharampuri"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Dindori"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Esagarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gadarwara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gairatganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ganj Basoda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Garhakota"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Garoth"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gata"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ghansor"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ghatiya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gohad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gopadbanas"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gorihar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gotegaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gouharganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Guna"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gwalior"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Gyaraspur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Hanumana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Harda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Harduwa Khurd"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Harrai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Hatta"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Hoshangabad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Huzur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ichhawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Indergarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Indore"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Isagarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Itarsi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jabalpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jabera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jaisinghnagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jaithari"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jamaldi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jamner"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jaora"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jatara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jawad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jayant"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jhabua"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jharda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jhirniya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Jirapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Joura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Junnardeo"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kailaras"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kalapipal Gaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kalisindh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kanad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kannod"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Karahal"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kareli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Karera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kasrawad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Katangi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Katni"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kelaras"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Keolari"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kesali"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khachrod"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khairlanji"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khajuraho"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khaknar Khurd"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khandwa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khaniadhana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khargapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khargone"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khategaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khilchipur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khirkiya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khujner"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Khurai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kila Bandhogarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kirnapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kolaras"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kolgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Korai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kothri"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kotma"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kukshi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kumbhraj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kundam"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kurawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Kurwai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lahar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lakhnadon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lalbarra"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lanji"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lateri"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Lavkush Nagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Machalpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Maheshwar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mahidpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Maihar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Majauli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Majhauli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Maksudangarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Malhargarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Manasa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Manawar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mandla"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mandsaur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Marugarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Maunganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Megh Nagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mehgaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mhow"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mihona"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mohan Babodiya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Moman Badodia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Morena"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Multai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Mungaoli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nagda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nagod"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nainpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nalkheda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Narsinghpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Narwar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nasrullaganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nateran"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Neemuch"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nepanagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Newali"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Niwas"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Nowgong"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Old Harsud"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Orachha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pachore"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Padliya Umath"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Palera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pali Birsinghpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pandhana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pandhurna"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Panna"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pansemal"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Parasia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Parasia Baitul"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Paraswar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Patan"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Patera"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Patharia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pathariya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Petlawad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pichhore"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pipariya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Piploda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Piploda Sagotimata"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pohari"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Polay Kalan"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Porsa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Prithvipur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Pushparajgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raghogarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raghurajnagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rahatgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raipura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raisen"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rajgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rajgarh(Bia)"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rampur Naikin"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ranapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ratlam"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raun"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Raura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rehli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rehti"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rewa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Rithi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Runija"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sabalgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Saikheda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sailana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Salsalai"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sanwer"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sarangpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sarlanagar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sarmapur Ryt."
      },
      {
        "state": "Madhya Pradesh",
        "city": "Satna"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sausar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Segaon"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sehore"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sendhwa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Seondha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Seoni"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Seoni Malwa"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Shahdol"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Shahpura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Shajapur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sheopur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Shivpuri"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Shujalpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Siddiqueganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sidhi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sihawal"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sihora"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sikandarganj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Silwani"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Singrauli"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Singrawan"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sirmaur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sironj"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sitamau"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sleemanabad"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sohagpur"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sonkatch"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Soyat Kalan"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Sundersi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Susner"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Suthaliya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Suwasra"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Talen"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Tamia"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Tarana"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Tendukheda"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Teonthar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Thandla"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Thikri"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Tikamgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Timarni"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Tirodi"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Udaipura"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Ujjain"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Umaria"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Umariya"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Unchehara"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Vidisha"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Vijaypur Alias Kilhar"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Vijayraghavgarh"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Waraseoni"
      },
      {
        "state": "Madhya Pradesh",
        "city": "Zirnia"
      },
      {
        "state": "Maharashtra",
        "city": "Achalpur"
      },
      {
        "state": "Maharashtra",
        "city": "Aheri"
      },
      {
        "state": "Maharashtra",
        "city": "Ahmednagar"
      },
      {
        "state": "Maharashtra",
        "city": "Ahmedpur"
      },
      {
        "state": "Maharashtra",
        "city": "Ajara"
      },
      {
        "state": "Maharashtra",
        "city": "Akkalkot"
      },
      {
        "state": "Maharashtra",
        "city": "Akkalkuva"
      },
      {
        "state": "Maharashtra",
        "city": "Akluj"
      },
      {
        "state": "Maharashtra",
        "city": "Akola"
      },
      {
        "state": "Maharashtra",
        "city": "Akole"
      },
      {
        "state": "Maharashtra",
        "city": "Akot"
      },
      {
        "state": "Maharashtra",
        "city": "Alibag"
      },
      {
        "state": "Maharashtra",
        "city": "Amalner"
      },
      {
        "state": "Maharashtra",
        "city": "Ambad"
      },
      {
        "state": "Maharashtra",
        "city": "Ambajogai"
      },
      {
        "state": "Maharashtra",
        "city": "Ambegaon"
      },
      {
        "state": "Maharashtra",
        "city": "Ambernath"
      },
      {
        "state": "Maharashtra",
        "city": "Ambewadgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Amgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Amravati"
      },
      {
        "state": "Maharashtra",
        "city": "Anjaneri"
      },
      {
        "state": "Maharashtra",
        "city": "Anjangaon"
      },
      {
        "state": "Maharashtra",
        "city": "Ardhapur"
      },
      {
        "state": "Maharashtra",
        "city": "Arjuni"
      },
      {
        "state": "Maharashtra",
        "city": "Armori"
      },
      {
        "state": "Maharashtra",
        "city": "Arvi"
      },
      {
        "state": "Maharashtra",
        "city": "Ashti"
      },
      {
        "state": "Maharashtra",
        "city": "Atpadi"
      },
      {
        "state": "Maharashtra",
        "city": "Aundha Nagnath"
      },
      {
        "state": "Maharashtra",
        "city": "Aurangabad"
      },
      {
        "state": "Maharashtra",
        "city": "Ausa"
      },
      {
        "state": "Maharashtra",
        "city": "Babhulgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Badnapur"
      },
      {
        "state": "Maharashtra",
        "city": "Balapur"
      },
      {
        "state": "Maharashtra",
        "city": "Balharshah"
      },
      {
        "state": "Maharashtra",
        "city": "Baramati"
      },
      {
        "state": "Maharashtra",
        "city": "Barshi"
      },
      {
        "state": "Maharashtra",
        "city": "Barsi Takli"
      },
      {
        "state": "Maharashtra",
        "city": "Basmat"
      },
      {
        "state": "Maharashtra",
        "city": "Bavada"
      },
      {
        "state": "Maharashtra",
        "city": "Beed"
      },
      {
        "state": "Maharashtra",
        "city": "Bhadgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Bhadravati"
      },
      {
        "state": "Maharashtra",
        "city": "Bhamragarh"
      },
      {
        "state": "Maharashtra",
        "city": "Bhandara"
      },
      {
        "state": "Maharashtra",
        "city": "Bhatkuli"
      },
      {
        "state": "Maharashtra",
        "city": "Bhavaninagar"
      },
      {
        "state": "Maharashtra",
        "city": "Bhikarkheda"
      },
      {
        "state": "Maharashtra",
        "city": "Bhiwandi"
      },
      {
        "state": "Maharashtra",
        "city": "Bhiwapur"
      },
      {
        "state": "Maharashtra",
        "city": "Bhokar"
      },
      {
        "state": "Maharashtra",
        "city": "Bhokardan"
      },
      {
        "state": "Maharashtra",
        "city": "Bhoom"
      },
      {
        "state": "Maharashtra",
        "city": "Bhusawal"
      },
      {
        "state": "Maharashtra",
        "city": "Bhuye"
      },
      {
        "state": "Maharashtra",
        "city": "Biloli"
      },
      {
        "state": "Maharashtra",
        "city": "Bodwad"
      },
      {
        "state": "Maharashtra",
        "city": "Buldhana"
      },
      {
        "state": "Maharashtra",
        "city": "Chakur"
      },
      {
        "state": "Maharashtra",
        "city": "Chalisgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Chamorshi"
      },
      {
        "state": "Maharashtra",
        "city": "Chandgad"
      },
      {
        "state": "Maharashtra",
        "city": "Chandrapur"
      },
      {
        "state": "Maharashtra",
        "city": "Chandsaili"
      },
      {
        "state": "Maharashtra",
        "city": "Chandur"
      },
      {
        "state": "Maharashtra",
        "city": "Chandur Bazar"
      },
      {
        "state": "Maharashtra",
        "city": "Chandur Railway"
      },
      {
        "state": "Maharashtra",
        "city": "Chandwad"
      },
      {
        "state": "Maharashtra",
        "city": "Chikhaldara"
      },
      {
        "state": "Maharashtra",
        "city": "Chikhli"
      },
      {
        "state": "Maharashtra",
        "city": "Chimur"
      },
      {
        "state": "Maharashtra",
        "city": "Chiplun"
      },
      {
        "state": "Maharashtra",
        "city": "Chopda"
      },
      {
        "state": "Maharashtra",
        "city": "Dahanu"
      },
      {
        "state": "Maharashtra",
        "city": "Dahiwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Dapoli"
      },
      {
        "state": "Maharashtra",
        "city": "Darwha"
      },
      {
        "state": "Maharashtra",
        "city": "Daryapur Banosa"
      },
      {
        "state": "Maharashtra",
        "city": "Daund"
      },
      {
        "state": "Maharashtra",
        "city": "Deaulgaon Raja"
      },
      {
        "state": "Maharashtra",
        "city": "Degloor"
      },
      {
        "state": "Maharashtra",
        "city": "Deoni"
      },
      {
        "state": "Maharashtra",
        "city": "Desaiganj"
      },
      {
        "state": "Maharashtra",
        "city": "Deulgaon Mahi"
      },
      {
        "state": "Maharashtra",
        "city": "Devgad"
      },
      {
        "state": "Maharashtra",
        "city": "Dhadgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Dhamangaon"
      },
      {
        "state": "Maharashtra",
        "city": "Dharangaon"
      },
      {
        "state": "Maharashtra",
        "city": "Dharmabad"
      },
      {
        "state": "Maharashtra",
        "city": "Dharni"
      },
      {
        "state": "Maharashtra",
        "city": "Dharur"
      },
      {
        "state": "Maharashtra",
        "city": "Dholagarwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Dhondewadi"
      },
      {
        "state": "Maharashtra",
        "city": "Dhule"
      },
      {
        "state": "Maharashtra",
        "city": "Digras"
      },
      {
        "state": "Maharashtra",
        "city": "Dodamarg"
      },
      {
        "state": "Maharashtra",
        "city": "Dombivli"
      },
      {
        "state": "Maharashtra",
        "city": "Erandol"
      },
      {
        "state": "Maharashtra",
        "city": "Etapalli"
      },
      {
        "state": "Maharashtra",
        "city": "Gadchiroli"
      },
      {
        "state": "Maharashtra",
        "city": "Gadhinglaj"
      },
      {
        "state": "Maharashtra",
        "city": "Gaganbavada"
      },
      {
        "state": "Maharashtra",
        "city": "Gaganbawda"
      },
      {
        "state": "Maharashtra",
        "city": "Gangakhed"
      },
      {
        "state": "Maharashtra",
        "city": "Georai"
      },
      {
        "state": "Maharashtra",
        "city": "Ghansawangi"
      },
      {
        "state": "Maharashtra",
        "city": "Ghatanji"
      },
      {
        "state": "Maharashtra",
        "city": "Ghera Sudhagad"
      },
      {
        "state": "Maharashtra",
        "city": "Gherapurandhar"
      },
      {
        "state": "Maharashtra",
        "city": "Godhani"
      },
      {
        "state": "Maharashtra",
        "city": "Gondia"
      },
      {
        "state": "Maharashtra",
        "city": "Gondpipri"
      },
      {
        "state": "Maharashtra",
        "city": "Goregaon"
      },
      {
        "state": "Maharashtra",
        "city": "Gorewasti"
      },
      {
        "state": "Maharashtra",
        "city": "Guhagar"
      },
      {
        "state": "Maharashtra",
        "city": "Hadgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Harat Kheda"
      },
      {
        "state": "Maharashtra",
        "city": "Hatkanangale"
      },
      {
        "state": "Maharashtra",
        "city": "Himayatnagar"
      },
      {
        "state": "Maharashtra",
        "city": "Hinganghat"
      },
      {
        "state": "Maharashtra",
        "city": "Hingna"
      },
      {
        "state": "Maharashtra",
        "city": "Hingoli"
      },
      {
        "state": "Maharashtra",
        "city": "Indapur"
      },
      {
        "state": "Maharashtra",
        "city": "Jalgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Jalgaon Jamod"
      },
      {
        "state": "Maharashtra",
        "city": "Jalkot"
      },
      {
        "state": "Maharashtra",
        "city": "Jalna"
      },
      {
        "state": "Maharashtra",
        "city": "Jamkhed"
      },
      {
        "state": "Maharashtra",
        "city": "Jath"
      },
      {
        "state": "Maharashtra",
        "city": "Javali"
      },
      {
        "state": "Maharashtra",
        "city": "Jawhar"
      },
      {
        "state": "Maharashtra",
        "city": "Jayakochiwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Jintur"
      },
      {
        "state": "Maharashtra",
        "city": "Jiwati"
      },
      {
        "state": "Maharashtra",
        "city": "Junnar"
      },
      {
        "state": "Maharashtra",
        "city": "Kadegaon"
      },
      {
        "state": "Maharashtra",
        "city": "Kagal"
      },
      {
        "state": "Maharashtra",
        "city": "Kahanapur"
      },
      {
        "state": "Maharashtra",
        "city": "Kahatav"
      },
      {
        "state": "Maharashtra",
        "city": "Kaij"
      },
      {
        "state": "Maharashtra",
        "city": "Kalamb"
      },
      {
        "state": "Maharashtra",
        "city": "Kalameshwar"
      },
      {
        "state": "Maharashtra",
        "city": "Kalamnuri"
      },
      {
        "state": "Maharashtra",
        "city": "Kallam"
      },
      {
        "state": "Maharashtra",
        "city": "Kalyan"
      },
      {
        "state": "Maharashtra",
        "city": "Kamptee"
      },
      {
        "state": "Maharashtra",
        "city": "Kandhar"
      },
      {
        "state": "Maharashtra",
        "city": "Kankavali"
      },
      {
        "state": "Maharashtra",
        "city": "Kannad"
      },
      {
        "state": "Maharashtra",
        "city": "Karad"
      },
      {
        "state": "Maharashtra",
        "city": "Karanja"
      },
      {
        "state": "Maharashtra",
        "city": "Karanja (Ghadge)"
      },
      {
        "state": "Maharashtra",
        "city": "Karanjali"
      },
      {
        "state": "Maharashtra",
        "city": "Karjat"
      },
      {
        "state": "Maharashtra",
        "city": "Karmala"
      },
      {
        "state": "Maharashtra",
        "city": "Karmala Rural"
      },
      {
        "state": "Maharashtra",
        "city": "Katol"
      },
      {
        "state": "Maharashtra",
        "city": "Kavathe Mahankal"
      },
      {
        "state": "Maharashtra",
        "city": "Kelapur"
      },
      {
        "state": "Maharashtra",
        "city": "Khalapur"
      },
      {
        "state": "Maharashtra",
        "city": "Khamgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Khanapur"
      },
      {
        "state": "Maharashtra",
        "city": "Khandala"
      },
      {
        "state": "Maharashtra",
        "city": "Khatav"
      },
      {
        "state": "Maharashtra",
        "city": "Khopoli"
      },
      {
        "state": "Maharashtra",
        "city": "Khuldabad"
      },
      {
        "state": "Maharashtra",
        "city": "Kikali"
      },
      {
        "state": "Maharashtra",
        "city": "Kinwat"
      },
      {
        "state": "Maharashtra",
        "city": "Kiwat"
      },
      {
        "state": "Maharashtra",
        "city": "Kodgaon Ambad"
      },
      {
        "state": "Maharashtra",
        "city": "Kolgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Kolhapur"
      },
      {
        "state": "Maharashtra",
        "city": "Kopargaon"
      },
      {
        "state": "Maharashtra",
        "city": "Korchi"
      },
      {
        "state": "Maharashtra",
        "city": "Koregaon"
      },
      {
        "state": "Maharashtra",
        "city": "Korpana"
      },
      {
        "state": "Maharashtra",
        "city": "Kudal"
      },
      {
        "state": "Maharashtra",
        "city": "Kudnur"
      },
      {
        "state": "Maharashtra",
        "city": "Kuhi"
      },
      {
        "state": "Maharashtra",
        "city": "Kurduwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Kurkheda"
      },
      {
        "state": "Maharashtra",
        "city": "Lakhandur"
      },
      {
        "state": "Maharashtra",
        "city": "Lakhni"
      },
      {
        "state": "Maharashtra",
        "city": "Lanja"
      },
      {
        "state": "Maharashtra",
        "city": "Latur"
      },
      {
        "state": "Maharashtra",
        "city": "Lohara"
      },
      {
        "state": "Maharashtra",
        "city": "Lonar"
      },
      {
        "state": "Maharashtra",
        "city": "Lonavala"
      },
      {
        "state": "Maharashtra",
        "city": "Madha"
      },
      {
        "state": "Maharashtra",
        "city": "Mahabaleshwar"
      },
      {
        "state": "Maharashtra",
        "city": "Mahad"
      },
      {
        "state": "Maharashtra",
        "city": "Mahagaon"
      },
      {
        "state": "Maharashtra",
        "city": "Malegaon"
      },
      {
        "state": "Maharashtra",
        "city": "Malegaon Bk"
      },
      {
        "state": "Maharashtra",
        "city": "Malegaon Kannad"
      },
      {
        "state": "Maharashtra",
        "city": "Malkapur"
      },
      {
        "state": "Maharashtra",
        "city": "Malshiras"
      },
      {
        "state": "Maharashtra",
        "city": "Malvan"
      },
      {
        "state": "Maharashtra",
        "city": "Manchar"
      },
      {
        "state": "Maharashtra",
        "city": "Mandangad"
      },
      {
        "state": "Maharashtra",
        "city": "Mandur"
      },
      {
        "state": "Maharashtra",
        "city": "Mangalwedha"
      },
      {
        "state": "Maharashtra",
        "city": "Mangaon"
      },
      {
        "state": "Maharashtra",
        "city": "Mangrulpir"
      },
      {
        "state": "Maharashtra",
        "city": "Mantha"
      },
      {
        "state": "Maharashtra",
        "city": "Manwath"
      },
      {
        "state": "Maharashtra",
        "city": "Maregaon"
      },
      {
        "state": "Maharashtra",
        "city": "Matheran"
      },
      {
        "state": "Maharashtra",
        "city": "Mauda"
      },
      {
        "state": "Maharashtra",
        "city": "Mawalgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Mehkar"
      },
      {
        "state": "Maharashtra",
        "city": "Mhasala"
      },
      {
        "state": "Maharashtra",
        "city": "Mhase"
      },
      {
        "state": "Maharashtra",
        "city": "Mira Bhayandar"
      },
      {
        "state": "Maharashtra",
        "city": "Miraj"
      },
      {
        "state": "Maharashtra",
        "city": "Mohadi"
      },
      {
        "state": "Maharashtra",
        "city": "Mohdal Bhanguda"
      },
      {
        "state": "Maharashtra",
        "city": "Mohol"
      },
      {
        "state": "Maharashtra",
        "city": "Mohopada"
      },
      {
        "state": "Maharashtra",
        "city": "Mokhada"
      },
      {
        "state": "Maharashtra",
        "city": "Morshi"
      },
      {
        "state": "Maharashtra",
        "city": "Motala"
      },
      {
        "state": "Maharashtra",
        "city": "Mudkhed"
      },
      {
        "state": "Maharashtra",
        "city": "Mukhed"
      },
      {
        "state": "Maharashtra",
        "city": "Muktainagar"
      },
      {
        "state": "Maharashtra",
        "city": "Mulchera"
      },
      {
        "state": "Maharashtra",
        "city": "Mulshi"
      },
      {
        "state": "Maharashtra",
        "city": "Mumbai"
      },
      {
        "state": "Maharashtra",
        "city": "Mumbai City"
      },
      {
        "state": "Maharashtra",
        "city": "Mumbai Suburban"
      },
      {
        "state": "Maharashtra",
        "city": "Mundhe Tarf Chiplun"
      },
      {
        "state": "Maharashtra",
        "city": "Murbad"
      },
      {
        "state": "Maharashtra",
        "city": "Murtizapur"
      },
      {
        "state": "Maharashtra",
        "city": "Murud"
      },
      {
        "state": "Maharashtra",
        "city": "Murud rural"
      },
      {
        "state": "Maharashtra",
        "city": "Nagbhid"
      },
      {
        "state": "Maharashtra",
        "city": "Nagbhir"
      },
      {
        "state": "Maharashtra",
        "city": "Nagpur"
      },
      {
        "state": "Maharashtra",
        "city": "Naigaon"
      },
      {
        "state": "Maharashtra",
        "city": "Nala Sopara"
      },
      {
        "state": "Maharashtra",
        "city": "Nanded"
      },
      {
        "state": "Maharashtra",
        "city": "Nandgaon Khandeshwar"
      },
      {
        "state": "Maharashtra",
        "city": "Nandura"
      },
      {
        "state": "Maharashtra",
        "city": "Nandurbar"
      },
      {
        "state": "Maharashtra",
        "city": "Narkhed"
      },
      {
        "state": "Maharashtra",
        "city": "Nashik"
      },
      {
        "state": "Maharashtra",
        "city": "Navapur"
      },
      {
        "state": "Maharashtra",
        "city": "Navi Mumbai"
      },
      {
        "state": "Maharashtra",
        "city": "Naygaon"
      },
      {
        "state": "Maharashtra",
        "city": "Newasa"
      },
      {
        "state": "Maharashtra",
        "city": "Nilanga"
      },
      {
        "state": "Maharashtra",
        "city": "Niphad"
      },
      {
        "state": "Maharashtra",
        "city": "Omerga"
      },
      {
        "state": "Maharashtra",
        "city": "Oros"
      },
      {
        "state": "Maharashtra",
        "city": "Osmanabad"
      },
      {
        "state": "Maharashtra",
        "city": "Pachora"
      },
      {
        "state": "Maharashtra",
        "city": "Paithan"
      },
      {
        "state": "Maharashtra",
        "city": "Palghar"
      },
      {
        "state": "Maharashtra",
        "city": "Palus"
      },
      {
        "state": "Maharashtra",
        "city": "Pandharpur"
      },
      {
        "state": "Maharashtra",
        "city": "Panhala"
      },
      {
        "state": "Maharashtra",
        "city": "Panumbre Tarf Shirala"
      },
      {
        "state": "Maharashtra",
        "city": "Panvel"
      },
      {
        "state": "Maharashtra",
        "city": "Paranda"
      },
      {
        "state": "Maharashtra",
        "city": "Paratwada"
      },
      {
        "state": "Maharashtra",
        "city": "Parbhani"
      },
      {
        "state": "Maharashtra",
        "city": "Parli"
      },
      {
        "state": "Maharashtra",
        "city": "Parner"
      },
      {
        "state": "Maharashtra",
        "city": "Parola"
      },
      {
        "state": "Maharashtra",
        "city": "Parsamal"
      },
      {
        "state": "Maharashtra",
        "city": "Parshivni"
      },
      {
        "state": "Maharashtra",
        "city": "Partur"
      },
      {
        "state": "Maharashtra",
        "city": "Pathardi"
      },
      {
        "state": "Maharashtra",
        "city": "Patoda"
      },
      {
        "state": "Maharashtra",
        "city": "Pauni"
      },
      {
        "state": "Maharashtra",
        "city": "Peint"
      },
      {
        "state": "Maharashtra",
        "city": "Pen"
      },
      {
        "state": "Maharashtra",
        "city": "Peth"
      },
      {
        "state": "Maharashtra",
        "city": "Peth Umri"
      },
      {
        "state": "Maharashtra",
        "city": "Phaltan"
      },
      {
        "state": "Maharashtra",
        "city": "Phulambri"
      },
      {
        "state": "Maharashtra",
        "city": "Pimpalgaon Tarf Khed"
      },
      {
        "state": "Maharashtra",
        "city": "Pimpri Ashti"
      },
      {
        "state": "Maharashtra",
        "city": "Pimpri Bk.Pr.Pachora"
      },
      {
        "state": "Maharashtra",
        "city": "Pimpri-Chinchwad"
      },
      {
        "state": "Maharashtra",
        "city": "Poladpur"
      },
      {
        "state": "Maharashtra",
        "city": "Pombhurna"
      },
      {
        "state": "Maharashtra",
        "city": "Pulgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Pune"
      },
      {
        "state": "Maharashtra",
        "city": "Purna"
      },
      {
        "state": "Maharashtra",
        "city": "Pusad"
      },
      {
        "state": "Maharashtra",
        "city": "Radhanagari"
      },
      {
        "state": "Maharashtra",
        "city": "Rahata"
      },
      {
        "state": "Maharashtra",
        "city": "Rahta Pimplas"
      },
      {
        "state": "Maharashtra",
        "city": "Rahuri"
      },
      {
        "state": "Maharashtra",
        "city": "Raigad"
      },
      {
        "state": "Maharashtra",
        "city": "Rajapur"
      },
      {
        "state": "Maharashtra",
        "city": "Rajgurunagar"
      },
      {
        "state": "Maharashtra",
        "city": "Rajura"
      },
      {
        "state": "Maharashtra",
        "city": "Ralegaon"
      },
      {
        "state": "Maharashtra",
        "city": "Ramkheda"
      },
      {
        "state": "Maharashtra",
        "city": "Ramtek"
      },
      {
        "state": "Maharashtra",
        "city": "Ranala"
      },
      {
        "state": "Maharashtra",
        "city": "Ranewadi"
      },
      {
        "state": "Maharashtra",
        "city": "Ratnagiri"
      },
      {
        "state": "Maharashtra",
        "city": "Raver"
      },
      {
        "state": "Maharashtra",
        "city": "Risod"
      },
      {
        "state": "Maharashtra",
        "city": "Roha"
      },
      {
        "state": "Maharashtra",
        "city": "Sadak Arjuni"
      },
      {
        "state": "Maharashtra",
        "city": "Sakoli"
      },
      {
        "state": "Maharashtra",
        "city": "Sakri"
      },
      {
        "state": "Maharashtra",
        "city": "Salekasa"
      },
      {
        "state": "Maharashtra",
        "city": "Samudrapur"
      },
      {
        "state": "Maharashtra",
        "city": "Sangameshwar"
      },
      {
        "state": "Maharashtra",
        "city": "Sangamner"
      },
      {
        "state": "Maharashtra",
        "city": "Sangli"
      },
      {
        "state": "Maharashtra",
        "city": "Sangola"
      },
      {
        "state": "Maharashtra",
        "city": "Sangrampur"
      },
      {
        "state": "Maharashtra",
        "city": "Saoli"
      },
      {
        "state": "Maharashtra",
        "city": "Saswad"
      },
      {
        "state": "Maharashtra",
        "city": "Satana"
      },
      {
        "state": "Maharashtra",
        "city": "Satara"
      },
      {
        "state": "Maharashtra",
        "city": "Savner"
      },
      {
        "state": "Maharashtra",
        "city": "Sawali"
      },
      {
        "state": "Maharashtra",
        "city": "Sawantwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Seloo"
      },
      {
        "state": "Maharashtra",
        "city": "Selu"
      },
      {
        "state": "Maharashtra",
        "city": "Sengaon"
      },
      {
        "state": "Maharashtra",
        "city": "Shahada"
      },
      {
        "state": "Maharashtra",
        "city": "Shahade"
      },
      {
        "state": "Maharashtra",
        "city": "Shahapur"
      },
      {
        "state": "Maharashtra",
        "city": "Shahuwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Shegaon"
      },
      {
        "state": "Maharashtra",
        "city": "Shekhmirewadi"
      },
      {
        "state": "Maharashtra",
        "city": "Shevgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Shirala"
      },
      {
        "state": "Maharashtra",
        "city": "Shirol"
      },
      {
        "state": "Maharashtra",
        "city": "Shirpur"
      },
      {
        "state": "Maharashtra",
        "city": "Shirur"
      },
      {
        "state": "Maharashtra",
        "city": "Shirur Anantpal"
      },
      {
        "state": "Maharashtra",
        "city": "Shirwal"
      },
      {
        "state": "Maharashtra",
        "city": "Shrigonda"
      },
      {
        "state": "Maharashtra",
        "city": "Shrirampur"
      },
      {
        "state": "Maharashtra",
        "city": "Shrivardhan"
      },
      {
        "state": "Maharashtra",
        "city": "Sillod"
      },
      {
        "state": "Maharashtra",
        "city": "Sindewahi"
      },
      {
        "state": "Maharashtra",
        "city": "Sindhudurg"
      },
      {
        "state": "Maharashtra",
        "city": "Sindkhed Raja"
      },
      {
        "state": "Maharashtra",
        "city": "Sindkheda"
      },
      {
        "state": "Maharashtra",
        "city": "Sinnar"
      },
      {
        "state": "Maharashtra",
        "city": "Sironcha"
      },
      {
        "state": "Maharashtra",
        "city": "Sironcha Ry."
      },
      {
        "state": "Maharashtra",
        "city": "Solapur"
      },
      {
        "state": "Maharashtra",
        "city": "Sonpeth"
      },
      {
        "state": "Maharashtra",
        "city": "Soygaon"
      },
      {
        "state": "Maharashtra",
        "city": "Surgana"
      },
      {
        "state": "Maharashtra",
        "city": "Takali Ambad"
      },
      {
        "state": "Maharashtra",
        "city": "Talasari"
      },
      {
        "state": "Maharashtra",
        "city": "Taloda"
      },
      {
        "state": "Maharashtra",
        "city": "Talvat Khed"
      },
      {
        "state": "Maharashtra",
        "city": "Talvat Pal"
      },
      {
        "state": "Maharashtra",
        "city": "Tambale"
      },
      {
        "state": "Maharashtra",
        "city": "Tasgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Telhara"
      },
      {
        "state": "Maharashtra",
        "city": "Tembhari"
      },
      {
        "state": "Maharashtra",
        "city": "Teosa"
      },
      {
        "state": "Maharashtra",
        "city": "Thane"
      },
      {
        "state": "Maharashtra",
        "city": "Tirora"
      },
      {
        "state": "Maharashtra",
        "city": "Trimbak"
      },
      {
        "state": "Maharashtra",
        "city": "Tuljapur"
      },
      {
        "state": "Maharashtra",
        "city": "Tumsar"
      },
      {
        "state": "Maharashtra",
        "city": "Udgir"
      },
      {
        "state": "Maharashtra",
        "city": "Umarkhed"
      },
      {
        "state": "Maharashtra",
        "city": "Umred"
      },
      {
        "state": "Maharashtra",
        "city": "Vadgaon"
      },
      {
        "state": "Maharashtra",
        "city": "Vaibhavwadi"
      },
      {
        "state": "Maharashtra",
        "city": "Vaijapur"
      },
      {
        "state": "Maharashtra",
        "city": "Varvand"
      },
      {
        "state": "Maharashtra",
        "city": "Vasai"
      },
      {
        "state": "Maharashtra",
        "city": "Velhe"
      },
      {
        "state": "Maharashtra",
        "city": "Vengurla"
      },
      {
        "state": "Maharashtra",
        "city": "Vikramgad"
      },
      {
        "state": "Maharashtra",
        "city": "Virar"
      },
      {
        "state": "Maharashtra",
        "city": "Wada"
      },
      {
        "state": "Maharashtra",
        "city": "Wadhe"
      },
      {
        "state": "Maharashtra",
        "city": "Wadwani"
      },
      {
        "state": "Maharashtra",
        "city": "Wai"
      },
      {
        "state": "Maharashtra",
        "city": "Walwa"
      },
      {
        "state": "Maharashtra",
        "city": "Wani"
      },
      {
        "state": "Maharashtra",
        "city": "Wardha"
      },
      {
        "state": "Maharashtra",
        "city": "Warora"
      },
      {
        "state": "Maharashtra",
        "city": "Warud"
      },
      {
        "state": "Maharashtra",
        "city": "Washim"
      },
      {
        "state": "Maharashtra",
        "city": "Yavatmal"
      },
      {
        "state": "Maharashtra",
        "city": "Yawal"
      },
      {
        "state": "Maharashtra",
        "city": "Yeola"
      },
      {
        "state": "Manipur",
        "city": "Bishnupur"
      },
      {
        "state": "Manipur",
        "city": "Chandel"
      },
      {
        "state": "Manipur",
        "city": "Churachandpur"
      },
      {
        "state": "Manipur",
        "city": "Imphal"
      },
      {
        "state": "Manipur",
        "city": "Imphal East"
      },
      {
        "state": "Manipur",
        "city": "Imphal West"
      },
      {
        "state": "Manipur",
        "city": "Jiribam"
      },
      {
        "state": "Manipur",
        "city": "Kakching"
      },
      {
        "state": "Manipur",
        "city": "Kamjong"
      },
      {
        "state": "Manipur",
        "city": "Kangpokpi"
      },
      {
        "state": "Manipur",
        "city": "Mandop Yumpham"
      },
      {
        "state": "Manipur",
        "city": "Noney"
      },
      {
        "state": "Manipur",
        "city": "Pherzawl"
      },
      {
        "state": "Manipur",
        "city": "Senapati"
      },
      {
        "state": "Manipur",
        "city": "Tamenglong"
      },
      {
        "state": "Manipur",
        "city": "Tengnoupal"
      },
      {
        "state": "Manipur",
        "city": "Thoubal"
      },
      {
        "state": "Manipur",
        "city": "Ukhrul"
      },
      {
        "state": "Meghalaya",
        "city": "Amlarem"
      },
      {
        "state": "Meghalaya",
        "city": "Ampati"
      },
      {
        "state": "Meghalaya",
        "city": "Araimile"
      },
      {
        "state": "Meghalaya",
        "city": "Baghmara"
      },
      {
        "state": "Meghalaya",
        "city": "Balat"
      },
      {
        "state": "Meghalaya",
        "city": "Barengapara"
      },
      {
        "state": "Meghalaya",
        "city": "Bholaganj Bazar"
      },
      {
        "state": "Meghalaya",
        "city": "Byrnihat"
      },
      {
        "state": "Meghalaya",
        "city": "Cherrabazar"
      },
      {
        "state": "Meghalaya",
        "city": "Cherrapunjee"
      },
      {
        "state": "Meghalaya",
        "city": "Dambo Rongjeng"
      },
      {
        "state": "Meghalaya",
        "city": "Dawki"
      },
      {
        "state": "Meghalaya",
        "city": "Dobasipara"
      },
      {
        "state": "Meghalaya",
        "city": "Dudnai"
      },
      {
        "state": "Meghalaya",
        "city": "East Khasi Hills"
      },
      {
        "state": "Meghalaya",
        "city": "Fulbari"
      },
      {
        "state": "Meghalaya",
        "city": "Garobadha"
      },
      {
        "state": "Meghalaya",
        "city": "Jowai"
      },
      {
        "state": "Meghalaya",
        "city": "Khliehriat"
      },
      {
        "state": "Meghalaya",
        "city": "Laban"
      },
      {
        "state": "Meghalaya",
        "city": "Lad Rymbai"
      },
      {
        "state": "Meghalaya",
        "city": "Laitkor Peak"
      },
      {
        "state": "Meghalaya",
        "city": "Madanriting"
      },
      {
        "state": "Meghalaya",
        "city": "Mahendraganj"
      },
      {
        "state": "Meghalaya",
        "city": "Mairang"
      },
      {
        "state": "Meghalaya",
        "city": "Mawkynrew"
      },
      {
        "state": "Meghalaya",
        "city": "Mawkyrwat"
      },
      {
        "state": "Meghalaya",
        "city": "Mawlai"
      },
      {
        "state": "Meghalaya",
        "city": "Mawpat"
      },
      {
        "state": "Meghalaya",
        "city": "Mawphlang"
      },
      {
        "state": "Meghalaya",
        "city": "Mawsynram"
      },
      {
        "state": "Meghalaya",
        "city": "Mendipathar"
      },
      {
        "state": "Meghalaya",
        "city": "Nangwalbibra"
      },
      {
        "state": "Meghalaya",
        "city": "Nonglyer"
      },
      {
        "state": "Meghalaya",
        "city": "Nongpoh"
      },
      {
        "state": "Meghalaya",
        "city": "Nongstoin"
      },
      {
        "state": "Meghalaya",
        "city": "North Garo Hills"
      },
      {
        "state": "Meghalaya",
        "city": "Phulbari"
      },
      {
        "state": "Meghalaya",
        "city": "Pynursla"
      },
      {
        "state": "Meghalaya",
        "city": "Resubelpara"
      },
      {
        "state": "Meghalaya",
        "city": "Rongjeng"
      },
      {
        "state": "Meghalaya",
        "city": "Rongra"
      },
      {
        "state": "Meghalaya",
        "city": "Rynjah"
      },
      {
        "state": "Meghalaya",
        "city": "Shella"
      },
      {
        "state": "Meghalaya",
        "city": "Shillong"
      },
      {
        "state": "Meghalaya",
        "city": "Smit"
      },
      {
        "state": "Meghalaya",
        "city": "South West Garo Hills"
      },
      {
        "state": "Meghalaya",
        "city": "South West Khasi Hills"
      },
      {
        "state": "Meghalaya",
        "city": "Sumer"
      },
      {
        "state": "Meghalaya",
        "city": "Synrang Kaban"
      },
      {
        "state": "Meghalaya",
        "city": "Tikrikilla"
      },
      {
        "state": "Meghalaya",
        "city": "Tura"
      },
      {
        "state": "Meghalaya",
        "city": "UCC"
      },
      {
        "state": "Meghalaya",
        "city": "Umiam"
      },
      {
        "state": "Meghalaya",
        "city": "Umsaw"
      },
      {
        "state": "Meghalaya",
        "city": "Umsning"
      },
      {
        "state": "Meghalaya",
        "city": "West Khasi Hills"
      },
      {
        "state": "Meghalaya",
        "city": "Williamnagar"
      },
      {
        "state": "Mizoram",
        "city": "Aibawk"
      },
      {
        "state": "Mizoram",
        "city": "Aizawl"
      },
      {
        "state": "Mizoram",
        "city": "Bawngkawn"
      },
      {
        "state": "Mizoram",
        "city": "Bilkhawthlir"
      },
      {
        "state": "Mizoram",
        "city": "Bunghmun"
      },
      {
        "state": "Mizoram",
        "city": "Champhai"
      },
      {
        "state": "Mizoram",
        "city": "Chawngte"
      },
      {
        "state": "Mizoram",
        "city": "Darlawn"
      },
      {
        "state": "Mizoram",
        "city": "Demagiri"
      },
      {
        "state": "Mizoram",
        "city": "East Lungdar"
      },
      {
        "state": "Mizoram",
        "city": "Hanhthial"
      },
      {
        "state": "Mizoram",
        "city": "Kawnpui"
      },
      {
        "state": "Mizoram",
        "city": "Khawbung"
      },
      {
        "state": "Mizoram",
        "city": "Khawzawl"
      },
      {
        "state": "Mizoram",
        "city": "Kolasib"
      },
      {
        "state": "Mizoram",
        "city": "Kulikawn"
      },
      {
        "state": "Mizoram",
        "city": "Lawngtlai"
      },
      {
        "state": "Mizoram",
        "city": "Lengpui"
      },
      {
        "state": "Mizoram",
        "city": "Lunglei"
      },
      {
        "state": "Mizoram",
        "city": "Lungsen"
      },
      {
        "state": "Mizoram",
        "city": "Mamit"
      },
      {
        "state": "Mizoram",
        "city": "N.Kawnpui"
      },
      {
        "state": "Mizoram",
        "city": "Ngopa"
      },
      {
        "state": "Mizoram",
        "city": "North Thingdawl"
      },
      {
        "state": "Mizoram",
        "city": "Phaileng"
      },
      {
        "state": "Mizoram",
        "city": "Phullen"
      },
      {
        "state": "Mizoram",
        "city": "Reiek"
      },
      {
        "state": "Mizoram",
        "city": "S.khawbung"
      },
      {
        "state": "Mizoram",
        "city": "Sairang"
      },
      {
        "state": "Mizoram",
        "city": "Saitual"
      },
      {
        "state": "Mizoram",
        "city": "Sangau"
      },
      {
        "state": "Mizoram",
        "city": "Serchhip"
      },
      {
        "state": "Mizoram",
        "city": "Siaha"
      },
      {
        "state": "Mizoram",
        "city": "Sialsuk"
      },
      {
        "state": "Mizoram",
        "city": "Sihphir"
      },
      {
        "state": "Mizoram",
        "city": "Thingsai"
      },
      {
        "state": "Mizoram",
        "city": "Thingsulthliah"
      },
      {
        "state": "Mizoram",
        "city": "Tlabung"
      },
      {
        "state": "Mizoram",
        "city": "Tuipang"
      },
      {
        "state": "Mizoram",
        "city": "Vairengte"
      },
      {
        "state": "Mizoram",
        "city": "W.Bunghmun"
      },
      {
        "state": "Mizoram",
        "city": "Zawlnuam"
      },
      {
        "state": "Mizoram",
        "city": "Zemabawk"
      },
      {
        "state": "Mizoram",
        "city": "Zotlang"
      },
      {
        "state": "Nagaland",
        "city": "Aghunato"
      },
      {
        "state": "Nagaland",
        "city": "Akuluto"
      },
      {
        "state": "Nagaland",
        "city": "Artc"
      },
      {
        "state": "Nagaland",
        "city": "Atoizu"
      },
      {
        "state": "Nagaland",
        "city": "Bhandari"
      },
      {
        "state": "Nagaland",
        "city": "Champang"
      },
      {
        "state": "Nagaland",
        "city": "Changtongya"
      },
      {
        "state": "Nagaland",
        "city": "Chiechama"
      },
      {
        "state": "Nagaland",
        "city": "Chizami"
      },
      {
        "state": "Nagaland",
        "city": "Chozuba"
      },
      {
        "state": "Nagaland",
        "city": "Chuchulang"
      },
      {
        "state": "Nagaland",
        "city": "Chuchuyimlang"
      },
      {
        "state": "Nagaland",
        "city": "Chumukedima"
      },
      {
        "state": "Nagaland",
        "city": "Dimapur"
      },
      {
        "state": "Nagaland",
        "city": "Doyang Hydro Project"
      },
      {
        "state": "Nagaland",
        "city": "Ghathashi"
      },
      {
        "state": "Nagaland",
        "city": "Imphal"
      },
      {
        "state": "Nagaland",
        "city": "Impur"
      },
      {
        "state": "Nagaland",
        "city": "Jalukie"
      },
      {
        "state": "Nagaland",
        "city": "Kiphire"
      },
      {
        "state": "Nagaland",
        "city": "Kohima"
      },
      {
        "state": "Nagaland",
        "city": "Longkhim"
      },
      {
        "state": "Nagaland",
        "city": "Longleng"
      },
      {
        "state": "Nagaland",
        "city": "Mangkolemba"
      },
      {
        "state": "Nagaland",
        "city": "Medziphema"
      },
      {
        "state": "Nagaland",
        "city": "Meluri"
      },
      {
        "state": "Nagaland",
        "city": "Mokokchung"
      },
      {
        "state": "Nagaland",
        "city": "Mon"
      },
      {
        "state": "Nagaland",
        "city": "Naginimora"
      },
      {
        "state": "Nagaland",
        "city": "Noklak"
      },
      {
        "state": "Nagaland",
        "city": "Ongpang Kong"
      },
      {
        "state": "Nagaland",
        "city": "Peren"
      },
      {
        "state": "Nagaland",
        "city": "Pfutsero"
      },
      {
        "state": "Nagaland",
        "city": "Phek"
      },
      {
        "state": "Nagaland",
        "city": "Tizit"
      },
      {
        "state": "Nagaland",
        "city": "Tseminyu"
      },
      {
        "state": "Nagaland",
        "city": "Tuensang"
      },
      {
        "state": "Nagaland",
        "city": "Tuli"
      },
      {
        "state": "Nagaland",
        "city": "Wokha"
      },
      {
        "state": "Nagaland",
        "city": "Zakhama"
      },
      {
        "state": "Nagaland",
        "city": "Zalukie"
      },
      {
        "state": "Nagaland",
        "city": "Zunheboto"
      },
      {
        "state": "Odisha",
        "city": "Adava"
      },
      {
        "state": "Odisha",
        "city": "Agalpur"
      },
      {
        "state": "Odisha",
        "city": "Ambabhona"
      },
      {
        "state": "Odisha",
        "city": "Anandapur"
      },
      {
        "state": "Odisha",
        "city": "Angargan"
      },
      {
        "state": "Odisha",
        "city": "Angul"
      },
      {
        "state": "Odisha",
        "city": "Ankush Pur"
      },
      {
        "state": "Odisha",
        "city": "Asika"
      },
      {
        "state": "Odisha",
        "city": "Athagarh"
      },
      {
        "state": "Odisha",
        "city": "Athmallik"
      },
      {
        "state": "Odisha",
        "city": "Attabira"
      },
      {
        "state": "Odisha",
        "city": "Aul"
      },
      {
        "state": "Odisha",
        "city": "Badamba"
      },
      {
        "state": "Odisha",
        "city": "Badasahi"
      },
      {
        "state": "Odisha",
        "city": "Badmal"
      },
      {
        "state": "Odisha",
        "city": "Badnuagaon"
      },
      {
        "state": "Odisha",
        "city": "Baghiabahal"
      },
      {
        "state": "Odisha",
        "city": "Bahalda"
      },
      {
        "state": "Odisha",
        "city": "Balangir"
      },
      {
        "state": "Odisha",
        "city": "Balasore"
      },
      {
        "state": "Odisha",
        "city": "Balianta"
      },
      {
        "state": "Odisha",
        "city": "Baliapal"
      },
      {
        "state": "Odisha",
        "city": "Balikuda"
      },
      {
        "state": "Odisha",
        "city": "Balimi"
      },
      {
        "state": "Odisha",
        "city": "Balipatna"
      },
      {
        "state": "Odisha",
        "city": "Balisankara"
      },
      {
        "state": "Odisha",
        "city": "Balliguda"
      },
      {
        "state": "Odisha",
        "city": "Bamunigaon"
      },
      {
        "state": "Odisha",
        "city": "Banaharapali"
      },
      {
        "state": "Odisha",
        "city": "Banapur"
      },
      {
        "state": "Odisha",
        "city": "Banarpal"
      },
      {
        "state": "Odisha",
        "city": "Banei"
      },
      {
        "state": "Odisha",
        "city": "Bangomunda"
      },
      {
        "state": "Odisha",
        "city": "Banki"
      },
      {
        "state": "Odisha",
        "city": "Banspal"
      },
      {
        "state": "Odisha",
        "city": "Barakhama"
      },
      {
        "state": "Odisha",
        "city": "Barang"
      },
      {
        "state": "Odisha",
        "city": "Barapali Town"
      },
      {
        "state": "Odisha",
        "city": "Barasahi"
      },
      {
        "state": "Odisha",
        "city": "Barbil"
      },
      {
        "state": "Odisha",
        "city": "Bargarh"
      },
      {
        "state": "Odisha",
        "city": "Baripada"
      },
      {
        "state": "Odisha",
        "city": "Barkote"
      },
      {
        "state": "Odisha",
        "city": "Barpali"
      },
      {
        "state": "Odisha",
        "city": "Barsahi"
      },
      {
        "state": "Odisha",
        "city": "Basudebpur"
      },
      {
        "state": "Odisha",
        "city": "Baunsuni"
      },
      {
        "state": "Odisha",
        "city": "Begunia"
      },
      {
        "state": "Odisha",
        "city": "Belpada"
      },
      {
        "state": "Odisha",
        "city": "Belpahar"
      },
      {
        "state": "Odisha",
        "city": "Betnoti"
      },
      {
        "state": "Odisha",
        "city": "Bhadrak"
      },
      {
        "state": "Odisha",
        "city": "Bhadrak Rural"
      },
      {
        "state": "Odisha",
        "city": "Bhairabsingipur"
      },
      {
        "state": "Odisha",
        "city": "Bhalumunda"
      },
      {
        "state": "Odisha",
        "city": "Bhandari Pokhari"
      },
      {
        "state": "Odisha",
        "city": "Bhanjanagar"
      },
      {
        "state": "Odisha",
        "city": "Bhapur"
      },
      {
        "state": "Odisha",
        "city": "Bharchhan"
      },
      {
        "state": "Odisha",
        "city": "Bhasma"
      },
      {
        "state": "Odisha",
        "city": "Bhatli"
      },
      {
        "state": "Odisha",
        "city": "Bhawanipatna"
      },
      {
        "state": "Odisha",
        "city": "Bheden"
      },
      {
        "state": "Odisha",
        "city": "Bhishmagiri"
      },
      {
        "state": "Odisha",
        "city": "Bhograi"
      },
      {
        "state": "Odisha",
        "city": "Bhuban"
      },
      {
        "state": "Odisha",
        "city": "Bhubaneswar"
      },
      {
        "state": "Odisha",
        "city": "Bhubanpur"
      },
      {
        "state": "Odisha",
        "city": "Bidyadharpur"
      },
      {
        "state": "Odisha",
        "city": "Bijepur"
      },
      {
        "state": "Odisha",
        "city": "Binjharpur"
      },
      {
        "state": "Odisha",
        "city": "Binka"
      },
      {
        "state": "Odisha",
        "city": "Biranarasinghpur"
      },
      {
        "state": "Odisha",
        "city": "Biridi"
      },
      {
        "state": "Odisha",
        "city": "Biripali"
      },
      {
        "state": "Odisha",
        "city": "Birmaharajpur"
      },
      {
        "state": "Odisha",
        "city": "Birmitrapur"
      },
      {
        "state": "Odisha",
        "city": "Bishama Katek"
      },
      {
        "state": "Odisha",
        "city": "Bissamcuttack"
      },
      {
        "state": "Odisha",
        "city": "Boden"
      },
      {
        "state": "Odisha",
        "city": "Boipariguda"
      },
      {
        "state": "Odisha",
        "city": "Bolagarh"
      },
      {
        "state": "Odisha",
        "city": "Bonaigarh"
      },
      {
        "state": "Odisha",
        "city": "Borigumma"
      },
      {
        "state": "Odisha",
        "city": "Boudh"
      },
      {
        "state": "Odisha",
        "city": "Bounsuni"
      },
      {
        "state": "Odisha",
        "city": "Brahmagiri"
      },
      {
        "state": "Odisha",
        "city": "Brahmapur"
      },
      {
        "state": "Odisha",
        "city": "Budhi Padar"
      },
      {
        "state": "Odisha",
        "city": "Buguda"
      },
      {
        "state": "Odisha",
        "city": "Burla"
      },
      {
        "state": "Odisha",
        "city": "Chakapada"
      },
      {
        "state": "Odisha",
        "city": "Champua"
      },
      {
        "state": "Odisha",
        "city": "Chandaka"
      },
      {
        "state": "Odisha",
        "city": "Chandbali"
      },
      {
        "state": "Odisha",
        "city": "Chandikhol"
      },
      {
        "state": "Odisha",
        "city": "Chatrapur"
      },
      {
        "state": "Odisha",
        "city": "Chaudwar"
      },
      {
        "state": "Odisha",
        "city": "Chhendipada"
      },
      {
        "state": "Odisha",
        "city": "Chikiti"
      },
      {
        "state": "Odisha",
        "city": "Chilika"
      },
      {
        "state": "Odisha",
        "city": "Chitrakonda"
      },
      {
        "state": "Odisha",
        "city": "Chudapali"
      },
      {
        "state": "Odisha",
        "city": "Colliery"
      },
      {
        "state": "Odisha",
        "city": "Cuttack"
      },
      {
        "state": "Odisha",
        "city": "Dabugaon"
      },
      {
        "state": "Odisha",
        "city": "Damanjodi"
      },
      {
        "state": "Odisha",
        "city": "Daringbadi"
      },
      {
        "state": "Odisha",
        "city": "Dasmanthpur"
      },
      {
        "state": "Odisha",
        "city": "Daspalla"
      },
      {
        "state": "Odisha",
        "city": "Debagarh"
      },
      {
        "state": "Odisha",
        "city": "Deogaon"
      },
      {
        "state": "Odisha",
        "city": "Deogarh"
      },
      {
        "state": "Odisha",
        "city": "Dhamanagar"
      },
      {
        "state": "Odisha",
        "city": "Dharmagarh"
      },
      {
        "state": "Odisha",
        "city": "Dharmasala"
      },
      {
        "state": "Odisha",
        "city": "Dhaurapali"
      },
      {
        "state": "Odisha",
        "city": "Dhenkanal"
      },
      {
        "state": "Odisha",
        "city": "Dhenkanal Sadar"
      },
      {
        "state": "Odisha",
        "city": "Digapahandi"
      },
      {
        "state": "Odisha",
        "city": "Duajhar"
      },
      {
        "state": "Odisha",
        "city": "Dumerpali"
      },
      {
        "state": "Odisha",
        "city": "Ersama"
      },
      {
        "state": "Odisha",
        "city": "Fulaalasa"
      },
      {
        "state": "Odisha",
        "city": "G Udayagiri"
      },
      {
        "state": "Odisha",
        "city": "Gaisilat"
      },
      {
        "state": "Odisha",
        "city": "Gajapati"
      },
      {
        "state": "Odisha",
        "city": "Gandia"
      },
      {
        "state": "Odisha",
        "city": "Ganjam"
      },
      {
        "state": "Odisha",
        "city": "Garabandha"
      },
      {
        "state": "Odisha",
        "city": "Ghantapada"
      },
      {
        "state": "Odisha",
        "city": "Ghatgaon"
      },
      {
        "state": "Odisha",
        "city": "Girisola"
      },
      {
        "state": "Odisha",
        "city": "Gobindanagar"
      },
      {
        "state": "Odisha",
        "city": "Gobindapur"
      },
      {
        "state": "Odisha",
        "city": "Golamunda"
      },
      {
        "state": "Odisha",
        "city": "Gudari"
      },
      {
        "state": "Odisha",
        "city": "Gunderpur"
      },
      {
        "state": "Odisha",
        "city": "Gunupur"
      },
      {
        "state": "Odisha",
        "city": "Gurundia"
      },
      {
        "state": "Odisha",
        "city": "Harabhanga"
      },
      {
        "state": "Odisha",
        "city": "Hasanabad chaka badachana"
      },
      {
        "state": "Odisha",
        "city": "Hatadihi"
      },
      {
        "state": "Odisha",
        "city": "Hatibari"
      },
      {
        "state": "Odisha",
        "city": "Hemgir"
      },
      {
        "state": "Odisha",
        "city": "Hinjillikatu"
      },
      {
        "state": "Odisha",
        "city": "Hirakud"
      },
      {
        "state": "Odisha",
        "city": "Iswarpur"
      },
      {
        "state": "Odisha",
        "city": "Jagatsinghpur"
      },
      {
        "state": "Odisha",
        "city": "Jaipatna"
      },
      {
        "state": "Odisha",
        "city": "Jajapur"
      },
      {
        "state": "Odisha",
        "city": "Jajpur"
      },
      {
        "state": "Odisha",
        "city": "Jajpur Road"
      },
      {
        "state": "Odisha",
        "city": "Jaleswar"
      },
      {
        "state": "Odisha",
        "city": "Jamankira"
      },
      {
        "state": "Odisha",
        "city": "Jarada"
      },
      {
        "state": "Odisha",
        "city": "Jatni"
      },
      {
        "state": "Odisha",
        "city": "Jeypore"
      },
      {
        "state": "Odisha",
        "city": "Jhadabai"
      },
      {
        "state": "Odisha",
        "city": "Jhadrajing"
      },
      {
        "state": "Odisha",
        "city": "Jhagrahi"
      },
      {
        "state": "Odisha",
        "city": "Jharbandh"
      },
      {
        "state": "Odisha",
        "city": "Jharigaon"
      },
      {
        "state": "Odisha",
        "city": "Jharsuguda"
      },
      {
        "state": "Odisha",
        "city": "Joketa"
      },
      {
        "state": "Odisha",
        "city": "Jujumura"
      },
      {
        "state": "Odisha",
        "city": "Junagarh"
      },
      {
        "state": "Odisha",
        "city": "Kakatpur"
      },
      {
        "state": "Odisha",
        "city": "Kalahandi"
      },
      {
        "state": "Odisha",
        "city": "Kalampur"
      },
      {
        "state": "Odisha",
        "city": "Kalimela"
      },
      {
        "state": "Odisha",
        "city": "Kalinga"
      },
      {
        "state": "Odisha",
        "city": "Kalosihiria"
      },
      {
        "state": "Odisha",
        "city": "Kalyansingpur"
      },
      {
        "state": "Odisha",
        "city": "Kamakhyanagar"
      },
      {
        "state": "Odisha",
        "city": "Kandhamal"
      },
      {
        "state": "Odisha",
        "city": "Kaniha"
      },
      {
        "state": "Odisha",
        "city": "Kantabanji"
      },
      {
        "state": "Odisha",
        "city": "Kantamal"
      },
      {
        "state": "Odisha",
        "city": "Karanjia"
      },
      {
        "state": "Odisha",
        "city": "Karlamunda"
      },
      {
        "state": "Odisha",
        "city": "Kashinagar"
      },
      {
        "state": "Odisha",
        "city": "Katarbaga"
      },
      {
        "state": "Odisha",
        "city": "Kavisuryanagar"
      },
      {
        "state": "Odisha",
        "city": "Kendrapara"
      },
      {
        "state": "Odisha",
        "city": "Kendujhar"
      },
      {
        "state": "Odisha",
        "city": "Keonjhar"
      },
      {
        "state": "Odisha",
        "city": "Kesannagar"
      },
      {
        "state": "Odisha",
        "city": "Kesinga"
      },
      {
        "state": "Odisha",
        "city": "Khajuripada"
      },
      {
        "state": "Odisha",
        "city": "Khallikot"
      },
      {
        "state": "Odisha",
        "city": "Khandapada"
      },
      {
        "state": "Odisha",
        "city": "Khandapra"
      },
      {
        "state": "Odisha",
        "city": "Khaprakhol"
      },
      {
        "state": "Odisha",
        "city": "Khariar"
      },
      {
        "state": "Odisha",
        "city": "Khariar Road"
      },
      {
        "state": "Odisha",
        "city": "Khatiguda"
      },
      {
        "state": "Odisha",
        "city": "Khinimung"
      },
      {
        "state": "Odisha",
        "city": "Khordha"
      },
      {
        "state": "Odisha",
        "city": "Khunta"
      },
      {
        "state": "Odisha",
        "city": "Khuntagaon"
      },
      {
        "state": "Odisha",
        "city": "Khurdha"
      },
      {
        "state": "Odisha",
        "city": "Kinjirkela"
      },
      {
        "state": "Odisha",
        "city": "Kirmira"
      },
      {
        "state": "Odisha",
        "city": "Kisinda"
      },
      {
        "state": "Odisha",
        "city": "Kodala"
      },
      {
        "state": "Odisha",
        "city": "Kodinga"
      },
      {
        "state": "Odisha",
        "city": "Koira"
      },
      {
        "state": "Odisha",
        "city": "Kolabira"
      },
      {
        "state": "Odisha",
        "city": "Konark"
      },
      {
        "state": "Odisha",
        "city": "Koraput"
      },
      {
        "state": "Odisha",
        "city": "Korua"
      },
      {
        "state": "Odisha",
        "city": "Kotagad"
      },
      {
        "state": "Odisha",
        "city": "Kotapad"
      },
      {
        "state": "Odisha",
        "city": "Kothagarh"
      },
      {
        "state": "Odisha",
        "city": "Krafadgang"
      },
      {
        "state": "Odisha",
        "city": "Krishnaprasad"
      },
      {
        "state": "Odisha",
        "city": "Krushnaprasad"
      },
      {
        "state": "Odisha",
        "city": "Kuanrmunda"
      },
      {
        "state": "Odisha",
        "city": "Kuchinda"
      },
      {
        "state": "Odisha",
        "city": "Kujang"
      },
      {
        "state": "Odisha",
        "city": "Kundheigola"
      },
      {
        "state": "Odisha",
        "city": "Kundura"
      },
      {
        "state": "Odisha",
        "city": "Kupari"
      },
      {
        "state": "Odisha",
        "city": "Kurtamgarh"
      },
      {
        "state": "Odisha",
        "city": "Kutra"
      },
      {
        "state": "Odisha",
        "city": "Lahunipara"
      },
      {
        "state": "Odisha",
        "city": "Laikera"
      },
      {
        "state": "Odisha",
        "city": "Lanjigarh"
      },
      {
        "state": "Odisha",
        "city": "Lanjigarh Road"
      },
      {
        "state": "Odisha",
        "city": "Lathikata"
      },
      {
        "state": "Odisha",
        "city": "Lephripara"
      },
      {
        "state": "Odisha",
        "city": "Line Pada"
      },
      {
        "state": "Odisha",
        "city": "Loisingha"
      },
      {
        "state": "Odisha",
        "city": "Machh kund"
      },
      {
        "state": "Odisha",
        "city": "Madanpur Rampur"
      },
      {
        "state": "Odisha",
        "city": "Mahulpada"
      },
      {
        "state": "Odisha",
        "city": "Mahulpali"
      },
      {
        "state": "Odisha",
        "city": "Malkangiri"
      },
      {
        "state": "Odisha",
        "city": "Manamunda"
      },
      {
        "state": "Odisha",
        "city": "Maneswar"
      },
      {
        "state": "Odisha",
        "city": "Mankha"
      },
      {
        "state": "Odisha",
        "city": "Markona"
      },
      {
        "state": "Odisha",
        "city": "Marshaghai"
      },
      {
        "state": "Odisha",
        "city": "Mathili"
      },
      {
        "state": "Odisha",
        "city": "Mauliberena"
      },
      {
        "state": "Odisha",
        "city": "Melchhamunda"
      },
      {
        "state": "Odisha",
        "city": "Mohana"
      },
      {
        "state": "Odisha",
        "city": "Motunga"
      },
      {
        "state": "Odisha",
        "city": "Mundali"
      },
      {
        "state": "Odisha",
        "city": "Muniguda"
      },
      {
        "state": "Odisha",
        "city": "Nabarangapur"
      },
      {
        "state": "Odisha",
        "city": "Nachhipur"
      },
      {
        "state": "Odisha",
        "city": "Naktideul"
      },
      {
        "state": "Odisha",
        "city": "Nandapur"
      },
      {
        "state": "Odisha",
        "city": "Nandipada"
      },
      {
        "state": "Odisha",
        "city": "Narasinghpur"
      },
      {
        "state": "Odisha",
        "city": "Narayanpatna"
      },
      {
        "state": "Odisha",
        "city": "Narla"
      },
      {
        "state": "Odisha",
        "city": "Nayagarh"
      },
      {
        "state": "Odisha",
        "city": "Niali"
      },
      {
        "state": "Odisha",
        "city": "Nilagiri"
      },
      {
        "state": "Odisha",
        "city": "Nimapada"
      },
      {
        "state": "Odisha",
        "city": "Nisankapur"
      },
      {
        "state": "Odisha",
        "city": "Nizigarh"
      },
      {
        "state": "Odisha",
        "city": "Nowrangpur"
      },
      {
        "state": "Odisha",
        "city": "Nuagam"
      },
      {
        "state": "Odisha",
        "city": "Nuagaon"
      },
      {
        "state": "Odisha",
        "city": "Nuapara"
      },
      {
        "state": "Odisha",
        "city": "Odagaon"
      },
      {
        "state": "Odisha",
        "city": "Orkel"
      },
      {
        "state": "Odisha",
        "city": "Paburia"
      },
      {
        "state": "Odisha",
        "city": "Padmapur"
      },
      {
        "state": "Odisha",
        "city": "Padmapur Town"
      },
      {
        "state": "Odisha",
        "city": "Paduwa"
      },
      {
        "state": "Odisha",
        "city": "Paikamal"
      },
      {
        "state": "Odisha",
        "city": "Paikmal"
      },
      {
        "state": "Odisha",
        "city": "Palasahi"
      },
      {
        "state": "Odisha",
        "city": "Pallahara"
      },
      {
        "state": "Odisha",
        "city": "Palsada"
      },
      {
        "state": "Odisha",
        "city": "Pappadahandi"
      },
      {
        "state": "Odisha",
        "city": "Paradeep"
      },
      {
        "state": "Odisha",
        "city": "Paralakhemundi"
      },
      {
        "state": "Odisha",
        "city": "Parjang"
      },
      {
        "state": "Odisha",
        "city": "Parmanandapur"
      },
      {
        "state": "Odisha",
        "city": "Patkura"
      },
      {
        "state": "Odisha",
        "city": "Patnagarh"
      },
      {
        "state": "Odisha",
        "city": "Patrapali"
      },
      {
        "state": "Odisha",
        "city": "Pattamundai"
      },
      {
        "state": "Odisha",
        "city": "Phiringia"
      },
      {
        "state": "Odisha",
        "city": "Phulbani"
      },
      {
        "state": "Odisha",
        "city": "Phulnakhara"
      },
      {
        "state": "Odisha",
        "city": "Pindapadar"
      },
      {
        "state": "Odisha",
        "city": "Pipili"
      },
      {
        "state": "Odisha",
        "city": "Pottangi"
      },
      {
        "state": "Odisha",
        "city": "Puranakatak"
      },
      {
        "state": "Odisha",
        "city": "Puri"
      },
      {
        "state": "Odisha",
        "city": "Purusandha"
      },
      {
        "state": "Odisha",
        "city": "Purushottampur"
      },
      {
        "state": "Odisha",
        "city": "Purusottampur"
      },
      {
        "state": "Odisha",
        "city": "R.udayagiri"
      },
      {
        "state": "Odisha",
        "city": "Raghunathapali"
      },
      {
        "state": "Odisha",
        "city": "Raghurajpur"
      },
      {
        "state": "Odisha",
        "city": "Raibania"
      },
      {
        "state": "Odisha",
        "city": "Raighar"
      },
      {
        "state": "Odisha",
        "city": "Raikia"
      },
      {
        "state": "Odisha",
        "city": "Rairangpur"
      },
      {
        "state": "Odisha",
        "city": "Raj-Ranpur"
      },
      {
        "state": "Odisha",
        "city": "Rajgangpur"
      },
      {
        "state": "Odisha",
        "city": "Rajkanika"
      },
      {
        "state": "Odisha",
        "city": "Rajnagar"
      },
      {
        "state": "Odisha",
        "city": "Rambha"
      },
      {
        "state": "Odisha",
        "city": "Randia"
      },
      {
        "state": "Odisha",
        "city": "Ranjagola"
      },
      {
        "state": "Odisha",
        "city": "Rasgovindpur"
      },
      {
        "state": "Odisha",
        "city": "Rasol"
      },
      {
        "state": "Odisha",
        "city": "Rayagada"
      },
      {
        "state": "Odisha",
        "city": "Reamal"
      },
      {
        "state": "Odisha",
        "city": "Redhakhol"
      },
      {
        "state": "Odisha",
        "city": "Remuna"
      },
      {
        "state": "Odisha",
        "city": "Rengali"
      },
      {
        "state": "Odisha",
        "city": "Rengali Dam Projectship"
      },
      {
        "state": "Odisha",
        "city": "Rengali Project Town"
      },
      {
        "state": "Odisha",
        "city": "Rondapali"
      },
      {
        "state": "Odisha",
        "city": "Rourkela"
      },
      {
        "state": "Odisha",
        "city": "Rupsa"
      },
      {
        "state": "Odisha",
        "city": "S.rampur"
      },
      {
        "state": "Odisha",
        "city": "Saintala"
      },
      {
        "state": "Odisha",
        "city": "Sakhigopal"
      },
      {
        "state": "Odisha",
        "city": "Salapada"
      },
      {
        "state": "Odisha",
        "city": "Salipur"
      },
      {
        "state": "Odisha",
        "city": "Samal Barrage"
      },
      {
        "state": "Odisha",
        "city": "Sambalpur"
      },
      {
        "state": "Odisha",
        "city": "Sankarakhole"
      },
      {
        "state": "Odisha",
        "city": "Sarangada"
      },
      {
        "state": "Odisha",
        "city": "Sarsara"
      },
      {
        "state": "Odisha",
        "city": "Satmile"
      },
      {
        "state": "Odisha",
        "city": "Similia"
      },
      {
        "state": "Odisha",
        "city": "Similiguda"
      },
      {
        "state": "Odisha",
        "city": "Simulia"
      },
      {
        "state": "Odisha",
        "city": "Sinapali"
      },
      {
        "state": "Odisha",
        "city": "Sindhekela"
      },
      {
        "state": "Odisha",
        "city": "Sohela"
      },
      {
        "state": "Odisha",
        "city": "Sonepur"
      },
      {
        "state": "Odisha",
        "city": "Soro"
      },
      {
        "state": "Odisha",
        "city": "Subarnapur"
      },
      {
        "state": "Odisha",
        "city": "Subdega"
      },
      {
        "state": "Odisha",
        "city": "Sukhabandh"
      },
      {
        "state": "Odisha",
        "city": "Sunabeda"
      },
      {
        "state": "Odisha",
        "city": "Sundargarh"
      },
      {
        "state": "Odisha",
        "city": "Sunguda"
      },
      {
        "state": "Odisha",
        "city": "Surada"
      },
      {
        "state": "Odisha",
        "city": "Surajapur"
      },
      {
        "state": "Odisha",
        "city": "Takadida"
      },
      {
        "state": "Odisha",
        "city": "Talasara"
      },
      {
        "state": "Odisha",
        "city": "Talcher"
      },
      {
        "state": "Odisha",
        "city": "Tangarpali"
      },
      {
        "state": "Odisha",
        "city": "Tangi"
      },
      {
        "state": "Odisha",
        "city": "Tarbha"
      },
      {
        "state": "Odisha",
        "city": "Telkoi"
      },
      {
        "state": "Odisha",
        "city": "Tentulikhunti"
      },
      {
        "state": "Odisha",
        "city": "Thakurgarh"
      },
      {
        "state": "Odisha",
        "city": "Thuamul Rampur"
      },
      {
        "state": "Odisha",
        "city": "Tigiria"
      },
      {
        "state": "Odisha",
        "city": "Tihidi"
      },
      {
        "state": "Odisha",
        "city": "Tikabali"
      },
      {
        "state": "Odisha",
        "city": "Tikkaballi"
      },
      {
        "state": "Odisha",
        "city": "Tileibani"
      },
      {
        "state": "Odisha",
        "city": "Tirtol"
      },
      {
        "state": "Odisha",
        "city": "Titilagarh"
      },
      {
        "state": "Odisha",
        "city": "Tumudibandh"
      },
      {
        "state": "Odisha",
        "city": "Tumusingha"
      },
      {
        "state": "Odisha",
        "city": "Turekela"
      },
      {
        "state": "Odisha",
        "city": "Tushra"
      },
      {
        "state": "Odisha",
        "city": "Tusura"
      },
      {
        "state": "Odisha",
        "city": "Udala"
      },
      {
        "state": "Odisha",
        "city": "Ulunda"
      },
      {
        "state": "Odisha",
        "city": "Umerkote"
      },
      {
        "state": "Odisha",
        "city": "Uparadiha"
      },
      {
        "state": "Odisha",
        "city": "Urali"
      },
      {
        "state": "Odisha",
        "city": "samkona"
      },
      {
        "state": "Puducherry",
        "city": "Bahour"
      },
      {
        "state": "Puducherry",
        "city": "Karaikal"
      },
      {
        "state": "Puducherry",
        "city": "Kottucherry"
      },
      {
        "state": "Puducherry",
        "city": "Mahe"
      },
      {
        "state": "Puducherry",
        "city": "Nannilam"
      },
      {
        "state": "Puducherry",
        "city": "Nedungadu"
      },
      {
        "state": "Puducherry",
        "city": "Neravy"
      },
      {
        "state": "Puducherry",
        "city": "Periyababu Samuthiram"
      },
      {
        "state": "Puducherry",
        "city": "Puducherry"
      },
      {
        "state": "Puducherry",
        "city": "Thiruvarur"
      },
      {
        "state": "Puducherry",
        "city": "Tirumalairayan Pattinam"
      },
      {
        "state": "Puducherry",
        "city": "Villianur"
      },
      {
        "state": "Puducherry",
        "city": "Yanam"
      },
      {
        "state": "Punjab",
        "city": "Abohar"
      },
      {
        "state": "Punjab",
        "city": "Ajnala"
      },
      {
        "state": "Punjab",
        "city": "Amloh"
      },
      {
        "state": "Punjab",
        "city": "Amritsar"
      },
      {
        "state": "Punjab",
        "city": "Amritsar Cantt."
      },
      {
        "state": "Punjab",
        "city": "Anandpur Sahib"
      },
      {
        "state": "Punjab",
        "city": "Attari"
      },
      {
        "state": "Punjab",
        "city": "Baba Bakala"
      },
      {
        "state": "Punjab",
        "city": "Bagha Purana"
      },
      {
        "state": "Punjab",
        "city": "Balachaur"
      },
      {
        "state": "Punjab",
        "city": "Banawala"
      },
      {
        "state": "Punjab",
        "city": "Bandala"
      },
      {
        "state": "Punjab",
        "city": "Banga Rural"
      },
      {
        "state": "Punjab",
        "city": "Bareta"
      },
      {
        "state": "Punjab",
        "city": "Barnala"
      },
      {
        "state": "Punjab",
        "city": "Bassi Pathana"
      },
      {
        "state": "Punjab",
        "city": "Batala"
      },
      {
        "state": "Punjab",
        "city": "Bathinda"
      },
      {
        "state": "Punjab",
        "city": "Bazidpur"
      },
      {
        "state": "Punjab",
        "city": "Beas"
      },
      {
        "state": "Punjab",
        "city": "Bela"
      },
      {
        "state": "Punjab",
        "city": "Bhagta Bhai Ka"
      },
      {
        "state": "Punjab",
        "city": "Bhai Rupa"
      },
      {
        "state": "Punjab",
        "city": "Bhaini Bagha"
      },
      {
        "state": "Punjab",
        "city": "Bhanopli"
      },
      {
        "state": "Punjab",
        "city": "Bharatgarh"
      },
      {
        "state": "Punjab",
        "city": "Bhikhiwind"
      },
      {
        "state": "Punjab",
        "city": "Bholath"
      },
      {
        "state": "Punjab",
        "city": "Bhucho Mandi"
      },
      {
        "state": "Punjab",
        "city": "Bhulath"
      },
      {
        "state": "Punjab",
        "city": "Bihala"
      },
      {
        "state": "Punjab",
        "city": "Boha"
      },
      {
        "state": "Punjab",
        "city": "Budhlada"
      },
      {
        "state": "Punjab",
        "city": "Bundala"
      },
      {
        "state": "Punjab",
        "city": "Butala"
      },
      {
        "state": "Punjab",
        "city": "Chabal Kalan"
      },
      {
        "state": "Punjab",
        "city": "Chabhal"
      },
      {
        "state": "Punjab",
        "city": "Chak"
      },
      {
        "state": "Punjab",
        "city": "Chamiari"
      },
      {
        "state": "Punjab",
        "city": "Chamkaur Sahib"
      },
      {
        "state": "Punjab",
        "city": "Chamyari"
      },
      {
        "state": "Punjab",
        "city": "Chetanpura"
      },
      {
        "state": "Punjab",
        "city": "Chhajli"
      },
      {
        "state": "Punjab",
        "city": "Chogawan"
      },
      {
        "state": "Punjab",
        "city": "Chohla Sahib"
      },
      {
        "state": "Punjab",
        "city": "Dasuya"
      },
      {
        "state": "Punjab",
        "city": "Dera Baba Nanak"
      },
      {
        "state": "Punjab",
        "city": "Dera Bassi"
      },
      {
        "state": "Punjab",
        "city": "Dhar Kalan"
      },
      {
        "state": "Punjab",
        "city": "Dharamkot"
      },
      {
        "state": "Punjab",
        "city": "Dhariwal"
      },
      {
        "state": "Punjab",
        "city": "Dhilwan"
      },
      {
        "state": "Punjab",
        "city": "Dhotian"
      },
      {
        "state": "Punjab",
        "city": "Dhuri"
      },
      {
        "state": "Punjab",
        "city": "Faridkot"
      },
      {
        "state": "Punjab",
        "city": "Fatehgarh Sahib"
      },
      {
        "state": "Punjab",
        "city": "Fatehpur Rajputan"
      },
      {
        "state": "Punjab",
        "city": "Fazilka"
      },
      {
        "state": "Punjab",
        "city": "Ferozepur"
      },
      {
        "state": "Punjab",
        "city": "Firozpur"
      },
      {
        "state": "Punjab",
        "city": "Garhshankar"
      },
      {
        "state": "Punjab",
        "city": "Gazipur"
      },
      {
        "state": "Punjab",
        "city": "Gidderbaha"
      },
      {
        "state": "Punjab",
        "city": "Gobindgarh"
      },
      {
        "state": "Punjab",
        "city": "Goindwal"
      },
      {
        "state": "Punjab",
        "city": "Goniana"
      },
      {
        "state": "Punjab",
        "city": "Gurdaspur"
      },
      {
        "state": "Punjab",
        "city": "Harike"
      },
      {
        "state": "Punjab",
        "city": "Hoshiarpur"
      },
      {
        "state": "Punjab",
        "city": "Husnar"
      },
      {
        "state": "Punjab",
        "city": "Jagraon"
      },
      {
        "state": "Punjab",
        "city": "Jaito"
      },
      {
        "state": "Punjab",
        "city": "Jalalabad"
      },
      {
        "state": "Punjab",
        "city": "Jalandhar"
      },
      {
        "state": "Punjab",
        "city": "Jandiala Guru"
      },
      {
        "state": "Punjab",
        "city": "Jhela Amda Gurdaspur"
      },
      {
        "state": "Punjab",
        "city": "Kacha Pakka"
      },
      {
        "state": "Punjab",
        "city": "Kairon"
      },
      {
        "state": "Punjab",
        "city": "Kang"
      },
      {
        "state": "Punjab",
        "city": "Kapurthala"
      },
      {
        "state": "Punjab",
        "city": "Kathu Nangal"
      },
      {
        "state": "Punjab",
        "city": "Khadur Sahib"
      },
      {
        "state": "Punjab",
        "city": "Khalra"
      },
      {
        "state": "Punjab",
        "city": "Khamanon"
      },
      {
        "state": "Punjab",
        "city": "Khanna"
      },
      {
        "state": "Punjab",
        "city": "Kharar"
      },
      {
        "state": "Punjab",
        "city": "Khasa"
      },
      {
        "state": "Punjab",
        "city": "Khem Karan"
      },
      {
        "state": "Punjab",
        "city": "Khilchian"
      },
      {
        "state": "Punjab",
        "city": "Khizrabad"
      },
      {
        "state": "Punjab",
        "city": "Kiratpur Sahib"
      },
      {
        "state": "Punjab",
        "city": "Kot Kapura"
      },
      {
        "state": "Punjab",
        "city": "Kothe Kehar Singh"
      },
      {
        "state": "Punjab",
        "city": "Kurali"
      },
      {
        "state": "Punjab",
        "city": "Ladda"
      },
      {
        "state": "Punjab",
        "city": "Lambi"
      },
      {
        "state": "Punjab",
        "city": "Langroya"
      },
      {
        "state": "Punjab",
        "city": "Lasara"
      },
      {
        "state": "Punjab",
        "city": "Lehragaga"
      },
      {
        "state": "Punjab",
        "city": "Ludhiana"
      },
      {
        "state": "Punjab",
        "city": "Lutheri"
      },
      {
        "state": "Punjab",
        "city": "Majitha"
      },
      {
        "state": "Punjab",
        "city": "Malerkotla"
      },
      {
        "state": "Punjab",
        "city": "Malout"
      },
      {
        "state": "Punjab",
        "city": "Mansa"
      },
      {
        "state": "Punjab",
        "city": "Maur"
      },
      {
        "state": "Punjab",
        "city": "Mehta Chowk"
      },
      {
        "state": "Punjab",
        "city": "Moga"
      },
      {
        "state": "Punjab",
        "city": "Mohali"
      },
      {
        "state": "Punjab",
        "city": "Moonak"
      },
      {
        "state": "Punjab",
        "city": "Mukerian"
      },
      {
        "state": "Punjab",
        "city": "Muktsar"
      },
      {
        "state": "Punjab",
        "city": "Mullanpur"
      },
      {
        "state": "Punjab",
        "city": "Mullanpur Garibdass"
      },
      {
        "state": "Punjab",
        "city": "Nabha"
      },
      {
        "state": "Punjab",
        "city": "Nakodar"
      },
      {
        "state": "Punjab",
        "city": "Naushehra Pannuan"
      },
      {
        "state": "Punjab",
        "city": "Nawanshahr"
      },
      {
        "state": "Punjab",
        "city": "Nawanshahr (Shahid Bhagat Singh Nagar)"
      },
      {
        "state": "Punjab",
        "city": "Nihal Singh Wala"
      },
      {
        "state": "Punjab",
        "city": "Pathankot"
      },
      {
        "state": "Punjab",
        "city": "Patiala"
      },
      {
        "state": "Punjab",
        "city": "Patti"
      },
      {
        "state": "Punjab",
        "city": "Payal"
      },
      {
        "state": "Punjab",
        "city": "Phagwara"
      },
      {
        "state": "Punjab",
        "city": "Phillaur"
      },
      {
        "state": "Punjab",
        "city": "Phul"
      },
      {
        "state": "Punjab",
        "city": "Preet Nagar"
      },
      {
        "state": "Punjab",
        "city": "Raikot"
      },
      {
        "state": "Punjab",
        "city": "Raja Sansi"
      },
      {
        "state": "Punjab",
        "city": "Rajpura"
      },
      {
        "state": "Punjab",
        "city": "Rampura Phul"
      },
      {
        "state": "Punjab",
        "city": "Rayya"
      },
      {
        "state": "Punjab",
        "city": "Rupnagar"
      },
      {
        "state": "Punjab",
        "city": "Sahauran"
      },
      {
        "state": "Punjab",
        "city": "Sahibzada Ajit Singh Nagar"
      },
      {
        "state": "Punjab",
        "city": "Samana"
      },
      {
        "state": "Punjab",
        "city": "Samrala"
      },
      {
        "state": "Punjab",
        "city": "Sangat"
      },
      {
        "state": "Punjab",
        "city": "Sangrur"
      },
      {
        "state": "Punjab",
        "city": "Sarai Amanat Khan"
      },
      {
        "state": "Punjab",
        "city": "Sardulgarh"
      },
      {
        "state": "Punjab",
        "city": "Sarhali"
      },
      {
        "state": "Punjab",
        "city": "Sarhali Kalan"
      },
      {
        "state": "Punjab",
        "city": "Sathiala"
      },
      {
        "state": "Punjab",
        "city": "Shaheed Bhagat Singh Nagar"
      },
      {
        "state": "Punjab",
        "city": "Shahkot"
      },
      {
        "state": "Punjab",
        "city": "Shehzada Nangal"
      },
      {
        "state": "Punjab",
        "city": "Sialba Majri"
      },
      {
        "state": "Punjab",
        "city": "Sirhind"
      },
      {
        "state": "Punjab",
        "city": "Sri Muktsar Sahib"
      },
      {
        "state": "Punjab",
        "city": "Sultanpur Lodhi"
      },
      {
        "state": "Punjab",
        "city": "Sunam"
      },
      {
        "state": "Punjab",
        "city": "Sur Singh"
      },
      {
        "state": "Punjab",
        "city": "Talwandi Sabo"
      },
      {
        "state": "Punjab",
        "city": "Tarn Taran"
      },
      {
        "state": "Punjab",
        "city": "Tarsikka"
      },
      {
        "state": "Punjab",
        "city": "Verka"
      },
      {
        "state": "Punjab",
        "city": "Zira"
      },
      {
        "state": "Punjab",
        "city": "Zirakpur"
      },
      {
        "state": "Rajasthan",
        "city": "6 Amp"
      },
      {
        "state": "Rajasthan",
        "city": "Aau"
      },
      {
        "state": "Rajasthan",
        "city": "Abu Road"
      },
      {
        "state": "Rajasthan",
        "city": "Ahore"
      },
      {
        "state": "Rajasthan",
        "city": "Ajmer"
      },
      {
        "state": "Rajasthan",
        "city": "Alawara"
      },
      {
        "state": "Rajasthan",
        "city": "Alsisar"
      },
      {
        "state": "Rajasthan",
        "city": "Alwar"
      },
      {
        "state": "Rajasthan",
        "city": "Amet"
      },
      {
        "state": "Rajasthan",
        "city": "Anupgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Arnaud"
      },
      {
        "state": "Rajasthan",
        "city": "Arnod"
      },
      {
        "state": "Rajasthan",
        "city": "Asop"
      },
      {
        "state": "Rajasthan",
        "city": "Aspur"
      },
      {
        "state": "Rajasthan",
        "city": "Bachhren"
      },
      {
        "state": "Rajasthan",
        "city": "Bagoda"
      },
      {
        "state": "Rajasthan",
        "city": "Bagora"
      },
      {
        "state": "Rajasthan",
        "city": "Bagra"
      },
      {
        "state": "Rajasthan",
        "city": "Balesar"
      },
      {
        "state": "Rajasthan",
        "city": "Bali"
      },
      {
        "state": "Rajasthan",
        "city": "Baloti"
      },
      {
        "state": "Rajasthan",
        "city": "Balotra"
      },
      {
        "state": "Rajasthan",
        "city": "Bamanwas"
      },
      {
        "state": "Rajasthan",
        "city": "Bansur"
      },
      {
        "state": "Rajasthan",
        "city": "Banswara"
      },
      {
        "state": "Rajasthan",
        "city": "Banwala"
      },
      {
        "state": "Rajasthan",
        "city": "Baran"
      },
      {
        "state": "Rajasthan",
        "city": "Bari"
      },
      {
        "state": "Rajasthan",
        "city": "Bari Sadri"
      },
      {
        "state": "Rajasthan",
        "city": "Barmer"
      },
      {
        "state": "Rajasthan",
        "city": "Barwali"
      },
      {
        "state": "Rajasthan",
        "city": "Baseri"
      },
      {
        "state": "Rajasthan",
        "city": "Bassi"
      },
      {
        "state": "Rajasthan",
        "city": "Bayana"
      },
      {
        "state": "Rajasthan",
        "city": "Baytu"
      },
      {
        "state": "Rajasthan",
        "city": "Beawar"
      },
      {
        "state": "Rajasthan",
        "city": "Begun"
      },
      {
        "state": "Rajasthan",
        "city": "Behror"
      },
      {
        "state": "Rajasthan",
        "city": "Bewar"
      },
      {
        "state": "Rajasthan",
        "city": "Bhadesar"
      },
      {
        "state": "Rajasthan",
        "city": "Bhadra"
      },
      {
        "state": "Rajasthan",
        "city": "Bhainsrorgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Bharatpur"
      },
      {
        "state": "Rajasthan",
        "city": "Bhilwara"
      },
      {
        "state": "Rajasthan",
        "city": "Bhinai"
      },
      {
        "state": "Rajasthan",
        "city": "Bhinder"
      },
      {
        "state": "Rajasthan",
        "city": "Bhinmal"
      },
      {
        "state": "Rajasthan",
        "city": "Bhiwadi"
      },
      {
        "state": "Rajasthan",
        "city": "Bhopalgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Bhupalsagar"
      },
      {
        "state": "Rajasthan",
        "city": "Bijainagar"
      },
      {
        "state": "Rajasthan",
        "city": "Bikaner"
      },
      {
        "state": "Rajasthan",
        "city": "Bilara"
      },
      {
        "state": "Rajasthan",
        "city": "Bishala"
      },
      {
        "state": "Rajasthan",
        "city": "Bonli"
      },
      {
        "state": "Rajasthan",
        "city": "Borunda"
      },
      {
        "state": "Rajasthan",
        "city": "Buhana"
      },
      {
        "state": "Rajasthan",
        "city": "Bundi"
      },
      {
        "state": "Rajasthan",
        "city": "Chadi"
      },
      {
        "state": "Rajasthan",
        "city": "Chak Jaitaran"
      },
      {
        "state": "Rajasthan",
        "city": "Chauth Ka Barwara"
      },
      {
        "state": "Rajasthan",
        "city": "Chhattargarh"
      },
      {
        "state": "Rajasthan",
        "city": "Chhoti Sadri"
      },
      {
        "state": "Rajasthan",
        "city": "Chirawa"
      },
      {
        "state": "Rajasthan",
        "city": "Chittorgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Chohtan"
      },
      {
        "state": "Rajasthan",
        "city": "Churu"
      },
      {
        "state": "Rajasthan",
        "city": "Danta Ramgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Dausa"
      },
      {
        "state": "Rajasthan",
        "city": "Dechu"
      },
      {
        "state": "Rajasthan",
        "city": "Deeg"
      },
      {
        "state": "Rajasthan",
        "city": "Degana"
      },
      {
        "state": "Rajasthan",
        "city": "Deogarh"
      },
      {
        "state": "Rajasthan",
        "city": "Desuri"
      },
      {
        "state": "Rajasthan",
        "city": "Dhamotar"
      },
      {
        "state": "Rajasthan",
        "city": "Dholpur"
      },
      {
        "state": "Rajasthan",
        "city": "Dhorimana"
      },
      {
        "state": "Rajasthan",
        "city": "Didwana"
      },
      {
        "state": "Rajasthan",
        "city": "Digod"
      },
      {
        "state": "Rajasthan",
        "city": "Doonglai"
      },
      {
        "state": "Rajasthan",
        "city": "Dungarpur"
      },
      {
        "state": "Rajasthan",
        "city": "Dungla"
      },
      {
        "state": "Rajasthan",
        "city": "Fateh Garh"
      },
      {
        "state": "Rajasthan",
        "city": "Gadra Road"
      },
      {
        "state": "Rajasthan",
        "city": "Gajsinghpur"
      },
      {
        "state": "Rajasthan",
        "city": "Gajsinghpura"
      },
      {
        "state": "Rajasthan",
        "city": "Gandav"
      },
      {
        "state": "Rajasthan",
        "city": "Gangapur City"
      },
      {
        "state": "Rajasthan",
        "city": "Gangarar"
      },
      {
        "state": "Rajasthan",
        "city": "Gharsana"
      },
      {
        "state": "Rajasthan",
        "city": "Gilund"
      },
      {
        "state": "Rajasthan",
        "city": "Girwas"
      },
      {
        "state": "Rajasthan",
        "city": "Gogunda"
      },
      {
        "state": "Rajasthan",
        "city": "Gordhan Vilas Rural"
      },
      {
        "state": "Rajasthan",
        "city": "Gosundi"
      },
      {
        "state": "Rajasthan",
        "city": "Gudamalani"
      },
      {
        "state": "Rajasthan",
        "city": "Hanawant Nagar"
      },
      {
        "state": "Rajasthan",
        "city": "Hanumangarh"
      },
      {
        "state": "Rajasthan",
        "city": "Hanumangarh Town"
      },
      {
        "state": "Rajasthan",
        "city": "Hindaun City"
      },
      {
        "state": "Rajasthan",
        "city": "Jaipur"
      },
      {
        "state": "Rajasthan",
        "city": "Jaisalmer"
      },
      {
        "state": "Rajasthan",
        "city": "Jaitaran"
      },
      {
        "state": "Rajasthan",
        "city": "Jalore"
      },
      {
        "state": "Rajasthan",
        "city": "Janoothar"
      },
      {
        "state": "Rajasthan",
        "city": "Jetaran"
      },
      {
        "state": "Rajasthan",
        "city": "Jhalawar"
      },
      {
        "state": "Rajasthan",
        "city": "Jharol"
      },
      {
        "state": "Rajasthan",
        "city": "Jhunjhunu"
      },
      {
        "state": "Rajasthan",
        "city": "Jodhpur"
      },
      {
        "state": "Rajasthan",
        "city": "Kaman"
      },
      {
        "state": "Rajasthan",
        "city": "Kankroli"
      },
      {
        "state": "Rajasthan",
        "city": "Kanore"
      },
      {
        "state": "Rajasthan",
        "city": "Kapasan"
      },
      {
        "state": "Rajasthan",
        "city": "Karauli"
      },
      {
        "state": "Rajasthan",
        "city": "Kathoomar"
      },
      {
        "state": "Rajasthan",
        "city": "Kawas"
      },
      {
        "state": "Rajasthan",
        "city": "Kekri"
      },
      {
        "state": "Rajasthan",
        "city": "Kerwali"
      },
      {
        "state": "Rajasthan",
        "city": "Kesrisinghpur"
      },
      {
        "state": "Rajasthan",
        "city": "Khajuwala"
      },
      {
        "state": "Rajasthan",
        "city": "Khamnore"
      },
      {
        "state": "Rajasthan",
        "city": "Khandar"
      },
      {
        "state": "Rajasthan",
        "city": "Kherwara"
      },
      {
        "state": "Rajasthan",
        "city": "Kherwara Chhaoni"
      },
      {
        "state": "Rajasthan",
        "city": "Khetri"
      },
      {
        "state": "Rajasthan",
        "city": "Khetri Nagar"
      },
      {
        "state": "Rajasthan",
        "city": "Khichan"
      },
      {
        "state": "Rajasthan",
        "city": "Khimsar"
      },
      {
        "state": "Rajasthan",
        "city": "Kishangarh Bas"
      },
      {
        "state": "Rajasthan",
        "city": "Kolayat"
      },
      {
        "state": "Rajasthan",
        "city": "Kota"
      },
      {
        "state": "Rajasthan",
        "city": "Kotra"
      },
      {
        "state": "Rajasthan",
        "city": "Kuchaman City"
      },
      {
        "state": "Rajasthan",
        "city": "Kumbhalgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Kumher"
      },
      {
        "state": "Rajasthan",
        "city": "Kuraj"
      },
      {
        "state": "Rajasthan",
        "city": "LALSOT"
      },
      {
        "state": "Rajasthan",
        "city": "Ladnun"
      },
      {
        "state": "Rajasthan",
        "city": "Lasadiya"
      },
      {
        "state": "Rajasthan",
        "city": "Laxmangarh"
      },
      {
        "state": "Rajasthan",
        "city": "Lohawat"
      },
      {
        "state": "Rajasthan",
        "city": "Luni"
      },
      {
        "state": "Rajasthan",
        "city": "Lunkaransar"
      },
      {
        "state": "Rajasthan",
        "city": "Makrana"
      },
      {
        "state": "Rajasthan",
        "city": "Makri Fatak"
      },
      {
        "state": "Rajasthan",
        "city": "Malarna Dungar"
      },
      {
        "state": "Rajasthan",
        "city": "Malsisar"
      },
      {
        "state": "Rajasthan",
        "city": "Mandalgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Mandawar"
      },
      {
        "state": "Rajasthan",
        "city": "Mandrayal"
      },
      {
        "state": "Rajasthan",
        "city": "Mangrol"
      },
      {
        "state": "Rajasthan",
        "city": "Marwar Junction"
      },
      {
        "state": "Rajasthan",
        "city": "Masuda"
      },
      {
        "state": "Rajasthan",
        "city": "Mathania"
      },
      {
        "state": "Rajasthan",
        "city": "Mavli"
      },
      {
        "state": "Rajasthan",
        "city": "Merta"
      },
      {
        "state": "Rajasthan",
        "city": "Mewa Nagar"
      },
      {
        "state": "Rajasthan",
        "city": "Mokalsar"
      },
      {
        "state": "Rajasthan",
        "city": "Mount Abu"
      },
      {
        "state": "Rajasthan",
        "city": "Mundawar"
      },
      {
        "state": "Rajasthan",
        "city": "Mundwa"
      },
      {
        "state": "Rajasthan",
        "city": "Nadbai"
      },
      {
        "state": "Rajasthan",
        "city": "Nadoti"
      },
      {
        "state": "Rajasthan",
        "city": "Nagar"
      },
      {
        "state": "Rajasthan",
        "city": "Nagaur"
      },
      {
        "state": "Rajasthan",
        "city": "Nasirabad"
      },
      {
        "state": "Rajasthan",
        "city": "Nathdwara"
      },
      {
        "state": "Rajasthan",
        "city": "Navania"
      },
      {
        "state": "Rajasthan",
        "city": "Nawalgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Neem Ka Thana"
      },
      {
        "state": "Rajasthan",
        "city": "Neemrana"
      },
      {
        "state": "Rajasthan",
        "city": "Nimbahera"
      },
      {
        "state": "Rajasthan",
        "city": "Niwai"
      },
      {
        "state": "Rajasthan",
        "city": "Nohar"
      },
      {
        "state": "Rajasthan",
        "city": "Nokha"
      },
      {
        "state": "Rajasthan",
        "city": "Osian"
      },
      {
        "state": "Rajasthan",
        "city": "Pachpadra"
      },
      {
        "state": "Rajasthan",
        "city": "Padampura"
      },
      {
        "state": "Rajasthan",
        "city": "Padru"
      },
      {
        "state": "Rajasthan",
        "city": "Pahari"
      },
      {
        "state": "Rajasthan",
        "city": "Pali"
      },
      {
        "state": "Rajasthan",
        "city": "Parbatsar"
      },
      {
        "state": "Rajasthan",
        "city": "Parlu"
      },
      {
        "state": "Rajasthan",
        "city": "Patti Beena"
      },
      {
        "state": "Rajasthan",
        "city": "Peelwa"
      },
      {
        "state": "Rajasthan",
        "city": "Phalodi"
      },
      {
        "state": "Rajasthan",
        "city": "Pilani"
      },
      {
        "state": "Rajasthan",
        "city": "Pilibanga"
      },
      {
        "state": "Rajasthan",
        "city": "Pindwara"
      },
      {
        "state": "Rajasthan",
        "city": "Piparcity"
      },
      {
        "state": "Rajasthan",
        "city": "Pisangan"
      },
      {
        "state": "Rajasthan",
        "city": "Pokhran"
      },
      {
        "state": "Rajasthan",
        "city": "Pratapgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Raisingh Nagar"
      },
      {
        "state": "Rajasthan",
        "city": "Rajakhera"
      },
      {
        "state": "Rajasthan",
        "city": "Rajsamand"
      },
      {
        "state": "Rajasthan",
        "city": "Ramsar"
      },
      {
        "state": "Rajasthan",
        "city": "Rani Gaon"
      },
      {
        "state": "Rajasthan",
        "city": "Raniwara"
      },
      {
        "state": "Rajasthan",
        "city": "Rashmi"
      },
      {
        "state": "Rajasthan",
        "city": "Rasmi"
      },
      {
        "state": "Rajasthan",
        "city": "Ratangarh"
      },
      {
        "state": "Rajasthan",
        "city": "Rawatbhata"
      },
      {
        "state": "Rajasthan",
        "city": "Rawatsar"
      },
      {
        "state": "Rajasthan",
        "city": "Relmangra"
      },
      {
        "state": "Rajasthan",
        "city": "Revdar"
      },
      {
        "state": "Rajasthan",
        "city": "Rohat"
      },
      {
        "state": "Rajasthan",
        "city": "Roopbas"
      },
      {
        "state": "Rajasthan",
        "city": "Rupbas"
      },
      {
        "state": "Rajasthan",
        "city": "Sadul Shahar"
      },
      {
        "state": "Rajasthan",
        "city": "Sadulpur"
      },
      {
        "state": "Rajasthan",
        "city": "Saipau"
      },
      {
        "state": "Rajasthan",
        "city": "Salumbar"
      },
      {
        "state": "Rajasthan",
        "city": "Samdari"
      },
      {
        "state": "Rajasthan",
        "city": "Sanawara"
      },
      {
        "state": "Rajasthan",
        "city": "Sanchore"
      },
      {
        "state": "Rajasthan",
        "city": "Sangaria"
      },
      {
        "state": "Rajasthan",
        "city": "Sapotara"
      },
      {
        "state": "Rajasthan",
        "city": "Sardargarh"
      },
      {
        "state": "Rajasthan",
        "city": "Sardarshahar"
      },
      {
        "state": "Rajasthan",
        "city": "Sarwar"
      },
      {
        "state": "Rajasthan",
        "city": "Sawai Madhopur"
      },
      {
        "state": "Rajasthan",
        "city": "Sawau Padamsingh"
      },
      {
        "state": "Rajasthan",
        "city": "Sayla"
      },
      {
        "state": "Rajasthan",
        "city": "Sayra"
      },
      {
        "state": "Rajasthan",
        "city": "Serwa"
      },
      {
        "state": "Rajasthan",
        "city": "Setrawa"
      },
      {
        "state": "Rajasthan",
        "city": "Shambhupura"
      },
      {
        "state": "Rajasthan",
        "city": "Shekhawati"
      },
      {
        "state": "Rajasthan",
        "city": "Sheoganj"
      },
      {
        "state": "Rajasthan",
        "city": "Sherani Abad"
      },
      {
        "state": "Rajasthan",
        "city": "Shri Dungargarh"
      },
      {
        "state": "Rajasthan",
        "city": "Shrimadhopur"
      },
      {
        "state": "Rajasthan",
        "city": "Sikar"
      },
      {
        "state": "Rajasthan",
        "city": "Sindhari"
      },
      {
        "state": "Rajasthan",
        "city": "Sirohi"
      },
      {
        "state": "Rajasthan",
        "city": "Siwana"
      },
      {
        "state": "Rajasthan",
        "city": "Sojat"
      },
      {
        "state": "Rajasthan",
        "city": "Sri Ganganagar"
      },
      {
        "state": "Rajasthan",
        "city": "Sri Vijaynagar"
      },
      {
        "state": "Rajasthan",
        "city": "Srikaranpur"
      },
      {
        "state": "Rajasthan",
        "city": "Sujangarh"
      },
      {
        "state": "Rajasthan",
        "city": "Sumerpur"
      },
      {
        "state": "Rajasthan",
        "city": "Surajgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Suratgarh"
      },
      {
        "state": "Rajasthan",
        "city": "Taranagar"
      },
      {
        "state": "Rajasthan",
        "city": "Tena"
      },
      {
        "state": "Rajasthan",
        "city": "Teori"
      },
      {
        "state": "Rajasthan",
        "city": "Thanagazi"
      },
      {
        "state": "Rajasthan",
        "city": "Tibbi"
      },
      {
        "state": "Rajasthan",
        "city": "Tijara"
      },
      {
        "state": "Rajasthan",
        "city": "Todabhim"
      },
      {
        "state": "Rajasthan",
        "city": "Tonk"
      },
      {
        "state": "Rajasthan",
        "city": "Udaipur"
      },
      {
        "state": "Rajasthan",
        "city": "Udaipurwati"
      },
      {
        "state": "Rajasthan",
        "city": "Vallabhnagar"
      },
      {
        "state": "Rajasthan",
        "city": "Weir"
      },
      {
        "state": "Sikkim",
        "city": "Chungthang"
      },
      {
        "state": "Sikkim",
        "city": "Dikchu"
      },
      {
        "state": "Sikkim",
        "city": "Gangtok"
      },
      {
        "state": "Sikkim",
        "city": "Geyzing"
      },
      {
        "state": "Sikkim",
        "city": "Gyalshing"
      },
      {
        "state": "Sikkim",
        "city": "Jorethang"
      },
      {
        "state": "Sikkim",
        "city": "Mangan"
      },
      {
        "state": "Sikkim",
        "city": "Melli"
      },
      {
        "state": "Sikkim",
        "city": "Namchi"
      },
      {
        "state": "Sikkim",
        "city": "Pakyong"
      },
      {
        "state": "Sikkim",
        "city": "Pelling"
      },
      {
        "state": "Sikkim",
        "city": "Rangpo"
      },
      {
        "state": "Sikkim",
        "city": "Ranipool"
      },
      {
        "state": "Sikkim",
        "city": "Ravangla"
      },
      {
        "state": "Sikkim",
        "city": "Rhenock"
      },
      {
        "state": "Sikkim",
        "city": "Rongli"
      },
      {
        "state": "Sikkim",
        "city": "Singtam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Agasteeswaram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Alangudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Alangulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ambasamudram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Anaimalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Andipatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Andippatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Annavasal"
      },
      {
        "state": "Tamil Nadu",
        "city": "Arakkonam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Arani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Aranthangi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Aravakurichi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Arcot"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ariyalur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Aruppukkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Attur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Avadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Avinashi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Avudaiyarkoil"
      },
      {
        "state": "Tamil Nadu",
        "city": "Bargur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Bhavani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Bodinayakanur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chengalpattu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chengam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chennai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Cherangode"
      },
      {
        "state": "Tamil Nadu",
        "city": "Cheyur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Cheyyar"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chidambaram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chidambaram North"
      },
      {
        "state": "Tamil Nadu",
        "city": "Chinnasalem"
      },
      {
        "state": "Tamil Nadu",
        "city": "Coimbatore"
      },
      {
        "state": "Tamil Nadu",
        "city": "Coimbatore North"
      },
      {
        "state": "Tamil Nadu",
        "city": "Coimbatore South"
      },
      {
        "state": "Tamil Nadu",
        "city": "Coonoor"
      },
      {
        "state": "Tamil Nadu",
        "city": "Cuddalore"
      },
      {
        "state": "Tamil Nadu",
        "city": "Denkanikottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Devakottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Dharapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Dharmapuri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Dindigul"
      },
      {
        "state": "Tamil Nadu",
        "city": "Dusi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Erode"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ettaiyapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gandarvakkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gangavalli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gingee"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gobichettipalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gudalur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gudiyattam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gummudipoondi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Gunambadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Harur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Hosur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Idappadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ilayangudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Illuppur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Jayamkondacholapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kadaladi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kadambarayapatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kadambur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kalkulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kallakurichi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kamudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kamuthi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kanchipuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kangayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kanyakumari"
      },
      {
        "state": "Tamil Nadu",
        "city": "Karaikudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Karaipudur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kariapatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Karur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Katpadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kattumannarkoil"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kattuputhur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kaveripattinam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kilvelur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kodaikanal"
      },
      {
        "state": "Tamil Nadu",
        "city": "Koradacheri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kotagiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kothamangalam North"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kovilpatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Krishnagiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Krishnarayapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kudavasal"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kulathur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kulithalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kumarapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kumbakonam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kundah"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kuttalam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Kuzhithurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Lalgudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Madhavapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Madurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Madurai North"
      },
      {
        "state": "Tamil Nadu",
        "city": "Madurai South"
      },
      {
        "state": "Tamil Nadu",
        "city": "Madurantakam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mallapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mallasamudram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Manamadurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Manamelkudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Manapparai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mannachanallur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mannargudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Marakkanam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mayiladuthurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Melur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mettupalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Mettur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Moongilpatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Musiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Muthukulathur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Naduppati"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nagapattinam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nagercoil"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nallampalli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Namakkal"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nanguneri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nannilam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Natham"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nidamangalam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nilakottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Nilgiris"
      },
      {
        "state": "Tamil Nadu",
        "city": "Oddanchatram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Omalur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ooty"
      },
      {
        "state": "Tamil Nadu",
        "city": "Orathanadu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ottapidaram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Padapai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Palacode"
      },
      {
        "state": "Tamil Nadu",
        "city": "Palani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Palayamkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Palayanur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Palladam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pallipalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pallipattu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pandalur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Panruti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Papanasam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pappireddipatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Paramakudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Paramathi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pattukkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pauparapatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pavithiram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pennagaram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pennathur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Peraiyur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Perambalur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Peravurani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Periyakulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Perundurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "PillaPalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pochampalli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pollachi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Polur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ponneri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pudukkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Puliampatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Pullagoundampatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Radhapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Rajapalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ramanathapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ramapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Rameswaram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Rasipuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Rayagiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "S Ramachandrapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sakkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Salem"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sankarankovil"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sankarapuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sankari"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sathankulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sathyamangalam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sattur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sendurai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sengaluneer Thottam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Shenkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sholavandan"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sholayar Dam City"
      },
      {
        "state": "Tamil Nadu",
        "city": "Singampunari"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sirkazhi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sivaganga"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sivagiri"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sivakasi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Soodamani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Sriperumbudur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Srivaikuntam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Srivilliputhur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tenkasi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thandalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thandrambattu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thandrampattu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thanjavur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tharangambadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Theni"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thirukkuvalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thirukoilure"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thirumakkottai -II"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thirumayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thirupananthal"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruthani"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruthuraipoondi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruvadanai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruvaiyaru"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruvarur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thiruvidaimaruthur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thoothukudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thoothukudi (Tuticorin)"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thottiyam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thovalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thuraikkadu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Thuraiyur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tindivanam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruchendur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruchengode"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruchirappalli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruchuli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tirukazhukundram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tirumangalam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tirunelveli"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tirupattur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruppur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruppuvanam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruvallur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruvannamalai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tiruvarur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Tittagudi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Turaiyur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Udayarpalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Udumalpet"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ullar"
      },
      {
        "state": "Tamil Nadu",
        "city": "Ulundurpet"
      },
      {
        "state": "Tamil Nadu",
        "city": "Usilampatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Uthamapalayam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Uthangarai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Uthukkottai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Uttangarai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Uttiramerur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vadipatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vairavanpatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Valangaiman"
      },
      {
        "state": "Tamil Nadu",
        "city": "Valappadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Valparai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vanadirayanpatti"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vandalur R.F."
      },
      {
        "state": "Tamil Nadu",
        "city": "Vandavasi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vaniyambadi"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vanur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vedaranyam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vedasandur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Veerakeralampudur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vellalore"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vellore"
      },
      {
        "state": "Tamil Nadu",
        "city": "Velur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vennangupattu"
      },
      {
        "state": "Tamil Nadu",
        "city": "Veppanthattai"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vilathikulam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Vilavancode"
      },
      {
        "state": "Tamil Nadu",
        "city": "Villupuram"
      },
      {
        "state": "Tamil Nadu",
        "city": "Viriyur"
      },
      {
        "state": "Tamil Nadu",
        "city": "Virudhachalam"
      },
      {
        "state": "Tamil Nadu",
        "city": "Virudhunagar"
      },
      {
        "state": "Tamil Nadu",
        "city": "Walajapet"
      },
      {
        "state": "Tamil Nadu",
        "city": "Wallajah"
      },
      {
        "state": "Tamil Nadu",
        "city": "Yercaud"
      },
      {
        "state": "Tamil Nadu",
        "city": "Z.Devarkulam"
      },
      {
        "state": "Telangana",
        "city": "Achampet"
      },
      {
        "state": "Telangana",
        "city": "Adilabad"
      },
      {
        "state": "Telangana",
        "city": "Aler"
      },
      {
        "state": "Telangana",
        "city": "Alladurg"
      },
      {
        "state": "Telangana",
        "city": "Alugunur"
      },
      {
        "state": "Telangana",
        "city": "Amangal"
      },
      {
        "state": "Telangana",
        "city": "Amrabad"
      },
      {
        "state": "Telangana",
        "city": "Andole"
      },
      {
        "state": "Telangana",
        "city": "Anumula"
      },
      {
        "state": "Telangana",
        "city": "Armoor"
      },
      {
        "state": "Telangana",
        "city": "Asifabad"
      },
      {
        "state": "Telangana",
        "city": "Aswapuram"
      },
      {
        "state": "Telangana",
        "city": "Aswaraopeta"
      },
      {
        "state": "Telangana",
        "city": "Atmakur"
      },
      {
        "state": "Telangana",
        "city": "Bachannapet"
      },
      {
        "state": "Telangana",
        "city": "Balanagar"
      },
      {
        "state": "Telangana",
        "city": "Balaraju Gudem"
      },
      {
        "state": "Telangana",
        "city": "Balmur"
      },
      {
        "state": "Telangana",
        "city": "Banswada"
      },
      {
        "state": "Telangana",
        "city": "Bayyaram"
      },
      {
        "state": "Telangana",
        "city": "Bazarhathnoor"
      },
      {
        "state": "Telangana",
        "city": "Bejjanki"
      },
      {
        "state": "Telangana",
        "city": "Bejjur"
      },
      {
        "state": "Telangana",
        "city": "Bhadrachalam"
      },
      {
        "state": "Telangana",
        "city": "Bhadradri Kothagudem"
      },
      {
        "state": "Telangana",
        "city": "Bheemadevarpalle"
      },
      {
        "state": "Telangana",
        "city": "Bheemgal"
      },
      {
        "state": "Telangana",
        "city": "Bhiknoor"
      },
      {
        "state": "Telangana",
        "city": "Bhoodan Pochampally"
      },
      {
        "state": "Telangana",
        "city": "Bhoorgampahad"
      },
      {
        "state": "Telangana",
        "city": "Bhootpur"
      },
      {
        "state": "Telangana",
        "city": "Bhupalpally"
      },
      {
        "state": "Telangana",
        "city": "Bhuthpur"
      },
      {
        "state": "Telangana",
        "city": "Bhuvanagiri"
      },
      {
        "state": "Telangana",
        "city": "Bibinagar"
      },
      {
        "state": "Telangana",
        "city": "Bichkunda"
      },
      {
        "state": "Telangana",
        "city": "Bijnapalli"
      },
      {
        "state": "Telangana",
        "city": "Boath"
      },
      {
        "state": "Telangana",
        "city": "Bodhan"
      },
      {
        "state": "Telangana",
        "city": "Bodhan Rural"
      },
      {
        "state": "Telangana",
        "city": "Bommakal"
      },
      {
        "state": "Telangana",
        "city": "Bonakal"
      },
      {
        "state": "Telangana",
        "city": "Boothpur"
      },
      {
        "state": "Telangana",
        "city": "Chandrugonda"
      },
      {
        "state": "Telangana",
        "city": "Chandurthi"
      },
      {
        "state": "Telangana",
        "city": "Cheeturu"
      },
      {
        "state": "Telangana",
        "city": "Chelpur"
      },
      {
        "state": "Telangana",
        "city": "Chennaraopet"
      },
      {
        "state": "Telangana",
        "city": "Cherial"
      },
      {
        "state": "Telangana",
        "city": "Cheruvumadharam"
      },
      {
        "state": "Telangana",
        "city": "Chigurumamidi"
      },
      {
        "state": "Telangana",
        "city": "Chinna Chintha Kunta"
      },
      {
        "state": "Telangana",
        "city": "Chinnur"
      },
      {
        "state": "Telangana",
        "city": "Chintakani"
      },
      {
        "state": "Telangana",
        "city": "Chityal"
      },
      {
        "state": "Telangana",
        "city": "Choppadandi"
      },
      {
        "state": "Telangana",
        "city": "Choutuppal"
      },
      {
        "state": "Telangana",
        "city": "Dammapeta"
      },
      {
        "state": "Telangana",
        "city": "Devarakonda"
      },
      {
        "state": "Telangana",
        "city": "Devarkadra"
      },
      {
        "state": "Telangana",
        "city": "Devaruppal"
      },
      {
        "state": "Telangana",
        "city": "Dharmaram"
      },
      {
        "state": "Telangana",
        "city": "Dharmasagar"
      },
      {
        "state": "Telangana",
        "city": "Dharur"
      },
      {
        "state": "Telangana",
        "city": "Dichpally"
      },
      {
        "state": "Telangana",
        "city": "Dornakal"
      },
      {
        "state": "Telangana",
        "city": "Dubbak"
      },
      {
        "state": "Telangana",
        "city": "Duggondi"
      },
      {
        "state": "Telangana",
        "city": "Dummugudem"
      },
      {
        "state": "Telangana",
        "city": "Ellantakunta"
      },
      {
        "state": "Telangana",
        "city": "Enkoor"
      },
      {
        "state": "Telangana",
        "city": "Eturnagaram"
      },
      {
        "state": "Telangana",
        "city": "Farooqnagar"
      },
      {
        "state": "Telangana",
        "city": "Gadwal"
      },
      {
        "state": "Telangana",
        "city": "Gambhiraopet"
      },
      {
        "state": "Telangana",
        "city": "Gangadhara"
      },
      {
        "state": "Telangana",
        "city": "Garla"
      },
      {
        "state": "Telangana",
        "city": "Gavicherla"
      },
      {
        "state": "Telangana",
        "city": "Gdpeta"
      },
      {
        "state": "Telangana",
        "city": "Geesugonda"
      },
      {
        "state": "Telangana",
        "city": "Ghanpur"
      },
      {
        "state": "Telangana",
        "city": "Ghanpur(m)"
      },
      {
        "state": "Telangana",
        "city": "Ghatkesar"
      },
      {
        "state": "Telangana",
        "city": "Gokinepalli"
      },
      {
        "state": "Telangana",
        "city": "Gollapalli"
      },
      {
        "state": "Telangana",
        "city": "Gopalpet"
      },
      {
        "state": "Telangana",
        "city": "Govindaraopet"
      },
      {
        "state": "Telangana",
        "city": "Gudur"
      },
      {
        "state": "Telangana",
        "city": "Hanamkonda"
      },
      {
        "state": "Telangana",
        "city": "Hasanparthy"
      },
      {
        "state": "Telangana",
        "city": "Hayathnagar"
      },
      {
        "state": "Telangana",
        "city": "Husnabad"
      },
      {
        "state": "Telangana",
        "city": "Huzur Nagar"
      },
      {
        "state": "Telangana",
        "city": "Huzurabad"
      },
      {
        "state": "Telangana",
        "city": "Hyderabad"
      },
      {
        "state": "Telangana",
        "city": "Ibrahimpatnam"
      },
      {
        "state": "Telangana",
        "city": "Jadcherla"
      },
      {
        "state": "Telangana",
        "city": "Jagtial"
      },
      {
        "state": "Telangana",
        "city": "Jainoor"
      },
      {
        "state": "Telangana",
        "city": "Jammikunta"
      },
      {
        "state": "Telangana",
        "city": "Jangaon"
      },
      {
        "state": "Telangana",
        "city": "Jangedu"
      },
      {
        "state": "Telangana",
        "city": "Jayashankar Bhoopalpally"
      },
      {
        "state": "Telangana",
        "city": "Jogipet"
      },
      {
        "state": "Telangana",
        "city": "Jogulamba Gadwal"
      },
      {
        "state": "Telangana",
        "city": "Julapalle"
      },
      {
        "state": "Telangana",
        "city": "Julapalli"
      },
      {
        "state": "Telangana",
        "city": "Julurpad"
      },
      {
        "state": "Telangana",
        "city": "Kallur"
      },
      {
        "state": "Telangana",
        "city": "Kalva Srirampur"
      },
      {
        "state": "Telangana",
        "city": "Kalwakurthy"
      },
      {
        "state": "Telangana",
        "city": "Kamanpur"
      },
      {
        "state": "Telangana",
        "city": "Kamareddy"
      },
      {
        "state": "Telangana",
        "city": "Kamareddy Gudem"
      },
      {
        "state": "Telangana",
        "city": "Kanchanapally"
      },
      {
        "state": "Telangana",
        "city": "Kandukur"
      },
      {
        "state": "Telangana",
        "city": "Karepalli"
      },
      {
        "state": "Telangana",
        "city": "Karimnagar"
      },
      {
        "state": "Telangana",
        "city": "Kataram"
      },
      {
        "state": "Telangana",
        "city": "Kathlapoor"
      },
      {
        "state": "Telangana",
        "city": "Kerimeri"
      },
      {
        "state": "Telangana",
        "city": "Kesamudram"
      },
      {
        "state": "Telangana",
        "city": "Kesavapatnam"
      },
      {
        "state": "Telangana",
        "city": "Keshampet"
      },
      {
        "state": "Telangana",
        "city": "Khammam"
      },
      {
        "state": "Telangana",
        "city": "Khanapur"
      },
      {
        "state": "Telangana",
        "city": "Kodad"
      },
      {
        "state": "Telangana",
        "city": "Kodair"
      },
      {
        "state": "Telangana",
        "city": "Kodakandla"
      },
      {
        "state": "Telangana",
        "city": "Kodangal"
      },
      {
        "state": "Telangana",
        "city": "Kodimial"
      },
      {
        "state": "Telangana",
        "city": "Koheda"
      },
      {
        "state": "Telangana",
        "city": "Kollapur"
      },
      {
        "state": "Telangana",
        "city": "Komaram Bheem Asifabad"
      },
      {
        "state": "Telangana",
        "city": "Konaraopet"
      },
      {
        "state": "Telangana",
        "city": "Kondapur"
      },
      {
        "state": "Telangana",
        "city": "Kondur"
      },
      {
        "state": "Telangana",
        "city": "Kondurg"
      },
      {
        "state": "Telangana",
        "city": "Korutla"
      },
      {
        "state": "Telangana",
        "city": "Kosigi"
      },
      {
        "state": "Telangana",
        "city": "Kothagudem"
      },
      {
        "state": "Telangana",
        "city": "Kothakota"
      },
      {
        "state": "Telangana",
        "city": "Kothur"
      },
      {
        "state": "Telangana",
        "city": "Kuravi"
      },
      {
        "state": "Telangana",
        "city": "Kusumanchi"
      },
      {
        "state": "Telangana",
        "city": "Lingal"
      },
      {
        "state": "Telangana",
        "city": "Lingal Ghanpur"
      },
      {
        "state": "Telangana",
        "city": "Lingala"
      },
      {
        "state": "Telangana",
        "city": "Lingampet"
      },
      {
        "state": "Telangana",
        "city": "Luxettipet"
      },
      {
        "state": "Telangana",
        "city": "Machareddi"
      },
      {
        "state": "Telangana",
        "city": "Maddur"
      },
      {
        "state": "Telangana",
        "city": "Madgul"
      },
      {
        "state": "Telangana",
        "city": "Madhira"
      },
      {
        "state": "Telangana",
        "city": "Madhira D"
      },
      {
        "state": "Telangana",
        "city": "Madnoor"
      },
      {
        "state": "Telangana",
        "city": "Mahabubabad"
      },
      {
        "state": "Telangana",
        "city": "Mahbubnagar"
      },
      {
        "state": "Telangana",
        "city": "Maheshwaram"
      },
      {
        "state": "Telangana",
        "city": "Maheswaram"
      },
      {
        "state": "Telangana",
        "city": "Mallayal"
      },
      {
        "state": "Telangana",
        "city": "Mallial"
      },
      {
        "state": "Telangana",
        "city": "Manakondur"
      },
      {
        "state": "Telangana",
        "city": "Mancherial"
      },
      {
        "state": "Telangana",
        "city": "Mandamarri"
      },
      {
        "state": "Telangana",
        "city": "Mangapet"
      },
      {
        "state": "Telangana",
        "city": "Manthani"
      },
      {
        "state": "Telangana",
        "city": "Manuguru"
      },
      {
        "state": "Telangana",
        "city": "Maripeda"
      },
      {
        "state": "Telangana",
        "city": "Marriguda"
      },
      {
        "state": "Telangana",
        "city": "Medak"
      },
      {
        "state": "Telangana",
        "city": "Medapalli"
      },
      {
        "state": "Telangana",
        "city": "Medchal"
      },
      {
        "state": "Telangana",
        "city": "Metpalle"
      },
      {
        "state": "Telangana",
        "city": "Metpalli"
      },
      {
        "state": "Telangana",
        "city": "Metpally"
      },
      {
        "state": "Telangana",
        "city": "Midjil"
      },
      {
        "state": "Telangana",
        "city": "Miryalaguda"
      },
      {
        "state": "Telangana",
        "city": "Mogula Pally"
      },
      {
        "state": "Telangana",
        "city": "Moinabad"
      },
      {
        "state": "Telangana",
        "city": "Mothkur"
      },
      {
        "state": "Telangana",
        "city": "Mothugudem"
      },
      {
        "state": "Telangana",
        "city": "Mudhole"
      },
      {
        "state": "Telangana",
        "city": "Mulug"
      },
      {
        "state": "Telangana",
        "city": "Mulugu"
      },
      {
        "state": "Telangana",
        "city": "Munugodu"
      },
      {
        "state": "Telangana",
        "city": "Mustabad"
      },
      {
        "state": "Telangana",
        "city": "Nagarkurnool"
      },
      {
        "state": "Telangana",
        "city": "Nakerakal"
      },
      {
        "state": "Telangana",
        "city": "Nalgonda"
      },
      {
        "state": "Telangana",
        "city": "Nallabelli"
      },
      {
        "state": "Telangana",
        "city": "Narayanapur"
      },
      {
        "state": "Telangana",
        "city": "Narayankhed"
      },
      {
        "state": "Telangana",
        "city": "Narayanpet"
      },
      {
        "state": "Telangana",
        "city": "Narmetta"
      },
      {
        "state": "Telangana",
        "city": "Narnoor"
      },
      {
        "state": "Telangana",
        "city": "Narsampet"
      },
      {
        "state": "Telangana",
        "city": "Narsapur"
      },
      {
        "state": "Telangana",
        "city": "Narsimhulapet"
      },
      {
        "state": "Telangana",
        "city": "Nawabpet"
      },
      {
        "state": "Telangana",
        "city": "Nekkonda"
      },
      {
        "state": "Telangana",
        "city": "Nellikudur"
      },
      {
        "state": "Telangana",
        "city": "Nellipaka"
      },
      {
        "state": "Telangana",
        "city": "Nirmal"
      },
      {
        "state": "Telangana",
        "city": "Nizamabad"
      },
      {
        "state": "Telangana",
        "city": "Nizamabad Village"
      },
      {
        "state": "Telangana",
        "city": "Padamati Narsapuram"
      },
      {
        "state": "Telangana",
        "city": "Palair"
      },
      {
        "state": "Telangana",
        "city": "Palakurthy"
      },
      {
        "state": "Telangana",
        "city": "Palem"
      },
      {
        "state": "Telangana",
        "city": "Palwancha"
      },
      {
        "state": "Telangana",
        "city": "Pangal"
      },
      {
        "state": "Telangana",
        "city": "Papannapet"
      },
      {
        "state": "Telangana",
        "city": "Parkal"
      },
      {
        "state": "Telangana",
        "city": "Parvathagiri"
      },
      {
        "state": "Telangana",
        "city": "Pebbair"
      },
      {
        "state": "Telangana",
        "city": "Pedda Adiserla Palle"
      },
      {
        "state": "Telangana",
        "city": "Peddakothapally"
      },
      {
        "state": "Telangana",
        "city": "Peddamandadi"
      },
      {
        "state": "Telangana",
        "city": "Peddapalli"
      },
      {
        "state": "Telangana",
        "city": "Peddavura"
      },
      {
        "state": "Telangana",
        "city": "Pegadapalli"
      },
      {
        "state": "Telangana",
        "city": "Pegadapally"
      },
      {
        "state": "Telangana",
        "city": "Penuballi"
      },
      {
        "state": "Telangana",
        "city": "Pindiprolu"
      },
      {
        "state": "Telangana",
        "city": "Pochampalle"
      },
      {
        "state": "Telangana",
        "city": "Qutubullapur"
      },
      {
        "state": "Telangana",
        "city": "Raikal"
      },
      {
        "state": "Telangana",
        "city": "Raiparthy"
      },
      {
        "state": "Telangana",
        "city": "Rajanna Sircilla"
      },
      {
        "state": "Telangana",
        "city": "Ramadugu"
      },
      {
        "state": "Telangana",
        "city": "Ramagundam"
      },
      {
        "state": "Telangana",
        "city": "Ramannapeta"
      },
      {
        "state": "Telangana",
        "city": "Ramanujapuram"
      },
      {
        "state": "Telangana",
        "city": "Rangareddy"
      },
      {
        "state": "Telangana",
        "city": "Rayaparthi"
      },
      {
        "state": "Telangana",
        "city": "Redlawada"
      },
      {
        "state": "Telangana",
        "city": "Regonda"
      },
      {
        "state": "Telangana",
        "city": "Sadashivpet"
      },
      {
        "state": "Telangana",
        "city": "Saidapur"
      },
      {
        "state": "Telangana",
        "city": "Samathsingaram"
      },
      {
        "state": "Telangana",
        "city": "Sangareddy"
      },
      {
        "state": "Telangana",
        "city": "Sangem"
      },
      {
        "state": "Telangana",
        "city": "Sarangapur"
      },
      {
        "state": "Telangana",
        "city": "Sarapaka"
      },
      {
        "state": "Telangana",
        "city": "Saroornagar"
      },
      {
        "state": "Telangana",
        "city": "Sathupally"
      },
      {
        "state": "Telangana",
        "city": "Satyanarayanapuram"
      },
      {
        "state": "Telangana",
        "city": "Secunderabad"
      },
      {
        "state": "Telangana",
        "city": "Seethampet"
      },
      {
        "state": "Telangana",
        "city": "Shabad"
      },
      {
        "state": "Telangana",
        "city": "Shadnagar"
      },
      {
        "state": "Telangana",
        "city": "Shankarampet"
      },
      {
        "state": "Telangana",
        "city": "Shankarampet (A)"
      },
      {
        "state": "Telangana",
        "city": "Shankarapatnam"
      },
      {
        "state": "Telangana",
        "city": "Shankarpally"
      },
      {
        "state": "Telangana",
        "city": "Shayampet"
      },
      {
        "state": "Telangana",
        "city": "Siddipet"
      },
      {
        "state": "Telangana",
        "city": "Singam Palle"
      },
      {
        "state": "Telangana",
        "city": "Sircilla"
      },
      {
        "state": "Telangana",
        "city": "Sircilla Rural"
      },
      {
        "state": "Telangana",
        "city": "Siripuram"
      },
      {
        "state": "Telangana",
        "city": "Sirpur-Kaghaznagar"
      },
      {
        "state": "Telangana",
        "city": "Station Ghanpur"
      },
      {
        "state": "Telangana",
        "city": "Subled"
      },
      {
        "state": "Telangana",
        "city": "Sujathanagar"
      },
      {
        "state": "Telangana",
        "city": "Sultanabad"
      },
      {
        "state": "Telangana",
        "city": "Suryapet"
      },
      {
        "state": "Telangana",
        "city": "Tadoor"
      },
      {
        "state": "Telangana",
        "city": "Tadvai"
      },
      {
        "state": "Telangana",
        "city": "Talakondapally"
      },
      {
        "state": "Telangana",
        "city": "Tallada"
      },
      {
        "state": "Telangana",
        "city": "Tamsi"
      },
      {
        "state": "Telangana",
        "city": "Tandur"
      },
      {
        "state": "Telangana",
        "city": "Telkapalle"
      },
      {
        "state": "Telangana",
        "city": "Telkapally"
      },
      {
        "state": "Telangana",
        "city": "Thadoor"
      },
      {
        "state": "Telangana",
        "city": "Thanikella"
      },
      {
        "state": "Telangana",
        "city": "Thimajipet"
      },
      {
        "state": "Telangana",
        "city": "Thimmapur"
      },
      {
        "state": "Telangana",
        "city": "Thorrur"
      },
      {
        "state": "Telangana",
        "city": "Thungathurthy"
      },
      {
        "state": "Telangana",
        "city": "Tirumalagiri"
      },
      {
        "state": "Telangana",
        "city": "Tiryani"
      },
      {
        "state": "Telangana",
        "city": "Tungaturthi"
      },
      {
        "state": "Telangana",
        "city": "Uppal"
      },
      {
        "state": "Telangana",
        "city": "Uppununthala"
      },
      {
        "state": "Telangana",
        "city": "Utnoor"
      },
      {
        "state": "Telangana",
        "city": "Utukur"
      },
      {
        "state": "Telangana",
        "city": "V.Venkatayapalem"
      },
      {
        "state": "Telangana",
        "city": "Valigonda"
      },
      {
        "state": "Telangana",
        "city": "Vangoor"
      },
      {
        "state": "Telangana",
        "city": "Varni"
      },
      {
        "state": "Telangana",
        "city": "Veenavanka"
      },
      {
        "state": "Telangana",
        "city": "Veepanagandla"
      },
      {
        "state": "Telangana",
        "city": "Veldanda"
      },
      {
        "state": "Telangana",
        "city": "Velgatur"
      },
      {
        "state": "Telangana",
        "city": "Vemsoor"
      },
      {
        "state": "Telangana",
        "city": "Vemulawada"
      },
      {
        "state": "Telangana",
        "city": "Venkatapur"
      },
      {
        "state": "Telangana",
        "city": "Vikarabad"
      },
      {
        "state": "Telangana",
        "city": "Wanaparthy"
      },
      {
        "state": "Telangana",
        "city": "Warangal"
      },
      {
        "state": "Telangana",
        "city": "Warangal (Urban)"
      },
      {
        "state": "Telangana",
        "city": "Wardannapet"
      },
      {
        "state": "Telangana",
        "city": "Wyra"
      },
      {
        "state": "Telangana",
        "city": "Yadadri Bhuvanagiri"
      },
      {
        "state": "Telangana",
        "city": "Yadagirigutta"
      },
      {
        "state": "Telangana",
        "city": "Yaragandla Pally"
      },
      {
        "state": "Telangana",
        "city": "Yedapally"
      },
      {
        "state": "Telangana",
        "city": "Yellandu"
      },
      {
        "state": "Telangana",
        "city": "Yellareddipet"
      },
      {
        "state": "Telangana",
        "city": "Yellareddy"
      },
      {
        "state": "Telangana",
        "city": "Yellareddypet"
      },
      {
        "state": "Telangana",
        "city": "Yerravalli"
      },
      {
        "state": "Telangana",
        "city": "Yerrupalem"
      },
      {
        "state": "Telangana",
        "city": "Zaffergadh"
      },
      {
        "state": "Telangana",
        "city": "Zaheerabad"
      },
      {
        "state": "Telangana",
        "city": "kottakkal"
      },
      {
        "state": "Tripura",
        "city": "Agartala"
      },
      {
        "state": "Tripura",
        "city": "Ambassa"
      },
      {
        "state": "Tripura",
        "city": "Ampinagar"
      },
      {
        "state": "Tripura",
        "city": "Asharambari"
      },
      {
        "state": "Tripura",
        "city": "Bagafa"
      },
      {
        "state": "Tripura",
        "city": "Bagbasa"
      },
      {
        "state": "Tripura",
        "city": "Balidhum"
      },
      {
        "state": "Tripura",
        "city": "Banbazar"
      },
      {
        "state": "Tripura",
        "city": "Baruakandi"
      },
      {
        "state": "Tripura",
        "city": "Behalabari"
      },
      {
        "state": "Tripura",
        "city": "Belonia"
      },
      {
        "state": "Tripura",
        "city": "Betchhara"
      },
      {
        "state": "Tripura",
        "city": "Birchandranagar"
      },
      {
        "state": "Tripura",
        "city": "Birendra Nagar"
      },
      {
        "state": "Tripura",
        "city": "Bishalgarh"
      },
      {
        "state": "Tripura",
        "city": "Bishramganj"
      },
      {
        "state": "Tripura",
        "city": "Boxanagar"
      },
      {
        "state": "Tripura",
        "city": "Central Catchment R.F."
      },
      {
        "state": "Tripura",
        "city": "Chailengta"
      },
      {
        "state": "Tripura",
        "city": "Chawmanu"
      },
      {
        "state": "Tripura",
        "city": "Chebri"
      },
      {
        "state": "Tripura",
        "city": "Chhantail"
      },
      {
        "state": "Tripura",
        "city": "Chulubari"
      },
      {
        "state": "Tripura",
        "city": "Churaibari"
      },
      {
        "state": "Tripura",
        "city": "Dakshin Padmabil"
      },
      {
        "state": "Tripura",
        "city": "Dakshin Promodenagar"
      },
      {
        "state": "Tripura",
        "city": "Dakshin Ramchandraghat"
      },
      {
        "state": "Tripura",
        "city": "Dalapatipara"
      },
      {
        "state": "Tripura",
        "city": "Damcherra"
      },
      {
        "state": "Tripura",
        "city": "Dasda"
      },
      {
        "state": "Tripura",
        "city": "Debendrachandranagar"
      },
      {
        "state": "Tripura",
        "city": "Dewanpasa"
      },
      {
        "state": "Tripura",
        "city": "Dhanbilash"
      },
      {
        "state": "Tripura",
        "city": "Dharmanagar"
      },
      {
        "state": "Tripura",
        "city": "Fatikchhara"
      },
      {
        "state": "Tripura",
        "city": "Fatikroy"
      },
      {
        "state": "Tripura",
        "city": "Fultali"
      },
      {
        "state": "Tripura",
        "city": "Gakulnagar"
      },
      {
        "state": "Tripura",
        "city": "Ganki"
      },
      {
        "state": "Tripura",
        "city": "Garji"
      },
      {
        "state": "Tripura",
        "city": "Ghilatali"
      },
      {
        "state": "Tripura",
        "city": "Gomati"
      },
      {
        "state": "Tripura",
        "city": "Halahali"
      },
      {
        "state": "Tripura",
        "city": "Halhuli"
      },
      {
        "state": "Tripura",
        "city": "Hrishyamukh"
      },
      {
        "state": "Tripura",
        "city": "Indurail"
      },
      {
        "state": "Tripura",
        "city": "Jagabandhupara"
      },
      {
        "state": "Tripura",
        "city": "Jampuii Hills"
      },
      {
        "state": "Tripura",
        "city": "Jatan Bari"
      },
      {
        "state": "Tripura",
        "city": "Jirania"
      },
      {
        "state": "Tripura",
        "city": "Jolaibari"
      },
      {
        "state": "Tripura",
        "city": "Jubarajnagar"
      },
      {
        "state": "Tripura",
        "city": "Kachucherra"
      },
      {
        "state": "Tripura",
        "city": "Kailashahar"
      },
      {
        "state": "Tripura",
        "city": "Kakraban"
      },
      {
        "state": "Tripura",
        "city": "Kameswar"
      },
      {
        "state": "Tripura",
        "city": "Kanchanbari"
      },
      {
        "state": "Tripura",
        "city": "Katalutma"
      },
      {
        "state": "Tripura",
        "city": "Kathalia"
      },
      {
        "state": "Tripura",
        "city": "Khedacherra"
      },
      {
        "state": "Tripura",
        "city": "Khowai"
      },
      {
        "state": "Tripura",
        "city": "Kuchainala"
      },
      {
        "state": "Tripura",
        "city": "Kulai Ghantachara"
      },
      {
        "state": "Tripura",
        "city": "Kumarghat"
      },
      {
        "state": "Tripura",
        "city": "Kurti"
      },
      {
        "state": "Tripura",
        "city": "Laljuri"
      },
      {
        "state": "Tripura",
        "city": "Longtarai R.F."
      },
      {
        "state": "Tripura",
        "city": "Maharanipur"
      },
      {
        "state": "Tripura",
        "city": "Mainama"
      },
      {
        "state": "Tripura",
        "city": "Manik Bhandar"
      },
      {
        "state": "Tripura",
        "city": "Manu Bazar"
      },
      {
        "state": "Tripura",
        "city": "Matabari"
      },
      {
        "state": "Tripura",
        "city": "Melaghar"
      },
      {
        "state": "Tripura",
        "city": "Mohanpur"
      },
      {
        "state": "Tripura",
        "city": "Muhuripur"
      },
      {
        "state": "Tripura",
        "city": "Nabincherra"
      },
      {
        "state": "Tripura",
        "city": "Nalkata"
      },
      {
        "state": "Tripura",
        "city": "Nehalchandranagar"
      },
      {
        "state": "Tripura",
        "city": "Paglabari"
      },
      {
        "state": "Tripura",
        "city": "Panisagar"
      },
      {
        "state": "Tripura",
        "city": "Paschim Bachaibari"
      },
      {
        "state": "Tripura",
        "city": "Paschim Champachhara"
      },
      {
        "state": "Tripura",
        "city": "Paschim Kalyanpur"
      },
      {
        "state": "Tripura",
        "city": "Paschim Kanchanbari"
      },
      {
        "state": "Tripura",
        "city": "Paschim Manpai"
      },
      {
        "state": "Tripura",
        "city": "Paschim Simna part"
      },
      {
        "state": "Tripura",
        "city": "Pecharthal"
      },
      {
        "state": "Tripura",
        "city": "Pencharthal"
      },
      {
        "state": "Tripura",
        "city": "Promodenagar"
      },
      {
        "state": "Tripura",
        "city": "Purba Anandapur"
      },
      {
        "state": "Tripura",
        "city": "Purba Bachaibari"
      },
      {
        "state": "Tripura",
        "city": "Purba Chhamanu"
      },
      {
        "state": "Tripura",
        "city": "Purba Halflong"
      },
      {
        "state": "Tripura",
        "city": "Purba Masli"
      },
      {
        "state": "Tripura",
        "city": "Purba Nalichhara"
      },
      {
        "state": "Tripura",
        "city": "Purba Tilthai"
      },
      {
        "state": "Tripura",
        "city": "Radhakishorepur R.F."
      },
      {
        "state": "Tripura",
        "city": "Radhanagar"
      },
      {
        "state": "Tripura",
        "city": "Ragna"
      },
      {
        "state": "Tripura",
        "city": "Rajkandi"
      },
      {
        "state": "Tripura",
        "city": "Rajnagar"
      },
      {
        "state": "Tripura",
        "city": "Ramdayalbari"
      },
      {
        "state": "Tripura",
        "city": "Rangauti"
      },
      {
        "state": "Tripura",
        "city": "Rangrung"
      },
      {
        "state": "Tripura",
        "city": "Ranipukur"
      },
      {
        "state": "Tripura",
        "city": "Ranirbazar"
      },
      {
        "state": "Tripura",
        "city": "Sabroom"
      },
      {
        "state": "Tripura",
        "city": "Sabual"
      },
      {
        "state": "Tripura",
        "city": "Sadar"
      },
      {
        "state": "Tripura",
        "city": "Samrurpar"
      },
      {
        "state": "Tripura",
        "city": "Santirbazar"
      },
      {
        "state": "Tripura",
        "city": "Sardu Karkari"
      },
      {
        "state": "Tripura",
        "city": "Satchand"
      },
      {
        "state": "Tripura",
        "city": "Satnala"
      },
      {
        "state": "Tripura",
        "city": "Sekerkote"
      },
      {
        "state": "Tripura",
        "city": "Sepahijala"
      },
      {
        "state": "Tripura",
        "city": "Sonaimuri"
      },
      {
        "state": "Tripura",
        "city": "Sonamura"
      },
      {
        "state": "Tripura",
        "city": "Sonatala"
      },
      {
        "state": "Tripura",
        "city": "South Tripura"
      },
      {
        "state": "Tripura",
        "city": "Teliamura"
      },
      {
        "state": "Tripura",
        "city": "Teliamura R.F. part"
      },
      {
        "state": "Tripura",
        "city": "Tuichama"
      },
      {
        "state": "Tripura",
        "city": "Tulabagan"
      },
      {
        "state": "Tripura",
        "city": "Unakoti"
      },
      {
        "state": "Tripura",
        "city": "Uptakhali"
      },
      {
        "state": "Tripura",
        "city": "Uttar Gakulnagar"
      },
      {
        "state": "Tripura",
        "city": "Uttar Machmara"
      },
      {
        "state": "Tripura",
        "city": "Uttar Padmabil part"
      },
      {
        "state": "Tripura",
        "city": "Uttar Ramchandraghat"
      },
      {
        "state": "Tripura",
        "city": "Vanghmun"
      },
      {
        "state": "Tripura",
        "city": "West Tripura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Aalapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Achheja"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Adalhat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Agra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Akbapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Akbarpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Akorha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Alapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Alapur Uparhar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Aliganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Aligarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Alipur Berjee"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Allahabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Allapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amaria"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amaur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ambedkar Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amethi 2"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amiliya Kala"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amour"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amraudha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amroha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Amroha (J.P. Nagar)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Anand Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Anapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Anpara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Anupshahar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Anwarganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Aonla"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Aranj Hami"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Armapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Arya Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ashok Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ator Nagla"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Atraith"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Atrampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Atrauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Auraiya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Azamgarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Babu Patti"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Babura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Badaun"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Badlapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bagh Shahoman"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Baghpat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bah"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Baheri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bahraich"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Baikunthpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bairia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Balari"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Balkaranpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ballia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Balrampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bamharavli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Banda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bandha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bansdih"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bansgaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bansi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Barabanki"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Barai Garh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Baraut"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bareilly"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bargadi Magath"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Barhaj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bariya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Barkhera"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Barna"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Basllia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Basti"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bausar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Behat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Behta Gambhirpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Belthara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Belthara Road"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhadohi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhadrsa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhairam Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhamora"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhanpur Rani"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bharthana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bharu"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhatpar Rani"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhautipratappur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bheoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhimsen"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhinga"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhongaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bhowapar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bidauri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bidhnu"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bidhuna"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bijnor"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bikapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bilari"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bilgram"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bilhaur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bilsi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Binour"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bisalpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bisara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bisauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bisayakpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bishrakh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Biswan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bithoor"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bodarwar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Brijmanganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Budaun"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Budhan Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Budhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Bulandshahr"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Busauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Campirganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chail"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chakar Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chakarpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chakeri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chakia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chandauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chandausi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chandpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Charwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chaubepur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chaubepur Pankhan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chauraha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chaurai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chauri Chaura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chhapraula"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chhata"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chhata Rural"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chipyana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chitrakoot"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Chunar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Colonelganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dadri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dalmau"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dariya Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dataganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Daulat Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Deoband"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Deoria"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhan Ghata"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhanapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhanaura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhanghata"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dharauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhaulana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhaurahara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhaurehra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhoom Manikpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhusah"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dhusuriya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dibai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Domariyaganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Dudhinagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Etah"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Etawah"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Etmadpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fahimabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Faizabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Farenda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Faridpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Faridpur Meghu Urf Tuwakpuri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Farrashkhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Farrukhabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fatahabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fatehpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fatehpur Roshanai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fazalganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Filkhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Firozabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Fridpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gajraula"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gambhir Patti Bisana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ganga Ganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gangoh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Garautha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Garhmukteshwar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Garholi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gauriganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gazipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ghatampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ghaziabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ghazipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ghiyanagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ghosi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Godhan Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gola Bazar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gola Gokaran Nath"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gonda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gopalpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gopalpur Narwal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gorakhpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Govind Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Greater Noida"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gunnaur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Gyanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Haidergarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Haliyapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hamirpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Handia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hanuman Ganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hapur (Panchsheel Nagar)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Harchand Khera"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hardauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hardoi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Harjinder Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Harraiya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hasanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hasanpur Soraon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hata"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hathgaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hathras"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hatia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Hithgaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Holagarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ikauna"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Itaura Or Itraura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Itra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Itwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jafrabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jahana Ganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jajauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jajmau"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jakhanian"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalal Kasim"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur Chandhan Urfpirthipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur Parsurampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur Pikar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur Sahara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalalpur Tekam Para"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalaun"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jalesar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jamoo"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jangal Agahi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Janghai Bazar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jansath"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jarkala"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jasrana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jaswant Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jaunpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jawahar Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jewar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jhansi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jhinjhak"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Jugrajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Juhi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "KHAJANI"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kadipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kadri Champatpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kahal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kaindha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kairana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kaisarganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kakadeo"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kakhania"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kakori"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kalpi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kalyanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kamalpur Narwal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kamlapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kandarwal Kalan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kannauj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kanpur Dehat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kanpur Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kanshiram Nagar (Kasganj)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kanth"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Karahal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Karari"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Karbigwan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Karchana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Karhal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kasba Sagri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kasganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kasia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kasia Bazaar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kasigaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Katghar Sadar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kathara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Katherua"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kathongar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kauriram"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kaushalpuri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kaushambi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kerakat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khadesar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khain"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khalilabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khan Jahanpur Chirkuan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khanjarpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khapra Mohal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khatmepur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khekada"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kheora"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kheragarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khiluai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khoria"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Khurja"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kilak"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kiraoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kithore"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Konch"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Koraon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kotkadar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kotwalipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kudani"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kudna Pur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kudni"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kulgaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kunda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Kushinagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ladlapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Laharpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lajpat Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lakhimpur - Kheri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lakhraiya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lalganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lalganj Ajhara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lalgopalganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lalitpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lambhua"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lohanipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Loni"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Lucknow"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Machhali Shahar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Madanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Madhogarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Madhuban"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Madria"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maharajganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maharajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maharani Paschim"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahmoodabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahmudabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahoba"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maholi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahrauni"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahsi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahua Gaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mahuawa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maicha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mainpuri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mainuddinpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Majhawan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Malihabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Malimanha Mustkaham"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mallawan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Manakpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Manapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mandhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mani Garhi Bangar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mani Garhi Khadar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maniha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Manjhanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mankapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mant"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mardanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mariahu"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Marihan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mathura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mau"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mau Aima"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Maunath Bhanjan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mauranipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mawana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Meerganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Meerpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Meerut"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mehnagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Meja"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Menhdawal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Milak"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Milkipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mirazapur Sadar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mirganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mirzapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mishrikh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Misir Patti"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Misrikh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Misrikh-cum-Neemsar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Modinagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mohammadabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mohammadi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mohanlalganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Moor Ghat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Moradabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Morta"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Moth"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Moth Rural"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Motijheel"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Motinagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mrizapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Mungra Badshahpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Munshipurwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Muradnagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Musafirkhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Muzaffarabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Muzaffarnagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nachulwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nagal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nagina"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Najibabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nakthar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nakur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nanauta"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nangal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nanpara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Naramau"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Narhar Patti"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Narwal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nasra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Naubasta"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Naugarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nautanwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nawabganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nayaganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "New Kanpur City"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nichlaul"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nighasan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nigohi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nijamabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nirala"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Nistoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Noida"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Orai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pachor"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Padrauna"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pakharauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Palhepur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Palia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Palia Kalan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Palibhogipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pallia"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Panki"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Parade"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Parsadepur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Parsauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pasonda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pathakpur Shivrajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Patiyali"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Patiyali Dehat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Patti"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Patwa Mutfarka"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Payagpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pem"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Phaphamau"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pharenda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Phulpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Phuphuwarrajthok"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pilibhit"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pindra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pipergaon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Piyaoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pooranpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Powayan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pratapgarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pratapgarh city"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Premnagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pukhrayan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Punwarka"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Purana Kanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Puranpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Pure Goliya"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Purwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Purwameer"
      },
      {
        "state": "Uttar Pradesh",
        "city": "RaeBareli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Railganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Raipur Kukhat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rajesultanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ram Sanehi Ghat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ramaipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ramnagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rampur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rampur Maniharan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rania"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Raniganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rasra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rasulabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rasulpur Umra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Robertsganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rooma"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Roorkee"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Roza Yakubpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rudauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rudrapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Rura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sachendi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sadabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Safipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sagri"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saharanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sahaswan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sahjanwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sahson"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saidabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saidpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saifai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saifpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saiyan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sakaldiha"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Salarpur Kalan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Salempur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Salon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Salon Bazar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sambhal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sambhal (Bhim Nagar)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sandila"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sanigawan"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sankalpa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sant Kabir Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sapai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Saraismail Urf Khagalpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sarayindrawat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sardhana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sareni"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sarsaul"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sarsawa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sarvodaya Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sawaijpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sawayajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Semarjhal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sewarhi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shahabad 2"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shahganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shahjahanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shajnwa"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shamali (Prabuddh Nagar)"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shankargarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shanti Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sheoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shikarpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shikohabad"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shivaji Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shivarajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shivli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shivrajpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shohratgarh"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Shyam Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Siddharth Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sidhauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sikanderpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sikarpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Simbhaoli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sirathu"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sirauligauspur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sirhi Itara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sitapur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Siyana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sobhadra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sohawal"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sonbhadra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Soraon"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Suar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Subhauli"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Sultanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Swaijpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Swarup Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Talbehat"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tanda"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tarabganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tatiyaganj"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Taudhakpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Telia Kurmi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Thakurdwara"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Thana"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tikra"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tikra Kanpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tikri Ta.Kalmai"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tilhar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tiloi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tilsahari"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Tulsipur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Ugrasenpur"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Unchahar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Unnao"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Utraula"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Uttaripura"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Varanasi"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Vijay Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Vikas Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Yashoda Nagar"
      },
      {
        "state": "Uttar Pradesh",
        "city": "Zamania"
      },
      {
        "state": "Uttarakhand",
        "city": "Almora"
      },
      {
        "state": "Uttarakhand",
        "city": "Bageshwar"
      },
      {
        "state": "Uttarakhand",
        "city": "Barkinda"
      },
      {
        "state": "Uttarakhand",
        "city": "Barkot"
      },
      {
        "state": "Uttarakhand",
        "city": "Basikhet"
      },
      {
        "state": "Uttarakhand",
        "city": "Bazpur"
      },
      {
        "state": "Uttarakhand",
        "city": "Berinag"
      },
      {
        "state": "Uttarakhand",
        "city": "Bhanoli"
      },
      {
        "state": "Uttarakhand",
        "city": "Bhatwari"
      },
      {
        "state": "Uttarakhand",
        "city": "Bhikia Sain"
      },
      {
        "state": "Uttarakhand",
        "city": "Bhikiyasan"
      },
      {
        "state": "Uttarakhand",
        "city": "Bhimtal"
      },
      {
        "state": "Uttarakhand",
        "city": "Burkot"
      },
      {
        "state": "Uttarakhand",
        "city": "Chakrata"
      },
      {
        "state": "Uttarakhand",
        "city": "Chamoli"
      },
      {
        "state": "Uttarakhand",
        "city": "Champawat"
      },
      {
        "state": "Uttarakhand",
        "city": "Chaukhutiya"
      },
      {
        "state": "Uttarakhand",
        "city": "Dehradun"
      },
      {
        "state": "Uttarakhand",
        "city": "Devaprayag"
      },
      {
        "state": "Uttarakhand",
        "city": "Dhanolti"
      },
      {
        "state": "Uttarakhand",
        "city": "Dhanolti Lagga Goth"
      },
      {
        "state": "Uttarakhand",
        "city": "Dharchula"
      },
      {
        "state": "Uttarakhand",
        "city": "Dhumakot"
      },
      {
        "state": "Uttarakhand",
        "city": "Didihat"
      },
      {
        "state": "Uttarakhand",
        "city": "Dunda Range"
      },
      {
        "state": "Uttarakhand",
        "city": "Dwarahat"
      },
      {
        "state": "Uttarakhand",
        "city": "Gadarpur"
      },
      {
        "state": "Uttarakhand",
        "city": "Gaja"
      },
      {
        "state": "Uttarakhand",
        "city": "Gangolihat"
      },
      {
        "state": "Uttarakhand",
        "city": "Garur"
      },
      {
        "state": "Uttarakhand",
        "city": "Ghansali"
      },
      {
        "state": "Uttarakhand",
        "city": "Ghat"
      },
      {
        "state": "Uttarakhand",
        "city": "Haldwani"
      },
      {
        "state": "Uttarakhand",
        "city": "Haridwar"
      },
      {
        "state": "Uttarakhand",
        "city": "Jainti"
      },
      {
        "state": "Uttarakhand",
        "city": "Jakhnidhar"
      },
      {
        "state": "Uttarakhand",
        "city": "Jakholi"
      },
      {
        "state": "Uttarakhand",
        "city": "Jakholi Badma"
      },
      {
        "state": "Uttarakhand",
        "city": "Jaspur"
      },
      {
        "state": "Uttarakhand",
        "city": "Joshimath"
      },
      {
        "state": "Uttarakhand",
        "city": "Kaladhungi"
      },
      {
        "state": "Uttarakhand",
        "city": "Kanda"
      },
      {
        "state": "Uttarakhand",
        "city": "Kapkot"
      },
      {
        "state": "Uttarakhand",
        "city": "Karnaprayag"
      },
      {
        "state": "Uttarakhand",
        "city": "Kashipur"
      },
      {
        "state": "Uttarakhand",
        "city": "Khadun Laga Gawali"
      },
      {
        "state": "Uttarakhand",
        "city": "Khatima"
      },
      {
        "state": "Uttarakhand",
        "city": "Kichha"
      },
      {
        "state": "Uttarakhand",
        "city": "Kotdwara"
      },
      {
        "state": "Uttarakhand",
        "city": "Laksar"
      },
      {
        "state": "Uttarakhand",
        "city": "Lalkuan"
      },
      {
        "state": "Uttarakhand",
        "city": "Lansdowne"
      },
      {
        "state": "Uttarakhand",
        "city": "Lohaghat"
      },
      {
        "state": "Uttarakhand",
        "city": "Maidhana Saimi"
      },
      {
        "state": "Uttarakhand",
        "city": "Munsyari"
      },
      {
        "state": "Uttarakhand",
        "city": "Mussoorie"
      },
      {
        "state": "Uttarakhand",
        "city": "Muzaffarabad"
      },
      {
        "state": "Uttarakhand",
        "city": "Nainital"
      },
      {
        "state": "Uttarakhand",
        "city": "Narendra Nagar"
      },
      {
        "state": "Uttarakhand",
        "city": "New Tehri"
      },
      {
        "state": "Uttarakhand",
        "city": "Pauri"
      },
      {
        "state": "Uttarakhand",
        "city": "Pauri Garhwal"
      },
      {
        "state": "Uttarakhand",
        "city": "Pithoragarh"
      },
      {
        "state": "Uttarakhand",
        "city": "Pokhari"
      },
      {
        "state": "Uttarakhand",
        "city": "Purola"
      },
      {
        "state": "Uttarakhand",
        "city": "Radu"
      },
      {
        "state": "Uttarakhand",
        "city": "Rajgarhi"
      },
      {
        "state": "Uttarakhand",
        "city": "Ramnagar"
      },
      {
        "state": "Uttarakhand",
        "city": "Ranikhet"
      },
      {
        "state": "Uttarakhand",
        "city": "Rishikesh"
      },
      {
        "state": "Uttarakhand",
        "city": "Roorkee"
      },
      {
        "state": "Uttarakhand",
        "city": "Rudraprayag"
      },
      {
        "state": "Uttarakhand",
        "city": "Rudrapur"
      },
      {
        "state": "Uttarakhand",
        "city": "Sigro"
      },
      {
        "state": "Uttarakhand",
        "city": "Sitarganj"
      },
      {
        "state": "Uttarakhand",
        "city": "Someshwar"
      },
      {
        "state": "Uttarakhand",
        "city": "South Gola Range"
      },
      {
        "state": "Uttarakhand",
        "city": "Takoli Challannsyun"
      },
      {
        "state": "Uttarakhand",
        "city": "Tanakpur"
      },
      {
        "state": "Uttarakhand",
        "city": "Tanda Range"
      },
      {
        "state": "Uttarakhand",
        "city": "Tehri"
      },
      {
        "state": "Uttarakhand",
        "city": "Tehri Garhwal"
      },
      {
        "state": "Uttarakhand",
        "city": "Thali Sain"
      },
      {
        "state": "Uttarakhand",
        "city": "Tharali"
      },
      {
        "state": "Uttarakhand",
        "city": "Tiuni"
      },
      {
        "state": "Uttarakhand",
        "city": "Udham Singh Nagar"
      },
      {
        "state": "Uttarakhand",
        "city": "Ukhimath"
      },
      {
        "state": "Uttarakhand",
        "city": "Veerbhadra"
      },
      {
        "state": "Uttarakhand",
        "city": "Vikasnagar"
      },
      {
        "state": "West Bengal",
        "city": "Adasimla"
      },
      {
        "state": "West Bengal",
        "city": "Adhata"
      },
      {
        "state": "West Bengal",
        "city": "Adra"
      },
      {
        "state": "West Bengal",
        "city": "Ahiran"
      },
      {
        "state": "West Bengal",
        "city": "Ajijnagar"
      },
      {
        "state": "West Bengal",
        "city": "Ajodhyapur"
      },
      {
        "state": "West Bengal",
        "city": "Akaipur"
      },
      {
        "state": "West Bengal",
        "city": "Akanda Beria"
      },
      {
        "state": "West Bengal",
        "city": "Akandakeshari"
      },
      {
        "state": "West Bengal",
        "city": "Akpura"
      },
      {
        "state": "West Bengal",
        "city": "Aktail"
      },
      {
        "state": "West Bengal",
        "city": "Alal"
      },
      {
        "state": "West Bengal",
        "city": "Alanggiri"
      },
      {
        "state": "West Bengal",
        "city": "Alankarpur"
      },
      {
        "state": "West Bengal",
        "city": "Alikhoja"
      },
      {
        "state": "West Bengal",
        "city": "Alipurduar"
      },
      {
        "state": "West Bengal",
        "city": "Amarshi"
      },
      {
        "state": "West Bengal",
        "city": "Ambaria"
      },
      {
        "state": "West Bengal",
        "city": "Ambigere"
      },
      {
        "state": "West Bengal",
        "city": "Amdanga"
      },
      {
        "state": "West Bengal",
        "city": "Amdobe"
      },
      {
        "state": "West Bengal",
        "city": "Amla Kora"
      },
      {
        "state": "West Bengal",
        "city": "Amodghata"
      },
      {
        "state": "West Bengal",
        "city": "Amodpur"
      },
      {
        "state": "West Bengal",
        "city": "Amta"
      },
      {
        "state": "West Bengal",
        "city": "Amtalia"
      },
      {
        "state": "West Bengal",
        "city": "Amudia"
      },
      {
        "state": "West Bengal",
        "city": "Analberia"
      },
      {
        "state": "West Bengal",
        "city": "Anandapally"
      },
      {
        "state": "West Bengal",
        "city": "Andal"
      },
      {
        "state": "West Bengal",
        "city": "Andhirampara"
      },
      {
        "state": "West Bengal",
        "city": "Angrail"
      },
      {
        "state": "West Bengal",
        "city": "Angua"
      },
      {
        "state": "West Bengal",
        "city": "Anikola"
      },
      {
        "state": "West Bengal",
        "city": "Arambagh"
      },
      {
        "state": "West Bengal",
        "city": "Argara"
      },
      {
        "state": "West Bengal",
        "city": "Arsha"
      },
      {
        "state": "West Bengal",
        "city": "Asansol"
      },
      {
        "state": "West Bengal",
        "city": "Asda"
      },
      {
        "state": "West Bengal",
        "city": "Asharu"
      },
      {
        "state": "West Bengal",
        "city": "Atbati"
      },
      {
        "state": "West Bengal",
        "city": "Atghara"
      },
      {
        "state": "West Bengal",
        "city": "Atpukur"
      },
      {
        "state": "West Bengal",
        "city": "Aturia"
      },
      {
        "state": "West Bengal",
        "city": "Ausgram"
      },
      {
        "state": "West Bengal",
        "city": "Ayma Barbaria"
      },
      {
        "state": "West Bengal",
        "city": "Aziznagar"
      },
      {
        "state": "West Bengal",
        "city": "BHANDER KOLA BAZAR"
      },
      {
        "state": "West Bengal",
        "city": "Babla"
      },
      {
        "state": "West Bengal",
        "city": "Bachhurkhoyar"
      },
      {
        "state": "West Bengal",
        "city": "Badalpur 2Nd Part"
      },
      {
        "state": "West Bengal",
        "city": "Badkulla"
      },
      {
        "state": "West Bengal",
        "city": "Baduria"
      },
      {
        "state": "West Bengal",
        "city": "Bagband-Saiberia"
      },
      {
        "state": "West Bengal",
        "city": "Bagdah"
      },
      {
        "state": "West Bengal",
        "city": "Baghmundi"
      },
      {
        "state": "West Bengal",
        "city": "Baguran Jalpai"
      },
      {
        "state": "West Bengal",
        "city": "Baharampur"
      },
      {
        "state": "West Bengal",
        "city": "Bahurupa"
      },
      {
        "state": "West Bengal",
        "city": "Baidyapur"
      },
      {
        "state": "West Bengal",
        "city": "Baikara"
      },
      {
        "state": "West Bengal",
        "city": "Baikunthapur"
      },
      {
        "state": "West Bengal",
        "city": "Baishnabnagar"
      },
      {
        "state": "West Bengal",
        "city": "Bakhrabad"
      },
      {
        "state": "West Bengal",
        "city": "Baksha"
      },
      {
        "state": "West Bengal",
        "city": "Bakurpada"
      },
      {
        "state": "West Bengal",
        "city": "Balagarh"
      },
      {
        "state": "West Bengal",
        "city": "Balakdi"
      },
      {
        "state": "West Bengal",
        "city": "Balarampur"
      },
      {
        "state": "West Bengal",
        "city": "Baliadanga"
      },
      {
        "state": "West Bengal",
        "city": "Balichak"
      },
      {
        "state": "West Bengal",
        "city": "Balighai"
      },
      {
        "state": "West Bengal",
        "city": "Balisai"
      },
      {
        "state": "West Bengal",
        "city": "Balitora"
      },
      {
        "state": "West Bengal",
        "city": "Ballabhpur"
      },
      {
        "state": "West Bengal",
        "city": "Ballavpur"
      },
      {
        "state": "West Bengal",
        "city": "Ballichak"
      },
      {
        "state": "West Bengal",
        "city": "Balurghat"
      },
      {
        "state": "West Bengal",
        "city": "Balyagobindapur"
      },
      {
        "state": "West Bengal",
        "city": "Bamangachi"
      },
      {
        "state": "West Bengal",
        "city": "Bamangola"
      },
      {
        "state": "West Bengal",
        "city": "Bamanpukur"
      },
      {
        "state": "West Bengal",
        "city": "Bamongola"
      },
      {
        "state": "West Bengal",
        "city": "Bamunara"
      },
      {
        "state": "West Bengal",
        "city": "Bamunia"
      },
      {
        "state": "West Bengal",
        "city": "Bamunia Purba"
      },
      {
        "state": "West Bengal",
        "city": "Bandel"
      },
      {
        "state": "West Bengal",
        "city": "Bandhu Chak"
      },
      {
        "state": "West Bengal",
        "city": "Baneswarpur"
      },
      {
        "state": "West Bengal",
        "city": "Bangangram"
      },
      {
        "state": "West Bengal",
        "city": "Bangaon"
      },
      {
        "state": "West Bengal",
        "city": "Banglani"
      },
      {
        "state": "West Bengal",
        "city": "Bankra Dobar"
      },
      {
        "state": "West Bengal",
        "city": "Bankura"
      },
      {
        "state": "West Bengal",
        "city": "Bankura - I"
      },
      {
        "state": "West Bengal",
        "city": "Bankura - II"
      },
      {
        "state": "West Bengal",
        "city": "Bansberia"
      },
      {
        "state": "West Bengal",
        "city": "Bansh Kuti"
      },
      {
        "state": "West Bengal",
        "city": "Bansihari"
      },
      {
        "state": "West Bengal",
        "city": "Bara Bankra"
      },
      {
        "state": "West Bengal",
        "city": "Bara Bari"
      },
      {
        "state": "West Bengal",
        "city": "Bara Garania"
      },
      {
        "state": "West Bengal",
        "city": "Bara Kalanki"
      },
      {
        "state": "West Bengal",
        "city": "Bara Simulguri"
      },
      {
        "state": "West Bengal",
        "city": "Barabani"
      },
      {
        "state": "West Bengal",
        "city": "Barabantalia"
      },
      {
        "state": "West Bengal",
        "city": "Barabatia"
      },
      {
        "state": "West Bengal",
        "city": "Barabazar"
      },
      {
        "state": "West Bengal",
        "city": "Barabhum"
      },
      {
        "state": "West Bengal",
        "city": "Barangi"
      },
      {
        "state": "West Bengal",
        "city": "Barasat"
      },
      {
        "state": "West Bengal",
        "city": "Barasehana"
      },
      {
        "state": "West Bengal",
        "city": "Baratala"
      },
      {
        "state": "West Bengal",
        "city": "Baraudaypur"
      },
      {
        "state": "West Bengal",
        "city": "Bardhaman"
      },
      {
        "state": "West Bengal",
        "city": "Bargram"
      },
      {
        "state": "West Bengal",
        "city": "Baribhanga Abad"
      },
      {
        "state": "West Bengal",
        "city": "Barida"
      },
      {
        "state": "West Bengal",
        "city": "Barjora"
      },
      {
        "state": "West Bengal",
        "city": "Barrackpore"
      },
      {
        "state": "West Bengal",
        "city": "Barrackpur Cantonment"
      },
      {
        "state": "West Bengal",
        "city": "Barsul"
      },
      {
        "state": "West Bengal",
        "city": "Bartana"
      },
      {
        "state": "West Bengal",
        "city": "Baruipur"
      },
      {
        "state": "West Bengal",
        "city": "Barunhat"
      },
      {
        "state": "West Bengal",
        "city": "Basanti"
      },
      {
        "state": "West Bengal",
        "city": "Basantia"
      },
      {
        "state": "West Bengal",
        "city": "Basirhat"
      },
      {
        "state": "West Bengal",
        "city": "Basudeb Berya"
      },
      {
        "state": "West Bengal",
        "city": "Basudebpur"
      },
      {
        "state": "West Bengal",
        "city": "Bathuari"
      },
      {
        "state": "West Bengal",
        "city": "Beara"
      },
      {
        "state": "West Bengal",
        "city": "Begunia"
      },
      {
        "state": "West Bengal",
        "city": "Beharkhanda Nabagram"
      },
      {
        "state": "West Bengal",
        "city": "Behrampore"
      },
      {
        "state": "West Bengal",
        "city": "Belda"
      },
      {
        "state": "West Bengal",
        "city": "Beldanga"
      },
      {
        "state": "West Bengal",
        "city": "Belmula"
      },
      {
        "state": "West Bengal",
        "city": "Benachakri"
      },
      {
        "state": "West Bengal",
        "city": "Benadiha"
      },
      {
        "state": "West Bengal",
        "city": "Benichak"
      },
      {
        "state": "West Bengal",
        "city": "Berabari"
      },
      {
        "state": "West Bengal",
        "city": "Beraberi"
      },
      {
        "state": "West Bengal",
        "city": "Berhampore"
      },
      {
        "state": "West Bengal",
        "city": "Bermajur"
      },
      {
        "state": "West Bengal",
        "city": "Bethuadahari"
      },
      {
        "state": "West Bengal",
        "city": "Bhabanipur"
      },
      {
        "state": "West Bengal",
        "city": "Bhaduria"
      },
      {
        "state": "West Bengal",
        "city": "Bhagabati"
      },
      {
        "state": "West Bengal",
        "city": "Bhagatpur Tea Garden"
      },
      {
        "state": "West Bengal",
        "city": "Bhagwangola-ii"
      },
      {
        "state": "West Bengal",
        "city": "Bhagyamantapur"
      },
      {
        "state": "West Bengal",
        "city": "Bhaja Chauli"
      },
      {
        "state": "West Bengal",
        "city": "Bhandar khola"
      },
      {
        "state": "West Bengal",
        "city": "Bhandarhati"
      },
      {
        "state": "West Bengal",
        "city": "Bhandarkhali"
      },
      {
        "state": "West Bengal",
        "city": "Bhangatushkhali"
      },
      {
        "state": "West Bengal",
        "city": "Bhasila"
      },
      {
        "state": "West Bengal",
        "city": "Bhastara"
      },
      {
        "state": "West Bengal",
        "city": "Bhatar"
      },
      {
        "state": "West Bengal",
        "city": "Bhatda"
      },
      {
        "state": "West Bengal",
        "city": "Bhatpara"
      },
      {
        "state": "West Bengal",
        "city": "Bhaudi"
      },
      {
        "state": "West Bengal",
        "city": "Bhupati Nagar"
      },
      {
        "state": "West Bengal",
        "city": "Bhupatinagar"
      },
      {
        "state": "West Bengal",
        "city": "Bibhishananpur"
      },
      {
        "state": "West Bengal",
        "city": "Billagram"
      },
      {
        "state": "West Bengal",
        "city": "Binnaguri"
      },
      {
        "state": "West Bengal",
        "city": "Binpur"
      },
      {
        "state": "West Bengal",
        "city": "Biramnagar"
      },
      {
        "state": "West Bengal",
        "city": "Biramput"
      },
      {
        "state": "West Bengal",
        "city": "Birbhum"
      },
      {
        "state": "West Bengal",
        "city": "Birpara Tea Garden"
      },
      {
        "state": "West Bengal",
        "city": "Bishnpur"
      },
      {
        "state": "West Bengal",
        "city": "Bishnuprosad"
      },
      {
        "state": "West Bengal",
        "city": "Bishpur"
      },
      {
        "state": "West Bengal",
        "city": "Biswanathpur"
      },
      {
        "state": "West Bengal",
        "city": "Bithari"
      },
      {
        "state": "West Bengal",
        "city": "Bodai"
      },
      {
        "state": "West Bengal",
        "city": "Bodhra"
      },
      {
        "state": "West Bengal",
        "city": "Boinchi"
      },
      {
        "state": "West Bengal",
        "city": "Bolkushda"
      },
      {
        "state": "West Bengal",
        "city": "Bolpur"
      },
      {
        "state": "West Bengal",
        "city": "Bongabari"
      },
      {
        "state": "West Bengal",
        "city": "Bongaon"
      },
      {
        "state": "West Bengal",
        "city": "Borah"
      },
      {
        "state": "West Bengal",
        "city": "Borai"
      },
      {
        "state": "West Bengal",
        "city": "Bouthakurani"
      },
      {
        "state": "West Bengal",
        "city": "Boyra"
      },
      {
        "state": "West Bengal",
        "city": "Brajaballavpur"
      },
      {
        "state": "West Bengal",
        "city": "Bulbulchandi"
      },
      {
        "state": "West Bengal",
        "city": "Burdwan"
      },
      {
        "state": "West Bengal",
        "city": "Canning"
      },
      {
        "state": "West Bengal",
        "city": "Chaigharia"
      },
      {
        "state": "West Bengal",
        "city": "Chaital"
      },
      {
        "state": "West Bengal",
        "city": "Chak Bhabani"
      },
      {
        "state": "West Bengal",
        "city": "Chak Patli"
      },
      {
        "state": "West Bengal",
        "city": "Chakdaha"
      },
      {
        "state": "West Bengal",
        "city": "Champahati"
      },
      {
        "state": "West Bengal",
        "city": "Chanchal"
      },
      {
        "state": "West Bengal",
        "city": "Chandabila"
      },
      {
        "state": "West Bengal",
        "city": "Chandangar"
      },
      {
        "state": "West Bengal",
        "city": "Chandannagar"
      },
      {
        "state": "West Bengal",
        "city": "Chanddandaha"
      },
      {
        "state": "West Bengal",
        "city": "Chandipur"
      },
      {
        "state": "West Bengal",
        "city": "Chanditala"
      },
      {
        "state": "West Bengal",
        "city": "Chandpara"
      },
      {
        "state": "West Bengal",
        "city": "Chandrakona"
      },
      {
        "state": "West Bengal",
        "city": "Chandrakona Road"
      },
      {
        "state": "West Bengal",
        "city": "Chapari"
      },
      {
        "state": "West Bengal",
        "city": "Chapra"
      },
      {
        "state": "West Bengal",
        "city": "Charalkhali"
      },
      {
        "state": "West Bengal",
        "city": "Charghat"
      },
      {
        "state": "West Bengal",
        "city": "Chata Padmapur"
      },
      {
        "state": "West Bengal",
        "city": "Chatla"
      },
      {
        "state": "West Bengal",
        "city": "Chauhata"
      },
      {
        "state": "West Bengal",
        "city": "Chauki Mirdadpur"
      },
      {
        "state": "West Bengal",
        "city": "Chaulia"
      },
      {
        "state": "West Bengal",
        "city": "Chaulkhola"
      },
      {
        "state": "West Bengal",
        "city": "Chelyama"
      },
      {
        "state": "West Bengal",
        "city": "Chhaigharia"
      },
      {
        "state": "West Bengal",
        "city": "Chharrah"
      },
      {
        "state": "West Bengal",
        "city": "Chhat Guzrimari"
      },
      {
        "state": "West Bengal",
        "city": "Chhatna"
      },
      {
        "state": "West Bengal",
        "city": "Chhorda"
      },
      {
        "state": "West Bengal",
        "city": "Chhota Bagasthi"
      },
      {
        "state": "West Bengal",
        "city": "Chhota Chaulkhola"
      },
      {
        "state": "West Bengal",
        "city": "Chhota Jagulia"
      },
      {
        "state": "West Bengal",
        "city": "Chhota Laukuthi"
      },
      {
        "state": "West Bengal",
        "city": "Chhota Sehara"
      },
      {
        "state": "West Bengal",
        "city": "Chikanpara"
      },
      {
        "state": "West Bengal",
        "city": "Chingur Dania"
      },
      {
        "state": "West Bengal",
        "city": "Chinsurah - Magra"
      },
      {
        "state": "West Bengal",
        "city": "Chirulia"
      },
      {
        "state": "West Bengal",
        "city": "Choralmani"
      },
      {
        "state": "West Bengal",
        "city": "Chorpalia"
      },
      {
        "state": "West Bengal",
        "city": "Chowberia"
      },
      {
        "state": "West Bengal",
        "city": "Chowrashi"
      },
      {
        "state": "West Bengal",
        "city": "Chunpara"
      },
      {
        "state": "West Bengal",
        "city": "Contai"
      },
      {
        "state": "West Bengal",
        "city": "Cooch Behar"
      },
      {
        "state": "West Bengal",
        "city": "Daisai"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Bagundi"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Bayenda"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Chatra"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Darua"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Kalamdan"
      },
      {
        "state": "West Bengal",
        "city": "Dakshin Moyna"
      },
      {
        "state": "West Bengal",
        "city": "Dalkhola"
      },
      {
        "state": "West Bengal",
        "city": "Damaipur"
      },
      {
        "state": "West Bengal",
        "city": "Dandirhat P"
      },
      {
        "state": "West Bengal",
        "city": "Dangarpara"
      },
      {
        "state": "West Bengal",
        "city": "Dankuni"
      },
      {
        "state": "West Bengal",
        "city": "Darjeeling"
      },
      {
        "state": "West Bengal",
        "city": "Darua"
      },
      {
        "state": "West Bengal",
        "city": "Dasagram"
      },
      {
        "state": "West Bengal",
        "city": "Dashin Akhratala"
      },
      {
        "state": "West Bengal",
        "city": "Daspur"
      },
      {
        "state": "West Bengal",
        "city": "Dattapara"
      },
      {
        "state": "West Bengal",
        "city": "Dayal Thong Tea Garden"
      },
      {
        "state": "West Bengal",
        "city": "Deara"
      },
      {
        "state": "West Bengal",
        "city": "Debagram"
      },
      {
        "state": "West Bengal",
        "city": "Debaipukur"
      },
      {
        "state": "West Bengal",
        "city": "Debra"
      },
      {
        "state": "West Bengal",
        "city": "Debsala"
      },
      {
        "state": "West Bengal",
        "city": "Deganga"
      },
      {
        "state": "West Bengal",
        "city": "Dekhali"
      },
      {
        "state": "West Bengal",
        "city": "Deotala"
      },
      {
        "state": "West Bengal",
        "city": "Deuli"
      },
      {
        "state": "West Bengal",
        "city": "Deulia"
      },
      {
        "state": "West Bengal",
        "city": "Deulpota"
      },
      {
        "state": "West Bengal",
        "city": "Dhalbelun"
      },
      {
        "state": "West Bengal",
        "city": "Dhaltitha P"
      },
      {
        "state": "West Bengal",
        "city": "Dhanghara"
      },
      {
        "state": "West Bengal",
        "city": "Dhania"
      },
      {
        "state": "West Bengal",
        "city": "Dhaniakhali"
      },
      {
        "state": "West Bengal",
        "city": "Dhanyakuria"
      },
      {
        "state": "West Bengal",
        "city": "Dheknamari"
      },
      {
        "state": "West Bengal",
        "city": "Dholmari"
      },
      {
        "state": "West Bengal",
        "city": "Dhramatala"
      },
      {
        "state": "West Bengal",
        "city": "Dhubulia"
      },
      {
        "state": "West Bengal",
        "city": "Dhuliyan"
      },
      {
        "state": "West Bengal",
        "city": "Dhupguri"
      },
      {
        "state": "West Bengal",
        "city": "Dhuturdaha"
      },
      {
        "state": "West Bengal",
        "city": "Diamond Harbour"
      },
      {
        "state": "West Bengal",
        "city": "Digha"
      },
      {
        "state": "West Bengal",
        "city": "Digha Danri"
      },
      {
        "state": "West Bengal",
        "city": "Dihierench"
      },
      {
        "state": "West Bengal",
        "city": "Dinhata"
      },
      {
        "state": "West Bengal",
        "city": "Dobandi"
      },
      {
        "state": "West Bengal",
        "city": "Dogachhi"
      },
      {
        "state": "West Bengal",
        "city": "Dogachhi P"
      },
      {
        "state": "West Bengal",
        "city": "Dogachia"
      },
      {
        "state": "West Bengal",
        "city": "Dol Gram"
      },
      {
        "state": "West Bengal",
        "city": "Domjur"
      },
      {
        "state": "West Bengal",
        "city": "Domkal"
      },
      {
        "state": "West Bengal",
        "city": "Dongaria"
      },
      {
        "state": "West Bengal",
        "city": "Dubda"
      },
      {
        "state": "West Bengal",
        "city": "Dubrajpur"
      },
      {
        "state": "West Bengal",
        "city": "Durgamandap"
      },
      {
        "state": "West Bengal",
        "city": "Durgapur"
      },
      {
        "state": "West Bengal",
        "city": "Duria"
      },
      {
        "state": "West Bengal",
        "city": "Durmut"
      },
      {
        "state": "West Bengal",
        "city": "Duttapara"
      },
      {
        "state": "West Bengal",
        "city": "Dwipchandrapur"
      },
      {
        "state": "West Bengal",
        "city": "Egra"
      },
      {
        "state": "West Bengal",
        "city": "Ekarukhi"
      },
      {
        "state": "West Bengal",
        "city": "Englishbazar"
      },
      {
        "state": "West Bengal",
        "city": "Eojnagar"
      },
      {
        "state": "West Bengal",
        "city": "Erenda"
      },
      {
        "state": "West Bengal",
        "city": "Falakata"
      },
      {
        "state": "West Bengal",
        "city": "Falta"
      },
      {
        "state": "West Bengal",
        "city": "Farakka"
      },
      {
        "state": "West Bengal",
        "city": "Faridpur Durgapur"
      },
      {
        "state": "West Bengal",
        "city": "Farrakka Barrage Township"
      },
      {
        "state": "West Bengal",
        "city": "Gachaimari"
      },
      {
        "state": "West Bengal",
        "city": "Gadpukuria"
      },
      {
        "state": "West Bengal",
        "city": "Gaganeswar"
      },
      {
        "state": "West Bengal",
        "city": "Gaighata"
      },
      {
        "state": "West Bengal",
        "city": "Gaita"
      },
      {
        "state": "West Bengal",
        "city": "Galdaha"
      },
      {
        "state": "West Bengal",
        "city": "Galsi"
      },
      {
        "state": "West Bengal",
        "city": "Gandharbbapur"
      },
      {
        "state": "West Bengal",
        "city": "Gangadhar Chak"
      },
      {
        "state": "West Bengal",
        "city": "Gangajalghati"
      },
      {
        "state": "West Bengal",
        "city": "Gangarampur"
      },
      {
        "state": "West Bengal",
        "city": "Gangatikuri"
      },
      {
        "state": "West Bengal",
        "city": "Ganrapota"
      },
      {
        "state": "West Bengal",
        "city": "Ganti"
      },
      {
        "state": "West Bengal",
        "city": "Garakupi"
      },
      {
        "state": "West Bengal",
        "city": "Garhbeta"
      },
      {
        "state": "West Bengal",
        "city": "Garulia"
      },
      {
        "state": "West Bengal",
        "city": "Gazipur Kismat"
      },
      {
        "state": "West Bengal",
        "city": "Gazole"
      },
      {
        "state": "West Bengal",
        "city": "Ghat Patila"
      },
      {
        "state": "West Bengal",
        "city": "Ghatal"
      },
      {
        "state": "West Bengal",
        "city": "Ghatihara"
      },
      {
        "state": "West Bengal",
        "city": "Ghona"
      },
      {
        "state": "West Bengal",
        "city": "Ghoom"
      },
      {
        "state": "West Bengal",
        "city": "Ghoraghata"
      },
      {
        "state": "West Bengal",
        "city": "Giria"
      },
      {
        "state": "West Bengal",
        "city": "Goal Bathan"
      },
      {
        "state": "West Bengal",
        "city": "Goaldaha"
      },
      {
        "state": "West Bengal",
        "city": "Goalpokhar"
      },
      {
        "state": "West Bengal",
        "city": "Goaltore"
      },
      {
        "state": "West Bengal",
        "city": "Gobag"
      },
      {
        "state": "West Bengal",
        "city": "Gobardanga"
      },
      {
        "state": "West Bengal",
        "city": "Gobardhanpur"
      },
      {
        "state": "West Bengal",
        "city": "Gobila"
      },
      {
        "state": "West Bengal",
        "city": "Gobindapur"
      },
      {
        "state": "West Bengal",
        "city": "Gobrapur"
      },
      {
        "state": "West Bengal",
        "city": "Goghat"
      },
      {
        "state": "West Bengal",
        "city": "Gograskottaluk"
      },
      {
        "state": "West Bengal",
        "city": "Gokna"
      },
      {
        "state": "West Bengal",
        "city": "Golgram"
      },
      {
        "state": "West Bengal",
        "city": "Gomunda"
      },
      {
        "state": "West Bengal",
        "city": "Gopal Chak"
      },
      {
        "state": "West Bengal",
        "city": "Gopalnagar"
      },
      {
        "state": "West Bengal",
        "city": "Gopi Chak"
      },
      {
        "state": "West Bengal",
        "city": "Gopiballabpur"
      },
      {
        "state": "West Bengal",
        "city": "Gorubathan"
      },
      {
        "state": "West Bengal",
        "city": "Gosaba"
      },
      {
        "state": "West Bengal",
        "city": "Guptipara"
      },
      {
        "state": "West Bengal",
        "city": "Gurap"
      },
      {
        "state": "West Bengal",
        "city": "Gushkara"
      },
      {
        "state": "West Bengal",
        "city": "Guskhara"
      },
      {
        "state": "West Bengal",
        "city": "Habra"
      },
      {
        "state": "West Bengal",
        "city": "Haldia"
      },
      {
        "state": "West Bengal",
        "city": "Haldia Municipality"
      },
      {
        "state": "West Bengal",
        "city": "Haldibari"
      },
      {
        "state": "West Bengal",
        "city": "Haludbari"
      },
      {
        "state": "West Bengal",
        "city": "Hamiltonganj"
      },
      {
        "state": "West Bengal",
        "city": "Handla"
      },
      {
        "state": "West Bengal",
        "city": "Hansghara"
      },
      {
        "state": "West Bengal",
        "city": "Hanskhali"
      },
      {
        "state": "West Bengal",
        "city": "Haraipur"
      },
      {
        "state": "West Bengal",
        "city": "Hardam Nagar"
      },
      {
        "state": "West Bengal",
        "city": "Hariharpara"
      },
      {
        "state": "West Bengal",
        "city": "Haringhata"
      },
      {
        "state": "West Bengal",
        "city": "Haringhata Farm"
      },
      {
        "state": "West Bengal",
        "city": "Haripal"
      },
      {
        "state": "West Bengal",
        "city": "Harishchandrapur"
      },
      {
        "state": "West Bengal",
        "city": "Haroa"
      },
      {
        "state": "West Bengal",
        "city": "Harranamaldina"
      },
      {
        "state": "West Bengal",
        "city": "Hasnabad"
      },
      {
        "state": "West Bengal",
        "city": "Hatbaincha"
      },
      {
        "state": "West Bengal",
        "city": "Hatgachha"
      },
      {
        "state": "West Bengal",
        "city": "Hatiary"
      },
      {
        "state": "West Bengal",
        "city": "Hatinda"
      },
      {
        "state": "West Bengal",
        "city": "Hatisala"
      },
      {
        "state": "West Bengal",
        "city": "Helencha"
      },
      {
        "state": "West Bengal",
        "city": "Hemnagar"
      },
      {
        "state": "West Bengal",
        "city": "Hemtabad"
      },
      {
        "state": "West Bengal",
        "city": "Hili"
      },
      {
        "state": "West Bengal",
        "city": "Hingalganj"
      },
      {
        "state": "West Bengal",
        "city": "Hingli"
      },
      {
        "state": "West Bengal",
        "city": "Hir Bandh"
      },
      {
        "state": "West Bengal",
        "city": "Hirbandh"
      },
      {
        "state": "West Bengal",
        "city": "Hisabi"
      },
      {
        "state": "West Bengal",
        "city": "Hooghly"
      },
      {
        "state": "West Bengal",
        "city": "Hoomgarh"
      },
      {
        "state": "West Bengal",
        "city": "Howrah"
      },
      {
        "state": "West Bengal",
        "city": "Humbirpara"
      },
      {
        "state": "West Bengal",
        "city": "Hura"
      },
      {
        "state": "West Bengal",
        "city": "Hutmura"
      },
      {
        "state": "West Bengal",
        "city": "Ichapur"
      },
      {
        "state": "West Bengal",
        "city": "Ichhabacha"
      },
      {
        "state": "West Bengal",
        "city": "Ichhlampur"
      },
      {
        "state": "West Bengal",
        "city": "Indas"
      },
      {
        "state": "West Bengal",
        "city": "Indpur"
      },
      {
        "state": "West Bengal",
        "city": "Ishwarigachha"
      },
      {
        "state": "West Bengal",
        "city": "Iswarbati"
      },
      {
        "state": "West Bengal",
        "city": "Itaberia"
      },
      {
        "state": "West Bengal",
        "city": "Itachuna"
      },
      {
        "state": "West Bengal",
        "city": "Itahar"
      },
      {
        "state": "West Bengal",
        "city": "Itinda"
      },
      {
        "state": "West Bengal",
        "city": "Jadupur"
      },
      {
        "state": "West Bengal",
        "city": "Jadurhati"
      },
      {
        "state": "West Bengal",
        "city": "Jagadishpur"
      },
      {
        "state": "West Bengal",
        "city": "Jagannathpur"
      },
      {
        "state": "West Bengal",
        "city": "Jagatballavpur"
      },
      {
        "state": "West Bengal",
        "city": "Jagatnagar"
      },
      {
        "state": "West Bengal",
        "city": "Jahalda"
      },
      {
        "state": "West Bengal",
        "city": "Jalabaria"
      },
      {
        "state": "West Bengal",
        "city": "Jalangi"
      },
      {
        "state": "West Bengal",
        "city": "Jaleshwar"
      },
      {
        "state": "West Bengal",
        "city": "Jalpaiguri"
      },
      {
        "state": "West Bengal",
        "city": "Jamboni"
      },
      {
        "state": "West Bengal",
        "city": "Jamuria"
      },
      {
        "state": "West Bengal",
        "city": "Janaphul"
      },
      {
        "state": "West Bengal",
        "city": "Jangalpur"
      },
      {
        "state": "West Bengal",
        "city": "Jangipara"
      },
      {
        "state": "West Bengal",
        "city": "Jangipur"
      },
      {
        "state": "West Bengal",
        "city": "Jashaikati"
      },
      {
        "state": "West Bengal",
        "city": "Jaynagar"
      },
      {
        "state": "West Bengal",
        "city": "Jelepara"
      },
      {
        "state": "West Bengal",
        "city": "Jerthan"
      },
      {
        "state": "West Bengal",
        "city": "Jhalida"
      },
      {
        "state": "West Bengal",
        "city": "Jhanjia Nankar"
      },
      {
        "state": "West Bengal",
        "city": "Jhargram"
      },
      {
        "state": "West Bengal",
        "city": "Jhawdanga"
      },
      {
        "state": "West Bengal",
        "city": "Jhikra"
      },
      {
        "state": "West Bengal",
        "city": "Jhowdanga"
      },
      {
        "state": "West Bengal",
        "city": "Jiaganj"
      },
      {
        "state": "West Bengal",
        "city": "Jiakhali"
      },
      {
        "state": "West Bengal",
        "city": "Jiudaru"
      },
      {
        "state": "West Bengal",
        "city": "Jogesganj"
      },
      {
        "state": "West Bengal",
        "city": "Joygopalpur"
      },
      {
        "state": "West Bengal",
        "city": "Joypul"
      },
      {
        "state": "West Bengal",
        "city": "Joypur"
      },
      {
        "state": "West Bengal",
        "city": "Juki"
      },
      {
        "state": "West Bengal",
        "city": "Kachua Swarupnagar"
      },
      {
        "state": "West Bengal",
        "city": "Kadambagachi"
      },
      {
        "state": "West Bengal",
        "city": "Kadampukur"
      },
      {
        "state": "West Bengal",
        "city": "Kadamtala"
      },
      {
        "state": "West Bengal",
        "city": "Kahankia"
      },
      {
        "state": "West Bengal",
        "city": "Kaijuri"
      },
      {
        "state": "West Bengal",
        "city": "Kaipukharia"
      },
      {
        "state": "West Bengal",
        "city": "Kaipukuria"
      },
      {
        "state": "West Bengal",
        "city": "Kakdwip"
      },
      {
        "state": "West Bengal",
        "city": "Kakrajit"
      },
      {
        "state": "West Bengal",
        "city": "Kalapathar"
      },
      {
        "state": "West Bengal",
        "city": "Kalchini"
      },
      {
        "state": "West Bengal",
        "city": "Kalchini Tea Garden"
      },
      {
        "state": "West Bengal",
        "city": "Kaliachak"
      },
      {
        "state": "West Bengal",
        "city": "Kaliaganj"
      },
      {
        "state": "West Bengal",
        "city": "Kaliani"
      },
      {
        "state": "West Bengal",
        "city": "Kalimpong"
      },
      {
        "state": "West Bengal",
        "city": "Kalinagar"
      },
      {
        "state": "West Bengal",
        "city": "Kaliyaganj"
      },
      {
        "state": "West Bengal",
        "city": "Kalma Pukhuria"
      },
      {
        "state": "West Bengal",
        "city": "Kalna"
      },
      {
        "state": "West Bengal",
        "city": "Kalyani"
      },
      {
        "state": "West Bengal",
        "city": "Kamarda"
      },
      {
        "state": "West Bengal",
        "city": "Kamdebpur"
      },
      {
        "state": "West Bengal",
        "city": "Kamduni"
      },
      {
        "state": "West Bengal",
        "city": "Kanaidighi"
      },
      {
        "state": "West Bengal",
        "city": "Kanchrapara"
      },
      {
        "state": "West Bengal",
        "city": "Kanda Gram"
      },
      {
        "state": "West Bengal",
        "city": "Kanksa"
      },
      {
        "state": "West Bengal",
        "city": "Kanmari"
      },
      {
        "state": "West Bengal",
        "city": "Kantai"
      },
      {
        "state": "West Bengal",
        "city": "Kanthalia"
      },
      {
        "state": "West Bengal",
        "city": "Kanturka"
      },
      {
        "state": "West Bengal",
        "city": "Kapileshwarpur"
      },
      {
        "state": "West Bengal",
        "city": "Karandighi"
      },
      {
        "state": "West Bengal",
        "city": "Karimpur 2"
      },
      {
        "state": "West Bengal",
        "city": "Kartik Khali"
      },
      {
        "state": "West Bengal",
        "city": "Kasaria"
      },
      {
        "state": "West Bengal",
        "city": "Kasba Narayangarh"
      },
      {
        "state": "West Bengal",
        "city": "Kasbagoas"
      },
      {
        "state": "West Bengal",
        "city": "Kasbagola"
      },
      {
        "state": "West Bengal",
        "city": "Kashimpur"
      },
      {
        "state": "West Bengal",
        "city": "Kashinathpur"
      },
      {
        "state": "West Bengal",
        "city": "Kasinathpur"
      },
      {
        "state": "West Bengal",
        "city": "Katiahat"
      },
      {
        "state": "West Bengal",
        "city": "Katkadebi Chak"
      },
      {
        "state": "West Bengal",
        "city": "Katranka"
      },
      {
        "state": "West Bengal",
        "city": "Katwa"
      },
      {
        "state": "West Bengal",
        "city": "Katwa - I"
      },
      {
        "state": "West Bengal",
        "city": "Kayemgeria"
      },
      {
        "state": "West Bengal",
        "city": "Kekai"
      },
      {
        "state": "West Bengal",
        "city": "Kenda"
      },
      {
        "state": "West Bengal",
        "city": "Keshiary"
      },
      {
        "state": "West Bengal",
        "city": "Keshpur"
      },
      {
        "state": "West Bengal",
        "city": "Keshrambha"
      },
      {
        "state": "West Bengal",
        "city": "Ketugram"
      },
      {
        "state": "West Bengal",
        "city": "Khagda"
      },
      {
        "state": "West Bengal",
        "city": "Khakurda"
      },
      {
        "state": "West Bengal",
        "city": "Khalina"
      },
      {
        "state": "West Bengal",
        "city": "Khalisabhanga"
      },
      {
        "state": "West Bengal",
        "city": "Khalisadi"
      },
      {
        "state": "West Bengal",
        "city": "Khalitpur"
      },
      {
        "state": "West Bengal",
        "city": "Khamargachi"
      },
      {
        "state": "West Bengal",
        "city": "Khamarpara"
      },
      {
        "state": "West Bengal",
        "city": "Khanakul"
      },
      {
        "state": "West Bengal",
        "city": "Khanati"
      },
      {
        "state": "West Bengal",
        "city": "Khandaghosh"
      },
      {
        "state": "West Bengal",
        "city": "Khanpur Hubaspur 165"
      },
      {
        "state": "West Bengal",
        "city": "Kharagpur"
      },
      {
        "state": "West Bengal",
        "city": "Kharampur"
      },
      {
        "state": "West Bengal",
        "city": "Kharba"
      },
      {
        "state": "West Bengal",
        "city": "Khargram"
      },
      {
        "state": "West Bengal",
        "city": "Kharija Durganagar Dwitia Khanda"
      },
      {
        "state": "West Bengal",
        "city": "Kharikamathani"
      },
      {
        "state": "West Bengal",
        "city": "Kharua Rajapur"
      },
      {
        "state": "West Bengal",
        "city": "Kharupala"
      },
      {
        "state": "West Bengal",
        "city": "Khas Balandar"
      },
      {
        "state": "West Bengal",
        "city": "Khejurda"
      },
      {
        "state": "West Bengal",
        "city": "Khilkapur"
      },
      {
        "state": "West Bengal",
        "city": "Khirpai"
      },
      {
        "state": "West Bengal",
        "city": "Khodambari"
      },
      {
        "state": "West Bengal",
        "city": "Kholapota"
      },
      {
        "state": "West Bengal",
        "city": "Khoribari"
      },
      {
        "state": "West Bengal",
        "city": "Khulna"
      },
      {
        "state": "West Bengal",
        "city": "Khurutia"
      },
      {
        "state": "West Bengal",
        "city": "Kirtankhola"
      },
      {
        "state": "West Bengal",
        "city": "Kismat Bajkul"
      },
      {
        "state": "West Bengal",
        "city": "Kolanda"
      },
      {
        "state": "West Bengal",
        "city": "Kolkata"
      },
      {
        "state": "West Bengal",
        "city": "Konarpur"
      },
      {
        "state": "West Bengal",
        "city": "Kontai Jinagerya"
      },
      {
        "state": "West Bengal",
        "city": "Korakati"
      },
      {
        "state": "West Bengal",
        "city": "Kothabbari"
      },
      {
        "state": "West Bengal",
        "city": "Kotpada"
      },
      {
        "state": "West Bengal",
        "city": "Kotshila"
      },
      {
        "state": "West Bengal",
        "city": "Kotulpur"
      },
      {
        "state": "West Bengal",
        "city": "Krishnachandrapur"
      },
      {
        "state": "West Bengal",
        "city": "Krishnaganj"
      },
      {
        "state": "West Bengal",
        "city": "Krishnanagar"
      },
      {
        "state": "West Bengal",
        "city": "Krishnapur Madanpur"
      },
      {
        "state": "West Bengal",
        "city": "Kshirai"
      },
      {
        "state": "West Bengal",
        "city": "Kuili Suta"
      },
      {
        "state": "West Bengal",
        "city": "Kulanjara"
      },
      {
        "state": "West Bengal",
        "city": "Kuldiha"
      },
      {
        "state": "West Bengal",
        "city": "Kulpi"
      },
      {
        "state": "West Bengal",
        "city": "Kultikri"
      },
      {
        "state": "West Bengal",
        "city": "Kumarda"
      },
      {
        "state": "West Bengal",
        "city": "Kumarganj"
      },
      {
        "state": "West Bengal",
        "city": "Kumargram"
      },
      {
        "state": "West Bengal",
        "city": "Kumarkhola"
      },
      {
        "state": "West Bengal",
        "city": "Kumirda"
      },
      {
        "state": "West Bengal",
        "city": "Kundipur"
      },
      {
        "state": "West Bengal",
        "city": "Kunjapur"
      },
      {
        "state": "West Bengal",
        "city": "Kurseong"
      },
      {
        "state": "West Bengal",
        "city": "Kush Bashan"
      },
      {
        "state": "West Bengal",
        "city": "Kushmandi"
      },
      {
        "state": "West Bengal",
        "city": "Kusidha"
      },
      {
        "state": "West Bengal",
        "city": "Kusumgram"
      },
      {
        "state": "West Bengal",
        "city": "Labpur"
      },
      {
        "state": "West Bengal",
        "city": "Labukhali"
      },
      {
        "state": "West Bengal",
        "city": "Lakshan Chak"
      },
      {
        "state": "West Bengal",
        "city": "Lakshipur"
      },
      {
        "state": "West Bengal",
        "city": "Lalat"
      },
      {
        "state": "West Bengal",
        "city": "Lalgola"
      },
      {
        "state": "West Bengal",
        "city": "Lalkunri"
      },
      {
        "state": "West Bengal",
        "city": "Lalua"
      },
      {
        "state": "West Bengal",
        "city": "Laudaha"
      },
      {
        "state": "West Bengal",
        "city": "Laudoha"
      },
      {
        "state": "West Bengal",
        "city": "Lebukhali"
      },
      {
        "state": "West Bengal",
        "city": "Lenga Mara"
      },
      {
        "state": "West Bengal",
        "city": "Loha Baria"
      },
      {
        "state": "West Bengal",
        "city": "Lohabaran Chak"
      },
      {
        "state": "West Bengal",
        "city": "Lot No 126"
      },
      {
        "state": "West Bengal",
        "city": "Machibhanga"
      },
      {
        "state": "West Bengal",
        "city": "Madanmohanpur"
      },
      {
        "state": "West Bengal",
        "city": "Madhabpur"
      },
      {
        "state": "West Bengal",
        "city": "Madhubati"
      },
      {
        "state": "West Bengal",
        "city": "Madhya Madarihat"
      },
      {
        "state": "West Bengal",
        "city": "Magrahat - II"
      },
      {
        "state": "West Bengal",
        "city": "Magurali"
      },
      {
        "state": "West Bengal",
        "city": "Magurati Shrirampur"
      },
      {
        "state": "West Bengal",
        "city": "Mahatpur"
      },
      {
        "state": "West Bengal",
        "city": "Maheshtala"
      },
      {
        "state": "West Bengal",
        "city": "Mahespur"
      },
      {
        "state": "West Bengal",
        "city": "Mahisadal"
      },
      {
        "state": "West Bengal",
        "city": "Mahisha Machhlandapur"
      },
      {
        "state": "West Bengal",
        "city": "Mahishadal"
      },
      {
        "state": "West Bengal",
        "city": "Maisali"
      },
      {
        "state": "West Bengal",
        "city": "Makdumnagar"
      },
      {
        "state": "West Bengal",
        "city": "Makhalgacha"
      },
      {
        "state": "West Bengal",
        "city": "Makrampur"
      },
      {
        "state": "West Bengal",
        "city": "Mal Bazar"
      },
      {
        "state": "West Bengal",
        "city": "Mal Forest"
      },
      {
        "state": "West Bengal",
        "city": "Malancha"
      },
      {
        "state": "West Bengal",
        "city": "Malbari"
      },
      {
        "state": "West Bengal",
        "city": "Malda"
      },
      {
        "state": "West Bengal",
        "city": "Malekanghumti"
      },
      {
        "state": "West Bengal",
        "city": "Malidah"
      },
      {
        "state": "West Bengal",
        "city": "Malikapur"
      },
      {
        "state": "West Bengal",
        "city": "Malior"
      },
      {
        "state": "West Bengal",
        "city": "Maljamuna"
      },
      {
        "state": "West Bengal",
        "city": "Mallikpur"
      },
      {
        "state": "West Bengal",
        "city": "Malpara"
      },
      {
        "state": "West Bengal",
        "city": "Manbazar"
      },
      {
        "state": "West Bengal",
        "city": "Mangalkot"
      },
      {
        "state": "West Bengal",
        "city": "Manglamaro"
      },
      {
        "state": "West Bengal",
        "city": "Manguria"
      },
      {
        "state": "West Bengal",
        "city": "Manihara"
      },
      {
        "state": "West Bengal",
        "city": "Manikabasan"
      },
      {
        "state": "West Bengal",
        "city": "Manikchak"
      },
      {
        "state": "West Bengal",
        "city": "Manikjore"
      },
      {
        "state": "West Bengal",
        "city": "Manteswar"
      },
      {
        "state": "West Bengal",
        "city": "Marishda"
      },
      {
        "state": "West Bengal",
        "city": "Matbari Abad"
      },
      {
        "state": "West Bengal",
        "city": "Matelli"
      },
      {
        "state": "West Bengal",
        "city": "Mathabari"
      },
      {
        "state": "West Bengal",
        "city": "Mathabhanga"
      },
      {
        "state": "West Bengal",
        "city": "Mathurapur"
      },
      {
        "state": "West Bengal",
        "city": "Matiar Kuthi"
      },
      {
        "state": "West Bengal",
        "city": "Matigara"
      },
      {
        "state": "West Bengal",
        "city": "Mayna"
      },
      {
        "state": "West Bengal",
        "city": "Maynaguri"
      },
      {
        "state": "West Bengal",
        "city": "Mayureswar"
      },
      {
        "state": "West Bengal",
        "city": "Medinipur"
      },
      {
        "state": "West Bengal",
        "city": "Mejia"
      },
      {
        "state": "West Bengal",
        "city": "Mekhliganj"
      },
      {
        "state": "West Bengal",
        "city": "Memari"
      },
      {
        "state": "West Bengal",
        "city": "Merudandi"
      },
      {
        "state": "West Bengal",
        "city": "Minakhan"
      },
      {
        "state": "West Bengal",
        "city": "Mirgoda"
      },
      {
        "state": "West Bengal",
        "city": "Mirhati"
      },
      {
        "state": "West Bengal",
        "city": "Mirik"
      },
      {
        "state": "West Bengal",
        "city": "Mohati"
      },
      {
        "state": "West Bengal",
        "city": "Mosat"
      },
      {
        "state": "West Bengal",
        "city": "Mugberia"
      },
      {
        "state": "West Bengal",
        "city": "Murabani"
      },
      {
        "state": "West Bengal",
        "city": "Murarai"
      },
      {
        "state": "West Bengal",
        "city": "Murardih"
      },
      {
        "state": "West Bengal",
        "city": "Murarisah"
      },
      {
        "state": "West Bengal",
        "city": "Murshidabad"
      },
      {
        "state": "West Bengal",
        "city": "Murulahar"
      },
      {
        "state": "West Bengal",
        "city": "Nabadwip"
      },
      {
        "state": "West Bengal",
        "city": "Nabagram"
      },
      {
        "state": "West Bengal",
        "city": "Nachhipur"
      },
      {
        "state": "West Bengal",
        "city": "Nachinda"
      },
      {
        "state": "West Bengal",
        "city": "Nadia"
      },
      {
        "state": "West Bengal",
        "city": "Nagrakata"
      },
      {
        "state": "West Bengal",
        "city": "Nagri Pada"
      },
      {
        "state": "West Bengal",
        "city": "Naihati"
      },
      {
        "state": "West Bengal",
        "city": "Nakashipara"
      },
      {
        "state": "West Bengal",
        "city": "Nakpul"
      },
      {
        "state": "West Bengal",
        "city": "Naksa"
      },
      {
        "state": "West Bengal",
        "city": "Nalabara"
      },
      {
        "state": "West Bengal",
        "city": "Nalhati"
      },
      {
        "state": "West Bengal",
        "city": "Nalkora"
      },
      {
        "state": "West Bengal",
        "city": "Namkhana"
      },
      {
        "state": "West Bengal",
        "city": "Nandakumar"
      },
      {
        "state": "West Bengal",
        "city": "Nandigram"
      },
      {
        "state": "West Bengal",
        "city": "Nanoor"
      },
      {
        "state": "West Bengal",
        "city": "Narandia"
      },
      {
        "state": "West Bengal",
        "city": "Narayanchak"
      },
      {
        "state": "West Bengal",
        "city": "Naskarpur"
      },
      {
        "state": "West Bengal",
        "city": "Nata Talya"
      },
      {
        "state": "West Bengal",
        "city": "Natabaria"
      },
      {
        "state": "West Bengal",
        "city": "Natagram"
      },
      {
        "state": "West Bengal",
        "city": "Naxalbari"
      },
      {
        "state": "West Bengal",
        "city": "Nayagram"
      },
      {
        "state": "West Bengal",
        "city": "Nayaput"
      },
      {
        "state": "West Bengal",
        "city": "Nazat"
      },
      {
        "state": "West Bengal",
        "city": "Nebadhai Duttapukur"
      },
      {
        "state": "West Bengal",
        "city": "Nehalpur"
      },
      {
        "state": "West Bengal",
        "city": "Nekrasole"
      },
      {
        "state": "West Bengal",
        "city": "Neturia"
      },
      {
        "state": "West Bengal",
        "city": "New Town"
      },
      {
        "state": "West Bengal",
        "city": "Nikursini"
      },
      {
        "state": "West Bengal",
        "city": "Nilda"
      },
      {
        "state": "West Bengal",
        "city": "Nimchi"
      },
      {
        "state": "West Bengal",
        "city": "Nimdaria"
      },
      {
        "state": "West Bengal",
        "city": "Nimna Tandu Forest"
      },
      {
        "state": "West Bengal",
        "city": "Nimpith"
      },
      {
        "state": "West Bengal",
        "city": "Nischintapur"
      },
      {
        "state": "West Bengal",
        "city": "Nityananda Kati"
      },
      {
        "state": "West Bengal",
        "city": "Nodakhali"
      },
      {
        "state": "West Bengal",
        "city": "North 24 Parganas"
      },
      {
        "state": "West Bengal",
        "city": "Nowda"
      },
      {
        "state": "West Bengal",
        "city": "Old Malda"
      },
      {
        "state": "West Bengal",
        "city": "Onda"
      },
      {
        "state": "West Bengal",
        "city": "Pailachhanpur"
      },
      {
        "state": "West Bengal",
        "city": "Palashipara"
      },
      {
        "state": "West Bengal",
        "city": "Paldhui"
      },
      {
        "state": "West Bengal",
        "city": "Palpara"
      },
      {
        "state": "West Bengal",
        "city": "Palsanda"
      },
      {
        "state": "West Bengal",
        "city": "Palsandapur"
      },
      {
        "state": "West Bengal",
        "city": "Palta Beria"
      },
      {
        "state": "West Bengal",
        "city": "Panapukur"
      },
      {
        "state": "West Bengal",
        "city": "Panchanandapur"
      },
      {
        "state": "West Bengal",
        "city": "Panchberia"
      },
      {
        "state": "West Bengal",
        "city": "Panchet"
      },
      {
        "state": "West Bengal",
        "city": "Panchita"
      },
      {
        "state": "West Bengal",
        "city": "Panchla"
      },
      {
        "state": "West Bengal",
        "city": "Panchpota"
      },
      {
        "state": "West Bengal",
        "city": "Panchrol"
      },
      {
        "state": "West Bengal",
        "city": "Panchuria"
      },
      {
        "state": "West Bengal",
        "city": "Pandaveshwar"
      },
      {
        "state": "West Bengal",
        "city": "Pandua"
      },
      {
        "state": "West Bengal",
        "city": "Panitar"
      },
      {
        "state": "West Bengal",
        "city": "Pankhai"
      },
      {
        "state": "West Bengal",
        "city": "Panskura"
      },
      {
        "state": "West Bengal",
        "city": "Parghumti"
      },
      {
        "state": "West Bengal",
        "city": "Parpatna"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Madhyampur"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Manikpur"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Medinipur (West Medinipur)"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Memari"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Narayanpur"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Punropara"
      },
      {
        "state": "West Bengal",
        "city": "Paschim Sarpai"
      },
      {
        "state": "West Bengal",
        "city": "Pashchim Narayanpur"
      },
      {
        "state": "West Bengal",
        "city": "Patashpur"
      },
      {
        "state": "West Bengal",
        "city": "Pathar Pratima"
      },
      {
        "state": "West Bengal",
        "city": "Patharghata"
      },
      {
        "state": "West Bengal",
        "city": "Patrasayer"
      },
      {
        "state": "West Bengal",
        "city": "Patshimulia"
      },
      {
        "state": "West Bengal",
        "city": "Payradanga"
      },
      {
        "state": "West Bengal",
        "city": "Payragachhi"
      },
      {
        "state": "West Bengal",
        "city": "Petrapole"
      },
      {
        "state": "West Bengal",
        "city": "Phandar"
      },
      {
        "state": "West Bengal",
        "city": "Phultala"
      },
      {
        "state": "West Bengal",
        "city": "Pingla"
      },
      {
        "state": "West Bengal",
        "city": "Pipha"
      },
      {
        "state": "West Bengal",
        "city": "Pipla Kasimpur"
      },
      {
        "state": "West Bengal",
        "city": "Pirolgari More"
      },
      {
        "state": "West Bengal",
        "city": "Pitha Pukuria"
      },
      {
        "state": "West Bengal",
        "city": "Plashipara"
      },
      {
        "state": "West Bengal",
        "city": "Plassey"
      },
      {
        "state": "West Bengal",
        "city": "Pratap Dighi"
      },
      {
        "state": "West Bengal",
        "city": "Prithiba"
      },
      {
        "state": "West Bengal",
        "city": "Puinan"
      },
      {
        "state": "West Bengal",
        "city": "Pukhuria"
      },
      {
        "state": "West Bengal",
        "city": "Pulbazar"
      },
      {
        "state": "West Bengal",
        "city": "Puncha"
      },
      {
        "state": "West Bengal",
        "city": "Pundag"
      },
      {
        "state": "West Bengal",
        "city": "Pundibari"
      },
      {
        "state": "West Bengal",
        "city": "Purandarpur"
      },
      {
        "state": "West Bengal",
        "city": "Purba Medinipur (East Medinipur)"
      },
      {
        "state": "West Bengal",
        "city": "Purbasthali"
      },
      {
        "state": "West Bengal",
        "city": "Purbba Chak"
      },
      {
        "state": "West Bengal",
        "city": "Purbba Khejurbaria"
      },
      {
        "state": "West Bengal",
        "city": "Purbba Radhapur"
      },
      {
        "state": "West Bengal",
        "city": "Purulia"
      },
      {
        "state": "West Bengal",
        "city": "Purunda"
      },
      {
        "state": "West Bengal",
        "city": "Purusattampur"
      },
      {
        "state": "West Bengal",
        "city": "Putia"
      },
      {
        "state": "West Bengal",
        "city": "Putiamathbari"
      },
      {
        "state": "West Bengal",
        "city": "Raghabpur"
      },
      {
        "state": "West Bengal",
        "city": "Raghunathganj"
      },
      {
        "state": "West Bengal",
        "city": "Raidighi"
      },
      {
        "state": "West Bengal",
        "city": "Raiganj"
      },
      {
        "state": "West Bengal",
        "city": "Raikhan"
      },
      {
        "state": "West Bengal",
        "city": "Raipur Bazar"
      },
      {
        "state": "West Bengal",
        "city": "Rajarhat"
      },
      {
        "state": "West Bengal",
        "city": "Rajendrapur"
      },
      {
        "state": "West Bengal",
        "city": "Rajibpur"
      },
      {
        "state": "West Bengal",
        "city": "Rajibpur Bira"
      },
      {
        "state": "West Bengal",
        "city": "Rajnagar"
      },
      {
        "state": "West Bengal",
        "city": "Rajpur Sonarpur"
      },
      {
        "state": "West Bengal",
        "city": "Ram Chak"
      },
      {
        "state": "West Bengal",
        "city": "Rameswarpur"
      },
      {
        "state": "West Bengal",
        "city": "Ramkanali"
      },
      {
        "state": "West Bengal",
        "city": "Ramkrishnapur"
      },
      {
        "state": "West Bengal",
        "city": "Ramnathpur"
      },
      {
        "state": "West Bengal",
        "city": "Ramnathpur K"
      },
      {
        "state": "West Bengal",
        "city": "Rampurhat"
      },
      {
        "state": "West Bengal",
        "city": "Ramshankarpur"
      },
      {
        "state": "West Bengal",
        "city": "Ranaghat"
      },
      {
        "state": "West Bengal",
        "city": "Rangabhita"
      },
      {
        "state": "West Bengal",
        "city": "Rangli Rangliot"
      },
      {
        "state": "West Bengal",
        "city": "Ranibandh"
      },
      {
        "state": "West Bengal",
        "city": "Raniganj"
      },
      {
        "state": "West Bengal",
        "city": "Ranihati"
      },
      {
        "state": "West Bengal",
        "city": "Raninagar"
      },
      {
        "state": "West Bengal",
        "city": "Ranisai"
      },
      {
        "state": "West Bengal",
        "city": "Ratua"
      },
      {
        "state": "West Bengal",
        "city": "Rautara"
      },
      {
        "state": "West Bengal",
        "city": "Rautarapur"
      },
      {
        "state": "West Bengal",
        "city": "Rayna"
      },
      {
        "state": "West Bengal",
        "city": "Raynagar"
      },
      {
        "state": "West Bengal",
        "city": "Renjura"
      },
      {
        "state": "West Bengal",
        "city": "Rukushpur"
      },
      {
        "state": "West Bengal",
        "city": "Rupmari"
      },
      {
        "state": "West Bengal",
        "city": "Sabang"
      },
      {
        "state": "West Bengal",
        "city": "Sagardighi"
      },
      {
        "state": "West Bengal",
        "city": "Sahajalalpur"
      },
      {
        "state": "West Bengal",
        "city": "Sahebkhali"
      },
      {
        "state": "West Bengal",
        "city": "Sahebpur"
      },
      {
        "state": "West Bengal",
        "city": "Sainthia"
      },
      {
        "state": "West Bengal",
        "city": "Salanpur"
      },
      {
        "state": "West Bengal",
        "city": "Salboni"
      },
      {
        "state": "West Bengal",
        "city": "Saltora"
      },
      {
        "state": "West Bengal",
        "city": "Salua"
      },
      {
        "state": "West Bengal",
        "city": "Saluka"
      },
      {
        "state": "West Bengal",
        "city": "Samudrapur"
      },
      {
        "state": "West Bengal",
        "city": "Sandelerbil"
      },
      {
        "state": "West Bengal",
        "city": "Sandeshkhali"
      },
      {
        "state": "West Bengal",
        "city": "Sankardaha Abad"
      },
      {
        "state": "West Bengal",
        "city": "Santaldih"
      },
      {
        "state": "West Bengal",
        "city": "Santipur"
      },
      {
        "state": "West Bengal",
        "city": "Santra Pur"
      },
      {
        "state": "West Bengal",
        "city": "Santuri"
      },
      {
        "state": "West Bengal",
        "city": "Saoradi"
      },
      {
        "state": "West Bengal",
        "city": "Sarenga"
      },
      {
        "state": "West Bengal",
        "city": "Saridaspur"
      },
      {
        "state": "West Bengal",
        "city": "Sarisha"
      },
      {
        "state": "West Bengal",
        "city": "Sarishaberya"
      },
      {
        "state": "West Bengal",
        "city": "Sarpai"
      },
      {
        "state": "West Bengal",
        "city": "Sartla"
      },
      {
        "state": "West Bengal",
        "city": "Sat Bhaiya"
      },
      {
        "state": "West Bengal",
        "city": "Sat Khanda Saheb Nagar"
      },
      {
        "state": "West Bengal",
        "city": "Satashi"
      },
      {
        "state": "West Bengal",
        "city": "Satbahini"
      },
      {
        "state": "West Bengal",
        "city": "Satikeshwar"
      },
      {
        "state": "West Bengal",
        "city": "Satilapur"
      },
      {
        "state": "West Bengal",
        "city": "Satmile"
      },
      {
        "state": "West Bengal",
        "city": "Sautia"
      },
      {
        "state": "West Bengal",
        "city": "Sayestanagar"
      },
      {
        "state": "West Bengal",
        "city": "Selmabad"
      },
      {
        "state": "West Bengal",
        "city": "Sendanga"
      },
      {
        "state": "West Bengal",
        "city": "Senhat"
      },
      {
        "state": "West Bengal",
        "city": "Serampore"
      },
      {
        "state": "West Bengal",
        "city": "Serampur Uttarpara"
      },
      {
        "state": "West Bengal",
        "city": "Serkhan Chak"
      },
      {
        "state": "West Bengal",
        "city": "Shalikota"
      },
      {
        "state": "West Bengal",
        "city": "Shaljora"
      },
      {
        "state": "West Bengal",
        "city": "Shasan"
      },
      {
        "state": "West Bengal",
        "city": "Shibhati"
      },
      {
        "state": "West Bengal",
        "city": "Shimulia"
      },
      {
        "state": "West Bengal",
        "city": "Shimulia Bar"
      },
      {
        "state": "West Bengal",
        "city": "Shipur"
      },
      {
        "state": "West Bengal",
        "city": "Sholka Durgapur"
      },
      {
        "state": "West Bengal",
        "city": "Shridhar Kati"
      },
      {
        "state": "West Bengal",
        "city": "Shushunia"
      },
      {
        "state": "West Bengal",
        "city": "Shutia"
      },
      {
        "state": "West Bengal",
        "city": "Sikharpur"
      },
      {
        "state": "West Bengal",
        "city": "Siliguri"
      },
      {
        "state": "West Bengal",
        "city": "Simlapal"
      },
      {
        "state": "West Bengal",
        "city": "Simulia"
      },
      {
        "state": "West Bengal",
        "city": "Simulpur"
      },
      {
        "state": "West Bengal",
        "city": "Sindrani"
      },
      {
        "state": "West Bengal",
        "city": "Singur"
      },
      {
        "state": "West Bengal",
        "city": "Sirkabad"
      },
      {
        "state": "West Bengal",
        "city": "Sitai"
      },
      {
        "state": "West Bengal",
        "city": "Sitalia"
      },
      {
        "state": "West Bengal",
        "city": "Sitalkuchi"
      },
      {
        "state": "West Bengal",
        "city": "Siyalsai"
      },
      {
        "state": "West Bengal",
        "city": "Soladanga"
      },
      {
        "state": "West Bengal",
        "city": "Sonakania"
      },
      {
        "state": "West Bengal",
        "city": "Sonakhali"
      },
      {
        "state": "West Bengal",
        "city": "Sonamukhi"
      },
      {
        "state": "West Bengal",
        "city": "Sonarpur"
      },
      {
        "state": "West Bengal",
        "city": "Soriang Khasmahal"
      },
      {
        "state": "West Bengal",
        "city": "South 24 Parganas"
      },
      {
        "state": "West Bengal",
        "city": "Sri Ram Pur"
      },
      {
        "state": "West Bengal",
        "city": "Srikrishnapur"
      },
      {
        "state": "West Bengal",
        "city": "Sriniketan"
      },
      {
        "state": "West Bengal",
        "city": "Suarasatya"
      },
      {
        "state": "West Bengal",
        "city": "Subdi"
      },
      {
        "state": "West Bengal",
        "city": "Sukani"
      },
      {
        "state": "West Bengal",
        "city": "Sukha Khola"
      },
      {
        "state": "West Bengal",
        "city": "Suri"
      },
      {
        "state": "West Bengal",
        "city": "Suria"
      },
      {
        "state": "West Bengal",
        "city": "Susunia"
      },
      {
        "state": "West Bengal",
        "city": "Sutahata"
      },
      {
        "state": "West Bengal",
        "city": "Suti"
      },
      {
        "state": "West Bengal",
        "city": "Sutia"
      },
      {
        "state": "West Bengal",
        "city": "Swarupnagar"
      },
      {
        "state": "West Bengal",
        "city": "Syamnagar"
      },
      {
        "state": "West Bengal",
        "city": "Tagaria Gopalpur"
      },
      {
        "state": "West Bengal",
        "city": "Taki"
      },
      {
        "state": "West Bengal",
        "city": "Takla"
      },
      {
        "state": "West Bengal",
        "city": "Talashur"
      },
      {
        "state": "West Bengal",
        "city": "Talbangrua"
      },
      {
        "state": "West Bengal",
        "city": "Taldangra"
      },
      {
        "state": "West Bengal",
        "city": "Taldaratan Chak"
      },
      {
        "state": "West Bengal",
        "city": "Talgachhi"
      },
      {
        "state": "West Bengal",
        "city": "Tamluk"
      },
      {
        "state": "West Bengal",
        "city": "Tanuya"
      },
      {
        "state": "West Bengal",
        "city": "Tapan"
      },
      {
        "state": "West Bengal",
        "city": "Tarakeswar"
      },
      {
        "state": "West Bengal",
        "city": "Taranipur"
      },
      {
        "state": "West Bengal",
        "city": "Teghari"
      },
      {
        "state": "West Bengal",
        "city": "Tehatta"
      },
      {
        "state": "West Bengal",
        "city": "Telami"
      },
      {
        "state": "West Bengal",
        "city": "Tengnamari"
      },
      {
        "state": "West Bengal",
        "city": "Tengunia"
      },
      {
        "state": "West Bengal",
        "city": "Thakurnagar"
      },
      {
        "state": "West Bengal",
        "city": "Tikashi"
      },
      {
        "state": "West Bengal",
        "city": "Tilkhoja"
      },
      {
        "state": "West Bengal",
        "city": "Titagarh"
      },
      {
        "state": "West Bengal",
        "city": "Tota Nala"
      },
      {
        "state": "West Bengal",
        "city": "Tufanganj"
      },
      {
        "state": "West Bengal",
        "city": "Tukdah Forest"
      },
      {
        "state": "West Bengal",
        "city": "Tulin"
      },
      {
        "state": "West Bengal",
        "city": "Tulsihata"
      },
      {
        "state": "West Bengal",
        "city": "Tushkhali"
      },
      {
        "state": "West Bengal",
        "city": "Tutranga"
      },
      {
        "state": "West Bengal",
        "city": "Uchildaha"
      },
      {
        "state": "West Bengal",
        "city": "Uchudiha"
      },
      {
        "state": "West Bengal",
        "city": "Udbadal"
      },
      {
        "state": "West Bengal",
        "city": "Uludanga"
      },
      {
        "state": "West Bengal",
        "city": "Urdhabpur"
      },
      {
        "state": "West Bengal",
        "city": "Ururi"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Amtalia"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Asda"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Baliadanga"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Chara Sankarara"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Chhat Jaygir Chilakhana"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Gobindapur"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Kalamdan"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Kalsur"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Kamakhyaguri"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Kasipur"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Lakshmipur"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Laxmipur"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Madarihat"
      },
      {
        "state": "West Bengal",
        "city": "Uttar Mokamberia"
      },
      {
        "state": "West Bengal",
        "city": "Uttar-tajpur"
      },
      {
        "state": "West Bengal",
        "city": "Uttarpara Kotrung"
      },
      {
        "state": "West Bengal",
        "city": "West Medinipur"
      }
    ],
    "Indian Institute of Forest Mana": [
      {
        "Parametres": "LIVE API URL",
        "Value": "https://api.nopaperforms.com/dataporting/629/careers360",
        "course": "Post Graduate Diploma in Forestry Management"
      },
      {
        "Parametres": "college_id",
        "Value": 629,
        "course": "Post Graduate Diploma in Sustainability Management"
      },
      {
        "Parametres": "secret_key",
        "Value": "d634d33d76d5dc93fe2b8af1f534735b"
      },
      {
        "Parametres": "source",
        "Value": "careers360"
      },
      {
        "Parametres": "medium",
        "Value": "Dynamic value as per the Publisher"
      },
      {
        "Parametres": "campaign",
        "Value": "Dynamic value as per the Publisher"
      },
      {
        "Parametres": "Field Parameters and Values"
      },
      {
        "Parametres": "Field Name",
        "Value": "Sample Values"
      },
      {
        "Parametres": "name",
        "Value": "abhishek"
      },
      {
        "Parametres": "email",
        "Value": "abhishek@nopaperforms.com"
      },
      {
        "Parametres": "mobile",
        "Value": 9876543210
      },
      {
        "Parametres": "state",
        "Value": "delhi"
      },
      {
        "Parametres": "city",
        "Value": "south delhi"
      },
      {
        "Parametres": "course",
        "Value": "PGDM"
      }
    ]
  }


  courseList = [
    {
        "CourseList": "UG",
        "specialization": "B.Sc. (Hons.) Agriculture"
    },
    {
        "CourseList": "UG",
        "specialization": "B.Tech - CSE (AI & ML, IoT, Cyber Security, Data Science)"
    },
    {
        "CourseList": "UG",
        "specialization": "BCA (Data Science & IoT)"
    },
    {
        "CourseList": "UG",
        "specialization": "B.Pharm"
    },
    {
        "CourseList": "UG",
        "specialization": "B.Pharm(Lateral Entry)"
    },
    {
        "CourseList": "UG",
        "specialization": "B.Sc (Clinical Research)"
    },
    {
        "CourseList": "UG",
        "specialization": "B.A"
    },
    {
        "CourseList": "UG",
        "specialization": "BA (Journalism & Mass Communication)"
    },
    {
        "CourseList": "UG",
        "specialization": "BA LLB"
    },
    {
        "CourseList": "UG",
        "specialization": "BBA LLB"
    },
    {
        "CourseList": "UG",
        "specialization": "Bachelor of Hotel Management"
    },
    {
        "CourseList": "UG",
        "specialization": "B.Com"
    },
    {
        "CourseList": "UG",
        "specialization": "BBA"
    },
    {
        "CourseList": "UG",
        "specialization": "BBA Logistic and supply chain Management"
    },
    {
        "CourseList": "UG",
        "specialization": "BBA Aviation Management"
    },
    {
        "CourseList": "UG",
        "specialization": "B.A. Psychology"
    },
    {
        "CourseList": "UG",
        "specialization": "B.Tech - CSE"
    },
    {
        "CourseList": "UG",
        "specialization": "B.Tech - LE"
    },
    {
        "CourseList": "UG",
        "specialization": "Bachelor of Education (B.Ed)"
    },
    {
        "CourseList": "UG",
        "specialization": "B.A International Relations"
    },
    {
        "CourseList": "UG",
        "specialization": "B.A Digital Journalism"
    },
    {
        "CourseList": "UG",
        "specialization": "B.A Restaurant Management"
    },
    {
        "CourseList": "UG",
        "specialization": "B.Sc Animation & Graphics Design"
    },
    {
        "CourseList": "UG",
        "specialization": "BCA"
    },
    {
        "CourseList": "PG",
        "specialization": "M.Sc Agriculture (Agronomy)"
    },
    {
        "CourseList": "PG",
        "specialization": "M.Sc Agriculture (Genetics & Plant Breeding)"
    },
    {
        "CourseList": "PG",
        "specialization": "MA (Journalism & Mass Communication)"
    },
    {
        "CourseList": "PG",
        "specialization": "LLB"
    },
    {
        "CourseList": "PG",
        "specialization": "LLM (1 Yr)"
    },
    {
        "CourseList": "PG",
        "specialization": "MBA"
    },
    {
        "CourseList": "PG",
        "specialization": "LLM (2 Yrs)"
    },
    {
        "CourseList": "PG",
        "specialization": "M.A Clinical Psychology"
    },
    {
        "CourseList": "PG",
        "specialization": "MBA Aviation Management"
    },
    {
        "CourseList": "PG",
        "specialization": "M.Sc. Clinical Research"
    },
    {
        "CourseList": "PG",
        "specialization": "Master in Public Health"
    },
    {
        "CourseList": "PG",
        "specialization": "MBA Health Care & Hospital Management"
    },
    {
        "CourseList": "PG",
        "specialization": "MA Education"
    },
    {
        "CourseList": "Diploma",
        "specialization": "D.Pharm"
    },
    {
        "CourseList": "Diploma",
        "specialization": "Diploma in Hotel Management"
    },
    // {
    //     "CourseList": "Ph.D.",
    //     "specialization": "Ph.D. (Agricultural Sciences)"
    // },
    // {
    //     "CourseList": "Ph.D.",
    //     "specialization": "Ph.D. (Management)"
    // },
    // {
    //     "CourseList": "Ph.D.",
    //     "specialization": "Ph.D. (Journalism & Mass Communication)"
    // },
    // {
    //     "CourseList": "Ph.D.",
    //     "specialization": "Ph.D. (Legal Studies)"
    // },
    // {
    //     "CourseList": "Ph.D.",
    //     "specialization": "Ph.D. (Pharmaceutical Sciences)"
    // },
    {
        "CourseList": "Certificate",
        "specialization": "Digital Media Production & Content Writing"
    },
    {
        "CourseList": "Certificate",
        "specialization": "BBQ"
    }
]
}
