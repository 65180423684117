<div class="section">
    <header class="header" id="scroll_header">
        <div class="menu_list">
            <ul>
                <li><a href="intellectual-propperty-right-cell#OverviewOfRAndDCell">Overview of IPR cell </a></li>
                <li><a href="intellectual-propperty-right-cell#goalsAndobjective">Major Goals/Objective</a></li>
                <li><a href="intellectual-propperty-right-cell#TeamRandD">Team IPR cell</a></li>
                <li><a href="intellectual-propperty-right-cell#Patents">List Of Published Patents</a></li>
            </ul>
        </div>
    </header>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="title">Overview of IPR cell</div>
                <div class="para">
                    <p>Intellectual property rights (IPR), refers to the legal rights given to the inventor or creator
                        to protect their invention or creation for a certain period of time. These legal rights confer
                        an exclusive right to the inventor or his assignee to fully utilize his invention for a given
                        period of time.</p>
                    <p>IPR cell has been established in the Jigyasa University
                        (Formerly Himgiri Zee University). Research is the crucial footstep of
                        innovation that plays a noteworthy role in the Universities. Intellectual property rights (IPR),
                        Jigyasa University
                        (Formerly Himgiri Zee University) has made enthusiastic efforts to align its focus through
                        maintaining a
                        research based eco system in the University through involving all staff, faculty, Research
                        Scholars, and students.</p>
                    <p>Intellectual property rights (IPR), Jigyasa University
                        (Formerly Himgiri Zee University) believe in promoting and supporting to
                        all of the research scholars and faculty to conduct various workshop, seminars to help in
                        drafting and filling the patents.</p>
                </div>
            </div>
        </section>
        <section id="goalsAndobjective">
            <div class="majorGoals">
                <img src="../../../assets/img/jigyasa/banner/zeeUniversity.png" width="100%" alt="">
                <div class="para mt-4">
                    <div class="sub_title">Goals / Objective</div>
                    <ul>
                        <li>
                            <p>The main objective of IPR is to create public awareness about the benefits of
                                Intellectual property among all sections of society.</p>
                        </li>
                        <li>
                            <p>To stimulate the creation and growth of intellectual property by undertaking
                                relevantmeasures.</p>
                        </li>
                        <li>
                            <p>To have strong and effective laws with regard to IP rights, consistent with international
                                obligations.</p>
                        </li>
                        <li>
                            <p>To modernise and strengthen IP administration.</p>
                        </li>
                        <li>
                            <p>To catalyse commercialization of IP rights.</p>
                        </li>
                        <li>
                            <p>To promote University-Industry Collaboration.</p>
                        </li>
                        <li>
                            <p>To encourage Core Proficiency and Consultancy work.</p>
                        </li>
                        <li>
                            <p>To organize various workshops/ seminar/ trainings related to promotion of IPR.</p>
                        </li>
                        <li>
                            <p>To create awareness about filing Patents & IPR and to motivate all faculty and students.
                            </p>
                        </li>
                        <li>
                            <p>To strengthen the enforcement and adjudicatory mechanisms for combating IP violations and
                                to promote awareness and respect for IP rights.</p>
                        </li>
                        <li>
                            <p>Capacity development by strengthening and expanding human resources, institutions for
                                training, research and skill building in IP.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <section id="TeamRandD">
            <div class="majorGoals py-5">
                <img src="../../../assets/img/jigyasa/banner/zeeUniversity.png" width="100%" alt="">
                <div class="para mt-4">
                    <div class="title">Team IPR Cell</div>
                    <div class="row mt-5">
                        <div class="col-md-6">
                            <div class="facultyWrapperItem">
                                <div class="faculty-banner">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <img src="../../../assets/img/jigyasa/DrGideon.png" class="img-responsive">
                                        </div>
                                        <div class="col-md-7">
                                            <div class="profile">
                                                <h3>Dr. Gideon Synrem</h3>
                                                <p>Member IPR cell, JU</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="facultyContentWrapper_para">
                                    <p>Dr. Gideon Synrem did his Masters (Agriculture) Genetic and Plant Breeding in
                                        2011, and Ph.D (Agriculture) Genetic and Plant Breeding in 2017 from Sam
                                        Higginbottom University of Agriculture, Technology and Sciences, Prayagraj
                                        (Formerly Allahabad Agriculture Institute). He is engaged in Teaching and
                                        Research since 2017 (5 years) and presently serving the Jigyasa University
                                        (Formerly Himgiri Zee University),
                                        Dehradun as an Assistant professor in the Department of Agriculture. Dr. Gideon
                                        Synrem has published many research papers in NAAS/UGC CARE/SCOPUS Journals. He
                                        is also an author of one book titled “Advances in Agriculture”. He has two book
                                        chapters in the book “Advances in Agriculture”. He Hs attended many National
                                        conferences/Seminar/Symposium and have presented many poster presentations. He
                                        is a recipient of the prestigious Rajiv Gandhi National Fellowship for a period
                                        of 5 years as JRF/SRF by the University Grants Commission, New Delhi. His area
                                        of interest includes Life sciences, Quantitative genetics, statistical sofware
                                        like SPSS, R Soft Skills. His hobbies include Singing (Record in All India
                                        Radio), Outdoor and Indoor Sports, Athletics and Travelling and Counselling.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="facultyWrapperItem">
                                <div class="faculty-banner">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <img src="../../../assets/img/jigyasa/3T.png" style="margin:5px"
                                                class="img-responsive">
                                        </div>
                                        <div class="col-md-7">
                                            <div class="profile">
                                                <h3>Dr. Abha Sharma</h3>
                                                <p>Member IPR cell, JU</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="facultyContentWrapper_para">
                                    <h2 class="titleWrap">Area of Specialization/Interest:</h2>
                                    <p class="mt-2">Interest– Interpersonal Relations, Stress Management, Leadership and
                                        other
                                        significant areas related to Human Resource Management She possess rich
                                        experience of academics and administration and credited with book publication
                                        /patent / Paper Publication and guided several UG & PG students for their
                                        project work/dissertation. Besides this she had been a member of Board of
                                        Studies for BBA and MBA programmes and associated with 16 years with Zee
                                        university / Higher Education System in Essel group.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section id="Patents">
            <div class="patents py-5">
                <div class="title">Patents</div>
                <div class="sub_title mt-5">Number of Patents published/awarded during the last five years</div>
                <div class="patents_table mt-3">
                    <table>
                        <thead>
                            <tr>
                                <th>S.N.</th>
                                <th>Name</th>
                                <th>Patent Number</th>
                                <th>Title of the patent</th>
                                <th>Year of Award publish of patent</th>
                                <th>Patent Link</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <p>1</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Alok Bhatt / Dr. Jyoti Maithani Kalra
                                    </p>
                                </td>
                                <td>
                                    <p>202211010190 A</p>
                                </td>
                                <td>
                                    <p>A method for ameliorating the solubility of rifabutin with the inclusion of melt
                                        granulation process.</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/1.pdf">Link</a></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>2</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Alok Bhatt / Dr. Jyoti Maithani Kalra

                                    </p>
                                </td>
                                <td>
                                    <p>202211026331 A</p>
                                </td>
                                <td>
                                    <p>A co solvency-based method for improving the solubility of rifabutin.</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/2.pdf">Link</a></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>3</p>
                                </td>
                                <td>

                                    <p>
                                        Mr. Alok Bhatt / Dr. Jyoti Maithani Kalra
                                    </p>
                                </td>
                                <td>
                                    <p>202211032157 A</p>
                                </td>
                                <td>
                                    <p>An inclusion complexation-based method for catalyzing the solubility of
                                        Rifabutin.
                                    </p>
                                </td>
                                <td>
                                    <p>2022
                                    </p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/3.pdf">Link</a></p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>4</p>
                                </td>
                                <td>
                                    <p>Mr. Alok Bhatt
                                    </p>
                                </td>
                                <td>
                                    <p>202211026894 A</p>
                                </td>
                                <td>
                                    <p> Iot enabled automated air quality indicator system in airport and method
                                        thereof.
                                    </p>
                                </td>
                                <td>
                                    <p>2022
                                    </p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/4.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>5</p>
                                </td>
                                <td>
                                    <p>Mr. Alok Bhatt
                                    </p>
                                </td>
                                <td>
                                    <p>202211015536 A</p>
                                </td>
                                <td>
                                    <p> A system of assessment of behavioral activities in experimental animals in
                                        elevated plus maze animal module with vision-based edge device

                                    </p>
                                </td>
                                <td>
                                    <p>2022
                                    </p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/5.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>6</p>
                                </td>
                                <td>
                                    <p>Mr. Alok Bhatt</p>
                                </td>
                                <td>
                                    <p>202211015535 A</p>
                                </td>
                                <td>
                                    <p> A system of iot enabled histamine chamber for assessment of allergic activity in
                                        experimental animals</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/6.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>7</p>
                                </td>
                                <td>
                                    <p>Dr. Jyoti Maithani Kalra</p>
                                </td>
                                <td>
                                    <p>202211010105 A</p>
                                </td>
                                <td>
                                    <p>A method of enhancing the solubility of rifabutin using solid dispersion.</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/7.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>8</p>
                                </td>
                                <td>
                                    <p>Dr. Jyoti Maithani Kalra / Mr. Manish Nawani</p>
                                </td>
                                <td>
                                    <p>202211050785 A</p>
                                </td>
                                <td>
                                    <p>A method based on complexation to enhance the solubility of the drug rifapentine
                                    </p>
                                </td>
                                <td>
                                    <p>2022
                                    </p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/8.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>9</p>
                                </td>
                                <td>
                                    <p>Dr. Jyoti Maithani Kalra </p>
                                </td>
                                <td>
                                    <p>202211051809 A</p>
                                </td>
                                <td>
                                    <p>An improved process for catalysing the solubility of rifapentine through melting
                                        technique
                                    </p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/9.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>10</p>
                                </td>
                                <td>
                                    <p>Mr. Ashok Kumar</p>
                                </td>
                                <td>
                                    <p>202211005009 A</p>
                                </td>
                                <td>
                                    <p>Alcoholic formulation of calcarea carbonica for treatment and prevention of
                                        inflammation diseases.</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/10.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>11</p>
                                </td>
                                <td>
                                    <p>Mr. Ashok Kumar</p>
                                </td>
                                <td>
                                    <p>202211037370 A</p>
                                </td>
                                <td>
                                    <p>An innovative technique for preparation of transdermal drug delivery system with
                                        natural biplomer matrix.</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/11.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>12</p>
                                </td>
                                <td>
                                    <p>Mr.Subodh Choudhary</p>
                                </td>
                                <td>
                                    <p>202111059121 A</p>
                                </td>
                                <td>
                                    <p>Application of Marketing Strategies and Tools in Restaurants</p>
                                </td>
                                <td>
                                    <p>2021</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/12.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>13</p>
                                </td>
                                <td>
                                    <p>Mr.Subodh Choudhary</p>
                                </td>
                                <td>
                                    <p>202231006225 A</p>
                                </td>
                                <td>
                                    <p>Tourism industry an inquiry into its growth, problems, prospects and strategy in
                                        Dehradun</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/13.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>14</p>
                                </td>
                                <td>
                                    <p>Mr.Subodh Choudhary</p>
                                </td>
                                <td>
                                    <p>202211029361 A</p>
                                </td>
                                <td>
                                    <p>Interdisciplinary investigation related to strategic management in the field of
                                        hospitality and costs</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/14.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>15</p>
                                </td>
                                <td>
                                    <p>Dr. Jai Jayant</p>
                                </td>
                                <td>
                                    <p>202241025597 A</p>
                                </td>
                                <td>
                                    <p>Analysis Of Major Domestic Tourism Companies In India</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/15.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>16</p>
                                </td>
                                <td>
                                    <p>Dr. Jai Jayant</p>
                                </td>
                                <td>
                                    <p>202241049347 A</p>
                                </td>
                                <td>
                                    <p>Uthenticated Block Chain Security Enabled Transaction</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/16.pdf">Link</a>
                                    </p>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>
                                    <p>17</p>
                                </td>
                                <td>
                                    <p>Dr. Suchita Gera</p>
                                </td>
                                <td>
                                    <p>2021105488</p>
                                </td>
                                <td>
                                    <p>A novel method for managing after-sale services and customer retention.</p>
                                </td>
                                <td>
                                    <p>2021</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/17.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>18</p>
                                </td>
                                <td>
                                    <p>Dr. Suchita Gera</p>
                                </td>
                                <td>
                                    <p>202111058517</p>
                                </td>
                                <td>
                                    <p>TBA- Management System: Tourist Behaviour Analysis and Manageent System Using AI
                                        (Indian Patent)</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/18.pdf">Link</a>

                                    </p>
                                </td>
                            </tr> -->
                            <tr>
                                <td>
                                    <p>17</p>
                                </td>
                                <td>
                                    <p>Dr. Neeraj Jain</p>
                                </td>
                                <td>
                                    <p>202241049347 A</p>
                                </td>
                                <td>
                                    <p>Impact of online Recruitment Process in IT Sector.</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/19.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>18</p>
                                </td>
                                <td>
                                    <p>Dr. Neeraj Jain</p>
                                </td>
                                <td>
                                    <p>202241031229 A</p>
                                </td>
                                <td>
                                    <p>Impact of COVID -19 Pandemic on sustainability of Supply Chain in India</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/20.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>19</p>
                                </td>
                                <td>
                                    <p>Mr. Amit Kumar</p>
                                </td>
                                <td>
                                    <p>202211023299 A</p>
                                </td>
                                <td>
                                    <p> Wall Panel and Method of Constructing the Same (Indian patent)</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/21.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>20</p>
                                </td>
                                <td>
                                    <p>Mr. Amit Kumar</p>
                                </td>
                                <td>
                                    <p>202211023301 A</p>
                                </td>
                                <td>
                                    <p> Aircraft Crash Detection System (Indian patent)</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/22.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>21</p>
                                </td>
                                <td>
                                    <p>Mr. Amit Kumar</p>
                                </td>
                                <td>
                                    <p>202211026305 A</p>
                                </td>
                                <td>
                                    <p> Smart motorcycle helmet</p>
                                </td>
                                <td>
                                    <p>2021</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/23.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>22</p>
                                </td>
                                <td>
                                    <p>Mr. Amit Kumar</p>
                                </td>
                                <td>
                                    <p>2021101733</p>
                                </td>
                                <td>
                                    <p> Internet of Things (IoT) System and Method for Smart Hotel Location and Internal
                                        Status Update (Australian Innovation Patent)</p>
                                </td>
                                <td>
                                    <p>2021</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/24.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>23</p>
                                </td>
                                <td>
                                    <p>Mr. Amit Kumar</p>
                                </td>
                                <td>
                                    <p>2021102721</p>
                                </td>
                                <td>
                                    <p> A System and a Method for Tracking and Scrutinizing an Aquatic Sensory Node
                                        (Australian Innovation Patent)</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/25.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>24</p>
                                </td>
                                <td>
                                    <p>Mr. Amit Kumar</p>
                                </td>
                                <td>
                                    <p>202211008763 A</p>
                                </td>
                                <td>
                                    <p> An Ultraviolet Radiation Based Alert System in A Vehicle (Indian Patent)</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/26.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>25</p>
                                </td>
                                <td>
                                    <p>Mr. Amit Kumar</p>
                                </td>
                                <td>
                                    <p>202211007500 A</p>
                                </td>
                                <td>
                                    <p> A novel Method of Charging Electric Vehicles</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/27.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>26</p>
                                </td>
                                <td>
                                    <p>Mr. Amit Kumar</p>
                                </td>
                                <td>
                                    <p>202211007502 A</p>
                                </td>
                                <td>
                                    <p> A Traffic Signal Alert System for Vehicles</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/28.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>27</p>
                                </td>
                                <td>
                                    <p>Dr. Jyoti Maithani Kalra</p>
                                </td>
                                <td>
                                    <p>124382</p>
                                </td>
                                <td>
                                    <p>AUTOMATIC MORTAR WITH TABLET GRANULARITY LEVEL CONTROL AND INDICATOR</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/29.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>28</p>
                                </td>
                                <td>
                                    <p> Dr. Jyoti Maithani Kalra</p>
                                </td>
                                <td>
                                    <p>120491</p>
                                </td>
                                <td>
                                    <p> VARIABLE SPEED LABORATORY STIRRER WITH SATURATION LEVEL INDICATOR AND DIGITAL
                                        CONTROL</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/30.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>29</p>
                                </td>
                                <td>
                                    <p>Mr. Alok Bhatt</p>
                                </td>
                                <td>
                                    <p>202211069418 A</p>
                                </td>
                                <td>
                                    <p> AN IOT ENABLED DRYER SYSTEM FOR MINOR FOREST</p>
                                </td>
                                <td>
                                    <p>2022</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/31.pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>30</p>
                                </td>
                                <td>
                                    <p>Dr. Mojahid Ul Islam / Dr. Jyoti Maithani Kalra</p>
                                </td>
                                <td>
                                    <p>6253975</p>
                                </td>
                                <td>
                                    <p> NEONATAL BILIRUBINOMETER</p>
                                </td>
                                <td>
                                    <p>2023</p>
                                </td>
                                <td>
                                    <p><a href="../pdf/patent/32 (2).pdf">Link</a>

                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
</div>