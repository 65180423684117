import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AOS from 'aos';
import { CommonService } from '../../service/common.service';

@Component({
  selector: 'app-all-blog',
  templateUrl: './all-blog.component.html',
  styleUrls: ['./all-blog.component.css'],
})
export class AllBlogComponent implements OnInit {
  project_id = environment.projectid;   
  blogsList:any=[];
  AssignCategory:any=[];
  alldata:any=[];
  findex:number=0;
  tindex:number=20;
  totalrecord:number=0;
  more:boolean=true;
  title:string="";
  secondtitle:string="";
  thirdtitle:string="";
  pagedata: any;
  // blogDetails: boolean = true;
  // allBlogs: boolean = false;
  // headerTitle = 'Blog';

  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,    
    private _service: CommonService,
    private spinner:NgxSpinnerService,
    private _ApicallService: ApicallService,
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {      
      this.title = params['type'];    
      this.route.params.subscribe((params: any) => { 
        this.secondtitle = params['type2'];
        this.route.params.subscribe((params: any) => {    
          this.thirdtitle = params['type3'];      
        })
      })            
    })
    AOS.init();
    this.getslug();
    
  }


  getseo() {
    let tbody = {
      slug:'blog',
    };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {      
      this.alldata = data?.data;
      this.pagedata = data?.data?.pagedata;
      this.projectService.sendMessageblog(data?.data?.Blog);
      this.projectService.sendMessagenews(data?.data?.News)
      this.projectService.setFacultysubject(data?.data?.Faculty);
      this.projectService.setPlacementsubject(data?.data?.Placement);
      this.projectService.setprogramssubject(data?.data?.Programs);//
      this.projectService.sendMessageseo(data?.data?.Testimonial);
      this.projectService.sendMessageFaqs(data?.data?.FAQs);
      this.projectService.setmeta(data?.data);
      this.projectService.sendMessagebread(data?.data.breadcrumb)
    });
  }

  getslug(){
    this.getseo();
    let slug;
    if(this.thirdtitle!=undefined){
      slug=this.title+'/'+this.secondtitle+'/'+this.thirdtitle;
    }
    else if (this.secondtitle!=undefined){
      slug=this.title+'/'+this.secondtitle;
    }
    else{
      slug=this.title;
    }
    this.getplacementData(slug); 
  }


  getplacementData(slug:string) {
    this.spinner.show();
    let obj = {
      "Project_Id": this.project_id,
      "Type": "Blog",
      "pageurl":slug
    }
    this._service.getContentbytype(obj).subscribe(
      res => {    
        this.spinner.hide();   
        if(res.data){
          this.AssignCategory = JSON.parse(res.data[0].AssignCategory);
          this.alldata=JSON.parse(res.data[0].contentData);
          //this.PlacementList = JSON.parse(res.data[0].contentData);   
          this.setcentrelist();  
        }else{
          
        }
          
      }
    )
  }
  fn_ClientFilter(event: any) {
    let val = event.target.value.toLowerCase();
    if (!val) {
      this.blogsList = this.alldata;
    }
    this.blogsList = this.alldata.filter(function (dt: any): any {
      if (
        dt?.Title?.toString().toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        // found match, return true to add to result set
        return true;
      }
      else if (dt.slug) {
        if (dt?.slug?.toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          // found match, return true to add to result set
          return true;
        }
      }      
    })
  }
  setcentrelist()
{
  let dlenth=this.alldata.length;
  this.totalrecord=dlenth;
  if(dlenth>20)
  {
    this.tindex=20;
  }
  else
  {
    this.tindex=dlenth;
    this.more=false;
  }

  this.blogsList=this.alldata.slice(this.findex,this.tindex);
  
}
filtercategory(id:number){
  if(id!=0){
    this.blogsList=this.alldata.filter((dt:any)=>{
      return dt.category.includes(id)
    }).map((obj:any)=>{
      return obj;
    })
  }
  else{
    this.blogsList=this.alldata;

  }
  
}
  setcentrelistclick()
{
  let dlenth=this.alldata.length;
  let nindex=this.tindex+20;

  if( dlenth> this.tindex)
  {


    if(nindex>dlenth)
    {
       this.tindex=dlenth;
        this.more=false;
    }
    else
    {
    this.tindex += 20;
    }
  }
  else
  {

  this.more=false;
  }

  this.blogsList=this.alldata.slice(this.findex,this.tindex);
  //this.centerList=data;
}

}
