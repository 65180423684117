<section>
    <div class="subMenu_content" *ngIf="ishide!=1">
        <app-sub-menu></app-sub-menu>
    </div>
   <div class="sub_content">
    <div class="para">
        <p>At Jigyasa University, we are committed to give ample of opportunities to all the students of all the courses
            to jump start their professional life after academics.</p>
        <p>Some of our USPs are</p>
        <ul>
            <li>We assure a minimum of 3 opportunities to all the students to get placed. There is no limit to the
                maximum number of opportunities.</li>
            <li>Even after getting selected in any company the students get an option of Dream offer to pursue their
                aspirations in professional life.</li>
            <li>Students are guided by our expert faculty to be employable and also on how to crack interview process.
            </li>
            <li>Special Soft Skill classes, mock interviews, mock Group Discussions, Aptitude test training, technical
                training are given to students by university faculty and also by external experts.</li>
            <li>Placement is conducted in Campus as well as Off Campus in Offline and Online mode.</li>
            <li>We follow a rigorous feedback mechanism to help the students who are not able to clear the placement
                process to help them succeed in forthcoming processes.</li>

        </ul>
    </div>
</div>
    <div class="py-3" [ngClass]="ishide==1 ? '' :'sub_content'">
         <div class="py-3">
            <div class="title" *ngIf="ishide!=1">TOP Achiever</div>
            <div class="row">
                <div class="col-md-6 mt-3" *ngFor="let a of topachievers; index as i">
                    <!-- *ngIf="i <=1" -->
                    <div class="top_content">
                        <div class="row">
                            <div class="row">
                                <div class="col-md-5 d-flex justify-content-center align-items center">
                                    <div class="profile_pic" *ngFor="let img of a.logofiles">
                                        <img src="{{img.value}}" width="100%" height="auto" alt="{{img.key}}">
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="para">
                                        <div class="title">{{a.Title}}</div>
                                        <h5>{{a.short}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="title mt-5">Some of Our Achievers</div>
            <div class="some_achievers_content">
                <div class="row">
                    <div class="col-md-3 mt-3" *ngFor="let a of someachievers">
                        <div class="achivers_sub_content">
                            <div class="profile_pic" *ngFor="let img of a.logofiles">
                                <img src="{{img.value}}" width="100%" height="auto" alt="top">
                            </div>
                            <div class="sub_title text-center">{{a.Title}}</div>
                            <h6 class="text-center">{{a.short}}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>