<section>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <div class="title">Disclaimer</div>
        <div class="main_content_para my-4">
            <div class="para">
                <p>The Jigyasa University
(Formerly Himgiri Zee University) has made all reasonable efforts to ensure that all information provided
                    through its website is accurate, current and informative. However the information contained in this
                    website is not comprehensive and there may be inadvertent and occasional errors. The information and
                    services may contain bugs, errors, problems or other limitations. Jigyasa University
(Formerly Himgiri Zee University) and our
                    affiliated parties have no liability whatsoever for your use of any information or service</p>
                <p>We make no warranties about the information provided through this website. The contents are subject
                    to change without notice and no contractual commitment is formed simply by using the site. The
                    Jigyasa University
(Formerly Himgiri Zee University) does not accept liability for any direct, indirect, special, consequential or
                    other losses or damages of whatsoever kind arising out of access to, or the use of this website or
                    any information contained in it.</p>
                <p>Any decisions you make based on information contained in this website are your sole responsibility.
                    No part of the text, any graphics or images on this site may be reproduced or transmitted in any
                    form or by any means, electronic, mechanical or otherwise, including by photocopying, facsimile
                    transmission, recording, re-keying or using any information storage and retrieval system without
                    prior written permission from University.</p>
            </div>
            <h5>External links</h5>
            <div class="para">
                <p>Many links in our website will lead to external websites over which we have no control. Although we
                    endeavour to review the content of websites to which we provide links, we do not accept
                    responsibility for the information contained in these websites. It is the responsibility of the
                    Internet user to make their own decisions about the accuracy, currency, reliability and correctness
                    of information found at sites linked from this website.</p>
            </div>
        </div>
    </div>
</section>