import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-academics',
  templateUrl: './academics.component.html',
  styleUrls: ['./academics.component.css']
})
export class AcademicsComponent implements OnInit{
  applayUrl = environment.applay_url
  project$: Observable<any> | undefined;
  Title:string="";
  leveldesc:String="";
  academindata:any=[];
  visible:number=-1;
  isshow:boolean=false;
  alldata:any=[];
  constructor(private route:ActivatedRoute,
              private router:Router,
              private seoService: HomeSeoService,
              private apiservice:ApicallService,
              private projectService: ProjectSeoService){}

  ngOnInit():void{
    let urlstr=this.router.url;
    this.Title=urlstr.slice(1);    
    this.getseo();    
    // this.route.params.subscribe((params: any) => {      
    //   let routevalue = params['school'];      
    //   this.Title=routevalue;
    //   this.getseo();
    // })
  }
  // getcoursedata(){
  //   this.academindata=[];
  //   this.isshow=false;
  //   let jdata={
  //     "slug":this.Title,
  //     "projectid":1
  //   }
  //   return this.apiservice.Getcoursedatabyslug(jdata).subscribe({
  //     next:(resp:any)=>{
  //       this.academindata=resp.data;
      
  //       console.log('this.academindata',this.academindata);
  //     },
  //     error:(error)=>{
  //       console.log(error);
  //     }
  //   })
  // } 
  getseo() {
    let tbody = {
      slug:this.Title,
    
    };
    this.apiservice.getGetseo(tbody).subscribe((data: any) => {
      this.alldata=data?.data;
      console.log('this.alldata',this.alldata);
        this.academindata=data?.data?.pagedata;       
        console.log(this.academindata);
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);      
        this.projectService.setprogramssubject(data?.data?.Programs);//
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
    });
  }

  showhide(data:any){
    this.leveldesc="";
    if(data){
      this.leveldesc=data;
    }
  }
}
