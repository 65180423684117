import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApicallService } from 'src/app/services/apicall.service';
import { environment } from 'src/environments/environment';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-ph-dawarded-list',
  templateUrl: './ph-dawarded-list.component.html',
  styleUrls: ['./ph-dawarded-list.component.css']
})
export class PhDAwardedListComponent implements OnInit{
  classParam: any;
  project_id = environment.projectid; 
  alldata: any;
  Announcementdata: any;
  pgdata: any;
  studentdata:any=[];
  studentcategory:any=[];
  constructor(private activatedRoute: ActivatedRoute,
    private _ApicallService: ApicallService,
    private projectService: ProjectSeoService,
    private route: ActivatedRoute, 
    private spinner:NgxSpinnerService,  
    private _service: CommonService,
  ) {
  }

  ngOnInit(): void {
    this.getstudentdata();
    
  }

  getstudentdata(){
    this.spinner.show();
    let obj = {
      "Project_Id": this.project_id,
      "Type": "Ph.D. Students",
      "pageurl":"home"
    }
    this._service.getContentbytype(obj).subscribe(
      res => {    
        this.getseo();
        this.spinner.hide();   
        if(res.data){
          this.studentcategory = JSON.parse(res.data[0].AssignCategory);
          this.studentdata=JSON.parse(res.data[0].contentData);            
          console.log('this.studentdata',this.studentdata);
        }else{
          
        }          
      }
    )
  }

  getseo() {
    let tbody = {
      slug: 'ph-d',
      // Projectid:environment.projectid
    };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
      this.alldata = data?.data;
      this.pgdata = data?.data?.pagedata;
      this.projectService.sendMessageblog(data?.data?.Blog);
      this.projectService.sendMessagenews(data?.data?.News)
      this.projectService.setFacultysubject(data?.data?.Faculty);
      this.projectService.setPlacementsubject(data?.data?.Placement);
      this.projectService.setprogramssubject(data?.data?.Programs);//
      this.projectService.sendMessageseo(data?.data?.Testimonial);
      this.projectService.sendMessageFaqs(data?.data?.FAQs);
      this.projectService.setmeta(data?.data);
      this.projectService.sendMessagebread(data?.data.breadcrumb)
    });
  }
}
