<section>
  <div class="subMenu_content">
    <app-sub-menu></app-sub-menu>
  </div>
  <div class="main_div sub_content">
    <div class="listSearchArea d-flex justify-content-between align-items-center">

      <ul class="nav nav-tabs tabs-buttons" role="tablist">
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab0">
          <button id="click_btn" class="nav-link active" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0"
            type="button" role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
            (click)="filtercategory('Introduction')">Introduction</button>
        </li>
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab1">
          <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
            (click)="filtercategory('Dance Club')">Dance Club</button>
        </li>
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab2">
          <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
            (click)="filtercategory('Music Club')">Music Club</button>
        </li>
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab3">
          <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
            (click)="filtercategory('Art and Craft clubs')">Art and Craft clubs</button>
        </li>
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab4">
          <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
            (click)="filtercategory('Literature Club')">Literature Club</button>
        </li>
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab5">
          <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
            (click)="filtercategory('Yoga Club')">Yoga Club</button>
        </li>
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab6">
          <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
            (click)="filtercategory('Sports Clubs')">Sports Clubs</button>
        </li>
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab7">
          <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
            (click)="filtercategory('Photography')">Photography</button>
        </li>
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab8">
          <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
            (click)="filtercategory('Media Club')">Media Club</button>
        </li>
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab9">
          <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
            (click)="filtercategory('Theatre Club')">Theatre Club</button>
        </li>
      </ul>
    </div>
    <div>
      <div *ngIf="selectedclub=='Introduction'">
        <div class="title">Club @JU</div>
        <p>Jigyasa University
          (Formerly Himgiri Zee University) is one of the leading universities in India that provides world-class
          education to its students. This
          university offers a wide range of courses in various disciplines, including engineering, management,
          journalism, and others.
          In addition to academic excellence, Jigyasa University
          (Formerly Himgiri Zee University) also prioritizes extracurricular activities and offers different
          clubs to its students. The university believes that extracurricular activities play a vital role in the
          personal and professional
          development of students. The clubs offered by the university provide students with a platform to explore their
          interests, develop
          new skills, and interact with like-minded peers. Apart from providing a platform for personal development, the
          clubs also help in
          creating a sense of community within the university. It provides students with an opportunity to connect with
          other students from
          different disciplines and backgrounds. These clubs also act as a resource for students, as they get to learn
          from their seniors and
          alumni who have been a part of these clubs. The university believes that education is not only about academic
          excellence and career
          prospects but also about personal growth and development. The clubs offered by the university help in
          achieving this goal by providing
          students with a platform to explore their interests and develop new skills.</p>

      </div>
      <div *ngIf="selectedclub=='Dance Club'">
        <div class="title">Dance club</div>
        <p>The Dance club of Jigyasa University
          (Formerly Himgiri Zee University) is a popular student organization that promotes dance as a means of
          entertainment, expression, and exercise. The club is run by a group of passionate dancers who share their
          love of dance with fellow students. The club holds regular practice sessions where members of all levels of
          experience can learn new moves and improve their skills. The dance styles include Bollywood, hip-hop,
          contemporary,
          folk, and classical. The club also organizes workshops and masterclasses with professional dancers to enhance
          the
          members' knowledge and abilities. The Dance club of Jigyasa University
          (Formerly Himgiri Zee University) is not only about dancing but also about
          having fun and making friendships. The club organizes social events such as dance parties, outings, and
          celebrations
          that bring together like-minded individuals who share a passion for dance. The club also participates in
          various
          intercollegiate competitions and has won several accolades for their outstanding performance. The club members
          are not
          only skilled dancers but also talented choreographers who have crafted unique and captivating dance routines
          that leave
          the audience spellbound. The Dance club of Jigyasa University
          (Formerly Himgiri Zee University) provides a platform for students to explore their creativity,
          boost their confidence, and showcase their talent. The club is open to all students who have an interest in
          dance, regardless
          of their experience or background. Joining the club is a great opportunity to express oneself through dance,
          make new friends,
          and create memories that will last a lifetime.</p>
      </div>
      <div *ngIf="selectedclub=='Music Club'">
        <h3>Music club</h3>
        <p>Music club of Jigyasa University
          (Formerly Himgiri Zee University) is one of the most popular and vibrant clubs on campus. The club was formed
          with the
          objective of bringing together students who have a passion for music and providing them with a platform to
          showcase their
          talents. The music club is open to all students, regardless of their area of study or level of expertise. Some
          members of
          the club are trained musicians who have been performing for years, while others are beginners who are just
          starting to
          explore their musical abilities. The club meets regularly to practice, share ideas and collaborate on various
          musical projects.
          Some of the activities that the music club engages in include organizing concerts, performing on campus events
          and participating
          in music competitions. One of the standout features of the music club is the diversity of genres explored by
          the members. The club
          members perform a wide range of musical styles, from classical to contemporary, jazz to rock, and everything
          in between. The music
          club is also actively involved in community engagement initiatives, where they perform at local events,
          hospitals, schools and other
          community events. Overall, the music club of Jigyasa University
          (Formerly Himgiri Zee University) is a place where music enthusiasts from all walks of life can come
          together, share their passion for music, and create beautiful sounds together. The club provides an
          opportunity for students to develop
          their musical talents, build friendships and make a positive impact on their communities.</p>
      </div>
      <div *ngIf="selectedclub=='Art and Craft clubs'">
        <h3>Art & Craft</h3>
        <p>Jigyasa University
          (Formerly Himgiri Zee University) has a rich tradition of promoting the art and craft culture among its
          students. During their academic
          journey, students not only learn theoretical concepts but also get practical experience in the field of art
          and craft. The
          university provides a platform for students to showcase their creativity and explore their talents. The
          university emphasizes
          the importance of preserving Indian art and craft heritage and promoting the same among the younger
          generation. The students
          are given various opportunities to learn different art forms such as painting, music, dance, sculpture,
          pottery, and so on.
          They are encouraged to participate in workshops and exhibitions organized by the university. The university
          has a well-equipped
          art and craft center that boasts modern facilities and tools. Students can avail themselves of the center's
          resources and gain
          hands-on experience in different techniques and mediums of art, such as oil painting, watercolor painting,
          charcoal drawing, and
          so on. They are also encouraged to experiment and bring their own unique style and perspective to their work.
          The university organizes
          various events and competitions throughout the year to showcase the students' talents. These events not only
          provide a platform for students
          to showcase their creativity but also give them exposure to the industry and inspire them to pursue their
          passion. It also helps students
          develop their confidence, communication, and presentation skills, which are essential in any field. In a
          nutshell, Jigyasa University
          (Formerly Himgiri Zee University)
          has a vibrant art and craft culture that fosters creativity and learning among its students. The university's
          commitment to preserving Indian
          art and craft heritage while promoting new forms of creativity is commendable. With its state-of-the-art
          facilities and dedicated faculty,
          Jigyasa University
          (Formerly Himgiri Zee University) is poised to become a major hub for art and craft education in the country.
        </p>
      </div>
      <div *ngIf="selectedclub=='Literature Club'">
        <h3>Literature Club</h3>
        <p>The Literature Club of Jigyasa University
          (Formerly Himgiri Zee University) is a dedicated and passionate group of individuals who share a love for
          literature. Students from all majors come together to read, discuss, and appreciate literature in its many
          forms. The
          club is an excellent platform for students to develop their critical thinking skills and expand their
          knowledge of
          different authors and genres. The club holds regular meetings where members discuss current books they are
          reading,
          analyze pieces of literature, and share their views about them. Apart from these meetings, the club also
          organizes
          several events where literary enthusiasts are invited to participate. Such activities include book clubs,
          poetry recitals,
          creative writing workshops, and guest speaker sessions with renowned authors, poets, and publishers. The club
          is well
          recognized for its efforts in promoting literature inside and outside of the university. Members of the club
          are often
          invited to participate in literary competitions, where they showcase their skills and talent. The club has
          also organized
          several literary events in collaboration with other universities, increasing its reach and popularity within
          the literary
          community. The Literature Club of Jigyasa University
          (Formerly Himgiri Zee University) aims to inspire and inculcate the love for literature in all its
          members. By providing a space for passionate readers to come together and share their ideas, the club fosters
          a culture of
          learning, appreciation, and creativity. It is undoubtedly an excellent opportunity for students to grow both
          academically
          and intellectually. In conclusion, the Literature Club of Jigyasa University
          (Formerly Himgiri Zee University) is a place where students can explore their
          literary interests and interact with like-minded people. With its diverse array of activities, the club is an
          excellent platform
          for students to expand their knowledge, develop their critical thinking skills, and enhance their creativity.
          For any student who
          shares a passion for literature, the Literature Club is an absolute must-join.</p>
      </div>
      <div *ngIf="selectedclub=='Yoga Club'">
        <h3>Yoga Club</h3>
        <p>The Yoga Club of Jigyasa University
          (Formerly Himgiri Zee University) is a widely popular and active student club that operates on the principle
          of holistic
          wellness. The club intends to promote a healthy and balanced lifestyle by practicing and propagating the
          ancient Indian tradition
          of yoga. With a dedicated team of members, the Yoga Club aims to spread awareness about the countless benefits
          of yoga, including
          stress reduction, increased flexibility, better posture, and enhanced breathing. The club has a strong mission
          to create a positive
          impact on the overall well-being of the students and faculty of the university. Through various yoga sessions
          and workshops organized
          by the club, individuals are exposed to various forms of yoga, such as Hatha, Ashtanga, Kundalini, and many
          more. These sessions are
          conducted by experienced professionals who guide participants through the correct postures, breathing
          techniques, and meditation practices.
          The Yoga Club of Jigyasa University
          (Formerly Himgiri Zee University) has been praised for its ability to create a cohesive community of
          like-minded individuals. By
          bringing together individuals with a passion for yoga, the club provides a platform where members can share
          their experiences, knowledge,
          and expertise with each other. The members of the club are dedicated to spreading the value of yoga in not
          only the university but also in
          the surrounding area. The Yoga Club of Jigyasa University
          (Formerly Himgiri Zee University) has organized several programs and events to promote and celebrate yoga.
          Some
          of these include International Day of Yoga, National Yoga Week, and others. The club also conducts seminars
          and workshops, inviting expert yoga
          instructors and practitioners to share their insights and skills. Overall, the Yoga Club of Jigyasa University
          (Formerly Himgiri Zee University) is a thriving community that
          has successfully created a positive impact on the physical and mental well-being of its members. The club has
          brought the benefits of yoga to various
          people, and its efforts to promote health and wellness are truly commendable.</p>
      </div>
      <div *ngIf="selectedclub=='Sports Clubs'">
        <h3>Sports Club</h3>
        <p>The Sports Club of Jigyasa University
          (Formerly Himgiri Zee University) is one of the most active and vibrant clubs on campus. Our club is dedicated
          to
          promoting and encouraging sports and fitness among students, faculty, staff. Our club offers a wide range of
          sports and
          activities to suit every interest and skill level. From football and cricket to badminton and table tennis, we
          provide
          an environment where everyone can find their niche and have fun while staying healthy. We organize various
          sporting events
          throughout the year, including intramural competitions, inter-college tournaments, and friendly matches with
          local sports
          clubs. We also have regular practice sessions and coaching for those who want to improve their skills and
          techniques. At the
          Sports Club, we believe that sports and fitness are essential for maintaining a healthy body and mind. Our
          club members are
          encouraged to participate in physical activities and maintain a healthy lifestyle. We organize regular fitness
          classes, yoga
          sessions, and talks on healthy living to promote overall well-being among our members. Our club is proud to
          have produced some
          of the finest athletes and sports teams in the university. We have won several inter-college competitions in
          various sports and
          proudly represent our university in state and national-level tournaments. The Sports Club of Jigyasa
          University
          (Formerly Himgiri Zee University) provides a
          platform for our students to unleash their talents and passion for sports. Our club is committed to fostering
          a culture of sportsmanship,
          teamwork, and leadership among our members. We believe that sports have the power to bring people together,
          break down barriers, and build
          a strong, healthy community. In conclusion, the Sports Club of Jigyasa University
          (Formerly Himgiri Zee University) strives to provide a nurturing environment for all
          sports enthusiasts and encourages everyone to embrace a healthy and active lifestyle.</p>
      </div>
      <div *ngIf="selectedclub=='Photography Club'">
        <h3>Photography Club</h3>
        <p>The Photography Club of Jigyasa University
          (Formerly Himgiri Zee University) is a community of photography enthusiasts who share a passion for
          capturing life's moments. Our club provides a platform for students who want to explore the world of
          photography
          and improve their skills. We believe that photography is not only an art but also a means of storytelling.
          Whether
          it’s documenting a historical event or capturing a candid moment, the art of photography has the power to
          evoke emotions,
          inspire creativity and create a lasting impact. Our club offers a range of opportunities for students to learn
          and develop
          their photography skills, including workshops, field trips, and discussions. We also host photography
          exhibitions to
          showcase our members' work and inspire others. As part of our club, members learn the technical aspects of
          photography
          such as lighting, composition, and editing. Members also have access to our equipment, and the chance to
          collaborate with
          other artists on projects. Through our photography club, members have the chance to express themselves
          creatively, learn new
          techniques and even launch their careers in photography. Our community shares a passion for photography, and
          we welcome and
          encourage anyone interested in this art form to join us! The Jigyasa University
          (Formerly Himgiri Zee University) Photography Club is a space for growth,
          creativity and exploration, and we can’t wait to see what moments we can capture together.</p>
      </div>
      <div *ngIf="selectedclub=='Media Club'">
        <h3>Press and Media Club</h3>
        <p>The Press and Media Club of Jigyasa University
          (Formerly Himgiri Zee University) is a student-led organization that provides a platform for students
          interested in journalism, broadcasting, and media-related fields. Our club aims to promote the values of free
          speech,
          press freedom, and media literacy. We believe that a strong media informs and shapes public opinion and plays
          a vital
          role in society. Through our club, we strive to create responsible journalists and media professionals who are
          capable
          of addressing the challenges facing the media industry today. The Press and Media Club offers a variety of
          opportunities
          for students to learn about media and journalism. We organize guest lectures, panel discussions, and workshops
          conducted
          by experienced professionals in the field. We also provide internship opportunities with various media
          organizations that
          give students hands-on experience in media production. Our club members have access to modern equipment and
          technology,
          and we encourage them to use it creatively to produce original and meaningful content. Our club features a
          website, the
          university’s news channel, newsletters, and social media platforms, where members can showcase their work and
          reach a wider
          audience. Through the Press and Media Club, students have the chance to hone their communication skills, learn
          from experts
          in the field, and gain valuable experiences that will help them in their future careers. As members of our
          club, students have
          the opportunity to engage in meaningful discourse, and the responsibility to report truthfully, objectively,
          and responsibly.
          The Press and Media Club of Jigyasa University
          (Formerly Himgiri Zee University) serves as a forum for open dialogue, intellectual exploration and creation,
          and we look forward to nurturing a community of responsible and ethical journalists and media professionals
        </p>
      </div>
      <div *ngIf="selectedclub=='Theatre Club'">
        <div class="title">Theatre Club</div>
        <p>
          The Theatre Club of Jigyasa University
          (Formerly Himgiri Zee University) is a vibrant and dynamic group of students who share a passion for the
          performing arts. The club provides a platform for students to explore their creativity, develop their skills,
          and
          showcase their talents in the field of theatre. The club is open to all students of the university who have an
          interest
          in theatre, regardless of their major or prior experience. Members of the club come from diverse backgrounds
          and bring a
          range of talents and perspectives to the group, creating a rich and stimulating environment for artistic
          exploration. The
          Theatre Club of Jigyasa University
          (Formerly Himgiri Zee University) is involved in a wide range of activities, including stage productions,
          workshops,
          and performances. Members of the club work together to develop and stage plays, musicals, and other theatrical
          productions,
          utilizing their skills in acting, directing, stage design, and more. In addition to producing shows, the club
          also hosts
          workshops and training sessions to help members develop their skills and learn new techniques. These sessions
          are led by
          experienced theatre professionals and cover a range of topics, from acting and improvisation to stage design
          and lighting.
          The Theatre Club of Jigyasa University
          (Formerly Himgiri Zee University) is dedicated to promoting and fostering a love of theatre among the student
          body.
          Through its activities and events, the club provides a space for students to express themselves creatively,
          build their confidence,
          and develop important life skills such as teamwork, communication, and leadership. Overall, the Theatre Club
          of Jigyasa University
          (Formerly Himgiri Zee University)
          is a vibrant and active community of students who are passionate about the performing arts. Whether you are a
          seasoned theatre veteran
          or just starting out, the club is a great place to meet like-minded individuals, learn new skills, and explore
          your creativity.</p>
      </div>
    </div>
    <div class="title">Club coordinator</div>
    <div class="row">
      <div class="col-md-4" *ngFor="let data of clubList">
        <div class="common-card">
          <figure *ngFor="let a of data.logofiles">
            <img class=" lazyloaded" src="{{a.value}}" data-src="../../../assets/img/jigyasa/blank-removebg-preview.png"
              alt="{{data.Title}}" title="{{data.Title}}" width="485" height="400">
          </figure>
          <div class="common-card-body">
            <div class="info_content">
              <div class="common-card-body-heads">
                <h3 class="mt-2">{{data.Title}}</h3>
                <span>{{data.short}}</span>
              </div>
              <p class="line-clamp" [innerHTML]="data.Long1"></p>
            </div>
            <div class="readmore d-flex justify-content-end align-items-center">
              <button class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal"
                (click)="showAllDetails(data)"><i class="fa fa-long-arrow-right readmore"></i></button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="clubList.length==0">
        No Record Found!
      </div> -->
      <div class="showMore_content text-center py-4" *ngIf="clubList.length">
        <label for="" class="count_label">Showing {{tindex}} records of {{this.totalrecord}}</label>
        <a class="showMore_btn" *ngIf="more" (click)="setcentrelistclick()">Show More</a>
      </div>

    </div>
  </div>

  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h5 class="modal-title" id="exampleModalLabel"><span class="title">Faculty</span></h5> -->
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-5">
              <figure class="figure" *ngFor="let a of imgFile">
                <img class=" lazyloaded" src="{{a.value}}" data-src="../../../assets/img/jigyasa/blank-removebg-preview.png"
                  alt="{{a.key}}" title="{{a.key}}" width="485" height="400">
              </figure>
            </div>
            <div class="col-md-7">
              <div class="common-card-body-heads">
                <h3 class="mt-2">{{getAllDetails.Title}}</h3>
                <span>{{getAllDetails.short}}</span>
              </div>
              <p [innerHTML]="getAllDetails.Long1"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner type="ball-climbing-dot"></ngx-spinner>