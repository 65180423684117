import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/common/header/header.component';
import { LegancyComponent } from './components/legancy/legancy.component';
import { AdmissionsComponent } from './components/admissions/admissions.component';
import { FranchiseOpportunityComponent } from './components/franchise-opportunity/franchise-opportunity.component';
import { BlogComponent } from './components/blog/blog/blog.component';
import { PentemindComponent } from './components/pentemind/pentemind.component';
import { PlayGroupComponent } from './components/play-group/play-group.component';
import { NurseryComponent } from './components/nursery/nursery.component';
import { KindergartenComponent } from './components/kindergarten/kindergarten.component';
import { TeacherTrainingComponent } from './components/teacher-training/teacher-training.component';
import { DayCareComponent } from './components/day-care/day-care.component';
import { OurApproachComponent } from './components/our-approach/our-approach.component';
import { VisionComponent } from './components/vision/vision.component';
import { AwardsAndRecognitionComponent } from './components/awards-and-recognition/awards-and-recognition.component';
import { LocateUsComponent } from './components/locate-us/locate-us.component';
import { OurPresenceComponent } from './components/our-presence/our-presence.component';
import { PreschoolInAgartalaComponent } from './components/preschool-in-agartala/preschool-in-agartala.component';
import { PartnerLandingComponent } from './components/partner-landing/partner-landing.component';
import { AdmissionThankyouComponent } from './components/thank-you/admission-thankyou/admission-thankyou.component';
import { FranchiseThankyouComponent } from './components/thank-you/franchise-thankyou/franchise-thankyou.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { BlogDetailsComponent } from './components/blog/blog-details/blog-details.component';
import { InnewsComponent } from './components/innews/innews.component';
// import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AcademicsComponent } from './components/academics/academics.component';
import { AllNewsComponent } from './components/all-news/all-news.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { LeadershipComponent } from './components/leadership/leadership.component';
import { AllBlogComponent } from './components/blog/all-blog/all-blog.component';
import { AllFaqsComponent } from './components/all-faqs/all-faqs.component';
import { AllPlacementsComponent } from './components/all-placements/all-placements.component';
import { AllTestimonialComponent } from './components/all-testimonial/all-testimonial.component';
import { AdmissionLPThankyouComponent } from './components/thank-you/admission-lp-thankyou/admission-lp-thankyou.component';
const routes: Routes = [
  // { path: '', component: HomeComponent },
  // { path: 'blog/:name', component: BlogComponent },
  // { path: 'blog', component: BlogDetailsComponent },
  // { path: 'innews', component: InnewsComponent },
  // { path: 'preschool-in/:city', component: PreschoolInAgartalaComponent },
  
  {path:'', component:AdmissionLPComponent},
  {path:'admissionlp', component:AdmissionLPComponent},
 { path: 'thankyou', component: AdmissionLPThankyouComponent },
    
  // {
  //   path: '',
  //   component: HeaderComponent,
  //   children: [
  //     {path:'', component:HomeComponent},
  //     { path: 'pentemind', component: PentemindComponent },
  //     { path: 'about-us', component: AboutUsComponent },
  //     {path: 'research-and-development',component:ResearchAndDevlopementCellComponent},
  //     {path:'rti', component:RtiComponent},
  //     {path: 'intellectual-propperty-right-cell', component:IntellectualPropertyComponent},
  //     {path:'patents-awards',component:PatentsAwardsComponent},
  //     {path:'student-placement', component:PlacementListComponent},
  //     {path:'placement-gallery', component:PlacementGalleryComponent},
  //     {path:'ph-d', component:PhDAwardedListComponent},
  //     {path:'research-updates' ,component:ResearchUpdatesComponent},
  //     {path:'policies-and-notices',component:PoliciesandnoticesComponent},
  //     {path:'nss', component:NSSComponent},
  //     {path:'WhyJU', component:WhyJUComponent},
  //     {path: 'leadership', component:LeadershipComponent},
  //     {path:'alumni', component:AlumniComponent},
  //     {path:'hostel-facilities', component:HostelFacilitiesComponent},
  //     {path:'sports', component:SportsFacilitiesComponent},
  //     {path:'swimming-pool', component:SwimmingPoolComponent},
  //     {path:'medical-facility', component:MedicalFacilityComponent},
  //     {path:'library-facilities', component:LibraryComponent},
  //     {path:'transport-facilities', component: TransportFacilitiesComponent},
  //     {path:'cafeteria', component:CafeteriaComponent},
  //     {path:'gallery', component:GalleryComponent},
  //     {path:'clubs',component:ClubcoordinatorComponent},
  //     {path:'allAlumni',component:AllNotableAlumniComponent},
  //     {path:'allcoordinateAlumni',component:AllCoordinatorsAlumniComponent},
  //     {path:'international-admission',component:InternationalAdmissionComponent},
  //     {path:'iqac',component:IQACComponent},
  //     {path:'whyju',component:WhyJUComponent},
  //     {path:'programme',component:ProgrammeComponent},
  //     {path:'contactus',component:ContactUsComponent},
  //     {path:'flexibility',component:FlexibilityComponent},
  //     {path:'library',component:LibraryComponent},
  //     {path:'hi-zest-center', component:HiZESTCentreComponent},
  //     {path:'students-grievance-redressal', component:StudentsGrievanceRedressalComponent},
  //     {path:'carrer', component:CarrerAtJUComponent},
  //     {path:'placement-statistics', component:PlacementStatisticsComponent},
  //     {path:'trainings-and-placement-cell',component:TrainingandPlacementComponent},
  //     {path:'internship', component:InternshipsJUComponent},
  //     {path:'career-counselling-cell', component:CareerCounsellingCellComponent},
  //     {path:'events/:slug',component:EventsComponent},
  //     {path:'events',component:EventsComponent},//nullable
  //     {path:'news/:slug',component:AllNewsComponent},
  //     {path:'news',component:AllNewsComponent}, //nullable
  //     {path:'event/:Type', component:EventdetailsComponent},
  //     {path:'internshipandplacement',component:InternshipandPlacementComponent},
  //     {path:'internshipandplacement',component:InternshipandPlacementComponent},
  //     {path: 'blogs/:type/:type2/:type3', component: AllBlogComponent},
  //     {path: 'blogs/:type/:type2', component: AllBlogComponent},
  //     {path: 'blogs/:type', component: AllBlogComponent},
  //     {path: 'about-dehradun', component:AboutDehradunComponent},
  //     {path: 'terms-and-conditions', component: TermAndConditionsComponent},
  //     {path: 'refund-policy', component: RefundPolicyComponent},
  //     {path: 'disclaimer', component:DisclaimerComponent},
  //     {path: 'privacy-policy', component: PrivacyPolicyComponent},
  //     {path: 'career', component: CareerComponent},
  //     {path: 'blog/:name', component:BlogDetailsComponent},
  //     {path:'faculty/:type/:type2/:type3',component:AllFacultyComponent},
  //     {path:'faculty/:type/:type2',component:AllFacultyComponent},
  //     {path:'faculty/:type',component:AllFacultyComponent},
  //     {path: 'faqs/:type/:type2/:type3', component:AllFaqsComponent},
  //     {path: 'faqs/:type/:type2', component:AllFaqsComponent},
  //     {path: 'faqs/:type', component:AllFaqsComponent},      
  //     {path: 'placements/:type/:type2/:type3', component:AllPlacementsComponent},
  //     {path: 'placements/:type/:type2', component:AllPlacementsComponent},
  //     {path: 'placements/:type', component:AllPlacementsComponent},    
  //     {path:'testimonial/:type/:type2/:type3', component:AllTestimonialComponent}, 
  //     {path:'testimonial/:type/:type2', component:AllTestimonialComponent},   
  //     {path:'testimonial/:type', component:AllTestimonialComponent},
  //     // school
  //     {path:'school-of-agriculture-forestry-and-fisheries',component:AcademicsComponent},
  //     {path:'school-of-science-and-technology',component:AcademicsComponent},
  //     {path:'school-of-education-and-humanities',component:AcademicsComponent},
  //     {path:'school-of-journalism-and-mass-communication',component:AcademicsComponent},
  //     {path:'school-of-business-studies',component:AcademicsComponent},
  //     {path:'school-of-hotel-and-hospitality-management',component:AcademicsComponent},
  //     {path:'school-of-legal-studies',component:AcademicsComponent},
  //     {path:'school-of-pharmaceutical-sciences',component:AcademicsComponent},
  //     // course 
  //     {path:'school-of-agriculture-forestry-and-fisheries/bsc-hons-agriculture',component:SchoolcourseComponent},
  //     {path:'school-of-agriculture-forestry-and-fisheries/msc-agriculture-agronomy',component:SchoolcourseComponent},
  //     {path:'school-of-agriculture-forestry-and-fisheries/msc-agriculture-genetics-and-plant-breeding',component:SchoolcourseComponent},
  //     {path:'school-of-agriculture-forestry-and-fisheries/phd-genetics-and-plant-breeding',component:SchoolcourseComponent},

  //     {path:'school-of-science-and-technology/btech-cse',component:SchoolcourseComponent},
  //     {path:'school-of-science-and-technology/bca',component:SchoolcourseComponent},
  //     {path:'school-of-science-and-technology/ph-d',component:SchoolcourseComponent},

  //     {path:'school-of-education-and-humanities/b-ed',component:SchoolcourseComponent},
  //     {path:'school-of-education-and-humanities/b-a',component:SchoolcourseComponent},
  //     {path:'school-of-education-and-humanities/m-a',component:SchoolcourseComponent},
  //     {path:'school-of-education-and-humanities/ph-d',component:SchoolcourseComponent},

  //     {path:'school-of-journalism-and-mass-communication/b-a',component:SchoolcourseComponent},
  //     {path:'school-of-journalism-and-mass-communication/m-a',component:SchoolcourseComponent},
  //     {path:'school-of-journalism-and-mass-communication/zicazima',component:SchoolcourseComponent},
  //     {path:'school-of-journalism-and-mass-communication/ph-d',component:SchoolcourseComponent},

  //     {path:'school-of-business-studies/b-com',component:SchoolcourseComponent},
  //     {path:'school-of-business-studies/bba',component:SchoolcourseComponent},
  //     {path:'school-of-business-studies/mba',component:SchoolcourseComponent},
  //     {path:'school-of-business-studies/phd',component:SchoolcourseComponent},

  //     {path:'school-of-hotel-and-hospitality-management/certificate-in-hotel-management-chm',component:SchoolcourseComponent},
  //     {path:'school-of-hotel-and-hospitality-management/diploma-in-hotel-management-dhm',component:SchoolcourseComponent},
  //     {path:'school-of-hotel-and-hospitality-management/bachelor-of-hotel-management-bhm',component:SchoolcourseComponent},
  //     {path:'school-of-hotel-and-hospitality-management/ba-restaurant-management',component:SchoolcourseComponent},

  //     {path:'school-of-legal-studies/ba-llb',component:SchoolcourseComponent},
  //     {path:'school-of-legal-studies/bba-llb',component:SchoolcourseComponent},
  //     {path:'school-of-legal-studies/llb',component:SchoolcourseComponent},
  //     {path:'school-of-legal-studies/ba-llb/llm1-yearcriminal-laws-constitutional-law-and-human-rights-and-corporate-laws',component:SchoolcourseComponent},
  //     {path:'school-of-legal-studies/ba-llb/llm2-yearcriminal-laws-constitutional-law-and-human-rights-and-corporate-laws',component:SchoolcourseComponent},
  //     {path:'school-of-legal-studies/ph-d',component:SchoolcourseComponent},

  //     {path:'school-of-pharmaceutical-sciences/d-pharm',component:SchoolcourseComponent},
  //     {path:'school-of-pharmaceutical-sciences/b-pharm',component:SchoolcourseComponent},
  //     {path:'school-of-pharmaceutical-sciences/b-pharm-lateral',component:SchoolcourseComponent},
  //     {path:'school-of-pharmaceutical-sciences/bsc-clinical-research',component:SchoolcourseComponent},
  //     {path:'school-of-pharmaceutical-sciences/msc-clinical-research',component:SchoolcourseComponent},
  //     {path:'school-of-pharmaceutical-sciences/master-in-public-health',component:SchoolcourseComponent},
  //     {path:'school-of-pharmaceutical-sciences/mba-health-care-and-hospital-management',component:SchoolcourseComponent},
  //     {path:'school-of-pharmaceutical-sciences/ph-d',component:SchoolcourseComponent},
      
  //     { path: '**', pathMatch: 'full', component: PagenotfoundComponent }
  //   ],
    
  // },

];

//{path:':school',component:AcademicsComponent},
      //{path:':school/:course',component:SchoolcourseComponent},
      //{path:':school/:course/:speciality',component:CoursespecialityComponent},

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
import { UrlSegment, UrlSegmentGroup, Route } from '@angular/router';
import { SchoolcourseComponent } from './components/schoolcourse/schoolcourse.component';
import { CoursespecialityComponent } from './components/coursespeciality/coursespeciality.component';
import { AllFacultyComponent } from './components/all-faculty/all-faculty.component';
import { AboutDehradunComponent } from './components/quickLink/about-dehradun/about-dehradun.component';
import { TermAndConditionsComponent } from './components/quickLink/term-and-conditions/term-and-conditions.component';
import { RefundPolicyComponent } from './components/quickLink/refund-policy/refund-policy.component';
import { DisclaimerComponent } from './components/quickLink/disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from './components/quickLink/privacy-policy/privacy-policy.component';
import { CareerComponent } from './components/quickLink/career/career.component';
import { ResearchAndDevlopementCellComponent } from './components/research-and-devlopement-cell/research-and-devlopement-cell.component';
import { IntellectualPropertyComponent } from './components/intellectual-property/intellectual-property.component';
import { PhDAwardedListComponent } from './components/ph-dawarded-list/ph-dawarded-list.component';
import { ResearchUpdatesComponent } from './components/research-updates/research-updates.component';
import { EventsComponent } from './components/events/events.component';
import { EventdetailsComponent } from './components/eventdetails/eventdetails.component';
import { PoliciesandnoticesComponent } from './components/policiesandnotices/policiesandnotices.component';
import { PatentsAwardsComponent } from './components/patents-awards/patents-awards.component';
import { TrainingandPlacementComponent } from './components/trainingand-placement/trainingand-placement.component';
import { InternshipsJUComponent } from './components/internships-ju/internships-ju.component';
import { PlacementStatisticsComponent } from './components/placement-statistics/placement-statistics.component';
import { CareerCounsellingCellComponent } from './components/career-counselling-cell/career-counselling-cell.component';
import { AlumniComponent } from './components/alumni/alumni.component';
import { AllNotableAlumniComponent } from './components/all-notable-alumni/all-notable-alumni.component';
import { HostelFacilitiesComponent } from './components/hostel-facilities/hostel-facilities.component';
import { CarrerAtJUComponent } from './components/carrer-at-ju/carrer-at-ju.component';
import { SportsFacilitiesComponent } from './components/sports-facilities/sports-facilities.component';
import { SwimmingPoolComponent } from './components/swimming-pool/swimming-pool.component';
import { MedicalFacilityComponent } from './components/medical-facility/medical-facility.component';
import { StudentsGrievanceRedressalComponent } from './components/students-grievance-redressal/students-grievance-redressal.component';
// import { LibraryFacilitiesComponent } from './components/library-facilities/library-facilities.component';
import { TransportFacilitiesComponent } from './components/transport-facilities/transport-facilities.component';
import { CafeteriaComponent } from './components/cafeteria/cafeteria.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { AllCoordinatorsAlumniComponent } from './components/all-coordinators-alumni/all-coordinators-alumni.component';
import { IQACComponent } from './components/iqac/iqac.component';
import { ProgrammeComponent } from './components/programme/programme.component';
import { InternationalAdmissionComponent } from './components/international-admission/international-admission.component';
import { HiZESTCentreComponent } from './components/hi-zest-centre/hi-zest-centre.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FlexibilityComponent } from './components/flexibility/flexibility.component';
import { LibraryComponent } from './components/library/library.component';
import { ClubcoordinatorComponent } from './components/clubcoordinator/clubcoordinator.component';
import { WhyJUComponent } from './components/why-ju/why-ju.component';
import { LibraryFacilitiesComponent } from './components/library-facilities/library-facilities.component';
import { InternshipandPlacementComponent } from './components/internshipand-placement/internshipand-placement.component';
import { PlacementListComponent } from './components/placement-list/placement-list.component';
import { NSSComponent } from './components/nss/nss.component';
import { PlacementGalleryComponent } from './components/placement-gallery/placement-gallery.component';
import { AdmissionLPComponent } from './components/landingPage/admission-lp/admission-lp.component';
import { RtiComponent } from './rti/rti.component';



export function ComplexUrlMatcher(paramName: string, regex: RegExp) {
  return function (
    segments: UrlSegment[],
    segmentGroup: UrlSegmentGroup,
    route: Route)  {
    const parts = [regex];
    const posParams: { [key: string]: UrlSegment } = {};
    const consumed: UrlSegment[] = [];

    let currentIndex = 0;

    for (let i = 0; i < parts.length; ++i) {
      if (currentIndex >= segments.length) {
        return null;
      }
      const current = segments[currentIndex];

      const part = parts[i];
      if (!part.test(current.path)) {
        return null;
      }

      posParams[paramName] = current;
      consumed.push(current);
      currentIndex++;
    }

    if (route.pathMatch === 'full' &&
      (segmentGroup.hasChildren() || currentIndex < segments.length)) {
      return null;
    }

    return { consumed, posParams };
  }

}

