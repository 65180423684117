<section>
    <div>
        <div>
            <div class="section">

                <div class="subMenu_content">
                    <app-sub-menu></app-sub-menu>
                </div>
                <div class="sub_content">
                    <section id="OverviewOfRAndDCell">
                        <div class="overview">
                            <div class="title">NSS</div>
                            <div class="para">
                                <p class="mt-3">
                                    National Service Scheme (NSS) was introduced in 1969 with the primary objective
                                    of developing the personality and character of the student youth through voluntary
                                    community service. &#39;Education through Service&#39; is the purpose of the NSS.
                                    The
                                    motto of NSS is &quot;Not me but you&quot;, It underlines that the welfare of an
                                    individual
                                    ultimately depend on the welfare of society as a whole. The ideological orientation
                                    of the NSS is inspired by the ideals of Mahatma Gandhi. The NSS (National
                                    Service Scheme) operates under the Ministry of Youth Affairs and Sports of the
                                    Government of India. It aims to inculcate the spirit of social service and
                                    volunteerism among students, encouraging them to actively contribute to the
                                    welfare of society.
                                </p>
                            </div>
                        </div>
                    </section>
                    <!-- <section>
                        <div class="row pb-5">
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../assets/img/jigyasa/gallery1.jpg">
                                        <img src="../../../assets/img/jigyasa/gallery1.jpg" width="100%" height="200px"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../assets/img/jigyasa/gallery2.jpg">
                                        <img src="../../../assets/img/jigyasa/gallery2.jpg" width="100%" height="200px"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../assets/img/jigyasa/gallery3.jpg">
                                        <img src="../../../assets/img/jigyasa/gallery3.jpg" width="100%" height="200px"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../assets/img/jigyasa/gallery4.jpg">
                                        <img src="../../../assets/img/jigyasa/gallery4.jpg" width="100%" height="200px"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../assets/img/jigyasa/gallery5.jpeg">
                                        <img src="../../../assets/img/jigyasa/gallery5.jpeg" width="100%" height="200px"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../assets/img/jigyasa/gallery6.jpeg">
                                        <img src="../../../assets/img/jigyasa/gallery6.jpeg" width="100%" height="200px"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../assets/img/jigyasa/gallery7.jpeg">
                                        <img src="../../../assets/img/jigyasa/gallery7.jpeg" width="100%" height="200px"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="../../../assets/img/jigyasa/gallery8.jpeg">
                                        <img src="../../../assets/img/jigyasa/gallery8.jpeg" width="100%" height="200px"
                                            alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section> -->
                </div>
            </div>

        </div>
    </div>
</section>