<section>
    <div class="subMenu_content">
      <app-sub-menu></app-sub-menu>
    </div>
    <div class="main_div sub_content pb-5">
        <div class="title">Internships & Placements</div>
        <div class="row">
            <div class="col-md-12">
                <div class="listSearchArea">
                    <ul class="nav nav-tabs ul_style tabs-buttons" role="tablist">
                      <li class="nav-item" role="presentation" id="boardOfGovernorsTab0">
                        <button id="click_btn" class="nav-link active" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0"
                          type="button" role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                          (click)="filtercategory('Welcome')">Welcome</button>
                      </li>
                      <li class="nav-item" role="presentation" id="boardOfGovernorsTab1">
                        <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                          role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                          (click)="filtercategory('Internship')">Internship</button>
                      </li>
                      <li class="nav-item" role="presentation" id="boardOfGovernorsTab2">
                        <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                          role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                          (click)="filtercategory('Placements')">Placements</button>
                      </li>
                      <li class="nav-item" role="presentation" id="boardOfGovernorsTab3">
                        <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                          role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                          (click)="filtercategory('Our Recruiters')">Our Recruiters</button>
                      </li>
                      <li class="nav-item" role="presentation" id="boardOfGovernorsTab4">
                        <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                          role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                          (click)="filtercategory('JU ZEST- Centre for Skill Enhancement')">JU ZEST- Centre for Skill Enhancement</button>
                      </li>
                      <li class="nav-item" role="presentation" id="boardOfGovernorsTab5">
                        <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                          role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                          (click)="filtercategory('Career Counselling Cell')">Career Counselling Cell</button>
                      </li>
                      <li class="nav-item" role="presentation" id="boardOfGovernorsTab6">
                        <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                          role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                          (click)="filtercategory('Photo Gallery')">Photo Gallery</button>
                      </li>
                      <li class="nav-item" role="presentation" id="boardOfGovernorsTab7">
                        <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                          role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                          (click)="filtercategory('Internship & Placement Team')">Internship & Placement Team</button>
                      </li>
                    </ul>
                  </div>
                  <hr>
            </div>
            <div class="col-md-12">
 <div *ngIf="selectedclub=='Welcome'">
           <p>Dear Recruiter</p>
           <p>Greetings from Jigyasa University</p>
           <p>Jigyasa University was founded under the aegis of Essel Group (popularly known as Zee Group) as Himgiri Zee University in 2003.</p>
           <p>The name was changed to Jigyasa University to foster the spirit of Curiosity in a child so that He/ She can develop to what they want to be in life. We believe that every child born has special skills and should be groomed and supported to develop their intrinsic capabilities. We at Jigyasa University are committed to do the same.</p>
           <p>We offer the following courses for the students to build their careers:</p>
           <p>(Hyperlink for courses being offered)</p>
           <p>We can engage with the corporates in several ways:</p>
           <div class="para">
            <ol class="content_ul">
                	<li>Full Semester Internship Program starting from January for all courses (from July for B Ed)</li>
                    <li>Placements of students</li>
                    <li>Academic Alliances for Training purposes</li>
                    <li>Guest Lectures and Career Counselling Sessions</li>
                    <li>Consultancy</li>
                    <li>Management Development Programs</li> 
                <p>We will be looking forward to collaborating with you on all the above aspects.</p>
                <div class="content_details">
                    <label for=""><b><i>Jasjit Singh</i></b></label><br>
                    <label for=""><b>Director Placements</b></label><br>
                    <label for="">Mobile No: 9501664852</label><br>
                    <label for="">Email: <a href="director.placement@hzu.edu.in">director.placement@hzu.edu.in</a></label><br>
                    <label for="">LinkedIn: <a href="www.linkedin.com/in/jasjeetsngh">www.linkedin.com/in/jasjeetsngh</a></label>
                </div>
            </ol>
           </div>
  
        </div>
        <div *ngIf="selectedclub=='Internship'">
        <div class="content_page">
            <app-internships-ju [ishide]="1"></app-internships-ju>
        </div>
        </div>
        <div *ngIf="selectedclub=='Placements'">
            <div class="content_page">
                <app-placement-statistics [ishide]="1"></app-placement-statistics>
            </div>
        </div>
        <div *ngIf="selectedclub=='Our Recruiters'">
            <div class="content_page">
                <app-all-placements [ishide]="1"></app-all-placements>
            </div>
        </div>
        <div *ngIf="selectedclub=='JU ZEST- Centre for Skill Enhancement'">
          <p>The Centre for Skill Enhancement is the key factor ensuring the enhancement of skills of the students to make them employable.</p>
          <p>Salient Features:</p>
          <ul>
              <li>Our training program is a blend of lectures by expert and specialised faculty, guest lectures from industry, corporate and academia, workshops and activities.</li>
              <li>Our focus is on the holistic personality development.</li>
              <li>We work strenuously to enhance confidence and self-esteem, positive attitude, team-building and decision-making.</li>
              <li>Activities such as presentations, role-plays, group-discussions, debates, mock-interviews, group-activities, business games are part of our methodology.</li>
              <li>Our courses provide training for the reasoning and aptitude.</li>
              <li>Our team of experts provide guidance to prepare students for the competitive examinations.</li>

          </ul>
        </div>
        <div *ngIf="selectedclub=='Career Counselling Cell'">
          <div class="content_page">
            <app-career-counselling-cell [ishide]="1"></app-career-counselling-cell>
        </div>
          
        </div>
        <div *ngIf="selectedclub=='Photo Gallery'">
          <div class="content_page">
        <app-placement-gallery [ishide]="1" [category]="'Placement'"></app-placement-gallery>
      </div>
        </div>
        <div *ngIf="selectedclub=='Internship & Placement Team'">
           <app-internshipand-placement [ishide]="1"></app-internshipand-placement>
          </div>
            </div>
        </div>
      
      <div>
       
      </div>
    </div>

  </section>
  <ngx-spinner type="ball-climbing-dot"></ngx-spinner>

