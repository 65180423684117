<div class="section">
    <!-- <header class="header" id="scroll_header">
        <div class="menu_list">
            <ul>
                <li><a href="researchanddevelopment#OverviewOfRAndDCell"> Overview of R&D cell </a></li>
                <li><a href="researchanddevelopment#goalsAndobjective">Major Goals/Objective</a></li>
                <li><a href="researchanddevelopment#aboutDean">About Dean Research</a></li>
                <li><a href="researchanddevelopment#TeamRandD">Team R&D cell</a></li>
            </ul>
        </div>
    </header> -->
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="title">Canteen</div>
                <div class="para">
                    <p>The JU cafeteria is designed to create a comfortable environment where you can discuss ideas while you enjoy a good meal. The larger mess hall is equipped to serve lunch and dinner for all students. We also serve variety of snacks along with North and South Indian dishes at affordable prices.</p>
                </div>
            </div>
        </section>
        <section>
             <div class="row pb-5">
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/canteen1.jpg">
                        <img src="../../../assets/img/jigyasa/canteen1.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/canteen2.jpg">
                        <img src="../../../assets/img/jigyasa/canteen2.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/canteen3.jpg">
                        <img src="../../../assets/img/jigyasa/canteen3.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>