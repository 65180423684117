import { Component,Input,OnInit} from '@angular/core';
import { CommonService } from '../service/common.service';
import { environment } from 'src/environments/environment';
import { json } from 'express';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { ApicallService } from 'src/app/services/apicall.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
@Component({
  selector: 'app-placement-statistics',
  templateUrl: './placement-statistics.component.html',
  styleUrls: ['./placement-statistics.component.css']
})
export class PlacementStatisticsComponent implements OnInit{
  @Input() ishide!:number;
  project_id = environment.projectid
  careerPlacementList: any=[];
  filterPlacementList:any=[];
  topachievers:any=[];
  someachievers:any=[];
  classParam:any;
  alldata: any;
  Announcementdata: any;
  pgdata: any;
constructor(private _service: CommonService,
  private activatedRoute: ActivatedRoute,
    private _ApicallService: ApicallService,
    private projectService: ProjectSeoService,
){

}

ngOnInit(): void {
  this.getseo();
}

getseo() {
  let tbody = {
    slug:'placement-statistics',
    };
  this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
    this.alldata=data?.data;
      this.pgdata=data?.data?.pagedata;  
      this.projectService.sendMessageblog(data?.data?.Blog);
      this.projectService.sendMessagenews(data?.data?.News)
      this.projectService.setFacultysubject(data?.data?.Faculty);
      this.projectService.setPlacementsubject(data?.data?.Placement);      
      this.projectService.setprogramssubject(data?.data?.Programs);//
      this.projectService.sendMessageseo(data?.data?.Testimonial);
      this.projectService.sendMessageFaqs(data?.data?.FAQs);
      this.projectService.setmeta(data?.data);
      this.projectService.sendMessagebread(data?.data.breadcrumb)
      
      this.getLidershipData();
  });
}

getLidershipData() {
  let obj = {
    "Project_Id": this.project_id,
    "Type": "Career Placement"   
  }
  this._service.getContentbytype(obj).subscribe(
    res => {
      this.careerPlacementList = JSON.parse(res.data[0].contentData);
      this.assigndata();
  })
}

assigndata(){
  this.topachievers = this.careerPlacementList.filter((data:any)=>{
    return data.category.includes(35) //top achievers
  }).map((obj:any)=>{
    return obj
  });
  this.someachievers=this.careerPlacementList.filter((data:any)=>{
    return data.category.includes(36) ///some of our achievers 
  }).map((obj:any)=>{
    return obj
  });
}
  
}
