import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';
import { CommonService } from '../service/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-all-testimonial',
  templateUrl: './all-testimonial.component.html',
  styleUrls: ['./all-testimonial.component.css']
})
export class AllTestimonialComponent implements OnInit {
  project_id = environment.projectid
  getAlldata: any;
  getAllcontentData: any;
  getAssignCategory: any;
  filterCatogryList: any;
  getResponceData: any;
  totalrecord:number=0;
  findex:number=0;
  tindex:number=20;
  more:boolean=true;
  title:string="";
  secondtitle:string="";
  thirdtitle:string="";
  getAllDetails: any=[];
  imgFile: any;
  alldata: any;
  pagedata: any;
  constructor(private _commonService: CommonService,private route: ActivatedRoute, 
    private projectService: ProjectSeoService,private _ApicallService: ApicallService,){

  }
  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {      
      this.title = params['type'];    
      this.route.params.subscribe((params: any) => { 
        this.secondtitle = params['type2'];
        this.route.params.subscribe((params: any) => {    
          this.thirdtitle = params['type3'];      
        })
      })            
    })
    AOS.init();
    this.getslug();
  }

  getseo() {
    let tbody = {
      slug:'testimonial',
    };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {  
      this.alldata = data?.data;
      this.pagedata = data?.data?.pagedata;
      this.projectService.sendMessageblog(data?.data?.Blog);
      this.projectService.sendMessagenews(data?.data?.News)
      this.projectService.setFacultysubject(data?.data?.Faculty);
      this.projectService.setPlacementsubject(data?.data?.Placement);
      this.projectService.setprogramssubject(data?.data?.Programs);//
      this.projectService.sendMessageseo(data?.data?.Testimonial);
      this.projectService.sendMessageFaqs(data?.data?.FAQs);
      this.projectService.setmeta(data?.data);
      this.projectService.sendMessagebread(data?.data.breadcrumb)
    });
  }

  getslug(){
    this.getseo();
    let slug;
    if(this.thirdtitle!=undefined){
      slug=this.title+'/'+this.secondtitle+'/'+this.thirdtitle;
    }
    else if (this.secondtitle!=undefined){
      slug=this.title+'/'+this.secondtitle;
    }
    else{
      slug=this.title;
    }
    this.getAllData(slug); 
  }

  getAllData(slug:string){
    let obj = {
      "Project_Id": this.project_id,
      "Type": "testimonial",
      "pageurl":slug
    }
this._commonService.getContentbytype(obj).subscribe(
  (res:any)=>{
    

this.getResponceData = JSON.parse(res.data[0].contentData)
this.getAllcontentData = JSON.parse(res.data[0].contentData)
this.getAssignCategory = JSON.parse(res.data[0].AssignCategory)
this.setcentrelist();

  }
)
  }

  filtercategory(id:number){
    if(id!=0){
      this.getAllcontentData=this.getResponceData.filter((dt:any)=>{
        return dt.category.includes(id)
      }).map((obj:any)=>{
        return obj;
      })
    }
    else{
      this.getAllcontentData=this.getResponceData;
      }
    
  }

  fn_ClientFilter(event: any) {
    let val = event.target.value.toLowerCase();
    if (!val) {
      this.getAllcontentData = this.getResponceData;
    }
    this.getAllcontentData = this.getResponceData.filter(function (dt: any): any {
      if (
        dt?.Title?.toString().toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        // found match, return true to add to result set
        return true;
      }
      else if (dt.slug) {
        if (dt?.slug?.toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          // found match, return true to add to result set
          return true;
        }
      }      
    })
  }

  setcentrelist()
  {
    let dlenth=this.getResponceData.length;
    this.totalrecord=dlenth;
    if(dlenth>20)
    {
      this.tindex=20;
    }
    else
    {
      this.tindex=dlenth;
      this.more=false;
    }
  
    this.getAllcontentData=this.getResponceData.slice(this.findex,this.tindex);
    
  }
  
    setcentrelistclick()
  {
    let dlenth=this.getResponceData.length;
    let nindex=this.tindex+20;
  
    if( dlenth> this.tindex)
    {
  
  
      if(nindex>dlenth)
      {
         this.tindex=dlenth;
          this.more=false;
      }
      else
      {
      this.tindex += 20;
      }
    }
    else
    {
  
    this.more=false;
    }
  
    this.getAllcontentData=this.getResponceData.slice(this.findex,this.tindex);
    //this.centerList=data;
  }

  showAllDetails(data:any){
    this.getAllDetails = data
    this.imgFile = this.getAllDetails.OtherFiles
  }
}
