import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { ApicallService } from 'src/app/services/apicall.service';
import { environment } from 'src/environments/environment';
import { ProjectSeoService } from 'src/app/services/projectseo.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit{
  classParam:any;
  alldata: any;
  Announcementdata: any;
  pgdata: any;
  constructor(private activatedRoute: ActivatedRoute,
    private _ApicallService: ApicallService,
    private projectService: ProjectSeoService,
  ){
  }

  ngOnInit(): void {
    this.getseo();
  }

  getseo() {
    let tbody = {
      slug:'career',
      };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
      this.alldata=data?.data;
        this.pgdata=data?.data?.pagedata;  
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);      
        this.projectService.setprogramssubject(data?.data?.Programs);
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
        this.projectService.sendMessagebread(data?.data.breadcrumb)
    });
  }
}
