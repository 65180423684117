import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';

@Component({
  selector: 'app-carrer-at-ju',
  templateUrl: './carrer-at-ju.component.html',
  styleUrls: ['./carrer-at-ju.component.css']
})
export class CarrerAtJUComponent implements OnInit{
  alldata: any;
  pgdata: any;
  
  
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,
    private _ApicallService: ApicallService,
  ) {

  }

  ngOnInit(): void {
    this.getseo();
  }



  getseo() {
    let tbody = {
      slug:'career-at-ju',
      };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
      this.alldata=data?.data;
        this.pgdata=data?.data?.pagedata;  
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);      
        this.projectService.setprogramssubject(data?.data?.Programs);//
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
        this.projectService.sendMessagebread(data?.data.breadcrumb)
    });
  }

}

