
<section>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <div class="title">RTI</div>
        <div class="main_content_para my-4">
            <div class="para">
            <div class="list text-center mt-3 d-flex justify-content-center align-items-center" *ngFor="let dt of noticeList">
                <h6 class="mt-2">{{dt.Title}}</h6>
                
                <span>
                    <a href="{{dt.slug}}" target="_blank">click here</a>
                </span> 
                <!-- <span *ngFor="let data of dt.logofiles">
                    <a href="{{data.value}}" target="_blank">click here</a>
                </span>  
                <span *ngFor="let data of dt.OtherFiles">
                    <a href="{{data.value}}" target="_blank">click here</a>
                </span>   -->
            </div>
                <!-- <table>
                    <thead>
                </thead>
                <tbody>
                    <tr *ngFor="let dt of noticeList">
                        <td class="d-flex">
                            {{dt.Title}}&nbsp;&nbsp;
                            <div *ngFor="let data of dt.logofiles">
                                <a href="{{data.value}}" target="_blank">click here</a>
                            </div> 
                            <div *ngFor="let data of dt.OtherFiles">
                                <a href="{{data.value}}" target="_blank">click here</a>
                            </div>                           
                        </td> 
                    </tr>
                </tbody>
                </table> -->
            </div>
        </div>
    </div>
</section>
