<div class="section">
    <div class="subMenu_content">
     <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="title">Career@JU</div>
                <div class="para">
                    <p>Thank you for visiting our Career Page. Interested candidates need to share their resume at <a href="hr@hzu.edu.in" target="_blank">hr@hzu.edu.in</a> along with duly filled JOB APPLICATION FORM. Shortlisted candidates will be contacted by our HR.</p>
                 <p>Download the <b>JOB APPLICATION FORM :</b><a href="https://www.hzu.edu.in/uploads/2021/2/Application-Form.pdf" target="_blank"> Click here for Application Form</a></p>
                 <p><b>CURRENT OPENINGS :</b><a href="/carrer" target="_blank"> Click here for Current Openings</a> </p>
                </div>
            </div>
        </section>

    </div>
</div>