<section>
    <div class="subMenu_content" *ngIf="ishide!=1">
        <app-sub-menu></app-sub-menu>
    </div>
    <div [ngClass]="ishide==1 ? '' :'sub_content'">
        <div class="title" *ngIf="ishide!=1">Career Counselling Cell</div>
        <div class="main_content_para my-4">
            <p>A dedicated Career Counselling Team is consistently working to guide and coach 
                the students in their queries and doubts n their career goals.</p>
               <p>Salient Features</p>
            <div class="para">
                <ul>
                      <li>Individual and personalized counselling to all the students.</li>
                  <li>Help them knowing themselves i. e. their interests, attitudes, aptitudes, strengths, limitations etc.</li>
                  <li>Guiding them regarding the preparation of an effective CV and Cover Letter.</li>
                  <li>Identifying the career path/role/opportunities.</li>
                  <li>Enable them to understand and resolve their personal issues and help them to grow as an individual.</li>
                  <li>Assisting the students to find the best job according to their skills and aspirations.</li>
                  <li>Enabling them to formulate a set of attainable goals and a plan of action.</li>
                  <li>Motivating them to attempt positive transformation to make their life healthier and happier.</li>

                </ul>
            </div>
            <h5 class="mt-5">Members of Career Counselling Cell</h5>
        </div>
        <div class="row mt-4 py-4">
            <div class="col-md-6" *ngFor="let data of getcareer_counselling_cell">
                <div class="facultyWrapperItem">
                    <div class="faculty-banner">
                        <div class="row">
                            <div class="col-md-5 d-flex justify-content-center align-items-center">
                                <div *ngFor="let img of data.logofiles">
                                    <img src="{{img.value}}" class="img-responsive">
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="profile">
                                    <h3>{{data.Title}}</h3>
                                    <p>{{data.short}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label class="lable_title">Work Experience :</label>
                    <div class="facultyContentWrapper_para">
                        <p [innerHTML]="data.Long1"></p>
                    </div>
                </div>
            </div>

            <!-- <div class="col-md-6">
                <div class="facultyWrapperItem">
                    <div class="faculty-banner">
                        <div class="row">
                            <div class="col-md-5">
                                <img src="../../../assets/img/jigyasa/NidhiChaudhary.png"
                                    class="img-responsive">
                            </div>
                            <div class="col-md-7">
                                <div class="profile">
                                    <h3>Mrs. Nidhi Chaudhary</h3>
                                    <p>Member & Assistant Professor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label class="lable_title">Work Experience :</label>
                    <div class="facultyContentWrapper_para">
                        <p>Having more than seven years of experience in teaching and research along with Gold Medal in M. Pharm awarded by UTU, Mrs. Nidhi Chaudhary, has fine exposure to guide and mentoring the students through providing them best suggestions. She is experienced in conducting career counseling sessions through establishing appropriate rapport with the students.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 mt-4">
                <div class="facultyWrapperItem">
                    <div class="faculty-banner">
                        <div class="row">
                            <div class="col-md-5">
                                <img src="../../../assets/img/jigyasa/Anjana-Dobhal.png"
                                    class="img-responsive">
                            </div>
                            <div class="col-md-7">
                                <div class="profile">
                                    <h3>Ms. Anjana Dobhal</h3>
                                    <p>Member & Assistant Professor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label class="lable_title">Work Experience :</label>
                    <div class="facultyContentWrapper_para">
                        <p>Ms. Anjana Dobhal has more than twelve years of experience in the field of teacher’s training. She helps students regarding the career opportunities through the conduction of several career-counseling sessions in the University as well in neighboring organizations. Ms. Anjana is actively involved in providing the Guidance and Counseling to the students through various techniques.
                        </p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>