<section>
    <div class="banner">
    <div class="col-md-12">
        <div class="mx-auto my-auto justify-content-center">
          <div id="recipeCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner" role="listbox">
                <div class="carousel-item active mt-2" *ngFor="let data  of academindata;let i=index">
                    <div>
                       <a href="https://apply.jigyasauniversity.com/" target="_blank"><img src="{{data?.media_url}}" width="100%" loading="lazy" class="d-block slider_img responsive" alt="..."></a>
                    </div>
                </div>              
            </div>
          </div>
        </div>
    </div>
   
    </div>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="maindiv">
        <div *ngFor="let data of academindata;let i=index">
            <div>
                <h1 class="">{{data.name}}</h1>
                <div [innerHTML]="data.Description"></div>
                <div *ngIf="data.name=='USP'">                    
                    
                </div>
            </div>
            <div *ngFor="let dt of data.description" class="">
                <div *ngIf="dt.Type=='School' && dt.Contenttype=='Descriptive'">                    
                    <div *ngIf="dt.Title!='Unmatched Global Opportunities'">
                        <h5 class="mt-2">{{dt.Title}}</h5>
                        <div  class="description">
                            <div class="" [innerHTML]="dt.content"></div>
                        </div>
                    </div>
                    <div *ngIf="dt.Title=='Unmatched Global Opportunities'" class="description ul_style">
                        <div class="row">
                            <div class="col-md-3 d-flex justify-content-center align-items-center">
                                <div class="title mt-2">{{dt.Title}}</div>
                            </div>
                            <div class="col-md-9">
                                <div class="" [innerHTML]="dt.content"></div>
                            </div>
                        </div>
                      
                    </div>
                    <mat-accordion *ngIf="dt.level2">
                                <mat-expansion-panel *ngFor="let dt1 of dt.level2">                    
                                        <mat-expansion-panel-header>
                                            <h5 class="">{{dt1.Title}}</h5>                  
                                        </mat-expansion-panel-header>
                                        <div class="description">
                                            <span class="" [innerHTML]="dt1.content"></span>  
                                        </div>
                                        <ul class="list-group list-group-horizontal">
                                            <li class="list-group-item" *ngFor="let d of dt1.level3;let i=index" value="{{d.Title}}">
                                                <span (click)="showhide(d.content)">
                                                    <h5 class="mt-2">{{d.Title}}</h5>
                                                </span>                                                
                                            </li>
                                        </ul>  
                                        <div class="description">
                                        <p class="" [innerHTML]="leveldesc"></p>      
                                    </div>          
                                </mat-expansion-panel>
                    </mat-accordion>
                </div> 
                <div>
                                  
                    <div *ngIf="dt.content=='Programs' && dt.Contenttype=='Component' && this.alldata?.Programs[0]?.data">
                        <h3>Programs</h3>
                        <app-commonprograms [slug]="Title" [slugdata]="this.alldata?.Programs"></app-commonprograms> 
                    </div>
                    <div *ngIf="dt.content=='Testimonial' && dt.Contenttype=='Component' && this.alldata?.Testimonial[0]?.data">
                        <app-testimonial [Testimonialdata]="this.alldata?.Testimonial" [fromslug]="Title"></app-testimonial>
                    </div>
                    <div *ngIf="dt.content=='FAQs' && dt.Contenttype=='Component' && this.alldata?.FAQs[0]?.data">
                        <!-- <h3>FAQs</h3> -->
                        <app-faqs [faqdata]="this.alldata?.FAQs" [fromslug]="Title"></app-faqs>
                    </div>  
                    <div *ngIf="dt.content=='Faculty' && dt.Contenttype=='Component' && this.alldata?.Faculty[0]?.data">
                        <!-- <h3>Faculty</h3> -->
                        <app-faculty [facultydata]="this.alldata?.Faculty" [fromslug]="Title"></app-faculty>
                    </div>
                    
                    
                    <!-- <div *ngIf="dt.Title=='Placement' && dt.Contenttype=='Component'"> -->
                        <!-- <h3>Placement</h3> -->
                        <div *ngIf="dt.content=='Placement' && dt.Contenttype=='Component' && this.alldata?.Placement[0]?.data">
                            <app-placements [Placementdata]="this.alldata?.Placement" [fromslug]="Title"></app-placements>
                        </div>
                        <div *ngIf="dt.content=='Placement with stat' && dt.Contenttype=='Component' && this.alldata?.Placement[0]?.data">
                            <app-placement-company [Placementdata]="this.alldata?.Placement" [fromslug]="Title"></app-placement-company>
                        </div>                        
                    <div *ngIf="dt.content=='Blog' && dt.Contenttype=='Component' && this.alldata?.Blog[0]?.data">
                        <app-blogs [blogdata]="this.alldata?.Blog" [fromslug]="Title"></app-blogs>
                    </div>      
                    <div *ngIf="dt.content=='Events' && dt.Contenttype=='Component' && this.alldata?.Events[0]?.data">
                        <!-- <h3>Faculty</h3> -->
                        <app-eventcommon [eventdata]="this.alldata?.Events" [fromslug]="Title"></app-eventcommon>
                    </div>  
                    <div *ngIf="dt.content=='Career Placement' && dt.Contenttype=='Component' && this.alldata?.careerplacement[0]?.data">
                        <!-- <h3>Faculty</h3> -->
                        <app-careerplacementcommon [careerplacementdata]="this.alldata?.careerplacement" [fromslug]="Title"></app-careerplacementcommon>
                    </div> 
                </div> 
            </div>
        </div>
    </div>
</section>