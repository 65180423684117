<section>
  <div class="main_page">
    <div class="column-2">
      <img alt=""
        loading="lazy"
        src="../../../assets/img/pageNotFOund.svg"
        class="img-2"
      />
    </div>
    <div class="div-7 mt-5 text-center">
      <div>Can’t find the page your’re looking for.</div>
      <!-- <button class="submit_btn" routerLink="">Go To HomePage</button> -->
      <div _ngcontent-ng-c668844952="" class="d-flex justify-content-center"><button class="btn view_sm_btn my-4" tabindex="0" routerLink="">Go To HomePage</button></div>
    </div>
  </div>
  </section>

  <app-footer></app-footer>
