import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../service/common.service'
//import Master_State_City from '../../JSON/state_city.json';
import * as Master_State_City from '../../JSON/state_city.json';
import { JsonFileService } from '../../service/json-file.service';
import { ActivatedRoute, Params ,Router} from '@angular/router';

@Component({
   selector: 'app-admission-lp',
   templateUrl: './admission-lp.component.html',
   styleUrls: ['./admission-lp.component.css']
})
export class AdmissionLPComponent implements OnInit {
   utm_source: string = "Website";
   utm_medium: string = "Website";
   loader:boolean=false;
   utm_compaign: string = "Website";
   gclid: string = "Website";
   utm_term: string = "Website";
   utm_content: string = "Website";
   utm_ad: string = "Website";
   admissionForm: FormGroup;
   specializationList: any = []
   courseDataList: any = []
   course_list: any = []
   state_data: any = []
   isChecked:any;
   stateList: any;
   submitted = false;
   gen_captcha: any;
   captchaText: any = [];
   cityList: any = [];
   text_captcha:any;
   customOptions: any = {
      loop: true,
      margin: 10,
      nav: true,
      dots: false,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
      responsive: {
         0: {
            items: 1
         },
         600: {
            items: 3
         },
         1000: {
            items: 3
         }
      },
   }
   items = [...Array(100).keys()].map((x) => ({
      title: `Slide ${x + 1}`,
   }));
   Master_State_City: any = (Master_State_City as any).default.Master_State_City;
   getadmissionResponce: any;
   checkVal: any;

   constructor(private fb: FormBuilder, private renderer: Renderer2, private json_service: JsonFileService,
      private common_service: CommonService, private _activeRoute: ActivatedRoute,private _route:Router) {
      this.admissionForm = fb.group({
         fname: ['', Validators.required],
         email: ['', [Validators.required, Validators.email]],
         mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
         state: ['', Validators.required],
         course: ['', Validators.required],
         city: ['', Validators.required],
         specialization: ['', Validators.required],
      })

   }
   clickMenu(value: any) {
      document.getElementById("menuClose")?.click();
   }

   ngOnInit(): void {
      this._activeRoute.queryParams.subscribe((params: Params) => {
         this.utm_source = params["utm_source"] != null ? params["utm_source"] : this.utm_source;
         this.utm_medium = params["utm_medium"] != null ? params["utm_medium"] : this.utm_medium;
         this.utm_compaign = params["utm_campaign"] != null ? params["utm_campaign"] : this.utm_compaign;
         this.gclid = params["gclid"] != null ? params["gclid"] : this.gclid;
         this.utm_term = params["utm_term"] != null ? params["utm_term"] : this.utm_term;
         this.utm_content = params["utm_content"] != null ? params["utm_content"] : this.utm_source;
         this.utm_ad = params["utm_ad"] != null ? params["utm_ad"] : this.utm_ad;
      });
      this.course_list = this.json_service.courseList
      this.courseData();
      console.log('course', this.course_list);
      this.courseData();
      this.selectCountry_State_cityList();
      this.createCaptcha();
   }

   get f() {
      return this.admissionForm.controls;
   }


   courseData() {
      let filterCourse = [...new Set(this.course_list.map((element: any) => element.CourseList))]
      this.courseDataList = filterCourse
      console.log('spcil', this.courseDataList)
   }

   selectCourse(selectVal: any) {
      let courseSpcil = selectVal.target.value
      console.log(selectVal)
      let filterCourse = this.course_list.filter((x: any) => {
         return x.CourseList == courseSpcil
      })
      this.specializationList = filterCourse
   }

   selectCountry_State_cityList() {
      this.state_data = this.json_service.stateCityList.Master_State_City;
      let newarr = [...new Set(this.state_data.map((element: any) => element.state))]
      this.stateList = newarr
      console.log('newarr', newarr);
   }
   selectState(selectVal: any) {
      let state = selectVal.target.value
      console.log(selectVal)
      let filterCity = this.state_data.filter((x: any) => {
         return x.state == state
      })
      this.cityList = filterCity
      console.log('state', filterCity);
      //  if(!Array.isArray(filterCity[0].city))
      //  {
      //    this.cityList=[filterCity[0].city];
      //    console.log('city1',this.cityList);
      //  }
      //  else
      //  {
      //    this.cityList = filterCity[0].city;
      //    console.log('city2',this.cityList);
      //  }
   }
   generateCAPTCHA() {
      "use strict";

      var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",

         CAPTCHALength = 6,

         randomCAPTCHA = "",

         i,

         randomNumber;

      for (i = 0; i < CAPTCHALength; i = i + 1) {

         randomNumber = Math.floor(Math.random() * chars.length);

         randomCAPTCHA += chars.substring(randomNumber, randomNumber + 1);
         this.gen_captcha = randomCAPTCHA;
         (document.getElementById("CAPTCHA") as HTMLInputElement).innerHTML = this.gen_captcha;
         //this.generatedcaptcha=this.gen_captcha;
      }
   }
   createCaptcha() {
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      const lengthOfCode = 1

      for (let i = 0; i < 6; i++) {
         let captchaChar = this.makeRandom(lengthOfCode, possible)
         this.captchaText[i] = captchaChar
      }
      this.gen_captcha = this.captchaText.join('').toString();
   }
   makeRandom(lengthOfCode: number, possible: string) {
      let text = "";
      for (let i = 0; i < lengthOfCode; i++) {
         text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
   }

   submit_captcha() {
      if ((document.getElementById("input") as HTMLInputElement).value === "") {
         (document.getElementById("wrong") as HTMLInputElement).innerHTML = "This Field Can't Be Empty";
         (document.getElementById("wrong") as HTMLInputElement).style.display = "block";

         (document.getElementById("done") as HTMLInputElement).style.display = "none";


         this.generateCAPTCHA();

      } else if ((document.getElementById("input") as HTMLInputElement).value !== this.gen_captcha) {
         (document.getElementById("done") as HTMLInputElement).style.display = "none";

         (document.getElementById("wrong") as HTMLInputElement).style.display = "block";

         (document.getElementById("wrong") as HTMLInputElement).innerHTML = "Try Again";

         (document.getElementById("input") as HTMLInputElement).value = "";

         this.generateCAPTCHA();

      } else {
         (document.getElementById("wrong") as HTMLInputElement).style.display = 'none'
         this.submitForm();
      }
   }
   validationForm() {
      console.log('1',((document.getElementById("text_captcha")as HTMLInputElement).value));
      console.log('2',this.gen_captcha)
      this.submitted = true;
      if (this.admissionForm.valid) {
         this.submitForm();
      } else {
        alert("AllFields Required")
         // this.submit_captcha();
      }
   }

   submitForm() {
      if(this.gen_captcha==(document.getElementById("text_captcha")as HTMLInputElement).value){
         if(this.checkVal == true){
            this.loader=true
      let data = {
         "name": this.admissionForm.get('fname')?.value,
         "email": this.admissionForm.get('email')?.value,
         "country_dial_code": "+91",
         "mobile": this.admissionForm.get('mobile')?.value,
         "state": this.admissionForm.get('state')?.value,
         "city": this.admissionForm.get('city')?.value,
         "course": this.admissionForm.get('course')?.value,
         "specialization": this.admissionForm.get('specialization')?.value,
         "source": this.utm_source,
         "medium": this.utm_medium,
         "campaign": this.utm_compaign
      }
      this.common_service.postAdmissionForm(data).subscribe(
         res => {
            this.getadmissionResponce = JSON.parse(res)
            console.log('sucess', this.getadmissionResponce)
            if(this.getadmissionResponce.status == true){
               alert(this.getadmissionResponce.message);
               this._route.navigateByUrl('/thankyou')
               this.loader=false;
            }else{
               alert(this.getadmissionResponce.message);
               this.loader=false
            }
           
         }
      )
   }else{
      alert('Select checkbox')
   }
}
   else{
      alert('Please Enter Valid Captcha')
   }
   }

   checkValue(val:any){
      this.checkVal = val.currentTarget.checked
      console.log(this.checkVal);
   }
}
