import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';

@Component({
  selector: 'app-careerplacementcommon',
  templateUrl: './careerplacementcommon.component.html',
  styleUrls: ['./careerplacementcommon.component.css']
})
export class CareerplacementcommonComponent {
  customOptions: any = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 3
      }
    },
  }
  items = [...Array(100).keys()].map((x) => ({
    title: `Slide ${x + 1}`,
  }));
  @Input() careerplacementdata: any = [];
  project$: Observable<any> | undefined;
  subscriptionnav!: Subscription;
  @Input() fromslug:string="";
  careerplacement: any = [];
  assigncategory: any = [];
  getAllDetails: any=[];
  imgFile: any;
  alldata: any;
  pgdata: any;
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,
    private _ApicallService: ApicallService,
  ) {

  }

  ngOnInit(): void {
    this.getseo();
    AOS.init();
    if (this.careerplacementdata) {
      this.careerplacement = this.careerplacementdata[0].data;
      this.assigncategory = this.careerplacementdata[0].AssignCategory;
    }
   
  }


  getseo() {
    let tbody = {
      slug:'career-placement',
      };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
      this.alldata=data?.data;
        this.pgdata=data?.data?.pagedata;  
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);      
        this.projectService.setprogramssubject(data?.data?.Programs);//
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
        this.projectService.sendMessagebread(data?.data.breadcrumb)
    });
  }

  filtercategory(id: number) {
    if (id != 0) {
      this.careerplacement = this.careerplacementdata[0].data.filter((dt: any) => {
        return dt.category.includes(id)
      }).map((obj: any) => {
        return obj;
      })
    }
    else {
      this.careerplacement = this.careerplacementdata[0].data;
    }

  }

  showAllDetails(data:any){
    this.getAllDetails = data
    this.imgFile = this.getAllDetails.files
  }
}
