import { Component, OnInit,Renderer2 } from '@angular/core';

@Component({
  selector: 'app-admission-lp-thankyou',
  templateUrl: './admission-lp-thankyou.component.html',
  styleUrls: ['./admission-lp-thankyou.component.css']
})
export class AdmissionLPThankyouComponent implements OnInit {

  constructor( private renderer: Renderer2){

}

  ngOnInit(): void {
    this.addFacebookPixelScript();
  }
  private addFacebookPixelScript(): void { const script = this.renderer.createElement('script'); script.type = 'text/javascript'; script.text = `gtag('event', 'conversion', {'send_to': 'AW-11468450464/vJ5_CLqZxa0ZEKDVytwq'});  !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '588724662990567');
fbq('track', 'FormSubmit');`; this.renderer.appendChild(document.head, script); const noscript = this.renderer.createElement('noscript'); const img = this.renderer.createElement('img'); this.renderer.setAttribute(img, 'height', '1'); this.renderer.setAttribute(img, 'width', '1'); this.renderer.setAttribute(img, 'style', 'display:none'); this.renderer.setAttribute(img, 'src', 'https://www.facebook.com/tr?id=588724662990567&ev=PageView&noscript=1'); this.renderer.appendChild(noscript, img); this.renderer.appendChild(document.body, noscript); }
}
