<section>
  <div class="subMenu_content">
    <app-sub-menu></app-sub-menu>
</div>
    <div class="sub_content">
        <div class="title">Privacy Policy</div>
        <div class="main_content_para my-4">
            <p>Jigyasa University
(Formerly Himgiri Zee University) has framed this Privacy Policy in order to demonstrate our firm commitment to your privacy. The following document outline our policies and practices with respect to information gathering, dissemination and protection.</p>
            <h5>Compliance</h5>
            <div class="para">
             <p>By entering into contractual agreement with Jigyasa University
(Formerly Himgiri Zee University) you agree to the terms of the Jigyasa University
(Formerly Himgiri Zee University) Policy outlined below:</p>
            </div>
            <h5>Collection of information</h5>
            <div class="para">
              <p>Jigyasa University
(Formerly Himgiri Zee University) does not collect any information about you or your organization other than the information that you affirmatively choose to submit to us.</p>
            </div>
            <h5>Use of information</h5>
            <div class="para">
              <p>Jigyasa University
(Formerly Himgiri Zee University) collects this information to make better decisions towards our mutual advantage, support the activities you choose to engage in, and provide higher quality education and services. We will not sell, or rent this information, for commercial purposes, to any person or organization.</p>
            </div>
            <h5>Information security</h5>
            <div class="para">
              <p>Jigyasa University
(Formerly Himgiri Zee University) classifies your personal information as Confidential Information. Confidential Information is protected by storing it on secure servers that only authorizes Jigyasa University
(Formerly Himgiri Zee University) personnel and students to have access to it. The information is encrypted to prevent unauthorised parties from accessing this information.</p>
            </div>
            <h5>Website Information Management Policy</h5>
            <div class="para">
            <ol>
                <li><p>While your personnel and students access our website we do not recognize and collect the IP address, browser type, ISP, referring/exit pages, platform type, date/time, stamp, number of clicks, domain name and country/state.</p></li>
                <li><p>. We may use your IP address to help diagnose problems with our server and to administer the web-site.</p></li>
                <li><p> The Site contains links to other third party websites. Jigyasa University
(Formerly Himgiri Zee University) is not responsible for the content, accuracy or opinions express in such websites, and such Web sites are not investigated, monitored or checked for accuracy or completeness by us. Inclusion of any linked website on our Site does not imply approval or endorsement of the linked website by us. If you decide to leave our Site and access these third-party sites, you do so at your own risk.</p></li>
                <li><p>We will not use any of your information to enhance our website without prior written consent from an authorized signatory of your organization.</p></li>
            </ol>   
            </div>
            <h5>Change to the policy</h5>
            <div class="para">
                <p>Jigyasa University
(Formerly Himgiri Zee University) reserves the right to modify or amend this Privacy Policy at its own discretion.
                </p>
            </div>
        </div>
    </div>
</section>