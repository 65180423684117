import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';
import { CommonService } from '../service/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { env } from 'process';

@Component({
  selector: 'app-internshipand-placement',
  templateUrl: './internshipand-placement.component.html',
  styleUrls: ['./internshipand-placement.component.css']
})
export class InternshipandPlacementComponent {
  @Input() ishide!:number;
    customOptions: any = {
      loop: true,
      margin: 10,
      nav: true,
      dots: false,
      navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 3
        },
        1000: {
          items: 3
        }
      },
    }
    items = [...Array(100).keys()].map((x) => ({
      title: `Slide ${x + 1}`,
    }));
    
    project$: Observable<any> | undefined;
    subscriptionnav!: Subscription;
    @Input() fromslug:string="";
    project_id:number=0;
    faculty: any = [];
    facultycategory: any = [];
    getAllDetails: any=[];
    alldata:any=[];
    imgFile: any;
  pagedata: any;
    constructor(
      private route: ActivatedRoute,
      private seoService: HomeSeoService,
      private projectService: ProjectSeoService,
      private apiService: ApicallService,
       private _service: CommonService,
      private spinner:NgxSpinnerService,
      private _ApicallService: ApicallService,
    ) {
  
    }
  
    ngOnInit(): void {    
     this.project_id=environment.projectid;  
     this.getplacementData();
    }

    getseo() {
      let tbody = {
        slug:'internship-and-placement',
        };
      this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
        this.alldata=data?.data;
          this.pagedata=data?.data?.pagedata;  
          this.projectService.sendMessageblog(data?.data?.Blog);
          this.projectService.sendMessagenews(data?.data?.News)
          this.projectService.setFacultysubject(data?.data?.Faculty);
          this.projectService.setPlacementsubject(data?.data?.Placement);      
          this.projectService.setprogramssubject(data?.data?.Programs);//
          this.projectService.sendMessageseo(data?.data?.Testimonial);
          this.projectService.sendMessageFaqs(data?.data?.FAQs);
          this.projectService.setmeta(data?.data);
          this.projectService.sendMessagebread(data?.data.breadcrumb)
          });
    }
    getplacementData() {
      this.spinner.show();
      let obj = {
        "Project_Id": this.project_id,
        "Type": "Internship and Placement",
        "pageurl":"internship-and-placement"
      }
      this._service.getContentbytype(obj).subscribe(
        res => {    
          this.spinner.hide();   
          if(res.data){
            this.facultycategory = JSON.parse(res.data[0].AssignCategory);
            this.alldata=JSON.parse(res.data[0].contentData);
            this.faculty=JSON.parse(res.data[0].contentData);
            //this.PlacementList = JSON.parse(res.data[0].contentData);  
          }else{
            
          }
            
        }
      )
    }
  
    filtercategory(id: number) {
      if (id != 0) {
        this.faculty = this.alldata.filter((dt: any) => {
          return dt.category.includes(id)
        }).map((obj: any) => {
          return obj;
        })
      }
      else {
        this.faculty = this.alldata;
      }
  
    }
  
    showAllDetails(data:any){
      this.getAllDetails = data
      this.imgFile = this.getAllDetails.logofiles
    }
}
  

