import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AOS from 'aos';
import { CommonService } from '../service/common.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-all-coordinators-alumni',
  templateUrl: './all-coordinators-alumni.component.html',
  styleUrls: ['./all-coordinators-alumni.component.css']
})
export class AllCoordinatorsAlumniComponent {
  project_id = environment.projectid;  
  alumnidata:any=[];
  alldata:any=[];
  pagedata:any=[];
  alumnicategory:any=[];
  project$: Observable<any> | undefined;
  subscriptionnav!: Subscription;
  @Input() fromslug:string="";
  faculty: any = [];
  facultycategory: any = [];
  getAllDetails: any=[];
  imgFile: any;
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,    
    private _service: CommonService,
    private spinner:NgxSpinnerService,
    private _ApicallService: ApicallService,
  ) {

  }

  ngOnInit(): void {    
    AOS.init();
    this.getalumnidata(); 
  }

  getseo() {
    let tbody = {
      slug:'alumni',
    };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {  
      this.alldata = data?.data;
      this.pagedata = data?.data?.pagedata;
      this.projectService.sendMessageblog(data?.data?.Blog);
      this.projectService.sendMessagenews(data?.data?.News)
      this.projectService.setFacultysubject(data?.data?.Faculty);
      this.projectService.setPlacementsubject(data?.data?.Placement);
      this.projectService.setprogramssubject(data?.data?.Programs);//
      this.projectService.sendMessageseo(data?.data?.Testimonial);
      this.projectService.sendMessageFaqs(data?.data?.FAQs);
      this.projectService.setmeta(data?.data);
      this.projectService.sendMessagebread(data?.data.breadcrumb)
    });
  }
  geturl(id:any){
    if(id){
      let url="https://www.youtube.com/embed/"+id+"?autoplay=1&amp;rel=0&controls=0&amp;"
     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }else{
      return this.sanitizer.bypassSecurityTrustResourceUrl('');
    }

  }
  getalumnidata(){
    this.spinner.show();
    let obj = {
      "Project_Id": this.project_id,
      "Type": "Alumni",
      "pageurl":"home"
    }
    this._service.getContentbytype(obj).subscribe(
      res => {    
        this.getseo();
        this.spinner.hide();   
        if(res.data){
          this.alumnicategory = JSON.parse(res.data[0].AssignCategory);
          this.alumnidata=JSON.parse(res.data[0].contentData);
          this.seturl(JSON.parse(res.data[0].contentData));
        //this.PlacementList = JSON.parse(res.data[0].contentData);   
          
        }else{
          
        }          
      }
    )
  }
  seturl(data:any){
    let dt=data.filter((x:any)=>{
      return x
    }).map((obj:any)=>{
      return {...obj,url:this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+obj.slug+"?autoplay=1&amp;rel=0&controls=0&amp;")}
    })
  
    
    this.alumnidata=dt.filter((a:any)=>{
      return a.category.includes(42) //coordinator
    }).map((obj:any)=>{
      return obj;
    });        
  }

  showAllDetails(data:any){
    this.getAllDetails = data
    this.imgFile = this.getAllDetails.files
  }
}
