<section>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <div class="sub_menu_content">
                <a class="navbar-brand" href="#">
                    <img src="../../../../assets/img/jigyasa/banner/JigyasaUniversityLogo.png" width="160px" height="60px"
                        alt="jigyasa">
                </a>
                <button class="navbar-toggler menu_btn" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#AboutUs">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page"
                                href="admissionlp#Accreditation">Accreditation</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#Programs">Programs</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#Gallery">Gallery</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#Recruiters">Recruiters</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#Placements">Placements</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#Testimonial">Testimonial</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#FAQs">FAQs</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#USPs">USPs</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page"
                                href="admissionlp#CourseSpecialization">Specialisation</a>
                        </li>
                        
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="admissionlp#AboutParagraph">About
                                Paragraph </a>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</section>
<div class="main_section">


<section>
        <div class=content>
            <div class="wrapper-1">
              <div class="wrapper-2">
                <h1>Thank you !</h1>
                <p>Thanks for submitting your enquiry to our Website.  </p>
                <p>You should receive a Email/Call soon to discusse further  </p>
                <button class="btn back_btn" routerLink="">
                Go home
                </button>
              </div>
          </div>
          </div>
            </section>
    <section id="FAQs">
        <div class="footer_content mt-5">
            <div class="">
                <div class="row">
                    <div class="col-md-3">
                        <img src="../../../../assets/img/jigyasa/banner/JigyasaUniversityLogo.png" width="70%" alt="">
                        <div class="midea_icon_list mt-4">
                            <a href="https://www.facebook.com/Jigyasauniversity" target="_blank" class="mx-1"><img
                                    src="../../../../assets/img/jigyasa/admissionLP/Icon.png" width="22px" height="22px"
                                    alt=""></a>
                            <a href="https://www.linkedin.com/school/jigyasa-university/" target="_blank" class="mx-1"><img
                                    src="../../../../assets/img/jigyasa/admissionLP/Icon2.png" width="22px"
                                    height="22px" alt=""></a>
                            <a href="https://www.youtube.com/@Jigyasauniversity" target="_blank" class="mx-1"><img
                                    src="../../../../assets/img/jigyasa/admissionLP/Icon3.png" width="22px"
                                    height="22px" alt=""></a>
                            <a href="https://www.instagram.com/ju_ddn/" target="_blank" class="mx-1"><img
                                    src="../../../../assets/img/jigyasa/admissionLP/Icon4.png" width="22px"
                                    height="22px" alt=""></a>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-4">
                                <ul>
                                    <li><a href="admissionlp#AboutUs"> About Us </a></li>
                                     <li><a href="admissionlp#Accreditation">Accreditation</a></li>
                                     <li><a href="admissionlp#Programs">Programs</a></li>
                                    
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <ul>
                                    <li><a href="admissionlp#Gallery">Gallery</a></li>
                                    <li><a href="admissionlp#Recruiters">Recruiters</a></li>
                                    <li><a href="admissionlp#Placements">Placements</a></li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <ul>
                                    <li><a href="admissionlp#Testimonial">Testimonial</a></li>
                                    <li><a href="admissionlp#FAQs">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>




    