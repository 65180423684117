<section class="box">
    <div class="banner">
        <div class="banner_text_content">
            <div class="banner_heading">OUR <br> LEADERSHIP</div>
        </div>
        <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/desk-leadership.webp"
            width="100%" alt="">
    </div>
</section>
<section>
    <div class="leaders_content mt-2 pb-5">
        <div class="sub_content">
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
            <h1 class="title">Our Leadership</h1>
            <!-- <div class="row">
                <div class="col-md-6 mt-5" *ngFor="let a of filterdataOurLeaderShip">
                    <div class="item" role="button" tabindex="0">
                        <div class="box-section">
                            <div class="image-section">
                                <figure>
                                    <picture class="d-flex justify-content-center align-items-center">
                                        <img src="{{a.logofiles[0]?.value}}" data-src="{{a.logofiles[0]?.value}}"
                                            width="60%" alt="{{a.title}}" title="" class=" ls-is-cached lazyloaded">
                                    </picture>
                                </figure>
                            </div>
                            <div class="content-section">
                                <h3 class="sub-title">{{a.Title}}</h3>
                                <p>{{a.short}}</p>
                                <p [innerHTML]="a.Long1"></p>
                                <div class="readmore">
                                    <i class="fa fa-long-arrow-right text-white"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

<div class="row">
            <div class="col-md-4" *ngFor="let data of filterdataOurLeaderShip">
                <div class="common-card">
                    <figure> 
                      <img style="border-radius: 25px;" src="{{data.logofiles[0]?.value}}" data-src="{{data.logofiles[0]?.value}}"
                      width="60%" alt="{{data.title}}" title="" class=" ls-is-cached lazyloaded" width="485px" height="400px"> 
                           </figure>
                    <div class="common-card-body">
                      <div class="info_content">
                      <div class="common-card-body-heads">
                        <h3 class="mt-2">{{data.Title}}</h3>
                        <span>{{data.short}}</span>
                      </div>
                     <p class="line-clamp" [innerHTML]="data.Long1"></p>
                      </div>
                      <div class="readmore d-flex justify-content-end align-items-center">
                        <button class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="showAllDetails(data)"><i class="fa fa-long-arrow-right readmore"></i></button>
                       </div>
                     </div>
                  </div>
            </div>
        </div>
            <div *ngIf="filterdataOurLeaderShip.length==0">
                No Record Found!
            </div>
            <div class="showMore_content text-center mt-5">
                <label for="" class="count_label">Showing {{tindex}} records of {{this.totalrecord}}</label>
                <a class="showMore_btn" *ngIf="more" (click)="setcentrelistclick()">Show More</a>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="sub_content padding_top" id="Academic_Council">
        <div class="title">Our Committee</div>
        <div class="findYourProgram-wrap governance-structure-wrap">
            <form action="" siq_id="autopick_2124" __bizdiag="1810509538" __biza="WJ__">
                <div class="findYourProgram">
                    <div class="program-tab-outer">
                        <div class="listSearchArea">

                            <ul class="nav nav-tabs tabs-buttons" role="tablist">

                                <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
                                    *ngFor="let b of careersAssignCategory;let i=index">
                                    <button id="click_btn" class="nav-link" [ngClass]="(i === 0) ? 'active' : ''"
                                        data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                                        role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                                        (click)="getCatagory(b.categoryId)"
                                        *ngIf="b.categoryname != 'Our Leadership'">{{b.categoryname}}</button>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="patents_table mt-5">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr No.</th>
                                    <th>Name</th>
                                    <th>Position</th>
                                    <th>Contact Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let c of careersPlacementData;let i=index">
                                    <td>{{i+1}}</td>
                                    <td>
                                        <p>{{c.Title}}</p>
                                    </td>
                                    <td>
                                        <p>{{c.short}}</p>
                                    </td>
                                    <td>
                                        <p [innerHTML]="c.Long1"></p>
                                    </td>
                                </tr>                               
                            </tbody>
                        </table>                       
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<section>
    <div class="sub_content pb-5 mt-5">
        <div class="needMoreInfoWrap2">
            <div class="row">
                <div class="col-md-2">
                    <img src="	https://www.upes.ac.in/assets/images/icon-first-step-white.svg" width="80%" alt="">
                </div>
                <div class="col-md-6">
                    <h4>TAKE THE FIRST STEP TOWARDS YOUR CAREER</h4>
                </div>
                <div class="col-md-4">
                    <a href="https://api.whatsapp.com/send?phone=7060137722&text=Welcom to Jigyasa University" target="_blank"><button class="btn view_btn  counsellor_btn text-uppercase w-100">Talk to Our Counsellors</button></a>
                    <a href="{{applayUrl}}" target="_blank"><button class="btn counsellor_btn1 mt-3">Apply
                            Now</button></a>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel"><span class="title">Our Leadership</span></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-5">
          <figure class="figure" *ngFor="let a of imgFile"> 
            <img class=" lazyloaded"
                  src="{{a.value}}"
                  data-src="../../../assets/img/jigyasa/blank-removebg-preview.png"
                  alt="{{a.key}}" title="{{a.key}}" width="485" height="400"> 
                 </figure>
                </div>
                <div class="col-md-7">
                 <div class="common-card-body-heads">
                  <h3 class="mt-2">{{getAllDetails.Title}}</h3>
                  <span>{{getAllDetails.short}}</span>
                </div>
               <p [innerHTML]="getAllDetails.Long1"></p>
              </div>
        </div>
      </div>
      </div>
    </div>
  </div>